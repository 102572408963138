import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import InstagramIcon from '../../../../../components/icons/instagram-icon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, Checkbox, InputAdornment, TextField } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from '@mui/material/InputLabel';

import { Editor } from '../../../../../components';
import defaultImage from "../../../../../../src/assets/images/image-fill.svg"
import { FormValidation } from "../../../../../core/utility";
import FileUploadPopup from "../../../../../components/popup/file-upload-popup";
import HeadingStyle from "../../../../../components/heading-style/heading-style";

import {CreateCampaign as CreateCampaignService} from "../../../../../core/services/createcampaign.service";
import { CreateCampaign, Loading } from "../../../../../redux/action";
import "../../plan/plan-create/plan-create";
import UpgradeBrandPopUp from "../../../../../components/popup/upgrade-brand-popup";
import { MenuPropsSelect } from "../../../../../core/utility/style";
import { DummyCampaignImage } from "../../../../../config/config";


const formValidation = new FormValidation();
const createCampaignService: CreateCampaignService = new CreateCampaignService();


interface ErrorTypes {
    status: boolean;
    message: string;
  }

interface Props {
    UserReducer: any;
    UserID: any;
    campaignReducer: any;
    CreateCampaignReducer?: (data: any) => void;
    loading: (data: any) => void;
    getSubscriptionDetail: any;
}
const InternalCampaign: React.FC<Props> = (props: Props) => {
    const [links, setLinks] = useState("");
    const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
    const path = `accounts/${props.UserReducer.account_id}/plans/${props.UserReducer.user_id}/campaigns`;
    const [formData, setFormData] = useState({
        brand_id: props.UserReducer.brand_id,
        user_id: props.UserReducer.user_id,
        plan_id: props.campaignReducer.createPlan?.plan_id,
        plan_name: "",
        plan_description: "",
        plan_product_name: "",
        ...props.campaignReducer.createPlan,
        campaign_id: props?.campaignReducer?.createCampaign?.campaign_id,
        campaign_platform: 'Instagram',
        campaign_title: '',
        campaign_type: 'Analytics',
        campaign_description: props?.campaignReducer?.createPlan?.plan_description,
        campaign_image_path: '',
        campaign_image_url: '',
        campaign_budget: '0',
        campaign_objective: '',
        post_link: [],
        ...props.campaignReducer.createCampaign,

    });
    const navigate = useNavigate();
    //Show to Errors
    const [formError, setFormErrors] = useState<any>({
        // image: { status: true, message: '' },
        description: { status: true, message: '' },
        campaign_title: { status: true, message: '' },
        // campaign_budget: { status: true, message: '' },
        post_link:{ status: true, message: "" } 
    });
    const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState<boolean>(false);

    const handleCheckBox = (e: any) => {
        
            setFormData({
                ...formData,
                campaign_description:
                    props.campaignReducer.createPlan.plan_description,
            });
        
    };
    const handleAddLink = (url: string) => {
        if (url.length > 0) {
            const platform = formData.campaign_platform
            let is_valid_link:ErrorTypes = platform == "Instagram" ? formValidation.CheckInstagramUrl(url) : formValidation.CheckYouTubeUrl(url);
            setFormErrors({
                ...formError,
                post_link: { ...is_valid_link }
                
            });
            if(is_valid_link?.status){
                let post_links: any = formData.post_link;
                post_links.push(url);
                setFormData({
                  ...formData,
                  post_link: post_links,
                });
                setLinks("");
            }
        }
      };
      const handleRemoveLink = (index: number) => {
        let ref = [...formData.post_link];
        ref.splice(index, 1);
        setFormData({ ...formData, post_link: ref });
      };
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            const validateImage = formValidation.GetNameValidate(
                formData.campaign_image_url
            );
            const validateTitle = formValidation.GetNameValidate(
                formData.campaign_title
            );
            const validateLink = formData.post_link.length > 0 ? { status: true, message: "" } : { status: false, message: "Please add atleast one link" };
            setFormErrors({
                ...formError,
                // image: { ...validateImage },
                // description: { ...validateDesc },
                campaign_title: { ...validateTitle },
                post_link: {...validateLink}
            });
            if (validateTitle.status && validateLink) {
                const response = await createCampaignService.createPostAnalyticsCampaign({ formData:formData });
                if (response.status === 1) {
                    localStorage.setItem('activeCampaignDetailMenu', 'Analytics');
                    navigate(`/brand/campaigns/analytics?planID=${formData.plan_id}&campaignID=${formData.campaign_id}&planName=${formData.plan_name}`)
                }
            }
        } catch (error) {
            throw new Error(`handleNext Error :: ${error}`);
        }
    };

    const handleBackBtn = () => {
        navigate('/brand/campaign/createplan');
    };

    const handleOpenUpgradeBrandModal = (url:string) => {
        if (url.length > 0) {
            const platform = formData.campaign_platform
            let is_valid_link:ErrorTypes = platform == "Instagram" ? formValidation.CheckInstagramUrl(url) : formValidation.CheckYouTubeUrl(url);
            setFormErrors({
                ...formError,
                post_link: { ...is_valid_link }
                
            });
            if(is_valid_link?.status){
                setOpenUpgradeBrandModal(true)
            }
        }
    };
    const handleCloseUpradeBrandModal = () => setOpenUpgradeBrandModal(false);
    

    return (
        <div className=" main_campaign_main_container track-campaign"> 
        <div className="container">
        <div className="create_campaign_main_container">
        <form onSubmit={handleSubmit}>
            <div className='create_campaign_main_row'>
            <div className="col-12">
                <HeadingStyle
                    title="Track Campaign"
                    className="mb-4 d-block"
                />
            </div>
            <div className=''>
                <strong className='pb-1 d-block'>Platform</strong>
                <div className='col nav sub-tabs no-scrollbar create_campaign_platform input-checkbox-box-container'>
                    <span
                        onClick={() =>
                            setFormData({
                                ...formData,
                                campaign_platform: 'Instagram',
                            })
                        }
                        className={`input-checkbox-box d-inline-flex nav-link
                            ${(
                                formData.campaign_platform + ''
                            ).toLocaleLowerCase() === 'instagram'
                                ? ' active'
                                : ''}`}

                    >
                        <InstagramIcon width={24} />
                        Instagram
                    </span>
                    <span
                        onClick={() =>
                            setFormData({
                                ...formData,
                                campaign_platform: 'youtube',
                            })
                        }
                        className={
                            `input-checkbox-box d-inline-flex nav-link
                            ${(
                                formData.campaign_platform + ''
                            ).toLocaleLowerCase() === 'youtube'
                                ? 'active'
                                : ''}`}
                    >
                        <YouTubeIcon style={{ color: 'red' }} />
                        Youtube
                    </span>
                </div>
            </div>
            {/* <div className='row mb-3'>
                <div className='col'>
                    <FormControl
                        fullWidth
                        size='small'
                        className='objective_type_formcontrol'
                    >
                        <InputLabel id='demo-simple-select-label'>
                            Objective
                        </InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='id_Objective'
                            value={formData.campaign_objective || 'Drive Awareness'} // Set the default value to 'Drive Awareness'
                            label='Objective'
                            className='objective_type_formcontrol_dropdown'
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    campaign_objective: e.target.value,
                                })
                            }
                            MenuProps={MenuPropsSelect}
                        >
                            <MenuItem
                                className='campaign-desc'
                                value='Drive Awareness'
                            >
                                <strong>
                                    {' '}
                                    Drive Awareness: &nbsp;
                                </strong>
                                <span>
                                    Choose this if you want to bulid
                                    brand awareness and maximise your
                                    reach. Works best with macro & mega
                                    Influencer
                                </span>
                            </MenuItem>
                            <MenuItem
                                className='campaign-desc'
                                value='Drive Performance'
                            >
                                <strong>
                                    {' '}
                                    Drive Performance: &nbsp;
                                </strong>
                                <span>
                                    Choose this if you want measure
                                    sales/conversion or track reviews of
                                    your Product.
                                </span>
                            </MenuItem>
                            <MenuItem
                                className='campaign-desc'
                                value='Drive Engagement'
                            >
                                <strong>
                                    {' '}
                                    Drive Engagement: &nbsp;
                                </strong>
                                <span>
                                    Choose this if you want measure
                                    sales/conversion or track reviews of
                                    your Product.
                                </span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div> */}
            <div className='row '>
                <div className='col'>
                    <div className='row mb-3'>
                        <div className='col'>
                            <TextField
                                className='w-100'
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        campaign_title: e.target.value,
                                    })
                                }
                                value={formData.campaign_title}
                                error={!formError.campaign_title.status}
                                helperText={
                                    formError.campaign_title.message
                                }
                                size='small'
                                type='text'
                                name='Campaign Title '
                                label='Campaign Title'
                            />
                        </div>
                    </div>
                    {/* <div className='row mb-3'>
                        <div className='col mt-3'>
                            <TextField
                                className='w-100'
                                error={!formError.campaign_budget.status}
                                helperText={
                                    formError.campaign_budget.message
                                }

                                label='Budget (Optional)'
                                id='outlined-start-adornment'
                                size='small'
                                value={
                                    formData.campaign_budget === '0'
                                        ? ''
                                        : formData.campaign_budget
                                }
                                onChange={(e) => {
                                    let validate = formValidation.IsNumber(e.target.value)
                                    if (validate.status) {
                                        setFormData({
                                            ...formData,
                                            campaign_budget: e.target.value,
                                        })
                                    };

                                    setFormErrors((e: any) => ({ ...e, campaign_budget: validate }))
                                }
                                }
                                InputProps={{

                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <CurrencyRupeeIcon className='currencyIcon' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div> */}
                    <div className='row mb-2 d-none'>
                        <div className='col d-flex flex-column flex-md-row'>
                            <strong className='me-2'>
                                Description
                            </strong>{' '}
                            <span className='fs-14'>
                                <Checkbox
                                    className='p-0'
                                    onChange={handleCheckBox}
                                    checked={true}
                                />
                                Use same as Plan Description
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='create_campaign_editor'>
                                {/* <Editor
                                    editorValues={
                                        formData.campaign_description
                                    }
                                    getEditorValue={(e) =>
                                        setFormData({
                                            ...formData,
                                            campaign_description: e,
                                        })
                                    }
                                />
                                <span className='error'>
                                    {formError.description.message}
                                </span> */}
                            </div>
                        </div>
                    </div>
                    <div className="row text_field">
            <div className="col product_add mt-2 d-flex">
            <TextField
                className="col"
                label="Post Link"
                name="link"
                id="first_link_id"
                size="small"
                error={!formError.post_link.status}
                helperText={formError.post_link.message}
  
                value={links}
                onChange={(e) => setLinks(e.target.value)}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                ),
                }}
            />
            <Button
                className="btn btn-outline-primary ms-2 w-20 col-auto"
                onClick={props.getSubscriptionDetail && !props.getSubscriptionDetail.isBasicUser ? () => handleAddLink(links) : () => handleOpenUpgradeBrandModal(links)}
                variant="outlined"
            >
                Add
            </Button>
            </div>
  </div>
                </div>
                <div className='col-auto position-relative'>
                    <span className="upload-image-helper-text">Upload Image <span>(optional)</span></span>
                    <div className="upload_image_section">
                    <strong className='d-block d-md-none mb-2 mb-md-none'>
                        Upload
                    </strong>
                    <div className=''>
                        {/* <span className='error'>
                            {formError.image.message}
                        </span> */}
                        {showUploadPopup && (
                            <FileUploadPopup openModal={showUploadPopup} onClose={(e) => setShowUploadPopup(false)} onSend={(e: any) =>
                                setFormData({
                                    ...formData,
                                    campaign_image_url: e.url,
                                    campaign_image_path: e.key,
                                })
                            }
                                path={path}
                                acceptedFileTypes=".jpg, .jpeg, .png" />
                        )}
                        {formData.campaign_image_url &&
                            <img
                                aria-label='cropped image'
                                className='campaign_cover_image'
                                alt='cover'
                                src={
                                    formData.campaign_image_url.length > 0
                                        ? formData.campaign_image_url
                                        : DummyCampaignImage.default_campaign_image
                                }
                            />
                        }
                       
                    </div>
                     <Button
                            className='btn btn-outline-primary upload_button'
                            onClick={() => setShowUploadPopup(true)}
                            variant='contained'
                        >
                            Upload
                    </Button>
                    </div>
                </div>
            </div>
            </div>
            
  <div className="row">
    <div className="col list_ul">
      <ul>
        {formData.post_link.length > 0
          ? formData.post_link.map((res: string, index: number) => {
            return (
              <li key={index}>
                {res}{" "}
                <span>
                  <CloseIcon
                    className="close_button"
                    onClick={() => handleRemoveLink(index)}
                  />
                </span>
              </li>
            );
          })
          : ""}
      </ul>
    </div>
  </div>               

    <div className='buttons d-flex'>
        <Button
            className='btn btn-outline-primary me-auto'
            variant='outlined'
            onClick={handleBackBtn}
        >
            Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={formData.post_link.length > 0 ? false:true} className='ms-2 btn btn-primary'>Get Analytics</Button>
    </div>
    </form>
        </div>
        </div>  
        {openUpgradeBrandModal && (
                <UpgradeBrandPopUp
                    openUpgradeBrandModal={openUpgradeBrandModal}
                    onClose={() => handleCloseUpradeBrandModal()}
                    popupMinWidth='630px'
                >
                </UpgradeBrandPopUp>
            )}     

    </div>

    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID,
        getSubscriptionDetail: state.Subscription.subscription_detail
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(InternalCampaign);
export { connectToRedux as InternalCampaign };