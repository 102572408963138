export const InfluencerTypes = [
  {
    id: 7,
    influencer_type: 'Mega Plus',
    description: '',
    min: 5000001,
    max: 8000000000,
    count_records: 0,
    status: 'active',
  },
  {
    id: 6,
    influencer_type: 'Mega',
    description: '',
    min: 1000001,
    max: 5000000,
    count_records: 0,
    status: 'active',
  },
  {
    id: 5,
    influencer_type: 'Macro Plus',
    description: '',
    min: 500001,
    max: 1000000,
    count_records: 0,
    status: 'active',
  },
  {
    id: 4,
    influencer_type: 'Macro',
    description: '',
    min: 100001,
    max: 500000,
    count_records: 0,
    status: 'active',
  },
  {
    id: 3,
    influencer_type: 'Micro Plus',
    description: '',
    min: 50001,
    max: 100000,
    count_records: 0,
    status: 'active',
  },
  {
    id: 2,
    influencer_type: 'Micro',
    description: '',
    min: 10001,
    max: 50000,
    count_records: 0,
    status: 'active',
  },
  {
    id: 1,
    influencer_type: 'Nano',
    description: '',
    min: 1000,
    max: 10000,
    count_records: 0,
    status: 'active',
  },
];

export const Platforms = [
  {id: 1, label: 'Instagram', value: 'instagram'},
  {id: 2, label: 'Youtube', value: 'youtube'},
];

export const InfluencerGender = [
  {id: 1, label: 'Male', value: 'M'},
  {id: 2, label: 'Female', value: 'F'},
  {id: 3, label: 'Any', value: ''},
];

export const AudienceGender = [
  {id: 1, label: 'All', value: 'A'},
  {id: 2, label: 'Male', value: 'M'},
  {id: 3, label: 'Female', value: 'F'},
];

export const Min_Engagement_Rate = [
  {id: 1, label: '', min_range: 0, max_range: 2},
  {id: 2, label: '', min_range: 2, max_range: 4.99},
  {id: 3, label: '', min_range: 5, max_range: 6.99},
  {id: 4, label: '', min_range: 7, max_range: 7.99},
  {id: 5, label: '', min_range: 10, max_range: 14.99},
  {id: 6, label: '', min_range: 15, max_range: 19.99},
  {id: 7, label: '', min_range: 20, max_range: 49.99},
  {id: 8, label: '', min_range: 50, max_range: 99.99},
  {id: 9, label: '', min_range: 100, max_range: 5000},
];

export const InfluencerAgeRange = [
  {id: 1, label: '', min_range: 0, max_range: 18},
  {id: 2, label: '', min_range: 18, max_range: 25},
  {id: 3, label: '', min_range: 25, max_range: 34},
  {id: 4, label: '', min_range: 36, max_range: 44},
  {id: 5, label: '', min_range: 45, max_range: 54},
  {id: 6, label: '', min_range: 55, max_range: 64},
  {id: 7, label: '', min_range: 65, max_range: 150},
];
export const ChannelViews = [
  {id: 2, label: '1 - 100K', min_range: 1, max_range: 100000},
  {id: 3, label: '100K - 500K', min_range: 100001, max_range: 500000},
  {id: 4, label: '500K - 1M', min_range: 500001, max_range: 1000000},
  {id: 5, label: '1M+', min_range: 1000001, max_range: 8000000},
];
