import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';

interface Props {
    // coverImage: string,
    // proposal: string,
    // expected: string,
    // gender: string,
    description: any,
    updated_at: string
}


export const InfluencerCampaignCard: React.FC<Props> = (props: Props) => {
    const myref: any = useRef(null);

    useEffect(() => {
        myref.current.innerHTML = props.description;

    }, [props.description])
    return (

        <div className="inf-campaign-card-container">

            {/* <div className="col-auto imageContainer">
                    <img className='cardImage' src={props.coverImage} alt='cover' />
                </div>
                <div className="col campaign-duration">
                    <span><strong>Posted on</strong></span>
                    <span>{dayjs(props.updated_at).format('LL')}</span>
                    <span><strong>Proposal Deadline</strong></span>
                    <span>{props.proposal}</span>
                    <span><strong>Expected Go Live</strong></span>
                    <span>{props.expected}</span>
                </div>
                <div className="col inf-details">
                    <span><strong>Influender Details</strong></span>
                    <span>{props.gender}</span>
                </div> */}
            <div className="col inf-description">
                <span className='description' id='id_description' ref={myref}>
                </span>
            </div>

        </div>

    )
}