import React from 'react';
import UseAnimations from 'react-useanimations';
import trash2 from "react-useanimations/lib/trash2";
import './common-icons.scss';


interface TrashIcon2Props {
    size: number;
    classname?: string;
    onClick?: () => void;
}
export const TrashIcon2: React.FC<TrashIcon2Props> = ({ size, classname = '' }: TrashIcon2Props) => {
    return (
        <UseAnimations className={` trashicon2 ${classname}`} animation={trash2} size={size} />
    )
}