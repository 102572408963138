import React, { Fragment, useEffect, useState } from 'react';

import * as Util from '../../../../../../../core/utility';
import { InfluencerType } from './enums';

const methods: Util.Methods = new Util.Methods();


interface DeliverableToolProps {
    data: Record<string, any[]>;
    filteredInfluencer?: (type: InfluencerType) => void;
};

const influencerRange: Record<InfluencerType, string> = {
    'Mega Plus': '5M+',
    'Mega': '(1M - 5M) ',
    'Macro Plus': '(500K - 1M)',
    'Macro': '(100K - 500K)',
    'Micro Plus': '(50K - 100K)',
    'Micro': '(10K - 50K)',
    'Nano': '(1K - 10K)',
    'All': ''
};

interface InfluencerData {
    influencer_type: InfluencerType;
    count_records: number;
}

export const DeliverableTool = ({ data, filteredInfluencer }: DeliverableToolProps) => {
    const [random] = useState(methods.getRandNumber());
    const [influencers, setInfluencers] = useState<InfluencerData[]>([]);
    const [value, setValue] = useState<InfluencerType>("All")

    useEffect(() => {
        const transformedData: InfluencerData[] = Object.entries(data)
            .filter(([key]) => key in influencerRange)
            .map(([key, value]) => ({
                influencer_type: key as InfluencerType,
                count_records: value.length
            }));
        const sortedData: any = [
            ...transformedData,
        ];
        setInfluencers(sortedData);
    }, [data]);

    // filteredInfluencer(value)
    const handleChange = (type: InfluencerType) => {
        setValue(type); // Call the parent function
        filteredInfluencer && filteredInfluencer(type);
    };
    return (
        <fieldset className="deliverable-tool" id={`deliverable-tool-${random}`}>
            {influencers.map((type, index) => (
                <Fragment key={index}>
                    <input type="radio" name="deliverable-tool" id={`deliverable-tool-${random}-${index}`} checked={type.influencer_type === value}
                        onChange={() => handleChange(type.influencer_type)} />
                    <label className={type.count_records === 0 ? "infl-range-disabled" : ""} htmlFor={`deliverable-tool-${random}-${index}`}>
                        {type.influencer_type}
                        <span className="ps-1">({type.count_records})</span>
                        {influencerRange[type.influencer_type] && (
                            <span className="deliverable-infl-range">
                                {influencerRange[type.influencer_type]}
                            </span>
                        )}
                    </label>
                </Fragment>
            ))}
        </fieldset>
    );
}