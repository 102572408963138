import {
    Button,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React from 'react';
import './refer-earn.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export const ReferEarn: React.FC = () => {
    return (
        <div className='influencer_setting_refer_main'>
            <strong>Your Referral Code</strong>
            <p className='fs-14'>
                You can share the below code/link with your friends to earn via
                Referrals.
            </p>
            <Grid container rowSpacing={1} spacing={2}>
                <Grid item md={12} spacing={3}>
                    <Grid md={8} className='d-flex input-w-btn'>
                        <TextField
                            className='col'
                            fullWidth
                            name='referral_code'
                            size='small'
                            type='text'
                            id='outlined-basic'
                            label='Referral Code'
                            placeholder='AY17737'
                            variant='outlined'
                        />
                        <Button
                            startIcon={<ContentCopyIcon />}
                            variant='outlined'
                            className='col-auto btn btn-outline-primary'
                        >
                            Copy Code
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={12} spacing={3}>
                    <Grid md={8} className='d-flex input-w-btn'>
                        <TextField
                            fullWidth
                            name='referral_link'
                            size='small'
                            type='text'
                            id='outlined-basic'
                            label='Referral Link'
                            placeholder='https://www.clanconnect.ai/register-as-influencer?referral=AY17737'
                            variant='outlined'
                            className='col'
                        />
                        <Button
                            startIcon={<ContentCopyIcon />}
                            variant='outlined'
                            className='col-auto btn btn-outline-primary'
                        >
                            Copy Link
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <div className='col  _influencer_setting_refer_earn_middle'>
                <div className='row mt-2'>
                    <div className='col text-start align-self-center mb-3'>
                        <strong className='fs-18 '>Total Earnings</strong>
                    </div>
                    <div className='col-auto text-center d-flex pb-1'>
                        <strong className='align-self-center mb-3'>
                            <CurrencyRupeeIcon className='fs-18' />0
                        </strong>
                        <div className='d-flex flex-column'>
                            <Button
                                className='btn btn-outline-primary influencer_setting_refer'
                                variant='outlined'
                            >
                                Claim
                            </Button>
                            <span style={{ fontSize: '10px' }}>
                                Earn a minimum of ₹ 100 before you can claim it.
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col _refer_earn_setting'>
                        <table className='table-plan'>
                            <tr></tr>
                        </table>
                        <TableContainer
                            className='table-plan'
                            component={Paper}
                        >
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead className='pln_camp_table_header'>
                                    <TableRow>
                                        <TableCell>Email Id</TableCell>
                                        <TableCell>Sign Up</TableCell>
                                        <TableCell>
                                            Instagram Connected
                                        </TableCell>
                                        <TableCell>Youtube Connected</TableCell>
                                        <TableCell>Subscription Type</TableCell>
                                        <TableCell>Total Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <div className='row'>
                    <div className='col  _influencer_refer_earn_data  text-center'>
                        No Data
                    </div>
                </div>
            </div>

            <div className='row my-3 py-3 border-top border-bottom'>
                <strong className='fs-18'>Referred by a friend?</strong>
                <p className='fs-14 mb-3'>
                    Put your friend’s code here to get a 10% discount on annual
                    subscription. Your friend will also earn 20% of your annual
                    subscription.
                </p>
                <div className='col-md-6'>
                    <div className='input-w-btn fixed-right'>
                        <TextField
                            fullWidth
                            name='referral_code'
                            size='small'
                            type='text'
                            id='outlined-basic'
                            label=''
                            placeholder=''
                            variant='outlined'
                        />
                        <Button
                            variant='outlined'
                            className='btn btn-outline-primary'
                        >
                            Apply
                        </Button>
                    </div>
                </div>
                <div className='col-auto'></div>
            </div>
            <div className='row'>
                <div className='col'>
                    <strong>Refer and Earn Program</strong>
                    <p className='fs-14'>
                        You will earn with people who you refer with the
                        following conditions:
                    </p>
                    <ol className='fs-14'>
                        <li>
                            If they complete the signup process and have a{' '}
                            <strong>minimum of 2000</strong>{' '}
                            followers/subscribers on Instagram/YouTube.
                            Completing the signup process means providing the
                            required fields asked during the signup process
                            along with verifying their email ID and phone
                            number.
                        </li>
                        <li>
                            After sharing the code and once they sign up, they
                            must connect at least one of their social media
                            account and take a paid subscription{' '}
                            <strong>within 30 days of signing up.</strong>
                        </li>
                        <li>
                            Once they have connected their social media account,
                            they must not disconnect it for at least{' '}
                            <strong>30 days</strong> else they will be
                            disqualified.
                        </li>
                        <li>
                            When they connect their social media account,{' '}
                            <strong>₹ 50 will be credited</strong> to your
                            earnings. In case they connect both of their social
                            media accounts (Instagram and Youtube), a maximum of{' '}
                            <strong>₹ 100</strong> will be credited to your
                            earnings.
                        </li>
                        <li>
                            When they take an annual subscription of{' '}
                            <strong>₹ 499, 20%</strong> of their subscription
                            amount will be credited to your earnings.
                        </li>
                        <li>
                            Referral earnings are only given for the first
                            subscription taken within 30 days of sign up,
                            whether it's monthly or annual and any further
                            subscriptions taken after the first one will not be
                            eligible for referral payouts. For example: If
                            someone signs up and takes a monthly subscription on
                            the 10th day, and then an annual subscription on the
                            20th day, only the 10% referral payout for the
                            monthly subscription will be credited. The second
                            subscription i.e. the annual one, will not be
                            considered for referral payout.
                        </li>
                        <li>
                            All eligible referral payouts will be{' '}
                            <strong>reconciled after 30 days,</strong> of their
                            signing up, and eligible payouts will be done within
                            31st to 60th day subject to meeting minimum balance
                            requirement for withdrawal.
                        </li>
                        <li>
                            One should have a minimum of <strong>₹100</strong>{' '}
                            in the Clan Wallet to claim the amount earned via
                            Referrals.
                        </li>
                        <li>
                            A maximum of 14 working days will be taken to
                            process the Claim request.
                        </li>
                        <li>
                            By accepting the offer provided by the company, you
                            acknowledge and agree that the company reserves the
                            right to revoke the offer at its sole discretion.
                            This means that the company may withdraw the offer
                            at any time, without any prior notice, for any
                            reason whatsoever.The company's decision to revoke
                            the offer shall be final and binding, and you hereby
                            waive any claims, causes of action, or damages that
                            you may have against the company arising out of or
                            relating to the revocation of the offer.
                        </li>
                    </ol>
                    <strong>What do they get?</strong>
                    <p>
                        The people whom you refer get a 10% discount on annual
                        subscription if taken within 30 days of signing up.
                    </p>
                </div>
            </div>
        </div>
    );
};
