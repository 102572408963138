import { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
    Switch,
    Grid,
} from '@mui/material';
import defaultImage from '../../../assets/images/image-fill.svg';
import Button from '@mui/material/Button';
import './product-card.scss';
import { ClanShopService } from '../../../core/services/clan.shop.service';
import { LocalService } from '../../../core/services';
import { Link, useNavigate } from 'react-router-dom';
import {
    AddShoppingCart,
    CloseFullscreen,
    RemoveShoppingCart,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const localService = new LocalService();

interface Props {
    data: any;
    type: string;
    UserReducer: any;
    UserID: any;
    onProductClick?: (product: any) => void;
}

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ProductCard: React.FC<Props> = (props: Props) => {
    const [dataArrayState, setDataArrayState] = useState(props.data);
    const [productAlreadyAdded, setProductAlreadyAdded] = useState<any>();
    const [toggle, setToggle] = useState<any>(false);
    const [disableAddProduct, setDisableAddProduct] = useState<any>(false);
    const navigate = useNavigate();
    //   const userData = useSelector(
    //     (state: any) => state.UserReducer.userData
    // );
    // setProductData()
    const shop_id = localService.getShopId();
    const cardData = props?.data;
    useEffect(() => {
        setDataArrayState(cardData);
        ClanShopService.getShopProducts(shop_id)
            .then((res) => {
                setProductAlreadyAdded(res?.data?.rows);
            })
            .catch((e) => setProductAlreadyAdded([]));
    }, [cardData, dataArrayState]);

    const handleAddProducts = (data: any) => {
        // // Make sure product is not already added
        // const isProductAdded = productAlreadyAdded.some(
        //     (addedProduct: any) =>
        //         addedProduct.product_id === product.product_id
        // );

        // if (!isProductAdded) {
        //     // Add the product to the state
        //     setProductAlreadyAdded([...productAlreadyAdded, product]);
        // }
        const storedData: any = {};
        storedData['account_id'] = props?.UserReducer?.account_id;
        storedData['brand_id'] = data.brand_id;
        storedData['product_id'] = data.product_id;
        ClanShopService.addProductToShop(storedData)
            .then((res) => {
                if (res?.data.status.status) {
                    // setDataArrayState(dataArrayState)
                }
                // setBrandList(res?.data?.rows);
            })
            .catch((e) => console.log(e));
    };

    const handleRemoveProducts = (data: any) => {
        const storedData: any = {};
        storedData['shop_id'] = data.shop_id
            ? data.shop_id
            : localService.getShopId();
        storedData['account_id'] = props?.UserReducer?.account_id;
        storedData['brand_id'] = data.brand_id;
        storedData['product_id'] = data.product_id;
        ClanShopService.RemoveProductToShop(storedData)
            .then((res) => {
                const updatedProducts = dataArrayState.filter(
                    (product: any) => product.product_id !== data.product_id
                );
                if (props.type === 'influencer-shop') {
                    setDataArrayState(updatedProducts);
                }
            })
            .catch((e) => console.log(e));
    };

    const handleAvailaibleToShop = (itemId: any) => (event: any) => {
        if (event.target.checked) {
            navigate(`product_integeration`, { state: { itemId } });
        }
        // setToggle(true)
    };

    const handleProductClick = (product: any) => {
        if (props.onProductClick) {
            props.onProductClick(product);
        }
    };

    const handleAccessTimeIcon = () => {
        toast.info("product integeration Pending")
    }

    return (
        <>
            <div className='product-card-container'>
                <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                >
                    {dataArrayState?.map((product: any) => {
                        const isProductAdded = productAlreadyAdded?.find(
                            (addedProduct: any) =>
                                addedProduct.product_id === product.product_id
                        );

                        return (
                            <>
                                {(product.status === 'live' || props.type === 'all-shops') && (
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Card
                                            className='card-container'
                                            
                                        >
                                            {props.type === 'brand' && (
                                                product.product_integeration === 'Pending' ? <AccessTimeIcon className='card-switch' onClick={handleAccessTimeIcon}/>:
                                                
                                                
                                                <Switch
                                                    className='card-switch'
                                                    checked={
                                                        product.availaible_to_shop
                                                    }
                                                    onClick={handleAvailaibleToShop(
                                                        product
                                                    )}
                                                    {...label}
                                                />
                                            )}
                                            <CardMedia
                                                className='product-card-image'
                                                sx={{ height: 140 }}
                                                image={props.type === 'all-shops' ?  defaultImage:
                                                    product?.product_image_url
                                                }
                                                onClick={() =>
                                                    handleProductClick(product)
                                                }
                                                title='image'
                                            />
                                            <CardContent className='product-card-content'>
                                                <h3>
                                                    <span  className="card-name-span" onClick={() =>
                                                handleProductClick(product)
                                            }>
                                                    {props.type === 'all-shops' ?  product.shop_name
                                                        :product?.product_name}
                                                    </span>
                                                </h3>

                                                <p className='product-card-desc'>
                                                    {props.type === 'all-shops' ?  product.shop_description:
                                                        product?.product_description
                                                    }
                                                </p>
                                                {props.type !== 'all-shops' &&
                                                <div className='product-card-price-info'>
                                                    <span>
                                                        Price :{' '}
                                                        <strong>
                                                            &#8377;
                                                            {
                                                                product?.product_cost
                                                            }
                                                        </strong>
                                                    </span>
                                                    <span className='price-info-discount'>
                                                        Discount :{' '}
                                                        <strong>
                                                            {product?.discount}%
                                                        </strong>
                                                    </span>
                                                </div>
                    }
                                                <div className='product-action-btn'>
                                                    {props.type ===
                                                        'influencer' && (
                                                        <Button
                                                            variant='outlined'
                                                            className='btn btn-outline-primary me-2'
                                                            disabled={
                                                                isProductAdded
                                                            }
                                                            onClick={() =>
                                                                handleAddProducts(
                                                                    product
                                                                )
                                                            }
                                                            startIcon={
                                                                <AddShoppingCart />
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                    )}
                                                    {(props.type ===
                                                        'influencer-shop' ||
                                                        props.type ===
                                                            'influencer') && (
                                                        <Button
                                                            variant='outlined'
                                                            color='error'
                                                            className='btn btn-outline-orange'
                                                            disabled={
                                                                !isProductAdded
                                                            }
                                                            onClick={() =>
                                                                handleRemoveProducts(
                                                                    product
                                                                )
                                                            }
                                                            startIcon={
                                                                <RemoveShoppingCart />
                                                            }
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </>
                        );
                    })}
                </Grid>
            </div>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        UserID: state.UserID.userID,
    };
};

const connectToRedux = connect(mapStateToProps)(ProductCard);

export { connectToRedux as ProductCard };
