import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class SubscriptionService {
  async getPlanDetails(data: any) {
    const path = BASE_URL + "/get/subscription/plans/detail";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async getSubscription_Remaining_Days(data: any) {
    const path = BASE_URL + "/get/subscription/days/left";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async getSubscription_History(data: any) {
    const path = BASE_URL + "/get/subscription/history";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async getFocCampaignPlanDetail(data: any) {
    const path = BASE_URL + "/get/brand/foc/campaign_plan_details";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  // async getFocRepublishCampaignPlanDetail(data: any) {
  //   const path = BASE_URL + "/get/brand/foc/republish_campaign";
  //   const response = await http.postRequest(path, data, null);
  //   return response;
  // }

  async getSubscriptionInvoice(data: {token: string}) {
    try {
      const path = BASE_URL + `/get/subscription/invoice`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Request a Demo :: ${error}`);
    }
  }
}
