import React from 'react'

type Props = {}

const IconComment = (props: Props) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_8136_3210" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_8136_3210)">
            <path d="M20.5115 16.2615C20.8282 15.5847 21.0721 14.891 21.2433 14.1805C21.4145 13.4702 21.5 12.7433 21.5 12C21.5 10.6858 21.2507 9.45083 20.752 8.295C20.2534 7.13917 19.5766 6.13375 18.7218 5.27875C17.867 4.42375 16.8617 3.74692 15.706 3.24825C14.5504 2.74942 13.3156 2.5 12.0018 2.5C10.6878 2.5 9.4527 2.74933 8.29653 3.248C7.14037 3.74667 6.1347 4.42342 5.27954 5.27825C4.42437 6.13308 3.74729 7.13833 3.24829 8.294C2.74945 9.44967 2.50003 10.6844 2.50003 11.9983C2.50003 13.3123 2.74945 14.5473 3.24829 15.7035C3.74695 16.8597 4.42379 17.8653 5.27879 18.7205C6.13379 19.5757 7.1392 20.2528 8.29503 20.7518C9.45087 21.2506 10.6859 21.5 12 21.5C12.7434 21.5 13.4702 21.4144 14.1805 21.2432C14.891 21.0721 15.5847 20.8282 16.2615 20.5115L20.686 21.807C21.0287 21.9113 21.3273 21.8363 21.5818 21.5818C21.8363 21.3273 21.9114 21.0287 21.807 20.686L20.5115 16.2615ZM20.05 20.05L16.85 19.1C16.6039 19.0333 16.3664 19.0052 16.1375 19.0155C15.9087 19.0257 15.6795 19.0872 15.45 19.2C14.9167 19.4667 14.3584 19.6667 13.775 19.8C13.1917 19.9333 12.6 20 12 20C9.7667 20 7.87504 19.225 6.32504 17.675C4.77504 16.125 4.00003 14.2333 4.00003 12C4.00003 9.76667 4.77504 7.875 6.32504 6.325C7.87504 4.775 9.7667 4 12 4C14.2334 4 16.125 4.775 17.675 6.325C19.225 7.875 20 9.76667 20 12C20 12.6 19.9334 13.1917 19.8 13.775C19.6667 14.3583 19.4667 14.9167 19.2 15.45C19.0834 15.6667 19.0193 15.8958 19.0078 16.1375C18.9963 16.3792 19.027 16.6167 19.1 16.85L20.05 20.05Z" fill="white" />
        </g>
    </svg>

}
export default IconComment
