import React from 'react';
import { Outlet } from 'react-router-dom';
import { TalentPartnerHeader } from '../../components/header/header-talent-partner';

export const MainTalentPartner: React.FC = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
