
interface Props {
  className?: string;
}
const IconCategory: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >

      <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.38923 10.522L12.7338 0L19.0738 10.522H6.38923ZM21.3365 28C19.7386 28 18.4002 27.4448 17.3215 26.3345C16.2427 25.2241 15.7033 23.8543 15.7033 22.2249C15.7033 20.5955 16.2428 19.2309 17.3217 18.1308C18.4007 17.0308 19.7391 16.4808 21.337 16.4808C22.9349 16.4808 24.2782 17.0309 25.3669 18.1311C26.4556 19.2313 27 20.5961 27 22.2254C27 23.8548 26.4556 25.2246 25.3667 26.3347C24.2778 27.4449 22.9344 28 21.3365 28ZM0 27.1031V17.2362H9.67629V27.1031H0ZM21.3541 26.8067C22.5927 26.8067 23.6483 26.361 24.5209 25.4696C25.3935 24.5782 25.8298 23.4959 25.8298 22.2227C25.8298 20.9495 25.3927 19.8731 24.5185 18.9935C23.6443 18.1138 22.5829 17.674 21.3343 17.674C20.0857 17.674 19.0301 18.1157 18.1675 18.999C17.3048 19.8823 16.8735 20.9636 16.8735 22.2429C16.8735 23.5059 17.3066 24.5823 18.1729 25.4721C19.0391 26.3618 20.0995 26.8067 21.3541 26.8067ZM1.17024 25.9099H8.50608V18.4295H1.17024V25.9099ZM8.44795 9.32875H17.045L12.7338 2.32723L8.44795 9.32875Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconCategory;











