import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserLogin, SwitchLayer2, Loading } from '../../../redux/action';
import useBodyClass from '../../../hooks/useBodyClass';
// import ClanConnectLogo from '../../../assets/images/clan_connect_logo.png';
import { LocalService, LoginService } from '../../../core/services';
import './switch.scss';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const localService = new LocalService();
const loginService = new LoginService();

interface Props {
  UserReducer: (data: any) => void;
  SwitchUserData: (data: any) => void;
  switchLayer1: any;
  loading: (data: any) => void;
  activeAccount: any;
}

const SwitchAccounts: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [accountsList, setAccountList] = useState<any>(null);
  const [activeAccount, setActiveAccount] = useState<any>(null);
  const [activeUser, setActiveUser] = useState<Boolean>(false);
  useEffect(() => {
    setAccountList(props.switchLayer1);
    const useractive: any = localService.getStorageItem('userreducer');
    localService.setStorageItem( 'switchaccount',JSON.stringify(props.switchLayer1));
    if (accountsList) {
      if (props.switchLayer1.length === 1) {
        //if Account Length === 1 then Direct Navigate Home Page; (Pending Navigation)
        setActiveAccount(props.switchLayer1[0]);
      } else {
        props.loading(false);
      }
    }
  }, [accountsList, props.switchLayer1]);
  useEffect(() => {
    if (activeAccount) {
      const accountType = (activeAccount.account_type + '')
        .toString()
        .toLocaleLowerCase();
      async function get_Brand_Inf_Data() {
        let response: any;
        props.loading(true);
        try {
          if (accountType === 'influencer') {
            response = await loginService.getInfluencerAccounts({
              user_id: activeAccount.user_id,
              account_id: activeAccount.account_id,
            });
            let gcp_profile_image_url = '';
            if (response.data.rows.length > 0){
              // To get gcp_profile_image_url for header
              const gcp_profile_image_urls = response.data.rows.map((row: any) => row.gcp_profile_image_url).filter((url: string) => url !== '') ?? ['']

              if (gcp_profile_image_urls.length > 0){
                gcp_profile_image_url = gcp_profile_image_urls[gcp_profile_image_urls.length - 1]
              }
            }
            if (response.data.status > 0) {
              const userData = {
                ...activeAccount,
                influencer_ids: response.data.rows,
                influencer_id: response.data.rows[0]?.influencer_id,
                gcp_profile_image_url: gcp_profile_image_url
              };
              props.UserReducer(userData);
              navigate(`/influencer/dashboard`);
              //without remember me save user Data
              localService.setStorageItem(
                'userreducer',
                JSON.stringify(userData)
              );
            } else {
              localService.toastify(response.data.status.message);
            }
          } else if (accountType === 'brand') {
            response = await loginService.getBrandIds({
              user_id: activeAccount.user_id,
              account_id: activeAccount.account_id,
            });

            if (response.data.status.status) {
              const userData = {
                ...activeAccount,
                ...response.data.rows[0],
                influencer_id: response.data.rows[0].influencer_id
              };
              props.UserReducer(userData);

              navigate('/brand/dashboard');
              localService.setStorageItem(
                'userreducer',
                JSON.stringify(userData)
              );
            } else {
              localService.toastify(response.data.status.message);
            }
          } else if (
            accountType === 'agency' ||
            accountType === 'talent partner'
          ) {
            props.SwitchUserData(activeAccount);
            props.UserReducer(activeAccount);

            localService.setStorageItem(
              'activeaccount',
              JSON.stringify(activeAccount)
            );
            localService.setStorageItem(
              'userreducer',
              JSON.stringify(activeAccount)
            );
            navigate('/switch/user');
          }
        } catch (error) {
          throw new Error(
            `useEffect Switch account Promise Error ==> ${JSON.stringify(
              error
            )}`
          );
        } finally {
          props.loading(false);
        }
      }

      get_Brand_Inf_Data();
      setActiveUser(true);
    }
  }, [activeAccount]);

  useBodyClass('no-spacing');
  useBodyClass('switch-account');
  return (
    <>
      <div className='container-fluid _switch_container_fluid common-bg'>
        <div className='container _switch_container'>
          <div className='row _switch_main_row'>
            <div className='_switch_main_col row'>
              {(accountsList && accountsList.length > 1) &&
                accountsList.map((res: any, index: number) => {
                  return (
                    <div key={index} className='col-md-6'>
                      <div
                        className={res.account_id == props.activeAccount.account_id ? "_switch_details_cards active" : "_switch_details_cards"}
                        key={res.account_id}
                        onClick={() => setActiveAccount(res)}
                      >
                        {res.account_id == props.activeAccount.account_id ? (
                          <TaskAltIcon className='icon-check' />
                        ) : (
                          ''
                        )}
                        <img className='_switchImage' src={res.photo} />
                        <div className='_switchDetails'>
                          <span className='_switch-acc-info _switch_account_name p-0'>
                            {res.account_name}
                          </span>
                          <span className='_switch-acc-info  _switch_account_type'>
                            {res.account_type}
                          </span>
                          <span className='_switch-acc-info  _switch_full_name'>
                            {res.full_name}
                          </span>
                          <span className='_switch-acc-info  _switch_email_id'>
                            {res.email_primary}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    switchLayer1: state.SwitchLayer1Reducer.switchAccount,
    activeAccount: state.UserReducer.UserData,
  };
};

const mapStateToDispatch = (dispatch: any) => {
  return {
    UserReducer: (data: any) => dispatch(UserLogin(data)),
    SwitchUserData: (data: any) => dispatch(SwitchLayer2(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapStateToDispatch
)(SwitchAccounts);

export { connectToRedux as SwitchAccounts };
