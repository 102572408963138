import { useEffect, useState } from 'react';
import { ClanShopService } from '../../../core/services/clan.shop.service';
import { connect } from 'react-redux';
import { ProductCard } from './product-card';
import './clan-shop.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import HeadingStyle from '../../../components/heading-style/heading-style';

interface Props {
    UserReducer: any;
    UserID: any;
}

const BrandClanShop: React.FC<Props> = (props: Props) => {
    const [productData, setProductData] = useState<any>();
    const navigate = useNavigate();
    const [campaignProductData, setCampaignProductData] = useState<any>();

    const brand_id = props.UserReducer?.brand_id;
    useEffect(() => {
        ClanShopService.getBrandProductsData(`${brand_id}`)
            .then((res: any) => {
                setProductData(res?.data?.rows);
            })
            .catch((e: any) => setProductData([]));

        ClanShopService.getBrandCampaignProductsData(`${brand_id}`)
            .then((res: any) => {
                setCampaignProductData(res?.data?.rows);
            })
            .catch((e: any) => setCampaignProductData([]));
    }, []);

    const handleAddProduct = () => {
        const productData: any = {};
        productData['brand_id'] = brand_id;
        productData['brand'] = props.UserReducer.account_name;
        ClanShopService.brandAddProduct(productData)
            .then((res: any) => {
                if (res.data.status.status) {
                    const data: any = {
                        product_id: res.data.status.product_id,
                    };
                    navigate(`add-product`, { state: data });
                }
                // if(res)
                // setCampaignProductData(res?.data?.rows);
            })
            .catch((e: any) => console.log('err', e));
    };

    const handleAffiliate = () => {
        navigate(`affiliate-dashboard?brand_id=${brand_id}`)
    }

    return (
        <div className='brand-product-cards'>
            <div className='container mb-4'>
                <div className='d-flex brand-shop-heading'>
                    <HeadingStyle title='Products' className='' />
                    <div>
                    <Button
                        variant='outlined'
                        className='btn btn-outline-primary ms-auto'
                        onClick={handleAffiliate}
                    >
                        Affiliate
                    </Button>
                    <Button
                        variant='outlined'
                        className='btn btn-outline-primary ms-auto'
                        onClick={handleAddProduct}
                    >
                        Add Product
                    </Button>
                    </div>
                </div>
            </div>
            {/* <ProductCard data={productData}/> */}
            <div className='container'>
                <ProductCard data={productData} type='brand' />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        UserID: state.UserID.userID,
    };
};

const connectToRedux = connect(mapStateToProps)(BrandClanShop);

export { connectToRedux as BrandClanShop };
