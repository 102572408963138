import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.scss';

interface Props {
    getEditorValue: (data: any) => void;
    editorValues: any;
}

export const Editor: React.FC<Props> = (props: Props) => {

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ size: [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ align: ["right", "center", "justify"] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            // ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'align',
        'indent',
        'link',
        'image',
        'video'
    ]

    return (

        <div className='editor_components' >
            <ReactQuill className='editor'
                onChange={props.getEditorValue}
                value={props.editorValues}
                modules={modules}
                formats={formats}
                placeholder="Write Some Text Here ..."
            />
        </div>
    )
};