import React, { useState } from "react";
import "./../../brand/admin/influencer-database/influencer-database-home.scss";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HeadingStyle from "../../../components/heading-style/heading-style";
// import InstagramIcon from "@mui/icons-material/Instagram";
import InstagramIcon from "../../../components/icons/instagram-icon";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ProfileInsight } from "../../brand/profile-insight/profile-insight";
import { YoutubeProfileInsight } from "./yt-profile-insight";


export const ProfileViewHome: React.FC = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <section className="inf-database-section">

      <div className="influncer_heading">
        <div className='container'>
          <HeadingStyle title="Profile View" className="mb-4 d-block" />
        </div>
      </div>

      <TabContext value={value}>
        <div className='container'>
          <TabList className='nav sub-tabs no-scrollbar' onChange={handleChange} aria-label="lab API tabs example"
            // sx={{
            //   [`& .${tabsClasses.scrollButtons}`]: {
            //     '&.MuiTabScrollButton-root': {
            //       borderRadius: '20px',
            //       height: '24px',
            //       border: '1px solid #000',
            //       width: '24px',
            //       transition: 'box-shadow 0.3s cubic-bezier(0.2,0,0,1), transform 0.3s  cubic-bezier(0.2,0,0,1)',
            //       position: 'relative',
            //       zIndex: 1,
            //       left: '1px',
            //       right: "1px",
            //       '&:hover': {
            //         boxShadow: '0 0 5px #ddd',
            //         transform: 'scale(1.02)'
            //       },
            //     },
            //   },
            // }}
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab
              label="Instagram"
              icon={<InstagramIcon width={25} height={25} />}
              iconPosition="start"
              className={`${value === "1" ? 'nav-link active' : "nav-link"}`}
              value="1"
              style={{
                textTransform: "none",
                fontSize: "16px",
              }}
            />
            <Tab
              label="YouTube"
              icon={
                <YouTubeIcon style={{ color: "red", fontSize: "30px" }} />
              }
              iconPosition="start"
              className={`${value === "2" ? 'nav-link active' : "nav-link"}`}
              value="2"
              style={{
                textTransform: "none",
                fontSize: "16px",
              }}
            />
          </TabList>
        </div>
        <TabPanel value="1">

          <ProfileInsight />
        </TabPanel>
        <TabPanel value="2">
          <YoutubeProfileInsight />
        </TabPanel>
      </TabContext>

    </section>
  );
};
