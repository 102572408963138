import React, { useEffect, useState } from 'react';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';
import HeadingStyle from '../../../../components/heading-style/heading-style';
import { Tab, Tabs } from '@mui/material';
import { connect } from 'react-redux';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';

interface Props {
    UserReducer: any;
}
const AdminDashboard: React.FC<Props> = (props: Props) => {
    let navigate = useNavigate();
    const [selection, setSelection] = useState('influencer-signups');
    const location = useLocation();

    useEffect(() => {
        navigate(`${selection}`);
    }, [selection]);

    return (
        <div className='profile_settings_container'>
            <div className='container-fluid'>
                <div className='row profile_settings_main_container'>
                    <Tabs
                        value={selection}
                        onChange={(e, v) => setSelection(v)}
                        aria-label='nav tabs example'
                        variant='scrollable'
                        scrollButtons='auto'
                        allowScrollButtonsMobile
                        className='d-flex d-md-none profile-menu-mobile tab-underline'
                    >

                        <Tab
                            className='tab-underline-menu'
                            label='Sign Up'
                            value='influencer-signups'
                        />

                        <Tab
                            className='tab-underline-menu'
                            label='UserBase'
                            value='userbase'
                        />

                        <Tab
                            className='tab-underline-menu'
                            label='Verifications'
                            value='campaign-verification'
                        />
                        <Tab
                            className='tab-underline-menu'
                            label='Platform'
                            value='campaign-details'
                        />
                        {(() => {
                            if (props.UserReducer?.view_influencer_db == "True") {
                                return (
                                    <Tab
                                        className='tab-underline-menu'
                                        label='Influencer Database'
                                        value='influencer-db'
                                    />)
                            }
                        })()}

                        {(() => {
                            if (props.UserReducer?.create_user == "True") {
                                return (
                                    <Tab
                                        className='tab-underline-menu'
                                        label='Crud'
                                        value='crud'
                                    />)
                            }
                        })()}

                        {(() => {
                                if (props?.UserReducer?.user_role_id == 1 || props?.UserReducer?.user_role_id == 2) {
                                    return (
                            <Tab
                                className='tab-underline-menu'
                                label='Agency Brands'
                                value='agency-brands'
                            />)
                                }
                        })()}
                    </Tabs>
                    <div className='profile_settings_left_side list-section-sidebar d-none d-md-flex collapse-sidebar'>
                        <ul>
                            <li>
                                <GroupOutlinedIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link '}
                                    to={location.pathname.includes('brand-signups') ? 'brand-signups' : 'influencer-signups'}
                                >
                                    Sign Up
                                </NavLink>
                            </li>
                            <li>
                                <SupervisedUserCircleOutlinedIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link'}
                                    to={location.pathname.includes('userbrief') ? 'userbrief' : 'userbase'}
                                >
                                    UserBase
                                </NavLink>
                            </li>
                            <li>
                                <VerifiedUserIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link'}
                                    to={location.pathname.includes('talent-verification') ? 'talent-verification' : 'campaign-verification'}
                                >
                                    Verifications
                                </NavLink>
                            </li>
                            <li>
                                <SupervisedUserCircleOutlinedIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link'}
                                    to={location.pathname.includes('disconnect-feedbacks') ? 'disconnect-feedbacks' : 'campaign-details'}
                                >
                                    Platform
                                </NavLink>
                            </li>
                            <li>
                                <BackupTableOutlinedIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link'}
                                    to={'influencer-db'}
                                >
                                    Influencer Database
                                </NavLink>
                            </li>
                            <li>
                                <EditNoteOutlinedIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link'}
                                    to={'crud'}
                                >
                                    Crud
                                </NavLink>
                            </li>
                            {(() => {
                            if (props?.UserReducer?.user_role_id == 1 || props?.UserReducer?.user_role_id == 2) {
                                return (
                            <li>
                                <BrandingWatermarkOutlinedIcon fontSize='medium' />
                                <NavLink
                                    className={'list-section-sidebar-link collapse-link'}
                                    to={'agency-brands'}
                                >
                                    Agency Brands
                                </NavLink>
                            </li> )
                                }
                            })()}
                        </ul>
                    </div>

                    <div className='profile_settings_right_side'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};

const connectToRedux = connect(
    mapStateToProps
)(AdminDashboard);

export { connectToRedux as AdminDashboard };


