interface Props {
  className?: string;
}
const IconCampaignDetail: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.5 14.8111V13.1893H35.5448V14.8111H29.5ZM31.6181 27.3431L26.765 23.7211L27.7725 22.4433L32.6256 26.0654L31.6181 27.3431ZM27.6717 5.4022L26.6643 4.12441L31.4707 0.509766L32.4782 1.78756L27.6717 5.4022ZM5.68906 25.4265V18.1284H3.25633C2.49254 18.1284 1.83473 17.8525 1.28289 17.3006C0.731014 16.7487 0.455078 16.0909 0.455078 15.3271V12.6732C0.455078 11.9094 0.731014 11.2516 1.28289 10.6998C1.83473 10.1479 2.49254 9.87196 3.25633 9.87196H10.7019L18.2948 5.37518V22.6252L10.7019 18.1284H7.31085V25.4265H5.68906ZM16.673 19.7477V8.25262L11.1466 11.4938H3.25633C2.96149 11.4938 2.69119 11.6166 2.44544 11.8623C2.19972 12.1081 2.07687 12.3784 2.07687 12.6732V15.3271C2.07687 15.622 2.19972 15.8923 2.44544 16.138C2.69119 16.3837 2.96149 16.5066 3.25633 16.5066H11.1466L16.673 19.7477ZM21.6858 19.5364V8.46393C22.3272 9.05861 22.8438 9.83941 23.2358 10.8063C23.6277 11.7733 23.8237 12.8379 23.8237 14.0002C23.8237 15.1625 23.6277 16.2271 23.2358 17.194C22.8438 18.1609 22.3272 18.9417 21.6858 19.5364Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconCampaignDetail;






