import React, { useEffect, useState } from 'react'
import CustomDrawer from '../custom-drawer/custom-drawer'
import { LoadingButton } from '@mui/lab';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText } from '@mui/material';
// import UseAnimations from 'react-useanimations';
// import arrowLeftCircle from 'react-useanimations/lib/arrowLeftCircle';

type Props = {
  openPopup: any;
  confirmClosePopup: any;
  drawerTitle: any;
  extraClass?: any;
  confirmClickAction: (formData: MarkCompleteForm) => Promise<void>;
  // confirmPopupInnerText: any;
  selectedCampaignData: any;
}

type MarkCompleteForm = {
  reason: string;
  status: string;
}

type MarkCompleteFormErrorField = {
  status: boolean;
  message: string;
}

type MarkCompleteFormError = {
  reason: MarkCompleteFormErrorField;
}

function MarkCompletePopup({ openPopup, confirmClosePopup, drawerTitle, extraClass, confirmClickAction, selectedCampaignData }: Props) {

  const [formData, setFormData] = useState<MarkCompleteForm>({
    reason: '',
    status: '',
  });

  const [formError, setFormErrors] = useState<MarkCompleteFormError>({
    reason: { status: false, message: 'Please select 1 reason!' }
  });

  const [consent, setConsent] = useState(false);

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, reason: (event.target as HTMLInputElement).value });
    // validateForm();
  };

  const validateForm = () => {
    if (!formData.reason) {
      setFormErrors({
        reason: { status: true, message: 'Please select 1 reason!' }
      });
      return false;
    } else {
      setFormErrors({
        reason: { status: false, message: '' }
      });
      return true;
    }
  };

  const confirmAction = async (status: string) => {
    // await confirmClickAction({status: status, reason: formData.reason}); // Perform the confirm action
    // confirmClosePopup(); // Close the popup after confirming action
    if (validateForm()) {
      setFormData({ ...formData, status: status })
    }
  }

  // const clearSelection = async () => {
  //   setFormData({status: '', reason: ''});
  // }

  const setConsentandProceed = async () => {
    setConsent(true);
    setFormErrors({
      reason: { status: false, message: '' }
    });
  }

  // useEffect(() => {
  //   const confirmTextElement = document.getElementById('confirm-text');
  //   if (confirmTextElement) {
  //     confirmTextElement.innerHTML = confirmPopupInnerText;
  //   }
  // }, [confirmPopupInnerText]);

  useEffect(() => {
    validateForm();
  }, [formData.reason]);

  return (
    <CustomDrawer
      open={openPopup}
      onClose={confirmClosePopup}
      drawerTitle={drawerTitle}
      className={`popup-confirm ${extraClass}`}
    >
      <div className='custom-drawer-body'>
        <div className='custom-drawer-body-inner pb-0'>
          <div className='confirm-body mb-0'>
            <div className='d-flex flex-column'>
              <span className="material-symbols-fw-200 material-symbols-rounded confirm-icon">
                help
              </span>
              <strong className='confirm-title'>Confirm </strong>
            </div>
            {/* <p>Are you sure want to accept the proposal?</p> */}
            {/* <p id='confirm-text'>{confirmPopupInnerText}</p> */}
            {/* {children} */}
            {(!consent)
              ?
              <>
                <p className='pb-3'>Before you proceed, please note that the analytics for the added links (if any) will not be updated<br /> once the campaign is archived or marked as complete.</p>
                <div
                  className='d-flex justify-content-end custom-drawer-footer'
                  style={{ borderTop: '1px solid #d7d7d7' }}
                >
                

                <LoadingButton
                  className='btn btn-outline-primary btn-lg'
                  // loading={loading}
                  loadingPosition='start'
                  variant='contained'
                  onClick={confirmClosePopup}
                  style={{ marginLeft: '10px' }}
                >
                  Cancel
                </LoadingButton>
                  <LoadingButton
                    className='btn btn-primary btn-lg'
                    // loading={loading}
                    loadingPosition='start'
                    variant='contained'
                    onClick={() => setConsentandProceed()}
                    style={{ marginLeft: '10px' }}
                  >
                    Proceed
                  </LoadingButton>
                </div>
              </>
              :
              <>
                {
                  formData.status === ''
                  &&
                  <>
                    {/* <UseAnimations
                          animation={arrowLeftCircle}
                          onClick={() => {
                            clearSelection();
                          }}
                          size={40}
                          className='cursor-pointer dummyd'
                        /> */}
                    <FormControl className='mb-4' component="fieldset">
                      <span>
                        You can either archive or mark the campaign as complete. Please select the reason for your choice:
                      </span>
                      <RadioGroup
                        aria-label="campaign-options"
                        name="campaign-options"
                        value={formData.reason}
                        onChange={handleReasonChange}
                      >
                        <FormControlLabel value="Complete" control={<Radio />} label="The campaign is complete." />
                        <FormControlLabel value="Unavailability" control={<Radio />} label="I couldn't complete the campaign due to unavailability of influencers." />
                        <FormControlLabel value="Other" control={<Radio />} label="Other" />
                      </RadioGroup>
                      {formError.reason.status && (
                        <FormHelperText error>{formError.reason.message}</FormHelperText>
                      )}
                    </FormControl>
                    {/* <p>The analytics for the added links (if any) will not be updated once the campaign is archived or marked as complete.</p> */}
                    <div
                      className='d-flex justify-content-end custom-drawer-footer'
                      style={{ borderTop: '1px solid #d7d7d7' }}
                    >
                      <LoadingButton
                        className='btn btn-outline-primary btn-lg'
                        // loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        onClick={() => confirmAction('Archived')}
                        style={{ marginLeft: '10px' }}
                      >
                        Archive
                      </LoadingButton>
                      <LoadingButton
                        className='btn btn-primary btn-lg'
                        // loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        onClick={() => confirmAction('Completed')}
                        style={{ marginLeft: '10px' }}
                      >
                        Mark complete
                      </LoadingButton>
                    </div>
                  </>
                }

                {
                  formData.status === 'Completed'
                  &&
                  <>
                    <p className='pb-4'>Are you sure you want to mark the campaign <strong>{selectedCampaignData['Title']}</strong> as complete?<br/> This action cannot be undone.</p>
                    <div
                      className='d-flex justify-content-end custom-drawer-footer'
                      style={{ borderTop: '1px solid #d7d7d7' }}
                    >
                      <LoadingButton
                        className='btn btn-outline-primary btn-lg'
                        // loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        onClick={confirmClosePopup}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </LoadingButton>
                    <LoadingButton
                      className='btn btn-primary btn-lg'
                      // loading={loading}
                      loadingPosition='start'
                      variant='contained'
                      onClick={() => confirmClickAction(formData)}
                      style={{ marginLeft: '10px' }}
                    >
                      Proceed
                    </LoadingButton>

                   
                    </div>
                  </>
                }

                {
                  formData.status === 'Archived'
                  &&
                  <div>
                    <p className='pb-4'>Are you sure you want to archive the campaign <strong>{selectedCampaignData['Title']}</strong>?<br/> You can unarchive it any time.</p>
                    <div
                      className='d-flex justify-content-end custom-drawer-footer'
                      style={{ borderTop: '1px solid #d7d7d7' }}
                    >
                        <LoadingButton
                          className='btn btn-outline-primary btn-lg'
                          // loading={loading}
                          loadingPosition='start'
                          variant='contained'
                          onClick={confirmClosePopup}
                          style={{ marginLeft: '10px' }}
                        >
                          Cancel
                        </LoadingButton>
                      <LoadingButton
                        className='btn btn-primary btn-lg'
                        // loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        onClick={() => confirmClickAction(formData)}
                        style={{ marginLeft: '10px' }}
                      >
                        Confirm
                      </LoadingButton>

                      
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
        {/* <div
          className='d-flex justify-content-end custom-drawer-footer'
          style={{ borderTop: '1px solid #d7d7d7' }}
        >
          <LoadingButton
            className='btn btn-primary btn-lg'
            // loading={loading}
            loadingPosition='start'
            variant='contained'
            onClick={confirmAction}
            style={{ marginLeft: '10px' }}
          >
            Confirm
          </LoadingButton>

        </div> */}
      </div>
    </CustomDrawer>
  )
}

export default MarkCompletePopup