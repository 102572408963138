import React from 'react';

type Props = {};

export default function BrandAgencyContent({}: Props) {
    return (
        <>
            <span className='_influencer-content-sec-title pb-3 d-none d-lg-block'>
                Welcome!
            </span>
            <div className='signup-text-sec'>
                <div className='brand-info-box'>
                    <ul id='brand-info-box-ul'>
                        <li>
                            <strong>ZERO</strong> management/platform fees
                        </li>
                        <li>
                            Engage with <strong>UNLIMITED</strong> influencers.
                        </li>
                        <li>
                            Authentic influencer insights from{' '}
                            <strong>DIRECT APIs</strong>.
                        </li>
                        <li>
                            Generate <strong>REALTIME</strong> campaign
                            analytics.
                        </li>
                        <li>
                            <strong>NO-HIDDEN</strong> charges, no riders.
                        </li>
                        <li>
                            <strong>MAXIMUM ROI </strong> with 100% transparent
                            model.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
