import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClanShopService } from "../../core/services/clan.shop.service";
import Moment from "react-moment";
import "./order-details.scss";

const OrderDetails = () => {
  const [orderData, setOrderData] = useState<any>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const order_id = searchParams.get("order_id");

  useEffect(() => {
    ClanShopService.getOrderDetails(order_id)
      .then((res) => {
        if (res?.data?.rows) {
          setOrderData(res?.data?.rows);
        }
      })
      .catch((error) => {
        setOrderData([]);
      });
  }, []);

  return (
    <div className="order-details-page">
      {orderData?.map((data: any) => {
        return (
          <>
            <h3>
              Delivery Done on{" "}
              <Moment
                date={new Date().setDate(new Date().getDate() + 4)}
                format="MMMM Do YYYY"
              ></Moment>
            </h3>
            <div className="order-deliver-details">
              <div className="delivery-block">
                <span>
                  <strong>Delivery Details</strong>
                </span>
                <br />
                <span>{data?.user_name}</span>
                <br />
                <span>
                  {data?.house_no}, {data?.area}, <br /> {data?.post_office},{" "}
                  {data?.district}, <br /> {data?.city}, {data?.state}, <br />{" "}
                  pincode - {data?.pincode}
                </span>
              </div>
              <div className="order-block">
                <span>
                  <strong>Order Details</strong>
                </span>
                <br />
                <span>
                  <strong>Buyer :</strong>
                  {data?.user_name}
                </span>
                <br />
                <span>
                  <strong>Product :</strong>
                  {data?.product_name}
                </span>
                <br />
                <span>
                  <strong>Price :</strong>
                  {data?.product_cost}
                </span>
                <br />
              </div>
            </div>
            <Link className="cancel-order-link" to={""}>
              Cancel Order
            </Link>
          </>
        );
      })}
    </div>
  );
};

export default OrderDetails;
