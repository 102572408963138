import React, { useEffect, useState, useRef } from "react";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CurrencyRupee } from "@mui/icons-material";

import {
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { Loading } from "../../../redux/action";
import { InvoiceService, LocalService } from "../../../core/services";
import dayjs from "dayjs";
import { FormValidation, Methods, Numbers } from "../../../core/utility";
import "./invoice.scss";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconRejected from "../../../components/icons/icon-rejected";
import { LoadingButton } from "@mui/lab";
import { InvoiceTableComponent } from "./invoice-table";

const invoiceService: InvoiceService = new InvoiceService();
const numberFormat: Numbers = new Numbers();
const methods: Methods = new Methods();
const localService = new LocalService();
const formValidation = new FormValidation();

interface Props {
  UserReducer: any;
  loading: (data: boolean) => void;
}

const PrintInvoice: React.FC<Props> = ({ UserReducer }: Props) => {
  const navigate = useNavigate();
  const [deliverables, setDeliverables] = useState<any>(null);
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
  const [calc, setCalc] = useState({
    subtotal: 0,
    igst: 0,
    sgst: 0,
    cgst: 0,
    total: 0,
  });
  const [editableField, setEditableField] = useState<any>({
    invoiceNo: "",
    invoiceDate: "",
  });
  const [editableFieldError, setEditableFieldError] = useState<any>({
    invoiceNo: {status: false, message: ""}
  })
  const [editInvoiceDate, setEditInvoiceDate] = useState(false)
  const [editInvoiceNumber, setEditInvoiceNumber] = useState(false)
  const [enableDownload, setEnableDownload] = useState(false);
  const [enableSend, setEnableSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<File | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [invoiceSent, setInvoiceSent] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');

  const [editAllowed, setEditAllowed] = useState(false);

  useEffect(() => {
    // console.log("deliverables ==> ", deliverables);
    // console.log("invoiceDetails ==> ", invoiceDetails);
  }, [deliverables, invoiceDetails]);


  const getInvoice = async () => {
    let getParams = new URLSearchParams(window.location.search).get('id');
    setInvoiceId(getParams ?? '');
    try {
      if (getParams) {
        const response: any = await invoiceService.getGeneratedInvoice(
          getParams
        );
        if (response.data.status.status) {
          setDeliverables(response.data.rows.deliverable_item);
          setInvoiceDetails(response.data.rows.invoiceDetails[0]);
          setEditableField({invoiceNo: response.data.rows.invoiceDetails[0].invoice_number, invoiceDate: response.data.rows.invoiceDetails[0].invoice_dt})

          if (![0, ''].includes(response.data.rows.invoiceDetails[0].invoice_id) ){
            setEditAllowed(false);
          }else{
            setEditAllowed(true);
          }
          //calc igst, cgst, sgst
          for (let data of response.data.rows.deliverable_item) {
            setCalc((prev) => {
              prev.cgst = prev.cgst + (data.dynamic_price * 9) / 100;
              prev.sgst = prev.sgst + (data.dynamic_price * 9) / 100;
              prev.igst = prev.igst + (data.dynamic_price * 18) / 100;
              prev.subtotal = prev.subtotal + data.dynamic_price;
              return { ...prev };
            });
          }
        }
      } else {
        handleNavigation();
      }
    } catch (error) {
      throw new Error(`Error getting invoice :: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    setCalc({
      subtotal: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      total: 0,
    })
    getInvoice();
  }, []);

  //if Some issue the navigate other route
  const handleNavigation = () => {
    const account_type = UserReducer.account_type
      .toString()
      .toLowerCase()
      .trim();
    let navLink = "";
    if (account_type === "influencer" || account_type === "talent partner") {
      navLink = "/influencer/dashboard";
    } else if (account_type === "brand" || account_type === "agency") {
      navLink = "/brand/dashboard";
    }
    navigate(navLink);
  };

  //Update the generated invoice

  async function updateGeneratedInvoice() {
    setLoading(true)
    try {
      const formData = new FormData();
      if (editableField.invoiceNo !== ''){
        let getParams = new URLSearchParams(window.location.search).get("id");

        if (!getParams) {
          throw new Error("Parameter 'id' not found in the URL.");
        }

        formData.append('id', getParams);
        formData.append('invoiceNo', editableField.invoiceNo);
        formData.append('invoiceDate', editableField.invoiceDate);

        const response: any = await invoiceService.updateInvoice(formData);
        const toastType = response.data.status.status ? "success" : "error";
        localService.toastify(response.data.status?.message, toastType, 1000);
        if (toastType === "success") {
          setCalc({
            subtotal: 0,
            igst: 0,
            sgst: 0,
            cgst: 0,
            total: 0,
          })
          getInvoice()
          setEditableField({
            invoiceNo: "",
            invoiceDate: ""
          });
          setEditInvoiceDate(false)
          setEditInvoiceNumber(false)
        }
      }
      setLoading(false)
    } catch (error) {
      throw new Error(`Error updating invoice :: ${JSON.stringify(error)}`);
    }
  }

  /*...............................Handle Invoice Edit Cancel & Other Icons................ */
  const handleInvoiceDate = () => {
    setEditInvoiceDate(true)
  }

  const handleInvoiceNumber = () => {
    setEditInvoiceNumber(true)
  }

  const handleCrossIcon = (type: any) => {
    if (type === 'date') {
      setEditInvoiceDate(false);
    } else if (type === 'number') {
      setEditInvoiceNumber(false);
    }
  };

  /*...............................Generate PDF................ */
  const options = {
    // default is `save`
    method: "build" as const,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: 5,
      // default is 'A4'
      format: 'A3',
      // default is 'portrait'
      orientation: 'portrait' as const,
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg' as const,
      qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };

  const generateInvoicePdf = async (actionType: ('Send' | 'Download')) => {
    setLoading(true);
    try {
      const elements = document.getElementById('invoice-main');
      elements?.classList.add('download-invoice');

      const getTargetElement = () => elements;
      const pdfInstance = await generatePDF(getTargetElement, options);
      // console.log(pdfInstance)
      const blob = pdfInstance.output('blob');
      const generatedFile = new File([blob], 'invoice.pdf', { type: 'application/pdf' });


      if (actionType === "Send") {
        setEnableSend(false);
        // console.log('called Genrate Invoice ==> ', enableSend)
        // setSelectedInvoice(generatedFile);
        await sendInvoice(generatedFile);
      }

      if (actionType === "Download") {
        window.open(URL.createObjectURL(blob), '_blank');
      }

      elements?.classList.remove('download-invoice');

    } catch (error) {
      console.log('Error generating PDF:', error);
      throw new Error('Error generating PDF.');
    } finally {
      setLoading(false);

    }
  }



  // useEffect(() => {
  //   if (enableDownload) {
  //     generateInvoicePdf();
  //   }

  //   console.log('enable download ==> ', enableDownload)
  // }, [enableDownload])



  // useEffect(() => {
  //   if (enableSend) {
  //     generateInvoicePdf();
  //   }

  //   console.log('enable send  ==> ', enableSend)
  // }, [enableSend])


  //  /*...............................Send Invoice to Brand......................*/

  const sendInvoice = async (invoiceFile: File) => {
    const formData = new FormData();
    setLoading(true);

    // console.log('process send Invoice ==> ', enableSend)
    try {

      if (invoiceFile) {
        // const email = UserReducer.email_primary;
        // let getParams = new URLSearchParams(window.location.search).get('id');
        // formData.append('email', email);
        // Invoice data
        formData.append('invoice_id', invoiceId);
        formData.append('file', invoiceFile);
        formData.append('path', `accounts/${UserReducer.account_id}/campaign_invoices`)
        // User + proposal data
        formData.append('user_id', UserReducer.user_id);
        formData.append('account_id', UserReducer.account_id);
        formData.append('influencer_id', UserReducer.influencer_id);
        formData.append('sm_id', invoiceDetails.sm_id);
        formData.append('campaign_id', invoiceDetails.campaign_id);
        formData.append('proposal_id', invoiceDetails.proposal_id);

        const response = await invoiceService.sendInvoice(formData);
        const toastType = response.data.status.message ? "success" : "error";
        setInvoiceSent(false)
        localService.toastify(response.data.status?.message, toastType, 1000);
        setCalc({
          subtotal: 0,
          igst: 0,
          sgst: 0,
          cgst: 0,
          total: 0,
        })
        getInvoice();
      } else {
        console.error("No invoice to send");
      }
    } catch (error) {
      console.log('Error Sending Invoice:', error);
      throw new Error('Error Sending Invoice.');
    }
    finally {
      setEnableSend(false)
      setLoading(false);
    }
  };

  //Invoice Column Name
  const invoiceColumnName = [
    {
      name: "S.NO"
    },
    {
      name: "ITEMS / SERVICES"
    },
    {
      name: "HSN / SAC"
    },
    {
      name: "QTY"
    },
    {
      name: "RATE"
    },
    {
      name: "TAX"
    },
    {
      name: "AMOUNT"
    }
  ];
  //Package Invoice Column Name
  const packageInvoiceColumnName = [
    {
      name: "S.NO"
    },
    {
      name: "ITEMS / SERVICES"
    },
    {
      name: "HSN / SAC"
    },
    {
      name: "QTY"
    }
  ];

  function deliverableItemName(deliverableItem: string) {
    switch (deliverableItem) {
      case "ig_reel":
        return "Reel";
      case "ig_story":
        return "Story";
      case "ig_carousel_image":
        return "Carousel";
      case "ig_static":
        return "Static Post";
      case "available_for_exclusivity":
        return `Exclusivity For ${deliverables.find((item: any) => item.deliverable_item === 'available_for_exclusivity').dynamic_qty} Days`;
      case "digital_rights":
        return `Digital Rights For ${deliverables.find((item: any) => item.deliverable_item === 'digital_rights').dynamic_qty} Days`;
      case "yt_shorts":
        return "Shorts";
      case "yt_dedicated_video":
        return "Dedicated Video";
      case "yt_integrated_video":
        return "Integrated Video";
      default:
        return "Deliverable"; // or a default value if needed
    }
  }

  function handleInvoiceNumberEdit(e: any){
    setEditableField({
      ...editableField,
      invoiceNo: e.target.value
    })
    if (e.target.value === ''){
      setEditableFieldError({invoiceNo: {status: true, message: 'Invoice Number is required'}})
    }else{
      setEditableFieldError({invoiceNo: {status: false, message: ''}})
    }
  }

  return (
    <>
      {invoiceSent ? (
        <div className="sending-invoice-text">Sending...</div>
      ) : (
        <div className="invoice-main-container">
          {deliverables && (
            // <div ref={targetRef} className="invoice-container" id="invoice-main">
            <div ref={targetRef} className="invoice-container " id='invoice-main'>
              <div className="row ">
                <div className="col">
                  <div className="row">
                    <div className="col-auto">
                      <img
                        className="contract_logo"
                        src={invoiceDetails.contract_logo_url}
                        alt="logo"
                      />
                    </div>
                    <div className="col">
                      <h3><strong></strong>{invoiceDetails.contract_organization}</h3>
                      <ul className="contract-details">
                        <li>{invoiceDetails.contract_address}</li>
                        <li>{invoiceDetails.contract_gstin}</li>
                        <li>{invoiceDetails.contract_state}</li>
                        <li>{invoiceDetails.contract_pan}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col border-left item-middle">
                  <div className="d-flex">
                    <span className="pe-4">
                      <strong>Invoice No:</strong>
                    </span>
                    <div className="d-flex" style={{minWidth:'100px'}}>
                      {editInvoiceNumber ? (
                        <TextField
                          id="InvoiceNumber"
                          required
                          className="invoice-number-field"
                          label=""
                          defaultValue=""
                          variant="standard"
                          size="small"
                          name="invoice_no"
                          value={editableField.invoiceNo}
                          onChange={(e) =>
                            handleInvoiceNumberEdit(e)
                          }
                          error={editableFieldError?.invoiceNo?.status}
                          helperText={
                            editableFieldError?.invoiceNo?.message
                          }
                        />
                      ) : (invoiceDetails.invoice_number)}
                      {!editInvoiceNumber && editAllowed && (
                        <span className='icon-accepted ' onClick={handleInvoiceNumber}>
                          <span className="material-symbols-rounded invoice-edit-icon">edit</span>
                        </span>
                      )}
                      {editInvoiceNumber ? (
                        <>
                          <span className=' icon-rejected' onClick={() => handleCrossIcon('number')} >
                            <IconRejected />
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="pe-4">
                      <strong>Invoice Date:</strong>
                    </span>
                      <div className="d-flex" style={{ minWidth: '100px' }}>
                      {editInvoiceDate ? (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}                          >
                          <MobileDatePicker
                            className='input-style-date invoice-date'
                            format='DD-MM-YYYY'
                            maxDate={dayjs(new Date())}
                            defaultValue={dayjs(editableField.invoiceDate)}
                            onChange={(e: any) => setEditableField((prev: any) => ({ ...prev, invoiceDate: e?.format('YYYY-MM-DD') }))}
                          />
                        </LocalizationProvider>
                      ) : (dayjs(invoiceDetails.invoice_dt).format('DD-MMMM-YYYY'))}
                      {!editInvoiceDate && editAllowed && (
                        <span className=' icon-accepted' onClick={handleInvoiceDate}>
                          <span className="material-symbols-rounded invoice-edit-icon">edit</span>
                        </span>)}
                      {editInvoiceDate ? (
                        <>
                          <span className=' icon-rejected' onClick={() => handleCrossIcon('date')} >
                            <IconRejected />
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="horizontal-border" />
              <div className="row mt-2">
                <div className="col">
                  <table>
                    <tr>
                      <th colSpan={2}>
                        <strong>BILL TO</strong>
                      </th>
                      {/* <th></th> */}
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <strong>
                          {(
                            invoiceDetails.shipping_organization + ""
                          ).toLocaleUpperCase()}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="content-width">Address :</td>
                      <td>{invoiceDetails.shipping_address}</td>
                    </tr>
                    <tr>
                      <td>GSTIN :</td>
                      <td>{invoiceDetails.shipping_gstin}</td>
                    </tr>
                    <tr>
                      <td>State :</td>
                      <td>{invoiceDetails.shipping_state}</td>
                    </tr>
                    <tr>
                      <td>Mobile :</td>
                      <td>{invoiceDetails.shipping_mobile}</td>
                    </tr>
                    <tr>
                      <td>PAN :</td>
                      <td>{invoiceDetails.shipping_pan}</td>
                    </tr>
                  </table>
                </div>

                <div className="col ps-5 ">
                  <table>
                    <tr>
                      <th colSpan={2}>
                        <strong>SHIP TO</strong>
                      </th>
                      {/* <th></th> */}
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <strong>
                          {(
                            invoiceDetails.shipping_organization + ""
                          ).toLocaleUpperCase()}
                        </strong>
                      </td>
                    </tr>
                    <tr >
                      <td className="content-width">Address :</td>
                      <td>{invoiceDetails.shipping_address}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <div className="col">
                    {!deliverables.find((item: any) => item.deliverable_item === "Package Cost 1") ? (
                      <InvoiceTableComponent columns={invoiceColumnName}                    >
                        {deliverables.map((data: any, index: number) => {
                          return (
                            <TableRow>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">
                                {deliverableItemName(data.deliverable_item)}
                              </TableCell>
                              <TableCell align="left">998361</TableCell>
                              <TableCell align="left">
                                {data.deliverable_item === 'available_for_exclusivity' || data.deliverable_item === 'digital_rights' ? (
                                  '--'
                                ) : (
                                  data.dynamic_qty
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {methods.decimalFormat(
                                  (['digital_rights', 'available_for_exclusivity'].includes(data.deliverable_item) ? (data.dynamic_price / 1) : (data.dynamic_price / data.dynamic_qty))
                                )}
                              </TableCell>
                              <TableCell align="left" >
                                {(methods.roundToTwoDecimalPlaces(data.dynamic_price * 18) / 100)}
                                <span style={{ marginLeft: "10px" }}>(18%)</span>
                              </TableCell>
                              <TableCell align="left">
                                {methods.roundToTwoDecimalPlaces(data.dynamic_price +
                                  (data.dynamic_price * 18) / 100)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </InvoiceTableComponent>
                    ) : <InvoiceTableComponent columns={packageInvoiceColumnName}                    >
                      {deliverables
                        .filter((data: any) => data.deliverable_item !== 'Package Cost 1')
                        .map((data: any, index: number) => {
                          return (
                            <TableRow>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">
                                {deliverableItemName(data.deliverable_item)}
                              </TableCell>
                              <TableCell align="left">998361</TableCell>
                              <TableCell align="left">
                                {data.deliverable_item === 'available_for_exclusivity' || data.deliverable_item === 'digital_rights' ? (
                                  '--'
                                ) : (
                                  data.dynamic_qty
                                )}
                              </TableCell>


                            </TableRow>
                          );
                        })}
                    </InvoiceTableComponent>}
                  </div>
                </div>
              </div>
              <div className="row mt-3 justify-content-end">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col-auto">
                  <TableContainer
                    className="table-plan"
                    id="table_id"
                    component={Paper}
                  >
                    {/* <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">HSN/SAC</TableCell>
                        <TableCell align="left">Taxable Value</TableCell>
                        <TableCell align="left">
                          <TableRow>
                            <TableCell className="non-border">CGST</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="non-border">Rate</TableCell>
                            <TableCell className="non-border">Amount</TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="left">
                          <TableRow>
                            <TableCell className="non-border">SGST</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="non-border">Rate</TableCell>
                            <TableCell className="non-border">Amount</TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="left">Total Tax Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="pln_table_body">
                      <TableRow>
                        <TableCell align="left">998361</TableCell>
                        <TableCell align="left">
                          {calc.cgst + calc.sgst}
                        </TableCell>
                        <TableCell align="left">
                          <TableRow>
                            <TableCell className="non-border">9%</TableCell>
                            <TableCell className="non-border">
                              {calc.cgst}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell>
                          <TableRow>
                            <TableCell className="non-border">9%</TableCell>
                            <TableCell className="non-border">
                              {calc.sgst}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="left">
                          {calc.cgst + calc.sgst}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table> */}
                    <TableRow className="summary-row">
                      <TableCell colSpan={7} align="left">
                        <strong>Summary</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Taxable Amount</TableCell>
                      <TableCell align="left" colSpan={5}></TableCell>
                      <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.subtotal)}</TableCell>
                    </TableRow>
                    {(invoiceDetails.contract_state !== invoiceDetails.shipping_state) ? (
                      <>
                        <TableRow>
                          <TableCell align="left">CGST <span>(9%)</span></TableCell>
                          <TableCell align="left" colSpan={3}></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.cgst)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">SGST <span>(9%)</span></TableCell>
                          <TableCell align="left" colSpan={3}></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.sgst)}</TableCell>
                        </TableRow>
                      </>) :
                      (
                        <TableRow>
                          <TableCell align="left">IGST <span>(18%)</span></TableCell>
                          <TableCell align="left" colSpan={3}></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.igst)}</TableCell>
                        </TableRow>
                      )
                    }

                    {/* <TableRow>
                  <TableCell align="left">IGST</TableCell>
                  <TableCell align="left" colSpan={3}></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {calc.sgst + calc.cgst}</TableCell>
                </TableRow> */}

                    <TableRow className="total-row">
                      <TableCell align="left" className="cell-total"><strong>Invoice Amount</strong></TableCell>
                      <TableCell align="left" ></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"><CurrencyRupee className="rupee-icon" /> <strong>{calc.sgst + calc.cgst + calc.subtotal}</strong></TableCell>
                    </TableRow>

                  </TableContainer>
                </div>

              </div>
              {/* <div className="row mt-3">
                <div className="col">
                  <strong>Terms and Conditions:</strong>
                  <ol>
                    <li>Payment to be made within 30 days of post going live.</li>
                    <li>
                      Acceptance and Contract: Seller's acceptance of this order is
                      expressly conditioned upon buyer's acceptance of all terms and
                      conditions hereof. the terms and conditions hereof shall
                      constitute the binding contract between seller and buyer
                      concerning the goods sold hereunder. neither party shall claim
                      any amendment, modification, waiver or release form any
                      provisions hereof unless the same is in writing and signed by
                      both buyer and seller.
                    </li>
                    <li>
                      Selling Terms: All goods sold hereunder are F.O.B. Seller's
                      facility unless otherwise stated herein, but Seller retains a
                      security interest in the goods until payment is received. All
                      claims for shipping loss or damage are Buyer's responsibility.
                      Delivery dates are not guaranteed and Seller has no liability
                      for damages that may be incurred due to any delay in shipment
                      of goods hereunder. Taxes are excluded unless otherwise
                      stated.
                    </li>
                    <li>
                      Force Majeure: Seller shall have no liability to Buyer for
                      delay or failure to perform due to any cause beyond the
                      reasonable control of Seller.
                    </li>
                    <li>
                      Returns/Cancellations: All claims relating to quantity or
                      shipping errors shall be waived by Buyer unless made in
                      writing to Seller within thirty (30) days after delivery of
                      goods to the address stated. Buyer shall pre-pay freight
                      charges for any approved returns. Buyer shall be obligated to
                      pay for the portion of the order that has been delivered to
                      Buyer or that is in-process at the time of cancellation
                    </li>
                    <li>
                      Warranty: For goods manufactured by seller, seller's standard
                      limited warranty for the goods sold hereby shall apply.
                      seller's obligation under such warranty, unless otherwise
                      stated, is limited to repairing or replacing, at its factory,
                      any parts which are returned to seller within said warranty
                      period, freight prepaid, which upon examination prove to be
                      defective. labor costs are excluded. this warranty is
                      exclusive and in lieu of any other warranties, either
                      expressed or implied, including but not limited to any implied
                      warranty of merchantability, fitness for a particular purpose,
                      title and non-infringement, and all warranties arising from
                      course of dealing, usage or trade practice. except for the
                      express warranties set forth in this section 5, all services
                      and materials are provided â€œas isâ€. all third-party
                      materials are provided â€œas isâ€ and any representation or
                      warranty of or concerning any third party materials is
                      strictly between customer and the third-party owner or
                      distributor of the third-party materials.. 5. limitation of
                      liability. to the maximum extent permitted by law, buyer's
                      sole recourse against seller is limited to the provisions in
                      section 5 above, and in no event shall seller be responsible
                      or liable to buyer (or claims of any third party against
                      buyer) for special, indirect, collateral, punitive or
                      exlemplary, incidental or consequential damages, even if
                      seller has been advised of the possibility of such damages. to
                      the fullest extent permitted by law, such excluded damages
                      include, but are not limited to, loss of goodwill, loss of
                      revenue, loss of profits, loss of use, interruption of
                      business, loss of business opportunity, or other damages
                      resulting from any delay, act, error or omission of seller or
                      any subcontractor of seller however caused including
                      negligence, gross negligence and strict liability.
                    </li>
                    <li>
                      Indemnification: Buyer shall indemnify and hold Seller
                      harmless from and against any and all claims, demands,
                      lawsuits, damages, liabilities, costs and expenses (including
                      attorney's fees), incurred by reason of any injury to or death
                      of any person, or damage to any property, resulting from or
                      arising out of any act, error, omission, negligence, or
                      misconduct by Buyer in connection with the goods sold
                      hereunder.
                    </li>
                  </ol>
                </div>
              </div> */}
              <div
                className='invoice-send d-flex justify-content-end  mt-2'
                style={{ borderTop: '1px solid #d7d7d7' }}
              >

                {(editInvoiceNumber || editInvoiceDate) ?
                  <LoadingButton
                    disabled={loading}
                    className='btn btn-primary me-2 '
                    loading={loading}
                    onClick={updateGeneratedInvoice}
                  >
                    Save
                  </LoadingButton> : (
                    <LoadingButton
                      disabled={loading}
                      className='btn btn-primary me-2 '
                      loading={loading}
                      onClick={() => generateInvoicePdf('Download')}
                    >
                      Download
                    </LoadingButton>
                  )}

                {(!editInvoiceNumber && !editInvoiceDate && editAllowed) && (
                  <LoadingButton
                    disabled={loading}
                    className='btn btn-outline-primary '
                    loading={loading}
                    // onClick={() => generateInvoicePdf('Send')}
                    onClick={() => {
                      generateInvoicePdf('Send');
                      setInvoiceSent(true);
                    }}>

                    Send
                  </LoadingButton>
                )}
              </div>
            </div>

          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  UserReducer: state.UserReducer.UserData,
});

const mapDispatchToProps = (dispatch: any) => ({
  loading: (data: any) => dispatch(Loading(data)),
});

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintInvoice);

export { connectToRedux as PrintInvoice };
