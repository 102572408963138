interface Props {
  className?: string;
}
const IconAnalyticsCampaign: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_942_884" fill="white">
      <path d="M19.0819 9.17676C19.2579 11.1695 18.7776 13.1652 17.7142 14.8596C16.6508 16.5541 15.0626 17.8545 13.1917 18.5627C11.3207 19.2709 9.2695 19.348 7.35062 18.7824C5.43175 18.2167 3.75039 17.0393 2.56274 15.4294C1.37508 13.8196 0.74624 11.8657 0.77203 9.86532C0.797819 7.86498 1.47682 5.9279 2.70559 4.34923C3.93435 2.77056 5.6455 1.63686 7.57832 1.12086C9.51114 0.604858 11.5597 0.73485 13.4118 1.49103L9.94438 9.98358L19.0819 9.17676Z"/>
      </mask>
      <path d="M19.0819 9.17676C19.2579 11.1695 18.7776 13.1652 17.7142 14.8596C16.6508 16.5541 15.0626 17.8545 13.1917 18.5627C11.3207 19.2709 9.2695 19.348 7.35062 18.7824C5.43175 18.2167 3.75039 17.0393 2.56274 15.4294C1.37508 13.8196 0.74624 11.8657 0.77203 9.86532C0.797819 7.86498 1.47682 5.9279 2.70559 4.34923C3.93435 2.77056 5.6455 1.63686 7.57832 1.12086C9.51114 0.604858 11.5597 0.73485 13.4118 1.49103L9.94438 9.98358L19.0819 9.17676Z" stroke="black" stroke-width="2" mask="url(#path-1-inside-1_942_884)"/>
      <path d="M19.4097 7.80021C19.2918 6.46433 18.8122 5.1858 18.0225 4.1019C17.2328 3.01799 16.1628 2.16965 14.9273 1.64794L12.0551 8.4496L19.4097 7.80021Z" fill="#585858"/>
      </svg>

    </div>
  );
};

export default IconAnalyticsCampaign;






