import { Skeleton } from '@mui/material'
import React, { Fragment } from 'react'



type Props = {
  deliverablesData: any;
  loading?: any;
}

const DeliverableInfo = (props: Props) => {
  return (
    <Fragment>
      {props?.deliverablesData && props?.deliverablesData?.deliverables?.map((mainDeliverable: any, index: number) => {
        return <div key={index} className={props?.deliverablesData?.influencer_type &&
          mainDeliverable.influencer_type.replace(/\s*\(.*?\)\s*/, '') === props.deliverablesData.influencer_type
          ? "deliverable-list active-deliverable "
          : "deliverable-list"}>
          <strong> {props?.loading ? <Skeleton animation="wave" /> : mainDeliverable?.influencer_type}</strong>
          <ul>
            {mainDeliverable?.deliverables?.map((deliverable: any) => {
              return <li>{props?.loading ? <Skeleton animation="wave" /> : deliverable.split(",")}</li>
            })}
          </ul>
          {props?.deliverablesData?.influencer_type && (mainDeliverable.influencer_type.replace(/\s*\(.*?\)\s*/, '') === props.deliverablesData.influencer_type) &&
            <span>👍 Deliverables that fit your {props.deliverablesData.campaign_platform == 'Youtube' ? 'subscriber' : 'follower'}  count.</span>
          }
        </div>
      })}
    </Fragment>
  )
}

export default DeliverableInfo