import React, { useState, useEffect } from "react";
import "./detailed-analytics.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { AnalyticsService } from "../../../core/services/analytics.service"
import "./analytics.scss";
import {
  Tab,
  Box,
  TableCell,
  TableRow,
  Grid,
} from "@mui/material";
import {
  TableComponent,
  SortingColumnsType,
} from "../../../../src/components/table-component/table"

import { Visibility, ThumbUp, Chat } from '@mui/icons-material';
import AddAnalytics from "./add_analytics"
import dayjs from 'dayjs';
import AnalyticsDashboard from './components/analytics-dashboard';
import DetailedInsightsPost from './components/detailed-insights-post';


interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
}

const analyticsService = new AnalyticsService();
const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_views: "Views",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "",
    columnSize: "35%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Date",
    columnSize: "15%",
  },
];


const InfluencerAnalytics: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [analyticsCumulativeDataIgNumericData, setAnalyticsCumulativeDataIgNumericData] = useState<any>([]);
  const [analyticsCumulativeDataIgMediaData, setAnalyticsCumulativeDataIgMediaData] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [creativeData, setCreativeData] = useState()
  const [graphData, setGraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0)
  const [campaignPlatform, setCampaignPlatform] = useState("")
  const [smId, setSmId] = useState(0)



  // -----------------Open Model-----------------------


  const [openModalAddPost, setOpenModalAddPost] = useState<boolean>();

  // -----------

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };


  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };



  const handleOpenModalAnalytics = (campaign_id: number, campaign_platform: string, sm_id: number) => {
    setOpenModalAddPost(true);
    setCampaignId(campaign_id);
    setCampaignPlatform(campaign_platform);
    setSmId(sm_id);


  }


  useEffect(() => {
    analyticsData();
  }, []);

  const analyticsData = async () => {
    let campaign_id = props?.selectCampaign?.campaign_id;
    const res = await analyticsService.brandAnalytics({
      campaign_id: campaign_id, sm_id: props.selectCampaign.smId
    });
    if (res) {
      setAnalyticsCumulativeDataIgMediaData(res.data.analytics_data_ig_media[0])
      setAnalyticsCumulativeDataIgNumericData(res.data.analytics_data_ig_numeric[0])
      setPostFilterData(res.data.post_data_content)
      setInfluencerFilterData(res.data.influencer_data_content)
      setGraphData(res.data.graph_data_array)
      setCreativeData(res.data.no_of_creatives)
    }
  }
  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Likes',
      },
    },
    series: [
      {
        name: 'Date',
        data: graphData.map((entry: any) => entry.likes),
      },
    ],
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Plays Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData && graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Plays',
      },
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Date',
        data: graphData.map((entry: any) => entry.video_plays),
      },
    ],
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Comments',
      },
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Date',
        data: graphData.map((entry: any) => entry.comments),
      },
    ],
  };
  const erOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'ER Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'ER',
      },
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Date',
        data: graphData.map((entry: any) => entry.er),
      },
    ],
  };

  // For Insights by the types of Creatives
  return (
    <>
      {openModalAddPost && (
        <AddAnalytics open={openModalAddPost}
          onClose={() => setOpenModalAddPost(false)}
          campaign_id={campaignId}
          post_platform={campaignPlatform}
          sm_id={smId}

        />
      )}
      <div className="infl-analytics detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="container custom-container">
            <div className="row project-detail-sec">
              <div className="button-div">
                <span className="btn btn-outline-primary add btn-wide"
                  onClick={(e) => {
                    handleOpenModalAnalytics(props.selectCampaign.campaign_id, props.selectCampaign.campaign_platform, props.selectCampaign.smId)
                  }} >
                  Add Post
                </span>
              </div>
              <div className="col-md-12">
                <div className="upper-grid">
                  <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataIgMediaData} campaign_platform={props?.selectCampaign?.campaign_platform} analyticsCumulativeDataNumericData={analyticsCumulativeDataIgNumericData} creatives={creativeData} UserReducer={props?.UserReducer} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          (() => {
            if (postFilterData.length > 0) {
              return (
                <>
                  <div className="toggle-button-div sec-stockchart-w-tab">
                    <TabContext value={value}>
                      <TabList
                        onChange={handleChange}
                        className='nav sub-tabs'
                        variant="fullWidth"
                      >
                        <Tab className="nav-link"
                          label={`Total Plays`}
                          value="1"
                          icon={<span className="material-symbols-rounded">play_arrow</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className="nav-link"
                          label={`Total Likes`}
                          value="2"
                          icon={<span className="material-symbols-rounded">favorite</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className="nav-link"
                          label={`Total Comments`}
                          value="3"
                          icon={<span className="material-symbols-rounded">chat_bubble</span>}

                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className="nav-link"
                          label={`ER`}
                          value="4"
                          icon={<span className="material-symbols-rounded">percent</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                      </TabList>

                      <TabPanel className="px-0" value="1">
                        <HighchartsReact highcharts={Highcharts} options={playsOptions} />
                      </TabPanel>
                      <TabPanel className="px-0" value="2">
                        <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                      </TabPanel>
                      <TabPanel className="px-0" value="3">
                        <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                      </TabPanel>
                      <TabPanel className="px-0" value="4">
                        <HighchartsReact highcharts={Highcharts} options={erOptions} />
                      </TabPanel>
                    </TabContext>
                  </div>
                  <div>
                  </div>
                  <div className="toggle-button-div2">
                    <TabContext value={value4}>
                      <TabList
                        onChange={handleChange4}
                        className="filter-tab-list nav sub-tabs"
                      >
                        <Tab
                          className='nav-link'
                          label={`Posts`}
                          value="1"
                          style={{ textTransform: "none", fontSize: "16px" }}
                        />
                      </TabList>
                      <TabPanel value="1" className='px-0'>
                        <div className="post-table">
                          <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                            orderByColumnName=""
                            inputDataSource={postFilterData}
                            sortingColumnType={PostDetailedData}
                            renderData={postFilterData}
                            setRenderData={(e: any) => setPostFilterData(e)}
                            selectCampaign={props?.selectCampaign}
                          />
                          {/* {
                            (() => {
                              if (postFilterData.length > 0) {
                                return (
                                  <>
                                    <TableComponent
                                      columnDisplayName={columnDisplayNamePostDetailedData}
                                      orderByColumnName=""
                                      inputDataSource={postFilterData}
                                      sortingColumnType={PostDetailedData}
                                      renderData={postFilterData}
                                      setRenderData={(e) => setPostFilterData(e)}
                                    >
                                      {postFilterData.map((data: any, index: number) => {
                                        return (
                                          <TableRow key={index} hover role="checkbox">
                                            <TableCell>
                                              <div className='grid-div-post d-flex'>
                                                <img src={data?.gcp_display_url} alt={data?.username} className='grid-image me-2' />
                                                <div className='d-flex flex-column'>
                                                  <span>{data?.username}</span>
                                                  <span>{data?.__typename === "GraphVideo" ? `${data?.video_duration} seconds` : ""}</span>
                                                </div>
                                              </div>
                                            </TableCell>
                                            <TableCell>{data?.er}</TableCell>
                                            <TableCell>{data?.video_views}</TableCell>
                                            <TableCell>{data?.likes}</TableCell>
                                            <TableCell>{data?.comments}</TableCell>
                                            <TableCell>{dayjs(data?.created_at).format("LL")}</TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableComponent>
                                  </>
                                )
                              }

                            })()

                          } */}
                        </div>
                      </TabPanel>

                    </TabContext>
                  </div>

                </>
              )
            }

          })()

        }

      </div>
    </>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerAnalytics);

export { connectToRedux as InfluencerAnalytics };
