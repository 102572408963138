import React from 'react';
import './heading-style.scss';

type Props = {
  children?: any;
};

export default function HeadingStyleSecondary(props: {
  title: string;
  className?: string;
  children?: any;
}) {
  return (
    <>
      <strong className={['title-secondary', props.className].join(' ')}>
        {`${props.title}`}
      </strong>
      {props.children}
    </>
  );
}
