import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Numbers } from '../../../core/utility';
import './subscription-invoice.scss';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CurrencyRupee } from '@mui/icons-material';
import { SubscriptionInvoiceType } from 'custom-type';
import TaxInformation from './tax-information';
import { SubscriptionService } from '../../../core/services';
import './subscription-invoice.scss'; // Import CSS file
// to generate downloadable pdf
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
// For Download animation icon
import UseAnimations from 'react-useanimations';
import download from 'react-useanimations/lib/download'
// import { Loading } from '../../../redux/action';
import ContentLoading from '../../../components/loader/loading';

// const localService: LocalService = new LocalService();
const subscriptionService: SubscriptionService = new SubscriptionService();
const numberFormat: Numbers = new Numbers();

interface Props {
  UserReducer: any;
  // loading: (data: any) => void;
}

const SubscriptionInvoice: React.FC<Props> = ({ UserReducer }: Props) => {
// const SubscriptionInvoice: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState<SubscriptionInvoiceType>({
    id: 0,
    invoice_number: '',
    subscription_id: 0,
    invoice_title: '',
    customer_name: '',
    customer_email: '',
    customer_phone: '',
    shipping_address: '',
    shipping_state: '',
    shipping_pincode: '',
    billing_address: '',
    billing_state: '',
    billing_pincode: '',
    pan_number: '',
    gstin: '',
    order_id: '',
    payment_id: '',
    amount: 0,
    igst: 0,
    cgst: 0,
    sgst: 0,
    roundoff: 0,
    total_amount: 0,
    sac_code: '',
    quantity: 0,
    payment_terms: '',
    plan_name: '',
    plan_type: '',
    plan_start: '',
    plan_end: '',
    invoice_date: '',
  });

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  /*...............................Generate PDF................ */
  const options = {
    // default is `save`
    method: "build" as const,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'portrait' as const,
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg' as const,
      qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };

  const generateInvoicePdf = async () => {
    try {
      const elements = document.getElementById('invoice-main');
      elements?.classList.add('download-invoice');

      const getTargetElement = () => elements;
      const pdfInstance = await generatePDF(getTargetElement, options);
      // console.log(pdfInstance)
      const blob = pdfInstance.output('blob');
      // const generatedFile = new File([blob], `invoice_${invoice.invoice_number}.pdf`, { type: 'application/pdf' });

      // window.open(URL.createObjectURL(blob), '_blank');

      // elements?.classList.remove('download-invoice');

      // Create a hidden anchor element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.target = '_blank';
      
      // Set the filename for the download
      let invoice_number = invoice.invoice_number;
      invoice_number = invoice_number.replaceAll('/','_')
      
      link.download = `Invoice_${invoice_number}`; // Set your desired filename here

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Trigger a click event on the anchor element to simulate download
      link.click();

      // Remove the anchor element from the document body
      document.body.removeChild(link);

      elements?.classList.remove('download-invoice');

    } catch (error) {
      console.log('Error generating PDF:', error);
      throw new Error('Error generating PDF.');
    } finally {
    }
  }

  /*...............................End Generate PDF................ */

  //if Some issue the navigate other route
  const handleNavigation = () => {
    const account_type = UserReducer.account_type
      .toString()
      .toLowerCase()
      .trim();
    let navLink = "";
    if (account_type === "influencer" || account_type === "talent partner") {
      navLink = "/influencer/dashboard";
    } else if (account_type === "brand" || account_type === "agency") {
      navLink = "/brand/dashboard";
    } else {
      navLink = '/'
    }
    navigate(navLink);
  };

  async function getSubscriptionInvoiceData() {
    try{
      // Parse subscription order_id from the URL query parameters
      const searchParams = new URLSearchParams(location.search);
      const subscriptionOrderId = searchParams.get('token') || '';
      const response = await subscriptionService.getSubscriptionInvoice({token: subscriptionOrderId});
      const invoiceDataString: SubscriptionInvoiceType = response.data.rows[0];
      if (invoiceDataString) {
        setInvoice(invoiceDataString);
      }else{
        handleNavigation();
      }
    } catch (error) {
      console.error('Error getSubscriptionInvoiceData:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getSubscriptionInvoiceData();
  }, []);

  return (
    <>
      {loading ? 
        (
          <ContentLoading width='80' height='80' />
        ) : (
        <main className="main-content-sec padd-content-top full-width">
          <div className="pdf-container" id='invoice-main'>
            {/* Invoice Details */}
            <div className="invoice-det">
              <div className="invoice-det-left">
                {invoice.invoice_title}
              </div>
              <div className="invoice-det-right" style={{ marginTop: "10px" }}>
                <div className="invoice-det-right-btm-left">
                  Date: <strong>{invoice.invoice_date ? dayjs(invoice.invoice_date).format('LL') : ""}</strong>
                </div>
                <div className="invoice-det-right-btm-right">
                  <ul>
                    <li><span>Invoice no. :</span><strong>{invoice.invoice_number}</strong></li>
                    {/* Other list items */}
                  </ul>
                </div>
              </div>
            </div>

            {/* Invoice Biller and Customer Information */}
            <div className="invoice-biller-info">
              <div className="invoice-biller-info-left">
                <strong className="d-block">IRIDA INTERACTIVE PRIVATE LIMITED</strong>
                <address className="d-block mb-0">
                  2nd floor, 249, Okhla Phase 3 Road, Okhla Industrial Estate Phase 3,
                  <br />
                  New Delhi, South East Delhi, Delhi, 110020
                </address>
                <span className="d-block">GSTIN/UIN: 07AAFCI5033D1ZY</span>
                <span className="d-block">State Name : Delhi, Code : 07</span>
                <span className="d-block">CIN: U72900DL2019PTC358765</span>
                <div className="invoice-customer-info mt-4">
                  <span className="d-block">Consignee (Ship to)</span>
                  <strong style={{ display: 'block' }}>
                    {invoice.customer_name}
                  </strong>
                  <address className="d-block mb-0">
                    {invoice.shipping_address}
                  </address>
                  <span className="d-block">
                    State Name:{' '}
                    {invoice.shipping_state}
                  </span>
                  <span className="d-block">
                    Phone Number:{' '}
                    {invoice.customer_phone}
                  </span>
                  <span className="d-block">
                    Email:{' '}
                    {invoice.customer_email}
                  </span>
                  <span className="d-block">
                    PAN No.:{' '}
                    {invoice.pan_number}
                  </span>
                  <span className="d-block">
                    GSTIN:{' '}
                    {invoice.gstin}
                  </span>
                </div>
                <div className="invoice-customer-info mt-4">
                  <span className="d-block">Buyer (Bill to)</span>
                  <strong style={{ display: 'block' }}>
                    {invoice.customer_name}
                  </strong>
                  <address className="d-block mb-0">
                    {invoice.billing_address}
                  </address>
                  <span className="d-block">
                    State Name:{' '}
                    {invoice.billing_state}
                  </span>
                  <span className="d-block">
                    Phone Number:{' '}
                    {invoice.customer_phone}
                  </span>
                  <span className="d-block">
                    Email:{' '}
                    {invoice.customer_email}
                  </span>
                  <span className="d-block">
                    PAN No.:{' '}
                    {invoice.pan_number}
                  </span>
                  <span className="d-block">
                    GSTIN:{' '}
                    {invoice.gstin}
                  </span>
                </div>
              </div>
              <div className="invoice-biller-info-right">
                <ul>
                  <li>
                    <span className="d-block">Delivery Note:</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Mode/Terms of Payment:</span>
                    <strong style={{ textAlign: 'left' }} className="d-block text-left">
                      {invoice.payment_terms}
                    </strong>
                  </li>
                  <li>
                    <span className="d-block">Reference No. & Date.</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Other References</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Buyer's Order No.</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Dated</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Dispatch Doc No.</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Delivery Note Date</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Dispatched through</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Destination</span>
                    {/*aasdfas*/} <br />
                  </li>
                  <li>
                    <span className="d-block">Order Id</span>
                    {invoice.order_id}
                    <br />
                  </li>
                  <li>
                    <span className="d-block">RazorPay Payment ID</span>
                    {invoice.payment_id}
                    <br />
                  </li>
                  <li>
                    <span className="d-block">Terms of Delivery</span>
                    {/*aasdfas*/} <br />
                  </li>
                </ul>
              </div>
            </div>

            {/* Invoice Table */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="invoice table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Particulars</TableCell>
                    <TableCell>HSN/SAC</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <strong>
                        SUBSCRIPTION SALE - ({invoice.plan_type})
                      </strong>
                      <br />
                      <strong>
                        Duration: From: {dayjs(invoice.plan_start).format('LL')}, To: {dayjs(invoice.plan_end).format('LL')}
                      </strong>
                      <br />
                      <strong>OUTPUT IGST@18%</strong>
                      {
                        invoice.roundoff > 0 && (
                          <>
                            Less : <strong>ROUND OFF</strong>
                          </>
                        )
                      }
                    </TableCell>
                    <TableCell>{invoice.sac_code}</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <CurrencyRupee />
                      {invoice.amount}
                    </TableCell>
                    <TableCell align="right">
                      <CurrencyRupee />
                      {invoice.amount}
                      <br />
                      <br />
                      <CurrencyRupee />
                      {invoice.igst > 0 ? invoice.igst : (invoice.cgst + invoice.sgst)}
                      {
                        invoice.roundoff > 0 && (
                          <>
                            <CurrencyRupee />
                            {invoice.roundoff}
                          </>
                        )
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-bg-color">
                    <TableCell></TableCell>
                    <TableCell><strong>Total</strong></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <CurrencyRupee />
                      {invoice.total_amount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6} className="inv-table-footer">
                      <strong>Amount Chargeable (in words): </strong>
                      <>
                        INR {numberFormat.convertToWords(invoice.total_amount)} only
                      </>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6} style={{ height: '20px' }}>&nbsp;</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table sx={{ minWidth: 650 }} aria-label="invoice table">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} style={{ paddingLeft: 0 }}>
                      <strong>Tax Information</strong>
                    </TableCell>
                  </TableRow>
                  {/* Tax Information */}
                  <TaxInformation
                    invoice={invoice}
                  />
                </TableBody>
              </Table>
            </TableContainer>

            {/* Invoice Footer */}
            <div className="inv-footer pt-4 w-100" style={{ float: "left", width: "100%" }}>
              <div className="inv-bottom float-left" style={{ float: "left" }}>
                Company's PAN:
                <strong>AAFCI5033D</strong>
              </div>
              <div className="inv-bottom-right float-right" style={{ float: "right" }}>
                <strong className="d-block pb-5">for IRIDA INTERACTIVE PRIVATE LIMITED:</strong>
                <span className="">Auth. Signatory</span>
              </div>
            </div>
          </div>
          <div
            className='invoice-send d-flex justify-content-end  mt-2'
            style={{ borderTop: '1px solid #d7d7d7' }}
          >
            <button className='btn btn-primary' onClick={() => {
              generateInvoicePdf();
            }} >
              Download
            </button>
            {/* <UseAnimations 
              animation={download}
              
              size={40}
              className='me-2 d-flex ms-auto cursor-pointer'
              speed={0.8}
            /> */}
          </div>
        </main>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  UserReducer: state.UserReducer.UserData,
});

const connectToRedux = connect(
  mapStateToProps
)(SubscriptionInvoice);

export { connectToRedux as SubscriptionInvoice };

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     loading: (data: any) => dispatch(Loading(data)),
//   };
// };

// const connectToRedux = connect(null, mapDispatchToProps)(SubscriptionInvoice);
// export { connectToRedux as SubscriptionInvoice };