import React from 'react';
import HeadingStyle from '../heading-style/heading-style';
import HeadingStyleSecondary from '../heading-style/heading-style-secondary';

type Props = {
  className?: string;
  linkText?: string;
  href?: string;
  actionType: string;
  title: string;
  children?: any;
};

export const CommonSection = ({
  className,
  actionType,
  title,
  children,
}: Props) => {
  let result = null;
  switch (actionType) {
    case 'section-w-left-right':
      result = (
        <section className={[`sec-common mb-3 ${className || ''}`].join(' ')}>
          <div className='sec-common-left'>
            <HeadingStyleSecondary title={title} />
          </div>
          <div className='sec-common-right'>{children}</div>
        </section>
      );
      break;

    default:
      result = <section className={`sec-common mb-3 ${className || ''}`}>
        <div className='sec-common-title'>
          <HeadingStyleSecondary title={title} />
        </div>
      </section>;
      break;
  }
  return result;
};
