import React from 'react'
import MaterialSymbol from '../../../components/icons/material-symbol'
import { IconButton, Skeleton, Tooltip } from '@mui/material';

type Props = {
    title: string,
    value: any,
    multipleValue?: boolean;
    value1?:any,
    tooltipText?:string,
    tooltip?:boolean,
    loading?:boolean
}

const FollowerCardInline = ({ multipleValue, title, value, value1, tooltip, tooltipText,loading }: Props) => {
    return (
        <div className='card-follower-inline'>
            <span className={`card-follower__title ${tooltip ? "d-flex align-items-center" : ""}`}>{title}
                {tooltip && 
                    <Tooltip title={tooltipText} arrow>
                        <IconButton className='p-0'>
                            <MaterialSymbol iconName='info' fontweight='200' />
                        </IconButton>
                    </Tooltip>
                }
            </span>
            {!multipleValue && <span className='card-follower__value'>{value}</span>}
            {multipleValue && 
            <div className='card-follower-inline-list'>
                {loading ? <Skeleton /> : <span className='card-follower__value'>{value}</span>}
                <span className='card-follower__value'>{value1}</span>
            </div>
            }

            
        </div>
    )
}

export default FollowerCardInline