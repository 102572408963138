import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './campaign-create.scss';
import { Button, Checkbox, InputAdornment, TextField } from '@mui/material';
import { Editor } from '../../../../../components';
import InstagramIcon from '../../../../../components/icons/instagram-icon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { toast } from 'react-toastify';
import { CreateCampaign, Loading } from '../../../../../redux/action';
import { connect } from 'react-redux';
import { FormValidation } from '../../../../../core/utility';
import { CreateCampaign as Create_Campaign, LocalService } from '../../../../../core/services';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import defaultImage from '../../../../../../src/assets/images/image-fill.svg';
import FileUploadPopup from '../../../../../components/popup/file-upload-popup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InstagramIcon1 from '../../../../../components/icons/instagram-icon1';
import IconYouTube from '../../../../../components/icons/icon-youtube';
import { MenuPropsSelect } from '../../../../../core/utility/style';

const formValidation = new FormValidation();
const localService = new LocalService();


interface Props {
    UserReducer: any;
    UserID: any;
    campaignReducer: any;
    CreateCampaignReducer?: (data: any) => void;
    loading?: (data: any) => void;
}

const CampaignCreate: React.FC<Props> = (props: Props) => {
    const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const path = `accounts/${props.UserReducer.account_id}/plans/${props.UserReducer.user_id}/campaigns`;
    const [formData, setFormData] = useState({
        user_id: props.UserID,
        campaign_id: props?.campaignReducer?.createCampaign?.campaign_id,
        campaign_platform: 'Instagram',
        campaign_title: '',
        campaign_type: 'Paid',
        campaign_description: '',
        campaign_image_path: '',
        campaign_image_url: '',
        campaign_budget: '0',
        campaign_objective: '',
        ...props.campaignReducer.createCampaign,
    });

    const [formError, setFormErrors] = useState<any>({
        image: { status: true, message: '' },
        description: { status: true, message: '' },
        campaign_title: { status: true, message: '' },
        campaign_budget: { status: true, message: '' }
    });
    //   For campaign type select dropdown on mobile
    const [campaignType, setCampaignType] = React.useState('');

    const handleCampaignTypeChange = (event: SelectChangeEvent) => {
        setCampaignType(event.target.value as string);
    };
    const navigate = useNavigate();

    //handles

    useEffect(() => {
        if (!props.campaignReducer.createPlan) {
            navigate('/brand/plans');
        }
    }, [props]);

    const handleSave = async () => {
        try {
            setLoading(true);
            const promise = new Promise(async (resolve, reject) => {
                Create_Campaign.updateCampaign(formData).then((res: any) => {
                    if (res?.data?.rows?.status) {
                        if (props.CreateCampaignReducer) {
                            props.CreateCampaignReducer({
                                createCampaign: formData,
                            });

                            if(props.campaignReducer.influencer){
                                if(formData?.campaign_platform?.toLocaleLowerCase() != props?.campaignReducer?.createCampaign.campaign_platform.toLocaleLowerCase()){
                                props.CreateCampaignReducer({
                                    influencer: {...props.campaignReducer.influencer,
                                        influencer_list_ids : [], listInfluencers : {}}
                                });
                            }}
                        }

                        navigate("/brand/plans");
                        localService.toastify(res?.data?.rows?.message, 'success', 1000);
                    } else {
                        localService.toastify(res?.data?.rows?.message, 'error', 1000);
                    }

                    resolve(res?.data?.rows);

                    setLoading(false);
                });
            });

            return promise;
        } catch (error) {
            throw new Error(`handle Save Error :: ${error}`);
        }
    };

    const handleNext = async () => {
        try {
            let productTab = formData.campaign_type
                .split(' ')
                .join('')
                .toLocaleLowerCase()
                .includes('barter');
            const validateImage = formValidation.GetNameValidate(
                formData.campaign_image_url
            );
            const validateDesc = formValidation.getdescriptionIsValid(
                formData.campaign_description
            );
            const validateTitle = formValidation.GetNameValidate(
                formData.campaign_title
            );


            setFormErrors({
                ...formError,
                image: { ...validateImage },
                description: { ...validateDesc },
                campaign_title: { ...validateTitle }
            });

            if (
                validateImage.status &&
                validateDesc.status &&
                validateTitle.status
            ) {
                const response: any = await handleSave();

                if (response.status) {
                    if (props.CreateCampaignReducer) {
                        props.CreateCampaignReducer({
                            navigation: {
                                ...props.campaignReducer.navigation,
                                createProduct: productTab,
                                influencer: !productTab,
                            },
                            completeStep: {
                                ...props.campaignReducer.completeStep,
                                campaign_create: true,
                            },
                        });
                        if(props.campaignReducer.influencer){
                            if(formData?.campaign_platform?.toLocaleLowerCase() != props?.campaignReducer?.createCampaign.campaign_platform.toLocaleLowerCase()){
                            props.CreateCampaignReducer({
                                influencer: {...props.campaignReducer.influencer,
                                    influencer_list_ids : [], listInfluencers : {}}
                            });
                        }}
                    }

                    if (productTab) {
                        navigate('/brand/campaign/createproduct');
                    } else {
                        navigate('/brand/campaign/influencer');
                    }
                }
            }
        } catch (error) {
            throw new Error(`handleNext Error :: ${error}`);
        }
    };

    const handleCampaign = (campaign: string) => {
        setFormData({ ...formData, campaign_type: campaign });
        const checkPaid_Barter = campaign
            .split(' ')
            .join('')
            .toLocaleLowerCase()
            .includes('barter');
        if (checkPaid_Barter) {
            if (props.CreateCampaignReducer) {
                props.CreateCampaignReducer({
                    ...props.campaignReducer,
                    hiddenTab: {
                        ...props.campaignReducer.hiddenTab,
                        createProduct: true,
                    },
                });
            }
        } else {
            if (props.CreateCampaignReducer) {
                props.CreateCampaignReducer({
                    ...props.campaignReducer,
                    hiddenTab: {
                        ...props.campaignReducer.hiddenTab,
                        createProduct: false,
                    },
                });
            }
        }
    };

    const handleCheckBox = (e: any, x: boolean) => {
        if (x) {
            setFormData({
                ...formData,
                campaign_description:
                    props.campaignReducer.createPlan.plan_description,
            });
        }
    };

    const handleBackBtn = () => {
        navigate('/brand/campaign/createplan');
    };

    return (
        <div className='create_campaign_main_container'>
            <form>
                <div className='create_campaign_main_row'>
                    <div className=''>
                        <strong className='pb-1 d-block'>Platform</strong>
                        <div className='col nav sub-tabs no-scrollbar create_campaign_platform input-checkbox-box-container'>
                            <span
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        campaign_platform: 'Instagram',
                                    })
                                }
                                className={`input-checkbox-box d-inline-flex nav-link
                                    ${(
                                        formData.campaign_platform + ''
                                    ).toLocaleLowerCase() === 'instagram'
                                        ? ' active'
                                        : ''}`}

                            >
                                <InstagramIcon1 width={24} />
                                <span className='input-checkbox-box-label'>Instagram</span>
                            </span>
                            <span
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        campaign_platform: 'youtube',
                                    })
                                }
                                className={
                                    `input-checkbox-box d-inline-flex nav-link
                                    ${(
                                        formData.campaign_platform + ''
                                    ).toLocaleLowerCase() === 'youtube'
                                        ? 'active'
                                        : ''}`}
                            >
                                <IconYouTube width={28} />
                                <span className='input-checkbox-box-label'>YouTube</span>
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            {/* <strong>Objective</strong> */}
                            <FormControl
                                fullWidth
                                size='small'
                                className='objective_type_formcontrol'
                            >
                                <InputLabel id='demo-simple-select-label'>
                                    Objective
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='id_Objective'
                                    value={formData.campaign_objective || 'Drive Awareness'} // Set the default value to 'Drive Awareness'
                                    label='Objective'
                                    className='objective_type_formcontrol_dropdown'
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            campaign_objective: e.target.value,
                                        })
                                    }
                                    MenuProps={MenuPropsSelect}
                                >
                                    <MenuItem
                                        className='campaign-desc'
                                        value='Drive Awareness'
                                    >
                                        <strong>
                                            {' '}
                                            Drive Awareness: &nbsp;
                                        </strong>
                                        <span>
                                            Choose this if you want to bulid
                                            brand awareness and maximise your
                                            reach. Works best with macro & mega
                                            Influencer
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        className='campaign-desc'
                                        value='Drive Performance'
                                    >
                                        <strong>
                                            {' '}
                                            Drive Performance: &nbsp;
                                        </strong>
                                        <span>
                                            Choose this if you want measure
                                            sales/conversion or track reviews of
                                            your Product.
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        className='campaign-desc'
                                        value='Drive Engagement'
                                    >
                                        <strong>
                                            {' '}
                                            Drive Engagement: &nbsp;
                                        </strong>
                                        <span>
                                            Choose this if you want measure
                                            sales/conversion or track reviews of
                                            your Product.
                                        </span>
                                    </MenuItem>
                                    {/* <MenuItem value='Other'>Other</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col campaign_type'>
                            <span
                                onClick={() => handleCampaign('paid')}
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'paid'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Paid
                            </span>
                            <span
                                onClick={() => handleCampaign('barter')}
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'barter'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Barter
                            </span>
                            {/* <span
                                onClick={() => handleCampaign('paid + barter')}
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'paid + barter'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Paid + Barter
                            </span> */}
                            {/* <span
                                onClick={() => handleCampaign('cpv')}
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'cpv'
                                        ? ' active'
                                        : ''
                                }
                            >
                                CPV
                            </span> */}
                            {/* <span
                                onClick={() => handleCampaign('affiliate')}
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'affiliate'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Affiliate
                            </span> */}
                            {/* <span
                                onClick={() =>
                                    handleCampaign('paid + affiliate')
                                }
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'paid + affiliate'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Paid + Affiliate
                            </span> */}
                            {/* <span
                                onClick={() =>
                                    handleCampaign('barter + affiliate')
                                }
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'barter + affiliate'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Barter + Affiliate
                            </span> */}
                            {/* <span
                                onClick={() => handleCampaign('analytics')}
                                className={
                                    (formData.campaign_type + '')
                                        .toLocaleLowerCase()
                                        .trim() === 'analytics'
                                        ? ' active'
                                        : ''
                                }
                            >
                                Analytics
                            </span> */}
                        </div>
                        {/* For Mobile */}
                        {/* <div className='d-flex d-md-none'>
                            <FormControl fullWidth>
                                <InputLabel id='select-campaign-type-label'>
                                    Select Campaign Type
                                </InputLabel>
                                <Select
                                    labelId='select-campaign-type-label'
                                    id='select-campaign-type'
                                    value={campaignType}
                                    label='Campaign type'
                                    onChange={handleCampaignTypeChange}
                                >
                                    <MenuItem
                                        onClick={() => handleCampaign('paid')}
                                        value='Paid'
                                    >
                                        Paid
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleCampaign('barter')}
                                        value='Barter'
                                    >
                                        Barter
                                    </MenuItem>
                                    {/* <MenuItem
                                        onClick={() =>
                                            handleCampaign('Paid + Barter')
                                        }
                                        value='Paid + Barter'
                                    >
                                        Paid + Barter
                                    </MenuItem> */}
                                    {/* <MenuItem
                                        onClick={() => handleCampaign('cpv')}
                                        value='cpv'
                                    >
                                        CPV
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleCampaign('affiliate')
                                        }
                                        value='affiliate'
                                    >
                                        Affiliate
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleCampaign('paid + affiliate')
                                        }
                                        value='paid + affiliate'
                                    >
                                        Paid + Affiliate
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleCampaign('barter + affiliate')
                                        }
                                        value='barter + affiliate'
                                    >
                                        Barter + Affiliate
                                    </MenuItem> 
                                    <MenuItem value='Analytics'>
                                        Analytics
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}
                    </div>
                    <div className='row '>
                        <div className='col-lg-9'>
                            <div className='row mb-3'>
                                <div className='col'>
                                    <TextField
                                        className='w-100'
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                campaign_title: e.target.value,
                                            })
                                        }
                                        value={formData.campaign_title}
                                        error={!formError.campaign_title.status}
                                        helperText={
                                            formError.campaign_title.message
                                        }
                                        size='small'
                                        type='text'
                                        name='Campaign Title '
                                        label='Campaign Title'
                                    />
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col mt-3'>
                                    <TextField
                                        className='w-100'
                                        error={!formError.campaign_budget.status}
                                        helperText={
                                            formError.campaign_budget.message
                                        }

                                        label='Budget'
                                        id='outlined-start-adornment'
                                        size='small'
                                        value={
                                            formData.campaign_budget === '0'
                                                ? ''
                                                : formData.campaign_budget
                                        }
                                        onChange={(e) => {
                                            let validate = formValidation.IsNumber(e.target.value)
                                            if (validate.status) {
                                                setFormData({
                                                    ...formData,
                                                    campaign_budget: e.target.value,
                                                })
                                            };
                                        }
                                        }
                                        InputProps={{

                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <CurrencyRupeeIcon className='currencyIcon' />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col d-flex flex-column flex-md-row'>
                                    <strong className='me-2'>
                                        Description
                                    </strong>{' '}
                                    <span className='fs-14 custom-checkbox'>
                                        <Checkbox
                                            className='p-0'
                                            onChange={handleCheckBox}
                                        />
                                        Use same as Plan Description
                                    </span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='create_campaign_editor'>
                                        <Editor
                                            editorValues={
                                                formData.campaign_description
                                            }
                                            getEditorValue={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    campaign_description: e,
                                                })
                                            }
                                        />
                                        <span className='error'>
                                            {formError.description.message}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 '>
                            <strong className='d-block d-md-none mb-2 mb-md-none'>
                                Upload
                            </strong>
                            <div className='cover_image_section'>
                                <span className='error'>
                                    {formError.image.message}
                                </span>
                                {showUploadPopup && (
                                    <FileUploadPopup openModal={showUploadPopup} onClose={(e) => setShowUploadPopup(false)} onSend={(e: any) =>
                                        setFormData({
                                            ...formData,
                                            campaign_image_url: e.url,
                                            campaign_image_path: e.key,
                                        })
                                    }
                                        path={path}
                                        acceptedFileTypes=".jpg, .jpeg, .png" />
                                )}
                                <img
                                    aria-label='cropped image'
                                    className='campaign_cover_image'
                                    alt='cover'
                                    src={
                                        formData.campaign_image_url.length > 0
                                            ? formData.campaign_image_url
                                            : defaultImage
                                    }
                                />
                                <Button
                                    className='btn btn-outline-primary upload_button'
                                    onClick={() => setShowUploadPopup(true)}
                                    variant='contained'
                                >
                                    Upload Image
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className='buttons text-end'>
                        <LoadingButton
                            loading={loading}
                            className='btn btn-outline-primary me-auto ms-0'
                            loadingPosition='start'
                            variant='outlined'
                            onClick={handleBackBtn}
                        >
                            Back
                        </LoadingButton>
                        <LoadingButton
                            loading={loading}
                            className='btn btn-outline-primary'
                            loadingPosition='start'
                            variant='outlined'
                            onClick={handleSave}
                        >
                            Save as draft
                        </LoadingButton>
                        <LoadingButton
                            className='btn btn-primary'
                            loading={loading}
                            loadingPosition='start'
                            variant='contained'
                            onClick={handleNext}
                        >
                            Next
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignCreate);

export { connectToRedux as CampaignCreate };
