import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocalService } from '../../../core/services';
import { ClanShopService } from '../../../core/services/clan.shop.service';
import { ProductCard } from '../../brand/clan-shop/product-card';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import HeadingStyle from '../../../components/heading-style/heading-style';

const localService = new LocalService();

export const InfluencerShop = () => {
    const [productData, seProductData] = useState<any>();
    const [value, setValue] = React.useState('1');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const {shop_id} = useParams();
    const {shop_name} = useParams();

    useEffect(() => {
        ClanShopService.getShopProducts(shop_id)
            .then((res) => {
                seProductData(res?.data?.rows);
            })
            .catch((e) => seProductData([]));
    }, []);

    const handleAddProductShop = () => {
        navigate('/influencer/clanshop/product_shop');
    };

    const handleProductClick = (product:any) => {
        navigate(`/influencer/clanshop/manage_product?shop_id=${shop_id}&product_id=${product.product_id}`)
    }

    const handleChange = () => {};
    return (
        <div className='container'>
            {/* <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="List" value="1" />
              <Tab label="Agreements" value="2" />

            </TabList>
          </Box>
          <TabPanel value="1"></TabPanel>
          <TabPanel value="2"></TabPanel>
        </TabContext>
      </Box> */}
            <div className='d-flex mb-3'>
                <HeadingStyle
                    title={productData && productData[0]?.shop_name}
                    className='me-auto'
                />

                <Button
                    variant='outlined'
                    className='btn btn-outline-primary'
                    onClick={handleAddProductShop}
                >
                    Add product in Shop
                </Button>
            </div>

            <ProductCard data={productData} type='influencer-shop' onProductClick={handleProductClick}/>
        </div>
    );
};
