import React, { useEffect, useRef } from "react";
import "./campaign-configure-1.scss";
import * as Highcharts from "highcharts";

import HeadingStyle from "../../../components/heading-style/heading-style";
import megaplus from "../../../assets/images/campaign_icons/mega-plus.png";
import mega from "../../../assets/images/campaign_icons/mega.png";
import macroplus from "../../../assets/images/campaign_icons/macro-plus.png";
import macro from "../../../assets/images/campaign_icons/macro.png";
import microplus from "../../../assets/images/campaign_icons/micro-plus.png";
import micro from "../../../assets/images/campaign_icons/micro.png";
import nano from "../../../assets/images/campaign_icons/nano.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CampaignConfigure1: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/brand/configurator");
  };

  const handleNavigation2 = () => {
    navigate("/brand/configure-2");
  };
  const colors = (Highcharts.getOptions()?.colors ?? []).map((c, i) =>
    Highcharts.color((Highcharts.getOptions()?.colors ?? [])[0])
      .brighten((i - 3) / 7)
      .get()
  );

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      width: 163,
      height: 173,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          //   format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    series: [
      {
        name: "Share",
        type: "pie",
        data: [
          { name: "Nano", y: 74.03 },
          { name: "Micro", y: 12.66 },
          { name: "Micro+", y: 4.96 },
        ],
      },
    ],
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(chartRef.current, chartOptions);
    }
  }, []);

  function YourCard() {
    return (
      <div className="card card-project-configurator">
        <div className="d-flex flex-column align-items-center justify-content-between">
          <div className="card-title">List 1</div>
          <div className="conf-list-detail justify-content-center w-100">
            <div className="conf-list-detail-left">
              <div className="card-image">
                <div className="infl-type-card">
                  <img alt="" src={macro} />
                  <div className="infl-type-card-content">
                    <strong>Macro</strong>
                    <span>(100K - 500K)</span>
                    <div className="infl-type-card-perc">1</div>
                  </div>
                </div>
                <div className="infl-type-card">
                  <img alt="" src={microplus} className="microplus2" />
                  <div className="infl-type-card-content">
                    <strong>Micro Plus</strong>
                    <span>(50K - 100K)</span>
                    <div className="infl-type-card-perc">4</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="conf-list-detail-right">
              <div className="higchartmain" ref={chartRef}></div>
              <div className="conf-list-detail-list">
                <div className="infl-type-card">
                  <div className="infl-type-card-content">
                    <strong>Macro</strong>
                    <div className="infl-type-card-perc">₹ 40755 | 20.0 %</div>
                  </div>
                </div>
                <div className="infl-type-card">
                  <div className="infl-type-card-content">
                    <strong>Micro Plus</strong>
                    <div className="infl-type-card-perc">₹ 158302 | 80.0 %</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-content d-flex flex-column align-items-center mt-auto">
          <div className="infl-search-res-value">
            <span className="infl-search-res-value-cate">
              Total Influencers:
            </span>
            <span className="infl-search-res-value-count">5</span>
          </div>
          <div className="infl-search-res-value">
            <span className="infl-search-res-value-cate">Budget:</span>₹
            <span className="infl-search-res-value-count">199,057</span>
          </div>
          <div className="infl-search-res-value mb-2">
            <span className="infl-search-res-value-cate">ER:</span>
            <span className="infl-search-res-value-count">2.85%</span>
          </div>
          <div className="infl-search-res-value mb-2">
            <span className="infl-search-res-value-cate">Reach:</span>
            <span className="infl-search-res-value-count">190,000</span>
          </div>
          <div className="btn-center">
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={handleNavigation2}
            >
              View List:
            </button>
          </div>
        </div>
      </div>
    );
  }

  function ClanRecommendation() {
    return (
      <div className="card card-project-configurator">
        <div className="d-flex flex-column align-items-center justify-content-between">
          <div className="card-title">Recommended List 1</div>
          <div className="conf-list-detail justify-content-center w-100">
            <div className="conf-list-detail-left">
              <div className="card-image">
                <div className="infl-type-card">
                  <img alt="" src={microplus} />
                  <div className="infl-type-card-content">
                    <strong>Micro Plus</strong>
                    <span>(50K - 100K)</span>
                    <div className="infl-type-card-perc">1</div>
                  </div>
                </div>
                <div className="infl-type-card">
                  <img alt="" src={micro} />
                  <div className="infl-type-card-content">
                    <strong>Micro</strong>
                    <span>(10K - 50K)</span>
                    <div className="infl-type-card-perc">7</div>
                  </div>
                </div>
                <div className="infl-type-card">
                  <img alt="" src={nano} />
                  <div className="infl-type-card-content">
                    <strong>Nano</strong>
                    <span>(1K - 10K)</span>
                    <div className="infl-type-card-perc">50</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="conf-list-detail-right">
              <div className="higchartmain" ref={chartRef}></div>
              <div className="conf-list-detail-list">
                <div className="infl-type-card">
                  <div className="infl-type-card-content">
                    <strong>Micro Plus</strong>
                    <div className="infl-type-card-perc">₹ 20170 | 10.0 %</div>
                  </div>
                </div>
                <div className="infl-type-card">
                  <div className="infl-type-card-content">
                    <strong>Micro</strong>
                    <div className="infl-type-card-perc">₹ 28822 | 15.0 %</div>
                  </div>
                </div>
                <div className="infl-type-card">
                  <div className="infl-type-card-content">
                    <strong>Nano</strong>
                    <div className="infl-type-card-perc">₹ 102181 | 75.0 %</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-content d-flex flex-column align-items-center mt-auto">
          <div className="infl-search-res-value">
            <span className="infl-search-res-value-cate">
              Total Influencers:
            </span>
            <span className="infl-search-res-value-count">58</span>
          </div>
          <div className="infl-search-res-value">
            <span className="infl-search-res-value-cate">Budget:</span>₹
            <span className="infl-search-res-value-count">151,173</span>
          </div>
          <div className="infl-search-res-value mb-2">
            <span className="infl-search-res-value-cate">ER:</span>
            <span className="infl-search-res-value-count">4.16%</span>
          </div>
          <div className="infl-search-res-value mb-2">
            <span className="infl-search-res-value-cate">Reach:</span>
            <span className="infl-search-res-value-count">198,900</span>
          </div>
          <div className="btn-center">
            <a href="/campaign_simulator/package_influencers?package_id=65389c164cad7a6612752f46&list_name=Recommended List 1">
              <button className="btn btn-outline-primary" type="button">
                View List*
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="main-container-campaign pb-0">
      <div className="container custom-container">
        <div className="alert alert-blue mb-3" role="alert">
          <p className="mb-0">
            <strong>
              <i className="bi bi-info-circle" />
            </strong>
            These are system generated calculations based on estimations. The
            actual cost of individual influencer might differ.
          </p>
        </div>

        <div className="row">
          <div className="heading col-12 d-flex flex-wrap flex-column flex-sm-row">
            <HeadingStyle
              title="Configuration"
              className="mb-4 d-block title-w-bdr"
            />

            <Button
              variant="outlined"
              size="small"
              className="btn"
              onClick={handleNavigation}
            >
              Configure Again
            </Button>
          </div>
        </div>
        <div className="container-configuration">
          <div className="row mb-3">
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Objective: &nbsp;</strong>
              <span>Reach</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Budget: &nbsp;</strong>
              <span>₹ 200,000</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Platform: &nbsp;</strong>
              <span>Instagram</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Gender: &nbsp;</strong>
              <span>Any</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Post Types: &nbsp;</strong>
              <span>Reel</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column flex-md-row">
              <strong>Category: &nbsp;</strong>
              <span>Movies, Music</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column flex-md-row flex-wrap">
              <strong className="mr-2 mb-2 mb-md-0">
                Influencer Type: &nbsp;
              </strong>
              <div className="d-flex flex-wrap">
                <div className="infl-type-card mb-2 mb-md-1">
                  <img alt="" src={macro} />

                  <div className="infl-type-card-content">
                    <strong>Macro</strong>
                    <span>(100K - 500K)</span>
                    <div className="infl-type-card-perc"></div>
                  </div>
                </div>
                <div className="infl-type-card mb-2 mb-md-1">
                  <img alt="" src={microplus} className="microplus" />

                  <div className="infl-type-card-content">
                    <strong>Micro Plus</strong>
                    <span>(50K - 100K)</span>
                    <div className="infl-type-card-perc"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="config-result-sec">
          <HeadingStyle
            title="Your Results"
            className="mb-4 d-block title-w-bdr"
          />
          <div className="row card-project-configurator-sec">
            <div className="col-md-6 col-xl-4 d-flex">
              <YourCard />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <YourCard />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <YourCard />
            </div>
          </div>
        </div>
        <div className="config-result-sec">
          <HeadingStyle
            title="Clan Recommendations"
            className="mb-4 d-block title-w-bdr"
          />
          <div className="d-flex flex-row flex-wrap row">
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <ClanRecommendation />
            </div>
          </div>
        </div>
        <div className="config-result-sec">
          <HeadingStyle
            title="Solo Results"
            className="mb-4 d-block title-w-bdr"
          />
          <div className="d-flex flex-row flex-wrap row">
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Nano List 1</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={nano} />
                          <div className="infl-type-card-content">
                            <strong>Nano</strong>
                            <span>(1K - 10K)</span>
                            <div className="infl-type-card-perc">99</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">99</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">200,504</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">4.59%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">260,800</span>
                  </div>

                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c124cad7a6612752f3b&list_name=Nano List 1">
                      <button className="btn btn-outline-primary" type="button">
                        View List
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Nano List 2</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={nano} />
                          <div className="infl-type-card-content">
                            <strong>Nano</strong>
                            <span>(1K - 10K)</span>
                            <div className="infl-type-card-perc">53</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">53</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">112,989</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">4.4%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">145,700</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c134cad7a6612752f3c&list_name=Nano List 2">
                      <button className="btn btn-outline-primary" type="button">
                        View List:
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Nano List 3</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={nano} />
                          <div className="infl-type-card-content">
                            <strong>Nano</strong>
                            <span>(1K - 10K)</span>
                            <div className="infl-type-card-perc">53</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">53</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">114,563</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">4.34%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">141,100</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c134cad7a6612752f3d&list_name=Nano List 3">
                      <button className="btn btn-outline-primary" type="button">
                        View List.
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Micro List 1</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={micro} />
                          <div className="infl-type-card-content">
                            <strong>Micro</strong>
                            <span>(10K - 50K)</span>
                            <div className="infl-type-card-perc">40</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">40</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">200,700</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">2.62%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">255,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c114cad7a6612752f38&list_name=Micro List 1">
                      <button className="btn btn-outline-primary" type="button">
                        View List:
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Micro List 2</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={micro} />
                          <div className="infl-type-card-content">
                            <strong>Micro</strong>
                            <span>(10K - 50K)</span>
                            <div className="infl-type-card-perc">45</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">45</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">200,881</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">3.31%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">243,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c114cad7a6612752f37&list_name=Micro List 2">
                      <button className="btn btn-outline-primary" type="button">
                        View List
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Micro List 3</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={micro} />
                          <div className="infl-type-card-content">
                            <strong>Micro</strong>
                            <span>(10K - 50K)</span>
                            <div className="infl-type-card-perc">41</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">41</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">200,859</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">3.23%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">240,500</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c124cad7a6612752f3a&list_name=Micro List 3">
                      <button className="btn btn-outline-primary" type="button">
                        View List*
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Macro List 1</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={macro} />
                          <div className="infl-type-card-content">
                            <strong>Macro</strong>
                            <span>(100K - 500K)</span>
                            <div className="infl-type-card-perc">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">5</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">203,761</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">1.39%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">215,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c104cad7a6612752f32&list_name=Macro List 1">
                      <button className="btn btn-outline-primary" type="button">
                        View List*
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Macro List 2</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={macro} />
                          <div className="infl-type-card-content">
                            <strong>Macro</strong>
                            <span>(100K - 500K)</span>
                            <div className="infl-type-card-perc">5</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">5</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">203,170</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">3.17%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">175,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c0f4cad7a6612752f2f&list_name=Macro List 2">
                      <button className="btn btn-outline-primary" type="button">
                        View List
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Macro List 3</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={macro} />
                          <div className="infl-type-card-content">
                            <strong>Macro</strong>
                            <span>(100K - 500K)</span>
                            <div className="infl-type-card-perc">4</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">4</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">163,249</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">1.19%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">175,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c0f4cad7a6612752f31&list_name=Macro List 3">
                      <button className="btn btn-outline-primary" type="button">
                        View List.
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Micro Plus List 1</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={microplus} />
                          <div className="infl-type-card-content">
                            <strong>Micro Plus</strong>
                            <span>(50K - 100K)</span>
                            <div className="infl-type-card-perc">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">10</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">203,995</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">1.83%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">215,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c114cad7a6612752f35&list_name=Micro Plus List 1">
                      <button className="btn btn-outline-primary" type="button">
                        View List.
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Micro Plus List 2</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={microplus} />

                          <div className="infl-type-card-content">
                            <strong>Micro Plus</strong>
                            <span>(50K - 100K)</span>
                            <div className="infl-type-card-perc">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">10</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">201,936</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">1.54%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">195,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c104cad7a6612752f33&list_name=Micro Plus List 2">
                      <button className="btn btn-outline-primary" type="button">
                        View List
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Micro Plus List 3</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={microplus} />

                          <div className="infl-type-card-content">
                            <strong>Micro Plus</strong>
                            <span>(50K - 100K)</span>
                            <div className="infl-type-card-perc">10</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">10</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">203,573</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">4.48%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">195,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c104cad7a6612752f34&list_name=Micro Plus List 3">
                      <button className="btn btn-outline-primary" type="button">
                        View List:
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Macro Plus List 1</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={macroplus} />
                          <div className="infl-type-card-content">
                            <strong>Macro Plus</strong>
                            <span>(500K - 1M)</span>
                            <div className="infl-type-card-perc">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">1</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">200,614</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">2.19%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">200,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c0e4cad7a6612752f2b&list_name=Macro Plus List 1">
                      <button className="btn btn-outline-primary" type="button">
                        View List
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Macro Plus List 2</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={macroplus} />
                          <div className="infl-type-card-content">
                            <strong>Macro Plus</strong>
                            <span>(500K - 1M)</span>
                            <div className="infl-type-card-perc">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">1</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">200,614</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">2.19%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">200,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c0e4cad7a6612752f2c&list_name=Macro Plus List 2">
                      <button className="btn btn-outline-primary" type="button">
                        View List:
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 d-flex">
              <div className="card card-project-configurator">
                <div className="d-flex flex-column align-items-center justify-content-between">
                  <div className="card-title">Macro Plus List 3</div>
                  <div className="conf-list-detail justify-content-center w-auto">
                    <div className="conf-list-detail-left">
                      <div className="card-image">
                        <div className="infl-type-card">
                          <img alt="" src={macroplus} />
                          <div className="infl-type-card-content">
                            <strong>Macro Plus</strong>
                            <span>(500K - 1M)</span>
                            <div className="infl-type-card-perc">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content d-flex flex-column align-items-center mt-auto">
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">
                      Total Influencers:
                    </span>
                    <span className="infl-search-res-value-count">1</span>
                  </div>
                  <div className="infl-search-res-value">
                    <span className="infl-search-res-value-cate">Budget:</span>₹
                    <span className="infl-search-res-value-count">201,112</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">ER:</span>
                    <span className="infl-search-res-value-count">0.87%</span>
                  </div>
                  <div className="infl-search-res-value mb-2">
                    <span className="infl-search-res-value-cate">Reach:</span>
                    <span className="infl-search-res-value-count">200,000</span>
                  </div>
                  <div className="btn-center">
                    <a href="/campaign_simulator/package_influencers?package_id=65389c0e4cad7a6612752f2d&list_name=Macro Plus List 3">
                      <button className="btn btn-outline-primary" type="button">
                        View List.
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-center mt-2">
          <button
            className="btn btn-outline-primary mt-4 mb-5"
            type="button"
            onClick={handleNavigation}
          >
            Configure Again
          </button>
        </div>
      </div>
    </div>
  );
};
