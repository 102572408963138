import * as React from 'react';
import CommonPopUp from './common-popup';
import ConfirmPopup from '../confirm-popup/confirm-popup';

interface Props {
  children?: any;
  openUpgradeBrandModal: boolean;
  popupMinWidth?: any;
  popupMaxWidth?: any;
  onClose: () => void; // Receive onClose callback as a prop
  campaignStatusForDraft?:any;
  save_Post?: (data:any) => any;
  loading?:any;
  
}

const ShowWelcomePopUp: React.FC<Props> = ({
  children,
  openUpgradeBrandModal,
  onClose,
  popupMinWidth,
  popupMaxWidth,
  save_Post,
  campaignStatusForDraft,
  loading
}) => {
  return (
    <>
    <CommonPopUp
      // modalTitle="Welcome PopUp"
      modalContent=""
      openModal={openUpgradeBrandModal}
      onClose={onClose}
      className="modal-medium mx-auto show-welcome"
    >
      <div className="">
      <p style={{textAlign: "center"}}><strong>Welcome to the new and improved ClanConnect!</strong></p>
      <p style={{textAlign: "center"}}>We've revamped our interface to improve usability. Take a moment to explore the new look and feel. We value your feedback, so feel free to let us know if you encounter any issues or have suggestions for improvement.</p>
      <p style={{textAlign: "center"}}> Thank you for being a part of our platform's evolution!</p>
      </div>
    </CommonPopUp>
    </> 
     );
};

export default ShowWelcomePopUp;
