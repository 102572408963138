import React from 'react';
import './loader.scss';
import Lottie from 'react-lottie';
import animationData from '../../data/lottie/animationData.json';
interface props {
  width: string;
  height: string;

}

const ContentLoading: React.FC<props> = ({ width, height }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className='page-loader'>
      <Lottie
        options={defaultOptions}
        height={width}
        width={height}
        style={{ width: `${width}px`, height: `${height}px` }}
      />
    </div>

  )
};

export default ContentLoading;