import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CreateCampaign, Loading } from "../../../../../../redux/action";
import { CreateCampaign as Create_Campaign } from "../../../../../../core/services";
import { Instagram } from "@mui/icons-material";
import { DeliverablesComponent } from '../campaign-deliverables';
import { TransformDeliverables } from '../de-comman';
import YouTubeIcon from "@mui/icons-material/YouTube";
import DeleteIcon from '@mui/icons-material/Delete';
import { TrashIcon2 } from '../../../../../../assets/icons/common-icons';
import "./campaign-influencer-list.scss";
import { Link } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import { MenuPropsSelect } from "../../../../../../core/utility/style";
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
const transform: TransformDeliverables = new TransformDeliverables();
// const localService: LocalService = new LocalService();


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


type IntialFormData = {
  deliverable: string;
  ig_static_image: number | null;
  quantity: number | null;
  influencer_type: number[];
};

const intialFormData: IntialFormData = {
  deliverable: '',
  ig_static_image: null,
  quantity: null,
  influencer_type: []
};


interface Props {
  influencerList: any;
  update_influencerList: (data: any) => void;
  UserReducer: any;
  campaignReducer: any;
  CreateCampaignReducer?: (data: any) => void;
  loading: (data: any) => void;
  platformType: string;
  getValidationStatus: any,
  getSubscriptionDetail:any
}

const CampaingInfluencerList: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const [influencerList, setInfluencerList] = useState([]);
  const [userData] = useState(props.UserReducer);
  const [genre] = useState<string[]>([]);
  const [formDataCollection, setFormDataCollection] = useState<IntialFormData[]>([]);
  const [formData, setFormData] = useState<IntialFormData>(intialFormData);
  const [error, setError] = useState<number | null>();
  const [selectOpen, setSelectOpen] = useState(false);
  const [influencerType, setInfluencerType] = useState<any>();

  useEffect(() => {
    Create_Campaign.influencerList({
      user_id: userData.user_id,
      brand_id: userData.brand_id,
      list_type: props.platformType,
    }).then((res: any) => {
      if (res.data.status === 1) {
        setInfluencerList(
          res.data.rows.filter(
            (data: any) =>
              (data.list_type.toString().toLocaleLowerCase() === 
              props.campaignReducer.createCampaign.campaign_platform.toString().toLocaleLowerCase()) 
              && data.influencer_counts !== 0
          )
        );
      }
    });
  }, []);

  const handleInfluencerList = (
    event: SelectChangeEvent<typeof props.influencerList>
  ) => {
    if(props.getSubscriptionDetail && props.getSubscriptionDetail.isBasicUser && ((props.campaignReducer.createCampaign.campaign_type).toLowerCase() === 'paid')){
      const {
        target: { value },
      } = event;
      if(value.length > 1){
        props.getValidationStatus.influencer_list.status = false
        props.getValidationStatus.influencer_list.message = (
          <>
             You can select only 1 list.{' '}
            <Link to="/brand/settings/subscription">Upgrade</Link> to select more lists.
          </>
        )
        setSelectOpen(false);
      }else{
        let influencer_ids: { [key: string]: any } = {};
        let sm_ids: { [key: string]: any } = {};
        if (value.length > 0) {
          for (let index = 0; index < value.length; index++) {
            const getInfluencerId: any = influencerList.find(
              (res: any) => res.list_id === value[index]
            );
            //Influencer ids
            influencer_ids[`${value[index]}`] =
              getInfluencerId.influencer_id.split(",");
            //sm_ids
            sm_ids[`${value[index]}`] = 
              getInfluencerId.sm_id.split(",");
          }
        }
        // console.warn({
        //   influencer_list_ids: value,
        //   listInfluencers: influencer_ids,
        // });
        props.update_influencerList({
          influencer_list_ids: value,
          listInfluencers: influencer_ids,
          listInfluencer_sm_ids: sm_ids
        });
      }
    }else{
    const {
      target: { value },
    } = event;
    let influencer_ids: { [key: string]: any } = {};
    let sm_ids: { [key: string]: any } = {};
    if (value.length > 0) {
      for (let index = 0; index < value.length; index++) {
        const getInfluencerId: any = influencerList.find(
          (res: any) => res.list_id === value[index]
        );
        //Influencer ids
        influencer_ids[`${value[index]}`] =
          getInfluencerId.influencer_id.split(",");
        //sm_ids
        sm_ids[`${value[index]}`] = 
          getInfluencerId.sm_id.split(",");
      }
    }
    // console.warn({
    //   influencer_list_ids: value,
    //   listInfluencers: influencer_ids,
    // });
    props.update_influencerList({
      influencer_list_ids: value,
      listInfluencers: influencer_ids,
      listInfluencer_sm_ids: sm_ids
    });
  }};


  useEffect(()=>{
    if(props.influencerList.influencer_list_ids.length > 0){
    handlePrivateDeliverables();
    }
  },[influencerType])

  const handlePrivateDeliverables = () =>{
    if(props.campaignReducer.activeTab == 2){
        const filteredFormdataCollection = formDataCollection.filter(item => 
          item.influencer_type.every(number => influencerType.includes(number))
        );
      
        setFormDataCollection(filteredFormdataCollection);
      }
  }

  useEffect(() => {
    props.update_influencerList({ influencer_selector: transform.transformCollectionForDatabase(formDataCollection) })
  }, [formDataCollection]);

  useEffect(() => {
    /* Filter Data the data set according to platfrom like instagram, youtube and etc...  */
    let temp: any[] = [];
    let platfrom = '';
    switch ((props.platformType).toLowerCase()) {
      case 'instagram':
        platfrom = 'ig';
        break;
      case 'youtube':
        platfrom = 'yt';
        break;
      default:
        platfrom = 'ig';
        break;
    };

    Create_Campaign.getDeliverables(props.campaignReducer.createCampaign.campaign_id).then((res: any) => {
      if (res.data.status > 0) {
        for (let data of transform.transformDbForFormObj(res.data.rows)) {
          if (['digital_rights', 'available_for_exclusivity'].includes(data.deliverable)) {
            temp.push(data);
          } else if (data.deliverable.includes(platfrom)) {
            temp.push(data);
          }
        }
        setFormDataCollection(temp);
      }
    })
  }, [])


  function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function handleAddDeliverables() {
    if(formData.deliverable === "ig_static"){
      if (formData.quantity === null || formData.influencer_type.length === 0 || formData.ig_static_image === null) {
        return;
      }
    }
    if (formData.deliverable === "" ||formData.quantity === null || formData.influencer_type.length === 0) {
      return;
    }
    setFormDataCollection([...formDataCollection, formData]);
    setFormData(intialFormData);
  }

  const handleOpen = () => {
    setSelectOpen(true);
  };

  const handleClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    Create_Campaign.get_influencer_type_ids({
      platform: props.platformType,
      list_ids: props.influencerList.influencer_list_ids
    }).then((res: any) => {
      if (res.data.status === 1 && res.data.fetchedRows > 0) {
        const influencerTypeIds = res.data.rows[0].influencer_type_ids;
        const influencerTypeArray = influencerTypeIds 
          ? influencerTypeIds.split(',').map(Number) 
          : [];
        
        setInfluencerType(influencerTypeArray);
      } else {
        setInfluencerType([]);
      }
    });
  }, [props.platformType, props.influencerList.influencer_list_ids]);
  

  return (
    <div className="container-fluid p-0 influencer_list_main_container">
      <div className="row">
        <div className="col">
          {/* <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-name-label">
              Influencer List
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={props.influencerList.influencer_list_ids}
              onChange={handleInfluencerList}
              onClose={handleClose}
              onOpen={handleOpen}
              open={selectOpen}
              input={<OutlinedInput error={!props.getValidationStatus.influencer_list.status} label="Influencer List" />}
              MenuProps={MenuPropsSelect}
            >
              {influencerList.length > 0 ? (
                influencerList.map(
                  (cat: {
                    list_id: number;
                    list_name: string;
                    influencer_counts: number;
                  }) => (
                    <MenuItem
                      key={cat.list_id}
                      value={cat.list_id}
                      style={getStyles(cat.list_name, influencerList, theme)}
                    >
                      {props.campaignReducer.createCampaign.campaign_platform.toString().toLocaleLowerCase() === 'instagram' && <Instagram />}
                      {props.campaignReducer.createCampaign.campaign_platform.toString().toLocaleLowerCase() === 'youtube' && <YouTubeIcon style={{ color: 'red' }} />}
                      &nbsp; {cat.list_name} &nbsp;{" "}
                      <strong>({cat.influencer_counts})</strong>
                    </MenuItem>
                  )
                )
              ) : (
                <MenuItem
                  key={1}
                  value={0}
                  style={getStyles("No Data", genre, theme)}
                >
                  {"No Data"}
                </MenuItem>
              )}
            </Select>
          </FormControl> */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-name-label">
              Influencer List
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={props.influencerList.influencer_list_ids}
              onChange={handleInfluencerList}
              onClose={handleClose}
              onOpen={handleOpen}
              open={selectOpen}
              input={
                <OutlinedInput
                  error={!props.getValidationStatus.influencer_list.status}
                  label="Influencer List"
                />
              }
              MenuProps={MenuPropsSelect}
              renderValue={(selected) =>
                influencerList
                  .filter((cat: any) => selected.includes(cat.list_id))
                  .map((cat: any, index: number) => {
                    const platform = props.campaignReducer.createCampaign.campaign_platform.toString().toLocaleLowerCase();
                    return (
                      <span key={cat.list_id}>
                        {platform === 'instagram' && <Instagram />}
                        {platform === 'youtube' && <YouTubeIcon style={{ color: 'red' }} />}
                        &nbsp;{cat.list_name}
                        {index < selected.length - 1 && ', '} {/* Adds comma between items */}
                      </span>
                    );
                  })
              }
            >
              {influencerList.length > 0 ? (
                influencerList.map((cat: { list_id: number; list_name: string; influencer_counts: number; }) => {
                  const selected = props.influencerList.influencer_list_ids.includes(cat.list_id);
                  return (
                    <MenuItem
                      key={cat.list_id}
                      value={cat.list_id}
                      style={getStyles(cat.list_name, influencerList, theme)}
                    >
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {props.campaignReducer.createCampaign.campaign_platform.toString().toLocaleLowerCase() === 'instagram' && <Instagram />}
                      {props.campaignReducer.createCampaign.campaign_platform.toString().toLocaleLowerCase() === 'youtube' && <YouTubeIcon style={{ color: 'red' }} />}
                      &nbsp; {cat.list_name} &nbsp;{" "}
                      <strong>({cat.influencer_counts})</strong>
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem
                  key={1}
                  value={0}
                  style={getStyles("No Data", genre, theme)}
                >
                  {"No Data"}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {!props.getValidationStatus.influencer_list.status && <span className="error">{props.getValidationStatus.influencer_list.message}</span>}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <DeliverablesComponent
            activeTab = {props.campaignReducer.activeTab}
            influencerType = {influencerType}
            formDataCollection={formDataCollection}
            value={formData}
            deliverable_type={formData.deliverable}
            platform={props.platformType}
            onChange={(values: any) => setFormData(values)} />
            {!props.getValidationStatus.deliverables.status && <span className="error">{props.getValidationStatus.deliverables.message}</span>}
        </div>
        <div className="col-auto">
          <button onClick={handleAddDeliverables} className="btn btn-primary">Add Deliverable</button>
        </div>
      </div>
      <div className="row">
        <div className="col mt-3">
          {
            formDataCollection.map((data, index) => {
              let deliverable = <div className="row mb-3" key={index}>
                <div className="col">
                  <DeliverablesComponent
                    activeTab = {props.campaignReducer.activeTab}
                    influencerType = {influencerType}
                    platform={props.platformType}
                    disableDeliverables={true}
                    disableNoOfImages={true}
                    formDataCollection={formDataCollection}
                    deliverable_type={data.deliverable}
                    value={data}
                    error={error === index && true}
                    onChange={(values: any) => {
                      if(values.influencer_type.length > 0){
                        let temp = [...formDataCollection];
                        temp[index] = values;
                        setFormDataCollection(temp);
                        setError(null);
                      }else{
                        setError(index);
                      }
                    }} />
                </div>
                <div className="col-auto">
                  <span onClick={() => {
                    let temp = [...formDataCollection];
                    temp.splice(index, 1);
                    setFormDataCollection(temp);
                  }}>
                    <TrashIcon2 size={30} />
                  </span>
                </div>
              </div>

              return deliverable;
            }
            )
          }
        </div>
      </div>
      <hr />
      {props.campaignReducer.createCampaign.campaign_type
        .toString()
        .toLocaleLowerCase()
        .includes("paid") && (
          <div className="row">
            <div className="col">
              <FormControl>
                <FormLabel id="id_rb-costing-only">
                  {" "}
                  <span className="field_header"> Costing Model</span>{" "}
                </FormLabel>
                <RadioGroup
                  defaultValue="individual"
                  onChange={(e) =>
                    props.update_influencerList({
                      costing_module: e.target.value,
                    })
                  }
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="rb-costing-only"
                >
                  <div className="d-flex flex-column">
                    <FormControlLabel
                      value="individual"
                      control={<Radio />}
                      label="Individual Deliverable Costing"
                    />
                    <FormHelperText>
                      <ul style={{listStyle:'inherit'}}>
                        <li>This model provides the cost for each deliverable individually.</li>
                        <li>It allows you to negotiate the price for each deliverable separately.</li>
                        <li>Ideal if you have not yet finalized the exact deliverables.</li>
                      </ul>
                    
                    </FormHelperText>
                  </div>
                  <div className="d-flex flex-column">
                  <FormControlLabel
                    value="package"
                    control={<Radio />}
                    label="Package Costing"
                  />
                   <FormHelperText>
                      <ul style={{listStyle:'inherit'}}>
                      <li>This model offers a single package cost for all deliverables combined.</li>
                      <li>It enables you to negotiate the total price at the package level.</li>
                      <li>Best suited if you have finalised all the deliverables in advance.</li>
                      </ul>
                    
                    </FormHelperText>
                    </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    UserID: state.UserID.userID,
    getSubscriptionDetail: state.Subscription.subscription_detail
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaingInfluencerList);

export { connectToRedux as CampaingInfluencerList };
