import React, { useState, useEffect } from "react";
import { connect } from "react-redux";


import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Editor } from "../../../../../components";
import { CreateCampaign, Loading } from "../../../../../redux/action";

import HeadingStyle from "../../../../../components/heading-style/heading-style";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { CreateCampaign as Create_Campaign, LocalService } from "../../../../../core/services";
import { FormValidation } from "../../../../../core/utility";

import "./plan-create.scss";

const formValidation = new FormValidation();
const localService = new LocalService();

interface Props {
    UserReducer: any;
    UserID: any;
    campaignReducer: any;
    CreateCampaignReducer?: (data: any) => void;
    loading: (data: any) => void;
}

const PlanCreate: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [staticData] = useState({
        industry: props.UserReducer.brand_category,
        category: props.UserReducer.brand_category,
        organization: props.UserReducer.brand_organization,
        brand: props.UserReducer.brand_name,
    });
    const [formData, setFormData] = useState({
        brand_id: props.UserReducer.brand_id,
        user_id: props.UserReducer.user_id,
        plan_id: props.campaignReducer.createPlan?.plan_id,
        plan_name: "",
        plan_description: "",
        plan_product_name: "",
        ...props.campaignReducer.createPlan,
    });

    //Show to Errors
    const [errors, setErrors] = useState({
        product: { status: true, error: "" },
        planName: { status: true, error: "" },
        description: {
            status: true,
            error: "",
        },
    });
    const navigate = useNavigate();
    //Api Section
    const handleSave = async () => {
        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);
            Create_Campaign.createPlan(formData)
                .then((res: any) => {
                    if (res?.data?.rows?.status) {
                        if (props.campaignReducer.createCampaign === null ||props.campaignReducer?.createCampaign?.campaign_type == "Analytics") {
                            if (props.CreateCampaignReducer) {
                                props.CreateCampaignReducer({
                                    createCampaign: {
                                        campaign_id:
                                            res?.data?.rows.campaign_id,
                                    },
                                });
                            }

                        }

                        if (props.CreateCampaignReducer) {
                            setFormData({
                                ...formData,
                                plan_id: res?.data?.rows?.plan_id,
                                
                            });
                            props.CreateCampaignReducer({
                                createPlan: {
                                    ...formData,
                                    plan_id: props.campaignReducer.createPlan
                                        ?.plan_id
                                        ? props.campaignReducer.createPlan
                                            ?.plan_id
                                        : res?.data?.rows?.plan_id,
                                        campaign_id:
                                        res?.data?.rows.campaign_id ? res?.data?.rows.campaign_id : res?.data?.rows.campaign_id
                                },
                                
                            });
                        }

                        localService.toastify(res?.data?.rows?.message, 'success');
                        navigate("/brand/plans");
                    } else {
                        localService.toastify(res?.data?.rows?.message, 'error');
                    }
                    setLoading(false);

                    resolve(res?.data?.rows);
                })
                .catch((e) => {
                    throw new Error(`Create Plan Error :: ${e}`);
                })
                .finally(() => {

                });
        });

        return promise;
    };

    const handleNext = async () => {
        const productError = formValidation.GetNameValidate(
            formData.plan_product_name
        );
        const planError = formValidation.GetNameValidate(formData.plan_name);
        const desError = formValidation.getdescriptionIsValid(
            formData.plan_description
        );
        setErrors({
            ...errors,
            product: {
                status: productError.status,
                error: productError.message,
            },
            planName: { status: planError.status, error: planError.message },
            description: { status: desError.status, error: desError.message },
        });

        //validate form
        if (productError.status && planError.status && desError.status) {
            const response: any = await handleSave();

            if (response.status) {
                if (props.CreateCampaignReducer) {
                    props.CreateCampaignReducer({
                        navigation: {
                            ...props.campaignReducer.navigation,
                            campaign_create: true,
                        },
                        completeStep: {
                            ...props.campaignReducer.completeStep,
                            createPlan: true,
                        },
                    });
                }
                if(props.campaignReducer?.createCampaign?.campaign_type == "Analytics"){
                    navigate("/brand/internal-campaign");
                }
                else{
                    navigate("/brand/campaign/createcampaign");
                }
            }
        }
    };

    useEffect(() => {

    }, [props.CreateCampaignReducer])

    return (
        <div className="container plan_main_container">
            <form>
                <div className="plan_main_row ">
                    <div className="row">
                        <div className="col-12">
                            <HeadingStyle
                                title="Create New Plan"
                                className="mb-4 d-block"
                            />
                        </div>

                        <div className="col-6 mb-3">
                            <TextField
                                className="w-100"
                                size="small"
                                type="text"
                                value={staticData.industry}
                                InputProps={{ readOnly: false }}
                                name="industry "
                                id="industry_id"
                                label="Industry"
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <TextField
                                className="w-100"
                                size="small"
                                type="text"
                                value={staticData.organization}
                                InputProps={{ readOnly: true }}
                                name="organization "
                                id="organization_id"
                                label="Organization"
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <TextField
                                className="w-100"
                                size="small"
                                type="text"
                                value={staticData.brand}
                                InputProps={{ readOnly: true }}
                                name="product_brand"
                                id="product_brand_id"
                                label="Product Brand"
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <TextField
                                className="w-100"
                                size="small"
                                type="text"
                                value={staticData.category}
                                InputProps={{ readOnly: true }}
                                name="product_category"
                                id="product_category_id"
                                label="Product Category"
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <TextField
                                className="w-100"
                                size="small"
                                value={formData.plan_product_name}
                                type="text"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        plan_product_name: e.target.value,
                                    })
                                }
                                error={!errors.product.status}
                                helperText={errors.product.error}
                                name="plan_prduct_name"
                                id="product_name_id"
                                label="Product Name"
                            />
                        </div>

                        <div className="col-6 mb-3">
                            <TextField
                                className="w-100"
                                size="small"
                                type="text"
                                value={formData.plan_name}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        plan_name: e.target.value,
                                    })
                                }
                                error={!errors.planName.status}
                                helperText={errors.planName.error}
                                name="plan_name"
                                id="plan_name_id"
                                label="Plan Name "
                            />
                        </div>
                        <span><strong>Description</strong></span>
                        <div className="col-12">
                            <Editor
                                editorValues={formData.plan_description}
                                getEditorValue={(e) =>
                                    setFormData({
                                        ...formData,
                                        plan_description: e,
                                    })
                                }
                            />
                            <span className="error">
                                {errors.description.error}
                            </span>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                className="me-2 btn btn-outline-primary"
                                variant="outlined"
                                onClick={handleSave}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                className=" btn btn-primary"
                                loading={loading}
                                loadingPosition="start"
                                variant="contained"
                                onClick={handleNext}
                            >
                                Next
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(PlanCreate);

export { connectToRedux as PlanCreate };
