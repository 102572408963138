import React, { useState } from 'react';
import { Grid, TextField, Button, TextareaAutosize, InputLabel } from '@mui/material';
import { FormValidation } from '../../core/utility';
import { PublicService } from '../../core/services';
import { toast } from 'react-toastify';
import ContentLoading from '../loader/loading';

const formValidation = new FormValidation();
const publicService = new PublicService();

interface Props {
    userReducer:any;
    setOpenModal:any;
}
export const LetUsHelpYou: React.FC<Props> = ({userReducer, setOpenModal}) => {
  const [formData, setFormData] = useState<any>({
    purpose: 'brand_help_query',
    full_name: userReducer.full_name,
    company_name: userReducer.brand_name,
    email_id: userReducer.login_email,
    mobile: userReducer.login_mobile,
    message: '',
    user_id: userReducer.user_id,
    account_id: userReducer.account_id
  });
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [errors, setErrors] = useState<any>({
    full_name: '',
    company_name: '',
    email_id: '',
    mobile: '',
    message: '',
  });

  const validateField = (name:any, value:any) => {
    let error = '';
    switch (name) {
      case 'full_name':
        if (!value) error = 'Name is required';
        break;
      case 'company_name':
        if (!value) error = 'Brand Name is required';
        break;
      case 'email_id':
        const email_validation = formValidation.GetEmailControlIsValid(value);
        if (!email_validation.status) error = email_validation.message;
        break;
      case 'mobile':
        const mobile_validation = formValidation.GetMobileControlIsValid(value);
        if (!mobile_validation.status) error = mobile_validation.message;
        break;
      case 'message':
        if (value.length < 50) error = 'Comments must be at least 50 characters long';
        break;
      default:
        break;
    }
    return error;
  };

  const handleOnChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleOnSubmit = async (e:any) => {
    e.preventDefault();

    // Validate all fields before submitting
    const newErrors:any = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      newErrors[field] = error;
    });
    setErrors(newErrors);

    // Check if there are no errors
    const isValid = Object.values(newErrors).every((error) => !error);
    if (!isValid) {
      console.log('Form has errors:', newErrors);
      return;
    }else{
      setIsSubmitting(true); // Disable button when form is submitting
      try {
        // formData['user_id'] = userReducer.user_id
        // formData['account_id'] = userReducer.account_id
        const response = await publicService.requestADemo(formData);
        if (response.data.rows.status) {
          toast.success(response.data.rows.message);
        } else {
          toast.info(response.data.rows.message);
        }
        setOpenModal(false);
      } catch (error) {
        console.error('Error during form submission:', error);
        toast.error('An error occurred during submission.');
      } finally {
        setIsSubmitting(false); // Re-enable button after submission
      }
    }
};

  return (
    <>
    {isSubmitting && <ContentLoading width='150' height='150' />}
    {!isSubmitting &&
    <div className='let-us-help-form-container'>
        <p>
        Need help with running the first campaign or any other support?<br/>
       </p>
       <p>
        Please submit the details below and the team will reach out to you<br/>
         within 24 hours to provide support.
        </p>
      <form onSubmit={handleOnSubmit} >
        <Grid container spacing={2.5}>
          <Grid item sm={12} md={12} xs={12} xl={12}>
            <TextField
              className="w-100 input-style"
              size="small"
              id="name"
              name="full_name"
              value={formData.full_name}
              onChange={handleOnChange}
              placeholder="Clan Brand"
              label="Name"
              variant="outlined"
              error={!!errors.full_name}
              helperText={errors.full_name}
            />
            </Grid>
             <Grid item sm={12} md={12} xs={12} xl={12}><TextField
              className="w-100 input-style"
              size="small"
              id="company_name"
              name="company_name"
              value={formData.company_name}
              onChange={handleOnChange}
              placeholder="Clan Brand"
              label="Brand Name"
              variant="outlined"
              error={!!errors.company_name}
              helperText={errors.company_name}
            /></Grid>

             <Grid item sm={12} md={12} xs={12} xl={12}><TextField
              className="w-100 input-style"
              size="small"
              id="email_id"
              name="email_id"
              value={formData.email_id}
              onChange={handleOnChange}
              placeholder="clan.brand@clanconnect.ai"
              label="Email"
              variant="outlined"
              error={!!errors.email_id}
              helperText={errors.email_id}
            /></Grid>

             <Grid item sm={12} md={12} xs={12} xl={12}><TextField
              className="w-100 input-style"
              size="small"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleOnChange}
              placeholder="Mobile number without country code"
              label="Mobile"
              variant="outlined"
              error={!!errors.mobile}
              helperText={errors.mobile}
            /></Grid>
             <Grid item sm={12} md={12} xs={12} xl={12}>
                <InputLabel>
                Comments</InputLabel>
            <TextareaAutosize
              className="w-100 input-style"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleOnChange}
              placeholder="Minimum 50 characters"
              minRows={3}
              style={{ width: '100%', padding: '8px' }}
              minLength={50}
              
            />
            {errors.message && <div style={{ color: 'red', marginTop: '8px' }}>{errors.message}</div>}
            </Grid>
            <Grid item sm={12} md={12} xs={12} xl={12}>
                <div className='popup-footer mt-0'>
                    <div className="d-flex justify-content-end">
                    <Button
            className="btn btn-primary"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            //   style={{ marginTop: '16px' }}
            //   disabled={Object.values(errors).some((error) => error)}
            >
              Submit
            </Button>
                    </div>
                </div>
           
            </Grid>
          </Grid>
        
      </form>
    </div>
    }</>
  );
};
