import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { LocalService } from '../core/services';
import { setGuestUser, setCart } from '../redux/action';
import { SET_CART } from '../redux/types';

interface Props {
    guest_user: any;
    Component: any;
    setGuestUser: (data: any) => void;
    cartReducer: any;

    // IsUserLogged: (data: any) => void;
}

const localService = new LocalService();

const ClanShopRoute: React.FC<Props> = (props: Props) => {
    const { Component } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const guest_user_login: any =
            localService.getStorageItem('guest_user_login');
        const cart: any = localService.getStorageItem('cart');
        if (guest_user_login) {
            props.setGuestUser(JSON.parse(guest_user_login));
            dispatch({ type: SET_CART, payload: cart });
        }
    }, []);
    return (
        <>
            {props.guest_user && <Component props={props} />}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        guest_user: state.guestUserReducer.guest_user,
        cartReducer: state.cartReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        // IsUserLogged: (data: any) => dispatch(UserLogin(data)),
        setGuestUser: (data: any) => dispatch(setGuestUser(data)),
        cartReducer: (data: any) => dispatch(setCart(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ClanShopRoute);

export { connectToRedux as ClanShopRoute };
