import React from 'react';

import referEarn from '../../assets/images/refer-earn.jpg';

type Props = {};

export default function InfluencerContent({ }: Props) {
    return (
        <>
            <span className='_influencer-content-sec-title pb-3 d-none d-lg-block'>
                Welcome!
            </span>
            <div className='signup-text-sec'>
                <div className='brand-info-box'>
                    <ul id="brand-info-box-ul">
                        <span
                            style={{
                                paddingLeft: "24px",
                                paddingBottom: "5px",
                                display: "inline-block",
                            }}
                        >
                            Get access to
                        </span>
                        <li>
                            <strong>Unlimited campaign opportunities.</strong>
                        </li>
                        <li>
                            Work with
                            <strong> multiple </strong>
                            brands.
                        </li>
                        <li>
                            <strong>Earn </strong>
                            what you deserve.
                        </li>
                        <li>
                            <strong>100% </strong>
                            transparent.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
