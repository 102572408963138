import React, { useEffect, useState } from 'react'

type Props = {
  updateProposal?: any
  setNegotiateVisible?: any;
  data?: any
  handleAcceptConfirm?: any;
  handleShortListConfirm?: any;
  handleRejectConfirm?: any;
  campaignType?: any
}
const ProposalActionBtn = ({ updateProposal, setNegotiateVisible, data, handleAcceptConfirm, handleRejectConfirm, campaignType, handleShortListConfirm }: Props) => {
  const [negoAllAcceptReject, setNegoAllAcceptReject] = useState(false);
  useEffect(()=>{
    const allowToNegotiate = data.some((proposal: any) => {
      const statusKey = `s_${proposal.current_step -1}_status`;
      const status = proposal[statusKey];
      return status === "Pending" || status === "Negotiating";
    });
      setNegoAllAcceptReject(allowToNegotiate)
  },[])
  return (<div className='proposal-action-btn'>

    {data && (data[0]?.proposal_status !== "Shortlisted") && (data[0]?.proposal_status !== "Negotiating") && <button className='btn btn-outline-primary' 
      onClick={() => handleShortListConfirm()}>Shortlist</button>}
   

    <button className='btn btn-outline-error' onClick={() => handleRejectConfirm()}>Reject</button>
    { campaignType !=="Barter" && negoAllAcceptReject &&(data && (data[0]?.current_step === 1 || data[0]?.current_step === 3)) && (
      <button className='btn btn-outline-primary' onClick={(() => {
        setNegotiateVisible(true)
      })}>Negotiate</button>)
    }
    <button className='btn btn-primary' onClick={(() => handleAcceptConfirm())}>Accept</button>
  </div>
  )
}

export default ProposalActionBtn 