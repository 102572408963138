import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
 // Import your action types // Import your service
 // Import your local storage service
import { SET_CART } from '../redux/types';
import { ClanShopService } from '../core/services/clan.shop.service';
import { LocalService } from '../core/services';
const localService = new LocalService()
export function useCart() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeCart = async () => {
      const user_id = localService.getStorageItem('user_id');
      const guest_user_login = localService.getStorageItem('guest_user_login');
      const savedCart = localStorage.getItem('cart');

      if (user_id && guest_user_login) {
        const product_ids = '';
        try {
          const res = await ClanShopService.getUserCartDetails(user_id, product_ids);
          if (res.data.status === 1) {
            const cartDataFromResponse = res?.data?.rows;
            const combinedCart = [...cartDataFromResponse];
            dispatch({
              type: SET_CART,
              payload: {
                cartList: combinedCart,
                isLoggedIn: true,
              },
            });
          }
        } catch (error) {
          console.error('Error fetching user cart:', error);
        }
      } else if (savedCart) {
        const parsedCart = JSON.parse(savedCart);
        dispatch({
          type: SET_CART,
          payload: {
            cartList: parsedCart,
            isLoggedIn: false,
          },
        });
      }
    };

    initializeCart();
  }, [dispatch]);
}