import * as React from 'react';
import { Button } from "@mui/material";
import CommonPopUp from '../popup/common-popup';

interface Props {
    openModal: boolean;
    profileViewCount: number;
    profileViewLimit: number;
    popupMinWidth?: any;
    popupMaxWidth?: any;
    handleFocProfileView: () => void;
    onClose: () => void; // Receive onClose callback as a prop
}

const ProfileViewRestriction: React.FC<Props> = ({
    openModal,
    onClose,
    profileViewCount,
    profileViewLimit,
    handleFocProfileView,
    popupMinWidth,
    popupMaxWidth,
}) => {
    return (
        <CommonPopUp
            modalTitle="View Profile"
            modalContent=""
            openModal={openModal}
            onClose={onClose}
            className="modal-medium mx-auto"
        >
            <div className="mb-4">
                <div className="">
                    <p>
                        {(profileViewCount === 1) ? `As a free user, you will get ${profileViewLimit} profile views. This is your ${profileViewCount} profile view. If you continue, you will be left with ${profileViewLimit - 1} profile views.` : `This is your ${profileViewCount}/${profileViewLimit} profile view. If you continue, you will be left with ${profileViewLimit - profileViewCount} profile views for this month.`}
                    </p>

                    <p className="inline">Do you wish to continue?</p>
                </div>

                <div className='popup-footer' style={{ padding: '24px 0px 2px' }}>
                    <div className='row'>
                        <div className='col text-end' style={{ padding: '0px 44px' }}>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className="btn btn-outline-primary btn-mui ms-2"
                                    onClick={() => onClose()}
                                    variant="contained"
                                >
                                    No
                                </Button>
                                <Button
                                    className="btn btn-primary btn-mui  ms-2"
                                    onClick={() => handleFocProfileView()}
                                    variant="outlined"
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CommonPopUp>
    );
};

export default ProfileViewRestriction;