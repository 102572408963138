import {FormControlLabel, Stack,Typography, styled,  } from "@mui/material";
import Switch from "@mui/material/Switch";
import zIndex from "@mui/material/styles/zIndex";

interface SwitchProps {
    handleSwitch: (index: number, negotiable: boolean) => void;
    index: number;
    className?:string;
  }
  const Android12Switch = styled(Switch)(({ theme:any }) => ({
    padding: 8,
    '& .MuiSwitch-root': {
      width: 70,
      height: 40,
    },
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        content:'"Yes"',
        left: 14,
        color:'#fff',
        top:16
      },
      '&::after': {
        content:'"No"',
        right: 16,
        zIndex: 2,
        color:'#fff',
        top:16
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      margin: 0,
      BorderRadius: 28
    },
  }));
const SwitchButton: React.FC<SwitchProps> = (props: SwitchProps) => {
 
    const label = { inputProps: { "aria-label": "Switch demo" } };
    return (<Stack className={props.className} direction="row" spacing={1} alignItems="center">
      {/* <Switch
        id={`id_${props.index}_switch`}
        {...label}
        defaultChecked
        onChange={(e, b) => props.handleSwitch(props.index, b)}
      /> */}
      <FormControlLabel
        control={<Android12Switch defaultChecked  onChange={(e, b) => props.handleSwitch(props.index, b)} />}
        label=""
      />
    </Stack>
    );
};
  
export default SwitchButton;