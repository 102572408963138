import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DefaultAccountLogo } from '../../../config/config';
import CommonPopUpCentered from '../../../components/popup/common-popup-centered';

const SelectPostReel = () => {
  const [selectedPosts, setSelectedPosts] = useState<number[]>([]); // State to track selected posts (multiple)
   const [openAdditionalSettingsModal, setOpenAdditionalSettingsModal] = useState(false)

  const handlePostClick = (index:any) => {
    // Toggle selection of the post
    setSelectedPosts((prevSelectedPosts:any) =>
      prevSelectedPosts.includes(index)
        ? prevSelectedPosts.filter((post:any) => post !== index) // Deselect if already selected
        : [...prevSelectedPosts, index] // Add to selection if not selected
    );
  };
  const handleMorePosts = () => setOpenAdditionalSettingsModal(true);
    const handleCloseMorePosts = () => {
    setOpenAdditionalSettingsModal(false);
  }
  return (
    <>
   <li>
      <span className='pb-3 d-inline-flex fs-14'>Select the Post or Reel you want to use in automation.</span>
      <div className='specific-post-container'>
        <div className='d-flex fs-14 mb-3'>
          <span>Specific Post or Reel</span>
          <Link to="javascript:void(0)" className='ms-auto' onClick={handleMorePosts}>See more</Link>
        </div>
        <div className='post-image-container'>
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              className={`post-image ${selectedPosts.includes(index) ? 'active' : ''}`} // Add 'active' class if selected
              onClick={() => handlePostClick(index)} // Handle post selection
              style={{ cursor: 'pointer' }} // Make the image clickable
            >
              <img
                src={DefaultAccountLogo.brand}
                alt={`Post ${index + 1}`}
                style={{
                  width: '98px',
                  height: '98px',
                  border: selectedPosts.includes(index) ? '1px solid #000' : 'none', // Optional styling for active state
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </li>
         <CommonPopUpCentered
        centered
        className={`popup-see-more modal-medium  mx-auto`}
        openModal={openAdditionalSettingsModal}
        modalTitle=""
        modalContent=""
        onClose={() => handleCloseMorePosts()}>
        <div className="popup-content">
          <ul>
            <li>
              <div className='post-image-container'>
              {[...Array(9)].map((_, index) => (
                <div
                  key={index}
                  className={`post-image ${selectedPosts.includes(index) ? 'active' : ''}`} // Add 'active' class if selected
                  onClick={() => handlePostClick(index)} // Handle post selection
                  style={{ cursor: 'pointer' }} // Make the image clickable
                >
                  <img
                    src={DefaultAccountLogo.brand}
                    alt={`Post ${index + 1}`}
                    style={{
                      width: '98px',
                      height: '98px',
                      border: selectedPosts.includes(index) ? '2px solid #000' : 'none', // Optional styling for active state
                    }}
                  />
                </div>
              ))} 
            </div>
            </li>
          </ul>
        </div>
      </CommonPopUpCentered>
    </>
  );
};

export default SelectPostReel;
