import React from 'react'
import { TableComponent } from '../../../../../components/table-component/table'
import { TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { DefaultUserProfile } from '../../../../../config/config';
import { Methods } from '../../../../../core/utility';
import { Link } from 'react-router-dom';


const methods: Methods = new Methods()
type Props = {
  postFilterData?: any;
  columnDisplayName: any;
  orderByColumnName?: any;
  inputDataSource: any;
  sortingColumnType: any;
  renderData: any;
  setRenderData: any;
  selectCampaign?: any;
  platform?:any;
  sampleData?:boolean;
}

const SampleDetailedInsightsInfluencer = (props: Props) => {
  return (
    <>
      {
        (() => {
          if (props?.inputDataSource.length > 0) {
            return (
              <>
                <TableComponent
                  columnDisplayName={props?.columnDisplayName}
                  orderByColumnName=""
                  inputDataSource={props?.inputDataSource}
                  sortingColumnType={props?.sortingColumnType}
                  renderData={props?.renderData}
                  setRenderData={props?.renderData}
                >
                  {props?.inputDataSource?.map((data: any, index: number) => {
                    return (
                      <TableRow key={index} hover role="checkbox">
                        <TableCell>
                          <div className="grid-div-post d-flex">
                             
                            
                           
                            <img
                              src={
                                props?.platform === 'Instagram'
                                  ? ((data?.gcp_profile_pic_url && data?.gcp_profile_pic_url !== '') ? data?.gcp_profile_pic_url : DefaultUserProfile.influencer_unknown_default)
                                  : props?.platform === 'Youtube'
                                    ? ((data?.profile_image_url && data?.profile_image_url !== '') ? data?.profile_image_url : DefaultUserProfile.influencer_unknown_default)
                                    : DefaultUserProfile.influencer_unknown_default
                              }
                              alt={props?.platform === 'Instagram'
                                ? data?.gcp_profile_pic_url
                                : data?.profile_image_url}
                              className="grid-image me-2"
                            />
                  
                            
                            <div className='d-flex flex-column  align-items-start'>
                              {/* INSTAGRAM */}
                              {props?.platform === 'Instagram' &&
                                <span className='d-block'>{data.full_name}</span>
                              }
                              {props?.platform === 'Youtube' &&
                                <span className='d-block'>{data.title}</span>
                              }
                              <Link target='_blank' className='btn btn-text-link' to={props?.platform === 'Instagram' ? `https://www.instagram.com/${data?.username}` : `https://www.youtube.com/${data?.custom_url}`}>
                                {props?.platform === 'Instagram' ? data.username : data?.custom_url}
                              </Link>
                            </div>
                            
                          </div>
                        </TableCell>
                        <TableCell>{data?.total_post}</TableCell>
                        <TableCell>  {data?.total_er !== undefined && data?.total_er !== null ? `${data.total_er}%` : 'NA'}</TableCell>
                        <TableCell>{methods.formatInfluencerProfileNumber(data?.video_plays ? data?.video_plays : data?.total_views )}</TableCell>
                        <TableCell>{methods.formatInfluencerProfileNumber(data?.total_likes)}</TableCell>
                        <TableCell>{methods.formatInfluencerProfileNumber(data?.total_comments)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableComponent>
              </>
            )
          }

        })()

      }
    </>
  )
}

export default SampleDetailedInsightsInfluencer