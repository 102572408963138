import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { DefaultUserProfile } from '../../../../config/config';
import { Link } from 'react-router-dom';

type Props = {
  sortedData: any;
  selectCampaign?: any;
  sampleData?:boolean;
  platform?:string;
}

export const MostEngagingInfluencer = (props: Props) => {
  return (
    <div className="Most-Engaging-Posts infl">
      <Grid container spacing={2}>
        {props?.sortedData?.map((item: any) => (
          <Grid item xs={12} md={4} lg={2}>
            {!props.sampleData &&
            <div className="grid-div align-items-start w-100">
              {props?.selectCampaign?.campaign_platform === 'Instagram' &&
                <Link className='w-100' target='_blank' to={`https://www.instagram.com/${item?.username}`}>
                  <img src={item?.gcp_profile_pic_url && item?.gcp_profile_pic_url ? item?.gcp_profile_pic_url : DefaultUserProfile.influencer_unknown_default} alt={item?.username} className="grid-image" />
                </Link>
              }
              {props?.selectCampaign?.campaign_platform === 'Youtube' &&
                <Link target='_blank' to={`https://www.youtube.com/${item.custom_url}`}>
                  <img src={item?.profile_image_url ? item?.profile_image_url : DefaultUserProfile.influencer_unknown_default} alt={item?.username} className="grid-image" />
                </Link>
              }

              {/* <span>{props?.selectCampaign?.campaign_platform === 'Instagram' ? item?.username : item?.custom_url}</span> */}

              {props?.selectCampaign?.campaign_platform === 'Instagram' &&
                <Link target='_blank' className='btn btn-text-link mb-1' to={`https://www.instagram.com/${item?.username}`}>
                  {item?.username}
                </Link>
              }

              {props?.selectCampaign?.campaign_platform === 'Youtube' &&
                <Link target='_blank' className='btn btn-text-link mb-1' to={`https://www.youtube.com/${item.custom_url}`}>
                  {item?.custom_url}
                </Link>
              }
              {props?.selectCampaign?.campaign_platform === 'Instagram' &&
                <span>ER {item?.total_er}%</span>
              }
            </div>
            }
            {props.sampleData &&
            <div className="grid-div align-items-start w-100">
              {props?.platform === 'Instagram' &&
                <Link className='w-100' target='_blank' to={`https://www.instagram.com/${item?.username}`}>
                  <img src={item?.gcp_profile_pic_url && item?.gcp_profile_pic_url ? item?.gcp_profile_pic_url : DefaultUserProfile.influencer_unknown_default} alt={item?.username} className="grid-image" />
                </Link>
              }
              {props?.platform === 'Youtube' &&
                <Link target='_blank' to={`https://www.youtube.com/${item.custom_url}`}>
                  <img src={item?.profile_image_url ? item?.profile_image_url : DefaultUserProfile.influencer_unknown_default} alt={item?.username} className="grid-image" />
                </Link>
              }

              {/* <span>{props?.platform === 'Instagram' ? item?.username : item?.custom_url}</span> */}

              {props?.platform === 'Instagram' &&
                <Link target='_blank' className='btn btn-text-link mb-1' to={`https://www.instagram.com/${item?.username}`}>
                  {item?.username}
                </Link>
              }

              {props?.platform === 'Youtube' &&
                <Link target='_blank' className='btn btn-text-link mb-1' to={`https://www.youtube.com/${item.custom_url}`}>
                  {item?.custom_url}
                </Link>
              }
              {props?.platform === 'Instagram' &&
                <span>ER {item?.total_er}%</span>
              }
            </div>
            }
          </Grid>
        ))}
      </Grid>
    </div>);
}
