import { Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { DummyCampaignImage } from '../../../config/config';
import IconImpression from '../../../components/icons/icon-impression';
import MaterialSymbol from '../../../components/icons/material-symbol';
import { Methods } from '../../../core/utility';

type Story = {
  media_id: string;
  gcp_media_url: string;
  gcp_story_url: string;
  impressions: number;
  reach: number;
  username: string;

};

type Props = {
  loading?: boolean;
  influencerData: {
    top_stories: Story[];
  };
};


const methods = new Methods()

const InsightMediaDetailCard = (props: Props) => {
  const influencerData = props.influencerData;
  return (
    <Grid container spacing={3}>
      {influencerData?.top_stories?.map((story: Story) => (
        <Grid key={story?.media_id} item className='posts-story-grid'>
          <div className="card-posts card-posts-stories">
            <span
              className="card-posts-image embed-responsive embed-responsive-1by1"
              >
              {props?.loading ? <Skeleton width="100%" height={200} /> :
                <img
                  className="embed-responsive-item"
                  src={(story?.gcp_story_url === 'undefined' || story?.gcp_story_url === '') ? DummyCampaignImage.default_campaign_image : encodeURI(story?.gcp_story_url)}
                  width="100%"
                  alt={story.username}
                />
              }
            </span>
            {(story?.impressions > 0 || story?.reach > 0) &&
            <div className="card-posts-info">
              <ul>
                {story?.impressions > 0 &&
                  <li>
                    <Tooltip title="Impressions" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='mystery' fontweight='200' className='text-white' />
                      </IconButton>
                    </Tooltip>
                    <span>{story?.impressions !== 0 && methods.convertNumber(story?.impressions)}</span>
                  </li>
                }
                {story?.reach > 0 &&
                  <li>
                    <Tooltip title="Reach" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <IconImpression />
                      </IconButton>
                    </Tooltip>
                    <span>{story?.reach !== 0 && methods.convertNumber(story?.reach)}</span>
                  </li>
                }
              </ul>
            </div>
            }
          </div>
        </Grid>
      ))}
    </Grid>

  );
};

export default InsightMediaDetailCard;
