import React, { useState } from "react";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import { FormValidation } from "../../../core/utility";
import { LocalService } from "../../../core/services";
import { PinSecurity } from "./pin";
import { UserService } from "../../../core/services";


const localService = new LocalService();

const userService = new UserService();
interface Props {
  UserData: any;
}
interface ErrorTypes {
  status: boolean;
  message: string;
}

const Security: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [currentPassord, setCurrentPassord] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<ErrorTypes>({
    status: false,
    message: "",
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Check for empty fields
    if (!currentPassord || !newPassword || !confirmPassword) {
      localService.toastify("All fields are required", "error", 1000);
      return;
    }
    setErrorPassword(formValidation.GetPasswordValidate(newPassword));
    const isValidPassword = formValidation.GetPasswordValidate(`${newPassword}`).status
    if (isValidPassword) {

      if (newPassword === currentPassord || confirmPassword === currentPassord) {
        localService.toastify("Current Password should not match with new password", "error", 1000);

      }
      else if (newPassword != confirmPassword) {
        setConfirmPasswordError({
          status: true,
          message: "Passwords do not match",
        })

      }
      else if (newPassword !== currentPassord && newPassword.length > 0 && newPassword == confirmPassword) {
        setConfirmPasswordError({
          status: false,
          message: "",
        })
        let user_id = props.UserData.user_id;
        const response = await userService.updatePassword({
          data: {
            old_password: currentPassord,
            new_password: newPassword,
            user_id: user_id,
          },
        });
        if (response.data.affectedRows > 0) {
          localService.toastify("Password Updated", "success", 1000);

          setCurrentPassord("");
          setNewPassword("");
          setConfirmPassword("");
        }
        else {
          localService.toastify("Password update failed due to wrong password", "error", 1000);
        }
      }
      else {
        localService.toastify("Password update failed", "error", 1000);
      }
    }
  };

  return (
    <>
      <div className='list-section-content rounded'>
        <div className='row align-items-center'>
          <strong className='d-block'>Security</strong>
        </div>
      </div>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
        }}
      >
        <Grid item xs>
          <span className='fs-16 d-block' style={{ marginBottom: "10px" }}>Password</span>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  label="Current Password"
                  className='input-common'
                  value={currentPassord}
                  onChange={(event) => setCurrentPassord(event.target.value)}
                  fullWidth
                  size="small"
                  type="password"
                />

              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  type="password"
                  label="New Password"
                  className='input-common'
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  fullWidth
                  size="small"
                />
                <span className="error">{errorPassword?.message}</span>

              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  className='input-common'
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  fullWidth
                  size="small"
                />
                {confirmPasswordError.status && <span className="error">{confirmPasswordError.message}</span>}
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              className="btn btn-outline-primary upload_button  "
              sx={{ mt: 2 }}
            >
              Update Password
            </Button>
          </form>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="pin-pass-divider mx-2"
        >
          <span></span>
        </Divider>
        <Grid
          item
          xs
          sx={{
            marginTop: { xs: "40px", sm: "0", md: "0", lg: "0" },
          }}
        >
          <PinSecurity />
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserData: state.UserReducer.UserData,
  };
};
const connectToRedux = connect(mapStateToProps, null)(Security);
export { connectToRedux as Security };
