interface Props {
  className?: string;
}
const IconProposal: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.3903 20.6827H15.6099V19.484H5.3903V20.6827ZM5.3903 14.907H15.6099V13.7083H5.3903V14.907ZM2.42314 25.75C1.82578 25.75 1.32121 25.55 0.909425 25.15C0.497641 24.75 0.291748 24.2598 0.291748 23.6795V2.32049C0.291748 1.7402 0.497641 1.25005 0.909425 0.85003C1.32121 0.45001 1.82578 0.25 2.42314 0.25H14.3759L20.7084 6.40159V23.6795C20.7084 24.2598 20.5025 24.75 20.0907 25.15C19.679 25.55 19.1744 25.75 18.577 25.75H2.42314ZM13.7589 6.96645V1.44871H2.42314C2.19877 1.44871 1.99311 1.53952 1.80615 1.72114C1.6192 1.90276 1.52572 2.10254 1.52572 2.32049V23.6795C1.52572 23.8975 1.6192 24.0972 1.80615 24.2789C1.99311 24.4605 2.19877 24.5513 2.42314 24.5513H18.577C18.8014 24.5513 19.0071 24.4605 19.194 24.2789C19.381 24.0972 19.4744 23.8975 19.4744 23.6795V6.96645H13.7589Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconProposal;






