import React, { useEffect,useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { InfluencerListType } from '../../pages/brand/plans/campaings/campaign-view/proposals/chat';
import { DefaultUserProfile } from '../../config/config';


interface Props {
    campaignDetail: {campaign_title: string, campaign_id: number};
    influencerslist: InfluencerListType[];
    onChange: (value: InfluencerListType) => void;
    getSubscriptionDetail:any;
}


export const InfluencerList: React.FC<Props> = ({ influencerslist, getSubscriptionDetail, onChange, ...arg }: Props) => {
    const [searchInput, setSearchInput] = useState('');

    const handleSearchInputChange = (e:any) => {
        setSearchInput(e.target.value);
    };

    const filteredInfluencers = influencerslist?.filter(data =>
        data.account_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        data.username.toLowerCase().includes(searchInput.toLowerCase())
    );
    
    useEffect(() => {
        // console.log(arg.campaignDetail)
    }, [arg.campaignDetail])
    return (
        <Container className={`pro-inf-list-container`} fluid>
            <Row className='pro-inf-chat-label'>
                <Col>
                    <span className='campaign-name'>{arg.campaignDetail.campaign_title}</span>
                    <span className='influencer-count'>{influencerslist.length} Influencers</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul>
                    <input className='form-control w-100' 
                        type="text" 
                        placeholder="Search influencers..." 
                        value={searchInput} 
                        onChange={handleSearchInputChange} 
                    />

                        {filteredInfluencers?.map((data, index) =>
                            <li key={index} onClick={() => {
                                setSearchInput('');
                                onChange(data)}
                                }>
                                <div className="profile">
                                    <img className={`profile-image-size`} src={
                                        (data.gcp_profile_image_url || data.profile_image_url)
                                            ? (data.gcp_profile_image_url ? data.gcp_profile_image_url : data.profile_image_url)
                                            : DefaultUserProfile.influencer_unknown_default
                                        } 
                                    />
                                    <div className='influencer-details '>
                                        <span className={`influencer-name`}>{data.account_name}</span> <br />
                                        <span className={`influencer-username`}>{data.username}</span>
                                    </div>
                                </div>
                                <span className='proposal-status'>
                                    {data.status}
                                </span>
                                <span className={`message-count ${data.message_count === 0 ? 'inactive-count' : ''}`}>
                                    {data.message_count}
                                </span>

                            </li>
                        )}
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}