
interface Props {
  className?: string;
}
const IconClock: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >

      <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 21C10.1678 21 8.91915 20.7507 7.75406 20.252C6.58897 19.7533 5.57096 19.0707 4.70003 18.2044C3.8291 17.338 3.14297 16.3253 2.64166 15.1663C2.14036 14.0073 1.88971 12.7652 1.88971 11.4399C1.88971 10.1147 2.14036 8.87255 2.64166 7.71355C3.14297 6.55455 3.8291 5.54186 4.70003 4.67548C5.57096 3.80909 6.58897 3.12655 7.75406 2.62786C8.91915 2.12918 10.1678 1.87984 11.5 1.87984C12.8322 1.87984 14.0809 2.12918 15.2459 2.62786C16.411 3.12655 17.429 3.80909 18.3 4.67548C19.1709 5.54186 19.857 6.55455 20.3583 7.71355C20.8596 8.87255 21.1103 10.1147 21.1103 11.4399C21.1103 12.7652 20.8596 14.0073 20.3583 15.1663C19.857 16.3253 19.1709 17.338 18.3 18.2044C17.429 19.0707 16.411 19.7533 15.2459 20.252C14.0809 20.7507 12.8322 21 11.5 21ZM15.2794 16.0453L16.1296 15.1996L12.1006 11.1917V5.46487H10.8994V11.6881L15.2794 16.0453ZM4.18141 0L5.03153 0.845708L0.85015 5.00524L0 4.15956L4.18141 0ZM18.8186 0L23 4.15956L22.1499 5.00524L17.9685 0.845708L18.8186 0ZM11.5 19.805C13.8271 19.805 15.8104 18.9895 17.4498 17.3587C19.0893 15.7278 19.909 13.7549 19.909 11.4399C19.909 9.12497 19.0893 7.15206 17.4498 5.52118C15.8104 3.8903 13.8271 3.07485 11.5 3.07485C9.17289 3.07485 7.18962 3.8903 5.55018 5.52118C3.91073 7.15206 3.091 9.12497 3.091 11.4399C3.091 13.7549 3.91073 15.7278 5.55018 17.3587C7.18962 18.9895 9.17289 19.805 11.5 19.805Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconClock;











