import React, { useEffect, useState } from 'react';
import MaterialSymbol from '../../components/icons/material-symbol';


interface Props {
    onChange: (toggle: boolean) => void;
    value: boolean;
    messageCount: number;
}

export const ChatToggle: React.FC<Props> = ({ onChange, value, messageCount }: Props) => {
    return (
        <button onClick={() => onChange(!value)} className='toogle-chat' >
            {messageCount > 0 && <span className='toggle-message_count'>{messageCount}</span>}
            {!value && <MaterialSymbol className={`proposal-icon ${!value ? 'inactive-chat' : ''}`} iconName='chat' fontweight='200' />}
            {value && <MaterialSymbol className={`proposal-icon ${value ? 'active-chat' : ''}`} iconName='close' fontweight='200' />}
        </button>
    )
}