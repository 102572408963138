// import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import {
    LoadingReducer,
    LoginUserIdReducer,
    UserReducer,
    SwitchLayer1Reducer,
    SwitchLayer2Reducer,
    UserID,
    CreateCampaignReducer,
    EditCampaign,
    InfSelectCamp,
    guestUserReducer,
    CartReducer,
    _SubscriptionDetail
} from './reducer/reducer';
export const store = configureStore({
    //add the all reducer Like that
    reducer: {
        UserReducer: UserReducer,
        LoadingReducer: LoadingReducer,
        LoginUserIdReducer: LoginUserIdReducer,
        SwitchLayer1Reducer: SwitchLayer1Reducer,
        SwitchLayer2Reducer: SwitchLayer2Reducer,
        UserID: UserID,
        CreateCampaignReducer: CreateCampaignReducer,
        EditCampaign: EditCampaign,
        InfSelectCamp: InfSelectCamp,
        guestUserReducer: guestUserReducer,
        CartReducer: CartReducer,
        Subscription: _SubscriptionDetail
    },
});

// export const store = createStore(combineReducers({
//     //add the Reducers as Object
// UserReducer: UserReducer,
// LoadingReducer: LoadingReducer,
// LoginUserIdReducer: LoginUserIdReducer,
// SwitchReducer: SwitchReducer,
// UserID: UserID,
// CreateCampaignReducer: CreateCampaignReducer

// }), applyMiddleware(thunk))
