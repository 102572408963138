import React from "react";
import { useState, useEffect } from "react";
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { ChatService } from "../../../core/services";
import { CampaignList } from "../../../components/common-inbox-chat/campaign-list";
import { ChatBox } from "../../../components/common-inbox-chat/chat-box";
import * as Util from "../../../core/utility";
import { useNavigate } from "react-router-dom";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { useMediaQuery } from "@mui/material";
import {
  SubscriptionService,
} from '../../../core/services';
import  UpgradeInfluencerPopUp from '../../../components/popup/upgrade-influencer-popup'
interface Props {
  loading: (data: any) => any;
  userData: any;
}

const chatService: ChatService = new ChatService();
const methods: Util.Methods = new Util.Methods();
const subscriptionService: SubscriptionService = new SubscriptionService();

const InfluencerCampaignMsg: React.FC<Props> = (props: Props) => {
  const [campaignList, setCamapignList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [activeInfluencerChat, setActiveInfluencerChats] = useState({});
  const navigate = useNavigate();
  const [campaignUnreadMsgData, setCampaignUnreadMsgData] = useState<any>([]);
  const [directUnreadMsgData, setDirectUnreadMsgData] = useState<any>([]);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [isBasicUser, setBasicUser] = useState(false);
  const [openUpgradeInfluencerInboxModal, setopenUpgradeInfluencerInboxModal] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatSendLoading, setChatSendLoading] = useState(false);

  useEffect(() => {
    if (props.userData) {
      getLeftDays();
    }
  }, [props.userData]);

  useEffect(() => {
    getUnreadMessage();
  }, []);

  useEffect(() => {
    if (selectedCampaign) {
      getActiveChat();
    }
  }, [selectedCampaign]);

  useEffect(() => {
    chatService
      .getChatCampaignList({
        platfrom_type: "instagram",
        influencerID: props.userData.influencer_id,
        accountId: props.userData.account_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setCamapignList(res.data.rows);
        } else {
          setCamapignList([]);
        }
      });
  }, []);
  
  async function getLeftDays() {
    const { account_id, user_id, influencer_id } = props.userData;
    try {
      props.loading(true);
      const response =
        await subscriptionService.getSubscription_Remaining_Days({
          account_id: account_id,
          user_id: user_id,
          influencer_id: influencer_id
        });
      if (response.data.status.status) {
          setBasicUser(false);
      } else {
        setBasicUser(true);
        setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal);
        // navigate("/influencer/dashboard")
      }
    } catch (error) {
      console.log('getLeftDays Error :: ' + JSON.stringify(error));
    }finally{
      props.loading(false);
    }
  }

  const clickOnCampaign = (campaign: any) => {
    setSelectedCampaign(campaign);
  };

  const getActiveChat = () => {
    setChatLoading(true);
    chatService
      .getInfluencerConversion({
        proposal_id: selectedCampaign.proposal_id,
        from: props.userData.account_name,
        user_id: props.userData.user_id,
        account_id: props.userData.account_id,
        influencer_id: props.userData.influencer_id,
        sm_id: selectedCampaign.sm_id,
      })
      .then((res) => {
        if (res.data.status) {
          let alignData = methods.alignChat(res.data.rows);
          setActiveInfluencerChats(alignData);
          getUnreadMessage();
          // if (JSON.stringify(alignData) !== JSON.stringify(activeInfluencerChat)) {
          // }
          setChatLoading(false);
        }
      })
      .catch((err) =>
        console.log(`active influencer chat error in useEffect :: ${err}`)
      ).finally(()=>{setChatLoading(false);})
  };

  function getUnreadMessage() {
    chatService
      .getUnReadMessageChat({ influencer_id: props.userData.influencer_id })
      .then((res) => {
        if (res.status === 1) {
          setCampaignUnreadMsgData(res.data.campaign_unread);
          setDirectUnreadMsgData(res.data.direct_unread);
        } else {
          setCampaignUnreadMsgData([]);
          setDirectUnreadMsgData([]);
        }
      })
      .catch((error: any) =>
        console.log(`message count fetch proposal :: ${error}`)
      );
  }

  function handleSubmitChat(event: any) {
    try {
      event.preventDefault();
      let target: any = event.target;
      let values = Object.fromEntries(new FormData(target)) as {
        message: string;
      };
      if (values.message.replace(/\s+/g, "").length > 0 && selectedCampaign.status != 'Rejected') {
        setChatSendLoading(true);
        chatService
          .insertProposalMessage({
            proposal_id: selectedCampaign?.proposal_id,
            comments: values.message,
            from: props.userData.account_name,
            user_id: props.userData?.user_id ?? 0,
            account_id: props.userData?.account_id ?? 0,
            influencer_id: props.userData?.influencer_id ?? 0,
            sm_id: selectedCampaign?.sm_id ?? 0,
            receiver_influencer_id: selectedCampaign?.brand_influencer_id,
            // receiver_account_id: activeInfluencer?.account_id
          })
          .then((res) => {
            getActiveChat();
            event.target?.reset();
            setChatSendLoading(false);
          })
          .catch((error) => console.log(error))
          .finally(() => {setChatSendLoading(false);});
      }
    } catch (err) {
      throw new Error(`handleSubmitChat`);
    }
  }

  const onClickChatBackBtn = () => {
    setSelectedCampaign(null);
  };

  const handleCloseInfluencerInboxModal = () =>{
    setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal);
    navigate("/influencer/dashboard")
  }

  return (
    <section className="main-inbox-container">
      <h3 className="inbox-heading">Inbox</h3>
      <div className="btn-class-chat">
        <span
          className="btn active-chat-btn"
          onClick={() => navigate("/influencer/campaign-msg")}
        >
          {" "}
          Campaign Messages
          <span className={`${campaignUnreadMsgData.length > 0 ? "unread-msg" : "" }`}>{campaignUnreadMsgData.length > 0 ? campaignUnreadMsgData[0].total_unread_messages : ''}</span>
        </span>
        <span
          className="btn"
          onClick={() => navigate("/influencer/direct-msg")}
        >
          {" "}
          Direct Messages
          <span className={`${directUnreadMsgData.length > 0 ? "unread-msg" : "" }`}>{directUnreadMsgData.length > 0 ? directUnreadMsgData[0].total_unread_messages : ''}</span>
        </span>
      </div>
      <div className="main-chat-inbox-container">
        {isMobile ? (
          <div className="mobile-view">
            {!selectedCampaign && (
              <CampaignList
                data={campaignList}
                clickOnCampaign={clickOnCampaign}
                selected_campaign={selectedCampaign}
                unreadMsg={campaignUnreadMsgData}
                influencer_type={'influencer'}
              />
            )}
            {selectedCampaign && (
              <div className="chat-container">
                <ChatBox
                  campaign={selectedCampaign}
                  campaignDetail={selectedCampaign}
                  chats={activeInfluencerChat}
                  influencer={null}
                  userData={props.userData}
                  onSubmit={handleSubmitChat}
                  direct_tab={false}
                  influencer_type={"influencer"}
                  isMobile={isMobile}
                  onClickChatBackBtn={onClickChatBackBtn}
                  chatLoading={chatLoading}
                  chatSendLoading={chatSendLoading}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="list-container">
              <CampaignList
                data={campaignList}
                clickOnCampaign={clickOnCampaign}
                selected_campaign={selectedCampaign}
                unreadMsg={campaignUnreadMsgData}
                influencer_type={'influencer'}
              />
            </div>
            {selectedCampaign && (
              <div className="chat-container">
                <ChatBox
                  campaign={selectedCampaign}
                  campaignDetail={selectedCampaign}
                  chats={activeInfluencerChat}
                  // onClick={() => handleBackButton()}
                  influencer={null}
                  userData={props.userData}
                  onSubmit={handleSubmitChat}
                  direct_tab={false}
                  influencer_type={"influencer"}
                  chatLoading={chatLoading}
                  chatSendLoading={chatSendLoading}
                />
              </div>
            )}
            {!selectedCampaign && (
              <div className="chat-container">
                <div className="inbox">
                  <div className="in-inbox">
                    <p className="icon">
                      <QuestionAnswerOutlinedIcon />
                    </p>
                    <p className="p-1">Nothing Selected</p>
                    <p className="p-2">
                      Please select a conversation from the list to view its
                      details.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {openUpgradeInfluencerInboxModal && (
        <>
        <UpgradeInfluencerPopUp
          openUpgradeInfluencerModal={openUpgradeInfluencerInboxModal}
          onClose={() => handleCloseInfluencerInboxModal()}
          popupMinWidth='630px'
        >
        </UpgradeInfluencerPopUp>
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerCampaignMsg);
export { connectToRedux as InfluencerCampaignMsg };
