import React, { useEffect, useState } from "react";
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField, Theme, useTheme } from "@mui/material";
import { FormValidation } from "../../../../core/utility";
import { LocalService, PublicService } from "../../../../core/services";
import { AdminDashboardService } from "../../../../core/services/admin-dashboard.service";
import { MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect } from "../../../../core/utility/style";
import { InfluencerInstagram } from "./infIuencer-instagram";

const formValidation: FormValidation = new FormValidation();
const localService: LocalService = new LocalService();
const publicService: PublicService = new PublicService();
const adminDashboardService: AdminDashboardService = new AdminDashboardService();


interface Props {
  // loading: (loading: boolean) => void;
  showAddInfluencer: boolean
  excelData?: any[]
  clearExcelData?: () => void;
}

interface FormData {
  platform_type: string;
  username: string;
  name: string;
  phone: string;
  email_id: string;
  gender: string;
  location_id: any;
  genre: string[];
  language: string[];
  followers: string;
  campaign: string;
  brand: string;
  campaign_hashtags: string;
  cost_of_reel: string;
  avg_view_reel: string;
  cost_of_story: string;
  cost_of_story_wa_swipeup_link: string;
  cost_of_image_post: string;
  brands_worked_past: string;
}


export const AddInfluencerIG: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const [gender, setGender] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [genricList, setGenricList] = useState<string[]>([]);
  const [location, setLocation] = useState([]);
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [formDataList, setFormDataList] = useState<FormData[]>([]);
  const [isInfluencerAdded, setIsInfluencerAdded] = useState(false);
  const [excelInfluencerData, setExcelInfluencerData] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    platform_type: "Instagram",
    username: "",
    name: "",
    phone: "",
    email_id: "",
    gender: "",
    location_id: "",
    genre: [],
    language: [],
    followers: "",
    campaign: "",
    brand: "",
    campaign_hashtags: "",
    cost_of_reel: "",
    avg_view_reel: "",
    cost_of_story: "",
    cost_of_story_wa_swipeup_link: "",
    cost_of_image_post: "",
    brands_worked_past: ""
  });

  const [formError, setFormError] = useState({
    username: { status: true, message: "" },
    name: { status: true, message: "" },
    phone: { status: true, message: "" },
    email_id: { status: true, message: "" },
    gender: { status: true, message: "" },
    location_id: { status: true, message: "" },
    genre: { status: true, message: "" },
    language: { status: true, message: "" },
    followers: { status: true, message: "" },
    campaign: { status: true, message: "" },
    brand: { status: true, message: "" },
    campaign_hashtags: { status: true, message: "" },
    cost_of_reel: { status: true, message: "" },
    avg_view_reel: { status: true, message: "" },
    cost_of_story: { status: true, message: "" },
    cost_of_story_wa_swipeup_link: { status: true, message: "" },
    cost_of_image_post: { status: true, message: "" },
    brands_worked_past: { status: true, message: "" }
  });

  const handleGenre = (event: any) => {
    const { target: { value }, } = event;
    if (formData.genre.length != 3) {
      setFormData((previData) => ({ ...previData, genre: value }));
    } else {
      if (value.length === 2) {
        setFormData((previData) => ({ ...previData, genre: value }));
      }
    }
  };

  const handleLanguage = (event: any) => {
    const {
      target: { value },
    } = event;
    if (formData.language.length != 4) {
      setFormData((previData) => ({ ...previData, language: value }));
    } else {
      if (value.length === 3) {
        setFormData((previData) => ({ ...previData, language: value }));
      }
    }
  };

  // Extra component
  function getStyles(name: string, personName: string[], theme: Theme) {
    try {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    } catch (error) { }
  }


  async function addExtenalInfluencer() {
    try {
      // Validation
      const isValidUsername = formValidation.GetInstagramUsernameValidate(formData?.username);
      const isValidName = formValidation.GetNameValidate(formData?.name);
      const isValidPhone = formValidation.GetMobileControlIsValid(formData?.phone);
      const isValidEmail = formValidation.GetEmailControlIsValid(formData?.email_id);
      const isValidGender = formValidation.GetNameValidate(formData.gender);


      setFormError((prevData) => ({
        ...prevData,
        username: isValidUsername,
        name: isValidName,
        phone: isValidPhone,
        email_id: isValidEmail,
        gender: isValidGender
      }));

      // Validate form fields
      if (
        isValidUsername.status &&
        isValidName.status &&
        isValidPhone.status &&
        isValidEmail.status &&
        isValidGender
      ) {
        // Call API to save data
        setLoading(true);
        // setFormDataList([...formDataList, formData]);
        const updatedFormDataList = [...formDataList, formData];
        const response = await adminDashboardService.addExternalInfluencer({formDataList: updatedFormDataList });

        if (response.data.status.status) {
          // Reset formData
          localService.toastify("External influencer added succesfully.", 'success', 1000);
          setIsInfluencerAdded(true)
          setFormData({
            platform_type: "Instagram", // Default value
            username: "",
            name: "",
            phone: "",
            email_id: "",
            gender: "",
            location_id: "",
            genre: [],
            language: [],
            followers: "",
            campaign: "",
            brand: "",
            campaign_hashtags: "",
            cost_of_reel: "",
            avg_view_reel: "",
            cost_of_story: "",
            cost_of_story_wa_swipeup_link: "",
            cost_of_image_post: "",
            brands_worked_past: "",
          });
        }
      }
    } catch (error) {
      console.log(`addExternalInfluencer function failed: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false);
      setTimeout(() => setIsInfluencerAdded(false), 1000);
    }
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    addExtenalInfluencer()
  };

  async function handleBulkUploadExternalInfluencer() {
    if (Array.isArray(props.excelData) && props.excelData.length > 0) {
      const validInfluencers = props.excelData.filter((influencer) => {
        const isValidUsername = formValidation.GetInstagramUsernameValidate(influencer.username);
        const isValidName = formValidation.GetNameValidate(influencer.name);
        const isValidPhone = formValidation.GetMobileControlIsValid(influencer.phone);
        const isValidEmail = formValidation.GetEmailControlIsValid(influencer.email_id);
        const isValidGender = formValidation.GetNameValidate(influencer.gender);
  
        return (
          isValidUsername.status &&
          isValidName.status &&
          isValidPhone.status &&
          isValidEmail.status &&
          isValidGender
        );
      });
  
      if (validInfluencers.length > 0) {
        const formDataList = { formDataList: validInfluencers };
        setLoading(true);
        try {
          const response = await adminDashboardService.addExternalInfluencer(formDataList);
          if (response.data.status.status) {
            localService.toastify("Successfully added external influencers.", 'success', 1000);
            setIsInfluencerAdded(true)
            setFormDataList([])
            props.clearExcelData?.();
          } else {
            props.clearExcelData?.();
            localService.toastify("Failed to add external influencers.", 'error', 1000);
          }
        } catch (error) {
          console.error(`External Influencer Bulk upload failed: ${JSON.stringify(error)}`);
        } finally {
          setLoading(false);
          setTimeout(() => setIsInfluencerAdded(false), 1000);
        }
      } else {
        localService.toastify("No valid influencer data found.", 'error', 1000);
      }
    }
  }

  useEffect(() => {
    handleBulkUploadExternalInfluencer()
  }, [props.excelData]);

  useEffect(() => {
    async function getLocations() {
      try {
        // props.loading(true);
        const res = await publicService.getLocation({format: 'city,state,country'});
        if (res.data.status) {
          // if (res?.data?.rows[0].state === 'India' && res?.data?.rows[0].city === 'India') {
          //   res?.data?.rows.shift();
          // }
          setLocation(res?.data?.rows);
        } else {
          setLocation([]);
        }
      } catch (error) {
        setLocation([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {
      }
    }
    getLocations();

    PublicService.getGenre()
      .then((res) => {
        setGenricList(res?.data?.rows);
        // props.loading(false);
      })
      .catch((error) => {
        // props.loading(false);
        throw new Error(`getGenre error :: ${JSON.stringify(error)}`);
      });
    async function getLanguage() {
      try {
        // props.loading(true);
        const res = await publicService.getLanguage();
        if (res.data.status) {
          setLanguageList(res?.data?.rows);
          // props.loading(false);
        } else {
          setLanguageList([]);
        }
      } catch (error) {
        // props.loading(false);
        throw new Error(`getLanguage error :: ${JSON.stringify(error)}`);
      }
    }
    getLanguage();

  }, []);

  return (
    <section>
      {props.showAddInfluencer && (
        <form
          className="ig_influencer"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.username}
                error={!formError.username.status}
                helperText={formError.username.message}
                size="small"
                label="Username*"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    username: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.name}
                error={!formError.name.status}
                helperText={formError.name.message}
                size="small"
                label="Influencer Name*"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                type="number"
                value={formData.phone}
                error={!formError.phone.status}
                helperText={formError.phone.message}
                size="small"
                label="Phone*"
                variant="outlined"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 10) {
                    setFormData({
                      ...formData,
                      phone: value,
                    });
                  }
                }}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.email_id}
                error={!formError.email_id.status}
                helperText={formError.email_id.message}
                size="small"
                label="Email*"
                // InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email_id: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <FormControl fullWidth size="small" className="input-style">
                <InputLabel id="demo-simple-select-label">Gender*</InputLabel>
                <Select
                  error={!formError.gender.status}
                  labelId="demo-simple-select-label"
                  id="gender_id"
                  label="Gender"
                  name="gender"
                  value={formData.gender}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData((prevData) => ({ ...prevData, gender: value }));
                  }
                  }
                  MenuProps={MenuPropsSelect}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                <FormHelperText error={!formError.gender.status}>
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <Autocomplete
                className="inputFieldSp"
                size="small"
                disablePortal
                id="combo-box-demo"
                ListboxProps={MenuPropsAutoCompleteNoCheckBox?.PaperProps}
                value={

                  formData.location_id
                    ? (() => {
                      const selectedLocation: any = location.find(
                        (loc: any) => loc.location_id == formData.location_id
                      );
                      return selectedLocation
                        ? `${selectedLocation.city}, ${selectedLocation.state}, ${selectedLocation.country}`
                        : null;
                    })()

                    : null
                }
                onChange={(e, v) => {
                  setFormData((prevData) => {
                    const selectedLocation: any = location.find(
                      (data: any) => `${data.city}, ${data.state}, ${data.country}` === v
                    );

                    return {
                      ...prevData,
                      location_id: selectedLocation ? selectedLocation.location_id : null,
                    };
                  });
                }}
                options={
                  location.length
                    ? location.map(
                      (res: any) => `${res.city}, ${res.state}, ${res.country}`
                    )
                    : ["No Data"]
                }
                renderInput={(params) => (
                  <TextField
                    error={!formError.location_id.status}
                    name="location_id"
                    {...params}
                    label="Location"
                  />
                )}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <FormControl className="w-100 input-style" size="small">
                <InputLabel id="demo-multiple-name-label">
                  Genre (Max. 3)
                </InputLabel>
                <Select
                  error={!formError.genre.status}
                  className="_genre_option"
                  labelId="demo-multiple-name-label"
                  id="Genre_id"
                  multiple
                  input={<OutlinedInput label="Genre (Max. 3)" />}
                  onChange={handleGenre}
                  value={formData.genre?.map((res: any) => res)}
                  MenuProps={MenuPropsSelect}
                  name="genre"
                >
                  {genricList ? (
                    genricList?.map((res: any) => (
                      <MenuItem
                        key={res.category_id}
                        value={res.category_id}
                        style={getStyles(res.category, formData.genre, theme)}
                      >
                        {res.category}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      key={1}
                      value={1}
                      style={getStyles("No Data", formData.genre, theme)}
                    >
                      {"No Data"}
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText error={!formError.genre.status}>
                  {/* {formError.genre.message} */}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <FormControl className="w-100 input-style" size="small">
                <InputLabel id="demo-multiple-name-label">
                  Language (Max. 4)
                </InputLabel>
                <Select
                  error={!formError.language.status}
                  className="_genre_option"
                  labelId="demo-multiple-name-label"
                  id="Language_id"
                  multiple
                  input={<OutlinedInput label="Language(Max. 4)" />}
                  onChange={handleLanguage}
                  value={formData.language?.map((res: any) => res)}
                  MenuProps={MenuPropsSelect}
                  name="language"
                >
                  {languageList ? (
                    languageList?.map((res: any) => (
                      <MenuItem
                        key={res.id}
                        value={res.id}
                        style={getStyles(res.id, formData.language, theme)}
                      >
                        {`${res.language_name} (${res.language_original_name})`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      key={1}
                      value={1}
                      style={getStyles("No Data", formData.language, theme)}
                    >
                      {"No Data"}
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText error={!formError.language.status}>
                  {/* {formError.language.message} */}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                type="number"
                value={formData.followers}
                size="small"
                label="Followers"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    followers: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                value={formData.campaign}
                className="w-100 input-style"
                size="small"
                label="Campaign"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    campaign: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.brand}
                size="small"
                label="Brand"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    brand: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.campaign_hashtags}
                size="small"
                label="Campaign Hashtags"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    campaign_hashtags: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.cost_of_reel}
                type="number"
                size="small"
                label="Cost of reel"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cost_of_reel: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                type="number"
                value={formData.avg_view_reel}
                size="small"
                label="Avg. views of reel"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    avg_view_reel: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                type="number"
                value={formData.cost_of_story}
                size="small"
                label="Cost of story "
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cost_of_story: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                type="number"
                value={formData.cost_of_story_wa_swipeup_link}
                size="small"
                label="Cost of story w/a swipe up link "
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cost_of_story_wa_swipeup_link: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                type="number"
                value={formData.cost_of_image_post}
                size="small"
                label="Cost of image post"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cost_of_image_post: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
              <TextField
                className="w-100 input-style"
                value={formData.brands_worked_past}
                size="small"
                label="Brands worked with in past 3 months"
                variant="outlined"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    brands_worked_past: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm ml-0"
            style={{ margin: "0px 0px 10px 0px" }}
          >
            Add
          </button>
        </form>
      )}
      <InfluencerInstagram triggerUpdate={isInfluencerAdded}/>
    </section>
  );
};
