import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, InputAdornment, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import "./campaign-post.scss";
import { CreateCampaign, Loading } from "../../../../../redux/action";
import {
  CreateCampaign as CreateCampaignService,
  ApplyCampaignService,
  LocalService,
  SubscriptionService
} from "../../../../../core/services";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// important common popup component
import CommonPopUp from "../../../../../components/popup/common-popup";
// For brand foc campaign payment
import useRazorpay from 'react-razorpay';
import { makeRazorpayPayment } from "../../../../../core/services/PaymentService";
import SwitchButton from "../../../../../components/switch-button/switch-button";
import UpgradeBrandPopUp from "../../../../../components/popup/upgrade-brand-popup";
import { BASE_URL_WEB } from "../../../../../config/config";

interface Props {
  UserReducer: any;
  UserID: any;
  campaignReducer: any;
  CreateCampaignReducer?: (data: any) => void;
  loading?: (data: any) => void;
  getSubscriptionDetail: any;
}

const applyCampaignService: ApplyCampaignService = new ApplyCampaignService();
const localService: LocalService = new LocalService();
const subscriptionService: SubscriptionService = new SubscriptionService();
const createCampaignService: CreateCampaignService = new CreateCampaignService();

const CampaingPost: React.FC<Props> = (props: Props) => {
  const [razorpay] = useRazorpay();
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState("");
  const [formData, setFormData] = useState<any>({
    proposal_deadline: "",
    expected_go_live: "",
    comment: "",
    reference_link: [],
    reveal_brand: "true",
    ...props.campaignReducer.post,
  });

  const [dateError, setDateError] = useState("");
  const dateFormat = "YYYY-MM-DD";
  const navigate = useNavigate();

  // FOc campaign logic
  const [isBasicUser, setBasicUser] = useState(true);
  const [isFocCampaignAllowed, setIsFocCampaignAllowed] = useState(true);
  const [markCompleteReminder, setMarkCompleteReminder] = useState(false);
  // to check foc campaign allowed or not ?
  const [foc_campaign_allowed, setFocCampaignAllowed] = useState(true);
  // numeric value: (to store live brief days limit.)
  const [live_brief_days_limit, setLive_brief_days_limit] = useState(0);

  // For foc popup terms and condition checkbox
  const [isChecked, setChecked] = useState(true);
  const [isTcErrorVisible, setTcErrorVisible] = useState(false);

  // to restrict max proposal deadline date for 7 days.
  const maxProposalDeadline = isBasicUser ? dayjs().add(live_brief_days_limit, 'day') : undefined;

  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openGSTINModal: false,
    openFocModal: false,
    openUpgradeBrandModal: false,
    markCompleteReminder: false
  });

  const [availablePlan, setAvailablePlan] = useState<any>(null);
  // To set campaign status, and set it to Hold if status is Live.
  const campaignStatusForDraft = (props.campaignReducer.createCampaign?.status === 'Live' ? 'Hold' : (props.campaignReducer.createCampaign?.status ?? 'Draft'));

  // enum set for modal content
  const ModalContent = {
    FOC_CAMPAIGN: "You're about to post your FOC campaign for this month.  It will be published after review by our internal team. Do you want to proceed?",
    NEW_FOC_CAMPAIGN: 'You have already posted your FOC campaign for this month.',
    REPUBLISH_FOC_CAMPAIGN: 'You are about to Republish your campaign. This will be published after getting reviewed by our internal team. Do you wish to continue?',
    VERIFY_GSTIN: 'Verify your GSTIN details to post the campaign.',
    NEW_BARTER_CAMPAIGN: 'You are about to post your barter campaign.',
    MARK_COMPLETE_REMINDER: 'To create a new FOC campaign, please mark your previous campaign as complete.'
  };

  // enum set for modal title
  const ModalTitle = {
    FOC_CAMPAIGN: 'Post Campaign',
    NEW_FOC_CAMPAIGN: 'Post Campaign',
    REPUBLISH_FOC_CAMPAIGN: 'Republish Campaign',
    VERIFY_GSTIN: 'Verify GSTIN',
    NEW_BARTER_CAMPAIGN: 'Post Campaign',
    MARK_COMPLETE_REMINDER: 'Alert'
  };

  // Common modal content and title useState
  const [modalContent, setModalContent] = useState(ModalContent.FOC_CAMPAIGN);
  const [modalTitle, setModalTitle] = useState(ModalTitle.FOC_CAMPAIGN);

  // To handle Foc terms and condition checkbox
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    setTcErrorVisible(false); // Reset error message when the checkbox state changes
  };

  // To handle Foc modal popup open and close
  const handleOpenFocModal = () => setPopUps({ ...popUps, openFocModal: true });

  // to handle markCompleteReminder popup
  const handleMarkCompleteReminderModal = () => setPopUps({ ...popUps, markCompleteReminder: true });

  // function handleCloseFocModal() {
  //   setPopUps({...popUps, openFocModal: false});
  // }

  // To handle Gstin modal popup open and close
  // const handleOpenGSTINModal = () => setPopUps({...popUps, openGSTINModal: true});

  // function handleCloseGSTINModal() {
  //   setPopUps({...popUps, openGSTINModal: false});
  // }

  function handleClosePopUps() {
    setPopUps({
      openGSTINModal: false,
      openFocModal: false,
      openUpgradeBrandModal: false,
      markCompleteReminder: false
    })
  }
  // Foc campaign logic end.

  // For foc new campaign plan
  async function getFocCampaignPlanDetails(plan_name: string) {
    const { user_id, account_id, account_type, influencer_id } = props.UserReducer;
    const response = await subscriptionService.getFocCampaignPlanDetail({
      user_id: user_id,
      account_id: account_id,
      user_type: account_type,
      influencer_id: influencer_id,
      plan_name: plan_name
    });
    if (response.data.status.status) {
      //return Only Active Plan Info
      setAvailablePlan(response.data.rows);
    } else {
      // localService.toastify(response.data.status.message, 'info', 1000);
      setAvailablePlan(null);
    }
  }
  // For foc republish campaign plan
  // async function getFocRepublishCampaignPlanDetails() {
  //   const { user_id, account_id, account_type, influencer_id } = props.UserReducer;
  //   const response = await subscriptionService.getFocRepublishCampaignPlanDetail({
  //     user_id: user_id,
  //     account_id: account_id,
  //     user_type: account_type,
  //     influencer_id: influencer_id,
  //   });
  //   if (response.data.status.status) {
  //     //return Only Active Plan Info
  //     setAvailablePlan(response.data.rows);
  //   } else {
  //     // localService.toastify(response.data.status.message, 'info', 1000);
  //     setAvailablePlan(null);
  //   }
  // }

  // To check foc campaign allowed or not, and if allowed which plan to be used
  async function set_foc_allowed_count() {
    try {
      // props.loading(true);
      const foc_res = await applyCampaignService.allowed_monthly_foc_campaign({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id,
        campaign_id: props.campaignReducer.createCampaign.campaign_id
      });
      if (foc_res.data.status) {
        
        // setLive_brief_days_limit(foc_res.data.live_brief_days_limit);
        setLive_brief_days_limit(7);
        // setFocCampaignCount(foc_res.data.foc_campaign_count);
        setFocCampaignAllowed(foc_res.data.foc_campaign_allowed);
        setBasicUser(foc_res.data.basic_user);
        // Checking for FOC Republish campaign
        setMarkCompleteReminder(foc_res.data.markCompleteReminder);
        if (['barter', 'Barter'].includes(props.campaignReducer.createCampaign.campaign_type)) {
          getFocCampaignPlanDetails('New Barter Campaign');
          setFocCampaignAllowed(false);
          setModalContent(ModalContent.NEW_BARTER_CAMPAIGN);
          setModalTitle(ModalTitle.NEW_BARTER_CAMPAIGN);
          // reset campaign live date for barter campaign
          setLive_brief_days_limit(30);
        }
        else if (foc_res.data.basic_user && ['Hold', 'Live', 'Rejected'].includes(campaignStatusForDraft) && foc_res.data.campaign_previously_published) {
          if (!foc_res.data.foc_campaign_allowed) {
            setIsFocCampaignAllowed(false);
            // setPopUps({ ...popUps, openUpgradeBrandModal: true });
            // getFocCampaignPlanDetails('Republish Campaign');
            // setFocCampaignAllowed(false);
            // setModalContent(ModalContent.REPUBLISH_FOC_CAMPAIGN);
            // setModalTitle(ModalTitle.REPUBLISH_FOC_CAMPAIGN);
          } else {
            setModalContent(ModalContent.REPUBLISH_FOC_CAMPAIGN);
            setModalTitle(ModalTitle.REPUBLISH_FOC_CAMPAIGN);
          }
        }
        else if (foc_res.data.basic_user && !foc_res.data.foc_campaign_allowed) {
          setIsFocCampaignAllowed(false);
          // setPopUps({ ...popUps, openUpgradeBrandModal: true });
          // getFocCampaignPlanDetails('New Barter Campaign');
          // setModalContent(ModalContent.NEW_FOC_CAMPAIGN);
          // setModalTitle(ModalTitle.NEW_FOC_CAMPAIGN);
        } else {
          setModalContent(ModalContent.FOC_CAMPAIGN);
          setModalTitle(ModalTitle.FOC_CAMPAIGN);
        }
      }
    } catch (error) {
      console.error('Error fetching foc campaign data in campaign post:', error);
    } finally {
      // props.loading(false);
    }
  }

  // Set proposal deadline and live date default date if not present.
  if (!formData.proposal_deadline) {
    const tomorrowDate = localService.getFutureDateByDays(1);
    const dayAfterTomorrowDate = localService.getFutureDateByDays(2);
    setFormData({
      ...formData,
      proposal_deadline: tomorrowDate,
      expected_go_live: dayAfterTomorrowDate,
    });
  }

  const save_Post = async (status: string, payment_id: string = '') => {
    const promise = new Promise(async (resolve, reject) => {
      setLoading(true);
      createCampaignService.post_Campaign({
        ...formData,
        user_id: props.UserID,
        account_id: props.UserReducer.account_id,
        influencer_id: props.UserReducer.influencer_id,
        campaign_id: props.campaignReducer.createCampaign.campaign_id,
        status: status,
        campaign_type: props.campaignReducer.createCampaign.campaign_type,
      })
        .then((res: any) => {
          if (status === "Draft" || res.data.rows.status) {
            // localService.toastify(res.data.rows.message, "success", 1000);
          }
          if (res.data.rows.status) {
            localService.toastify(res.data.rows.message, "success", 1000);
            if (payment_id != '') {
              navigate(`/brand/orders/confirmed?payment_id=${payment_id}&campaign_id=${props.campaignReducer.createCampaign.campaign_id}`);
            } else {
              navigate("/brand/plans");
            }
          }
          resolve(res.data.rows);
        })
        .catch((err) => {
          reject({ status: false, message: "" });
          throw new Error("Save Post Function :: " + err);
        })
        .finally(() => {
          setLoading(false);
          // navigate('/brand/plans')
        });
    });
    return promise;
  };

  const publish_Post = async (status: string, payment_id: string = '') => {
    try{
      setLoading(true);
      if (!isChecked) {
        setTcErrorVisible(true); // Show error if the checkbox is not checked
      }
      else if (!dateError) {
        // If User Saved Company Detail then campaign will be posted other wise throw the Error message

        // const _GSTINValidation = await applyCampaignService._IsValidGSTIN({
        //   user_id: props.UserReducer.user_id,
        //   account_id: props.UserReducer.account_id,
        // });
        //if GSTIN is available in DB then campaign Posted successfully
        if (new Date(formData.proposal_deadline) <= new Date()) {
          localService.toastify("Proposal Deadline can not be less than or equal to present date.", "info", 1000);
        }
        else if (new Date(formData.proposal_deadline) >= new Date(formData.expected_go_live)) {
          localService.toastify("Go live date can not be less than or equal to proposal deadline.", "info", 1000);
        }
        else {
          // const responseStatus: any = await save_Post(status, payment_id);
          // if (responseStatus.status) {
          //   localService.toastify(responseStatus.message, "success", 1000);
          //   if (payment_id != '') {
          //     navigate(`/brand/orders/confirmed?payment_id=${payment_id}&campaign_id=${props.campaignReducer.createCampaign.campaign_id}`);
          //   } else {
          //     navigate("/brand/plans");
          //   }
          // }
          await save_Post(status, payment_id);
        }
        // else if (_GSTINValidation.data.status.status) {
        //   const responseStatus: any = await save_Post(status);
        //   if (responseStatus.status) {
        //     localService.toastify(responseStatus.message, "success", 1000);
        //     if (payment_id != ''){
        //       navigate(`/brand/orders/confirmed?payment_id=${payment_id}&campaign_id=${props.campaignReducer.createCampaign.campaign_id}`);
        //     }else{
        //       navigate("/brand/plans");
        //     }
        //   }
        // } 
        // else {
        //   // If GSTIN not available show throw the error message
        //   // setPopup(!_GSTINValidation.data.status.status);
        //   setPopUps({...popUps, openGSTINModal: !_GSTINValidation.data.status.status});
        // }
      }
      handleClosePopUps();
    } catch (error) {
      console.error(`publish_Post Error :: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  };

  const publish_Post_Check = async (status: string) => {
    try{
      setLoading(true);
      if (!isChecked) {
        setTcErrorVisible(true); // Show error if the checkbox is not checked
      } else if (!dateError) {
        // If User Saved Company Detail then campaign will be posted other wise throw the Error message

        // const _GSTINValidation = await applyCampaignService._IsValidGSTIN({
        //   user_id: props.UserReducer.user_id,
        //   account_id: props.UserReducer.account_id,
        // });
        //if GSTIN is available in DB then campaign Posted successfully
        if (new Date(formData.proposal_deadline) <= new Date()) {
          localService.toastify("Proposal Deadline can not be less than or equal to present date.", "info", 1000);
        }
        else if (new Date(formData.proposal_deadline) >= new Date(formData.expected_go_live)) {
          localService.toastify("Go live date can not be less than or equal to proposal deadline.", "info", 1000);
        }
        else {
          if (markCompleteReminder) {
            setModalContent(ModalContent.MARK_COMPLETE_REMINDER);
            handleMarkCompleteReminderModal();
          }
          else if (isFocCampaignAllowed) {
            handleOpenFocModal();
          } else {
            setPopUps({ ...popUps, openUpgradeBrandModal: true });
          }
        }
        // else if (_GSTINValidation.data.status.status) {
        //   handleOpenFocModal();
        // } 
        // else {
        //   // If GSTIN not available show throw the error message
        //   // setPopup(!_GSTINValidation.data.status.status);
        //   setPopUps({...popUps, openGSTINModal: !_GSTINValidation.data.status.status});
        // }
      }
    } catch (error) {
      console.error(`publish_Post_Check Error :: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }

  const handleAddLink = (url: string) => {
    if (url.length > 0) {
      if (formData.reference_link.length != 3) {
        let reference_links: any = formData.reference_link;
        reference_links.push(url);
        setFormData({
          ...formData,
          reference_link: reference_links,
        });
        setLinks("");
      } else {
        // toast.info("Limit Only three Links");
        localService.toastify("Limit Only three Links", "success", 1000);
      }
    }
  };

  const handleRemoveLink = (index: number) => {
    let ref = [...formData.reference_link];
    ref.splice(index, 1);
    setFormData({ ...formData, reference_link: ref });
  };

  // Verify GSTIN
  function verifyGSTIN() {
    navigate(`/brand/settings/companydetails`)
  }

  const handleBack = () => {
    navigate("/brand/campaign/influencer");
  };

  // For FOC campaign payment
  async function makePayment(data: any) {
    // let paymantStatus = {};
    setLoading(true);
    try {
      if (!isChecked) {
        setTcErrorVisible(true); // Show error if the checkbox is not checked
      }
      else {
        const paymentResponse = await makeRazorpayPayment(data, props, razorpay);
        if (paymentResponse?.status) {
          // Payment successful, perform necessary actions
          await publish_Post("Live", paymentResponse?.paymentId);
        } else {
          // Payment failed, handle it
          console.log("Payment failed");
          // localService.toastify(paymentResponse?.message, "error", 1000);
        }
      }
    } catch (error) {
      console.error(`Make Payment Error :: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.campaignReducer.createCampaign === null) {
      navigate("/brand/plans");
    }
    set_foc_allowed_count();
  }, [props]);

  // For handling switch
  const handleSwitch = (index: number, reveal_brand: boolean) => {
    setFormData({
      ...formData,
      reveal_brand: reveal_brand,
    })
  };

  return (
    <div className="campaign_post_main_container">
      {popUps.openGSTINModal && (
        <CommonPopUp
          modalTitle={ModalTitle.VERIFY_GSTIN}
          modalContent={ModalContent.VERIFY_GSTIN}
          openModal={popUps.openGSTINModal}
          onClose={() => handleClosePopUps()}
          // popupMinWidth='630px'
          popupMaxWidth='600px'>
          {
            <>
              <div className="popup-footer">
                <div className="row justify-content-end">
                  <div className="col-auto px-0">
                    <Button
                      className=" btn btn-outline-primary  No"
                      onClick={() => handleClosePopUps()}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-auto px-0 ms-2">
                    <Button
                      className="btn btn-outline-primary yes"
                      onClick={verifyGSTIN}
                      variant="contained"
                    >
                      Verify Now
                    </Button>
                  </div>
                </div>
              </div>
            </>
          }
        </CommonPopUp>
      )}
      {popUps.openFocModal && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={popUps.openFocModal}
          onClose={() => handleClosePopUps()}
          // popupMinWidth='630px'
          popupMaxWidth='600px'
          className="barter-popup"
        >
          {/* <div className="helper-text d-flex mt-3">
            <p className="inline">For unlimited access, explore our</p>
            <a className="btn btn-text-link ms-1" href="/brand/settings/subscription" target="_blank" rel="noopener noreferrer">
              pricing plans
            </a>
          </div> */}

          {foc_campaign_allowed
            ?
            (
              <div className="justify-content-between align-items-center mt-1">
                <div className="">
                  <div className="form-check">
                    <input
                      checked={isChecked}
                      className="form-check-input mt-1"
                      id="defaultCheck1"
                      type="checkbox"
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      I agree to{' '}
                      <a className="btn btn-text-link" target="_blank" href={`${BASE_URL_WEB}/terms_conditions`}>
                        Terms &amp; Conditions
                      </a>
                    </label>
                    <small className={`text-red ${isTcErrorVisible ? '' : 'd-none'}`}>This field is required.</small>
                  </div>

                  <div className='popup-footer'>
                    <div className='row'>
                      <div className='col text-end'>
                        <a className="btn btn-outline-primary" href="javascript:void(0);" onClick={handleClosePopUps}>
                          No
                        </a>
                        <a className="btn btn-primary float-right ms-2" href="javascript:void(0);" onClick={() => publish_Post("Live")}>
                          Yes
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
            :
            (
              <div className="justify-content-between align-items-center mt-3">
                <div className="">
                  <p className="inline mt-0">
                    {`To post campaign, please pay Rs.${availablePlan[0]?.amount} or view our `}
                    <a className="btn btn-text-link" style={{ fontSize: "16px" }} target="_blank" href="/brand/settings/subscription">
                      Pricing plans
                    </a>{' '}
                    for unlimited access.
                  </p>
                  <br />
                  <div className="form-check">
                    <input
                      checked={isChecked}
                      className="form-check-input mt-1"
                      id="defaultCheck1"
                      type="checkbox"
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" style={{ fontSize: "14px" }} htmlFor="defaultCheck1">
                      I agree to{' '}
                      <a className="btn btn-text-link" style={{ fontSize: "14px" }} target="_blank" href={`${BASE_URL_WEB}/terms_conditions`}>
                        Terms &amp; Conditions
                      </a>
                    </label>
                    <small className={`text-red ${isTcErrorVisible ? '' : 'd-none'}`}>This field is required.</small>
                  </div>
                </div>

                <div className='popup-footer mt-4'>
                  <div className='row'>
                    <div className='col text-end'>
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        // startIcon={<SaveIcon />}
                        className="btn btn-outline-primary"
                        variant="contained"
                        onClick={() => save_Post(campaignStatusForDraft)}
                      >
                        Save as draft
                      </LoadingButton>
                      {availablePlan && (
                        <>
                          {availablePlan.map((data: any, index: number) => {
                            return (
                              <LoadingButton
                                className='btn btn-primary ms-2 buy-btn'
                                loading={loading}
                                loadingPosition='start'
                                variant='contained'
                                onClick={() => makePayment(data)}
                              >
                                Pay Rs. {data.amount}
                              </LoadingButton>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between mt-3 mb-3">
                  <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    className="btn"
                    variant="contained"
                    onClick={() => save_Post(campaignStatusForDraft)}
                  >
                    Save as draft
                  </LoadingButton>
                  {availablePlan && (
                    <>
                      {availablePlan.map((data: any, index: number) => {
                        return (
                          <LoadingButton
                            className='btn btn-primary buy-btn'
                            loading={loading}
                            loadingPosition='start'
                            variant='contained'
                            onClick={() => makePayment(data)}
                          >
                            Pay Rs. {data.amount}
                          </LoadingButton>
                        );
                      })}
                    </>
                  )}
                </div> */}
              </div>
            )
          }
        </CommonPopUp>
      )}
      <div className="row date_picker">
        {/* Show Deadline error */}
        {/* <span className="error">Live Date not greater then proposal deadline</span> */}
        <div className="col-sm-6">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DeadLine"]}>
              <MobileDatePicker
                className="w-100"
                label="Proposal Deadline"
                format="DD-MM-YYYY"
                minDate={dayjs(new Date())}
                maxDate={maxProposalDeadline}
                defaultValue={dayjs(formData.proposal_deadline)}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    proposal_deadline: e?.format(dateFormat),
                  })
                }
                slotProps={{
                  textField: {
                    size: "small",
                    // helperText: "DD/MM/YYYY",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="col-sm-6">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["goLive"]}>
              <MobileDatePicker
                className="w-100"
                format="DD-MM-YYYY"
                label="Expected Go Live"
                minDate={dayjs(formData.proposal_deadline)}
                defaultValue={dayjs(formData.expected_go_live)}
                onError={(error) => setDateError(error as string)}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    expected_go_live: e?.format(dateFormat),
                  })
                }
                slotProps={{
                  textField: {
                    size: "small",
                    // helperText: "DD/MM/YYYY",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="row date_picker"></div>
      <div className="row text_field">
        <div className="col">
          <TextField
            id="outlined-basic"
            value={formData.comment}
            onChange={(e) =>
              setFormData({
                ...formData,
                comment: e.target.value,
              })
            }
            className="w-100"
            size="small"
            label="Comments (Optional)"
            variant="outlined"
          />
        </div>
      </div>
      <div className="row text_field">
        <div className="col product_add mt-2">
          <TextField
            className="w-100"
            label="Reference Links"
            name="link"
            id="first_link_id"
            size="small"
            value={links}
            onChange={(e) => setLinks(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          <Button
            className="btn btn-outline-primary ms-2"
            onClick={() => handleAddLink(links)}
            variant="outlined"
          >
            Add
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col list_ul">
          <ul>
            {formData.reference_link.length > 0
              ? formData.reference_link.map((res: string, index: number) => {
                return (
                  <li key={index}>
                    <a href={res} target="_blank" style={{fontWeight:"400", color:"#2b2b2b", fontSize:"13px"}}>{res}</a>{" "}
                    <span>
                      <CloseIcon
                        className="close_button"
                        onClick={() => handleRemoveLink(index)}
                      />
                    </span>
                  </li>
                );
              })
              : ""}
          </ul>
        </div>
      </div>
      <div className="row align-items-center mt-5">
        <div className="col-auto">Reveal Brand name to influencer</div>
        <div className="col ps-0">
          <SwitchButton
          className="common-switch"
            handleSwitch={handleSwitch}

            index={formData.reveal_brand}
          />
        </div>
      </div>
      <div className="row text_field mt-3">
        <div className="col">
          <div className="button_group">
            <div className="col campaign_influencer_buttons">
              <LoadingButton
                loading={loading}
                loadingPosition="start"

                className="btn btn-outline-primary me-auto ms-0"
                variant="outlined"
                onClick={handleBack}
              >
                Back
              </LoadingButton>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                className="btn btn-outline-primary"
                variant="contained"
                onClick={() => save_Post(campaignStatusForDraft)}
              >
                Save as draft
              </LoadingButton>

              <LoadingButton
                loading={loading}
                loadingPosition="start"
                className="btn btn-primary"
                variant="contained"
                onClick={() => (isBasicUser ? publish_Post_Check("Live") : publish_Post("Live"))}
              >
                Post
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
      {popUps.openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={popUps.openUpgradeBrandModal}
          onClose={() => handleClosePopUps()}
          popupMinWidth='630px'
          campaignStatusForDraft={campaignStatusForDraft}
          save_Post={save_Post}
          loading={loading}
        >
        </UpgradeBrandPopUp>
      )}

      {/* to show mark previous campaign complete popup */}
      {popUps.markCompleteReminder && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={popUps.markCompleteReminder}
          onClose={() => handleClosePopUps()}
          // popupMinWidth='630px'
          popupMaxWidth='600px'
          className="barter-popup"
        >
          <div className="justify-content-between align-items-center mt-3">
            <div className='popup-footer mt-4'>
              <div className='row'>
                <div className='col text-end'>
                  <LoadingButton
                    className='btn btn-primary ms-2 buy-btn'
                    loading={loading}
                    loadingPosition='start'
                    variant='contained'
                    onClick={() => navigate('/brand/plans')}
                  >
                    View Campaign
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    UserID: state.UserID.userID,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaingPost);

export { connectToRedux as CampaingPost };
