import React from "react";
import { useState, useEffect } from "react";
import "../userbase.scss"; import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { NavLink } from 'react-router-dom';
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LoadingButton } from "@mui/lab";
import { Grid, Tooltip } from "@mui/material";
import { RolesService } from "../../../../../../core/services/roles.service";
import { LocalService } from "../../../../../../core/services";
import { useNavigate } from 'react-router-dom';
import HeadingStyle from "../../../../../../components/heading-style/heading-style";
import { Skeleton } from "@mui/material";
import Paper from '@mui/material/Paper';
import { AdminDashboardService } from "../../../../../../core/services/admin-dashboard.service";
import { connect } from "react-redux";
import { Loading } from "../../../../../../redux/action";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const adminDashboardService = new AdminDashboardService();
const rolesService = new RolesService();
const localService: LocalService = new LocalService();

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
}

const UserBase: React.FC<Props> = (props: Props) => {
  const [userBaseData, setUserBaseData] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    start_date: dayjs('2023-01-01').format('YYYY-MM-DD'),
    end_date: dayjs(new Date()).format('YYYY-MM-DD'),
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [dateError, setDateError] = useState("");
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    // CHECK IF USER ALLOWED  ACCESS 
    checkAccessFunction();
  }, []);

  const checkAccessFunction = async () => {
    try {
      const res = await rolesService.checkAccess({ access_roles: ["view_userbase"], user_role_id: props.UserReducer.user_role_id })
      if (res.data.rows.length > 0) {
        getAdminDashUserbase(formData);
      }
      else {
        localService.toastify("You are not allowed to access", "error", 1000);

        navigate(`/brand/dashboard`)
      }
    }
    catch (error) {
      console.error('Error fetching getAdminDashData:', error);

    }
  }

  async function getAdminDashUserbase(formData: any) {
    try {
      setLoading(true);
      const res = await adminDashboardService.getAdminDashUserbase(formData);
      if (res?.data?.status?.status) {
        setUserBaseData(res.data.rows);
      }
    } catch (error) {
      console.error('Error fetching getAdminDashUserbase:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSearch() {
    try {
      if (formData.start_date > formData.end_date) {
        setDateError("Start date should be less than end date");
        return;
      } else {
        setDateError("");
        getAdminDashUserbase(formData);
      }
    }
    catch (error) {
      console.error('Error fetching handleSearch:', error);
    } finally {
    }
  }

  return (
    <div className="userbase-main">
      <HeadingStyle
        title='Admin Dashboard'
        className=' px-3 d-block'
      />
      <div className='col-auto nav sub-tabs mb-3'>
        <ul>
          <li className=''>
            <NavLink
              className='nav-link campaign-verification-tab'
              to={'/brand/admin-dashboard/userbase'}
            >
              Userbase
            </NavLink>
          </li>
          <li className=''>
            <NavLink
              className='nav-link talent-verification-tab'
              to={'/brand/admin-dashboard/userbrief'}
            >
              User Signups with briefs
            </NavLink>
          </li>
        </ul>
      </div>
      {dateError && <span className="error">{dateError}</span>}
      <div className="date_range_search">
        <Grid container spacing={2}>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["StartDate"]}>
                <MobileDatePicker
                  className="w-100"
                  label="start date"
                  format="DD-MM-YYYY"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.start_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      start_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["EndDate"]}>
                <MobileDatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  label="end date"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.end_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      end_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={2} md={2} xs={12} xl={4} className="btn-div">
            <LoadingButton className="btn btn-primary btn-sm" onClick={() => handleSearch()}>Search</LoadingButton>
          </Grid>
        </Grid>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="common-table-header">
            <TableRow className="brief-tr">
              <TableCell rowSpan={2} align="center" style={{ minWidth: '250px', }}></TableCell>
              <TableCell className="left-border-col" rowSpan={2} align="center" style={{ minWidth: '150px', }}>All</TableCell>
              <TableCell className="left-border-col" align="center" style={{ minWidth: '400px', }}>Registered</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Total userbase (includes all brands, influencers, agencies, talent partners)</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_users || 0) + (userBaseData?.basic_data?.[0]?.others_accounts || 0)}</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_users || 0)}</TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Total userbase (includes all brands + agency brands, influencers + TP influencers, agencies, talent partners)</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_users || 0) + (userBaseData?.basic_data?.[0]?.others_accounts || 0) + (userBaseData?.basic_data?.[0]?.total_tp_influencer || 0) + (userBaseData?.basic_data?.[0]?.total_agency_brand || 0)}</TableCell>
              <TableCell className="left-border-col" align="center"></TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left"></TableCell>
              <TableCell className="left-border-col" align="center"></TableCell>
              <TableCell className="left-border-col" align="center">
                <TableHead className="common-table-header">
                  <TableRow className="brief-tr-inner">
                    <TableCell rowSpan={3} align="center" style={{ minWidth: '120px' }}>
                      Total
                    </TableCell>
                    <TableCell className="left-border-nested-col" colSpan={2} align="center" style={{ minWidth: '200px' }}>
                      Subscriptions
                    </TableCell>
                    <TableCell className="left-border-nested-col" colSpan={4} align="center" style={{ minWidth: '400px' }}>
                      Connected
                    </TableCell>
                    <TableCell className="left-border-nested-col" colSpan={2} align="center" style={{ minWidth: '120px' }}>
                      Non-Connected
                    </TableCell>
                    <TableCell className="left-border-nested-col" rowSpan={3} align="center" style={{ minWidth: '100px' }}>
                      Non-Subscribed
                    </TableCell>
                  </TableRow>
                  <TableRow className="brief-tr-inner">
                    <TableCell className="left-border-nested-col" colSpan={1} align="center" style={{ minWidth: '100px' }}>Total</TableCell>
                    <TableCell colSpan={1} align="center" style={{ minWidth: '100px' }}>Active</TableCell>
                    <TableCell className="left-border-nested-col" colSpan={2} align="center" style={{ minWidth: '200px' }}>
                      Instagram
                    </TableCell>
                    <TableCell className="left-border-nested-col" colSpan={2} align="center" style={{ minWidth: '200px' }}>
                      YouTube
                    </TableCell>
                    <TableCell className="left-border-nested-col" colSpan={1} align="center" style={{ minWidth: '60px' }}>IG</TableCell>
                    <TableCell colSpan={1} align="center" style={{ minWidth: '60px' }}>YT</TableCell>
                  </TableRow>
                  <TableRow className="brief-tr-inner">
                    <TableCell className="left-border-nested-col" align="center" style={{ minWidth: '100px' }}></TableCell>
                    <TableCell align="center" style={{ minWidth: '100px' }}></TableCell>
                    <TableCell className="left-border-nested-col" align="center" style={{ minWidth: '100px' }}>Total</TableCell>
                    <TableCell align="center" style={{ minWidth: '100px' }}>Active</TableCell>
                    <TableCell className="left-border-nested-col" align="center" style={{ minWidth: '100px' }}>Total</TableCell>
                    <TableCell align="center" style={{ minWidth: '100px' }}>Active</TableCell>
                    <TableCell className="left-border-nested-col" align="center" style={{ minWidth: '60px' }}></TableCell>
                    <TableCell align="center" style={{ minWidth: '60px' }}></TableCell>

                  </TableRow>
                </TableHead>
              </TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Brands</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_brand || 0)}</TableCell>
              <TableCell className="left-border-col" align="left">
                <TableCell className="nested-tablecell-total">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_brand || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.brand_paid || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.active_brand || 0)}</TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_subscriber_accounts?.[0]?.brand_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Agencies</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_agency || 0)}</TableCell>
              <TableCell className="left-border-col" align="left">
                <TableCell className="nested-tablecell-total">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_agency || 0)}</TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
              </TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Brands under agencies</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_agency_brand || 0)}</TableCell>
              <TableCell className="left-border-col" align="left">
                <TableCell className="nested-tablecell-total">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_agency_brand || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.agency_brand_paid || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.active_agency_brand_paid || 0)}</TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_subscriber_accounts?.[0]?.agency_brand_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Talent partners</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_talent_partner || 0)}</TableCell>
              <TableCell className="left-border-col" align="left">
                <TableCell className="nested-tablecell-total">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_talent_partner || 0)}</TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="social-nested-tablecell"></TableCell>
                <TableCell className="nested-tablecell"></TableCell>
              </TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left">Influencers under talent partners</TableCell>
              <TableCell className="left-border-col" align="center">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_tp_influencer || 0)}</TableCell>
              <TableCell className="left-border-col" align="left">
                <TableCell className="nested-tablecell-total">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.total_tp_influencer || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.tp_influencer_paid || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.basic_data?.[0]?.active_tp_influencer_paid || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.tp_influencer_ig?.[0]?.tp_influencer_connected || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.tp_influencer_ig?.[0]?.active_tp_influencer_connected || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.tp_influencer_yt?.[0]?.tp_influencer_connected || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.tp_influencer_yt?.[0]?.tp_influencer_connected || 0)}</TableCell>
                <TableCell className="social-nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.tp_influencer_ig?.[0]?.tp_influencer_non_connected || 0)}</TableCell>
                <TableCell className="social-nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.tp_influencer_yt?.[0]?.tp_influencer_non_connected || 0)}</TableCell>
                <TableCell className="nested-tablecell">{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_subscriber_accounts?.[0]?.talent_partner_influencer_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
            <TableRow
              hover role='checkbox'
            >
              <TableCell align="left"></TableCell>
              <TableCell className="left-border-col" align="left">
                <TableHead className="common-table-header">
                  <TableRow className="brief-tr-inner">
                    <TableCell rowSpan={3} align="center" style={{ minWidth: '100px' }}>
                      Total
                    </TableCell>
                    <TableCell rowSpan={3} align="center" style={{ minWidth: '60px' }}>
                      IG
                    </TableCell>
                    <TableCell rowSpan={3} align="center" style={{ minWidth: '60px' }}>
                      YT
                    </TableCell>
                  </TableRow>
                </TableHead>
              </TableCell>
              <TableCell className="left-border-col">
                <TableHead className="common-table-header">
                  <TableRow className="brief-tr-inner">
                    <TableCell rowSpan={3} align="center" style={{ minWidth: '60px' }}>
                      IG
                    </TableCell>
                    <TableCell rowSpan={3} align="center" style={{ minWidth: '60px' }}>
                      YT
                    </TableCell>
                  </TableRow>
                </TableHead>
              </TableCell>
            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Total Influencers Profiles</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.all_Below_1000 || 0) + (userBaseData?.ig_social?.[0]?.all_Nano || 0) + (userBaseData?.yt_social?.[0]?.all_Nano || 0) +
                  (userBaseData?.ig_social?.[0]?.all_Micro || 0) + (userBaseData?.yt_social?.[0]?.all_Micro || 0) + (userBaseData?.ig_social?.[0]?.all_Micro_Plus || 0) + (userBaseData?.yt_social?.[0]?.all_Micro_Plus || 0) + (userBaseData?.ig_social?.[0]?.all_Macro || 0) + (userBaseData?.yt_social?.[0]?.all_Macro || 0) +
                  (userBaseData?.ig_social?.[0]?.all_Macro_Plus || 0) + (userBaseData?.yt_social?.[0]?.all_Macro_Plus || 0) + (userBaseData?.ig_social?.[0]?.all_Mega || 0) + (userBaseData?.yt_social?.[0]?.all_Mega || 0) + (userBaseData?.ig_social?.[0]?.all_Mega_Plus || 0) + (userBaseData?.yt_social?.[0]?.all_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Below_1000 || 0) + (userBaseData?.ig_social?.[0]?.all_Nano || 0) + (userBaseData?.ig_social?.[0]?.all_Micro || 0) + (userBaseData?.ig_social?.[0]?.all_Micro_Plus || 0) +
                  (userBaseData?.ig_social?.[0]?.all_Macro || 0) + (userBaseData?.ig_social?.[0]?.all_Macro_Plus) + (userBaseData?.ig_social?.[0]?.all_Mega || 0) + (userBaseData?.ig_social?.[0]?.all_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.all_Nano || 0) + (userBaseData?.yt_social?.[0]?.all_Micro || 0) + (userBaseData?.yt_social?.[0]?.all_Micro_Plus || 0) +
                  (userBaseData?.yt_social?.[0]?.all_Macro || 0) + (userBaseData?.yt_social?.[0]?.all_Macro_Plus) + (userBaseData?.yt_social?.[0]?.all_Mega || 0) + (userBaseData?.yt_social?.[0]?.all_Mega_Plus || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Below_1000 || 0) + (userBaseData?.ig_social?.[0]?.registered_Nano || 0) + (userBaseData?.ig_social?.[0]?.registered_Micro || 0) + (userBaseData?.ig_social?.[0]?.registered_Micro_Plus || 0) +
                  (userBaseData?.ig_social?.[0]?.registered_Macro || 0) + (userBaseData?.ig_social?.[0]?.registered_Macro_Plus || 0) + (userBaseData?.ig_social?.[0]?.registered_Mega || 0) + (userBaseData?.ig_social?.[0]?.registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.registered_Nano || 0) + (userBaseData?.yt_social?.[0]?.registered_Micro || 0) + (userBaseData?.yt_social?.[0]?.registered_Micro_Plus || 0) +
                  (userBaseData?.yt_social?.[0]?.registered_Macro || 0) + (userBaseData?.yt_social?.[0]?.registered_Macro_Plus || 0) + (userBaseData?.yt_social?.[0]?.registered_Mega || 0) + (userBaseData?.yt_social?.[0]?.registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (
                  <>{(userBaseData?.social_premium?.[0]?.Below_1000 || 0) + (userBaseData?.social_premium?.[0]?.Nano || 0) + (userBaseData?.social_premium?.[0]?.Micro || 0) + (userBaseData?.social_premium?.[0]?.Micro_Plus || 0) +
                    (userBaseData?.social_premium?.[0]?.Macro || 0) + (userBaseData?.social_premium?.[0]?.Macro_Plus || 0) + (userBaseData?.social_premium?.[0]?.Mega || 0) + (userBaseData?.social_premium?.[0]?.Mega_Plus || 0)  + (828)
                    }
                    <Tooltip title={`clan 1.0 = 828 & clan 2.0 = ${(userBaseData?.social_premium?.[0]?.Below_1000 || 0) + (userBaseData?.social_premium?.[0]?.Nano || 0) + (userBaseData?.social_premium?.[0]?.Micro || 0) + (userBaseData?.social_premium?.[0]?.Micro_Plus || 0) +
                    (userBaseData?.social_premium?.[0]?.Macro || 0) + (userBaseData?.social_premium?.[0]?.Macro_Plus || 0) + (userBaseData?.social_premium?.[0]?.Mega || 0) + (userBaseData?.social_premium?.[0]?.Mega_Plus || 0)
                    }`} arrow>
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </>
                  )}
                </TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (
                  <>{(userBaseData?.active_social_premium?.[0]?.Below_1000 || 0) + (userBaseData?.active_social_premium?.[0]?.Nano || 0) + (userBaseData?.active_social_premium?.[0]?.Micro || 0) + (userBaseData?.active_social_premium?.[0]?.Micro_Plus || 0) +
                    (userBaseData?.active_social_premium?.[0]?.Macro || 0) + (userBaseData?.active_social_premium?.[0]?.Macro_Plus || 0) + (userBaseData?.active_social_premium?.[0]?.Mega || 0) + (userBaseData?.active_social_premium?.[0]?.Mega_Plus || 0)
                    }
                    {/* <Tooltip title={`clan 1.0 = 71 & clan 2.0 = ${(userBaseData?.active_social_premium?.[0]?.Below_1000 || 0) + (userBaseData?.active_social_premium?.[0]?.Nano || 0) + (userBaseData?.active_social_premium?.[0]?.Micro || 0) + (userBaseData?.active_social_premium?.[0]?.Micro_Plus || 0) +
                    (userBaseData?.active_social_premium?.[0]?.Macro || 0) + (userBaseData?.active_social_premium?.[0]?.Macro_Plus || 0) + (userBaseData?.active_social_premium?.[0]?.Mega || 0) + (userBaseData?.active_social_premium?.[0]?.Mega_Plus || 0)
                    }`} arrow>
                      <InfoOutlinedIcon />
                    </Tooltip> */}
                  </>
                  )}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Below_1000 || 0) + (userBaseData?.ig_social?.[0]?.connected_registered_Nano || 0) + (userBaseData?.ig_social?.[0]?.connected_registered_Micro || 0) + (userBaseData?.ig_social?.[0]?.connected_registered_Micro_Plus || 0) +
                  (userBaseData?.ig_social?.[0]?.connected_registered_Macro || 0) + (userBaseData?.ig_social?.[0]?.connected_registered_Macro_Plus || 0) + (userBaseData?.ig_social?.[0]?.connected_registered_Mega || 0) + (userBaseData?.ig_social?.[0]?.connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Below_1000 || 0) + (userBaseData?.ig_social?.[0]?.active_connected_registered_Nano || 0) + (userBaseData?.ig_social?.[0]?.active_connected_registered_Micro || 0) + (userBaseData?.ig_social?.[0]?.active_connected_registered_Micro_Plus || 0) +
                  (userBaseData?.ig_social?.[0]?.active_connected_registered_Macro || 0) + (userBaseData?.ig_social?.[0]?.active_connected_registered_Macro_Plus || 0) + (userBaseData?.ig_social?.[0]?.active_connected_registered_Mega || 0) + (userBaseData?.ig_social?.[0]?.active_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.connected_registered_Nano || 0) + (userBaseData?.yt_social?.[0]?.connected_registered_Micro || 0) + (userBaseData?.yt_social?.[0]?.connected_registered_Micro_Plus || 0) +
                  (userBaseData?.yt_social?.[0]?.connected_registered_Macro || 0) + (userBaseData?.yt_social?.[0]?.connected_registered_Macro_Plus || 0) + (userBaseData?.yt_social?.[0]?.connected_registered_Mega || 0) + (userBaseData?.yt_social?.[0]?.connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.active_connected_registered_Nano || 0) + (userBaseData?.yt_social?.[0]?.active_connected_registered_Micro || 0) + (userBaseData?.yt_social?.[0]?.active_connected_registered_Micro_Plus || 0) +
                  (userBaseData?.yt_social?.[0]?.active_connected_registered_Macro || 0) + (userBaseData?.yt_social?.[0]?.active_connected_registered_Macro_Plus || 0) + (userBaseData?.yt_social?.[0]?.active_connected_registered_Mega || 0) + (userBaseData?.yt_social?.[0]?.active_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Below_1000 || 0) + (userBaseData?.ig_social?.[0]?.non_connected_registered_Nano || 0) + (userBaseData?.ig_social?.[0]?.non_connected_registered_Micro || 0) + (userBaseData?.ig_social?.[0]?.non_connected_registered_Micro_Plus || 0) +
                  (userBaseData?.ig_social?.[0]?.non_connected_registered_Macro || 0) + (userBaseData?.ig_social?.[0]?.non_connected_registered_Macro_Plus || 0) + (userBaseData?.ig_social?.[0]?.non_connected_registered_Mega || 0) + (userBaseData?.ig_social?.[0]?.non_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.non_connected_registered_Nano || 0) + (userBaseData?.yt_social?.[0]?.non_connected_registered_Micro || 0) + (userBaseData?.yt_social?.[0]?.non_connected_registered_Micro_Plus || 0) +
                  (userBaseData?.yt_social?.[0]?.non_connected_registered_Macro || 0) + (userBaseData?.yt_social?.[0]?.non_connected_registered_Macro_Plus || 0) + (userBaseData?.yt_social?.[0]?.non_connected_registered_Mega || 0) + (userBaseData?.yt_social?.[0]?.non_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Below_1000_count || 0) + (userBaseData?.non_premium_social?.[0]?.Nano_count || 0) + (userBaseData?.non_premium_social?.[0]?.Micro_count || 0) + (userBaseData?.non_premium_social?.[0]?.Micro_Plus_count || 0) +
                  (userBaseData?.non_premium_social?.[0]?.Macro_count || 0) + (userBaseData?.non_premium_social?.[0]?.Macro_Plus_count || 0) + (userBaseData?.non_premium_social?.[0]?.Mega_count || 0) + (userBaseData?.non_premium_social?.[0]?.Mega_Plus_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>{"<1K"}</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Below_1000 || 0) + (userBaseData?.yt_social?.[0]?.all_Below_1000 || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Below_1000 || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Below_1000 || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Below_1000 || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Below_1000 || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Below_1000 || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Below_1000 || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Below_1000_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Nano (1K - 10K)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Nano || 0) + (userBaseData?.yt_social?.[0]?.all_Nano || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Nano || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Nano || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Nano || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Nano || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Nano || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Nano || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Nano_count || 0)}</TableCell>
              </TableCell>

            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Micro (10K - 50K)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Micro || 0) + (userBaseData?.yt_social?.[0]?.all_Micro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Micro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Micro || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Micro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Micro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Micro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Micro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Micro_count || 0)}</TableCell>
              </TableCell>

            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Micro Plus (50K-100K)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Micro_Plus || 0) + (userBaseData?.yt_social?.[0]?.all_Micro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Micro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Micro_Plus || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Micro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Micro_Plus_count || 0)}</TableCell>
              </TableCell>

            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Macro (100K-500K)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Macro || 0) + (userBaseData?.yt_social?.[0]?.all_Macro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Macro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Macro || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Macro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Macro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Macro || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Macro || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Macro_count || 0)}</TableCell>
              </TableCell>

            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Macro Plus (500K-1M)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Macro_Plus || 0) + (userBaseData?.yt_social?.[0]?.all_Macro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Macro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Macro_Plus || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Macro_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Macro_Plus_count || 0)}</TableCell>
              </TableCell>

            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Mega (1M-5M)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Mega || 0) + (userBaseData?.yt_social?.[0]?.all_Mega || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Mega || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Mega || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Mega || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Mega || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Mega || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Mega || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Mega_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
            <TableRow hover role='checkbox'>
              <TableCell>Mega Plus (5M+)</TableCell>
              <TableCell className="left-border-col">
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Mega_Plus || 0) + (userBaseData?.yt_social?.[0]?.all_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.all_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.all_Mega_Plus || 0)}</TableCell>
              </TableCell>
              <TableCell className="left-border-col">
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.social_premium?.[0]?.Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.active_social_premium?.[0]?.Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.active_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.active_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.ig_social?.[0]?.non_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="social-nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.yt_social?.[0]?.non_connected_registered_Mega_Plus || 0)}</TableCell>
                <TableCell className="nested-tablecell" rowSpan={3}>{userBaseData && loading ? (<Skeleton animation="wave" />) : (userBaseData?.non_premium_social?.[0]?.Mega_Plus_count || 0)}</TableCell>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBase);

export { connectToRedux as UserBase };
