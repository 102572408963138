import React, { useState, useEffect } from 'react';
import { ImageCrop } from '../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import defaultImage from '../../../assets/images/default-image.jpg';
import {
    Button,
    InputLabel,
    OutlinedInput,
    TextField,
    Theme,
    useTheme,
    FormControl,
    MenuItem,
    Select,
    Grid,
    InputAdornment,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { PublicService } from '../../../core/services';
import './add-product.scss';
import { connect, useDispatch } from 'react-redux';
import { ClanShopService } from '../../../core/services/clan.shop.service';
import { LocalService } from '../../../core/services';
import HeadingStyle from '../../../components/heading-style/heading-style';
import { FormValidation } from '../../../core/utility';
import FileUploadPopup from '../../../components/popup/file-upload-popup';
import { MenuPropsSelect } from '../../../core/utility/style';

interface Props {
    UserReducer: any;
    UserID: any;
    loading?: (data: any) => void;
}
interface ErrorTypes {
    status: boolean;
    message: string;
}
const localService = new LocalService();
const AddProduct: React.FC<Props> = (props: Props) => {
    const formValidation = new FormValidation();
    const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const [productImageUrl, setProductImageUrl] = useState<any>('');
    const [productImagePath, setProductImagePath] = useState<any>('');
    const [category, setCategory] = useState<any>([]);
    const [parentCategory, setParentCategory] = useState<any>('');
    const [product, setProduct] = useState<any>('');
    const [items, setItems] = useState<any>();
    const location = useLocation();
    const { product_id } = location.state;
    const path = `accounts/${props.UserReducer.account_id}/brand/${props.UserReducer.brand_id}/products/${product_id}`;
    const [genricList, setGenricList] = useState<string[]>([]);
    const [genre, setGenre] = useState<string[]>([]);
    const theme = useTheme();

    const [errorProductName, setErrorProductName] = useState<ErrorTypes>();
    const [errorProductDescription, setErrorProductDescription] =
        useState<ErrorTypes>();
    const [errorProductCost, setErrorProductCost] = useState<ErrorTypes>();
    const [errorParam1, setErrorParam1] = useState<ErrorTypes>();
    const [errorParam2, setErrorParam2] = useState<ErrorTypes>();
    const [errorParam3, setErrorParam3] = useState<ErrorTypes>();
    const [errorProductCode, setErrorProductCode] = useState<ErrorTypes>();
    const [errorTotalCommission, setErrorTotalCommission] =
        useState<ErrorTypes>();
    const [errorDiscount, setErrorDiscount] = useState<ErrorTypes>();
    const [errorProductImage, setErrorProductImage] = useState<ErrorTypes>();

    useEffect(() => {
        PublicService.getGenre().then((res) => {
            setGenricList(res?.data?.rows);
            // if (props.loading) {
            //     dispatch(props.loading(false));
            // }
        });
        PublicService.getProductPrimaryCategory()
            .then((res: any) => {
                setCategory(res?.data?.rows);
            })
            .catch((e: any) => console.log('err', e));
    }, []);

    const uniqueCategories = [
        ...new Set(category?.map((product: any) => product.category)),
    ];

    const handleAddProductFormData = (e: any) => {
        e.preventDefault();

        const form = new FormData(e.target);
        const data = Object.fromEntries(form);
        const storeData: any = { ...data };
        let {
            product_name,
            product_description,
            product_cost,
            discount,
            product_code,
            total_commission,
            param_1,
            param_2,
            param_3,
        } = storeData;

        storeData['product_id'] = product_id;
        storeData['product_image_url'] = productImageUrl;
        storeData['product_image_path'] = productImagePath;
        storeData['is_status'] = 'live';
        // try {
        setErrorProductName(formValidation.GetNameValidate(product_name));
        setErrorProductDescription(
            formValidation.getdescriptionIsValid(product_description)
        );
        setErrorProductCost(formValidation.GetNameValidate(product_cost));
        setErrorDiscount(formValidation.GetDiscount(`${discount}`));
        setErrorProductCode(formValidation.GetNameValidate(product_code));
        setErrorTotalCommission(
            formValidation.GetDiscount(`${total_commission}`)
        );
        setErrorParam1(formValidation.GetNameValidate(param_1));
        setErrorParam2(formValidation.GetNameValidate(param_2));
        setErrorParam3(formValidation.GetNameValidate(param_3));
        setErrorProductImage(
            formValidation.GetNameValidate(productImageUrl)
        );
        // } catch (e) {
        //     console.log(e);
        // }
        const category_id = category.find(
            (data: any) =>
                data.category === parentCategory && data.product === product
        );
        storeData['category'] = category_id.id;

        ClanShopService.updatebrandProduct(storeData)
            .then((res: any) => {
                // const { user_id, account_id } = res?.data.rows?.userData;
                if (props.loading) {
                    dispatch(props.loading(false));
                }
                if (res?.data?.status?.status) {
                    localService.toastify(res.data.status.message);
                    navigate(`/brand/clanshop`);
                } else {
                    localService.toastify(res.data.status.message);
                }
            })
            .catch((error) => {
                if (props.loading) {
                    dispatch(props.loading(false));
                }

                console.warn(error);
            });
    };

    const handleParentCategory = (e: any) => {
        setParentCategory(e.target.value);

        const SecondaryCategory = category.filter(
            (product: any) => product.category === e.target.value
        );
        setItems(SecondaryCategory);
    };

    const handleProduct = (e: any) => {
        setProduct(e.target.value);
    };
    return (
        <div className='container'>
            <HeadingStyle title='Add Product Detail' className='mb-4 d-block' />
            <form onSubmit={handleAddProductFormData}>
                <div className='add_product_container'>
                    <Grid container spacing={2}>
                        <Grid item md={9} className='order-last'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='product_name'
                                        type='text'
                                        id='outlined-basic'
                                        label='Product Name'
                                        variant='outlined'
                                    />
                                    <span className='error'>
                                        {errorProductName?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='product_code'
                                        type='text'
                                        id='outlined-basic'
                                        label='Product Code'
                                        variant='outlined'
                                    />
                                    <span className='error'>
                                        {errorProductCode?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id='add-product-category'>
                                            Category
                                        </InputLabel>
                                        <Select
                                            className='custom_category'
                                            labelId='add-product-category'
                                            id='demo-multiple-name'
                                            // multiple
                                            name='product_category'
                                            value={parentCategory}
                                            onChange={handleParentCategory}
                                            input={
                                                <OutlinedInput label='Category' />
                                            }
                                        MenuProps={MenuPropsSelect}
                                        >
                                            {uniqueCategories?.map(
                                                (data: any) => (
                                                    <MenuItem
                                                        key={data}
                                                        value={data}
                                                    >
                                                        {data}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id='demo-multiple-name-label'>
                                            Sub-category
                                        </InputLabel>
                                        <Select
                                            className='custom_category'
                                            labelId='demo-multiple-name-label'
                                            id='demo-multiple-name'
                                            // multiple
                                            value={product}
                                            onChange={handleProduct}
                                            input={
                                                <OutlinedInput label='Category' />
                                            }
                                        MenuProps={MenuPropsSelect}
                                        >
                                            {items?.map((data: any) => (
                                                <MenuItem
                                                    key={data.product}
                                                    value={data.product}
                                                >
                                                    {data.product}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info '
                                        name='product_cost'
                                        type='number'
                                        id='outlined-basic'
                                        label='Cost'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    Rs
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <span className='error'>
                                        {errorProductCost?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='discount'
                                        type='text'
                                        id='outlined-basic'
                                        label='Discount'
                                        variant='outlined'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <span className='error'>
                                        {errorDiscount?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='total_commission'
                                        type='text'
                                        id='outlined-basic'
                                        label='Commission'
                                        variant='outlined'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <span className='error'>
                                        {errorTotalCommission?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='param_1'
                                        type='text'
                                        id='outlined-basic'
                                        label='Parameter 1'
                                        variant='outlined'
                                    />
                                    <span className='error'>
                                        {errorParam1?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='param_2'
                                        type='text'
                                        id='outlined-basic'
                                        label='Parameter 2'
                                        variant='outlined'
                                    />
                                    <span className='error'>
                                        {errorParam2?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='param_3'
                                        type='text'
                                        id='outlined-basic'
                                        label='Parameter 3'
                                        variant='outlined'
                                    />
                                    <span className='error'>
                                        {errorParam3?.message}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        size='small'
                                        multiline
                                        minRows={2}
                                        maxRows={6}
                                        fullWidth
                                        className='influencer_personal_info'
                                        name='product_description'
                                        type='text'
                                        id='outlined-basic'
                                        label='Description'
                                        variant='outlined'
                                    />
                                    <span className='error'>
                                        {errorProductDescription?.message}
                                    </span>
                                </Grid>
                            </Grid>
                            <Button
                                variant='outlined'
                                type='submit'
                                className='mt-3 btn btn-outline-primary'
                            >
                                Add Product
                            </Button>
                        </Grid>
                        <Grid item md={3}>
                            {' '}
                            <div className='cover_image_section '>
                                {showUploadPopup && (
                                    <FileUploadPopup

                                        openModal={showUploadPopup}
                                        onClose={(e) => setShowUploadPopup(false)}
                                        onSend={(e: any) => {
                                            setProductImageUrl(e.url);
                                            setProductImagePath(e.key);
                                        }
                                        }
                                        path={path}
                                        acceptedFileTypes=".jpg, .jpeg, .png"
                                    />
                                )}
                                <img
                                    aria-label='cropped image'
                                    className='campaign_cover_image'
                                    alt='cover'
                                    src={
                                        productImageUrl === ''
                                            ? defaultImage
                                            : productImageUrl
                                    }
                                />
                                <Button
                                    className='btn btn-outline-primary upload_button'
                                    onClick={() => setShowUploadPopup(true)}
                                    variant='contained'
                                    startIcon={<FileUploadIcon />}
                                >
                                    Upload Image
                                </Button>
                                <span className='error'>
                                    {errorProductImage?.message}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID,
    };
};

const connectToRedux = connect(mapStateToProps)(AddProduct);

export { connectToRedux as AddProduct };
