import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../../components/scrollToTop';
import './thanku.scss';
import { UserLogin, SwitchLayer2, Loading } from '../../../redux/action';

import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { LocalService, PublicService } from '../../../core/services';
import useBodyClass from '../../../hooks/useBodyClass';

const localService = new LocalService();
const publicService: PublicService = new PublicService();

interface Props {
    UserReducer: (data: any) => void;
    SwitchUserData: (data: any) => void;
    switchLayer1: any;
    loading: (data: any) => void;
    activeAccount: any;

}

const ThankuAgencyPage: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const login = localService.getStorageItem('login');
    const password = localService.getStorageItem('password');
    const pin = localService.getStorageItem('pin');

    // Get user-id from URL
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') || '';
    const url = window.location.href || '';

    localService.setStorageItem(
        'login',
        JSON.stringify(login)
    );
    if (password) {
        localService.setStorageItem(
            'password',
            JSON.stringify(password)
        );
    }
    if (pin) {
        localService.setStorageItem(
            'pin',
            JSON.stringify(pin)
        );

    }
    useBodyClass('common-thankyou-page');

    useEffect(() => {
        // Function to send the POST request
        async function recordFbLeadConversion() {
            if (!id) {
                console.error('No user ID found in URL');
                return;
            }
            try {
                const response = await publicService.recordFbConversionLead({ user_id: id, url: url });
                // console.log('Response:', response);
            } catch (error) {
                console.error('Error sending POST request:', error);
            }
        }

        // Call the function to send the POST request
        recordFbLeadConversion();
    }, [id]);

    return (
        <>
            <section className='sec-thanks'>
                <ScrollToTop />
                <div className='container'>
                    <div className='sec-thanks-left'>
                        <h3>Welcome to ClanConnect!<br />
                            Lets get you started.
                        </h3>
                        <ul className='ps-0'>
                            <li>
                                <span className="triangle-border triangle-border-inner"></span>
                                <span>Feel free to reach out to us at <Link to="mailto:agency.support@clanconnect.ai">agency.support@clanconnect.ai</Link><br /> with any questions or feedback you may have.
                                </span>
                            </li>

                            <button className='btn btn-outline-primary' onClick={() => navigate('/login')}>Go to Dashboard</button>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <div className="container-fluid" >

                <div className="container __thanku_container">
                    <h1>Thank you, enjoy</h1>
                    <h1 onClick={() => navigate('/login')}> Click  here to login </h1>
                </div>
            </div> */}
        </>
    )
}
const mapStateToProps = (state: any) => {
    return {
        switchLayer1: state.SwitchLayer1Reducer.switchAccount,
        activeAccount: state.UserReducer.UserData

    };
};

const mapStateToDispatch = (dispatch: any) => {
    return {
        UserReducer: (data: any) => dispatch(UserLogin(data)),
        SwitchUserData: (data: any) => dispatch(SwitchLayer2(data)),
        loading: (data: any) => dispatch(Loading(data)),
    };
};
const connectToRedux = connect(mapStateToProps, mapStateToDispatch)(ThankuAgencyPage);
export { connectToRedux as ThankuAgencyPage };
