import React from "react";

interface Props {
  width?: any;
  height?: any;
  className?: string;
}

const InstagramIcon: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        minWidth: '20px',
        minHeight: '20px',
        width: props.width,
        height: props.height,
        marginRight: '5px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        
        viewBox="98.08 104.07 314.83 314.85"
      >

        <linearGradient
          id="instagram-color-a"
          x1="255.531"
          x2="255.531"
          y1="117.176"
          y2="406.065"
          gradientUnits="userSpaceOnUse"
        >

          <stop offset="0" stopColor="#ea8928" />
          <stop offset="1" stopColor="#cf2b8f" />
        </linearGradient>
        <path
          fill="url(#instagram-color-a)"
          d="M326.1 104.1H185c-47.9 0-86.9 39-86.9 86.9v141c0 47.9 39 86.9 86.9 86.9h141c47.9 0 86.9-39 86.9-86.9V191c0-47.9-38.9-86.9-86.8-86.9zm58.9 228c0 32.5-26.4 58.9-58.9 58.9H185c-32.5 0-58.9-26.4-58.9-58.9V191c0-32.5 26.4-58.9 58.9-58.9h141c32.5 0 58.9 26.4 58.9 58.9l.1 141.1z"
        />
        <linearGradient
          id="instagram-color-b"
          x1="255.531"
          x2="255.531"
          y1="117.176"
          y2="406.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ea8928" />
          <stop offset="1" stopColor="#cf2b8f" />
        </linearGradient>
        <path
          fill="url(#instagram-color-b)"
          d="M255.5 180.4c-44.7 0-81.1 36.4-81.1 81.1 0 44.7 36.4 81.1 81.1 81.1s81.1-36.4 81.1-81.1c0-44.7-36.3-81.1-81.1-81.1zm0 134.3c-29.3 0-53.2-23.9-53.2-53.2 0-29.3 23.9-53.2 53.2-53.2s53.2 23.9 53.2 53.2c0 29.4-23.8 53.2-53.2 53.2z"
        />
        <linearGradient
          id="instagram-color-c"
          x1="340.043"
          x2="340.043"
          y1="117.176"
          y2="406.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ea8928" />
          <stop offset="1" stopColor="#cf2b8f" />
        </linearGradient>
        <path
          fill="url(#instagram-color-c)"
          d="M340 156.7c-5.4 0-10.7 2.2-14.5 6-3.8 3.8-6 9.1-6 14.5s2.2 10.7 6 14.5c3.8 3.8 9.1 6 14.5 6s10.7-2.2 14.5-6c3.8-3.8 6-9.1 6-14.5s-2.2-10.7-6-14.5c-3.8-3.8-9.1-6-14.5-6z"
        />
      </svg>
    </div>
  );
};

export default InstagramIcon;
