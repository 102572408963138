import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { CopyAll, Delete, Edit } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
interface Data {
  name: any;
  status: string;
  creatives: any;
  influencer: any;
  er: number;
  budget: number;
  amountSpent: number;
  clicks: number;
  conversions: number;
  planProductName: string;
  timestamp: string;
}
type campaignStatusType = {
  active: string;
  draft: string;
  underReview: string;
  completed: string;
};
type creativeStatusType = {
  active: string;
  draft: string;
  underReview: string;
  completed: string;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  minWidth?: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    minWidth: 170,
  },
  // {
  //   id: "status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Status",
  //   minWidth: 170,
  // },
  {
    id: 'influencer',
    numeric: true,
    disablePadding: false,
    label: 'Influencers',

    minWidth: 170,
  },
  {
    id: 'creatives',
    numeric: true,
    disablePadding: false,
    label: 'Creatives',
    minWidth: 170,
  },
  {
    id: 'er',
    numeric: true,
    disablePadding: false,
    label: 'ER',
    minWidth: 170,
  },
  {
    id: 'budget',
    numeric: true,
    disablePadding: false,
    label: 'Budget',
    minWidth: 170,
  },
  {
    id: 'amountSpent',
    numeric: true,
    disablePadding: false,
    label: 'Amount Spent',
    minWidth: 170,
  },
  {
    id: 'clicks',
    numeric: true,
    disablePadding: false,
    label: 'Clicks',
    minWidth: 170,
  },
  {
    id: 'conversions',
    numeric: true,
    disablePadding: false,
    label: 'Conversions',
    minWidth: 170,
  },
  {
    id: 'planProductName',
    numeric: false,
    disablePadding: false,
    label: 'Plan Product Name',
    minWidth: 170,
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Timestamp',
    minWidth: 170,
  },
];

//

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          Plan
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Filter list'>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

interface TableProps {
  dataSource: any;
  filter: string;
}

export default function PlanHomeNew(props: TableProps) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const handleDuplicate = () => setOpen(true);
  const [rows] = React.useState<any>(props.dataSource);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const navigateCampaignList = (data: any) => {
    // navigate(`/brand/plan/campaigns/${data.plan_id}`);
    // console.log('test data ==> ', data);

    navigate(`/brand/campaigns?planID=${data.plan_id}`);
  };

  return (
    <>
      {rows.length > 0 && (
        <div className='plantable-container'>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              {/* <EnhancedTableToolbar
                                numSelected={selected.length}
                            /> */}
              <TableContainer className='table-plan'>
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody className='pln_table_body'>
                    {visibleRows
                      .filter((data: any) => {
                        return Object.values(data)
                          .join('')
                          .toLocaleLowerCase()
                          .includes(props.filter.toLocaleLowerCase());
                      })
                      .map((row: any, index) => {
                        const isItemSelected = isSelected(String(row.name));
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            // selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              style={{
                                minWidth: '200px',
                              }}
                            >
                              <div className='d-flex'>
                                <span
                                  onClick={() => {
                                    navigateCampaignList(row);
                                  }}
                                >
                                  {row.name}
                                </span>
                              </div>
                            </TableCell>

                            <TableCell
                              style={{
                                minWidth: '150px',
                              }}
                            >
                              <Tooltip
                                arrow
                                title={`Accepted - ${row.proposal_accepted}`}
                              >
                                <span className='camp-status active'>
                                  {row.proposal_accepted}
                                </span>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={`Pending - ${row.proposal_pending}`}
                              >
                                <span className='camp-status draft'>
                                  {row.proposal_pending}
                                </span>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={`Rejected - ${row.proposal_rejected}`}
                              >
                                <span className='camp-status under-review'>
                                  {row.proposal_rejected}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '150px',
                              }}
                            >
                              <Tooltip
                                arrow
                                title={`Approved - ${row.creative_approved}`}
                              >
                                <span className='camp-status active'>
                                  {row.creative_approved}
                                </span>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={`Pending - ${row.creative_pending}`}
                              >
                                <span className='camp-status draft'>
                                  {row.creative_pending}
                                </span>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={`Rejected - ${row.creative_rejected}`}
                              >
                                <span className='camp-status under-review'>
                                  {row.creative_rejected}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '70px',
                              }}
                            >
                              {row.er}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '120px',
                              }}
                            >
                              {row.budget}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '150px',
                              }}
                            >
                              {row.amountSpent}
                            </TableCell>

                            <TableCell
                              style={{
                                minWidth: '120px',
                              }}
                            >
                              {row.clicks}
                            </TableCell>

                            <TableCell
                              style={{
                                minWidth: '120px',
                              }}
                            >
                              {row.conversions}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '190px',
                              }}
                            >
                              {row.planProductName}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '140px',
                              }}
                            >
                              {dayjs(row.created_at).format('LL')}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  color: '#00aae5',
                }}
              />
            </Paper>
          </Box>
          <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id='transition-modal-title'
                  variant='h6'
                  component='h2'
                >
                  Text in a modal
                </Typography>
                <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </div>
      )}
    </>
  );
}
