import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultAccountLogo } from '../../../config/config';

const SelectPostStory = () => {
  return (
    <li>
      <span className='pb-3 d-inline-flex fs-14'>Select the Story you want to use in automation.</span>
      <div className='specific-post-container'>
        <div className='d-flex fs-14 mb-3'>
          <span>Specific Story</span>
          <Link className='ms-auto' to="">See more</Link>
        </div>
        <div className='post-image-container'>
          {[...Array(3)].map((_, index) => (
            <div key={index} className='post-image post-story'>
              <img src={DefaultAccountLogo.brand} style={{ width: '98px', height: '174px', objectFit:'contain' }} />
            </div>
          ))}
        </div>
      </div>
    </li>
  );
};

export default SelectPostStory;
