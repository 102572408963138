import React, { useEffect, useState } from 'react'
import { AgreementService } from '../../../../../../../core/services/influencer-agreement.services'
import CommonPopUp from '../../../../../../../components/popup/common-popup'
import { LoadingButton } from '@mui/lab'
import { LocalService } from '../../../../../../../core/services'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { SortingColumnsType, TableComponent } from '../../../../../../../components/table-component/table'
import { TableCell, TableRow } from '@mui/material'


type Props = {
    step?: any;
    campaignId?: any;
    influencerId?: any;
    userType?: any;
    proposalData?: any;
}
const agreementService = new AgreementService()
const localService = new LocalService();

const InfluencerAgreementTerms = ({
    campaignId, influencerId, userType, proposalData
}: Props) => {
    const [agreementData, setAgreementData] = useState<any[]>([])
    const [chooseFile, setChooseFile] = useState<File | null>(null);
    const [signedTemplate, setSignedTemplate] = useState(false);
    const [confirmProceed, setConfirmProceed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availableAgreementData, setAvailableAgreementData] = useState<any[]>([])
    const [renderData, setRenderData] = useState<any>([]);
    const columnDisplayName = {
        received: "Received",
        sent: "Sent",
    };
    const sortingColumnType: SortingColumnsType[] = [
        {
            name: "received",
            columnSize: "60px",
        },
        {
            name: "sent",
            columnSize: "60px",
        }
    ];
    const getAgreementDetail = async () => {
        try {
            const response = await agreementService.getAgreementDetails({
                influencer_id: proposalData[0].influencer_id,
                campaign_id: proposalData[0].campaign_id,
            });
            if (response.data.status.status) {
                setAgreementData(response.data.rows)
            }
        } catch (error) {
            console.error('Error fetching agreement details:', error);
        }
    };

    useEffect(() => {
        getAgreementDetail()
    }, [])

    const handleCloseTemplate = () => {
        setSignedTemplate(false)
        setConfirmProceed(false)
    };

    const handleChooseFileChange = (e: any) => {
        const selectedChooseFile = e.target.files[0];
        if (selectedChooseFile) {
            setChooseFile(selectedChooseFile);
        }
    };

    const handleRemoveChooseFile = () => {
        setChooseFile(null);
    };
    async function saveSignedAgreement() {
        setLoading(true);
        try {
            const formData = new FormData();

            if (chooseFile) {
                formData.append('file', chooseFile);
                formData.append('path', `campaign_agreements/${agreementData[0].influencer_id}/influencer`);
            }
            formData.append('influencer_id', agreementData[0].influencer_id || 0);
            formData.append('campaign_id', agreementData[0].campaign_id || 0);
            formData.append('proposal_id', proposalData[0].proposal_id || 0);
            formData.append('sm_id', proposalData[0].sm_id || 0);

            const response = await agreementService.signedAgreementInsert(formData);
            const toastType = response.data.status?.status ? "success" : "error";
            localService.toastify(response.data.status?.message, toastType, 1000);

            // Fetch updated agreement data
            if (response.data.status?.status) {
                await getSignedAgreement();
            }

        } catch (error: any) {
            console.error(`saveAgreementDetail error :: ==> ${error.message}`);
        } finally {
            setLoading(false);
            setChooseFile(null);
            setSignedTemplate(false);
            setConfirmProceed(false)
        }
    }

    const getSignedAgreement = async () => {
        try {
            const response = await agreementService.getSignedAgreementDetails({
                campaign_id: agreementData[0].campaign_id || 0,
                influencer_id: agreementData[0].influencer_id || 0,
            });
            if (response.data.status.status) {
                setAvailableAgreementData(response?.data.rows)
            }
        } catch (error) {
            console.error('Error fetching agreement details:', error);
        }
    };
    useEffect(() => {
        getSignedAgreement();
    }, [agreementData])

    const handleConfirmProceed = () => {
        setConfirmProceed(true);
        setSignedTemplate(true)
    };
    
    return (
        <div className='proposal-main-container'>
            {(userType == "Influencer" || userType == "Talent Partner") && agreementData.length > 0 &&
                <>
                    <h5><strong>Influencer Agreement Terms</strong></h5>
                    <div className='mt-3' style={{ maxWidth: "500px" }}>
                        <TableComponent
                            columnDisplayName={columnDisplayName}
                            orderByColumnName=""
                            inputDataSource={agreementData}
                            sortingColumnType={sortingColumnType}
                            renderData={renderData}
                            setRenderData={setRenderData}
                            tableMinWidth={300}
                        >
                            {renderData.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        No Agreement Available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                renderData.map((data: any, index: number) => {
                                    return (
                                        <TableRow key={index} hover role="checkbox">
                                            {/* Received Column */}
                                            <TableCell>
                                                <div className='d-flex flex-column'>
                                                    <a href={data.agreement_url} target="_blank" rel="noopener noreferrer">
                                                        <DescriptionOutlinedIcon htmlColor={data.agreement_url ? 'black' : 'gray'} />
                                                    </a>
                                                    <span>{new Date(data.updated_at).toLocaleDateString()}{' '}</span>
                                                </div>
                                            </TableCell>

                                            {/* Sent Column */}
                                            <TableCell>
                                                <div className='d-flex'>
                                                    {availableAgreementData[0]?.signed_agr_url && (
                                                        <div className='d-flex flex-column'>
                                                            <a
                                                                href={availableAgreementData[0].signed_agr_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <DescriptionOutlinedIcon htmlColor='black' />
                                                            </a>
                                                            <span>
                                                                {new Date(availableAgreementData[0].updated_at).toLocaleDateString()}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <button
                                                            className={`btn btn-outline-primary btn-sm ${availableAgreementData[0]?.signed_agr_url ? 'ms-4' : ''}`}
                                                            onClick={() => setConfirmProceed(true)}
                                                        >
                                                            Upload Signed Agreement
                                                        </button>
                                                    </div>
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                            )}
                        </TableComponent>
                    </div>

                    <CommonPopUp
                        modalTitle="Upload"
                        modalContent=""
                        openModal={signedTemplate}
                        onClose={handleCloseTemplate}
                        className="modal-medium mx-auto"
                    >
                        <div className='upload-doc-sec'>
                            <>
                                {chooseFile &&
                                    <div className='creative-image-preview-sec'>

                                        <span className='material-symbols-rounded icon-filled close-preview' onClick={handleRemoveChooseFile} >cancel</span>
                                        <div className='d-flex flex-column'>
                                            <span className='material-symbols-rounded text-center mb-2' style={{ fontSize: '60px' }}>picture_as_pdf</span>
                                            {chooseFile && chooseFile.name}
                                        </div>
                                    </div>
                                }
                                {!chooseFile &&
                                    <div className="upload-btn">
                                        <input type="file" accept="application/pdf" onChange={handleChooseFileChange} />
                                        <span className='material-symbols-rounded'>
                                            upload
                                        </span> <strong>Upload</strong>
                                    </div>
                                }
                                <span className='fs-14 pb-5'>accepts: .pdf</span>
                                <LoadingButton
                                    className='upload-file-btn btn btn-primary'
                                    loading={loading}
                                    loadingPosition='start'

                                    onClick={() => {
                                        saveSignedAgreement();
                                    }}            >
                                    Send
                                </LoadingButton>
                            </>
                        </div>
                    </CommonPopUp>
                    <CommonPopUp
                        modalTitle="Upload"
                        modalContent=""
                        openModal={confirmProceed}
                        onClose={handleCloseTemplate}
                        className="modal-medium mx-auto"
                    >
                        <div>
                            <div><p>Please ensure you have thoroughly reviewed the agreement and added any missing terms before proceeding. </p></div>
                            <div className='popup-footer text-end'>
                                <div className="">
                                    <button
                                        className='btn btn-primary'
                                        onClick={handleConfirmProceed}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    </CommonPopUp>
                </>
            }
        </div>

    )
}

export default InfluencerAgreementTerms