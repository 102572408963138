import React, { useEffect, useState } from 'react'
import CustomDrawer from '../custom-drawer/custom-drawer'
import { LoadingButton } from '@mui/lab';
import ContentLoading from '../loader/loading';

type Props = {
  openPopup: any;
  confirmClosePopup: any;
  drawerTitle: any;
  extraClass?: any;
  confirmClickAction: any;
  confirmPopupInnerText: any;
  popupId?:any;
}

function ConfirmPopup({ popupId, openPopup, confirmClosePopup, drawerTitle, extraClass, confirmClickAction, confirmPopupInnerText }: Props) {

  const [isSubmitting, setIsSubmitting] = useState(false); // Disabled state

  const confirmAction = async () => {
    setIsSubmitting(true); // Disable button
    try {
      await confirmClickAction(); // Perform the confirm action
    } catch (error) {
      console.error('Error during confirmation:', error);
    } finally {
      setIsSubmitting(false); // Re-enable button after action is complete
      confirmClosePopup(); // Close the popup after confirming action
    }
  }
  useEffect(() => {
    const confirmTextElement = document.getElementById('confirm-text');
    if (confirmTextElement) {
      confirmTextElement.innerHTML = confirmPopupInnerText;
    }
  }, [confirmPopupInnerText]);
  
  return (
    <CustomDrawer
      open={openPopup}
      onClose={confirmClosePopup}
      drawerTitle={drawerTitle}
      className={`popup-confirm ${extraClass}`}
    >
      <div className='custom-drawer-body'>
        <div className='custom-drawer-body-inner'>
          <div className='confirm-body'>
            <div className='d-flex flex-column'>
              <span className="material-symbols-fw-200 material-symbols-rounded confirm-icon">
                help
              </span>
              <strong className='confirm-title'>Confirm</strong>
            </div>
            {isSubmitting && <ContentLoading width='150' height='150' />}
            {!isSubmitting &&
              <p id={`${popupId ? popupId :'confirm-text'}`}>{confirmPopupInnerText}</p>
            }
          </div>
        </div>
        <div
          className='d-flex justify-content-end custom-drawer-footer'
          style={{ borderTop: '1px solid #d7d7d7' }}
        >
          <LoadingButton
            className='btn btn-primary btn-lg'
            // loading={loading}
            disabled={isSubmitting} 
            loadingPosition='start'
            variant='contained'
            onClick={confirmAction}
            style={{ marginLeft: '10px' }}
          >
            Confirm
          </LoadingButton>

        </div>
      </div>
    </CustomDrawer>
  )
}

export default ConfirmPopup