import React, { FormEvent, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Instagram_Deliverables, Youtube_Deliverables, Quantity, No_of_Images, No_of_Days, InfluencerTypes } from '../../../../../core/enums';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import * as Util from '../../../../../core/utility';
import './campaign-deliverables.scss';
import { MenuPropsAutoComplete, MenuPropsSelect } from '../../../../../core/utility/style';

const methods: Util.Methods = new Util.Methods();


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




type IntialFormData = {
    deliverable: string;
    ig_static_image: number | null;
    quantity: number | null;
    influencer_type: number[];
};

const intialFormData: IntialFormData = {
    deliverable: '',
    ig_static_image: null,
    quantity: null,
    influencer_type: []
};

interface ComponentProps {
    disableDeliverables?: boolean;
    disableInfluencerType?: boolean;
    disableQuantity?: boolean;
    disableNoOfDays?: boolean;
    disableNoOfImages?: boolean;
    error?: boolean;
    activeTab?: any;
    influencerType?: any;
    onChange: (data: IntialFormData) => void;
    value: IntialFormData;
    formDataCollection: IntialFormData[];
    deliverable_type: string;
    platform: ('instagram' | 'youtube' | Omit<string, 'instagram' | "youtube">);
}

/**
 * 
 * @returns A form to add deliverables to the campaign.
 */
export const DeliverablesComponent: React.FC<ComponentProps> = ({ onChange, value, platform = 'instagram', ...arg }: ComponentProps) => {
    const [random] = useState(Math.floor(100000 + Math.random() * 900000));
    const [deliverables] = useState((platform).toString().toLowerCase() === 'instagram' ? Instagram_Deliverables : Youtube_Deliverables);
    const [influencerType, setInfluencerType] = useState<typeof InfluencerTypes>([]);
    const [formData, setFormData] = useState<IntialFormData>(value);
    /**
     * Checks if the available value is included in the filter array.
     * 
     * @param availableValue - The value to check.
     * @param filterArray - The array to check against.
     * @returns A boolean indicating if the available value is included in the filter array.
     */
    function InOut(availableValue: string, filterArray: string[]) {
        return filterArray.includes(availableValue);
    };


    /**     
     * Resets the form data when the deliverable changes.
     */
    useEffect(() => {
        if (formData.deliverable !== value.deliverable) {
            setFormData(prev => ({ ...intialFormData, deliverable: prev.deliverable }))
        }
    }, [formData.deliverable]);

    useEffect(() => {
        onChange(formData);
    }, [formData]);

    useEffect(() => {
        if (formData.deliverable !== value.deliverable) {
            setFormData(value);
        }
    }, [value]);

    useEffect(() => {
        setFormData(value);
    }, [arg.formDataCollection])

    /**
     * Retrieves the available influencer types based on the deliverables type.
     * @param deliverablesType - The type of deliverables.
     * @returns An array of available influencer types.
     */
    function available_Influencer(deliverablesType: string) {
        let influencerT = [...InfluencerTypes];

        for (let x of arg.formDataCollection) {
            if (x.deliverable === deliverablesType) {
                influencerT = methods.filterArray(x.influencer_type, influencerT, 'id', 'not_exist') as typeof influencerT;
            }
        }

        return influencerT;
    };




    function autoCompleteOptions() {
        let influencerT: typeof InfluencerTypes = [];
        influencerT = [
            ...available_Influencer(arg.deliverable_type),
            ...(methods.filterArray(formData.influencer_type, InfluencerTypes, 'id') as typeof InfluencerTypes),
        ];

        influencerT = methods.uniqueObjectArray([
            ...influencerT,
            ...methods.filterArray(influencerT.map(obj => obj.id), InfluencerTypes, 'id', 'not_exist')
                ?.map(obj => ({ ...obj, status: 'inactive' })) as typeof InfluencerTypes

        ], 'id');

        return influencerT.sort((a, b) => a.id - b.id);
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
    }


    return (
        <div className={`container-fluid p-0 deliverable-component`} id={`deliverable-component-${random}`}>
            <form onSubmit={handleSubmit}>
                <div className="row m-0">
                    <div className="col p-0">
                        <FormControl fullWidth disabled={arg.disableDeliverables}>
                            <InputLabel size='small' id="deliverables">Deliverables</InputLabel>
                            <Select
                                required
                                size='small'
                                labelId={`deliverables_id_${Math.floor(100000 + Math.random() * 900000)}`}
                                id={`deliverables_id_${Math.floor(100000 + Math.random() * 900000)}`}
                                multiple={false}
                                value={formData.deliverable}
                                input={<OutlinedInput label="Deliverables" />}
                                MenuProps={MenuPropsSelect}
                                onChange={(event) => setFormData(prev => ({ ...prev, deliverable: event.target.value }))}
                            >
                                {deliverables.map((data, index) => (
                                    <MenuItem
                                        key={index}
                                        value={data.value}
                                    // style={getStyles(name, personName, theme)}
                                    >
                                        {data.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {
                        InOut(formData.deliverable, ['ig_static']) &&
                        <div className="col-3 pe-0">
                            <FormControl fullWidth disabled={arg.disableNoOfImages}>
                                <InputLabel size='small' id="no_of_image_id">No of images</InputLabel>
                                <Select
                                    required
                                    size='small'
                                    labelId={`no_of_images_${Math.floor(100000 + Math.random() * 900000)}`}
                                    id={`no_of_images_${Math.floor(100000 + Math.random() * 900000)}`}
                                    multiple={false}
                                    value={formData.ig_static_image}
                                    input={<OutlinedInput label="No of images" />}
                                    MenuProps={MenuPropsSelect}
                                    onChange={(event: SelectChangeEvent<typeof formData.ig_static_image>) => setFormData(prev => ({ ...prev, ig_static_image: event.target.value as number }))}
                                >
                                    {No_of_Images.map((data, index) => (
                                        <MenuItem
                                            key={index}
                                            value={data.value}
                                        // style={getStyles(name, personName, theme)}
                                        >
                                            {data.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    {
                        InOut(formData.deliverable, ['available_for_exclusivity', 'digital_rights']) &&
                        <div className="col">
                            <FormControl fullWidth disabled={arg.disableNoOfDays}>
                                <InputLabel size='small' id="no_of_day_id">No of days</InputLabel>
                                <Select
                                    required
                                    size='small'
                                    labelId={`no_of_day_id_${Math.floor(100000 + Math.random() * 900000)}`}
                                    id={`no_of_day_id_${Math.floor(100000 + Math.random() * 900000)}`}
                                    multiple={false}
                                    value={formData.quantity}
                                    input={<OutlinedInput label="No of days" />}
                                    MenuProps={MenuPropsSelect}
                                    onChange={(event: SelectChangeEvent<typeof formData.quantity>) => setFormData(prev => ({ ...prev, quantity: event.target.value as number }))}
                                >
                                    {No_of_Days.map((data, index) => (
                                        <MenuItem
                                            key={index}
                                            value={data.value}
                                        // style={getStyles(name, personName, theme)}
                                        >
                                            {data.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    {
                        !InOut(formData.deliverable, ['available_for_exclusivity', 'digital_rights']) &&
                        <div className="col-2 pe-0">
                            <FormControl fullWidth disabled={arg.disableQuantity}>
                                <InputLabel size='small' id="quantity_id">Qty</InputLabel>
                                <Select
                                    required
                                    size='small'
                                    labelId={`quantity_id_${Math.floor(100000 + Math.random() * 900000)}`}
                                    id={`quantity_id_${Math.floor(100000 + Math.random() * 900000)}`}
                                    multiple={false}
                                    value={formData.quantity}
                                    input={<OutlinedInput label="Quantity" />}
                                    MenuProps={MenuPropsSelect}
                                    onChange={(event: SelectChangeEvent<typeof formData.quantity>) => setFormData(prev => ({ ...prev, quantity: event.target.value as number }))}
                                >
                                    {Quantity.map((data, index) => (
                                        <MenuItem
                                            key={index}
                                            value={data.value}
                                        // style={getStyles(name, personName, theme)}
                                        >
                                            {data.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }

                    <div className="col">
                        {arg.activeTab == 2 ?
                        <Autocomplete
                        disabled={arg.disableInfluencerType}
                        disableClearable={true}
                        size="small"
                        id={`influencerType_id_${Math.floor(100000 + Math.random() * 900000)}`}
                        limitTags={1}
                        multiple={true}
                        disableCloseOnSelect={true}
                        options={influencerType}
                        onFocus={() => setInfluencerType(autoCompleteOptions())}
                        value={methods.filterArray(value.influencer_type, InfluencerTypes, 'id')}
                        onChange={(event, value) => {
                          // Replace [2, 3, 4] with your array of allowed IDs
                          const allowedIds = arg.influencerType;
                      
                          // Filter out the options that are not part of the allowed IDs array
                          const filteredValue = value.filter((data: any) => allowedIds.includes(data.id));
                      
                          setFormData((prev) => ({
                            ...prev,
                            influencer_type: filteredValue.map((data: any) => data.id),
                          }));
                        }}
                        getOptionLabel={(option) => option.influencer_type}
                        ListboxProps={MenuPropsAutoComplete.PaperProps}
                        renderOption={(props, option, { selected }) => {
                          // Replace [2, 3, 4] with your array of allowed IDs
                          const allowedIds = arg.influencerType;
                          const isDisabled = !allowedIds.includes(option.id);
                      
                          return (
                            <li
                              {...props}
                              key={option.id}
                              className={`cln_autocomplete_default autocomplete_option_${option.status} ${isDisabled ? 'disabled' : ''}`}
                            >
                              <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                disabled={isDisabled} // Disable the checkbox if it matches the condition
                              />
                              <span className={`${isDisabled ? 'disabled' : ''}`}>{option.influencer_type} ({methods.formatNumber(option.min).includes('M+') ? methods.formatNumber(option.max) : methods.formatNumber(option.min) + ' - ' + methods.formatNumber(option.max)})</span>
                            </li>
                          );
                        }}
                        renderInput={(params) => <TextField {...params} label="Influencer Type" />}
                      />
                      :
                        <Autocomplete
                            disabled={arg.disableInfluencerType}
                            disableClearable={true}
                            size='small'
                            id={`influencerType_id_${Math.floor(100000 + Math.random() * 900000)}`}
                            limitTags={1}
                            multiple={true}
                            disableCloseOnSelect={true}
                            options={influencerType}
                            onFocus={() => setInfluencerType(autoCompleteOptions())}
                            value={methods.filterArray(value.influencer_type, InfluencerTypes, 'id')}
                            onChange={(event, value) => setFormData(prev => ({ ...prev, influencer_type: value.map((data: any) => data.id) }))}
                            getOptionLabel={(option) => option.influencer_type}
                            ListboxProps={MenuPropsAutoComplete.PaperProps}
                            renderOption={(props, option, { selected }) => (
                                <li  {...props} key={option.id}  className={`cln_autocomplete_default autocomplete_option_${option.status} autocomplete-option ${
                                    selected ? 'selected' : ''
                                  }`}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        // style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.influencer_type} ({methods.formatNumber(option.min).includes('M+') ? methods.formatNumber(option.max) : methods.formatNumber(option.min) + ' - ' + methods.formatNumber(option.max)})
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Influencer Type" />
                            )}
                        />
                        }
                        {arg.error && <span className='error'>influencer type can't be empty</span>}
                    </div>
                </div>
            </form>
        </div>
    )
};


