import {
    LOGIN_SUCCESS,
    LOGOUT,
    LOADING,
    LOGINUSERID,
    ACCOUNTSWITCH,
    USERSWITCH,
    CREATECAMPAIGN,
    CAMPAIGNEDIT,
    SELECTEDINFLUENCERCAMP,
    GUEST_USER,
    UPDATE_USER_ID,
    USER_EMAIL_ID,
    ADDTOCART,
    REMOVEFROMCART,
    SET_CART,
    UPDATE_QUANTITY,
    UPDATE_TOTAL,
    SUBSCRIPTIONDETAIL
} from './types';

export const UserLogin = (data: any) => {
    if (data) {
        return {
            type: LOGIN_SUCCESS,
            data,
        };
    }
};

export const UserLogout = () => {
    return {
        type: LOGOUT,
    };
};

export const Loading = (data: any) => {
    return {
        type: LOADING,
        data,
    };
};

export const LoginUserId = (data: any) => {
    return {
        type: LOGINUSERID,
        data,
    };
};

export const SwitchLayer1 = (data: any) => {
    return {
        type: ACCOUNTSWITCH,
        data,
    };
};

export const SwitchLayer2 = (data: any) => {
    return {
        type: USERSWITCH,
        data,
    };
};

export const CreateCampaign = (data: any) => {
    return {
        type: CREATECAMPAIGN,
        data,
    };
};

export const EditCampaign = (data: any) => {
    return {
        type: CAMPAIGNEDIT,
        data,
    };
};

export const SelectedCampaign = (data: any) => {
    return {
        type: SELECTEDINFLUENCERCAMP,
        data,
    };
};

// setGuestUser
export const setGuestUser = (data: any) => {
    return {
        type: GUEST_USER,
        payload: data,
    };
};

export const setGuestUserId = (data: any) => {
    return {
        type: UPDATE_USER_ID,
        payload: data,
    };
};

export const setGuestUserEmail = (data: any) => {
    return {
        type: USER_EMAIL_ID,
        payload: data,
    };
};

export const AddToCart = (data: any) => {
    return {
        type: ADDTOCART,
        payload: data,
    };
};

export const RemoveFromCart = (data: any) => {
    return {
        type: REMOVEFROMCART,
        payload: data,
    };
};

export const setCart = (data: any) => {
    return {
        type: SET_CART,
        payload: data,
    };
};

export const updateTotal = (total: any) => {
    return {
        type: UPDATE_TOTAL,
        payload: total,
    };
};

export const updateQuantity = (quantity: any) => {
    return {
        type: UPDATE_QUANTITY,
        payload: quantity,
    };
};

export const SubscriptionDetail = (data:any) =>{
    return {
        type: SUBSCRIPTIONDETAIL,
        data
    }
}