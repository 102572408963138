import { Autocomplete, Checkbox, Chip, Grid, Stack, TextField } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { CustomGenderButton, NewLayout } from './custom-designs';
import { AudienceGender } from '../../../core/enums/discover';
import { PublicService } from '../../../core/services';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { MenuPropsAutoComplete } from '../../../core/utility/style';

const publicService: PublicService = new PublicService();

type FormDataType = {
    audience_metrics: {
        location_name: string[],// ["Roorkee, Uttarakhand"],
        gender: string[], // ["M", "F"]
        age_range: string[] //["13-17"]
    }
};


const initialFormData: FormDataType = {
    audience_metrics: {
        location_name: [],
        gender: [],
        age_range: []
    }
}

interface Props {
    clearData: boolean;
    platform: ('instagram' | 'youtube');
    setFilterData: (data: FormDataType) => void;
    isBasicUser: boolean;
    handleOpenUpgradeBrandModal?:((value:boolean) => void) | undefined;
}

export const AudienceFilters: React.FC<Props> = ({ setFilterData, platform, ...arg }: Props) => {
    const [formData, setFormData] = useState(initialFormData);
    const [locations, setLocations] = useState<{ name: string }[]>([]);
    const [ageRange, setAgeRange] = useState<{ age_range: string }[]>([]);

    useEffect(() => {
        publicService.get_audience_location(platform).then(res => {
            setLocations(res.data.rows);
        }).catch((err) => console.log(err))

        publicService.get_audience_age_range(platform).then(res => {
            setAgeRange(res.data.rows);
        }).catch((err) => console.log(err))

    }, []);


    useEffect(() => {
        setFilterData(formData);
    }, [formData]);

    useEffect(() => {
        if (arg.clearData) {
            setFormData(initialFormData);
        }
    }, [arg.clearData]);


    useEffect(() => {
        setFormData(initialFormData);
    }, [platform])


    const filter_AutoComplete_Value = (selectedIds: any[], dataSource: any[], filterObjectKey: string) => {
        return dataSource.filter((data: any) => selectedIds.indexOf(data[filterObjectKey]) !== -1)
    };

    const handleInputs = (event: SyntheticEvent, value: { name: string }[], objectKey: ('location_name' | 'age_range'), subKey: string) => {
        setFormData(prev => ({
            ...prev, audience_metrics:
                { ...prev.audience_metrics, [objectKey]: value.map((data: any, index) => data[subKey]) }
        }))
    };

    function removeInfluencerMetrics(index: number, objectKey: ('location_name' | 'age_range' | 'gender')) {
        const temp = formData.audience_metrics[objectKey];
        temp.splice(index, 1)
        setFormData(prev => ({ ...prev, audience_metrics: { ...prev.audience_metrics, [objectKey]: temp } }))
    }

    return (
        <NewLayout label={'Audience of Influencer'} size={'large'}>
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }} className='mt-0'>
                    <Grid  item lg={3} md={6} sm={12} xs={12}>
                        <CustomGenderButton
                            onChange={(e: any) => setFormData(prev => ({ ...prev, audience_metrics: { ...prev.audience_metrics, gender: e } }))}
                            dataSource={AudienceGender}
                            label='Primary Gender' clearData={arg.clearData} formData={formData} 
                            isBasicUser={arg.isBasicUser}
                            section='audience-gender'
                            handleOpenUpgradeBrandModal={arg.handleOpenUpgradeBrandModal}
                        />
                    </Grid>
                    <Grid className={arg.isBasicUser ? 'user-basic' : ''} item lg={3} md={6} sm={12} xs={12}>
                    {arg.isBasicUser && <span className='pro-tag' style={{top:'34px', right:'35px'  }}>PRO</span>}
                        <Autocomplete
                            multiple={true}
                            limitTags={1}
                            className='bg-white'
                            size='small'
                            id="audience_age_range_id"
                            disableCloseOnSelect={true}
                            value={filter_AutoComplete_Value(formData.audience_metrics.age_range, ageRange, 'age_range')}
                            onChange={(event, value) => (arg.isBasicUser && arg.handleOpenUpgradeBrandModal ? arg.handleOpenUpgradeBrandModal(true) : handleInputs(event, value, "age_range", 'age_range'))}
                            ListboxProps={MenuPropsAutoComplete.PaperProps}
                            // options={ageRange}
                            options={ageRange.sort((a, b) => (a.age_range > b.age_range) ? 1 : -1)}
                            getOptionLabel={(option) => option.age_range}
                            renderOption={(props, option, { selected }) => (
                                <li  {...props} key={option.age_range}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.age_range.split('_').pop().replace(/-/, ' - ')}
                                </li>
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option.age_range.split('_').pop().replace(/-/, ' - ')}
                                        {...getTagProps({ index })}
                                        // style={{ backgroundColor: '#1976d2', color: '#fff' }}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Age Range" />
                            )}
                        />
                    </Grid>
                    {platform === 'instagram' && <Grid className={arg.isBasicUser ? 'user-basic' : ""} item lg={6} md={6} sm={12} xs={12}>
                    {arg.isBasicUser && <span className='pro-tag' style={{top:'34px', right:'35px'  }}>PRO</span>}
                        <Autocomplete
                            className='bg-white'
                            multiple={true}
                            size='small'
                            limitTags={1}
                            id="audience_location_id"
                            disableCloseOnSelect={true}
                            value={filter_AutoComplete_Value(formData.audience_metrics.location_name, locations, 'name')}
                            onChange={(event, value) => (arg.isBasicUser && arg.handleOpenUpgradeBrandModal ? arg.handleOpenUpgradeBrandModal(true) : handleInputs(event, value, 'location_name', 'name'))}
                            options={locations}
                            getOptionLabel={(option) => option.name}
                            ListboxProps={MenuPropsAutoComplete.PaperProps}
                            renderOption={(props, option, { selected }) => (
                                <li  {...props} key={option.name}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Locations" />
                            )}
                        />
                    </Grid>}

                </Grid>
            </form>
            <div className='extend-chips'>
                {/* LOCATIONS CHIPS */}
                {formData.audience_metrics.location_name.length > 0 &&
                    <div className="sel-category"><span className=''>Location</span>
                        <Stack direction="row" spacing={1}>
                            {formData.audience_metrics.location_name.map((data, index) => {
                                let filter = locations
                                    .filter(fil => fil.name === data);
                                if (filter.length > 0) {
                                    return <Chip
                                        key={index}
                                        id={`audience_location_id_${index}_id`}
                                        label={data}
                                        onDelete={(e) => removeInfluencerMetrics(index, 'location_name')} />
                                };

                                return null
                            })}
                        </Stack>
                    </div>
                }
                {/* GENDER CHIPS */}
                {formData?.audience_metrics?.gender.length > 0 && (
                    <div className='sel-category'>
                        <span>Primary Gender</span>
                        <Stack direction="row" spacing={1}>
                            {formData.audience_metrics?.gender.map((gender, index) => (
                                <Chip
                                    key={index}
                                    label={
                                        gender === "M" ? "Male" :
                                            gender === 'F' ? "Female" :
                                                gender === 'A' ? 'All' : ""
                                    }
                                    onDelete={() => removeInfluencerMetrics(index, 'gender')}
                                />
                            ))}
                        </Stack>
                    </div>
                )}
                {/* AGE RANGE CHIPS */}
                {formData.audience_metrics.age_range.length > 0 &&
                    <div className="sel-category" ><span className=''>Age Range</span>
                        <Stack direction="row" spacing={1}>
                            {formData.audience_metrics.age_range.map((data:any, index:number) => {
                                let filter = ageRange
                                    .filter((fil:any) => fil.age_range === data);
                                if (filter.length > 0) {
                                    return <Chip
                                        key={index}
                                        id={`audience_age_range_${index}_id`}
                                        label={data?.split('_').pop().replace(/-/, ' - ')}
                                        onDelete={(e) => removeInfluencerMetrics(index, 'age_range')} />
                                };

                                return null
                            })}
                        </Stack>
                    </div>
                }
            </div>
        </NewLayout>

    )
};