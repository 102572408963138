import React, { Fragment, useEffect, useState, useRef } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Lottie from 'react-lottie';
import MaterialSymbol from "../icons/material-symbol";
import { Trivia } from '../../data/data'
import dancingRobo from '../../data/lottie/dancingRobo.json';
import { useLocation } from 'react-router-dom';


export const ErrorPage: React.FC = () => {
    const [countdown, setCountdown] = useState(10);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const triviaContentRef = useRef(Trivia.Trivia_array[Math.floor(Math.random() * Trivia.Trivia_array.length)].content);
    const [pathname] = useState(window.location.pathname);
    const location = useLocation();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dancingRobo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if (pathname !== location.pathname) {
            reload();
        }
    }, [location])


    useEffect(() => {

        if (!isTimerActive) return;

        const interval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    window.location.reload();
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [isTimerActive]);

    const refreshPage = () => {
        // window.location.reload();
        window.location.href = window.location.origin;
    };

    const stopTimer = () => {
        setIsTimerActive(!isTimerActive);
    };

    const goToHome = () => {
        window.location.href = window.location.origin;
    };

    function reload() {
        window.location.reload();
    }

    return (
        <Fragment>
            <div className="error-page container">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className="error-page-left">
                                <p className="error-heading">Oops, looks like our tech robo took a wrong turn! <br />We're redirecting it back to the main route.</p>
                                <div className="error-redirect-div">
                                    <span className="error-play-stop">
                                        <p className="error-redirect">Redirecting in {countdown} seconds </p>
                                        <MaterialSymbol className="stop-play-icon" onClick={stopTimer} iconName={isTimerActive ? 'stop_circle' : 'play_circle'} fontweight="200" />
                                    </span>
                                    <button className="btn btn-outline-primary" onClick={refreshPage}>Redirect now</button>
                                </div>
                                <p className="error-afresh">Want to start afresh?<span onClick={goToHome} className="error-go-home">Go to homepage</span></p>
                                <div className="error-trivia-div">
                                    <p className="error-trivia-title">Here is a trivia to appreciate your patience.</p>
                                    <p className="error-trivia-content">{triviaContentRef.current}</p>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <div className="error-page-right">
                                <Lottie
                                    options={defaultOptions}
                                    height={400}
                                    width={400}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Fragment>
    )

}
