import React from "react";
import "./campaign-configurator.scss";
import "./campaign-configurator.scss";
import megaplus from "../../../assets/images/campaign_icons/mega-plus.png";
import mega from "../../../assets/images/campaign_icons/mega.png";
import macroplus from "../../../assets/images/campaign_icons/macro-plus.png";
import macro from "../../../assets/images/campaign_icons/macro.png";
import microplus from "../../../assets/images/campaign_icons/micro-plus.png";
import micro from "../../../assets/images/campaign_icons/micro.png";
import nano from "../../../assets/images/campaign_icons/nano.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MenuPropsSelect } from "../../../core/utility/style";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Art",
  "Automobiles",
  "Beauty",
  "Computers",
  "DIY & Design",
  "Education",
  "Entertainment",
  "Fashion",
  "Fitness & Gym",
  "Food",
  "Gadgets",
  "Humor,Fun & Comedy",
  "Lifestyle",
  "Mobiles",
  "Movies",
  "Music",
  "Restaurants",
  "Sports",
  "Travel & Tourism",
];

export const CampaignConfigurator: React.FC = () => {
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/brand/configure");
  };
  return (
    <div>
      <div className="main-container-campaign container-part">
        <div className="container-fluid">
          <div className="campaign-container">
            <div className="container-fluid campaign d-flex flex-row">
              <div className="campaign_inputs">
                <form
                  className="new_simulator_campaign"
                  id="cs_campaign"
                  data-parsley-validate="true"
                  action="/campaign_simulator"
                  accept-charset="UTF-8"
                  method="post"
                >
                  <input name="utf8" type="hidden" value="✓" />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value="ukNbuyySZU2P1tCDqQoZjtzPgTzaz+nGnuLZTGVMxLTmtszny2LkfBmiktZ2UX4g7wtUDRcXtjQMK/lMWTQWvw=="
                  />
                  <h2 className="campaign_inputs_title">
                    Campaign Configurator
                  </h2>
                  <div className="config-input">
                    <label
                      className="config-input-label padding-b"
                      htmlFor="Objective"
                    >
                      Objective
                    </label>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Objective
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Awareness (Reach Driven)"
                        name="radio-buttons-group"
                        sx={{ marginRight: -3 }}
                      >
                        <FormControlLabel
                          value="Awareness (Reach Driven)"
                          control={<Radio />}
                          label="Awareness (Reach Driven)"
                        />
                        <FormControlLabel
                          value="Drive ROI (Targeted CPV) coming soon"
                          control={<Radio />}
                          label="Drive ROI (Targeted CPV) coming soon"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <hr className="budget_line d-none" />
                  <p className="font14 d-none" id="budget-info">
                    Please enter any 2
                  </p>

                  <div className="config-input">
                    <label className="config-input-label padding-b">
                      Budget *
                    </label>
                    <TextField
                      id="outlined-basic"
                      label="Upto 10 Lakhs"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    className="row d-none mx-0"
                    id="cpv-info"
                    style={{ gap: "48px" }}
                  >
                    <div className="d-flex flex-column">
                      <label className="font16b padding-b">CPV Values *</label>
                      <div className="input-box">
                        <input
                          className="form-control cpv-budget"
                          id="cpv_amount"
                          type="text"
                          name="simulator_campaign[cpv_amount]"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <label className="font16b padding-b">
                        <strong>Total Views *</strong>
                      </label>
                      <div className="input-box">
                        <input
                          className="form-control cpv-budget"
                          id="num_of_views"
                          type="text"
                          value="0"
                          name="simulator_campaign[num_of_views]"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="budget_line d-none" />
                  <div className="config-input">
                    <label className="config-input-label padding-b">
                      Platform *
                    </label>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Platform
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="instagram"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="instagram"
                          control={<Radio checkedIcon={<InstagramIcon />} />}
                          label="Instagram"
                        />

                        <FormControlLabel
                          value="youtube"
                          control={
                            <Radio
                              checkedIcon={
                                <YouTubeIcon style={{ color: "red" }} />
                              }
                            />
                          }
                          label="Youtube"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="config-input">
                    <label className="config-input-label padding-b">
                      Post Type
                    </label>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Reel
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="reel"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="reel"
                          control={<Radio />}
                          label="Reel"
                        />
                        <FormControlLabel
                          value="static"
                          control={<Radio />}
                          label="Static"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="config-input">
                    <label className="config-input-label padding-b">
                      Gender *
                    </label>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="config-input category-input">
                    <label className="config-input-label padding-b">
                      Category *
                    </label>

                    <FormControl sx={{ m: 1, width: 500 }} size="small">
                      <InputLabel id="demo-multiple-checkbox-label">
                        Tag
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuPropsSelect}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="config-input">
                    <label className="config-input-label padding-b">
                      Influencer Type *
                    </label>
                    <div className="config-input-detail">
                      <div className="conf-infl-type d-flex flex-wrap">
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div
                            className="form-check"
                            id="influencer_type_mega_tier2"
                          >
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="2008073"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="mega_tier2"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_mega_tier2"
                            >
                              <img alt="" src={megaplus} />
                              <div className="d-flex flex-column">
                                <strong>Mega Plus</strong>
                                (5M+)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="celeb-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                min="0"
                                max="100"
                                disabled
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[mega_tier2_budget_percent]"
                                id="simulator_campaign_mega_tier2_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div className="form-check" id="influencer_type_mega">
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="400559"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="mega_tier1"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_mega"
                            >
                              <img alt="" src={mega} />
                              <div className="d-flex flex-column">
                                <strong>Mega</strong>
                                (1M - 5M)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="mega-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                min="0"
                                max="100"
                                disabled
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[mega_tier1_budget_percent]"
                                id="simulator_campaign_mega_tier1_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div
                            className="form-check"
                            id="influencer_type_macro_tier2"
                          >
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="200082"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="macro_tier2"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_macro_tier2"
                            >
                              <img alt="" src={macroplus} />
                              <div className="d-flex flex-column">
                                <strong>Macro Plus</strong>
                                (500K - 1M)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="macro-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                min="0"
                                max="100"
                                disabled
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[macro_tier2_budget_percent]"
                                id="simulator_campaign_macro_tier2_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div
                            className="form-check"
                            id="influencer_type_macro"
                          >
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="40011"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="macro_tier1"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_macro"
                            >
                              <img alt="" src={macro} />
                              <div className="d-flex flex-column">
                                <strong>Macro</strong>
                                (100K - 500K)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="macro-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                min="0"
                                max="100"
                                disabled
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[macro_tier1_budget_percent]"
                                id="simulator_campaign_macro_tier1_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div
                            className="form-check"
                            id="influencer_type_micro_tier2"
                          >
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="20001"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="micro_tier2"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_micro_tier2"
                            >
                              <img alt="" src={microplus} />
                              <div className="d-flex flex-column">
                                <strong>Micro Plus</strong>
                                (50K - 100K)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="micro-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                disabled
                                min="0"
                                max="100"
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[micro_tier2_budget_percent]"
                                id="simulator_campaign_micro_tier2_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div
                            className="form-check"
                            id="influencer_type_micro"
                          >
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="4000"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="micro_tier1"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_micro"
                            >
                              <img alt="" src={micro} />
                              <div className="d-flex flex-column">
                                <strong>Micro</strong>
                                (10K - 50K)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="micro-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                disabled
                                min="0"
                                max="100"
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[micro_tier1_budget_percent]"
                                id="simulator_campaign_micro_tier1_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex influencer_type_checkbox-sec">
                          <div className="form-check" id="influencer_type_nano">
                            <input
                              className="influencer-type-checkbox form-check-input"
                              data-actions-box="true"
                              data-live-search="true"
                              data-min-price="400"
                              id="flexCheckDefault3"
                              multiple
                              name="simulator_campaign[influencer_type][]"
                              type="checkbox"
                              value="nano"
                              data-parsley-multiple="simulator_campaigninfluencer_type"
                              disabled
                            />
                            <label
                              className="form-check-label d-flex flex-row"
                              htmlFor="influencer_type_nano"
                            >
                              <img alt="" src={nano} />
                              <div className="d-flex flex-column">
                                <strong>Nano</strong>
                                (1K - 10K)
                              </div>
                            </label>
                          </div>
                          <div className="budget-box" id="nano-budget">
                            <div className="input-box mt-2">
                              <input
                                className="form-control"
                                min="0"
                                max="100"
                                disabled
                                placeholder="Budget %"
                                type="number"
                                name="simulator_campaign[nano_budget_percent]"
                                id="simulator_campaign_nano_budget_percent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="btn-center">
                    <input
                      type="submit"
                      name="commit"
                      value="Configure"
                      className="btn btn-primary"
                      data-disable-with="Configure"
                      onClick={handleNavigation}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
