import React from "react";
import InstagramIcon from '../../assets/images/icon-instagram.png'
interface Props {
    width?: any;
    height?: any;
    className?: string;
}

const InstagramIcon1: React.FC<Props> = (props: Props) => {
    return (
        <span
            className={props.className}
            style={{
                minWidth: '20px',
                minHeight: '20px',
                width: props.width,
                height: props.height,
                marginRight: '5px',
                display: 'inline-flex',
                alignSelf: 'center'
            }}
        >
            <img src={InstagramIcon} alt="Instagram" width={props.width} height={props.height} />
        </span>
    );
};

export default InstagramIcon1;
