import React, { useEffect, useState, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  TextField,
  IconButton,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApplyCampaignService, ListService, LocalService } from '../../../../../../../core/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppLoader } from '../../../../../../../components';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';
import Loading from '../../../../../../../components/loader/loading';
import { DeliverableTool } from '../proposal-components/deliverable-tool';
import { Profile } from '../proposal-components/profile';
import ProposalContainer from '../proposal-components/proposal-container';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import FileUploadPopup from '../../../../../../../components/popup/file-upload-popup';
import { GenerateAgreement } from '../../../../../utlities/influencers-agreements/agreement-generate';
import UpgradeBrandPopUp from '../../../../../../../components/popup/upgrade-brand-popup';

interface Props {
  UserReducer: any;
  selectedCampaign: any;
  unreadProposal: any;
  updateUnreadProposal: () => void;
  set_sm_id?: (id: number) => void;
  getSubscriptionDetail: any;
  influencerSelectedCamapign: any;
}

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';

type CurrentSteps = 1 | 2 | 3 | 4;

interface Influencer {
  influencer_id: number;
  username: string;
  sm_id: number;
}

interface FormDataType {
  address_id: number;
  negotiation_id: number;
  proposal_id: number;
  campaign_id: number;
  influencer_id: number;
  sm_id: number;
  deliverable_item: string;
  s_0_quantity: number;
  s_0_price: number;
  s_0_status: StatusType;
  s_0_date: string;
  s_1_quantity: number;
  s_1_price: number;
  s_1_status: StatusType;
  s_1_date: string;
  s_2_quantity: number;
  s_2_price: number;
  s_2_status: StatusType;
  s_2_date: string;
  s_3_quantity: number;
  s_3_price: number;
  s_3_status: StatusType;
  s_3_date: string;
  current_step: CurrentSteps;
  status: StatusType;
  proposal_datetime: string;
  created_at: string;
  updated_at: string;
  shipping_receipt_url: string;
  product_delivery_status: string;
  product_delivery_date: number;
  product_delivery_remarks: string;
  product_delivery_image_url: string;
  shipping_details: string;
  shipping_receipt_path: string;
}

const applycampaignService = new ApplyCampaignService();
const localService = new LocalService();

const BrandAccepted: React.FC<Props> = (props: Props) => {

  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [originalInfluencers, setOriginalInfluencers] = useState<Influencer[]>([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([]);
  const [expanded, setExpanded] = useState<number | false>(false);
  const [details, setDetails] = useState<FormDataType[]>([]);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [deliverableHeader, setDeliverableHeader] = useState<any>([]);
  const [campaignType] = useState(props.selectedCampaign?.campaign_type || "Paid");
  const [proposalAddress_id, setProposalAddress_id] = useState(0);
  const [shippingDetails, setShippingDetails] = useState('');
  const [addressList, setAddressList] = useState<any>();
  const [file, setFile] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [panelDetails, SetPanelDetails] = useState({
    panelNumber: 0,
    smId: 0
  })
  
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [sendAgreement, setSendAgreement] = useState(false);
  const [openUpgradeBrandModal, setopenUpgradeBrandModal] = useState(false);
  const [campaignId, setCampaignId] = useState('');
  const navigate = useNavigate()
  const [formuploadData, setformuploadData] = useState({
    shipping_receipt_url: '',
    shipping_receipt_path: '',
    file_type: '',
    file_name: "",
    storage_size: "",
    proposal_id: "",
    shipping_details: "",
    trackingDetails: ""
  });
  const [influencersData, setInfluencersData] = useState({
    Nano: [],
    Micro: [],
    'Micro Plus': [],
    Macro: [],
    'Macro Plus': [],
    Mega: [],
    'Mega Plus': [],
    All: [],
  });
  
  const campaignDetails = useMemo(() => {
    return {
      campaignId: searchParams.get('campaignID'),
      planId: searchParams.get('planID'),
    };
  }, [searchParams]);

  const getDeliverableHeader = async () => {
    try {
      const response: any = await ApplyCampaignService.getDeliverableHeader({ campaign_id: campaignDetails.campaignId });
      if (response.status === 1) {
        setDeliverableHeader(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching deliverable header:', error);
    }
  };

  const getInfluencerList = async () => {
    try {
      setLoading(true);
      const response = await ApplyCampaignService.influencerList({ campaign_id: campaignDetails.campaignId, proposalStatus: 'Accepted' });
      if (response?.data?.rows?.length >= 0) {
        let inf: any = {
          Nano: [],
          Micro: [],
          'Micro Plus': [],
          Macro: [],
          'Macro Plus': [],
          Mega: [],
          'Mega Plus': [],
          All: response.data.rows,
        };

        response.data.rows.forEach((res: any) => {
          if (inf[res.infType]) {
            inf[res.infType].push(res);
          }
        });

        setInfluencersData(inf);
        setInfluencers(response.data.rows);

        setOriginalInfluencers(response.data.rows);
        setFilteredInfluencers(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching influencer list:', error);
    } finally {
      setLoading(false);
    }
  };


  const getAddressList = async (address_id: number) => {
    if (address_id > 0) {
      const response = await ListService.brandShippingAddressList({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        address_id: address_id,
      });

      if (response.data.status.status === true) {
        setAddressList(response.data.rows);
      }
    }
  };

  useEffect(() => {
    getInfluencerList();
    getDeliverableHeader();
    getAddressList(proposalAddress_id)

  }, [campaignDetails.campaignId]);

  const handleChange = async (panel: number, sm_id: number) => {
    try {
      setLoading(true);
      if (expanded === panel) {
        setExpanded(false);
      }
      else {
        const response = await ApplyCampaignService.brandProposalDetails({ smId: sm_id, campaign_id: campaignDetails.campaignId });
        SetPanelDetails({ panelNumber: panel, smId: sm_id })
        if (response?.data?.rows?.length > 0) {
          setDetails(response.data.rows);
          setExpanded(panel);
          getInfluencerList();
          setProposalAddress_id(parseInt(response.data.rows[0].address_id));
          const address_id = parseInt(response.data.rows[0].address_id);
          setProposalAddress_id(address_id);
          await getAddressList(address_id);
        }

      }
    }
    catch (error) {
      console.error('Error fetching proposal details:', error);
    } finally {
      if (props.unreadProposal.some((e: any) => e.sm_id === sm_id)) {
        props.updateUnreadProposal();
      }
      setLoading(false);
    }
  };
  const path = `accounts/${props.UserReducer.account_id}/campaign/${campaignDetails.campaignId}/barter`

  const uploadFile = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const updatadFormData = {
        ...formuploadData,
        proposal_id: `${details[0].proposal_id}`,
        shipping_details: formuploadData.shipping_details,
        shipping_receipt_path: formuploadData.shipping_receipt_path,
        shipping_receipt_url: formuploadData.shipping_receipt_url,
      }
      setformuploadData(updatadFormData)
      const response = await applycampaignService.brandShippingReceiptUpload(updatadFormData);
      if (response.data.status.status === true) {
        localService.toastify(response.data.status.message, 'success', 2000);
        handleChange(panelDetails.panelNumber, panelDetails.smId)
      }

      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      localService.toastify('An error occurred', 'info', 1000);
      setLoading(false);
    }
  };


  const handleTrackingDetailsChange = (e: any) => {
    setformuploadData({ ...formuploadData, shipping_details: e.target.value })

  }
  const handleClosePreviewImage = () => {
    setFile("")

  }
  const handleFilteredInfluencer = (event: any) => {
    const value = event.toLowerCase();
    if (value === 'all') {
      setFilteredInfluencers(originalInfluencers);
    } else {
      const filtered = originalInfluencers.filter((influencer: any) =>
        influencer?.infType?.toLowerCase() === value?.toLowerCase()
      );
      setFilteredInfluencers(filtered);
    }
  };

  const handleSendAgreements = (influencer: any) => {
    if (props.getSubscriptionDetail.plan_id == 9 || (props.getSubscriptionDetail.isBasicUser && props.influencerSelectedCamapign.campaign_type == 'Paid')) {
      setopenUpgradeBrandModal(true)
    } else {
      let getParams = new URLSearchParams(window.location.search).get('campaignID');
      setSelectedInfluencer(influencer);
      setCampaignId(getParams ?? '')
      // Set sendAgreement to true to trigger the agreement process
      setSendAgreement(true);

      // Reset sendAgreement to false after it's been triggered
      setTimeout(() => setSendAgreement(false), 0);
    }
  };

  function handleCloseUpradeBrandModal() {
    setopenUpgradeBrandModal(false);
  }

  return (

    <div className="proposal-container">
      {/* PROPOSAL CONTAINER LEFT */}
      <div className="proposal-container-left">
        <DeliverableTool data={influencersData} filteredInfluencer={(influencer: any) => handleFilteredInfluencer(influencer)} />
      </div>

      {/* PROPOSAL CONTAINER RIGHT */}
      <div className="proposal-container-right">
        {filteredInfluencers.map((inf, index) => (
          <Accordion className='accordion-container' key={inf.influencer_id} expanded={expanded === index} onChange={() => handleChange(index, inf.sm_id)}>
            <AccordionSummary>
              <div className="proposal-header">
                {props.unreadProposal.length > 0 && (props.unreadProposal.map((influencer_data: any, index: any) => (
                  (inf.sm_id === influencer_data.sm_id && influencer_data.status === 'Accepted' && influencer_data.read == 'no') &&

                  <span className='unread-notification-icon'></span>

                )))}
                <div className="proposal-header-left">
                  <Profile profileData={inf} data={details} deliverableHeader={deliverableHeader} userReducer={props.UserReducer} />
                </div>
                <div className="proposal-header-right">
                  {/* <div className="proposal-notification">
                    <Badge color="primary" className='color-primary' badgeContent={10}>
                    <IconButton onClick={(event:any) => {
                        event.stopPropagation();
                        props.set_sm_id && props.set_sm_id(inf.sm_id)
                      }}>                      
                      <MaterialSymbol fontweight="200" iconName="chat_bubble"/>
                      </IconButton>
                    </Badge>
                  </div> */}
                  {/* <span className="proposal-date">{inf?.profile_data.proposal_datetime}</span> */}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {loading && expanded === index && details ? (
                <Loading width='80' height='80' />
              ) : (
                <ProposalContainer
                  deliverableHeader={deliverableHeader}
                  data={details}
                  step={details}
                  profileData={inf}
                  campaignType={campaignType}
                  userType={props?.UserReducer}
                />
              )}
              {props.selectedCampaign.campaign_type
                .toString()
                .toLocaleLowerCase()
                .includes('barter') &&
                details &&
                details[0]?.status === 'Accepted' && (
                  <div
                    className={` updateBarterProduct-container accept-proposal ${details[0].address_id === 0 ? 'deactivated' : ''
                      }`}
                  >
                    <div className='product-detail-container'>
                      <div className='product-detail-title-sec'>
                        <h5>
                          <strong>Step 1 (Influencer) :</strong> Address Details{' '}
                        </h5>
                        <h6>The product delivery address.</h6>
                      </div>
                      <div className='saved-add-container'>
                        {details && proposalAddress_id > 0 && (
                          <>
                            {addressList &&
                              addressList?.map((data: any, index: number) => {
                                if (data?.address_id === proposalAddress_id) {
                                  return (
                                    <ul key={index}>
                                      <li>Country: {data?.country}</li>
                                      <li>State: {data?.state}</li>
                                      <li>City: {data?.city}</li>
                                      <li>PinCode: {data?.pincode}</li>
                                      <li>Flat No / House: {data?.house_no}</li>
                                      <li>Landmark: {data?.landmark}</li>
                                    </ul>
                                  );
                                }
                                return null;
                              })}
                          </>
                        )}
                      </div>
                    </div>
                    <div className='product-detail-container'>
                      <div className='product-detail-title-sec'>
                        <h5>
                          <strong>Step 2 (Brand) :</strong> Product Shipping
                          Details{' '}
                        </h5>
                        <h6>
                          The brand shares the product shipping details for your
                          reference.
                        </h6>
                      </div>
                      <div>
                        {(details && proposalAddress_id > 0 && details[0].shipping_receipt_url.length === 0) && (
                          <>
                            <div className="uploadproduct d-flex flex-column align-items-start" style={{ gap: '1rem' }}>
                              <TextField
                                placeholder="Product shipping details"
                                multiline
                                rows={4}
                                maxRows={4}
                                value={formuploadData.shipping_details}
                                onChange={handleTrackingDetailsChange}
                              />
                              <button type="button" className="btn btn-outline-primary" onClick={() => setShowUploadPopup(true)}>Upload</button>
                              <button type="submit" className="btn btn-primary mb-3" onClick={uploadFile}>Send</button>
                            </div>

                            <FileUploadPopup
                              openModal={showUploadPopup}
                              onClose={() => setShowUploadPopup(false)}
                              onSend={(e) => {
                                setformuploadData({
                                  ...formuploadData,
                                  shipping_receipt_url: e.url,
                                  shipping_receipt_path: e.key,
                                  file_type: e.fileType,
                                  file_name: e.fileName,
                                  storage_size: e.storage_size
                                });
                              }}
                              path={path}
                              acceptedFileTypes=".jpg, .jpeg, .png"
                            />
                          </>
                        )}

                        {details[0].shipping_receipt_url.length > 0 && (
                          <div className='tracking-details-img-preview'>
                            {details && (
                              <>
                                <span className='d-block mb-1'>{details[0].shipping_details}</span>
                                <img
                                  className='product_ship_image'
                                  src={details[0].shipping_receipt_url}
                                  alt='product_ship'
                                />
                              </>
                            )}

                          </div>

                        )}
                      </div>
                    </div>
                    <div className='product-detail-container'>
                      <div className='product-detail-title-sec'>
                        <h5>
                          <strong>Step 3 (Influencer) :</strong> Product Delivery
                          Acknowledgement{' '}
                        </h5>
                        <h6>
                          Acknowledgement by the influencer and creative timeline
                          for your reference.
                        </h6>
                      </div>

                      <>
                        {details[0].product_delivery_status !==
                          'Pending' && (
                            <div className='product_ship_detail shipping_receipt_section'>
                              {details && (
                                <ul className='ps-0'>
                                  <li>
                                    <strong>Status:</strong>{' '}
                                    {details[0].product_delivery_status}
                                  </li>
                                  <li>
                                    <strong>Delivery Date: </strong>
                                    {dayjs(
                                      details[0].product_delivery_date
                                    ).format('LL')}
                                  </li>
                                  <li>
                                    <strong>Remark: </strong>
                                    {details[0].product_delivery_remarks}
                                  </li>
                                  <li>
                                    <span>
                                      <strong>Product Image</strong>
                                    </span>
                                    <img
                                      className='image-style'
                                      src={
                                        details[0].product_delivery_image_url
                                      }
                                      alt='product delivery image'
                                    />
                                  </li>
                                </ul>
                              )}
                            </div>
                          )}
                      </>
                    </div>
                  </div>
                )}
            </AccordionDetails>
            <div onClick={() => handleSendAgreements(inf)}>
              <p className='text-end text-decoration-underline font-weight-bold' role="button">Send Agreement</p>
            </div>
          </Accordion>
        ))}
        {/* DISPLAY EMPTY MESSAGE */}
        {influencers.length === 0 && (
          <p className="no-list-comment align-items-baseline mt-5">There are no accepted proposals at the moment.</p>
        )}
        <div className=''>
          {selectedInfluencer && <GenerateAgreement influencer={selectedInfluencer} sendAgreement={sendAgreement} campaignId={campaignId} />}
        </div>
        {openUpgradeBrandModal && (
          <UpgradeBrandPopUp
            openUpgradeBrandModal={openUpgradeBrandModal}
            onClose={() => handleCloseUpradeBrandModal()}
            popupMinWidth='630px'
          >
          </UpgradeBrandPopUp>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
    getSubscriptionDetail: state.Subscription.subscription_detail,
    influencerSelectedCamapign: state.InfSelectCamp.campaign,
  };
};

const connectToRedux: any = connect(mapStateToProps)(BrandAccepted);

export { connectToRedux as BrandAccepted };
