import { TextField, Button, Radio, FormControlLabel, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./product-integeration.scss";
import { ClanShopService } from "../../../core/services/clan.shop.service";
import { Editor } from "../../../components";
import HeadingStyle from "../../../components/heading-style/heading-style";
import { LocalService } from "../../../core/services";
import ProductIntegerationInput from "./product_integeration_input";
// import {, TextField} from '@mui/material';


import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const localService = new LocalService();

const steps = ['Inventory Integeration', 'Pincode Integeration', 'Payment Integeration', 'Place Order Integeration', 'Order Tracking Integeration'];
export const BrandProductIntegeration = () => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [show, setShow] = useState(false);
  const [alteredOptions, setAlteredOptions] = useState<any>();
  const [textToCopy, setTextToCopy] = useState("");
  const location = useLocation();
  const { itemId } = location.state || {};
  const [selectedValue, setSelectedValue] = useState<any>("brand");
  const [pincodeSelectedValue, setPincodeSelectedValue] =
    useState<any>("PAN India");
  const [showInventory, setShowInventory] = useState<any>(true);
  const [showEditor, setShowEditor] = useState<any>(false);
  const [showPincode, setShowPincode] = useState<any>(false);
  const [showPayment, setShowPayment] = useState<any>(false);
  const [showPlaceOrder, setShowPlaceOrder] = useState<any>(false);
  const [showLogistics, setShowLogistics] = useState<any>(false);
  const [step, setStep] = useState<any>("Step 1 Inventory Integeration");
  const [editorError, setEditorError] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [checkIntegeration, setCheckIntegeration] = useState(false);
  const [data, setData] = useState({
    product_id: itemId.product_id,
    inventory_headers: "Authorization:token_nxkshdimm==j9374bmnbs864nsbd977q,",
    inventory_url: "https://myecommerce.website.com/api",
    inventory_params: "param1=value1&param2=value2",
    pincode_headers: "",
    pincode_params: "",
    pincode_url: "",
    payment_headers: "",
    payment_params: "",
    payment_url: "",
    placeOrder_headers: "",
    placeOrder_params: "",
    placeOrder_url: "",
    orderTracking_headers: "",
    orderTracking_params: "",
    orderTracking_url: "",
    delivery_pincode: pincodeSelectedValue,
    product_integeration: "Pending"

  });
  const [brandApi, setBrandApi] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      if (data.inventory_headers.trim() === "" || data.inventory_params.trim() === "" || data.inventory_url.trim() === "") {
        setErrorValue("Please fill all the fields");
        return
      } else {
        setErrorValue("");
      }
    }
    if (activeStep === 1) {
      if (data.delivery_pincode === "Api Integeration") {
        if (data.pincode_headers.trim() === "" || data.pincode_params.trim() === "" || data.pincode_url.trim() === "") {
          setErrorValue("Please fill all the fields");
          return
        } else {
          setErrorValue("");
        }
      }
    }
    if (activeStep === 2) {
      if (data.payment_headers.trim() === "" || data.payment_params.trim() === "" || data.payment_url.trim() === "") {
        setErrorValue("Please fill all the fields");
        return
      } else {
        setErrorValue("");
      }
    }
    if (activeStep === 3) {
      if (data.placeOrder_headers.trim() === "" || data.placeOrder_params.trim() === "" || data.placeOrder_url.trim() === "") {
        setErrorValue("Please fill all the fields");
        return
      } else {
        setErrorValue("");
      }
    }
    if (activeStep === 4) {
      if (data.orderTracking_headers.trim() === "" || data.orderTracking_params.trim() === "" || data.orderTracking_url.trim() === "") {
        setErrorValue("Please fill all the fields");
        return
      } else {
        setErrorValue("");
        ClanShopService.productIntegerationDetails(data)
          .then((res) => {
            if (res?.data?.status.status) {
              setCheckIntegeration(true);
              localService.toastify(res?.data?.status.message)
              //   setOrderData(res?.data?.rows);
            } else {
              localService.toastify(res?.data?.status.message)

            }
          })
          .catch((error) => {
            // setOrderData([]);
            console.log(error);
          });

      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
  }, [data, brandApi]);

  const handleEditorValue = (e: any) => {
    // setBrandApi(e)
    const queryString = e.split("?")[1];
    if (queryString) {
      const params = new URLSearchParams(queryString);
      // params.forEach((value, key) => {
      // });
      setAlteredOptions(params);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const handlePincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPincodeSelectedValue(event.target.value);
    setData((prevData) => ({
      ...prevData,
      delivery_pincode: event.target.value
    }));
    data.delivery_pincode = pincodeSelectedValue;
    if (event.target.value === "Api Integeration") {
      setShowEditor(!showEditor);
    } else {
      setShowEditor(false);
    }
  };
  const code = `
    Sample Code(Get Request)
        headers:{
            'Authorization':'Your Access Token / Api key',
            'Content-Type': 'application/json'
        }
        url:'https://example.com/api / Your Api URL'
        params:'https://example.com/api?param1=value1&param2=value2
        example => axios.get(url, headers)'
    `;
  const code2 = `
    Sample Code(Post Request)
        headers:{
            'Authorization':'Your Access Token / Api key',
            'Content-Type': 'application/json'
        }
        url:'https://example.com/api / Your Api URL'
        data:{
            'key':'value',
            'key':'value'
        }

        example => axios.post(url, data, headers)
    `;
  const codeSnippet = `
  const body = {
        clan_product_id:${itemId.product_id},
        brand_product_id:253456,
        inventory:20,
      }
      axios.post('https://affiliate.clanconnect.ai/api/brand-product-data/inventory', body);\n`;

  const codeSnippet2 = ` //Sample Code<br/>
  const config = {<br/>
        headers: {<br/>
          'authorization': 'Your_API_KEY',<br/>
        },<br/>
      };<br/>
      axios.get('https://affiliate.clanconnect.ai/api//brand-product-data/logistics', config);\n`;

  const handleCopyClick = () => {
    const textArea = document.createElement("textarea");
    textArea.value = codeSnippet;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
  };

  const handleNext1 = () => {
    setData((prevData) => ({
      ...prevData,
      inventory: selectedValue,
      inventory_brand_api:
        selectedValue === "clan" ? "" : JSON.stringify(brandApi),
    }));

    const inventory = selectedValue; // Use selectedValue instead of data.inventory
    const inventory_brand_api = selectedValue === "clan" ? "" : brandApi;
    if (
      inventory === "brand" &&
      (inventory_brand_api === "" || inventory_brand_api === "<p><br></p>")
    ) {
      setEditorError("this field is required");
    } else {
      setShowInventory(false);
      setShowPincode(true);
      setStep("Step 2 Pincode Integeration");
      setBrandApi("");
      setEditorError("");
    }
  };
  const handleNext2 = () => {
    setData((prevData) => ({
      ...prevData,
      delivery_pincode: pincodeSelectedValue,
      deliver_pincode_api:
        pincodeSelectedValue === "Api Integeration"
          ? JSON.stringify(brandApi)
          : "",
    }));
    const deliver_pincode = pincodeSelectedValue;
    const deliver_pincode_api =
      pincodeSelectedValue === "Api Integeration" ? brandApi : "";
    if (
      deliver_pincode === "Api Integeration" &&
      (deliver_pincode_api === "" || deliver_pincode_api === "<p><br></p>")
    ) {
      setEditorError("this field is required");
    } else {
      setShowPincode(false);
      setShowPayment(true);
      setStep("Step 3 Payment Integeration");
      setBrandApi("");
      setEditorError("");
    }
  };
  const handleNext3 = () => {
    // setShowInventory(false);

    setData((prevData) => ({
      ...prevData,
      payment_gateway: selectedValue,
      payment_gateway_brand_api:
        selectedValue === "clan" ? "" : JSON.stringify(brandApi),
    }));

    const payment_gateway = selectedValue; // Use selectedValue instead of data.inventory
    const payment_gateway_brand_api = selectedValue === "clan" ? "" : brandApi;
    if (
      payment_gateway === "brand" &&
      (payment_gateway_brand_api === "" ||
        payment_gateway_brand_api === "<p><br></p>")
    ) {
      setEditorError("this field is required");
    } else {
      setShowPayment(false);
      setShowPlaceOrder(true);
      setStep("Step 4 Place Order Integration");
      setBrandApi("");
      setEditorError("");
    }
  };

  const handleNext4 = () => {
    setData((prevData) => ({
      ...prevData,
      place_order_api: JSON.stringify(brandApi),
    }));
    // const deliver_pincode = pincodeSelectedValue;
    const place_order_api = brandApi;
    if ((place_order_api === "" || place_order_api === "<p><br></p>")
    ) {
      setEditorError("this field is required");
    } else {
      setShowPlaceOrder(false);
      setShowLogistics(true);
      setStep("Step 5 Order Tracking Integeration");
    }
  }

  // const handleFinalSubmit = () => {
  //   const tracking = selectedValue;
  //   const tracking_brand_api = selectedValue === "clan" ? "" : brandApi;
  //   setData((prevData) => ({
  //     ...prevData,
  //     tracking: tracking,
  //     tracking_brand_api: tracking_brand_api,
  //   }));
  //   data["tracking"] = tracking;
  //   if (
  //     tracking === "brand" &&
  //     (tracking_brand_api === "" || tracking_brand_api === "<p><br></p>")
  //   ) {
  //     setEditorError("this field is required");
  //   } else {
  //     ClanShopService.productIntegerationDetails(data)
  //       .then((res) => {
  //         if (res?.data?.status.status) {
  //           setOpen(true);
  //           //   setOrderData(res?.data?.rows);
  //         }
  //       })
  //       .catch((error) => {
  //         // setOrderData([]);
  //         console.log(error);
  //       });
  //   }
  // };

  const redirectToBrandClanShop = () => { };

  return (
    <div className="product-integeration">
      <div className="container">
        <div className="d-flex flex-column w-100 mb-4">
          <HeadingStyle title="Brand Integration" className="d-block mb-1" />
          <p>
            This will integerate with your <strong>E-Commerce</strong>{" "}
            platform. Please follow the steps for integeration.
          </p>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                // if (isStepOptional(index)) {
                // labelProps.optional = (
                //   <Typography variant="caption">Optional</Typography>
                // );
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (

              <React.Fragment>
                {checkIntegeration && (
                  <Typography sx={{ mt: 2, mb: 1 }} style={{ textAlign: 'center' }}>
                    <p>All steps completed - you&apos;re finished.</p>
                    <p>Product Integeration Request For this Product raised
                      SuccessFully. Technical Team Get in Touch With You. It Will
                      Take 24-48 hour.</p>
                  </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Link to={"/brand/clanshop"}>
                    <Button variant='outlined' autoFocus>Back to Shop</Button>
                    {/* onClick={handleReset} */}
                  </Link>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                <div>
                  {activeStep === 0 && (
                    <ProductIntegerationInput code={code} method='GET' headerValue={data.inventory_headers} urlValue={data.inventory_url} paramsValue={data.inventory_params} onHeaderChange={(e) => setData({ ...data, inventory_headers: e.target.value })}
                      onUrlChange={(e) => setData({ ...data, inventory_url: e.target.value })} onParamsChange={(e) => setData({ ...data, inventory_params: e.target.value })} errorValue={errorValue} />

                  )}
                  {activeStep === 1 && (
                    <div className="inner-container">
                      {/* <h3>Delvery/Pincode Details.</h3> */}
                      <div className="col-12 inner-container-title">
                        <p>
                          <Radio
                            checked={pincodeSelectedValue === "PAN India"}
                            onChange={handlePincodeChange}
                            value="PAN India"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "PAN India" }}
                          />{" "}
                          PAN India
                        </p>
                        <p>
                          <Radio
                            checked={pincodeSelectedValue === "Api Integeration"}
                            onChange={handlePincodeChange}
                            value="Api Integeration"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Api Integeration" }}
                          />{" "}
                          Api Integeration
                        </p>
                        <p>
                          <Radio
                            checked={pincodeSelectedValue === "Selected States"}
                            onChange={handlePincodeChange}
                            value="Selected States"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Selected States" }}
                            disabled
                          />{" "}
                          Selected States (*Coming Soon...)
                        </p>
                      </div>
                      {showEditor && (
                        <ProductIntegerationInput code={code} method='GET' headerValue={data.pincode_headers} urlValue={data.pincode_url} paramsValue={data.pincode_params} onHeaderChange={(e) => setData({ ...data, pincode_headers: e.target.value })}
                          onUrlChange={(e) => setData({ ...data, pincode_url: e.target.value })} onParamsChange={(e) => setData({ ...data, pincode_params: e.target.value })} errorValue={errorValue} />
                      )}
                    </div>
                  )}
                  {activeStep === 2 && (
                    <ProductIntegerationInput code={code} method='GET' headerValue={data.payment_headers} urlValue={data.payment_url} paramsValue={data.payment_params} onHeaderChange={(e) => setData({ ...data, payment_headers: e.target.value })}
                      onUrlChange={(e) => setData({ ...data, payment_url: e.target.value })} onParamsChange={(e) => setData({ ...data, payment_params: e.target.value })} errorValue={errorValue} />

                  )}
                  {activeStep === 3 && (
                    <ProductIntegerationInput code={code} method='GET' headerValue={data.placeOrder_headers} urlValue={data.placeOrder_url} paramsValue={data.placeOrder_params} onHeaderChange={(e) => setData({ ...data, placeOrder_headers: e.target.value })}
                      onUrlChange={(e) => setData({ ...data, placeOrder_url: e.target.value })} onParamsChange={(e) => setData({ ...data, placeOrder_params: e.target.value })} errorValue={errorValue} />

                  )}
                  {activeStep === 4 && (
                    <ProductIntegerationInput code={code} method='GET' headerValue={data.orderTracking_headers} urlValue={data.orderTracking_url} paramsValue={data.orderTracking_params} onHeaderChange={(e) => setData({ ...data, orderTracking_headers: e.target.value })}
                      onUrlChange={(e) => setData({ ...data, orderTracking_url: e.target.value })} onParamsChange={(e) => setData({ ...data, orderTracking_params: e.target.value })} errorValue={errorValue} />

                  )}
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  {/* <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

