import React, { useEffect, useState } from 'react';
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { ClanShopService } from '../../../core/services/clan.shop.service';
import { FormValidation } from '../../../core/utility';
import { useNavigate } from 'react-router-dom';
import { LocalService } from '../../../core/services';
import './clan-shop.scss';

const localService = new LocalService();
interface Props {
    UserReducer: any;
    UserID: any;
}

interface ErrorTypes {
    status: boolean;
    message: string;
}
const InfluencerClanShop: React.FC<Props> = (props: Props) => {
    const [shopDetails, setShopDetails] = useState<any>();
    const navigate = useNavigate();
    const formValidation = new FormValidation();
    const [uniqueName, setUniqueName] = useState(false);
    //validation
    const [errorShopName, setErrorShopName] = useState<ErrorTypes>();
    const [errorDescription, setErrorDescription] = useState<ErrorTypes>();

    const account_id = props.UserReducer?.account_id;

    useEffect(() => {
        ClanShopService.getShopDetails(`${account_id}`)
            .then((res) => {
                if (res?.data?.rows.length !== 0) {
                    navigate(
                        `/influencer/clanshop/myshop/${res?.data?.rows[0]?.shop_id
                        }/${(res?.data?.rows[0]?.shop_name)
                            .replace(/\s/g, '')
                            .toLowerCase()}`
                    );
                    localService.setShopId(res?.data?.rows[0]?.shop_id);
                    localService.setShopName(res?.data?.rows[0]?.shop_name);
                }
                setShopDetails(res?.data?.rows);
            })
            .catch((e) => setShopDetails([]));
    }, []);

    const handleShopName = (e: any) => {
        const shopName = e.target.value;
        const validationError = formValidation.GetShopNameValidate(shopName);
        setErrorShopName(validationError);
        if (validationError?.status === true) {
            ClanShopService.getShopName(`${shopName}`)
                .then((res: any) => {
                    if (res?.data?.rows.length === 0) {
                        setUniqueName(false);
                    } else {
                        setUniqueName(true);
                    }
                })
                .catch((e: any) => console.log(e));
        }
    };

    const handleCreateClanShop = (e: any) => {
        e.preventDefault();

        const form = new FormData(e.target);
        const data = Object.fromEntries(form);
        const storeData = { ...data };
        let googleRecapture = storeData[''];
        let { shop_name, shop_description } = storeData;
        storeData['account_id'] = account_id;
        // setErrorShopName(formValidation.GetShopNameValidate(`${shop_name}`))
        setErrorDescription(
            formValidation.GetShopDescriptionValidate(shop_description)
        );
        if (errorDescription?.status) {
            ClanShopService.createShop(storeData)
                .then((res: any) => {
                    if (res?.data?.status?.status) {
                        navigate(
                            `/influencer/clanshop/myshop/${res?.data?.store_id}/${shop_name}`
                        );
                    } else {
                    }
                })
                .catch((e: any) => setShopDetails([]));
        }
    };
    return (
        <div className='influencer-clan-shop'>
            <form onSubmit={handleCreateClanShop}>
                <div className='column'>
                    <div className='col-lg-12 mb-3'>
                        <TextField
                            onBlur={handleShopName}
                            size='small'
                            fullWidth
                            className='influencer_personal_info'
                            name='shop_name'
                            type='text'
                            id='outlined-basic'
                            label='Shop Name'
                            variant='outlined'
                        />
                        {uniqueName ? (
                            <span className='error'>
                                Shop name already exsist, choose unique name
                            </span>
                        ) : (
                            <span className='error'>
                                {errorShopName?.message}
                            </span>
                        )}
                    </div>
                    <div className='col-lg-12 mb-3'>
                        <TextField
                            size='small'
                            multiline
                            minRows={2}
                            maxRows={6}
                            fullWidth
                            className='influencer_personal_info'
                            name='shop_description'
                            type='text'
                            id='outlined-basic'
                            label='Description'
                            variant='outlined'
                        />
                        <span className='error'>
                            {errorDescription?.message}
                        </span>
                    </div>
                </div>
                <Button variant='outlined' type='submit'>
                    Create Clan Shop
                </Button>
            </form>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        UserID: state.UserID.userID,
    };
};

const connectToRedux = connect(mapStateToProps)(InfluencerClanShop);

export { connectToRedux as InfluencerClanShop };
