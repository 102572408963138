import React, { useEffect } from 'react';
import {
  TableComponent,
  SortingColumnsType,
} from "../../../../../../components/table-component/table";
import {
  TableCell,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdminDashboardService } from "../../../../../../core/services/admin-dashboard.service";
import { NavLink } from 'react-router-dom';
import '../platform.scss';
import { Loading } from "../../../../../../redux/action";
import { connect, useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import HeadingStyle from '../../../../../../components/heading-style/heading-style';

const columnDisplayName = {
  name: "Name",
  primary_email: "Email",
  primary_phone: "Phone",
  platform_type: "SM Type",
  username: "SM Name",
  token_disconnected_dt: "Disconnected at",
  remark: "Remark",
  remark_description: "Remark Description",
};

const sortingColumnType: SortingColumnsType[] = [
  {
    name: "name",
    columnSize: "180px",
  },
  {
    name: "primary_email",
    columnSize: "180px",
  },
  {
    name: "primary_phone",
    columnSize: "120px",
  },
  {
    name: "platform_type",
    columnSize: "120px",
  },
  {
    name: "username",
    columnSize: "150px",
  },
  {
    name: "token_disconnected_dt",
    columnSize: "180px",
  },
  {
    name: "remark",
    columnSize: "150px",
  },
  {
    name: "remark_description",
    columnSize: "250px",
  },
];

interface Props {
  loading: (data: any) => any;
  userData: any;
}

const adminDashboardService = new AdminDashboardService();

const DisconnectFeedbacks: React.FC<Props> = (props: Props) => {
  const [renderData, setRenderData] = useState<any>([]);
  const [disconnectData, setDisconnectData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    start_date: "",
    end_date: "",
  });
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    getDisconnectFilter(formData);
  }, []);


  async function getDisconnectFilter(formData: any) {
    try {
      props.loading(true);
      setLoading(true);
      const res = await adminDashboardService.getAdminDashDisconnectData(
        formData
      );
      if (res?.data?.status) {
        setDisconnectData(res?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching getDisconnectFilter:", error);
    } finally {
      props.loading(false);
      setLoading(false);
    }
  }

  async function handleSearch() {
    try {
      if (formData.start_date === "" && formData.end_date != "") {
        setDateError("Please enter start date");
        return;
      } else {
        if (formData.end_date === "" && formData.start_date != "") {
          setDateError("Please enter end date");
          return;
        } else {
          if (formData.start_date > formData.end_date) {
            setDateError("Start date should be less than end date");
            return;
          } else {
            setDateError("");
            setLoading(true);
            getDisconnectFilter(formData);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching handleSearch:", error);
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className='adminDashboardPlatformMostOutterDiv disconnect-feedback-main'>
      <HeadingStyle
        title='Admin Dashboard'
        className=' px-3 d-block'
      />
      <div className='col-auto nav sub-tabs mb-3'>
        <ul>
          <li className='tab-underline-menu'>
            <NavLink
              className='nav-link campaign-verification-tab'
              to={'/brand/admin-dashboard/campaign-details'}
            >
              Campaign Details
            </NavLink>
          </li>
          <li className='tab-underline-menu'>
            <NavLink
              className='nav-link talent-verification-tab'
              to={'/brand/admin-dashboard/disconnect-feedbacks'}
            >
              Disconnect feedbacks
            </NavLink>
          </li>
        </ul>
      </div>
      {dateError && <span className="error">{dateError}</span>}
      <div className="date_range_search">
        <Grid container spacing={2}>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["StartDate"]}>
                <MobileDatePicker
                  className="w-100"
                  label="start date"
                  format="DD-MM-YYYY"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.start_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      start_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["EndDate"]}>
                <MobileDatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  label="end date"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.end_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      end_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={2} md={2} xs={12} xl={4} className="btn-div">
            <LoadingButton
              className="btn btn-primary btn-sm"
              loading={loading}
              onClick={() => handleSearch()}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>
      </div>

      <div className="adminDashboardPlatformContentDivTable">
        <TableComponent
          pagination={true}
          columnDisplayName={columnDisplayName}
          inputDataSource={disconnectData}
          orderByColumnName=""
          sortingColumnType={sortingColumnType}
          renderData={renderData}
          setRenderData={(e) => setRenderData(e)}
        >
          {renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} hover role="checkbox">
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.primary_email}</TableCell>
                <TableCell>{data.primary_phone}</TableCell>
                <TableCell>{data.platform_type}</TableCell>
                <TableCell>{data.username}</TableCell>
                <TableCell>{data.token_disconnected_dt ? dayjs(data.token_disconnected_dt).format('LL') : ''}</TableCell>
                <TableCell>{data.remark}</TableCell>
                <TableCell>{data.remark_description}</TableCell>
              </TableRow>
            );
          })}
        </TableComponent>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisconnectFeedbacks);
export { connectToRedux as DisconnectFeedbacks };