import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';

const http = new HttpService();

export class CreateCampaign {

    static async createPlan(data: any) {
        const path = BASE_URL + '/brand/create/plan';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    static async updateCampaign(data: any) {
        const path = BASE_URL + '/brand/create/campaign';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async createProduct(data: any) {
        const path = BASE_URL + '/brand/create/product';
        try {

            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async influencerList(data: any) {
        const path = BASE_URL + '/brand/influencer/list';
        try {

            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async influencerTable(data: any) {
        const path = BASE_URL + '/brand/campaigns/list/influencers';
        try {

            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async create_influencer_all(data: any) {
        const path = BASE_URL + '/brand/campaign/influencer/all';
        try {

            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async create_influencer_list(data: any) {
        const path = BASE_URL + '/brand/campaign/influencer/list';
        try {

            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    async post_Campaign(data: any) {
        const path = BASE_URL + '/brand/campaign/post';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async edit_Campaign(data: any) {
        const path = BASE_URL + '/brand/campaign/edit';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    static async getDeliverables(campaignId: number) {
        const path = BASE_URL + `/brand/campaign/deliverables/${campaignId}`;
        try {
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

    // ---------------------------------------------------------------POST ANALYTICS CAMPAIGN--------------------------------------------------------------
    async createPostAnalyticsCampaign(data:any){
        const path = BASE_URL + '/brand/campaign/post-internal-campaign';
        try 
        {
            const response = await http.postRequest(path, data, null);
            return response;
        } 
        catch (error) {
            throw new Error(`Analytics Campaign Service :: ${error}`)
        }

    }

    static async get_influencer_type_ids(data: any) {
        const path = BASE_URL + '/brand/campaign/influencer-type/ids';
        try {

            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Campaign Service :: ${error}`)
        }
    };

}