import React, { useEffect, useState, useRef } from "react";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import jsPDF from "jspdf";
import "./influencers-agreements.scss";
import {
  Box,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import defaultImage from "../../../../assets/images/image-fill.svg";
import { Loading } from '../../../../redux/action';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import HeadingStyle from "../../../../components/heading-style/heading-style";
import { ImageCrop } from "../../../../components";
import { LocalService, PublicService } from "../../../../core/services";
import { FormValidation } from "../../../../core/utility";
import { AgreementService } from "../../../../core/services/influencer-agreement.services";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import CommonPopUp from '../../../../components/popup/common-popup';
import { createPortal } from 'react-dom';

const localService = new LocalService();
const formValidation = new FormValidation();
const agreementService = new AgreementService();

interface Props {
  UserReducer: any;
  loading: (data: boolean) => void;
}


interface Data {
  id: number;
  date: string;
  influencer_name: string;
  brand: string;
  campaign: string;
  status: number;
  action: string;
}

function createData(
  id: number,
  date: string,
  influencer_name: string,
  brand: string,
  campaign: string,
  status: number,
  action: string
): Data {
  return {
    id,
    date,
    influencer_name,
    brand,
    campaign,
    status,
    action,
  };
}

const rows = [
  createData(
    1,
    "1 Oct 2023",
    "Arunya Sood",
    "MG Motors",
    "MG Hector",
    67,
    "4.3"
  ),
  createData(
    2,
    "7 Nov 2023",
    "Ranvir Singh",
    "MG Motors",
    "MG Hector",
    67,
    "4.3"
  ),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  minWidth?: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    minWidth: 170,
  },
  {
    id: "influencer_name",
    numeric: true,
    disablePadding: false,
    label: "Influencer Name",
    minWidth: 170,
  },
  {
    id: "brand",
    numeric: true,
    disablePadding: false,
    label: "Brand",
    minWidth: 170,
  },
  {
    id: "campaign",
    numeric: true,
    disablePadding: false,
    label: "Campaign",
    minWidth: 170,
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    minWidth: 170,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    minWidth: 350,

  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className='common-table-header'>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const InfluencerAgreements: React.FC<Props> = (props: Props) => {
  const [doSearch, setDoSearch] = React.useState(false);
  const handleClickDoSearch = () => setDoSearch((search) => !search);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("date");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDialog, setOpenDialog] = React.useState(false);
  const path = "#";
  const [formData, setFormData] = useState({
    campaign_image_url: "",
    campaign_image_path: "",
  });

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [agreementSection, setAgreementSection] = useState(true)

  const [file, setFile] = useState<any>();
  const [chooseFile, setChooseFile] = useState<File | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const getTargetElement = () => document.getElementById('agreement');
  const ref = useRef();




  const options = {
    // default is `save`
    method: "build" as const,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'portrait' as const,
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg' as const,
      qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };




  /*.................................Send Agreement ..............................*/

  const today = new Date().toLocaleDateString('en-IN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  // Agreement form Handle
  const [agreementDetails, setAgreementDetails] = useState<any>({
    agreement_date: new Date().toISOString().slice(0, 10),
    influencer_name: "",
    email: "",
    brand_name: "",
    campaign_name: "",
    company_trade_name: "",
    camp_start_date: '',
    camp_end_date: '',
    deliverables: "",
    amount: 0,
    advance: "",
    agreement_url: "",
    agreement_path: "",
    inf_name: "",
    inf_date: "",
    inf_place: "",
    owner_name: "",
    owner_date: '',
    comment: ""
  });

  const [errors, setErrors] = useState<any>({
    influencer_name: { status: true, message: "" },
    email: { status: true, message: "" },
    brand_name: { status: true, message: "" },
    campaign_name: { status: true, message: "" },
    deliverables: { status: true, message: "" },
    company_trade_name: { status: true, message: "" },
    camp_start_date: { status: true, message: '' },
    camp_end_date: { status: true, message: '' },
    amount: { status: true, message: "" },
  });

  //Function to save the agreement details
  // async function saveAgreementDetails() {
  //   setLoading(true);
  //   try {
  //     let isValid = true;
  //     const updatedErrors: any = { ...errors };

  //     // let fieldsToValidate = ['influencer_name', 'email', 'brand_name', 'deliverables', 'amount'];
  //     const fieldsToValidate = ['influencer_name', 'email'];

  //     if (!showChooseFile) {
  //       fieldsToValidate.push('brand_name', 'deliverables', 'amount');
  //     }

  //     fieldsToValidate.forEach((key) => {
  //       if (!agreementDetails[key]) {
  //         isValid = false;
  //         if (!updatedErrors[key]) updatedErrors[key] = {};
  //         updatedErrors[key].status = false;
  //         updatedErrors[key].message = "This field is required";
  //       } else {
  //         updatedErrors[key].status = true;
  //         updatedErrors[key].message = "";
  //       }
  //     });

  //     setErrors(updatedErrors);

  //     if (isValid) {
  //       const response = await agreementService.influencerAgreement(agreementDetails);
  //       const toastType = response.data.status?.status ? "success" : "error";
  //       localService.toastify(response.data.status?.message, toastType, 1000);
  //       if (toastType === "success") {
  //         setActive(0);
  //         setAgreementDetails({
  //           agreement_date: new Date().toISOString().slice(0, 10),
  //           influencer_name: "",
  //           email: "",
  //           brand_name: "",
  //           campaign_name: "",
  //           camp_start_date: '',
  //           camp_end_date: '',
  //           deliverables: "",
  //           advance: "",
  //           amount: 0,
  //           agreement_url: "",
  //           agreement_path: "",
  //           comment: ""
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     throw new Error(`saveAgreementDetail error :: ==> ${error}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // }



  async function saveAgreementDetails() {

    setLoading(true);
    try {
      let isValid = true;
      const updatedErrors = { ...errors };

      const fieldsToValidate = ['influencer_name', 'email'];
      // if (chooseFile === null) {
      //   fieldsToValidate.push('brand_name', 'company_trade_name', 'deliverables', 'amount');
      // }
      if (chooseFile === null) {
        const fieldsToValidate = ['company_trade_name', 'deliverables', 'amount'];

        if (agreementDetails.brand_name || !props.UserReducer.brand_name) {
          fieldsToValidate.push('brand_name');
        }

        if (agreementDetails.company_trade_name || !props.UserReducer.company_trade_name) {
          fieldsToValidate.push('company_trade_name');
        }
      }

      fieldsToValidate.forEach((key) => {
        if (!agreementDetails[key]) {
          isValid = false;
          if (!updatedErrors[key]) updatedErrors[key] = {};
          updatedErrors[key].status = false;
          updatedErrors[key].message = "This field is required";
        } else {
          updatedErrors[key].status = true;
          updatedErrors[key].message = "";
        }
      });


      setErrors(updatedErrors);

      if (isValid) {
        const formData = new FormData();

        if (chooseFile) {
          formData.append('file', chooseFile);
          formData.append('path', `brand/${props.UserReducer.brand_id}/agreement`);
        } else {

          // if (getTargetElement) {
          //   try {

          //     const pdfInstance = await generatePDF(getTargetElement, options);
          //     const blob = pdfInstance.output('blob');
          //     const generatedFile = new File([blob], 'filename.pdf', { type: 'application/pdf' });
          //     console.log(generatedFile, "generatedFile")
          //     window.open(URL.createObjectURL(blob), '_blank');
          //     setChooseFile(generatedFile);
          //     formData.append('file', generatedFile);
          //     formData.append('path', `brand/${props.UserReducer.brand_id}/agreement`);
          //   } catch (error) {
          //     console.log('Error generating PDF:', error);
          //     throw new Error('Error generating PDF.');
          //   }

          // } else {
          //   throw new Error('PDF component reference is undefined.');
          // }

          try {
            const targetElement = getTargetElement();
            if (targetElement) {
              const className = targetElement ? 'modified-agreement' : 'agreement';
              if (targetRef.current) {
                targetRef.current.className = `${className} mt-2 overflow-auto`;
                const pdfInstance = await generatePDF(getTargetElement, options);
                const blob = pdfInstance.output('blob');
                const generatedFile = new File([blob], 'agreement.pdf', { type: 'application/pdf' });
                // window.open(URL.createObjectURL(blob), '_blank');
                setChooseFile(generatedFile);
                formData.append('file', generatedFile);
                formData.append('path', `brand/${props.UserReducer.brand_id}/agreement`);
              } else {
                console.error("targetRef.current is null");
              }
            } else {
              console.error("Target element is null");
            }
          } catch (error) {
            console.log('Error generating PDF:', error);
            throw new Error('Error generating PDF.');
          }


        }
        formData.append('agreement_date', agreementDetails.agreement_date);
        formData.append('influencer_name', agreementDetails.influencer_name);
        formData.append('email', agreementDetails.email);
        formData.append('brand_name', agreementDetails.brand_name);
        formData.append('campaign_name', agreementDetails.campaign_name);
        formData.append('company_trade_name', agreementDetails.company_trade_name);
        formData.append('camp_start_date', agreementDetails.camp_start_date);
        formData.append('camp_end_date', agreementDetails.camp_end_date);
        formData.append('deliverables', agreementDetails.deliverables);
        formData.append('amount', agreementDetails.amount);
        formData.append('advance', agreementDetails.advance);
        formData.append('agreement_url', agreementDetails.agreement_url);
        formData.append('agreement_path', agreementDetails.agreement_path);
        formData.append('inf_name', agreementDetails.inf_name);
        formData.append('inf_date', agreementDetails.inf_date);
        formData.append('inf_place', agreementDetails.inf_place);
        formData.append('owner_name', agreementDetails.owner_name);
        formData.append('owner_date', agreementDetails.owner_date);
        formData.append('comment', agreementDetails.comment);


        const response = await agreementService.influencerAgreement(formData);
        const toastType = response.data.status.status ? "success" : "error";
        localService.toastify(response.data.status?.message, toastType, 1000);
        if (toastType === "success") {
          setActive(0);
          setAgreementDetails({
            agreement_date: new Date().toISOString().slice(0, 10),
            influencer_name: "",
            email: "",
            brand_name: "",
            campaign_name: "",
            camp_start_date: '',
            camp_end_date: '',
            deliverables: "",
            advance: "",
            amount: 0,
            agreement_url: "",
            agreement_path: "",
            comment: ""
          });
          setOpenDialog(false);
        }
      }
    } catch (error) {
      throw new Error(`saveAgreementDetail error :: ==> ${error}`);
    } finally {
      setLoading(false);
      setChooseFile(null);
    }
  }


  //Handling Influencer name in two filed at same time
  const handleInfluencerNameChange = (event: any) => {
    const newName = event.target.value;
    const validation = formValidation.GetNameValidate2(newName);
    setErrors({
      ...errors,
      influencer_name: validation,
    });
    setAgreementDetails({
      ...agreementDetails,
      influencer_name: newName,
    });
  };


  const handleAmountChange = (e: any) => {
    try {
      const amountInput = e.target.value;

      if (!isNaN(amountInput) && amountInput !== "") {
        const amount = parseFloat(amountInput);
        const gstRate = 0.18;
        const gstAmount = amount * gstRate;
        const totalAmount = amount + gstAmount;

        // Check if the amount is valid
        if (formValidation.IsNumber(amount)) {
          setAgreementDetails({
            ...agreementDetails,
            amount: amount.toFixed(2), // Round to 2 decimal places
            gstAmount: gstAmount.toFixed(2), // Round to 2 decimal places
            totalAmount: totalAmount.toFixed(2) // Round to 2 decimal places
          });
          setErrors({ ...errors, amount: { status: true, message: "" } });
        } else {
          setErrors({ ...errors, amount: { status: false, message: "Invalid amount" } });
        }
      } else {
        setErrors({ ...errors, amount: { status: false, message: "Amount must be a number" } });
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };



  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAgreementSection(true)
  };


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  const handleChooseFileChange = (e: any) => {
    const selectedChooseFile = e.target.files[0];
    if (selectedChooseFile) {
      setChooseFile(selectedChooseFile);
    }
  };

  const handleRemoveChooseFile = () => {
    setChooseFile(null);
  };

  const handleClosePreviewImage = () => {
    setFile("")

  }
  function handleCloseModal() {
    setOpenModal(false);
    setFile("")

  }

  return (
    <div className='container'>
      <div className="influencer-agreement-main-section">
        <div className="agreement-heading my-3">
          <HeadingStyle title="Agreement" />
        </div>

        <div className=" row mb-3">
          <div className="col-lg col-md col-sm-12 search-div mb-2 mb-md-0">
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              className="agreement-search p-0"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Search and filter
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                type={doSearch ? "text" : "search"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle search"
                      onClick={handleClickDoSearch}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {doSearch ? <SearchIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Search and Filter"
              />
            </FormControl>
          </div>

          <div className="col-lg-auto col-md-auto col-sm-6">
            <button
              className="btn btn-outline-primary btn-mui agreement-button "
              onClick={handleOpenDialog}
            >
              Send Agreement
            </button>
          </div>
        </div>

        <div className="agreement-table">

          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer className='common-table'>
            <Table sx={{ minWidth: 750 }}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody className='common-table-body'>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="td"
                        id={labelId}
                        scope="row"

                        style={{ minWidth: '125px' }}
                      >
                        {row.date}

                      </TableCell>
                      <TableCell

                        style={{ minWidth: '200px' }}
                      >
                        {row.influencer_name}
                      </TableCell>
                      <TableCell

                        style={{ minWidth: '150px' }}
                      >
                        {row.brand}
                      </TableCell>
                      <TableCell

                        style={{ minWidth: '150px' }}
                      >
                        {row.campaign}
                      </TableCell>
                      <TableCell

                      >
                        <button className="btn btn-outline-primary btn-sm">
                          sent
                        </button>
                      </TableCell>
                      <TableCell
                        style={{ minWidth: '350px' }}

                        className=""
                      >
                        <div className='d-flex' style={{ gap: '8px' }}>
                          <Tooltip title="View" arrow><span className="material-symbols-rounded">
                            visibility
                          </span></Tooltip>

                          <Tooltip title="Duplicate" arrow>
                            <span className="material-symbols-rounded">
                              content_copy
                            </span>
                          </Tooltip>

                          <Tooltip title="Send Reminder" arrow><span className="material-symbols-rounded">
                            notifications_active
                          </span></Tooltip>

                          <Tooltip title="Delete" arrow><span className="material-symbols-rounded">
                            delete
                          </span></Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination className='common-pagination'
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "#00aae5",
            }}
          />

        </div>
        <CommonPopUp
          modalTitle="Send Agreement"
          // modalContent={modalContent}
          openModal={openDialog}
          onClose={handleCloseDialog}
          popupMinWidth='630px'
          className="modal-xlarge popup-agreement agreement-section"
        >
          <div className='common-popup-body'>
            <div className="agreement-section">
              <div className="agreement-format">
                <div className="agreement-title">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={12} lg={6} display="flex" gap={7}>
                      <TextField
                        fullWidth
                        id="InfluencerName1"
                        variant="outlined"
                        label='Name'
                        size="small"
                        style={{ marginLeft: "5px" }}
                        name="influencer_name"
                        helperText={errors.influencer_name.message}
                        error={!errors.influencer_name.status}
                        value={agreementDetails.influencer_name}
                        onChange={handleInfluencerNameChange}
                        onBlur={() => {
                          setErrors({
                            ...errors,
                            influencer_name: formValidation.GetNameValidate2(
                              agreementDetails.influencer_name
                            ),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={16} sm={12} lg={6} display="flex" gap={7}>
                      <TextField
                        fullWidth
                        id="fullWidth"
                        variant="outlined"
                        label='Email'
                        size="small"
                        style={{ marginLeft: "5px" }}
                        name="email"
                        helperText={errors.email.message}
                        error={!errors.email.status}
                        onChange={(e) =>
                          setAgreementDetails({
                            ...agreementDetails,
                            email: e.target.value,
                          })
                        }
                        onBlur={() => {
                          setErrors({
                            ...errors,
                            email: formValidation.GetEmailControlIsValid(
                              agreementDetails.email
                            ),
                          });
                        }}
                      />
                    </Grid>
                    {agreementSection && (
                      <Grid item xs={12} md={12} sm={12} lg={12} >
                        <Grid gap={2}>
                          <span>Agreement</span>
                          <div ref={targetRef} className="agreement mt-2 overflow-auto" id="agreement">
                            {/* <div ref={targetRef} className={`${getTargetElement() ? 'modified-agreement' : 'agreement'} mt-2 overflow-auto `} id="agreement"> */}

                            <div className="terms-conditions p-5">
                              <h3 className="text-center">INFLUENCER TERMS AGREEMENT</h3>
                              <p>{today}</p>
                              <ol>
                                <li><strong>Event:</strong> I,
                                  <TextField
                                    id="InfluencerName2"
                                    className="modifiedField"
                                    label=""
                                    defaultValue=""
                                    variant="standard"
                                    size="small"
                                    style={{ marginLeft: "5px" }}
                                    name="influencer_name"
                                    helperText={errors.influencer_name.message}
                                    error={!errors.influencer_name.status}
                                    value={agreementDetails.influencer_name}
                                    onChange={handleInfluencerNameChange}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    onBlur={() => {
                                      setErrors({
                                        ...errors,
                                        influencer_name: formValidation.GetNameValidate(
                                          agreementDetails.influencer_name
                                        ),
                                      });
                                    }}
                                  />
                                  <strong>(“Influencer”)</strong>, and have been engaged by the Company to provide services on the terms and conditions contained herein this Agreement. I as an Influencer management company, will provide the following services on behalf of

                                  <TextField
                                    id="standard-error"
                                    className="modifiedField"
                                    label=""
                                    defaultValue={props.UserReducer.brand_name}
                                    variant="standard"
                                    size="small"
                                    style={{ marginLeft: "5px" }}
                                    name="brand_name"
                                    helperText={errors.brand_name.message}
                                    error={!errors.brand_name.status}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        brand_name: e.target.value ? e.target.value : props.UserReducer.brand_name,
                                      })
                                    }
                                    onBlur={() => {
                                      if (!agreementDetails.brand_name && props.UserReducer.brand_name) {
                                        return; // Skip validation if agreementDetails.brand_name is empty and props.UserReducer.brand_name has a value
                                      }
                                      setErrors({
                                        ...errors,
                                        brand_name: formValidation.GetNameValidate2(
                                          agreementDetails.brand_name
                                        ),
                                      });
                                    }}
                                  />
                                  <strong>("Brand")</strong> for the campaign
                                  <TextField
                                    id="standard-error"
                                    className="modifiedField"
                                    label=""
                                    defaultValue=""
                                    variant="standard"
                                    size="small"
                                    style={{ marginLeft: "5px" }}
                                    name="campaign_name"
                                    helperText={errors.campaign_name.message}
                                    error={!errors.campaign_name.status}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        campaign_name: e.target.value,
                                      })
                                    }
                                    onBlur={() => {
                                      setErrors({
                                        ...errors,
                                        campaign_name: formValidation.GetNameValidate(
                                          agreementDetails.campaign_name
                                        ),
                                      });
                                    }}
                                  />
                                  to <TextField
                                    id="standard-error"
                                    className="modifiedField"
                                    label=""
                                    defaultValue={props.UserReducer.company_trade_name}
                                    variant="standard"
                                    size="small"
                                    style={{ marginLeft: "5px" }}
                                    name="company_trade_name"
                                    helperText={errors.company_trade_name.message}
                                    error={!errors.company_trade_name.status}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        company_trade_name: e.target.value ? e.target.value : props.UserReducer.company_trade_name,
                                      })
                                    }
                                    onBlur={() => {
                                      if (!agreementDetails.company_trade_name && props.UserReducer.company_trade_name) {
                                        return;
                                      }
                                      setErrors({
                                        ...errors,
                                        company_trade_name: formValidation.GetNameValidate2(
                                          agreementDetails.company_trade_name
                                        ),
                                      });
                                    }}
                                  />  <strong>(“Company”)</strong>. This campaign will commence on
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                      className=' input-style-date border-btm-only'
                                      format='DD-MM-YYYY'
                                      defaultValue={'DD-MM-YYYY'}
                                      onChange={(e: any) => setAgreementDetails((prev: any) => ({ ...prev, camp_start_date: e?.format('YYYY-MM-DD') }))}
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          // helperText: "DD/MM/YYYY",
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>

                                  <strong>(“Event Start)</strong>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}                          >
                                    <MobileDatePicker
                                      className='input-style-date border-btm-only'
                                      format='DD-MM-YYYY'
                                      // value={agreementDetails.camp_end_date}
                                      defaultValue={'DD-MM-YYYY'}
                                      onChange={(date) => {
                                        setAgreementDetails({ ...agreementDetails, camp_end_date: date ? dayjs(date).format('YYYY-MM-DD') : null });
                                      }}
                                    />
                                  </LocalizationProvider>
                                  <strong>(“Event End)</strong>
                                  .
                                </li>

                                <p></p>
                                <li>
                                  <strong>Services:</strong>
                                  <p></p>
                                  <ul className="agreement-services">
                                    <li>a) <span></span> <strong>Content:</strong> The total number of social posts to be provided by me and the timeframe within which the same is to be provided will be specified by the Brand from time to time depending on the requirements of Event for which I have been engaged.</li>
                                    <li>b) <span></span> My deliverables for this campaign and tentative timelines are mentioned below in clause 2(c) of this agreement. I will forward the content/creative/copy to the Company for approval minimum 48 hours before the activity start date. Social media posts should follow defined event terms and conditions and should bear reference content, URL, #hashtags as provided by the Company.</li>
                                    <li>c) <span></span> <strong>Requirements:</strong>
                                      <p></p>
                                      <div className="deliverables">
                                        <p><strong>Deliverables:</strong></p>
                                        <ol>
                                          <li><strong className='d-block pb-2'>Contents:</strong>

                                            <TextField
                                              id="outlined-multiline-flexible"
                                              className="mb-2"
                                              multiline
                                              rows={6}
                                              fullWidth
                                              style={{ marginLeft: "5px" }}
                                              name="deliverables"
                                              helperText={errors.deliverables.message}
                                              error={!errors.deliverables.status}
                                              onChange={(e) =>
                                                setAgreementDetails({
                                                  ...agreementDetails,
                                                  deliverables: e.target.value,
                                                })
                                              }
                                              onBlur={() => {
                                                setErrors({
                                                  ...errors,
                                                  deliverables: formValidation.GetNameValidate(
                                                    agreementDetails.deliverables
                                                  ),
                                                });
                                              }}
                                            /></li>
                                          <li><strong>Event Date</strong>:{agreementDetails.camp_start_date ? dayjs(agreementDetails.camp_start_date).format('DD-MMMM-YYYY') : null}</li>
                                        </ol>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <p></p>
                                <ul>
                                  <li> a)  <span></span> <strong>Contact:</strong> I will remain in contact with Company, respond to emails in a timely manner, and address any concerns within 48 hours.</li>
                                  <li>b) <span></span><strong>Compliance:</strong> I will comply with all applicable law(s), including the addition of #sponsored or #ad on my social media posts.</li>
                                </ul>
                                <p></p>
                                <li>
                                  <strong>Fees, Taxes, Termination:</strong> Company will pay me INR <TextField
                                    className="gst-amount modifiedField"
                                    id="standard-error"
                                    label=""
                                    placeholder="Amount"
                                    variant="standard"
                                    size="small"
                                    style={{ marginLeft: "5px" }}
                                    name="amount"
                                    helperText={errors.amount.message}
                                    error={!errors.amount.status}
                                    onChange={handleAmountChange}
                                  />
                                  {/* <span>Actual Amount: Rs-{agreementDetails.amount}</span>
                          <span>GST Amount: Rs-{agreementDetails.gstAmount}</span> */}
                                  <span>+ Taxes <strong>(GST @ 18%)</strong>, Total Amount <strong> Rs - {agreementDetails.totalAmount ? agreementDetails.totalAmount : '0'}</strong></span>
                                  <span className={` ${agreementDetails.advance ? 'has-input' : 'modifiedField'}`}
                                  >(</span>
                                  <TextField
                                    id="standard-error"
                                    className={` ${agreementDetails.advance ? 'has-input' : 'modifiedField'}`}
                                    label=""
                                    placeholder="Advance (If Any)"
                                    variant="standard"
                                    size="small"
                                    style={{ marginLeft: "5px" }}
                                    name="Advance"
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        advance: e.target.value,
                                      })
                                    }
                                  />
                                  <span className={` ${agreementDetails.advance ? 'has-input' : 'modifiedField'}`}
                                  >)</span>through bank transfer when all event requirements and due approval of the event from the Brand has been obtained by the Company in writing. I am responsible for all applicable taxes payable with respect to the said event. If I do not meet the obligations outlined herein, Company will withhold remaining payments payable to me regarding the event. The Company reserves the right to cancel this Agreement, and that if this occurs, my obligations cease immediately.
                                </li>
                                <p></p>
                                <li>
                                  <strong>Acknowledgments:</strong> I agree that:
                                  <ol>
                                    <li>Company may monitor my social media accounts for compliance and reporting purposes;</li>
                                    <li>Company may use my name, likeness, and social profile links and/or handles in event-related correspondence and post-event case studies;</li>
                                    <li>All information related to the services I provide or any information, materials or content are confidential information and I agree to protect and not disclose it to any third party;</li>
                                    <li>Company owns and retains all rights to any information, materials or intellectual property owned by them;</li>
                                    <li>I will personally perform all services required by the Company;</li>
                                    <li>All my content will be my original work and I will not infringe any third party's rights when providing these services;</li>
                                    <li>I grant Company a perpetual, transferrable and royalty-free right and license to use any content I create in providing the services for any reasonable purpose limited to use on its social media;</li>
                                    <li>No employment or similar relationship exists between me and the Company;</li>
                                    <li>I agree to, defend, indemnify and hold harmless Company, and their respective directors, officers, attorneys, agents, employees, service providers and assigns (collectively, <strong>"Protected Parties"</strong> ) from and against all claims, liabilities, suits, losses, damages and expenses, including costs and reasonable attorney’s fees, relating to or resulting from (a) my breach of or failure to perform under this Agreement, (b) any violation of applicable law or on any matter related to taxes or fees, or (c) any claims that my work violates any rights of any third party;</li>
                                    <p></p>
                                    <li>In no event shall the Company nor any Protected Party be liable for any indirect, punitive, consequential or other similar damages hereunder and Company and any Protected Party's total cumulative liability under this Agreement shall not exceed the fees paid to me hereunder;</li>
                                    <li>I, hereby, agree that the delivered content(s) would be aligned with shared brand guidelines at all times during the term of this agreement and posted following the arranged scheduled/Timeline.</li>
                                  </ol>
                                </li>
                                <p></p>
                                <li><strong>Dispute:</strong> This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of India and the courts of New Delhi have exclusive jurisdiction to deal with such matters.</li>
                              </ol>
                              <p>I hereby agree to the terms of this Agreement with the intent to be legally bound.</p>
                              <p></p>
                              <p></p>
                              <wbr></wbr>
                              <p><mark>For and on behalf of Influencer/Agency :</mark></p>
                              <p><strong>SIGNED:</strong></p>
                              <div className="d-flex">
                                <p>NAME:</p>
                                <TextField
                                  id="standard-error"
                                  className="modifiedField"
                                  label=""
                                  defaultValue=""
                                  variant="standard"
                                  size="small"
                                  style={{ marginLeft: "5px" }}
                                  name="inf_name"

                                  onChange={(e) =>
                                    setAgreementDetails({
                                      ...agreementDetails,
                                      inf_name: e.target.value,
                                    })
                                  }

                                /></div>

                              <div className="d-flex">
                                <p>DATE:</p>
                                <TextField
                                  id="standard-error"
                                  className="modifiedField"
                                  label=""
                                  defaultValue=""
                                  variant="standard"
                                  size="small"
                                  style={{ marginLeft: "5px" }}
                                  name="inf_date"
                                  helperText={errors.campaign_name.message}
                                  error={!errors.campaign_name.status}
                                  onChange={(e) =>
                                    setAgreementDetails({
                                      ...agreementDetails,
                                      campaign_name: e.target.value,
                                    })
                                  }

                                /></div>
                              <div className="d-flex">
                                <p>PLACE:</p>
                                <TextField
                                  id="standard-error"
                                  className="modifiedField"
                                  label=""
                                  defaultValue=""
                                  variant="standard"
                                  size="small"
                                  style={{ marginLeft: "5px" }}
                                  name="inf_place"

                                  onChange={(e) =>
                                    setAgreementDetails({
                                      ...agreementDetails,
                                      inf_place: e.target.value,
                                    })
                                  }

                                /></div>
                              <wbr></wbr>
                              <p></p>
                              <p><mark>For and on behalf of {agreementDetails.company_trade_name || props.UserReducer.company_trade_name} :</mark></p>
                              <p><strong>SIGNED:</strong></p>
                              <div className="d-flex">
                                <p>NAME:</p>
                                <TextField
                                  id="standard-error"
                                  className="modifiedField"
                                  label=""
                                  variant="standard"
                                  size="small"
                                  style={{ marginLeft: "10px" }}
                                  name="owner_name"

                                  onChange={(e) =>
                                    setAgreementDetails({
                                      ...agreementDetails,
                                      owner_name: e.target.value,
                                    })
                                  }

                                /></div>
                              <div className="d-flex ">
                                <p >DATE:</p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <MobileDatePicker
                                    className=' input-style-date border-btm-only'
                                    format='DD-MM-YYYY'
                                    defaultValue={'DD-MM-YYYY'}
                                    onChange={(e: any) => setAgreementDetails((prev: any) => ({ ...prev, owner_date: e?.format('YYYY-MM-DD') }))}
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        // helperText: "DD/MM/YYYY",
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>

                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      display="flex"
                      flexDirection="column"
                    >
                      <span
                        className="my-2"
                        style={{
                          marginRight: "auto",
                          marginLeft: "auto",
                          // paddingLeft: "110px",
                        }}
                      >
                        <strong>OR</strong>
                      </span>
                      {/* <div>
                  <button onClick={() => generatePDF(getTargetElement, options)}>Generate PDF</button>
                </div> */}
                      <div className="choosefile mx-auto">
                        {!chooseFile && <button type='button' className='btn btn-outline-primary' onClick={() => setOpenModal(true)}>Upload</button>
                        }

                        {chooseFile && (
                          <div className='creative-image-preview-sec'>
                            <span className='material-symbols-rounded icon-filled close-preview' onClick={handleRemoveChooseFile}>cancel</span>
                            <div className='d-flex flex-column'>
                              <span className='material-symbols-rounded text-center mb-2' style={{ fontSize: '60px' }}>picture_as_pdf</span>
                              <p>{chooseFile && chooseFile.name}</p>
                            </div>
                          </div>
                        )}
                      </div>
                      {createPortal(<CommonPopUp
                        modalTitle="Upload"
                        modalContent=""
                        openModal={openModal}
                        onClose={() => handleCloseModal()}
                        className="modal-medium mx-auto"
                      >
                        <div className='upload-doc-sec'>
                          <>
                            {chooseFile &&
                              <div className='creative-image-preview-sec'>

                                <span className='material-symbols-rounded icon-filled close-preview' onClick={handleRemoveChooseFile}>cancel</span>
                                <div className='d-flex flex-column'>
                                  <span className='material-symbols-rounded text-center mb-2' style={{ fontSize: '60px' }}>picture_as_pdf</span>
                                  {chooseFile && chooseFile.name}
                                </div>
                              </div>
                            }
                            {!chooseFile &&
                              <div className="upload-btn">
                                <input type="file" accept="application/pdf" onChange={handleChooseFileChange} />
                                <span className='material-symbols-rounded'>
                                  upload
                                </span> <strong>Upload</strong>
                              </div>
                            }
                            <span className='fs-14 pb-5'>accepts: .pdf</span>
                            <LoadingButton
                              className='upload-file-btn btn btn-primary'
                              loading={loading}
                              loadingPosition='start'
                              // disabled={disableSubmitButton}
                              onClick={() => {
                                // setShowChooseFile(true);
                                setOpenModal(false)
                                setAgreementSection(false);
                              }}
                            >
                              Send
                            </LoadingButton>
                          </>
                        </div>
                      </CommonPopUp>, document.body)}



                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} gap={3}>
                      <span>Comments</span>
                      <TextField
                        className="mt-2"
                        id="outlined-multiline-flexible"
                        variant="outlined"
                        label="Comments"
                        multiline
                        rows={2}
                        fullWidth
                        name="comment"
                        onChange={(e) =>
                          setAgreementDetails({
                            ...agreementDetails,
                            comment: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    {/* <Grid
                item
                xs={12}
                md={12}
                sm={12}
                lg={12}
                marginLeft="auto"
                display="flex"
                justifyContent="flex-end"
                marginRight="-18px"
              >

                <LoadingButton
                  className="btn btn-primary"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  onClick={() => {
                    setOpenDialog(false)
                    setAgreementSection(true)
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  className="btn btn-primary"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  onClick={saveAgreementDetails}
                  style={{ marginRight: "19px" }}
                >
                  Send
                </LoadingButton>
              </Grid> */}

                  </Grid>

                </div>
              </div>
            </div>
            <div className='popup-footer text-end'>
              <LoadingButton
                className="btn btn-outline-primary"
                loading={loading}
                loadingPosition="start"
                onClick={() => {
                  setOpenDialog(false)
                  setAgreementSection(true)
                }}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                className="btn btn-primary"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                onClick={saveAgreementDetails}
              >
                Send
              </LoadingButton>
            </div>
          </div>
        </CommonPopUp>

      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerAgreements);

export { connectToRedux as InfluencerAgreements };
