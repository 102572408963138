import React, { useState } from "react";
import CommonPopUp from "../../../components/popup/common-popup";
import CommonPopUpCentered from "../../../components/popup/common-popup-centered";
import { Link, matchPath, Navigate, Outlet, useLocation } from "react-router-dom";

export const InfluencerTools: React.FC = () => {
  const [openConnectInstagram, setOpenConnectInstagram] = useState(false)
  const [openAdditionalSettingsModal, setOpenAdditionalSettingsModal] = useState(false)


  const handleCloseConnectInstagramModal = () => {
    setOpenConnectInstagram(false);
  }

  const handleOpenConnectInstagramModal = () => setOpenConnectInstagram(true);

    const handleCloseAdditionalSettingsModal = () => {
    setOpenAdditionalSettingsModal(false);
  }

  const handleOpenAdditionalSettingsModal = () => setOpenAdditionalSettingsModal(true);

  const location = useLocation();

  // Check if we are not on the new-automation path
  const isExactToolsPath = matchPath('/influencer/tools', location.pathname);
  const handleCloseModal = () =>{
    setOpenAdditionalSettingsModal(false);
    setOpenConnectInstagram(false);
  }
  return (
    <>
      {isExactToolsPath && (
    <><section className="inf-tools">
        <button className="btn btn-purple" onClick={handleOpenConnectInstagramModal}>Instagram DM Automation</button>
      <button className="btn btn-disabled">Hashtag generator</button>
      <button className="btn btn-disabled">Generate caption</button>
    </section>
      <Link to={'/influencer/tools/new-automation'}>New Automation</Link>
      <Link to={'/influencer/tools/available-automation'}>Available Automation</Link>
        </>)}
      <Outlet />
      <CommonPopUpCentered
      centered
              className={`popup-instagram-automation modal-small  mx-auto`}
              openModal={openConnectInstagram}
          modalTitle="Connect Instagram"
          modalContent=""
              onClose={() => handleCloseConnectInstagramModal()}>
                <div className="popup-content">
                  <p>Automation requires a connected account. Connect your Instagram account and set your permissions to use the feature.</p>
                  <button className="btn btn-primary" onClick={handleOpenAdditionalSettingsModal}>Connect</button>
                </div>
        </CommonPopUpCentered>
      <CommonPopUpCentered
        centered
        className={`popup-instagram-automation modal-medium  mx-auto`}
        openModal={openAdditionalSettingsModal}
        modalTitle="Important Instagram Setting"
        modalContent=""
        onClose={() => handleCloseAdditionalSettingsModal()}>
        <div className="popup-content">
          <p className="text-left">It looks like there's one key setting missing from your Instagram account. To enable Automation, please grant access to Instagram Partner messages.</p>
          <div className="text-left">
            <strong>Here’s how to do it:</strong>
            <ol className="">
              <li>Open your Instagram mobile app.</li>
              <li>{`Go to Menu > Settings and activity > Messages and story replies > Message Controls > Connected Tools.`}</li>
              <li>
                {`Toggle ON ‘Allow access to Messages’.`}</li>
            </ol>
          </div>
          <Link onClick={handleCloseModal} to="/influencer/tools/new-automation" className="btn btn-primary">It’s Done!</Link>
        </div>
      </CommonPopUpCentered>
 </>

  );
};
