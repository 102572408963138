import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ClanShopService } from "../../../core/services/clan.shop.service";
import HeadingStyle from "../../../components/heading-style/heading-style";
import { Button, Tooltip } from "@mui/material";
import { Close, FileUpload, HighlightOff } from "@mui/icons-material";
import { ImageCrop } from "../../../components";
import { connect } from "react-redux";
import { FormValidation } from "../../../core/utility";
import { AffiliateService } from "../../../core/services/affiliate.service";
import { localeData } from "moment";
import { LocalService } from "../../../core/services";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { BASE_URL_UI } from "../../../config/config";

const localService = new LocalService()
type Props = {
  UserReducer: any;
  cartReducer: any;
};
interface ErrorTypes {
  status: boolean;
  message: string;
}

const ProductDetailsInfl: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const { params } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shop_id = searchParams.get("shop_id");
  const product_id = searchParams.get("product_id");
  const [productData, setProductData] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  const [cct_id, setcct_id] = useState(0);

  const path = `accounts/${props.UserReducer.account_id}/inf_product_img/products/${product_id}`;
  const [productImageUrl, setProductImageUrl] = useState<any>("");
  const [multipleProductImageUrl, setMultipleProductImageUrl] = useState<any>(
    []
  );

  const [reelURL, setReelURL] = useState<string>();
  const [productImagePath, setProductImagePath] = useState<any>("");
  useEffect(() => {

    ClanShopService.getProductDetail(product_id)
      .then((res) => {
        setProductData(res?.data?.rows);
      })
      .catch((e) => setProductData([]));
    AffiliateService.checkAffiliateLink(props.UserReducer.account_id, props.UserReducer.user_id, shop_id, product_id).then((res: any) => {
      if (res?.data?.status?.status || Object.keys(res?.data?.rows).length === 0) {
        setShowLink(false);
      } else {
        setDisable(true);
        setShowLink(true);
        setcct_id(res.data.rows[0].cct_id)
      }
    }).catch((err: any) => { })
  }, []);
  function calculateRemainingPrice(
    originalPrice: any,
    discountPercentage: any
  ) {
    const remainingPercentage = 100 - discountPercentage;
    const remainingDecimal = remainingPercentage / 100;
    const remainingPrice = originalPrice * remainingDecimal;
    return remainingPrice;
  }
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [reelLink, setReelLink] = useState<{ id: string; url: string }[]>([]);
  const [errorReelURL, setErrorReelURL] = useState<ErrorTypes>();
  const isValidURL = (urlString: string) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
  const handleReelURLChange = (e: any) => {
    const newUrl = e.target.value;
    setReelURL(newUrl);

    // Validate the URL
    if (newUrl === "") {
      // URL is blank
      setIsValidUrl(false);
    } else if (isValidURL(newUrl)) {
      // Valid URL
      setIsValidUrl(true);
    } else {
      // Invalid URL
      setIsValidUrl(false);
    }
  };

  const handleAddButtonClick = (e: any) => {
    e.preventDefault();
    if (reelURL?.trim() === "") {
      setErrorReelURL({
        status: true,
        message: "URL cannot be blank",
      });

      setReelURL("");
    } else if (isValidUrl) {
      if (reelLink.some((link) => link.url === reelURL)) {
        setErrorReelURL({
          status: true,
          message: "URL already exists in the list",
        });
      } else {
        const newReelLink = {
          id: new Date().getTime().toString(),
          url: reelURL,
        };
        setReelLink((prevReelLinks: any) => [...prevReelLinks, newReelLink]);

        setReelURL("");
        setErrorReelURL(undefined);
        const updatedReelLinks = [...reelLink, newReelLink];
        localStorage.setItem("reelLinks", JSON.stringify(updatedReelLinks));
      }
    } else {
      setErrorReelURL({
        status: true,
        message: "Invalid URL",
      });

      setReelURL("");
    }
  };
  const handleReelURL = (e: any) => {
    if (e.target.value === "") {
      setErrorReelURL({
        message: "It cannot be blank",
        status: true,
      });
    }
    isValidURL(e.target.value);
    //     setIsValidUrl(urlRegex.test(newUrl));
    // }
  };
  const handleRemoveReelLink = (id: any) => {
    const updatedReelLink = reelLink.filter((reel) => reel.id !== id);
    setReelLink(updatedReelLink);
  };
  useEffect(() => {
    const storedReelLinks = localStorage.getItem("reelLinks");
    if (storedReelLinks) {
      const parsedReelLinks = JSON.parse(storedReelLinks);
      setReelLink(parsedReelLinks);
    }
    const storedImages = localStorage.getItem("productImage");
    if (storedImages) {
      const parsedImageURL = JSON.parse(storedImages);
      setMultipleProductImageUrl(parsedImageURL);
    }
  }, []);

  const handleAddImagesForProduct = (e: any) => {
    setProductImageUrl(e.url);
    const updatedImages: any = [];
    setMultipleProductImageUrl([...multipleProductImageUrl, e.url]);
    setProductImagePath(e.key);
  };


  const handleGenerateAffiliateLink = () => {
    const data: any = {};
    data['user_id'] = props?.UserReducer?.user_id
    data['shop_id'] = shop_id
    data['product_id'] = product_id
    data['account_id'] = props?.UserReducer?.account_id
    AffiliateService.affiliateLinkGenerate(data).then((res: any) => {
      if (res.data.status.status) {
        setShowLink(true);
        setcct_id(res.data.status.cct_id)
        localService.toastify(res.data.status.message);
        setDisable(true);
      } else {
        localService.toastify(res.data.status.message);
      }
    }).catch((err: any) => { });
  }

  const handleCopyClick = () => {
    const textToCopy = `${BASE_URL_UI}/clanshop/product-details?shop_id=${shop_id}&product_id=${product_id}&cc_affiliate=true&cct_id=${cct_id}`;

    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  return (
    <div>
      {productData &&
        productData?.map((productData: any) => {
          return (
            <div key={productData.id} className="product-details-main">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="product-detail-img">
                      <img src={productData?.product_image_url} alt="brands" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="details">
                      <p>
                        <strong>Product Status:</strong> {productData?.status}
                      </p>
                      <p>
                        <strong>Brand Name:</strong> {productData?.brand}
                      </p>
                      <HeadingStyle
                        title={productData && productData?.product_name}
                        className="mb-3 d-block"
                      />
                      {productData?.description && (
                        <p>
                          <strong> Description : </strong>
                          {productData?.description}
                        </p>
                      )}
                      {productData?.inventory > 0 ? (
                        <p className="prod-inventory in-stock">In Stock</p>
                      ) : productData?.inventory > 0 &&
                        productData?.inventory < 5 ? (
                        <p className="prod-inventory few-stock">
                          Hurry! Last few stocks left.
                        </p>
                      ) : (
                        <span className="prod-inventory out-of-stock">
                          Out of Stock
                        </span>
                      )}
                      <p>
                        <strong>Stock:</strong> {productData?.inventory}
                      </p>
                      <p>
                        <strong>Original Cost :</strong>
                        <span
                          className="product-original-price"
                          style={{
                            textDecoration: "none !important",
                          }}
                        >
                          &#8377;
                          {productData?.product_cost}
                        </span>
                      </p>
                      <p>
                        <strong>Cost after discount :</strong>

                        <span className="product-after-discount">
                          &#8377;
                          {calculateRemainingPrice(
                            productData?.product_cost,
                            productData?.discount
                          )}
                        </span>
                      </p>
                      <p>
                        <strong>Discount : </strong>
                        {productData?.discount}
                      </p>

                      <p>
                        <strong>Total Commission :</strong>
                        {productData?.total_commission}
                      </p>
                      <p>
                        {" "}
                        <strong>Add Image:</strong>
                        <Button
                          className="btn btn-outline-primary upload_button"
                          onClick={() => setShowUploadPopup(true)}
                          variant="contained"
                        >
                          Upload Image
                        </Button>
                        {showUploadPopup && (
                          <ImageCrop
                            ratio={1 / 1}
                            path={path}
                            popup={(e) => setShowUploadPopup(e)}
                            onCroped={(e) => {
                              handleAddImagesForProduct(e);
                            }}
                          />
                        )}
                        <div className="uploaded-img-sec">
                          {productImageUrl && (
                            <strong className="d-block mb-3">
                              Uploaded Images
                            </strong>
                          )}
                          <div className="uploaded-images">
                            {multipleProductImageUrl &&
                              multipleProductImageUrl?.map(
                                (imageURL: string) => {
                                  return (
                                    <div className="uploaded-img">
                                      <img src={imageURL} alt="" />
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                        <div className="uploaded-reel-link">
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              placeholder="upload reel url"
                              value={reelURL}
                              className="form-control"
                              onChange={handleReelURLChange}
                              onBlur={handleReelURL}
                            />
                            <span className="error d-block">
                              {errorReelURL?.message}
                            </span>
                          </div>

                          <button
                            className="btn btn-outline-primary"
                            type="submit"
                            onClick={handleAddButtonClick}
                          >
                            Add
                          </button>
                        </div>
                        <div>
                          <button
                            className="btn btn-outline-primary mt-3"
                            type="submit"
                            onClick={handleGenerateAffiliateLink}
                            disabled={disable}
                          >
                            Generate Affiliate Link
                          </button>
                          {showLink &&
                            <div className="affl-gen-link">
                              <p>{BASE_URL_UI}/clanshop/product-details?shop_id={shop_id}&product_id={product_id}&cc_affiliate=true&cct_id={cct_id} </p>
                              <Tooltip title="Copy to clipboard">
                                <ContentCopyIcon
                                  id="copy-icon"
                                  onClick={handleCopyClick}
                                />

                              </Tooltip>
                            </div>
                          }
                        </div>

                        <div className="reel-link-sec mt-3">
                          {reelLink.length > 0 && (
                            <strong className="d-block mb-2">Reel URL:</strong>
                          )}
                          <ul>
                            {reelLink &&
                              reelLink?.map((reel) => {
                                return (
                                  <>
                                    <li className="reel-link-list">
                                      <span>{reel.url}</span>
                                      <span
                                        className="reel-remove"
                                        onClick={() =>
                                          handleRemoveReelLink(reel.id)
                                        }
                                      >
                                        <HighlightOff
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </span>
                                    </li>
                                  </>
                                );
                              })}
                          </ul>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    cartReducer: state.CartReducer.CartList,
  };
};
const connectToRedux = connect(mapStateToProps)(ProductDetailsInfl);

export { connectToRedux as ProductDetailsInfl };
