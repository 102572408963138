import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import ScrollToTop from '../../../components/scrollToTop';
import './thanku.scss';
import { Loading, SwitchLayer1, UserLogin, LoginUserId } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';
import { LocalService, LoginService, PublicService } from '../../../core/services';
import useBodyClass from '../../../hooks/useBodyClass';
// import ThankUMessage from './whatsapp-community-message';

const localService = new LocalService();
const publicService: PublicService = new PublicService();

interface Props {
    IsUserLogged: (data: any) => void;
    switchLayer1: (data: any) => void;
    userID: (data: any) => void;
    loading: (data: any) => void;
    activeAccount: any;
}

const ThankuPage: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState({ logIn: '', password: '', pin: '' });
    const [activeAccount, setActiveAccount] = useState<any>(null);

    // Get user-id from URL
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') || '';
    const url = window.location.href || '';

    useEffect(() => {
        if (localService.getStorageItem('login') && localService.getStorageItem('password')) {
            const _Login = localService.getStorageItem('login') || '';
            const _Pass = localService.getStorageItem('password') || '';
            handleLogin(_Login, _Pass, '');
        }

        // Function to send the POST request
        async function recordFbLeadConversion() {
            if (!id) {
                console.error('No user ID found in URL');
                return;
            }
            try {
                const response = await publicService.recordFbConversionLead({ user_id: id, url: url });
                // console.log('Response:', response);
            } catch (error) {
                console.error('Error sending POST request:', error);
            }
        }

        // Call the function to send the POST request
        recordFbLeadConversion();
    }, [id])


    //Login Button Actions
    const handleLogin = (login: string, password: string, pin: string) => {
        try {
            //login
            // localService.logout()
            // sessionStorage.clear();
            localStorage.clear();

            if (login.length > 0 && (password.length > 0 || pin.length > 0)) {
                setLoading(true);
                LoginService.login({
                    logIn: login.replace(/"/g, ''),
                    password: password.replace(/"/g, ''),
                    pin: pin.replace(/"/g, ''),
                })
                    .then((res: any) => {
                        if (res.data.status === 1) {
                            props.IsUserLogged(res.data.status === 1);
                            props.switchLayer1(res.data.rows);
                            setActiveAccount(res.data.rows[0]);
                            props.loading(false);
                            // navigate('/switch/account');
                            localService.toastify(
                                res.info,
                                'success',
                                1000
                            );

                            // Save Remmber me
                            handleremember();

                        } else {
                            localService.toastify(
                                res.info,
                                'info',
                                1000
                            );
                        }

                        setLoading(false);
                    })
                    .catch((error) => {
                        throw new Error(
                            'handleOTP in Login :: Failed :: ' + error
                        );
                    });
            } else {
                localService.toastify(
                    'Valid Email or  Valid Mobile Number is required',
                    'error',
                    1000
                );
            }
        } catch (error) {
            throw new Error(`handleLogin ::: ${error}`);
        }
    };

    const handleremember = () => {
        if (remember) {
            localService.setStorageItem('DeviceId', JSON.stringify(login));
        }

    };
    
    useBodyClass('common-thankyou-page');
    return (
        <section className='sec-thanks'>
            <ScrollToTop />
            <div className='container'>
                <div className='sec-thanks-left'>
                    <h3>Welcome to ClanConnect!<br />
                        We're excited to have you here.
                    </h3>
                    {/* <ThankUMessage /> */}
                    {activeAccount && <div>
                        <p style={{ fontWeight: 700 , fontSize: 18}}>
                            Join our Community
                        </p>
                        <p>
                            Want to stay in the loop with our latest campaigns and offers? Join our community!
                        </p>
                        <p> 
                            <a className="" href={activeAccount.env.WHATSAPP_COMMUNITY} target="_blank">Click Here</a>{' '}to join{' '}<strong>WhatsApp</strong>{' '}community
                        </p>
                        <p>
                            <a className="" href={activeAccount.env.TELEGRAM_COMMUNITY} target="_blank">Click Here</a>{' '}to join{' '}<strong>Telegram</strong>{' '}community
                        </p>
                    </div>}
                    <button className='btn btn-primary' onClick={() => navigate('/switch/account')}>Go to Dashboard</button>
                </div>
            </div>
        </section>
    )
}
const mapStateToProps = (state: any) => {
    return {
        switchLayer1: state.SwitchLayer1Reducer.switchAccount,
        activeAccount: state.UserReducer.UserData,
    };
};

const mapStateToDispatch = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
        switchLayer1: (data: any) => dispatch(SwitchLayer1(data)),
        IsUserLogged: (data: any) => dispatch(UserLogin(data)),
        userID: (data: any) => dispatch(LoginUserId(data)),
    };
};
const reduxConnect = connect(mapStateToProps, mapStateToDispatch)(ThankuPage);
export { reduxConnect as ThankuPage };
