import React, { FC, Fragment, useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import CommonPopUp from "./popup/common-popup";
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { Loading } from "../redux/action";
import { connect } from "react-redux";
import { UploadService, LocalService } from "../core/services";
import { LoadingButton } from "@mui/lab";
import { LinearProgress } from "@mui/material";

const uploadService: UploadService = new UploadService();
const localService: LocalService = new LocalService();

const ExcelFileInput: React.FC<{ onUpload: (data: any[]) => void, openUpload: boolean, UserReducer?: any; }> = ({ onUpload, openUpload, UserReducer }) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chooseFile, setChooseFile] = useState<File | null>(null);


  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files || e.target.files.length === 0) {
      setError("No file selected. Please select an Excel file.");
      return;
    }

    setError(null);
    const file: File = e.target.files[0];
    setChooseFile(file);
    const reader: FileReader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      try {
        const workbook: XLSX.WorkBook = XLSX.read(event.target!.result, { type: "binary" });
        const sheetName: string = workbook.SheetNames[0];
        const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const sheetData: any[] = XLSX.utils.sheet_to_json(sheet);

        setData(sheetData);
        // onUpload(sheetData)
      } catch (err) {
        setError("Error reading file. Please make sure it is a valid Excel file.");
        setData([]);
      }
    };

    reader.onerror = () => {
      setError("Error reading the file. Please try again.");
    };

    reader.readAsBinaryString(file);
  };

  const uploadExcelData = async () => {
    if (!chooseFile) {
      setError("Please select a file before uploading.");
      return;
    }
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", chooseFile);
      formData.append("path", `excelsheet/${UserReducer.user_id}/user`);
      formData.append('influencer_id', UserReducer.influencer_id || 0);
      formData.append('account_id', UserReducer.account_id || 0);
      formData.append('user_id', UserReducer.user_id || 0);

      const response = await uploadService.excelSheetUpload(formData);

      if (response.data.status?.status) {
        onUpload(data);
        setOpenModal(false);
        setChooseFile(null);
        setData([]);
        setLoading(false)
      } else {
        localService.toastify(response.data.status?.message, 'error', 1000);
        setOpenModal(false);
        setChooseFile(null);
        setData([]);
        setLoading(false)
      }
    } catch (error: any) {
      console.error(`uploadExcelData error :: ==> ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // const handleSend = () => {
  //   if (data.length > 0) {
  //     onUpload(data);
  //     setOpenModal(false)
  //     setData([]); 
  //   } else {
  //     setError("No data to send. Please upload a valid Excel file.");
  //   }
  // };


  const handleSend = () => {
    if (data.length > 0) {
      uploadExcelData();
    } else {
      setError("No data to send. Please upload a valid Excel file.");
    }
  };


  const onClose = (value: boolean) => {
    setOpenModal(false)
    setData([]);
  }

  // Reset the uploaded data
  const clearData = () => setData([]);

  const handleClosePreviewImage = () => {
    setChooseFile(null);
  };

  useEffect(() => {
    if (openUpload) {
      setOpenModal(true)
    }
  }, [openUpload])

  return (

    // <CommonPopUp
    //   modalTitle="Upload"
    //   modalContent=""
    //   openModal={openModal}
    //   onClose={() => onClose(false)}
    //   className="modal-medium mx-auto"
    // >
    //   <div className="upload-doc-sec">
    //     <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
    //     {error && <div style={{ color: "red" }}>{error}</div>}
    //     <pre>{data.length > 0 ? JSON.stringify(data, null, 2) : "No data uploaded yet."}</pre>
    //     <div className="popup-footer">
    //       <div className="d-flex justify-content-end">

    //         <LoadingButton
    //           className="btn btn-primary btn-mui"
    //           variant="contained"
    //           disabled={loading} 
    //           loadingPosition='start'
    //           onClick={handleSend}
    //         >
    //           Send
    //         </LoadingButton>
    //       </div>
    //     </div>
    //   </div>
    // </CommonPopUp>

    <CommonPopUp
      modalTitle="Upload"
      modalContent=""
      openModal={openModal}
      onClose={() => onClose(false)}
      className="modal-medium mx-auto"
    >
      <div className="upload-doc-sec pb-0">
        <Fragment>
          {!chooseFile && (
            <div className="upload-btn">
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
              <span className="material-symbols-rounded">
                upload
              </span>{" "}
              <strong>Choose File</strong>
            </div>
          )}

          {chooseFile && (
            <div className="uploaded-preview-sec">
              <div className="file-info d-flex align-items-center">
              {chooseFile && <FilePresentOutlinedIcon style={{ fontSize: '60px' }}></FilePresentOutlinedIcon>}
                <span className="file-name">{chooseFile.name}</span>
                <span
                  className="material-symbols-rounded icon-filled close-preview"
                  onClick={() => setChooseFile(null)}
                >
                  cancel
                </span>
              </div>
            </div>
          )}

          {error && <span className="error">{error}</span>}

          {loading && (
            <div className="uploading-progress mt-3">
              <LinearProgress />
              <span className="fs-14">
                File is uploading, please don't close this window.
              </span>
            </div>
          )}

          <div className="popup-footer d-flex justify-content-end">
            <LoadingButton
              className="upload-file-btn btn btn-primary btn-mui"
              variant="contained"
              disabled={loading || !chooseFile}
              loadingPosition="start"
              onClick={handleSend}
            >
              {loading ? "Uploading..." : "Upload"}
            </LoadingButton>
          </div>
        </Fragment>
      </div>
    </CommonPopUp>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelFileInput);

export { connectToRedux as ExcelFileInput };
