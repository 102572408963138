import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';

const TimelineChart: React.FC = () => {
  useEffect(() => {
    (async () => {
      // Load the dataset
      const response = await fetch(
        'https://demo-live-data.highcharts.com/aapl-c.json'
      );
      const data = await response.json();

      // Create the chart
      Highcharts.stockChart('container', {
        title: {
          text: 'Timeline View'
        },
        rangeSelector : {
            buttonTheme: {
                width: 50, // Adjust the width of the buttons
                height: 20, // Adjust the height of the buttons
                padding: 5, // Adjust the padding of the buttons
              },
            enabled: true,
            inputPosition: {
                align: 'right',
               
            },
            buttonPosition: {
                align: 'center', 
                x:110,
                y:0
            },

            buttons: [
                {
                  type: 'day',
                  count: 1,
                  text: 'daily'
                },
                {
                  type: 'week',
                  count: 1,
                  text: 'weekly'
                },
                {
                  type: 'month',
                  count: 1,
                  text: 'monthly'
                }
              ],
              selected: 1
        },
        navigator: {
            enabled: false
        },
        yAxis: {
          opposite: false
        },
       
        series: [
          {
           
            data: data,
           
            type: 'line' // or any other valid type
          }
        ]
      });
    })();
  }, []);

  return <div id="container" />;
};

export default TimelineChart;

