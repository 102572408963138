export const Instagram_Deliverables = [
    { label: 'Reel', value: 'ig_reel', status: 'active' },
    { label: 'Story', value: 'ig_story', status: 'active' },
    { label: 'Static', value: 'ig_static', status: 'active' },
    { label: 'Digital Rights', value: 'digital_rights', status: 'active' },
    { label: 'Exlusivity', value: 'available_for_exclusivity', status: 'active' }
];


export const Youtube_Deliverables = [
    { label: 'Shorts', value: 'yt_shorts', status: 'active' },
    { label: 'Dedicated video', value: 'yt_dedicated_video', status: 'active' },
    { label: 'Integrated video', value: 'yt_integrated_video', status: 'active' },
    { label: 'Digital rights', value: 'digital_rights', status: 'active' },
    { label: 'Exlusivity', value: 'available_for_exclusivity', status: 'active' }
];

export const Quantity = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 }
];


export const No_of_Days = [
    { label: '15 Days', value: 15 },
    { label: '30 Days', value: 30 },
    { label: '90 Days', value: 90 },
    { label: '180 Days', value: 180 },
];


export const No_of_Images = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 }
];
