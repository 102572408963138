import React, { Fragment } from 'react';
import { LoadingButton } from '@mui/lab';
import { SubscriptionPlanFeaturesData } from './subscription-plan-data';

interface PricingPlanProps {
  subscription_plan: any;
  makePayment?: (data: any) => void; // Declare makePayment function type
  loading?: boolean; // Loading state
  account_type: string;
  activePlan?: boolean; // Loading state
}

const PricingPlan: React.FC<PricingPlanProps> = ({ subscription_plan, makePayment, loading, account_type, activePlan }) => {
  let features;
  if (['BASIC'].includes(subscription_plan.plan_name)) {
    features = ['Influencer', 'Talent Partner'].includes(account_type) ? SubscriptionPlanFeaturesData.influencer.basic_plan_features : SubscriptionPlanFeaturesData.brand.basic_plan_features;
  } else {
    features = ['Influencer', 'Talent Partner'].includes(account_type) ? SubscriptionPlanFeaturesData.influencer.premium_plan_features : SubscriptionPlanFeaturesData.brand.premium_plan_features;
  }
  return (
    <Fragment>
      <div className={`pricing-plan ${(subscription_plan.user_type === 'Influencer' || subscription_plan.user_type === 'Talent Partner') ? "pricing-plan-influencer": ""}`}>
        <div className='pricing-plan-div'>
          <div className='d-flex flex-column  pb-3'>
            <span className='pricing-title'>{subscription_plan.plan_name === 'BASIC' ? 'BASIC' : 'PREMIUM'}<span className='ps-2 fs-12'>{(subscription_plan.plan_name !== 'BASIC' && subscription_plan.user_type === 'Influencer') ? '(Billed Annually)' : ''}</span></span>
            {subscription_plan.plan_name === 'BASIC' && (
              <span className='d-flex flex-column'>
                <span>
                  <span className="pricing-plan-type pricing-span">Free</span>
                  {/* <span className="pricing-plan-type pricing-span"> ₹0</span> */}
                </span>
                <span className='pricing-description'>By default, this plan is activated until you purchase a premium plan</span>
              </span>
            )}
            {subscription_plan.plan_name != 'BASIC' && subscription_plan.discount === 0 && (
              <span className="pricing-plan-type me-auto"><span className='pricing-span'> ₹{subscription_plan.net_amount}</span></span>
            )
            }
            {subscription_plan.plan_name != 'BASIC' && subscription_plan.discount != 0 && (
              <span className='d-flex flex-wrap align-items-center'>
                <span className="pricing-plan-type me-auto">
                  <span style={{ textDecoration: 'line-through' }}>
                    ₹{subscription_plan.subscription_amount}
                  </span>
                  &nbsp;
                  <span>₹{subscription_plan.net_amount}</span>
                  <span className='discount'> 
                    &nbsp;
                    SAVE {subscription_plan.discount}%
                  </span>
                </span>
                <span className="brand-pricing-plan-type"><span className='pricing-span'><span className='d-none' style={{ textDecoration: 'line-through' }}>₹{subscription_plan.monthly_subscription_amount}</span>&nbsp; ₹{subscription_plan.monthly_net_amount}/month</span></span>
              </span>
            )}
            {
              (subscription_plan.description && (subscription_plan.user_type === 'Brand' || subscription_plan.user_type === 'Agency')) && (
                <span className='pricing-description'>{subscription_plan.description}</span>
              )
            }
            {subscription_plan.gst_included === 'true' &&
              (
                <span className="pricing-plan-gst">(GST extra)</span>
              )
            }
          </div>
          {makePayment && subscription_plan.amount !== 0 &&
            (
              <LoadingButton
                className='btn btn-primary buy-btn plan-btn'
                loading={loading}
                loadingPosition='start'
                variant='contained'
                onClick={() => makePayment(subscription_plan)}
              >
                {activePlan !== null ? 'Upgrade' : 'Buy Now'}
              </LoadingButton>
            )
          }
        </div>
        <div className='pricing-plan-detail-sec'>
          {(subscription_plan.user_type === 'Influencer' || subscription_plan.user_type === 'Talent Partner') && (
            <ul>
              {features.map((feature, index) => (
                <li key={index} className={feature.liClassName}>
                  {feature.iconClassName && <i className={feature.iconClassName} />}
                  <span>{feature.text}</span>
                </li>
              ))}
            </ul>
          )}
          {(subscription_plan.user_type === 'Brand' || subscription_plan.user_type === 'Agency') && (
            <ul>
              {features.map((feature, index) => (
                <li key={index} className={feature.liClassName}>
                  <span>{feature.text}</span>
                  {feature.iconClassName && <i className={feature.iconClassName} />}
                  {feature.value && <span>{feature.value}</span>}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {subscription_plan.user_type === 'Influencer' &&
      <p className="fs-14 font-italic pb-5"><strong>Please note:</strong> ClanConnect is an influencer marketplace where we bring influencers and brands on a common plane in the most transparent manner. The selection of influencers for campaigns is the sole prerogative of the brand and ClanConnect has no role in this. Also, it is advisable to have a minimum of 1000 followers/subscribers to apply for a campaign.</p>
      }
    </Fragment>
  );
};

export default PricingPlan;
