import React, { Fragment, useEffect, useState } from 'react';
import * as Utils from '../../../../../../../core/utility';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';


const methods: Utils.Methods = new Utils.Methods();


/**
 * ************************FLOW OF THE NEGOTIATION INPUT COMPONENT *******************************
 * It will take single object as a prop and will return the input fields for the negotiation.
 * All actions will be handle own.
 */

/* Pending is opposite of Negotiating means if the status is pending then the Negotiating button, field and etc.. will be disabled. */



type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';

/**
 * Represents the data structure for a form used in negotiation input.
 */
type FormDataType = {
    negotiation_id: number;
    proposal_id: number;
    campaign_id: number;
    influencer_id: number;
    sm_id: number;
    deliverable_item: string;
    s_0_quantity: number;
    s_0_price: number;
    s_0_status: StatusType;
    s_0_date: string;
    s_1_quantity: number;
    s_1_price: number;
    s_1_status: StatusType;
    s_1_date: string;
    s_2_quantity: number;
    s_2_price: number;
    s_2_status: StatusType;
    s_2_date: string;
    s_3_quantity: number;
    s_3_price: number;
    s_3_status: StatusType;
    s_3_date: string;
    current_step: number;
    status: StatusType;
    proposal_datetime: string;
    created_at: string;
    updated_at: string;
};


interface Props {
    className?: string;
    dataSet: FormDataType;
    userType: 'influencer' | 'brand';
    step: number;
    campaignType? : string;
    costingModel?:string;

};


export const TableCellData: React.FC<Props> = ({ dataSet, step, userType = 'brand',campaignType,costingModel, ...arg }: Props) => {
    const [random] = useState(methods.getRandNumber());
    const [formData, setFormData] = useState<FormDataType>(dataSet);
    useEffect(() => {
        setFormData(dataSet);
    }, [dataSet])

    const getInputValue = (step: number, need: ("quantity" | 'price')) => {
        return formData[genrateObjectKey(step, need)] || 0;
    };

    const genrateObjectKey = (step: number, need: ("quantity" | 'price' | 'status' | 'total_price')) => {
        return `s_${step - 1}_${need}` as keyof FormDataType || '';
    };
    const getStatus = (step: number, dataSet: FormDataType): StatusType => {
        return dataSet[genrateObjectKey(step, 'status')] as StatusType;
    };

    const isRejected = getStatus(step, dataSet) === 'Rejected';
    const isAccepted = getStatus(step, dataSet) === 'Accepted';
    const NonNegotiable = getStatus(step, dataSet) === 'NonNegotiable';

    return (
        <div className={`table-proposal-body-rest-col-row ${isRejected ? 'rejected' : ''} ${isAccepted ? 'accepted' : ''} `} id={`cell-id-${random}`}>
            <span className='table-proposal-body-rest-col'>
                {(formData?.deliverable_item !== 'available_for_exclusivity' && formData?.deliverable_item !== 'digital_rights') && getInputValue(step, 'quantity')}
                {(formData?.deliverable_item === 'available_for_exclusivity' || formData?.deliverable_item === 'digital_rights') && <>-</>}
                {/* {getInputValue(step, 'quantity')} */}
            </span>
            { (campaignType =="Paid" && !costingModel?.includes("Package")) &&
            <span className={`table-proposal-body-rest-col ${NonNegotiable ? "pending non-negotiable d-flex align-items-center" : ""}`}>

            {getInputValue(step, 'price')}
            {NonNegotiable && <Tooltip title="Non-negotiable" arrow>
                <span className='ms-auto mt-auto mb-auto'>
                    <MaterialSymbol
                        className={`align-self-center lockIcon pointer`}
                        iconName='lock'
                        fontweight='200'
                    />
                </span>
            </Tooltip>}
        </span>
            }


        </div>
    );
}