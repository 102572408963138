import React, { useState } from 'react';
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { DetailedAnalytics } from './detailed-analytics';
import { DetailedAnalyticsYoutube } from './detailed-analytics-youtube';
import { InfluencerAnalytics } from './analytics';
import { InfluencerAnalyticsYoutube } from './analytics_youtube';



interface FormData {
  postLink: string;
}

interface AnalyticsTabProps {
  open: boolean;
  campaign_id: number;
  onClose: () => void;
}

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
}

const AnalyticsTab: React.FC<Props> = (props: Props) => {
  return (
    <>
      {

        (() => {
          if (props.UserReducer.account_type == "Brand" ||props.UserReducer.account_type == "Agency" ) {
            if (props.selectCampaign.campaign_platform == 'Instagram') {
              return (
                <>
                  <DetailedAnalytics />
                </>
              )

            }
            else {
              return (
                <>
                  <DetailedAnalyticsYoutube />
                </>
              )
            }
          }
          else {
            if (props.selectCampaign.campaign_platform === 'Instagram') {
              return (
                <>
                  <InfluencerAnalytics />
                </>
              )

            }
            else {
              return (
                <>
                  <InfluencerAnalyticsYoutube />
                </>
              )
            }

          }


        })()}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(AnalyticsTab);

export { connectToRedux as AnalyticsTab };

