interface Props {
  className?: string;
}
const IconShortListed: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 17.2499V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H11.6922C12.1974 0.5 12.625 0.675 12.975 1.025C13.325 1.375 13.5 1.80257 13.5 2.3077V17.2499L6.99997 14.4615L0.5 17.2499ZM1.99997 14.95L6.99997 12.8L12 14.95V2.3077C12 2.23077 11.9679 2.16024 11.9038 2.09613C11.8397 2.03203 11.7692 1.99998 11.6922 1.99998H2.3077C2.23077 1.99998 2.16024 2.03203 2.09613 2.09613C2.03202 2.16024 1.99997 2.23077 1.99997 2.3077V14.95Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconShortListed;
