import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from '@mui/material';
import { ApplyCampaignService,LocalService } from '../../../../../../../core/services';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Profile } from '../proposal-components/profile';
import { DeliverableTool } from '../proposal-components/deliverable-tool';
import Loading from '../../../../../../../components/loader/loading';
import ProposalContainer from '../proposal-components/proposal-container';
import ProposalActionBtn from '../proposal-components/proposal-action-btn';
import { createPortal } from 'react-dom';
import ConfirmPopup from '../../../../../../../components/confirm-popup/confirm-popup';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Checkbox } from "@mui/material";

const applyCampaignService: ApplyCampaignService = new ApplyCampaignService();
const localService:LocalService = new LocalService();
interface Props {
  UserReducer: any;
  selectedCampaign: any;
  unreadProposal: any;
  updateUnreadProposal: () => void;
  set_sm_id?: (id: number) => void;
}

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';

type CurrentSteps = 1 | 2 | 3 | 4;

interface Influencer {
  influencer_id: number;
  username: string;
  sm_id: number;
}

interface FormDataType {
  negotiation_id: number;
  proposal_id: number;
  campaign_id: number;
  influencer_id: number;
  sm_id: number;
  deliverable_item: string;
  s_0_quantity: number;
  s_0_price: number;
  s_0_status: StatusType;
  s_0_date: string;
  s_1_quantity: number;
  s_1_price: number;
  s_1_status: StatusType;
  s_1_date: string;
  s_2_quantity: number;
  s_2_price: number;
  s_2_status: StatusType;
  s_2_date: string;
  s_3_quantity: number;
  s_3_price: number;
  s_3_status: StatusType;
  s_3_date: string;
  current_step: CurrentSteps;
  status: StatusType;
  proposal_datetime: string;
  created_at: string;
  updated_at: string;
}

const BrandShortlisted: React.FC<Props> = (props: Props) => {
  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [originalInfluencers, setOriginalInfluencers] = useState<Influencer[]>([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([]);
  const [expanded, setExpanded] = useState<number | false>(false);
  const [details, setDetails] = useState<FormDataType[]>([]);
  const [searchParams] = useSearchParams();
  const [negotiateVisible, setNegotiateVisible] = useState(false);
  const [selectedProposals, setSelectedProposals] = useState<any[]>([]);
  const [selectProposalAll, setSelectProposalAll] = useState<boolean>(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deliverableHeader, setDeliverableHeader] = useState<any>([]);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openAcceptPopup, setOpenAcceptPopup] = useState(false);
  const [openAcceptAllPopup, setOpenAcceptAllPopup] = useState(false);
  const [openRejectAllPopup, setOpenRejectAllPopup] = useState(false);

  const [campaignType] = useState(props.selectedCampaign?.campaign_type || "Paid");

  const [influencersData, setInfluencersData] = useState({
    Nano: [],
    Micro: [],
    'Micro Plus': [],
    Macro: [],
    'Macro Plus': [],
    Mega: [],
    'Mega Plus': [],
    All: [],
  });

  const campaignDetails = useMemo(() => {
    return {
      campaignId: searchParams.get('campaignID'),
      planId: searchParams.get('planID'),
    };
  }, [searchParams]);


  const getDeliverableHeader = async () => {
    try {
      const response: any = await ApplyCampaignService.getDeliverableHeader({ campaign_id: campaignDetails.campaignId });
      if (response.status === 1) {
        setDeliverableHeader(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching deliverable header:', error);
    }
  };
  const getCommonStatus = (details: any[], campaign_cost_model: string): string => {
    if (details.length === 0) return "Negotiating";
    const statusIndexKey = `s_${details[0].current_step}_status`;
    if (campaign_cost_model === "Individual") {
      const commonStatus = details[0]?.[statusIndexKey];
      return details.every(obj => obj?.[statusIndexKey] === commonStatus) ? commonStatus : "Negotiating";
    }
    else {
      const package_cost_item = details.filter(obj => obj?.deliverable_item === "Package Cost 1");
      return package_cost_item[0][statusIndexKey]
    }
  }
  const submitProposal = async () => {
    try {
      setLoading(true);
      const status_value = getCommonStatus(details, props.selectedCampaign.campaign_cost_model);
      const response = await applyCampaignService.proposalNegotiate({ proposalNegotiate: details, status_value: status_value, user_type: "Brand" })
      if (response) {
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()

      }

    }
    catch (error) {
      console.error("Error in Negotiation ")
    }
  }

  const AcceptProposal = async (status: string) => {
    try {
      setLoading(true);
      const res:any = await applyCampaignService.acceptProposal({
        status: status,
        proposal_id: details[0].proposal_id,
        user_type: "Brand",
      })
      if(res.data.status.status == true){
        setLoading(false);
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()

      }
    } catch (error) {
      setLoading(false);
      console.warn("error",error)

    }
  }

  const UpdateBulkProposal = async (status: string) => {
    try {
      setLoading(true);
      const res:any = await applyCampaignService.updateBulkProposals({
        proposal_status: status,
        proposal_ids: selectedProposals,
      })
      if(res.data.status.status == true){
        setLoading(false);
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()

      }
      else{
        localService.toastify(res.data.status.message,"error",5000)

      }
    } catch (error) {
      setLoading(false);
      console.warn("error",error)

    }
  }
  const RejectProposal = async (status: string) => {
    try {
      setLoading(true);
      const res:any = await applyCampaignService.rejectProposal({
        status: status,
        proposal_id: details[0].proposal_id,
        user_type: "Brand",
      })
      if(res.data.status.status == true){
        setLoading(false);
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()

      }
    } catch (error) {
      setLoading(false);
      console.warn("error",error)

    
    }  
  }

  const getInfluencerList = async () => {
    try {
      setLoading(true);
      const response = await ApplyCampaignService.influencerList({ campaign_id: campaignDetails.campaignId, proposalStatus: 'Shortlisted' });
      if (response?.data?.rows?.length >= 0) {
        let inf: any = {
          Nano: [],
          Micro: [],
          'Micro Plus': [],
          Macro: [],
          'Macro Plus': [],
          Mega: [],
          'Mega Plus': [],
          All: response.data.rows,
        };

        response.data.rows.forEach((res: any) => {
          if (inf[res.infType]) {
            inf[res.infType].push(res);
          }
        });

        setInfluencersData(inf);
        setInfluencers(response.data.rows);
        setOriginalInfluencers(response.data.rows);
        setFilteredInfluencers(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching influencer list:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfluencerList();
    getDeliverableHeader();
  }, [campaignDetails.campaignId]);

  const handleChange = async (panel: number, sm_id: number) => {
    try {
      setLoading(true);
      if (expanded === panel) {
        setExpanded(false);
      }
      else {
        const response = await ApplyCampaignService.brandProposalDetails({ smId: sm_id, campaign_id: campaignDetails.campaignId });
        if (response?.data?.rows?.length > 0) {
          setDetails(response.data.rows);
          setExpanded(panel);
          getInfluencerList();
        }
      }

    } catch (error) {
      console.error('Error fetching proposal details:', error);
    } finally {
      if (props.unreadProposal.some((e: any) => e.sm_id === sm_id)) {
        props.updateUnreadProposal();
      }
      setLoading(false);
    }
  };

  function handleNegoValue(event: FormDataType, index: number) {
    setDetails((prev: any[]) => {
      return prev.map(item =>
        item.deliverable_item === event.deliverable_item ? { ...item, ...event } : item
      );
    });

  };

  const handleAcceptAllClose = () => {
    setOpenAcceptAllPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  const handleAcceptAllConfirm = () => {
    setOpenAcceptAllPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handleRejectAllClose = () => {
    setOpenRejectAllPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleRejectAllConfirm = () => {
    setOpenRejectAllPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }


  const handleAcceptClose = () => {
    setOpenAcceptPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleAcceptConfirm = () => {
    setOpenAcceptPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handleRejectClose = () => {
    setOpenRejectPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleRejectConfirm = () => {
    setOpenRejectPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleFilteredInfluencer = (event: any) => {
    const value = event.toLowerCase();
    if (value === 'all') {
      setFilteredInfluencers(originalInfluencers);
    } else {
      const filtered = originalInfluencers.filter((influencer: any) =>
        influencer?.infType?.toLowerCase() === value?.toLowerCase()
      );
      setFilteredInfluencers(filtered);
    }
  };

  const handleCheckbox = (proposal_id: any) => {
    setSelectedProposals((prev) =>
      prev.includes(proposal_id)
        ? prev.filter((id) => id !== proposal_id)
        : [...prev, proposal_id]
    );
  };

  const handleCheckboxSelectAll = (data: boolean) => {
    if (data) {
      setSelectedProposals(
        filteredInfluencers.map((inf: any) => {
          if (!selectedProposals.includes(inf.proposal_id)) {
            return inf.proposal_id;
          }
          return inf.proposal_id;
        })
      );
    } else {
      setSelectedProposals([]);
    }
  setSelectProposalAll(data);
  };

  return (

    <div className="proposal-container">
      {/* PROPOSAL CONTAINER LEFT */}
      <div className="proposal-container-left">
        <DeliverableTool data={influencersData} filteredInfluencer={(influencer: any) => handleFilteredInfluencer(influencer)} />
      </div>

      {/* PROPOSAL CONTAINER RIGHT */}
      <div className="proposal-container-right">
      {
        filteredInfluencers.length > 0 &&
        <div style={{display:'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '42px' }}>
              <FormControlLabel control={<Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                checked={selectProposalAll || filteredInfluencers.length == selectedProposals.length}
                onChange={() => handleCheckboxSelectAll(!selectProposalAll)}
              />} label="Select All" />
            
            {/* <span style={{fontSize:'14px'}}>Select All</span> */}
          </div>
          <div style={{ padding: '10px 0px 10px 10px', marginLeft: 'auto', display: 'flex', gap: '8px' }}>
            <button className={`btn btn-sm btn-primary ${selectedProposals.length <= 0 ? 'disabled' : ''}`} onClick={handleAcceptAllConfirm}>Accept</button>
            <button className={`btn btn-sm btn-outline-error ${selectedProposals.length <= 0 ? 'disabled' : ''}`} onClick={handleRejectAllConfirm}>Reject</button>
          </div>
        </div>
        }
        {filteredInfluencers.map((inf:any, index) => (
          <Accordion className='accordion-container' key={inf.influencer_id} expanded={expanded === index} onChange={() => handleChange(index, inf.sm_id)}>
            <AccordionSummary>
              <div className="proposal-header">
                {props.unreadProposal.length > 0 && (props.unreadProposal.map((influencer_data: any, index: any) => (
                  (inf.sm_id === influencer_data.sm_id && influencer_data.status === 'Shortlisted' && influencer_data.read == 'no') &&

                  <span className='unread-notification-icon'></span>

                )))}
                 <Checkbox
                  style={{padding:'16px'}}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    
                    checked={selectedProposals.includes(inf.proposal_id)}
                    onClick={(event:any) => {
                      event.stopPropagation();
                      handleCheckbox(inf.proposal_id)
                    }}
                  />
                <div className="proposal-header-left">
                  <Profile profileData={inf} userReducer={props.UserReducer} />
                </div>
                <div className="proposal-header-right">
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {loading && expanded === index && details ? (
                <Loading width='80' height='80' />
              ) : (
                <>
                  <ProposalContainer deliverableHeader={deliverableHeader}
                    data={details}
                    step={details}
                    index={index}
                    profileData={inf}
                    negotiateVisible={negotiateVisible}
                    onChange={(event: any) => handleNegoValue(event, index)}
                    setNegotiateVisible={setNegotiateVisible}
                    submitProposal={submitProposal}
                    campaignType={campaignType}

                  />
                </>
              )}
            </AccordionDetails>
            {!negotiateVisible && (details && details[0]?.current_step == 1 || details[0]?.current_step == 3) &&
              <>
                <ProposalActionBtn setNegotiateVisible={setNegotiateVisible} data={details} handleAcceptConfirm={handleAcceptConfirm} handleRejectConfirm={handleRejectConfirm} campaignType={campaignType} />
              </>
            }
          </Accordion>
        ))
        }
        {/* DISPLAY EMPTY MESSAGE */}
        {influencers.length === 0 && (
          <p className="no-list-comment align-items-baseline mt-5">There are no shortlisted proposals at the moment.</p>
        )}
        <>
          {openRejectPopup && <>
            {createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to reject the proposal?" openPopup={openRejectPopup} confirmClosePopup={handleRejectClose} confirmClickAction={(() => {
              RejectProposal("Rejected")
            })} drawerTitle="" />, document.body)}</>}
          {
          openAcceptPopup && 
          <>
            {createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to accept the proposal?" openPopup={openAcceptPopup} confirmClosePopup={handleAcceptClose} confirmClickAction={(() => {
              AcceptProposal("Accepted")
            })} drawerTitle="" />, document.body)}
          </>
          }
          {
          openAcceptAllPopup && 
          <>
            {createPortal(<ConfirmPopup confirmPopupInnerText= {selectedProposals.length > 1 ? "Are you sure want to accept all the proposals?" : "Are you sure want to accept the proposal?"} openPopup={openAcceptAllPopup} confirmClosePopup={handleAcceptAllClose} confirmClickAction={(() => {
              UpdateBulkProposal("Accepted")
            })} drawerTitle="" />, document.body)}
          </>
          }
          {
          openRejectAllPopup && 
          <>
            {createPortal(<ConfirmPopup confirmPopupInnerText={selectedProposals.length > 1 ? "Are you sure want to reject all the proposals?" : "Are you sure want to reject the proposal?"} openPopup={openRejectAllPopup} confirmClosePopup={handleRejectAllClose} confirmClickAction={(() => {
              UpdateBulkProposal("Rejected")
            })} drawerTitle="" />, document.body)}
          </>
          }
        </>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const connectToRedux = connect(mapStateToProps)(BrandShortlisted);

export { connectToRedux as BrandShortlisted };
