import React, { useEffect, useState } from "react";
import { SortingColumnsType, TableComponent } from "../../../components/table-component/table";
import { TableCell, TableRow, TextField } from "@mui/material";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { UserService } from "../../../core/services";
import { CryptoJS } from '../../../core/utility';
import { BASE_URL_UI } from '../../../config/config';
import UseAnimations from "react-useanimations";
import download from 'react-useanimations/lib/download'
import { useLocation } from "react-router-dom";



interface Props {
    UserReducer: any;
    loading: (data: boolean) => void;
}
const userService: UserService = new UserService();
const cryptojs = new CryptoJS();

const ABTIUserSubscriptionHistory: React.FC<Props> = (props: Props) => {
    const columnDisplayName = {
        serial_no: "#",
        brand_name: props.UserReducer.account_type === "Agency" ? "Brand" : "Influencer",
        plan_type: "Plan Type",
        amount: "Amount",
        plan_end_date: "Plan End Date",
        purchased_date: "Purchased On",
        invoice: "Invoice"
    };

    const sortingColumnType: SortingColumnsType[] = [
        {
            name: "#",
            columnSize: "30px",
        },
        {
            name: "brand_name",
            columnSize: "160px",
        },
        {
            name: "plan_type",
            columnSize: "60px",
        },
        {
            name: "amount",
            columnSize: "60px",
        },
        {
            name: "plan_end_date",
            columnSize: "30px",
        },
        {
            name: "purchased_date",
            columnSize: "30px",
        },
        {
            name: "invoice",
            columnSize: "30px",
        }
    ];

    const [renderData, setRenderData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [agencyBrands, setAgencyBrands] = useState<any>([]);
    const [agencyBrandsCopy, setAgencyBrandsCopy] = useState<any>([]);
    const [clearSearch, setClearSearch] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const location = useLocation();
    const brandInfluencerName = location.state?.brandInfluencerName || ''

    async function getABTIUserSubscriptionHis() {
        try {
            setLoading(true);
            const res = await userService.getInvoiceBrandsOfAgencyInflOfTP(props.UserReducer.account_id);

            if (res.data.status) {
                setAgencyBrands(res.data.rows);
                setAgencyBrandsCopy(res.data.rows);
            }
        } catch (error) {
            console.error('Error fetching getABTIUserSubscriptionHis:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getABTIUserSubscriptionHis();
    }, []);
    
    useEffect(() => {
        if (brandInfluencerName.length > 0 && agencyBrandsCopy.length > 0) {
            handleSearch({ target: { value: brandInfluencerName } });
        }
    }, [brandInfluencerName, agencyBrandsCopy]); 
    


    async function downloadInvoice(data: { order_id: number }) {
        try {
            let token = cryptojs.encryptMessage(JSON.stringify({ order_id: data.order_id }), BASE_URL_UI)
            token = encodeURIComponent(token)
            const url = `${window.location.origin}/subscription/invoice?token=${token}`;
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error downloading invoice:', error);
        }
    }


    const handleSearch = async (e: any) => {
        const newSearchValue = e.target.value 
        setSearchValue(newSearchValue);
    
        const lowerCaseSearchValue = newSearchValue.toLowerCase();
    
        if (lowerCaseSearchValue === '' && lowerCaseSearchValue.length < 3) {
          setClearSearch(false);
          setAgencyBrands(agencyBrandsCopy);
        } else {
          const newAgencyBrands = agencyBrands?.filter((data: any) =>
            data?.name?.toLowerCase().includes(lowerCaseSearchValue)
          );
          setAgencyBrands(newAgencyBrands);
          setClearSearch(true);
        }
    };

    const clearSearchHandler = () => {
        setSearchValue('');
        setClearSearch(false);
        setAgencyBrands(agencyBrandsCopy)
    };

     
    return (
        <div className="agency-brand-subs-history px-4">
            <div className="list-section-content rounded mb-3">
                <div className='row align-items-center'>
                    <strong className='col'>Subscription History</strong>
                </div>
            </div>
            <div className="search-filter mb-3 w-50">
                <TextField
                    className='input-login search-plan-input w-100'
                    variant='outlined'
                    label='Search'
                    size='small'
                    value={searchValue}
                    onChange={handleSearch}
                    placeholder={props.UserReducer.account_type ===  "Agency" ? "Enter Brand Name" : "Enter Influencer Name"}

                />
                {clearSearch && (
                    <span
                        className='clear-search-result d-none d-md-block'
                        onClick={clearSearchHandler}
                        style={{ cursor: 'pointer' }}
                    >
                        Clear search results
                    </span>
                )}
            </div>
            
            <TableComponent
                columnDisplayName={columnDisplayName}
                orderByColumnName=""
                sortingColumnType={sortingColumnType}
                inputDataSource={agencyBrands}
                renderData={renderData}
                setRenderData={setRenderData}
                tableMinWidth={300}
            >
                {renderData.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={7} align="center">
                            No Data Available
                        </TableCell>
                    </TableRow>
                ) : (
                    renderData
                        .filter((data: any) => data.total_amount !== null && data.plan_type !== null && data.total_amount !== "" && data.plan_type !== "")
                        .map((data: any, index: number) => {
                            const planEnd = data?.plan_end ? `${new Date(data.plan_end).getDate()} ${new Date(data.plan_end).toLocaleString('en-GB', { month: 'short', year: 'numeric' })}` : '-';
                            const planStart = data?.purchased_date ? `${new Date(data.purchased_date).getDate()} ${new Date(data.purchased_date).toLocaleString('en-GB', { month: 'short', year: 'numeric' })}` : '-';
                            return (
                                <TableRow key={index} hover role="checkbox">
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{data.name}</TableCell>
                                    <TableCell>{data.plan_type ? data.plan_type : '-'}</TableCell>
                                    <TableCell>{data.total_amount ? `₹${data.total_amount}` : '-'}</TableCell>
                                    <TableCell>{planEnd}</TableCell>
                                    <TableCell>{planStart}</TableCell>
                                    <TableCell>
                                        {data.total_amount > 0 && (
                                            <UseAnimations
                                                animation={download}
                                                size={30}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    downloadInvoice(data);
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                )}
            </TableComponent>
        </div>

    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ABTIUserSubscriptionHistory);

export { connectToRedux as ABTIUserSubscriptionHistory };


