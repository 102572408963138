import React, { useState, useRef, useEffect, RefObject } from 'react';
import { Grid } from '@mui/material';

interface Props {
  label?: any;
  children?: any;
}

const FilterDropdown = ({ label, children }: Props) => {
  const [dropdownMenu, setDropdownMenu] = useState(false);

  const showDropdownMenu = () => {
    setDropdownMenu(prev => !prev); // Toggle the dropdown menu state
  };

  const useClickOutside = (handler: () => void): RefObject<HTMLDivElement> => {
    const domNode = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const eventHandler = (event: MouseEvent) => {
        if (domNode.current) {
          let target = event.target as HTMLElement; // ensure element type
          while (target?.parentElement) {
            if (target === domNode.current) {
              return; // Click originated within dropdown menu
            }
            target = target.parentElement;
          }
          handler(); // Click outside
        }
      };

      document.addEventListener('mousedown', eventHandler);

      return () => {
        document.removeEventListener('mousedown', eventHandler);
      };
    }, [handler]);

    return domNode;
  };

  const dropdownNode = useClickOutside(() => {
    setDropdownMenu(false);
  });

  return (
    <Grid
      item
      xs={12}
      sm={4}
      lg={3}
      xl={2}
      sx={{ width: '250px' }}
      className={dropdownMenu ? 'filter-dropdown-sec active' : 'filter-dropdown-sec'}
      ref={dropdownNode}
    >
      <span
        className={dropdownMenu ? 'filter-dropdown active' : 'filter-dropdown'}
        onClick={showDropdownMenu}
      >
        <span className='filter-dropdown-text'>{label}</span>
      </span>
      <div

        className={dropdownMenu ? 'filter-dropdown-menu active' : 'filter-dropdown-menu'}
      >
        {children}
      </div>
    </Grid>
  );
};

export default FilterDropdown;
