import React from 'react';
// import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import './loader.scss';
import Lottie from 'react-lottie';
import animationData from '../../data/lottie/animationData.json';
interface Props {
    LoadingReducer?: any
}

const AppLoader: React.FC<Props> = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            {props.LoadingReducer?.loading && (
                <div className='app-loader'>
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                    {/* <div className='spinner-container'>

                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                     
                        </div> */}
                </div>
            )}
        </>

    )
};

const mapStateToProps = (state: any) => {
    return {
        LoadingReducer: state.LoadingReducer,
    }
};

const connectNavBar = connect(mapStateToProps)(AppLoader);
export { connectNavBar as AppLoader };