import React from "react";

import YouTubeIcon from '../../assets/images/icon-youtube.png'

interface Props {
  width?: any;
  height?: any;
  maxWidth?: string;
  className?: string;
  marginRight?:string;
  minHeight?:string;
}

const IconYouTube: React.FC<Props> = (props: Props) => {
  return (
    <span
      className={props.className}
      style={{
        width: props.width,
        maxWidth: props.maxWidth,
        height: props.height,
        marginRight:'5px',
        minWidth: '20px',
        minHeight: props?.minHeight,
        display:'inline-flex',
        alignSelf:'center'
      }}
    >
      <img src={YouTubeIcon} alt="YouTube" style={{ width: `${props.width
        }px`, maxWidth: '100%', marginRight: props.marginRight ? `${props.marginRight}px` : "5px", minHeight: props.minHeight ? `${props.marginRight}px` : '20px' }} />
    </span>
  );
};

export default IconYouTube;
