import { Component, ReactNode } from 'react';

export class AgencyDashboard extends Component {

    constructor(props: any) {
        super(props);
    }


    render(): ReactNode {
        return (
            <div className="container-fluid influencer_dashboard">
                <div className="row">
                    <div className="col" style={{minHeight: `100vh`}}>
                        <h1>Agency Dashboard</h1>
                    </div>
                </div>
            </div>
        )
    }



}