import { Skeleton, Tooltip } from '@mui/material'
import React from 'react'

type City = {
  name: any;
  percentage: number;
}


type Props = {
  loading: boolean;
  data: City[]
}

const CityList = (props: Props) => {
  return (
    <div className="cities-list">
      {props?.loading ? <Skeleton /> : <>
        {props?.data?.length < 1 && <>No Data</>}
        {props?.data?.length > 0 && props?.data?.slice(0, 5).map((cityName: City) => {
          return <div className="cssProgress" key={cityName?.name}>
            <p className="cities"><Tooltip arrow placement="top-start" title={cityName?.name}>{cityName?.name}</Tooltip></p>

            <div className="progress-bars">
              <div className="progress2" style={{ width: `${cityName?.percentage}%` }}>
                <div
                  className="cssProgress-bar"
                  data-percent={cityName?.percentage}
                  style={{width:'100%'}}
                  
                ></div>
              </div>
              <div className="percent-bar">
                <span className="p-span">{(cityName?.percentage < 1) ? "< 1%" : `${Math.floor(cityName?.percentage)}%`} </span>
              </div>
            </div>

          </div>
        })}
      </>}

    </div>
  )
}

export default CityList