import React, { useEffect, useRef } from "react";
import "./campaign-configure-2.scss";
import * as Highcharts from "highcharts";
import macro from "../../../assets/images/campaign_icons/macro.png";
import microplus from "../../../assets/images/campaign_icons/micro-plus.png";
import micro from "../../../assets/images/campaign_icons/micro.png";
import HeadingStyle from "../../../components/heading-style/heading-style";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useBodyClass from "../../../hooks/useBodyClass";
import { MenuPropsSelect } from "../../../core/utility/style";

interface ProgressSectionProps {
  Delhi: number;
  Mumbai: number;
  Trichur: number;
  Bangalore: number;
  Jaipur: number;
}

export const CampaignConfigure2: React.FC = () => {
  const [sortby, setSortby] = React.useState("");

  const handleChange = (event: any) => {
    setSortby(event.target.value);
  };
  const progress: ProgressSectionProps = {
    Delhi: 59,
    Mumbai: 33,
    Trichur: 43,
    Bangalore: 65,
    Jaipur: 45,
  };

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/brand/configurator");
  };
  const colors = (Highcharts.getOptions()?.colors ?? []).map((c, i) =>
    Highcharts.color((Highcharts.getOptions()?.colors ?? [])[0])
      .brighten((i - 3) / 7)
      .get()
  );

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      width: 163,
      height: 173,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          //   format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    series: [
      {
        name: "Share",
        type: "pie",
        data: [
          { name: "Micro", y: 12.66 },

          { name: "Micro+", y: 4.96 },
        ],
      },
    ],
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(chartRef.current, chartOptions);
    }
  }, []);

  //1stchart

  useEffect(() => {
    const chartOptions1: Highcharts.Options = {
      chart: {
        type: "pie",
        backgroundColor: "transparent",
        plotBorderWidth: 0,
        borderRadius: 4,
        height: 250,
      },
      title: {
        verticalAlign: "middle",
        floating: true,
        text: "",
      },

      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          innerSize: "55%",
          borderWidth: 0,
        },
      },
      series: [
        {
          type: "pie",
          data: [
            ["Estimated Reach", 44.2],
            ["", 44.2],
          ],
        },
      ],
    };
    Highcharts.chart("container1", chartOptions1);
  }, []);
  //2nd chart

  useEffect(() => {
    const chartOptions2: Highcharts.Options = {
      chart: {
        type: "bar",
        backgroundColor: "#00AAE536",
        borderRadius: 4,
        height: 260,
      },
      title: {
        text: "",
      },

      credits: {
        enabled: false,
      },
      xAxis: {
        labels: {
          style: {
            color: "#FFFFFF",
          },
        },
        categories: [
          "13-17",
          "18-24",
          "25-34",
          "35-44",
          "45-54",
          "55-64",
          "65+",
        ],

        crosshair: true,
      },
      yAxis: {
        labels: {
          style: {
            color: "#FFFFFF",
          },
          y: 10,
        },
        min: 0,
        gridLineWidth: 0,
        title: {
          text: "",
          style: {
            color: "#FFFFFF",
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        bar: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        itemStyle: {
          color: "#FFFFFF",
        },
        y: 2,
        x: -4,
      },
      series: [
        {
          name: "Male",
          type: "bar",
          groupPadding: 0.2,
          data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6],
          color: "#e83e8c",
        },
        {
          name: "Female",
          type: "bar",
          groupPadding: 0.2,
          data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0],
          color: "#17a2b8",
        },
      ],
    };

    Highcharts.chart("container2", chartOptions2);
  });
  useBodyClass("no-scroll");
  return (
    <div className="camcon_result_container config-result ">
      <div className="d-flex flex-column camcon_result_container_left">
        <div className="alert alert-blue mb-3" role="alert">
          <p className="mb-0">
            <strong>
              <i className="bi bi-info-circle" />
            </strong>
            These are system generated calculations based on estimations. The
            actual cost of individual influencer might differ.
          </p>
        </div>
        <div className="row">
          <div className="heading col-12 d-flex flex-wrap flex-column flex-sm-row">
            <HeadingStyle
              title="Configuration"
              className="mb-4 d-block title-w-bdr"
            />

            <Button
              variant="outlined"
              size="small"
              className="btn btn-1"
              onClick={handleNavigation}
            >
              Configure Again
            </Button>
          </div>
        </div>
        <div className="container-configuration">
          <div className="row mb-3">
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Objective: &nbsp;</strong>
              <span>Reach</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Budget: &nbsp;</strong>
              <span>₹ 200,000</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Platform: &nbsp;</strong>
              <span>Instagram</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Gender: &nbsp;</strong>
              <span>Any</span>
            </div>
            <div className="col-12 col-sm-auto mb-2 mb-sm-0">
              <strong>Post Types: &nbsp;</strong>
              <span>Reel</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column flex-md-row">
              <strong>Category: &nbsp;</strong>
              <span> Movies, Music</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column flex-md-row flex-wrap">
              <strong className="mr-2 mb-2 mb-md-0">
                Influencer Type: &nbsp;
              </strong>
              <div className="d-flex flex-wrap">
                <div className="infl-type-card mb-2 mb-md-1">
                  <img alt="" src={macro} />

                  <div className="infl-type-card-content">
                    <strong>Macro </strong>
                    <span>(100K - 500K)</span>
                    <div className="infl-type-card-perc"></div>
                  </div>
                </div>
                <div className="infl-type-card mb-2 mb-md-1">
                  <img alt="" src={microplus} className="microplus" />

                  <div className="infl-type-card-content">
                    <strong>Micro Plus</strong>
                    <span>(50K - 100K)</span>
                    <div className="infl-type-card-perc"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row my-3">
          <div className="heading">
            <HeadingStyle title="List-2" className="mb-4 d-block title-w-bdr" />
          </div>
          <div className="action-list d-flex config-list ml-auto align-self-start mr-2 ">
            <a className="btn btn-link" href="#">
              <span className="action-list" id="config-add-list">
                Add to list
              </span>
            </a>
            <a
              className="btn btn-link"
              href="/campaign_simulator/send_brief_influencers?package=6539e9f04cad7a6612752f7a"
            >
              <span className="action-list">Send Brief</span>
            </a>
            <a
              className="btn btn-link"
              href="/campaign_simulator/package_influencers.csv?package_id=6539e9f04cad7a6612752f7a"
            >
              <span className="action-list">Download as PDF/CSV</span>
            </a>
            <div className="d-flex justify-content-end ml-auto">
              <div className="dropdown bootstrap-select dropdown-right">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">Sort by</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={sortby}
                    label="Sort by"
                    onChange={handleChange}
                    MenuProps={MenuPropsSelect}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Followers</MenuItem>
                    <MenuItem value={20}>Engagement</MenuItem>
                    <MenuItem value={30}>Reach</MenuItem>
                  </Select>
                </FormControl>
                {/* <select
                  className="selectpicker dropdown-right"
                  data-dropdown-align-right="true"
                  tabIndex={-98}
                >
                  <option value="">Sort by</option>
                  <option value="/campaign_simulator/package_influencers?package_id=6539e9f04cad7a6612752f7a&sort=followers">
                    Followers
                  </option>
                  <option value="/campaign_simulator/package_influencers?package_id=6539e9f04cad7a6612752f7a&sort=engagement">
                    Engagement
                  </option>
                  <option value="/campaign_simulator/package_influencers?package_id=6539e9f04cad7a6612752f7a&sort=reach">
                    Reach
                  </option>
                </select> */}
                {/* <button
                  type="button"
                  className="btn dropdown-toggle bs-placeholder btn-light"
                  data-toggle="dropdown"
                  role="button"
                  title="Sort by"
                >
                  <div className="filter-option">
                    <div className="filter-option-inner">
                      <div className="filter-option-inner-inner">Sort by</div>
                    </div>{" "}
                  </div>
                </button> */}
                <div
                  className="dropdown-menu dropdown-menu-right"
                  role="combobox"
                >
                  <div
                    className="inner show"
                    role="listbox"
                    aria-expanded="false"
                    tabIndex={-1}
                  >
                    <ul className="dropdown-menu inner show" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none hidden-btns-add">
            <span className="d-inline-flex align-self-center pr-2">
              <span className="back-arw bi bi-arrow-left-circle mr-2" />
              Add Influencers
            </span>
            <button className="btn btn-primary ml-auto" id="add_to_package">
              Add to List
            </button>
          </div>
          <div className="d-none hidden-btns-delete">
            <span className="d-inline-flex align-self-center pr-2">
              <span className="back-arw bi bi-arrow-left-circle mr-2" />
              Delete Influencers
            </span>
            <button
              className="btn btn-danger ml-auto"
              id="delete-influencer-price"
            >
              Delete
            </button>
          </div>
          <input
            type="hidden"
            name="package_id"
            id="package_id"
            defaultValue="6539e9f04cad7a6612752f7a"
          />
        </div>
        <div
          className="camcon_card_container ScrollStyle"
          id="influencers-container"
        >
          <div
            className="config_card d-flex flex-column justify-content-between add-list-configure"
            data-platform="instagram"
          >
            <div className="d-flex">
              <div className="recomm-ricon">
                <label className="checkboxcontainer">
                  <input
                    className="inf_price_checkbox"
                    data-platform="instagram"
                    id="6368adb1f589a61fddbb1192"
                    type="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="config_card_info">
                <div className="d-flex flex-row">
                  {/* <img class="influencer_type_img"src="celeb@3x.png"> */}
                  <img
                    className="influencer_type_img"
                    src="https://d2r6q8zmpbz70u.cloudfront.net/assets/macro-a7d04fce431d960a5855854ed339fe73aef791e99a242a8e966d63d3d9743b67.png"
                  />
                  <div className="d-flex flex-column config_card_influencer_img_handle">
                    {/* <img class="shape" src="insta.png"> */}
                    <img
                      className="shape"
                      src="https://d2r6q8zmpbz70u.cloudfront.net/assets/insta-fed9f47adb23b3cfc6f6980d51cd8d737c373e4400fc48c760643b972ac24047.png"
                    />
                    <span
                      className="handle-name"
                      data-placement="top"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="@rr_rahulravi"
                    >
                      <a
                        href="https://www.instagram.com/rr_rahulravi"
                        target="_blank"
                      >
                        @rr_rahulravi
                      </a>
                    </span>
                  </div>
                </div>
                <img
                  className="config_card_influencer_img"
                  src="https://clan-influencer-images.s3.ap-south-1.amazonaws.com/hype_auditors/instagram/hype_5f69faf5a288e60cc4def57e"
                />
              </div>
              <span
                className="infulencer-name"
                data-placement="top"
                data-toggle="tooltip"
                title=""
                data-original-title="RaHul RaVi 🇮🇳"
              >
                RaHul RaVi 🇮🇳
              </span>
              <div className="d-flex justify-content-right">
                <span>₹</span>
                <span>40755</span>
              </div>
              <div className="config_card_info" />
            </div>
            <div className="config_card_analytics d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <span className="followers-value">101.89 K</span>
                <label>Followers</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="engagement-rate-value">8.16</span>
                <label>ER</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="reach-rate-value">25 K</span>
                <label>Reach</label>
              </div>
            </div>
          </div>
          <div
            className="config_card d-flex flex-column justify-content-between add-list-configure"
            data-platform="instagram"
          >
            <div className="d-flex">
              <div className="recomm-ricon">
                <label className="checkboxcontainer">
                  <input
                    className="inf_price_checkbox"
                    data-platform="instagram"
                    id="6368adb1f589a61fddbb1192"
                    type="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="config_card_info">
                <div className="d-flex flex-row">
                  {/* <img class="influencer_type_img"src="celeb@3x.png"> */}
                  <img
                    className="influencer_type_img"
                    src="https://d2r6q8zmpbz70u.cloudfront.net/assets/macro-a7d04fce431d960a5855854ed339fe73aef791e99a242a8e966d63d3d9743b67.png"
                  />
                  <div className="d-flex flex-column config_card_influencer_img_handle">
                    {/* <img class="shape" src="insta.png"> */}
                    <img
                      className="shape"
                      src="https://d2r6q8zmpbz70u.cloudfront.net/assets/insta-fed9f47adb23b3cfc6f6980d51cd8d737c373e4400fc48c760643b972ac24047.png"
                    />
                    <span
                      className="handle-name"
                      data-placement="top"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="@rr_rahulravi"
                    >
                      <a
                        href="https://www.instagram.com/rr_rahulravi"
                        target="_blank"
                      >
                        @rr_rahulravi
                      </a>
                    </span>
                  </div>
                </div>
                <img
                  className="config_card_influencer_img"
                  src="https://clan-influencer-images.s3.ap-south-1.amazonaws.com/hype_auditors/instagram/hype_5f69faf5a288e60cc4def57e"
                />
              </div>
              <span
                className="infulencer-name"
                data-placement="top"
                data-toggle="tooltip"
                title=""
                data-original-title="RaHul RaVi 🇮🇳"
              >
                RaHul RaVi 🇮🇳
              </span>
              <div className="d-flex justify-content-right">
                <span>₹</span>
                <span>40755</span>
              </div>
              <div className="config_card_info" />
            </div>
            <div className="config_card_analytics d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <span className="followers-value">101.89 K</span>
                <label>Followers</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="engagement-rate-value">8.16</span>
                <label>ER</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="reach-rate-value">25 K</span>
                <label>Reach</label>
              </div>
            </div>
          </div>
          <div
            className="config_card d-flex flex-column justify-content-between add-list-configure"
            data-platform="instagram"
          >
            <div className="d-flex">
              <div className="recomm-ricon">
                <label className="checkboxcontainer">
                  <input
                    className="inf_price_checkbox"
                    data-platform="instagram"
                    id="6368adb1f589a61fddbb1192"
                    type="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="config_card_info">
                <div className="d-flex flex-row">
                  {/* <img class="influencer_type_img"src="celeb@3x.png"> */}
                  <img
                    className="influencer_type_img"
                    src="https://d2r6q8zmpbz70u.cloudfront.net/assets/macro-a7d04fce431d960a5855854ed339fe73aef791e99a242a8e966d63d3d9743b67.png"
                  />
                  <div className="d-flex flex-column config_card_influencer_img_handle">
                    {/* <img class="shape" src="insta.png"> */}
                    <img
                      className="shape"
                      src="https://d2r6q8zmpbz70u.cloudfront.net/assets/insta-fed9f47adb23b3cfc6f6980d51cd8d737c373e4400fc48c760643b972ac24047.png"
                    />
                    <span
                      className="handle-name"
                      data-placement="top"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="@rr_rahulravi"
                    >
                      <a
                        href="https://www.instagram.com/rr_rahulravi"
                        target="_blank"
                      >
                        @rr_rahulravi
                      </a>
                    </span>
                  </div>
                </div>
                <img
                  className="config_card_influencer_img"
                  src="https://clan-influencer-images.s3.ap-south-1.amazonaws.com/hype_auditors/instagram/hype_5f69faf5a288e60cc4def57e"
                />
              </div>
              <span
                className="infulencer-name"
                data-placement="top"
                data-toggle="tooltip"
                title=""
                data-original-title="RaHul RaVi 🇮🇳"
              >
                RaHul RaVi 🇮🇳
              </span>
              <div className="d-flex justify-content-right">
                <span>₹</span>
                <span>40755</span>
              </div>
              <div className="config_card_info" />
            </div>
            <div className="config_card_analytics d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <span className="followers-value">101.89 K</span>
                <label>Followers</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="engagement-rate-value">8.16</span>
                <label>ER</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="reach-rate-value">25 K</span>
                <label>Reach</label>
              </div>
            </div>
          </div>
          <div
            className="config_card d-flex flex-column justify-content-between add-list-configure"
            data-platform="instagram"
          >
            <div className="d-flex">
              <div className="recomm-ricon">
                <label className="checkboxcontainer">
                  <input
                    className="inf_price_checkbox"
                    data-platform="instagram"
                    id="6368adb1f589a61fddbb1192"
                    type="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="config_card_info">
                <div className="d-flex flex-row">
                  {/* <img class="influencer_type_img"src="celeb@3x.png"> */}
                  <img
                    className="influencer_type_img"
                    src="https://d2r6q8zmpbz70u.cloudfront.net/assets/macro-a7d04fce431d960a5855854ed339fe73aef791e99a242a8e966d63d3d9743b67.png"
                  />
                  <div className="d-flex flex-column config_card_influencer_img_handle">
                    {/* <img class="shape" src="insta.png"> */}
                    <img
                      className="shape"
                      src="https://d2r6q8zmpbz70u.cloudfront.net/assets/insta-fed9f47adb23b3cfc6f6980d51cd8d737c373e4400fc48c760643b972ac24047.png"
                    />
                    <span
                      className="handle-name"
                      data-placement="top"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="@rr_rahulravi"
                    >
                      <a
                        href="https://www.instagram.com/rr_rahulravi"
                        target="_blank"
                      >
                        @rr_rahulravi
                      </a>
                    </span>
                  </div>
                </div>
                <img
                  className="config_card_influencer_img"
                  src="https://clan-influencer-images.s3.ap-south-1.amazonaws.com/hype_auditors/instagram/hype_5f69faf5a288e60cc4def57e"
                />
              </div>
              <span
                className="infulencer-name"
                data-placement="top"
                data-toggle="tooltip"
                title=""
                data-original-title="RaHul RaVi 🇮🇳"
              >
                RaHul RaVi 🇮🇳
              </span>
              <div className="d-flex justify-content-right">
                <span>₹</span>
                <span>40755</span>
              </div>
              <div className="config_card_info" />
            </div>
            <div className="config_card_analytics d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <span className="followers-value">101.89 K</span>
                <label>Followers</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="engagement-rate-value">8.16</span>
                <label>ER</label>
              </div>
              <div className="vl-card" />
              <div className="d-flex flex-column">
                <span className="reach-rate-value">25 K</span>
                <label>Reach</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="camcon_info_container" id=" camcon_con_info_main">
        <div className="tab-sec">
          <div className="tab-btns">
            <button className="tablinks active" id="defaultOpen">
              Overview
            </button>
            <button className="tablinks">Action</button>
          </div>
          <div
            className="influencers-summary-tab-content-sec"
            id="influencers-summary"
          >
            <div
              className="tabcontent influencers-summary-tab-content p-3"
              id="Overview"
              style={{ display: "block" }}
            >
              <div className="d-flex flex-column">
                <div className="total-influencer">
                  <div className="total-influencer-row1 d-flex flex-row justify-content-between">
                    <label>Total Influencers</label>
                    <span className="total-influencer-value">5</span>
                  </div>
                  <div className="total-influencer-row2 d-flex flex-wrap">
                    <div className="conf-list-detail">
                      <div className="conf-list-detail-left">
                        {/* Item 1 */}
                        {/* Item 2 */}
                        {/* Item 3 */}
                        <div className="infl-type-card">
                          <img alt="" src={macro} />
                          <div className="infl-type-card-content">
                            <strong>Macro</strong>
                            <span>(100K - 500K)</span>
                            <div className="infl-type-card-perc">1</div>
                          </div>
                        </div>
                        <div className="infl-type-card">
                          <img alt="" src={microplus} />
                          <div className="infl-type-card-content">
                            <strong>Micro Plus</strong>
                            <span>(50K - 100K)</span>
                            <div className="infl-type-card-perc">4</div>
                          </div>
                        </div>
                        {/* Item 4 */}
                        {/* Item 5 */}
                      </div>
                      <div className="conf-list-detail-right">
                        <div className="higchartmain" ref={chartRef}></div>
                        <div className="conf-list-detail-list">
                          <div className="infl-type-card">
                            <div className="infl-type-card-content">
                              <strong>Macro</strong>
                              <div className="infl-type-card-perc">
                                ₹ 40755 | 20.0 %
                              </div>
                            </div>
                          </div>
                          <div className="infl-type-card">
                            <div className="infl-type-card-content">
                              <strong>Micro Plus</strong>
                              <div className="infl-type-card-perc">
                                ₹ 156425 | 80.0 %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="campaign_confo_hr" />
                <div className="campaignConfo-common d-flex flex-row justify-content-between">
                  <label>Total Budget</label>
                  <span className="total-budget-value">₹197,180</span>
                </div>
                <hr className="campaign_confo_hr" />
                <div className="progressBar">
                  <div className="campaignConfo-common d-flex flex-row justify-content-between">
                    <label>Total Followers</label>
                    <span className="total-budget-value">492,949</span>
                  </div>
                  <div
                    className="campaignConfo-common d-flex flex-row justify-content-between"
                    style={{ paddingTop: 0 }}
                  >
                    <label>Estimated Reach</label>
                    {/* %span.total-budget-value= total_estimated_reach(@influencers, @package.simulator_campaign) */}
                    <span className="total-budget-value">185,000</span>
                  </div>
                  <div
                    id="estimated-reach-chart"
                    data-highcharts-chart={2}
                    style={{ overflow: "hidden" }}
                  >
                    <div id="container1"></div>
                  </div>
                  <div
                    className="campaignConfo-common d-flex flex-row justify-content-between"
                    style={{ paddingTop: 0 }}
                  >
                    <label>Cost Per Reach</label>
                    <span className="total-budget-value">₹ 1.07</span>
                  </div>
                </div>
                <hr className="campaign_confo_hr" />
                <div className="analysis-rate d-flex flex-column justify-content-between">
                  <div className="d-flex flex-row justify-content-between">
                    <label>Engagement Rate</label>
                    <span className="total-budget-value">3.26%</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <label>Total Views</label>
                    <span className="total-budget-value">0</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <label>Total Likes</label>
                    <span className="total-budget-value">13,984,442</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <label>Total Comments</label>
                    <span className="total-budget-value">234,758</span>
                  </div>
                </div>
                <hr className="campaign_confo_hr" />
                <div className="common-campco d-flex flex-row justify-content-between">
                  <label>Age Gender</label>
                  <span className="total-budget-value" />
                </div>

                <div
                  id="age-gender-chart"
                  data-highcharts-chart={1}
                  style={{ overflow: "hidden" }}
                >
                  <div id="container2"></div>
                </div>
                <hr className="campaign_confo_hr" />
                <div className="progress-rate">
                  <div className="common-campco d-flex flex-row justify-content-between">
                    <label>Top Cities</label>
                    <span className="total-budget-value" />
                  </div>
                  <div>
                    <div
                      className="progress-section"
                      data-aos="fade-left"
                      data-aos-once="true"
                    >
                      <div className="task-progress">
                        <div className="city">
                          <p>Delhi</p>
                        </div>

                        <div className="percentage">
                          <span>{progress.Delhi}%</span>
                          <progress
                            className="progress progress1"
                            max="100"
                            value={progress.Delhi}
                          ></progress>
                        </div>
                      </div>

                      <div className="task-progress">
                        <div className="city">
                          <p>Mumbai</p>
                        </div>

                        <div className="percentage">
                          <span>{progress.Mumbai}%</span>
                          <progress
                            className="progress progress2"
                            max="100"
                            value={progress.Mumbai}
                          ></progress>
                        </div>
                      </div>

                      <div className="task-progress">
                        <div className="city">
                          <p>Trichur</p>
                        </div>

                        <div className="percentage">
                          <span>{progress.Trichur}%</span>
                          <progress
                            className="progress progress1"
                            max="100"
                            value={progress.Trichur}
                          ></progress>
                        </div>
                      </div>

                      <div className="task-progress">
                        <div className="city">
                          <p>Bangalore</p>
                        </div>

                        <div className="percentage">
                          <span>{progress.Bangalore}%</span>
                          <progress
                            className="progress progress1"
                            max="100"
                            value={progress.Bangalore}
                          ></progress>
                        </div>
                      </div>

                      <div className="task-progress">
                        <div className="city">
                          <p>Jaipur</p>
                        </div>

                        <div className="percentage">
                          <span>{progress.Jaipur}%</span>
                          <progress
                            className="progress progress5"
                            max="100"
                            value={progress.Jaipur}
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="action-list d-flex flex-wrap">
                  <a href="#">
                    <span className="action-list" id="config-add-list">
                      Add to list
                    </span>
                  </a>
                  <a href="/campaign_simulator/send_brief_influencers?package=6539e9f04cad7a6612752f7a">
                    <span className="action-list">Send Brief</span>
                  </a>
                  <a href="/campaign_simulator/package_influencers.csv?package_id=6539e9f04cad7a6612752f7a">
                    <span className="action-list">Download as PDF/CSV</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="tabcontent influencers-summary-tab-content p-3"
              id="Action"
              style={{ display: "none" }}
            >
              <div className="action-list d-flex flex-column">
                <a href="#">
                  <span className="action-list" id="config-add-list">
                    Add to list
                  </span>
                </a>
                <a href="/campaign_simulator/send_brief_influencers?package=6539e9f04cad7a6612752f7a">
                  <span className="action-list">Send Brief</span>
                </a>
                <a href="/campaign_simulator/package_influencers.csv?package_id=6539e9f04cad7a6612752f7a">
                  <span className="action-list">Download as PDF/CSV</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
