import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import {
  GetUploadedURL,
  DeleteUploadURL,
  AddUploadURL,
  UpdateUploadURL,
  ResponseType,
} from "custom-type";

const http = new HttpService();

export class PaymentComponentService {
  async getBarterCampaignInvoice(data: GetUploadedURL): Promise<ResponseType> {
    const path = BASE_URL + "/get/campaign/barter/invoice";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
}
