import React from "react";
import { ErrorPage } from "../components/error-page/error-page";

interface Props {
    children: React.ReactNode
}


export class ErrorBoundary extends React.Component<Props> {
    state: { hasError: boolean };
    errorInfo: any;
    static error: any;


    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {

        // Update state so the next render will show the fallback UI.    return { hasError: true };
        return { hasError: true };

    }

    componentDidCatch(error: any, errorInfo: any) {
        // store the log if application is crashed.......

    }


    render() {
        if (this.state.hasError) {
            return <ErrorPage/>
        }
        return this.props.children;
    }

}