import React from 'react';

export const PrivacyPolicy = () => {
    return (
        <main className='main-content-sec padd-content-top110 full-width'>
            <div className='container-fluid'>
                <div className='bg-white content-bg p-3 min-vh-100'>
                    <div className='container custom-container'>
                        <h3 className='title-w-bdr'>
                            <span>Privacy Policy</span>
                        </h3>
                        <div className='content-info'>
                            <ol>
                                <li>
                                    <strong>General</strong>
                                    <ol>
                                        <li>
                                            We ( defined below ) are committed to protecting your
                                            privacy and granting importance to the confidentiality of
                                            your data over internet. This privacy policy{' '}
                                            <strong>(“ Privacy Policy ”)</strong> read with the Terms
                                            of Use available at our website www.clanconnect.ai{' '}
                                            <strong>(“ Platform ”)</strong> applies to all services
                                            offered to persons using or visiting{' '}
                                            <strong>(“ Users ”, “ You ” or “ Your ”)</strong> the
                                            Platform .{' '}
                                        </li>
                                        <li>
                                            Clanconnect is owned and operated by Irida Interactive
                                            Private Limited having its registered office at 93, 3rd
                                            Floor, Okhla Phase III Industrial Estate, New Delhi 110020
                                            and bearing CIN: U72900DL2019PTC358765, unless otherwise
                                            noted in this Privacy Policy, the terms
                                            <strong>
                                                " Clanconnect ", " we ", “ our ” and " us "
                                            </strong>{' '}
                                            refer to Irida Interactive Private Limited{' '}
                                            <strong>(“ Company ”, “ We ”, “ Our ” or “ Us ”)</strong>.
                                            We believe it is important for You to understand what
                                            information We collect from You, how it is used and who
                                            will have access to that information. This Privacy Policy
                                            sets forth the policy in relation to the collection and
                                            usage of personal information that you may provide to Us
                                            while using Our Platform and related services
                                            (collectively <strong>“ Service ”</strong>).
                                        </li>
                                        <li>
                                            By visiting or using Our Platform, domain name and any
                                            other linked pages, features, content, or any other
                                            services, We offer from time to time by in connection
                                            therewith, or by using the Services in any manner, You
                                            acknowledge that You accept the practices and policies
                                            outlined in this Privacy Policy, and You hereby consent to
                                            Our collection, use and disclosure of Personal Information
                                            ( as defined below ) in accordance with this Privacy
                                            Policy. If You are not agreeable to this, please do not
                                            use this Platform or Services provided by Us.{' '}
                                        </li>
                                        <li>
                                            If You are under 18 (eighteen) years of age, You must not
                                            use this Platform or provide personal data unless You have
                                            the consent of and are supervised by a parent or guardian.
                                        </li>
                                        <li>
                                            We collect, use, share, disclose and protect Your personal
                                            data provided while using the Services available on the
                                            Platform. The data collected is used only to enhance the
                                            operation of the Platform, allowing You to use all the
                                            listed features and allowing Us to respond to Your
                                            requests/queries in an efficient manner. The Personal
                                            Information is used strictly in line with Our business
                                            purposes and to provide You with useful features.{' '}
                                        </li>
                                        <li>
                                            In this Privacy Policy, “Personal Information” shall mean
                                            information through which a User can be identified,
                                            directly or indirectly, in particular by reference to
                                            User, their name, email address, phone number, username,
                                            password, billing address, Internet Protocol{' '}
                                            <strong>(“IP”)</strong> address as well as any information
                                            associated with the foregoing{' '}
                                            <strong>(“Personal Information”)</strong>. Personal
                                            Information does not include anonymized information that
                                            has been appropriately processed to make it
                                            non-identifiable in order to irreversibly prevent its
                                            identification.
                                        </li>
                                        <li>
                                            We respect the privacy of Our Users and are committed to
                                            protect it in all respects. The information about the User
                                            is collected by Us as (i) information supplied by Users;
                                            (ii) information automatically tracked during User's
                                            navigation on the Platform; and/ or (iii) communicated
                                            directly to Us via e-mail or telephone or another channel;
                                            (iv) information that is easily and readily available on
                                            the public domain/ social media accounts of the User.{' '}
                                        </li>
                                        <li>
                                            User acknowledges that this Privacy Policy, together with
                                            Terms of Use, forms Our agreement with the User in
                                            relation to his or her use of the Platform. Any
                                            capitalized term used but not defined in this Privacy
                                            Policy shall have the meaning attributed to it in Our
                                            Terms of Use.
                                        </li>
                                        <li>
                                            For any service that the User may use, We only collect the
                                            data that is strictly necessary (i) for the delivery of
                                            the Services and also the information that is easily and
                                            readily available on the social media accounts of the
                                            User, (ii) for the performance of a contract for which
                                            User is a party, (iii) for which User has given consent
                                            for such processing of data, or (iv) for compliance with a
                                            legal obligation to which We are subject.
                                        </li>
                                        <li>
                                            By using the Platform or by otherwise giving Us Your
                                            Personal Information, You will be deemed to have read,
                                            understood and agreed to the practices and policies
                                            outlined in this Privacy Policy and agree to be bound by
                                            the Privacy Policy. You hereby consent to Our collection,
                                            use and sharing, disclosure of Your Personal Information
                                            as described in this Privacy Policy. We reserve the right
                                            to change, modify, add or delete portions of the terms of
                                            this Privacy Policy, at Our sole discretion, at any time.{' '}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Information Collected</strong>
                                    <ol>
                                        <li>
                                            In the course of providing Services to You through the
                                            Platform, We may collect the following:
                                            <ol>
                                                <li>
                                                    Personal Identifiable Information such as your name,
                                                    phone number, profile picture, username, password,
                                                    e-mail address when You register on the Platform.
                                                </li>
                                                <li>
                                                    Information provided by You on your social media
                                                    account, which will be linked with the account created
                                                    by You on Our Platform, information disclosed therein
                                                    including but not limited to username as provided by
                                                    You at the time of sign-up, and publicly available
                                                    data of those social media accounts such as any posts,
                                                    comments, likes posted on such accounts, any personal
                                                    information provided in Your private profile of such
                                                    accounts and other information that may be required or
                                                    be related to rendering the Services in an efficient
                                                    and timely manner.
                                                </li>
                                                <li>
                                                    With Your permission, We may automatically collect
                                                    some information about Your hardware devices when You
                                                    access the Platform. For example, when You utilize Our
                                                    Platform, We may collect Your IP address and the type
                                                    of mobile device/computer/laptop You use and Your
                                                    geographic location. Most devices allow You to prevent
                                                    location data from being sent to Us and We honor Your
                                                    settings. We may also collect information about Your
                                                    activity on the Platform, such as information about
                                                    Your account usage, as well as information about how
                                                    You interact with the Platform (collectively,{' '}
                                                    <strong>“User Data”</strong>). We may combine this
                                                    automatically gathered information with other
                                                    information, including Personal Information we have
                                                    collected about You.
                                                </li>
                                                <li>
                                                    If you permit cookies on Your browser, We may receive,
                                                    and store certain types of information known as
                                                    “cookies” when You access the Platform.
                                                </li>
                                                <li>Communications between You and the Company.</li>
                                            </ol>
                                        </li>
                                        <li>
                                            The information collected from You by the Company may
                                            constitute ‘personal information’ or ‘sensitive personal
                                            data or information’ as defined under the Information
                                            Technology (Reasonable Security Practices and Procedures
                                            and Sensitive Personal Information) Rules, 2011 (“
                                            <strong>SPI Rules</strong>
                                            ”). The SPI Rules define “
                                            <strong>Sensitive Personal Data or Information</strong>”
                                            of a person to mean personal information about that person
                                            relating to:
                                            <ol>
                                                <li>passwords;</li>
                                                <li>
                                                    financial information such as bank accounts, credit
                                                    and debit card details or other payment instrument
                                                    details;
                                                </li>
                                                <li>
                                                    physical, physiological and mental health condition;
                                                </li>
                                                <li>sexual orientation;</li>
                                                <li>medical records and history;</li>
                                                <li>biometric information;</li>
                                                <li>
                                                    information received by body corporate under lawful
                                                    contract or otherwise; and
                                                </li>
                                                <li>
                                                    visitor details as provided at the time of
                                                    registration or thereafter.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Company will be free to use, collect and disclose
                                            information that is freely available in the public domain
                                            without Your consent.
                                        </li>
                                        <li>
                                            In order to provide Services through the Platform, We may
                                            require the User to provide Us with certain information
                                            that personally identifies himself. Personal Information
                                            includes the following categories of information:
                                            <ol>
                                                <li>
                                                    Contact data (such as email address, phone number and
                                                    call recording); and
                                                </li>
                                                <li>
                                                    Demographic data (such as time zone, postal address
                                                    and location details); and
                                                </li>
                                                <li>
                                                    If the User communicates with Us, for example, via
                                                    e-mail, registers with Us, any information provided in
                                                    such communication may be collected by Us.
                                                </li>
                                                <li>
                                                    Financial data (such as account details, e-wallet
                                                    details, bank account or credit or debit card details
                                                    or other payment instrument details etc.) that User
                                                    may have provided Us while using the Services provided
                                                    by the Platform.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            When You make Your profile on Our Platform, You can share
                                            with Us additional information, such as details of Your
                                            personality, lifestyle, interests and other details about
                                            You. By choosing to provide this information, You consent
                                            to Our processing of that information.
                                        </li>
                                        <li>
                                            If You decide to avail Our services on the Platform, We
                                            collect some additional information in order to complete
                                            the transaction. We collect information such as billing
                                            address, tracking information or necessary payment details
                                            to process the payments. However, We do not store any
                                            information related to credit card/debit card, CVV Number,
                                            expiry date or 3D secure password, online banking username
                                            or password. We receive and store limited information
                                            including the transaction ID, time, name and other details
                                            entered by You on the website of the payment gateway
                                            necessary to identify and verify the payments made by You.
                                            Whatever Your chosen online mode of payment, Our payment
                                            gateway adheres to the standards set by PCI-DSS as managed
                                            by the PCI Security Standards Council. PCI-DSS
                                            requirements helps ensure the secure handling of credit
                                            card information by Our Platform and its service
                                            providers.
                                        </li>
                                        <li>
                                            All such information is stored or used for processing
                                            Services. However, We may use this information to share
                                            with You additional and/ or upcoming information regarding
                                            the Services offered by the Platform.
                                        </li>
                                        <li>
                                            The Platform may transmit User Data to Amazon web server,
                                            to ensure secure storage of data collected by Us. Thereby
                                            the User is also subject to the terms of Amazon web
                                            server’s policy. The Personal Information is immediately
                                            deleted once User deletes his account exclusively made on
                                            the Platform, except to the extent it is necessary to
                                            store the same under applicable laws. Further, We have
                                            implemented commercially reasonable physical, managerial,
                                            operational and technical security measures to protect the
                                            loss, misuse and alteration and to preserve the security
                                            of the Personally Identifiable Information in Our care.
                                        </li>
                                        <li>
                                            Our Platform may contain links to third party websites or
                                            applications. The User agrees and understands that privacy
                                            policies of these websites are not under Our control and
                                            the User understands that once he or she leaves Our
                                            servers, use of any information provided by the User shall
                                            be governed by the privacy policy of the operator of the
                                            site used by them.
                                        </li>
                                        <li>
                                            Prior to any direct marketing or commercial operation (for
                                            example for sending the newsletters or commercial offers),
                                            We will first obtain User’s consent, either when creating
                                            account on the Platform or later by indicating Your choice
                                            in account settings. User will always have the right to
                                            withdraw his or her consent at any time, directly by
                                            clicking on the unsubscribe link available on each email
                                            received, by modifying his account settings, or by
                                            contacting Us at the following email address
                                            legal@clanconnect.ai.{' '}
                                        </li>
                                        <li>
                                            {' '}
                                            In accordance with the applicable laws, User has the right
                                            to access, to rectify and to object data concerning them.
                                            User can access and change their profile picture, display
                                            name and User profile password provided when account is
                                            created through the account page or by contacting Us.
                                        </li>
                                        <li>
                                            We may disclose any information We may deem necessary, in
                                            Our sole discretion, to comply with any applicable law,
                                            regulation, legal process or governmental request, in
                                            order to investigate, prevent or take action regarding
                                            illegal activities, suspected fraud, situations involving
                                            potential threats to the physical safety of any personal
                                            or as otherwise required by law. Such transfer or
                                            disclosure shall be for lawful purposes only. Further,
                                            such transfer or disclosure of Your sensitive personal
                                            information to other third parties shall only be done once
                                            it is ensured that the third party receiving such
                                            information has implemented the same level of security
                                            practices as we have.
                                        </li>
                                        <li>
                                            We take Your right to privacy very seriously and other
                                            than as specifically stated in this Privacy Policy, will
                                            only disclose Your Personal Information in the event it is
                                            required to do so by applicable law, rule, regulation, law
                                            enforcement agency, governmental official, legal authority
                                            or similar requirements or when Company, in its sole
                                            discretion, deems it necessary in order to protect its
                                            rights or the rights of others, to prevent harm to persons
                                            or property, to fight fraud and credit risk, or to enforce
                                            or apply the Terms of Use.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>USE OF PERSONAL INFORMATION</strong>
                                    <ol>
                                        <li>
                                            We (and third-party data processors, vendors and hosting
                                            partners who may be acting on Our behalf) may collect,
                                            store and process Your personal data/ information for the
                                            following reasons:
                                            <ol>
                                                <li>
                                                    to make this Platform customized, to provide content
                                                    tailored to individual requirements; to understand
                                                    Your needs and provide You with improved products and
                                                    Services, newsletters and other communications and to
                                                    enable others to view submissions/posts and blogs of
                                                    Users;
                                                </li>
                                                <li>
                                                    to maintain any registered accounts that You may hold
                                                    with Us and to provide You with updates or
                                                    notifications of changes to Your account;
                                                </li>
                                                <li>
                                                    for Our internal record keeping requirements including
                                                    collection of anonymized details about visitors to Our
                                                    website to compile aggregate statistics or produce
                                                    internal reports;
                                                </li>
                                                <li>
                                                    with Your agreement, to contact You about Our or Our
                                                    business partners' promotions, products and Services
                                                    or other information which We think You may find
                                                    interesting, offers, or for customer satisfaction
                                                    purposes;
                                                </li>
                                                <li>
                                                    for market research, analysis, testing, monitoring,
                                                    risk management and administrative purposes (including
                                                    diagnosing technology problems which may be reported
                                                    to Us); and
                                                </li>
                                                <li>
                                                    for any purposes related to the above or where We have
                                                    a legal right or duty to use or disclose Your personal
                                                    data/ information (including for abuse, crime and
                                                    fraud prevention and related purposes).
                                                </li>
                                                <li>
                                                    To better understand how Users access and use Our
                                                    Service, both on an aggregated and individualized
                                                    basis;
                                                </li>
                                                <li>
                                                    User posted content may be reused by Us to aggregate
                                                    data about a particular activity or location and
                                                    include it in consolidated formats.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            If We become aware that We have collected or been sent
                                            personal data/ information from a person who is under 18
                                            (eighteen) years of age, without the consent of the parent
                                            or guardian, We will deactivate the relevant account as
                                            soon as is reasonably practicable by Us.
                                        </li>
                                        <li>
                                            Your personal information may be disclosed as part of any
                                            merger, sale of company assets or acquisition, as well as
                                            in the event of insolvency, bankruptcy or receivership, in
                                            which personal information would be transferred as one of
                                            the business assets of the Company.
                                        </li>
                                        <li>
                                            Further, in an ongoing effort to better understand and
                                            serve the users of our Service, we also often conduct
                                            research on its customer demographics, interests and
                                            behavior based on the personal information and other
                                            information provided to Us. This research may be compiled
                                            and analyzed on an aggregate basis, and We may share this
                                            aggregate data with Our affiliates, agents, advertisers
                                            and business partners. This aggregate information does not
                                            identify You personally. We may also disclose aggregated
                                            User statistics in order to describe Our Service to
                                            current and prospective business partners and to other
                                            third parties for other lawful purposes (including
                                            providing advertising use cases to actual or potential
                                            advertisers).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>USAGE OF COOKIES</strong>
                                    <ol>
                                        <li>
                                            A cookie is a small piece of text file stored by the
                                            website on a user’s computer hard disk, when the website
                                            is opened. In some instances, We and Our service providers
                                            use cookies and other technology to automatically collect
                                            certain types of information when You visit Our Platform,
                                            as well as through emails that We may exchange. The
                                            collection of this information allows Us to customize Your
                                            online experience, improve the performance, usability and
                                            effectiveness of Our online presence, and to measure
                                            effectiveness of our marketing strategies. If You chose to
                                            disable the cookies, you might not be able ot fully
                                            experience the interactive features of this website.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>YouTube</strong>
                                    <ol>
                                        <li>
                                            We hereby inform all Users that We use YouTube API
                                            Services, and the Users are thereby bound by the policies
                                            framed by YouTube. Further, all Users including
                                            Influencers, Brands or agents, are agreeing to be bound by
                                            Google’s Privacy Policy. To view Google’s Privacy Policy,
                                            go to{' '}
                                            <a
                                                href='https://policies.google.com/privacy'
                                                target='_blank'
                                            >
                                                https://policies.google.com/privacy
                                            </a>
                                            .{' '}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Security</strong>
                                    <ol>
                                        <li>
                                            The security of User Data and Personal Information is
                                            important to Us. We follow generally accepted industry
                                            standards to protect the User Data and Personal
                                            Information submitted to Us, both during transmission and
                                            once We receive it. All information gathered on Our
                                            Platform is securely stored on the Amazon web server. The
                                            database is stored on servers secured behind a firewall;
                                            access to the servers is password-protected and is
                                            strictly limited. Such information may also be converted
                                            to physical form time to time, if deemed necessary. We
                                            take all necessary precautions to protect Your Personal
                                            Information both online and off-line, and implement
                                            reasonable security practices and measures including
                                            certain managerial, technical, operational and physical
                                            security control measures that are commensurate with
                                            respect to the information being collected and the nature
                                            of Our business.
                                        </li>
                                        <li>
                                            Although We make best possible efforts to store Personal
                                            Information in a secure operating environment that is not
                                            open to the public, User must understand that there is no
                                            such thing as complete security, and We do not guarantee
                                            that there will be no unintended disclosures of Personal
                                            Information. If We become aware that Users Personal
                                            Information has been disclosed in a manner not in
                                            accordance with this Privacy Policy, We will use
                                            reasonable efforts to notify such User of the nature and
                                            extent of such disclosure as soon as reasonably possible
                                            and as permitted by law.
                                        </li>
                                        <li>
                                            For security from Your part, You should:
                                            <ol>
                                                <li>
                                                    if using a computer in a public location, always log
                                                    out and close the website browser when You complete
                                                    Your online session;
                                                </li>
                                                <li>
                                                    keep Your account details and passwords private;
                                                </li>
                                                <li>
                                                    keep Your account details and passwords private;
                                                </li>
                                                <li>
                                                    avoid using the same password for multiple online
                                                    accounts.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Depending on the processing of Personal Information, the
                                            data may be subject to a different storage period.
                                        </li>
                                        <li>
                                            We do not sell Users Personal Information to any
                                            advertisers or any third party. We use Users Personal Data
                                            to display relevant advertisements, based on his
                                            preferences. We make reports related to the advertisement
                                            campaigns, in particular statistics, without any reference
                                            or information related to the personal information of
                                            visitors or Users of the Platform.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Change to Privacy Policy</strong>
                                    <ol>
                                        <li>
                                            Company may update this Privacy Policy at any time, with
                                            or without advance notice. In the event there are
                                            significant changes in the way Company treats User’s
                                            personally identifiable information, or in the Privacy
                                            Policy itself, Company will display a notice on the
                                            Platform or send Users an email, as provided for above, so
                                            that You may review the changed terms prior to continuing
                                            to use the services.{' '}
                                        </li>
                                        <li>
                                            If You object to any of the changes to Our Privacy Policy,
                                            and You no longer wish to avail the services, You may
                                            contact Our Grievance Officer Mr. Kunal Kishore having{' '}
                                            <a href='mailto:kunal@clanconnect.ai'>
                                                kunal@clanconnect.ai
                                            </a>{' '}
                                            to deactivate Your account and for any other issues
                                            related to this Privacy Policy. Unless stated otherwise,
                                            Company’s current Privacy Policy applies to all
                                            information that the Company has about You and Your
                                            account.
                                        </li>
                                        <li>
                                            If a User uses the Platform, after a notice of changes
                                            published on the Website, such User hereby provides
                                            his/her/its consent to the changed terms.
                                            <br />
                                            This Privacy Policy is an electronic record in the form of
                                            an electronic contract formed under the Information
                                            Technology Act, 2000 and the rules made thereunder and the
                                            amended provisions pertaining to electronic documents/
                                            records in various statutes as amended by the Information
                                            Technology Act, 2000. This Privacy Policy does not require
                                            any physical, electronic or digital signature and is a
                                            legally binding document between the User and Us.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
