interface Props {
  className?: string;
}
const IconAnalytics: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.8125 24.0416V22.2563L1.97596 21.0576V24.0416H0.8125ZM6.3742 24.0416V16.5896L7.53766 15.3909V24.0416H6.3742ZM11.9183 24.0416V15.3909L13.0817 16.625V24.0416H11.9183ZM17.48 24.0416V16.625L18.6434 15.4263V24.0416H17.48ZM23.024 24.0416V10.923L24.1875 9.72425V24.0416H23.024ZM0.8125 15.3809V13.7109L9.75 4.51343L15.25 10.1801L24.1875 0.966309V2.65451L15.25 11.8683L9.75 6.20163L0.8125 15.3809Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconAnalytics;






