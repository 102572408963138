import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class AdminDashboardService {

  async getAdminDashData(param: any) {
    try {
      const path = BASE_URL + `/admin-dashboard/signup?value1=${param.value1}&value2=${param.value2}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Admin Dashboard Data :: ${error}`);
    }
  }

  async getAdminDashFilter(data: any) {
    try {
      const path = BASE_URL + `/admin-dashboard/signup/filter`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Admin Dashboard Data :: ${error}`);
    }
  }

  // To fetch admin dashboard in-review campaign details
  async getReviewCampaigns() {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/review-campaigns`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getReviewCampaigns :: ${error}`);
    }
  }

  // To update admin dashboard in-review campaign details
  async updateReviewCampaigns(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/review-campaigns/update`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`getReviewCampaigns :: ${error}`);
    }
  }

  async getTalentInfluencers() {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/talent-influencers`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getTalentInfluencers :: ${error}`);
    }
  }


  // To update admin dashboard in-review influencer details
  async updateReviewInfluencer(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/talent-influencers/update`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`getReviewInfluencer :: ${error}`);
    }
  }

  // To fetch admin dashboard userbase brief
  async getAdminDashUserbaseBrief(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/userbase/briefs?fromDate=${data.start_date}&toDate=${data.end_date}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Admin Dashboard userbase Data :: ${error}`);
    }
  }

  // To fetch admin dashboard userbase
  async getAdminDashUserbase(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/userbase?fromDate=${data.start_date}&toDate=${data.end_date}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Admin Dashboard userbase Data :: ${error}`);
    }
  }

  // To fetch admin dashboard platform campaign details
  async getAdminDashCampaignsData(param: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/platform/campaign-details?start_date=${param.start_date}&end_date=${param.end_date}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Admin Dashboard Data :: ${error}`);
    }
  }


  // To fetch admin dashboard platform disconnect feedbacks
  async getAdminDashDisconnectData(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/platform/disconnect-feedbacks?start_date=${data.start_date}&end_date=${data.end_date}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Admin Dashboard Data :: ${error}`);
    }
  }


  // Get the user details
  async getUserDetail(loginEmailMobile: any) {
    try {
      const path = BASE_URL + `/get-user/details?login_email_mobile=${loginEmailMobile}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getUserDetail :: ${error}`);
    }
  }

   // Get agency brands
   async getAgencyBrand() {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/agency_brand`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getUserDetail :: ${error}`);
    }
  }

  async handlePremium(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/brand_premium`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`getUserDetail :: ${error}`);
    }
  }

  /*...........................  Delete the user.............................*/

  async deleteInfluencer(data: any) {
    try {
      const path = BASE_URL + `/temp/delete/influencer`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`deleteInfluencer :: ${error}`);
    }
  }

  async deleteBrand(data: any) {
    try {
      const path = BASE_URL + `/temp/delete/brand`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`deleteBrand :: ${error}`);
    }
  }

  async deleteAgency(data: any) {
    try {
      const path = BASE_URL + `/temp/delete/agency`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`deleteAgency :: ${error}`);
    }
  }

  async deleteTalentPartner(data: any) {
    try {
      const path = BASE_URL + `/temp/delete/tp`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`deleteTalentPartner :: ${error}`);
    }
  }

  
  // Get campaign Details Admin Dashboard
  async getCampaignDetail(campaignId: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/campaigns?campaign_id=${campaignId}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getCampaignDetail :: ${error}`);
    }
  }

  async updateCampaignDetail(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/campaigns/update`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateCampaignDetail :: ${error}`);
    }
  }

  async addExternalInfluencer(data: any) {
    try {
      const path = BASE_URL + `/brand/admin-dashboard/influencer/add/external`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`addExternalInfluencer :: ${error}`);
    }
  }

  async getExternalInfluencerInstagram() {
    try {
      const path = BASE_URL + '/brand/admin-dashboard/influencer/instagram';
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getExternalInfluencerInstagram :: ${error}`);
    }
  }

  async getExternalInfluencerYoutube() {
    try {
      const path = BASE_URL + '/brand/admin-dashboard/influencer/youtube';
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getExternalInfluencerInstagram :: ${error}`);
    }
  }

}
