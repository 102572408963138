import React from "react";
import "./heading-style.scss";

type Props = {
    children?: any;
};

export default function HeadingStyle(props: {
    title: string;
    className?: string;
    children?: any;
}) {
    return (
        <>
            <span className={["title-w-bdr", props.className].join(" ")}>
                <span>{props.title}</span>
            </span>
            {props.children}
        </>
    );
}
