import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableSortLabel,
} from '@mui/material'; import { DefaultUserProfile } from '../../../config/config';
import { Link } from 'react-router-dom';
import { Methods } from '../../../core/utility';

const methods = new Methods();

interface Props {
  data?: any;
  type?: any;
  handleProfileView: (event: any, platform_type: string, sm_id: any) => void;
}


const TopInfluencer: React.FC<Props> = (props: Props) => {
  const influencer_header_data = new Array(
    {
      type: 'Name',
    },
    {
      type: props?.type === 'Instagram' ? 'Followers' : 'Subscribers',
    },
    {
      type: 'ER',
    },
    {
      type: 'Category',
    },
    {
      type: 'Location',
    },
    {
      type: 'Campaigns',
    },
    {
      type: 'Total Amount',
    }
  );



  return (
    <div className='tp-info mb-5'>
      <TableContainer className='common-table'>
        <Table>
          <TableHead className='common-table-header'>
            <TableRow className='tp_influencers'>
              {influencer_header_data?.map((cell: any, index: number) => (
                <TableCell key={index}>
                  <TableSortLabel>
                    {cell.type}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className='common-table-body'>
            {props.data && props.data.length > 0 ? (
              props.data.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell component="td" className='table_cell'>
                    <div className='imageNameDiv'>
                      <div className='imageDiv'>
                        <img
                          src={props?.type === 'Youtube' ? (item?.profile_image_url ? item?.profile_image_url : DefaultUserProfile?.influencer_unknown_default) : (item?.gcp_profile_image_url ? item?.gcp_profile_image_url : DefaultUserProfile?.influencer_unknown_default)}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                          }}
                        />{' '}
                      </div>
                      <div className='instaNameDiv'>
                        <span>
                          {/* <Link style={{ textDecoration: 'none', color: '#000' }} to={`/brand/profile-insight?sm_id=${item.sm_id}`}>{item.Name}</Link> */}
                          <Link style={{ textDecoration: 'none', color: '#000' }} to="#" data-sm_id={item.sm_id} onClick={(event: any) => props.handleProfileView(event, props.type, item.sm_id)}>{props?.type === 'Youtube' ? item?.title : item.insta_name}</Link>
                        </span>
                        <span><Link to={props?.type === 'Youtube' ? `https://www.youtube.com/${item?.custom_url ? item?.custom_url : item?.title}` : `https://www.instagram.com/${item?.username}`} target='_blank'>{props.type === 'Youtube' ? (item?.custom_url ? item?.custom_url : item?.title) : item?.username}</Link></span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{props.type === 'Youtube' ? methods.convertNumber(item.subscriber_count) : methods.convertNumber(item?.followers)}</TableCell>
                  <TableCell>{props.type === 'Youtube' ? item?.er_without_view : item?.er}%</TableCell>
                  <TableCell>{item?.categories}</TableCell>
                  <TableCell>{item?.location}</TableCell>
                  <TableCell>{item?.campaign_count}</TableCell>
                  <TableCell>₹{methods.formatNumberINR(item?.amount)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{ borderRadius: '0 0 14px 14px' }} colSpan={7} align='center'>No data to display!</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TopInfluencer
