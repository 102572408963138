export const defaultChartData = [
    {
        "DATE":"2024-06-24",
        "comments":548,
        "earliest_created_at":"2024-06-24 09:06:37",
        "er":24.38,
        "likes":11372,
        "video_plays":302319473
    },
    {
        "DATE":"2024-06-25",
        "comments":589,
        "earliest_created_at":"2024-06-25 09:06:37",
        "er":29.42,
        "likes":11580,
        "video_plays":302325474
    },
    {
        "DATE":"2024-06-26",
        "comments":625,
        "earliest_created_at":"2024-06-26 09:06:37",
        "er":35.33,
        "likes":11675,
        "video_plays":302333321
    },
    {
        "DATE":"2024-06-27",
        "comments":658,
        "earliest_created_at":"2024-06-27 09:06:37",
        "er":39.67,
        "likes":11800,
        "video_plays":302335479
    },
    {
        "DATE":"2024-06-28",
        "comments":699,
        "earliest_created_at":"2024-06-28 09:06:37",
        "er":41.40,
        "likes":12000,
        "video_plays":302337671
    },
    {
        "DATE":"2024-06-29",
        "comments":733,
        "earliest_created_at":"2024-06-29 09:06:37",
        "er":42.31,
        "likes":12333,
        "video_plays":302339474
    },
    {
        "DATE":"2024-06-30",
        "comments":789,
        "earliest_created_at":"2024-06-30 09:06:37",
        "er":45.50,
        "likes":12889,
        "video_plays":302341474
    }
]

const getDateAtIndex = (index:number) => {
    const today = new Date();
    const date = new Date(today);
    date.setDate(today.getDate() - index);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

export const defaultEmptyChartData = [
    {
        "DATE":getDateAtIndex(6),
        "comments":0,
        "earliest_created_at":"2024-06-24 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    },
    {
        "DATE":getDateAtIndex(5),
        "comments":0,
        "earliest_created_at":"2024-06-25 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    },
    {
        "DATE":getDateAtIndex(4),
        "comments":0,
        "earliest_created_at":"2024-06-26 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    },
    {
        "DATE":getDateAtIndex(3),
        "comments":0,
        "earliest_created_at":"2024-06-27 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    },
    {
        "DATE":getDateAtIndex(2),
        "comments":0,
        "earliest_created_at":"2024-06-28 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    },
    {
        "DATE":getDateAtIndex(1),
        "comments":0,
        "earliest_created_at":"2024-06-29 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    },
    {
        "DATE":getDateAtIndex(0),
        "comments":0,
        "earliest_created_at":"2024-06-30 09:06:37",
        "er":0,
        "likes":0,
        "video_plays":0
    }
]

export const defaultChartDataNotPresent = [
    {
        "DATE":"2024-06-24",
        "comments":548,
        "earliest_created_at":"2024-06-24 09:06:37",
        "er":24.38,
        "likes":11372,
        "video_plays":302319473
    },
    {
        "DATE":"2024-06-25",
        "comments":589,
        "earliest_created_at":"2024-06-25 09:06:37",
        "er":29.42,
        "likes":11580,
        "video_plays":302325474
    },
    {
        "DATE":"2024-06-26",
        "comments":625,
        "earliest_created_at":"2024-06-26 09:06:37",
        "er":35.33,
        "likes":11675,
        "video_plays":302333321
    },
    {
        "DATE":"2024-06-27",
        "comments":658,
        "earliest_created_at":"2024-06-27 09:06:37",
        "er":39.67,
        "likes":11800,
        "video_plays":302335479
    },
    {
        "DATE":"2024-06-28",
        "comments":699,
        "earliest_created_at":"2024-06-28 09:06:37",
        "er":41.40,
        "likes":12000,
        "video_plays":302337671
    },
    {
        "DATE":"2024-06-29",
        "comments":733,
        "earliest_created_at":"2024-06-29 09:06:37",
        "er":42.31,
        "likes":12333,
        "video_plays":302339474
    },
    {
        "DATE":"2024-06-30",
        "comments":789,
        "earliest_created_at":"2024-06-30 09:06:37",
        "er":45.50,
        "likes":12889,
        "video_plays":302341474
    }
]

export const defaultCampaign = {
    "budget": 0,
    "campaign_id":0,
    "plan_id":0,
    "campaign_title":"Sample Analytics",
    "created_at":"2020-06-27 12:33:36"
}

