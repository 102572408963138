import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { AdminDashboardService } from "../../../../core/services/admin-dashboard.service";
import { TableComponent } from "../../../../components/table-component/table";

const adminDashboardService: AdminDashboardService = new AdminDashboardService();
interface Props {
  triggerUpdate?: boolean
}

const columnDisplayName = {
  username: "Username",
  name: "Influencer Name",
  phone: "Phone",
  email_id: "Email",
  gender: "Gender",
  location_id: "Location",
  category: "Genre",
  language: "Language",
  followers: "Followers",
  campaign: "Campaign",
  brand: "Brand",
  campaign_hashtags: "Campaign Hashtags",
  cost_of_reel: "Cost of Reel",
  avg_view_reel: "Average View of Reel",
  cost_of_story: "Cost of Story",
  cost_of_story_wa_swipeup_link: "Cost of Story (With Swipeup Link)",
  cost_of_image_post: "Cost of Image Post",
  brands_worked_past: "Brands Worked with (Past 3 Months)"
};

const sortingColumnType = [
  { name: "username", columnSize: "250px" },
  { name: "name", columnSize: "200px" },
  { name: "phone", columnSize: "100px" },
  { name: "email_id", columnSize: "150" },
  { name: "gender", columnSize: "100px" },
  { name: "location_id", columnSize: "150px" },
  { name: "category", columnSize: "150px" },
  { name: "language", columnSize: "150px" },
  { name: "followers", columnSize: "120px" },
  { name: "campaign", columnSize: "150px" },
  { name: "brand", columnSize: "150px" },
  { name: "campaign_hashtags", columnSize: "150px" },
  { name: "cost_of_reel", columnSize: "150px" },
  { name: "avg_view_reel", columnSize: "150px" },
  { name: "cost_of_story", columnSize: "150px" },
  { name: "cost_of_story_wa_swipeup_link", columnSize: "250px" },
  { name: "cost_of_image_post", columnSize: "150px" },
  { name: "brands_worked_past", columnSize: "200px" },
];


export const InfluencerInstagram: React.FC<Props> = (props: Props) => {
  const [renderData, setRenderData] = useState<any>([]);
  const [externalInfluencer, setExternalInfluencer] = useState<any>([]);

  async function getExternalInfluencerInstagram() {
    try {
      const res = await adminDashboardService.getExternalInfluencerInstagram();
      if (res.status) {
        setExternalInfluencer(res?.data.rows);
      }
    } catch (error) {
      console.error('Error fetching getExternalInfluencerInstagram:', error);
    } finally {
    }
  }

  useEffect(() => {
    getExternalInfluencerInstagram()
  }, [props.triggerUpdate])

   return (
    <section>
      <div className="talent_verification_tab">

      <TableComponent
        pagination={true}
        columnDisplayName={columnDisplayName}
        sortingColumnType={sortingColumnType}
        inputDataSource={externalInfluencer}
        orderByColumnName=""
        renderData={renderData}
        setRenderData={setRenderData}
      >
        {renderData.map((data: any, index: number) => {
          return (
            <TableRow key={index} >
              <TableCell>{data.username}</TableCell>
              <TableCell>{data.name}</TableCell>
              <TableCell>{data.phone}</TableCell>
              <TableCell>{data.email_id}</TableCell>
              <TableCell>{data.gender}</TableCell>
              <TableCell>{data.location_id}</TableCell>
              <TableCell>{data.category}</TableCell>
              <TableCell>{data.language}</TableCell>
              <TableCell>{data.followers ? data.followers : ''}</TableCell>
              <TableCell>{data.campaign}</TableCell>
              <TableCell>{data.brand}</TableCell>
              <TableCell>{data.campaign_hashtags}</TableCell>
              <TableCell>{data.cost_of_reel}</TableCell>
              <TableCell>{data.avg_view_reel}</TableCell>
              <TableCell>{data.cost_of_story}</TableCell>
              <TableCell>{data.cost_of_story_wa_swipeup_link}</TableCell>
              <TableCell>{data.cost_of_image_post}</TableCell>
              <TableCell>{data.brands_worked_past}</TableCell>
            </TableRow>
          )
        })}
      </TableComponent>
      </div>
    </section>
  );
};
