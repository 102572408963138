import React, { useEffect, useState, useReducer } from 'react';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { useNavigate, useLocation, Link, Outlet } from 'react-router-dom';
import { CartReducer } from '../../redux/reducer/reducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CartReducerState } from '../../redux/reducer/cart-reducer-state';
import { SET_CART } from '../../redux/types';
import './clan-shop-cart.scss';
import {
    Add,
    ChevronLeft,
    Close,
    Delete,
    Remove,
    ShoppingBag,
    ShoppingCart,
} from '@mui/icons-material';
import HeadingStyle from '../../components/heading-style/heading-style';
import {
    RemoveFromCart,
    updateQuantity,
    setGuestUser,
    setCart,
} from '../../redux/action';
import { LocalService } from '../../core/services';
import { useCart } from '../../hooks/useCart';
// import useQuantity from '../../hooks/useQuantity';
type Props = {
    CartReducer: any;
    setGuestUser: (data: any) => void;
};
const localService = new LocalService();
const ClanShopCart = (props: Props) => {
    useCart();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [cartData, setCartData] = useState<any>([]);
    // const shop_id = searchParams.get('shop_id');
    // const product_id = searchParams.get('product_id');
    const user_id = localService.getStorageItem('user_id');
    const shop_id = localService.getStorageItem('shop_id');
    const product_ids = localService.getStorageItem('product_id');
    const guest_user_login = localService.getStorageItem('guest_user_login');
    const dispatch = useDispatch();

    useEffect(() => {
        // const savedCart = localStorage.getItem('cart');
        // const user_id = localService.getStorageItem('user_id');

        // const product_ids = localService.getStorageItem('product_id');

        // if (savedCart) {
        //     // Parse and dispatch the cart data to Redux
        //     const parsedCart = JSON.parse(savedCart);
        //     dispatch({ type: SET_CART, payload: savedCart });
        // }
        const guest_user_login =
            localService.getStorageItem('guest_user_login');
        if (guest_user_login) {
            dispatch(setGuestUser(true));
        }
    }, [dispatch]);

    useEffect(() => {
        // ClanShopService.getProductDetail(product_id)
        //     .then((res) => {
        //         setProductData(res?.data?.rows);
        //     })
        //     .catch((e) => setProductData([]));
    }, []);
    const handleRemoveFromCart = (productId: any) => {
        if (guest_user_login) {
            const product_data: any = {};
            product_data['user_id'] = user_id;
            product_data['product_id'] = productId;
            ClanShopService.deleteCartItem(product_data)
                .then((res) => {
                    if (res.data.status.status) {
                        setCartData(
                            cartData.filter(
                                (product: any) =>
                                    product.product_id !== productId
                            )
                        );
                        dispatch(RemoveFromCart(productId));
                    }
                    // setProductData(res?.data?.rows);
                })
                .catch((e) => console.log('response', e));
        } else {
            dispatch(RemoveFromCart(productId));
        }
    };
    const handleUpdateQuantity = (productId: any, newQuantity: any) => {
        // Dispatch the UPDATE_QUANTITY action to update the Redux state
        dispatch(updateQuantity({ productId, newQuantity }));
    };
    // const total = cartItems.reduce((acc, item) => {
    //     const itemPrice = item.unitPrice; // Replace with the actual unit price
    //     const itemQuantity = item.quantity;
    //     return acc + itemPrice * itemQuantity;
    // }, 0);
    const shopId = props.CartReducer?.cartList[0]?.shop_id;

    const handleBuyNow = () => {
        navigate(`/clanshop/product-details/user-details?cart=${true}`);
    };
    function calculateRemainingPrice(
        originalPrice: any,
        discountPercentage: any
    ) {
        // Calculate the remaining percentage after discount
        const remainingPercentage = 100 - discountPercentage;
        // Convert remaining percentage to decimal
        const remainingDecimal = remainingPercentage / 100;
        // Calculate the remaining price
        const remainingPrice = originalPrice * remainingDecimal;
        return remainingPrice;
    }

    // const {
    //     quantityValue,
    //     handleQuantityChange,
    //     handleIncrement,
    //     handleDecrement,
    // } = useQuantity(1, 1, 3);

    // const quantityValues = props.CartReducer?.cartList.map(() =>
    //     useQuantity(1, 1, 3)
    // );
    // const calculateCartTotal = () => {
    //     if (!Array.isArray(props.CartReducer?.cartList)) {
    //         return 0; // Return 0 or an appropriate default value when cartList is not an array
    //     }

    //     return props.CartReducer?.cartList.reduce(
    //         (total: number, prod: any) => {
    //             // Check if product has inventory greater than zero before including in total
    //             if (prod.inventory > 0) {
    //                 return (
    //                     total +
    //                     calculateRemainingPrice(
    //                         prod.product_cost,
    //                         prod.discount
    //                     ) *
    //                         parseInt(prod.quantity)
    //                 );
    //             }
    //             return total;
    //         },
    //         0
    //     );
    // };
    return (
        <>
            <div className='container'>
                <div className='cart-length'>
                    <HeadingStyle title={`Cart`} />
                    {/* <>{console.log(props?.CartReducer?.cartList)}</> */}
                    <span className='cart-count'>
                        {props.CartReducer?.cartList?.length}
                    </span>
                </div>
                {props.CartReducer?.cartList?.length === 0 ? (
                    <div className='no-cart'>
                        <h1>No product found in your cart.</h1>
                        <Link
                            className='btn btn-outline-primary w-auto'
                            to={`/clanshop?shop_id=${shopId}`}
                        >
                            <ChevronLeft className='me-2' /> Back to Shop
                        </Link>
                    </div>
                ) : (
                    ''
                )}
                <div className='cart-table-sec'>
                    <>
                        <div className='cart-table'>
                            <>CART TABLE</>

                            {props?.CartReducer?.cartList &&
                                props?.CartReducer?.cartList.map(
                                    (prod: any) => {
                                        return (
                                            <div
                                                className={
                                                    prod.inventory === 0
                                                        ? 'row cart-row cart-row-no-stock'
                                                        : 'row cart-row '
                                                }
                                                key={prod.product_id}
                                            >
                                                <div className='col-xs-12 col-md-2'>
                                                    <img
                                                        src={
                                                            prod.product_image_url
                                                        }
                                                        width='100%'
                                                    />
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='product-articlenr'>
                                                        {prod.brand}
                                                    </div>
                                                    <div className='product-name'>
                                                        {prod.product_name}
                                                    </div>
                                                    <div className='product-description'>
                                                        {
                                                            prod.product_description
                                                        }
                                                    </div>
                                                    <div className='product-options'>
                                                        <span>Discount:</span>{' '}
                                                        {prod.discount}%
                                                        <br />
                                                        <span>Size:</span> M
                                                    </div>
                                                    <div className='product-price d-flex'>
                                                        <span className='d-flex align-self-end'>
                                                            {prod.quantity}
                                                        </span>

                                                        <div className='product-count ms-2'>
                                                            <small className='me-2'>
                                                                x
                                                            </small>
                                                            <span className='product-original-price'>
                                                                &#8377;
                                                                {
                                                                    prod?.product_cost
                                                                }
                                                            </span>
                                                            <strong className='product-after-discount'>
                                                                &#8377;
                                                                {calculateRemainingPrice(
                                                                    prod?.product_cost,
                                                                    prod?.discount
                                                                ).toFixed(2)}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 cart-actions'>
                                                    <div className='product-price-total'>
                                                        <>
                                                            &#8377;{' '}
                                                            {(
                                                                calculateRemainingPrice(
                                                                    prod?.product_cost,
                                                                    prod?.discount
                                                                ) *
                                                                parseInt(
                                                                    prod.quantity
                                                                )
                                                            ).toFixed(2)}
                                                        </>
                                                    </div>
                                                    <div className='product-delete'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-outline-orange btn-sm'
                                                            onClick={() => {
                                                                handleRemoveFromCart(
                                                                    prod.product_id
                                                                );
                                                            }}
                                                        >
                                                            <Delete className='text-red' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>

                        <>
                            <div className='row'>
                                <div className='col-md-2'></div>
                                <div className='col-md-6'></div>
                                <div className='col-md-4'>
                                    <div className='totals'>
                                        {/* <div className='totals-item'>
                                            <label>Subtotal</label>

                                            <strong>
                                                {' '}
                                                {props?.CartReducer?.total}
                                            </strong>
                                        </div> */}
                                        <div className='totals-item final-cart'>
                                            <label>Cart Total:</label>
                                            <div>
                                                &#8377;{' '}
                                                {props?.CartReducer?.total}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 row justify-content-between mx-0'>
                                <Link
                                    className='btn btn-outline-primary w-auto'
                                    to={`/clanshop?shop_id=${shopId}`}
                                >
                                    <ChevronLeft className='me-2' /> Back to
                                    Shop
                                </Link>
                                {props?.CartReducer?.cartList?.length > 0 && (
                                    <button
                                        className=' btn btn-primary w-auto'
                                        onClick={handleBuyNow}
                                    >
                                        Proceed to checkout
                                        <ShoppingCart className='ms-2' />
                                    </button>
                                )}
                            </div>
                        </>
                    </>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        CartReducer: state.CartReducer,
        totalCost: state.CartReducer.totalCost,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateQuantity: (data: any) => dispatch(updateQuantity(data)),
        setGuestUser: (data: any) => dispatch(setGuestUser(data)),
        setCart: (data: any) => dispatch(setCart(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ClanShopCart);

export { connectToRedux as ClanShopCart };
