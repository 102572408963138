import React from 'react'

type Props = {
  comments: string;
}

const ProposalComment = ({ comments }: Props) => {
  return <div className='proposal-comment-sec'>
    <strong>
      Proposal
      <br /> comments
    </strong>
    <div className='proposal-comment'>
      <p>
        {comments}
      </p>
    </div>
  </div>
}

export default ProposalComment