import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CommonPopUp from './common-popup';

const GuideToConnectContent = () => {
    const [applyRestrictionPopup, setApplyRestrictionPopup] = useState(false);
    const handleSafariBlockIssue = () =>{
        setApplyRestrictionPopup(true)
    }
    const handleSafariBlockIssueClose = () => {
        setApplyRestrictionPopup(false)
    }
  return (
    <>
              <strong className='d-block mb-3' style={{ fontSize: '18px' }}>Instagram</strong>
              <p className='d-block mb-3'>Your Instagram account should either be Professional, Creator or Business account.</p>
              <strong className='d-block mb-1'>Please follow the steps mentioned:</strong>
              <ol className='ps-4'>
                  <li>Click on Connect.</li>
                  <li>Click on Edit Settings.</li>
                  <li>Select your Facebook page which is connected to your Instagram account.</li>
                  <li>Allow all the requested permissions.</li>
              </ol>
              <p>You should now be able to apply to campaigns.</p>
              <strong className='d-block mb-1'>To check if your Instagram account is linked to your Facebook page, please follow the given steps:</strong>
              <ol className='ps-4'>
                  <li>Open your Instagram account</li>
                  <li>Go to profile, click on edit profile</li>
                  <li>Go to the page section.</li>
                  <li>You will be able to see the linked page there. If the page is not linked, it will ask you to create a new page.</li>
                  <li>Click on create a page and link the same while reconnecting your Instagram account.</li>
              </ol>
              <strong>iOS Users</strong>
          <p className='d-block mb-1'>In case of <strong>Safari</strong> browser, you might need to allow pop-ups. <span onClick={handleSafariBlockIssue} className='cursor-pointer fs-14' style={{color:'#00aae5', textDecoration:'underline'}}>watch video</span> <br/>Steps to follow:</p>
              <ol className='ps-4 mb-0'>
                  <li>Go to Settings, search Safari.</li>
                  <li>Under <strong>General</strong>, turn off <strong>Block Pop-ups.</strong></li>
              </ol>
              <p className='mb-4'>You can now follow the steps to connect your Instagram social account.</p>

              <strong className='d-block mb-3 mt-3' style={{ fontSize: '18px' }}>Youtube</strong>
              <p className='d-block mb-4'>You should have a YouTube channel connected to your Google account.</p>
              <p>If you still face any issues kindly connect with us at influencer.support@clanconnect.ai.</p>
              <Link to='https://api.whatsapp.com/send?phone=9289315858' target='_blank'>
                  <button className='btn btn-outline-primary'><WhatsAppIcon style={{ color: '#25D366' }} /> Chat with us</button></Link>
          <CommonPopUp
              modalTitle="How to turn off Block Pop-ups in Safari"
              modalContent=""
              openModal={applyRestrictionPopup}
              onClose={handleSafariBlockIssueClose}
              className="modal-medium mx-auto safari-video-block"
          >
              <div className='mb-4'>
                <video style={{aspectRatio:'1/1'}} width="100%" controls>
                      <source src="https://clanconnect.s3.ap-south-1.amazonaws.com/static_images/app-clanconnect/videos/guide-to-connect.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                </video>
              </div>
          </CommonPopUp>
      </>
      
  )
}

export default GuideToConnectContent