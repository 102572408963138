import React, { useState, useEffect, SyntheticEvent } from "react";
import { YouTube } from "@mui/icons-material";
import { IconButton, Slide, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormValidation } from "../../../core/utility";
import InstagramIcon from "../../../components/icons/instagram-icon";
import MaterialSymbol from '../../../components/icons/material-symbol';
import Loading from '../../../components/loader/loading';
import { LocalService, ListService, CreateCampaign as CampaignService } from "../../../core/services";
import InstagramIcon1 from "../../../components/icons/instagram-icon1";

const listService: ListService = new ListService();
const localService: LocalService = new LocalService();
const validations: FormValidation = new FormValidation();

interface Props {
    selectedInfluencer: any[];
    platform: string;
    UserReducer: any;
    showAddToListPopup: boolean;
    handleAddToListClose: () => void;
    handleOpenUpgradeBrandModal?:((value:boolean) => void) | undefined;
}



const AddToList: React.FC<Props> = ({ selectedInfluencer, platform, UserReducer, showAddToListPopup, handleAddToListClose, handleOpenUpgradeBrandModal }) => {
    const [popupInputValidation, setPopupInputValidation] = useState({
        status: true,
        message: "",
    });
    const [listName, setListName] = useState("");
    const [influencerLists, setInfluencerLists] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [loading, setLoading] = useState(false)
    const [createListElement, setCreateListElement] = useState(false)
    useEffect(() => {
        if (showAddToListPopup) {
            _InfluencerList();
        }
    }, [showAddToListPopup])

    //list name validation
    const handleListName = (e: any) => {
        const {
            target: { value },
        } = e;
        setPopupInputValidation(validations.GetNameValidate(value));
        setListName(value);
    };


    //create the list
    async function createInfluencerList() {
        try {
            let inputValidation = validations.GetNameValidate(listName);
            setPopupInputValidation(inputValidation);
            if (inputValidation.status) {
                let response: any = await listService.createInfluencerList({
                    user_id: UserReducer.user_id,
                    account_id: UserReducer.account_id,
                    brand_id: UserReducer.brand_id,
                    influencer_id: UserReducer.influencer_id,
                    list_name: listName,
                    platform_type: platform,
                });

                if (response.data.status.status) {
                    localService.toastify('List created', 'success', 1000);
                } else if (!response.data.status.status && response.data.status.message.includes('upgrade')) {
                    if(handleOpenUpgradeBrandModal){
                        handleOpenUpgradeBrandModal(true);
                    }
                    // localService.toastify(response.data.status.message, 'info', 1000);
                } else if (!response.data.status.status) {
                    localService.toastify('List exist', 'error', 1000);
                }
                _InfluencerList();
                setListName("");
            }
        } catch (error) {
            throw new Error(`craeteInfluencerList Error :: ${error}`);
        } finally {
            // setIsLoading(false);
        }
    };


    //get the lists
    function _InfluencerList() {
        setLoading(true)
        CampaignService.influencerList({
            user_id: UserReducer.user_id,
            brand_id: UserReducer.brand_id,
        }).then((res: any) => {
            if (res.data.status === 1) {
                setInfluencerLists(res.data.rows);
                setLoading(false)
            } else {
                setInfluencerLists(null);
                setLoading(false)
            }
        });
    };


    //select the list
    function getSelectedList(e: SyntheticEvent, data: any) {
        let exitInList = false;
        let tempData = [...selectedList];
        tempData.findIndex((value, index, obj: any) => {
            if (JSON.stringify(value) === JSON.stringify(data)) {
                tempData.splice(index, 1);
                exitInList = true;
            }
        });

        e.currentTarget.className = " ";

        if (!exitInList) {
            tempData.push(data);
            e.currentTarget.className = "selected-list";
        }
        setSelectedList(tempData);
    }

    //save influencer in the list
    async function saveInfluencerInList() {
        try {
            if (selectedInfluencer.length !== 0 && selectedList.length !== 0) {
                const response = await listService.addInfluencerList({
                    user_id: UserReducer.user_id,
                    account_id: UserReducer.account_id,
                    brand_id: UserReducer.brand_id,
                    influencer_id: UserReducer.influencer_id,
                    lists: selectedList,
                    influencers: selectedInfluencer,
                });
                if (response.data.status.status) {
                    localService.toastify('Added to list', 'success', 1000);
                    handleAddToListClose()
                } else if (!response.data.status.status && response.data.status.message.includes('upgrade')) {
                    if(handleOpenUpgradeBrandModal){
                        handleOpenUpgradeBrandModal(true);
                    }
                    // localService.toastify(response.data.status.message, 'info', 3000);
                    //  handleOpenUpgradeBrandModal();
                } else if (!response.data.status.status) {
                    localService.toastify('Please try again', 'error', 1000);
                }
            }
        } catch (error) {
            console.log(
                `saveInfluencerInList error in discover :: ${JSON.stringify(error)}`
            );
        } 
        // finally {
        //     handleAddToListClose();
        // }
    }

    const handleCancelAddToList = () => {
        handleAddToListClose();
    };
    const handleCreateList = () => {
        setCreateListElement(prev => !prev)
    }
    return (
        <Slide direction="up" in={showAddToListPopup} mountOnEnter unmountOnExit>
            <div className="col">
                <div className='d-flex popper-popup-header'>
                    <span className=' me-auto pb-1 d-block'>Add to List</span>
                    <IconButton onClick={handleCreateList}><MaterialSymbol fontweight='200' iconName='add' /></IconButton>
                    <IconButton onClick={handleCancelAddToList}><MaterialSymbol fontweight='200' iconName='close' /></IconButton>
                </div>
                {createListElement &&
                    <div className="d-flex mb-3 mt-2">
                        <TextField
                            error={!popupInputValidation.status}
                            helperText={popupInputValidation.message}
                            onChange={handleListName}
                            className="w-100"
                            id="id_create_list"
                            label="Create a new list"
                            variant="outlined"
                            size="small"
                            value={listName}
                        />
                        <LoadingButton
                            className="btn btn-primary ms-2 align-self-start"
                            loadingPosition="start"
                            variant="contained"
                            onClick={createInfluencerList}
                        >
                            Create
                        </LoadingButton>
                    </div>
                }
                {loading && <Loading width='80' height='80' />}
                {influencerLists && (

                    <div className="list-section">
                        <ul>
                            {influencerLists.map(
                                (
                                    res: {
                                        list_name: string;
                                        list_type: string;
                                        list_id: number;
                                    },
                                    index: number
                                ) => {
                                    if ((res.list_type).toLowerCase() === platform) {
                                        return (
                                            <li
                                                key={index}
                                                onClick={(e) => getSelectedList(e, res)}
                                            >
                                                {" "}
                                                {res.list_type === "Instagram" && (
                                                    <InstagramIcon1 className="icon-instagram me-2" width={20} />
                                                )}{" "}
                                                {res.list_type === "Youtube" && (
                                                    <YouTube className="me-2" />
                                                )}{" "}
                                                <span>{res.list_name}</span>
                                                <MaterialSymbol
                                                    iconName='check' fontweight="200" />
                                            </li>
                                        );
                                    }

                                    return null;
                                }
                            )}
                        </ul>
                    </div>

                )}
                <div className="popup-footer">
                    <div className="d-flex justify-content-end">
                        <LoadingButton
                            className="btn btn-primary btn-mui"
                            loadingPosition="start"
                            variant="contained"
                            onClick={saveInfluencerInList}
                            disabled={selectedList.length === 0}
                        >
                            Save
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </Slide>
    );
};


export default AddToList;