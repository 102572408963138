import { Grid, Skeleton, Tab } from '@mui/material'
import React from 'react'
import { Methods } from '../../../../core/utility';
import { ColumnChart, PieChart } from '../../../../components/charts';
import { Boy, Girl } from '@mui/icons-material';
import { LineChartDropDown } from '../../../../components/charts/line-chart';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CityList from '../city-list';

type Props = {
    influencerData: any;
    loading: boolean;
    postErComparisonData: any;

}

const methods = new Methods();

const Engagement = ({ influencerData, loading, postErComparisonData }: Props) => {
    const totalSaved = influencerData?.profile_data?.[0]?.image_total_saved + influencerData?.profile_data?.[0]?.carousel_total_saved;
    const averageSaved = (totalSaved / 2).toFixed(0);
    const formattedNumber = methods.formatInfluencerProfileNumber(averageSaved);
    // Extracting and checking the values
    const imageAvgImpressions = influencerData?.profile_data?.[0]?.image_avg_impressions;
    const carouselAvgImpressions = influencerData?.profile_data?.[0]?.carousel_avg_impressions;

    // Check if data exists and is greater than 0
    const avgImpressions = imageAvgImpressions > 0 && carouselAvgImpressions > 0
        ? ((imageAvgImpressions + carouselAvgImpressions) / 2).toFixed(0)
        : null;
    return (
        <>{(influencerData?.profile_data?.[0]?.reels_total_reach || influencerData?.profile_data?.[0]?.image_total_reach || influencerData?.profile_data?.[0]?.carousel_avg_impressions || postErComparisonData[0]?.data[0]?.value > 0 || postErComparisonData[1]?.data[0]?.value > 0) &&
            <>
                {(influencerData?.detail?.[0]?.reels_er_without_plays > 0 || influencerData?.profile_data?.[0]?.avg_plays > 0 || influencerData?.profile_data?.[0]?.reels_total_reach > 0 || influencerData?.profile_data?.[0]?.reels_total_saved || (influencerData?.profile_data?.[0]?.image_er + influencerData?.profile_data?.[0]?.carousel_er) > 0 || (influencerData?.profile_data?.[0]?.image_total_reach + influencerData?.profile_data?.[0]?.carousel_total_reach) > 0 || avgImpressions || formattedNumber > 0) &&
                    <div className='d-flex w-100 align-items-center pb-1'>
                        <strong className='influencer-profile__common__title me-auto pb-0'>Engagement</strong>
                        <span className='fs-14' style={{ color: '#5c5c5c' }}>Last 90 days</span>
                    </div>
                }
                <section className='influencer-profile__common w-bdr'>
                    <div className='engagement-info-sec'>
                        <div className='engagement-info-left'>
                            {(influencerData?.detail?.[0]?.reels_er_without_plays > 0 || influencerData?.profile_data?.[0]?.avg_plays > 0 || influencerData?.profile_data?.[0]?.reels_total_reach > 0 || influencerData?.profile_data?.[0]?.reels_total_saved) &&
                                <strong>Reels</strong>
                            }
                            <ul className='engagement-info'>
                                {influencerData?.detail?.[0]?.reels_er_without_plays > 0 &&
                                    <li>
                                        <span>ER without views</span>
                                        <span>{influencerData?.detail?.[0]?.reels_er_without_plays + '%'}</span>
                                    </li>
                                }
                                {influencerData?.profile_data?.[0]?.avg_plays > 0 && (
                                    <li><span>Avg. views</span>
                                        <span>{methods.formatInfluencerProfileNumber(influencerData?.profile_data?.[0]?.avg_plays.toFixed(0))}</span></li>
                                )
                                }
                                {influencerData?.profile_data?.[0]?.reels_total_reach > 0 &&
                                    <li> <span>Est. Reach</span>
                                        <span>{methods.formatInfluencerProfileNumber(influencerData?.profile_data?.[0]?.reels_total_reach)}</span></li>
                                }
                                {influencerData?.profile_data?.[0]?.reels_total_saved &&
                                    <li>
                                        <span>Avg. Saves</span>
                                        <span>{methods.formatInfluencerProfileNumber(influencerData?.profile_data?.[0]?.reels_total_saved)}</span>
                                    </li>
                                }
                            </ul>
                            {(influencerData?.profile_data?.[0]?.image_er || influencerData?.profile_data?.[0]?.carousel_er || influencerData?.profile_data?.[0]?.image_total_reach || influencerData?.profile_data?.[0]?.carousel_total_reach || influencerData?.profile_data?.[0]?.image_avg_impressions || influencerData?.profile_data?.[0]?.carousel_avg_impressions || influencerData?.profile_data?.[0]?.image_total_saved || influencerData?.profile_data?.[0]?.carousel_total_saved || influencerData?.detail?.[0]?.average_profile_views || influencerData?.detail?.[0]?.post_frequency ||
                                influencerData?.detail?.[0]?.total_impressions) &&
                                <>
                                    {((influencerData?.profile_data?.[0]?.image_er + influencerData?.profile_data?.[0]?.carousel_er) > 0 || (influencerData?.profile_data?.[0]?.image_total_reach + influencerData?.profile_data?.[0]?.carousel_total_reach) > 0 || avgImpressions || formattedNumber > 0) &&
                                        <strong style={{display:'block', borderTop:'1px solid #ededed', paddingTop:'1.25rem' }}>Images</strong>
                                    }
                                    
                                    <ul className='engagement-info'>
                                        {(influencerData?.profile_data?.[0]?.image_er + influencerData?.profile_data?.[0]?.carousel_er) > 0 &&
                                            <li>
                                                <span>ER</span>
                                                <span> {((influencerData?.profile_data?.[0]?.image_er + influencerData?.profile_data?.[0]?.carousel_er) / 2).toFixed(2) + '%'} </span>
                                            </li>
                                        }
                                        {(influencerData?.profile_data?.[0]?.image_total_reach + influencerData?.profile_data?.[0]?.carousel_total_reach) > 0 &&
                                            <li> <span>Est. Reach</span>
                                                <span>{methods.formatInfluencerProfileNumber((((influencerData?.profile_data?.[0]?.image_total_reach + influencerData?.profile_data?.[0]?.carousel_total_reach) / 2).toFixed(0)))}</span></li>
                                        }
                                        {avgImpressions &&
                                            <li>
                                                <span>Avg. Impressions</span>
                                                <span>{avgImpressions}</span>
                                            </li>
                                        }
                                        {formattedNumber > 0 &&
                                            <li>
                                                <span>Avg. Saves</span>
                                                <span>{formattedNumber}</span>
                                            </li>
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                        {postErComparisonData.length > 0 && (((influencerData?.profile_data?.[0]?.image_er || influencerData?.profile_data?.[0]?.carousel_er || influencerData?.profile_data?.[0]?.image_total_reach || influencerData?.profile_data?.[0]?.carousel_total_reach || influencerData?.profile_data?.[0]?.image_avg_impressions || influencerData?.profile_data?.[0]?.carousel_avg_impressions || influencerData?.profile_data?.[0]?.image_total_saved || influencerData?.profile_data?.[0]?.carousel_total_saved || influencerData?.detail?.[0]?.average_profile_views || influencerData?.detail?.[0]?.post_frequency ||
                            influencerData?.detail?.[0]?.total_impressions) || (influencerData?.detail?.[0]?.reels_er_without_plays > 0 || influencerData?.profile_data?.[0]?.avg_plays > 0 || influencerData?.profile_data?.[0]?.reels_total_reach > 0 || influencerData?.profile_data?.[0]?.reels_total_saved))) &&
                            <div className='engagement-info-right'>
                                <div
                                    className="inner-heading"
                                >
                                    <span className="border-0">
                                        Est. Reach Comparison
                                    </span>
                                </div>
                                <div
                                    id="postsEstimatedReach"
                                    data-highcharts-chart={2}
                                    style={{ overflow: "hidden" }}
                                >
                                </div>
                                <ColumnChart id="column-chart-er" dataSource={postErComparisonData} barWidth={0.8} height="220px" legend={true} />
                            </div>
                        }
                    </div>
                </section>
            </>
        }</>
    )
}

export default Engagement