import React, { useState, useEffect, Fragment } from 'react';

type sizeType = 'small' | 'large' | 'larger' | Omit<string, 'small' | 'large' | 'larger'>;

interface NewLayoutProps {
    label: string;
    className?: string;
    children: React.ReactNode;
    size: sizeType;
}

export const NewLayout: React.FC<NewLayoutProps> = ({ label, children, size, className = '' }: NewLayoutProps) => {
    return (
        <div className={`custom-section ${className}`}>
            <label className='custom-section-label'><span style={{ fontSize: `${size}` }}>{label}</span></label>
            {children}
        </div>
    )
};

interface CustomCollapseProps {
    className?: string;
    children: React.ReactNode;
    label: string;
};


export const CustomCollapse: React.FC<CustomCollapseProps> = ({ className = '', label, children }: CustomCollapseProps) => {
    const [dropdown, setDropDown] = useState(false);
    return (
        <div className={`custom-collapse ${className} `} >
            <label className='custom-collapse-label' onClick={() => setDropDown(!dropdown)}>
                <strong><span>{label}</span></strong>
                <span className={`material-symbols-rounded toggle-arrow ${dropdown ? 'active-arrow' : 'inactive-arrow'} `}>
                    {dropdown ? "expand_less" : "expand_more"}</span></label>
            <div className={`custom-collapse-child ${dropdown ? 'active-collapse' : 'inactive-collapse'}`}>{children}</div>
        </div>
    )
};


interface CustomGenderProps {
    dataSource: { id: number, label: string, value: string | number }[];
    onChange: (e: string[]) => void;
    className?: string;
    name?: string;
    defaultValue?: string;
    label: string;
    clearData?: boolean;
    formData?: any;
    isBasicUser: boolean;
    section: string
    handleOpenUpgradeBrandModal?:((value:boolean) => void) | undefined;
}

export const CustomGenderButton: React.FC<CustomGenderProps> = (
    {
        dataSource,
        label = 'inital',
        className = '',
        defaultValue = '',
        name = 'inital',
        clearData,
        onChange,
        formData,
        isBasicUser,
        section,
        handleOpenUpgradeBrandModal
    }: CustomGenderProps) => {
    const [randomNumber, setRandomNumber] = useState(0);
    const [radioValue, setRadioValue] = useState('');

    useEffect(() => {
        setRandomNumber(Math.floor(Math.random() * 1000));
    }, []);

    useEffect(() => {
        if (radioValue) {
            onChange([radioValue])
        } else {
            onChange([])
        }
    }, [radioValue])
    useEffect(() => {
        // Set the initial radioValue based on formData.influencer_metrics.gender
        if (formData?.influencer_metrics?.gender) {
            setRadioValue(formData.influencer_metrics.gender[0] || ''); // Set to the first gender value or empty string
        }
        if (formData?.audience_metrics?.gender) {
            setRadioValue(formData.audience_metrics.gender[0] || ''); // Set to the first gender value or empty string
        }
    }, [formData]);
    useEffect(() => {
        if (clearData) {
            setRadioValue(''); // Reset radioValue when clearData changes
        }
    }, [clearData]);
    return (
        <form className={isBasicUser && section == 'audience-gender' ? 'user-basic' : ''}>
            <>{(isBasicUser && section == 'audience-gender') && <span className='pro-tag' style={{top:'-23px'}}>PRO</span>}</>
            <div className={`custom-gender-layout`}>
                <span className={`fs-12 ${className}`}>{label}</span>
                <fieldset className='custom-gender-option' style={{ display: 'flex' }} onChange={(e: any) => (isBasicUser && section == 'audience-gender' && handleOpenUpgradeBrandModal ? handleOpenUpgradeBrandModal(true) : setRadioValue(e.target.value))}>
                    {dataSource.map((item, index) =>
                        <Fragment key={index}>
                            <input
                                id={`gender_${item.label}_${index}_${randomNumber}`}
                                type='radio'
                                name={name}
                                defaultChecked={defaultValue === item.value}
                                value={item.value}
                                checked={radioValue === item.value} // Check if the radio button should be checked
                                onChange={(e) => (isBasicUser && section == 'audience-gender' && handleOpenUpgradeBrandModal ? handleOpenUpgradeBrandModal(true) : setRadioValue(e.target.value))} // Update the selected radio button value
                            />
                            <label
                                style={{ flex: '1', textAlign: "center" }}
                                className='custom-gender-label'
                                htmlFor={`gender_${item.label}_${index}_${randomNumber}`} >{item.label}</label>
                        </Fragment>
                    )}
                </fieldset>
            </div>
        </form>
    )
};


