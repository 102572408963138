import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Loading } from "../../../../../../redux/action";
import {
  PaymentComponentService,
  InvoiceService,
  LocalService,
} from "../../../../../../core/services";
import "./payment.scss";
import ProposalPaymentTable from "./proposal_payment_table";

const paymentComponent: PaymentComponentService = new PaymentComponentService();
const invoiceService: InvoiceService = new InvoiceService();
const localService: LocalService = new LocalService();
interface Props {
  selectedCampaign: any;
  UserReducer: any;
  loading: (data: any) => void;
}

const BrandPayment: React.FC<Props> = ({selectedCampaign, UserReducer, ...props}: Props) => {
  // to store accepted proposal details
  const [acceptedProposalPaymentDetails, setAcceptedProposalPaymentDetails] = useState<any[]>([]);
  // to store total amount of accepted proposals
  const [totalCampaignAmount, setTotalCampaignAmount] = useState(0);
  // To toggle bank details section
  const [showBankDetails, setShowBankDetails] = useState(false);

  const toggleBankDetails = () => {
    setShowBankDetails(!showBankDetails);
  };

  useEffect(() => {
    async function getAcceptedProposalPaymentDetails() {
      try {
        const response = await invoiceService.getAcceptedProposalPaymentDetails({
          campaign_id: selectedCampaign.campaign_id,
          influencer_id: 0
        });

        if (response.data.status.status) {
          setAcceptedProposalPaymentDetails(response.data.rows);
          const totalSum = response.data.rows.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.total_price;
          }, 0);
          setTotalCampaignAmount(totalSum);
        } else {
          setAcceptedProposalPaymentDetails([]);
        }
      } catch (error) {
        throw new Error(`getAcceptedProposalPaymentDetails error :: ${error}`);
      }
    }

    getAcceptedProposalPaymentDetails();
  }, []);

  return (
    <>
      <div className="snapshot-wrapper snapshot-info d-flex flex-column">
        <strong className="snapshot-card-title">Campaign Estimated Payout (Excluding Taxes)</strong>
        <div id="estimated-payment" className="est-pay-sec snapshot-card-row">
          <div className="est-pay-row d-flex flex-wrap w-100">
            <div className="col est-pay-row-left">
              <div className="snapshot-card-row">
                <span className="est-pay-title">Total Campaign Amount</span>
                <p className="est-pay-value">{totalCampaignAmount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProposalPaymentTable UserReducer={UserReducer} acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={selectedCampaign} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandPayment);

export { connectToRedux as BrandPayment };
