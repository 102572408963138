import React from 'react'
import FollowerCard from '../follower-card'
import { Methods } from '../../../../core/utility';
import IconProfileER from '../../../../components/icons/icon-profile-er';


type Props = {
    influencerData:any
}
const methods = new Methods();
const FollowerSection = ({ influencerData }: Props) => {
    const formattedFollowers = methods.formatInfluencerProfileNumber(influencerData?.profile_detail?.[0]?.followers);

  return (
      <div className="follower-sec">
          {formattedFollowers &&
            <FollowerCard title="Followers" iconName="person" value={formattedFollowers} />
          }
          {influencerData?.profile_detail?.[0]?.er !== 0 &&
        <FollowerCard mobileTitle mobileTitleText="ER" title="Engagement Rate" iconName='' iconImage={IconProfileER} value={influencerData?.profile_detail?.[0]?.er &&
              influencerData?.profile_detail?.[0]?.er + '%'} />
          }
          {influencerData?.detail?.[0]?.avg_likes !== 0 && influencerData?.detail?.[0]?.avg_likes !== undefined &&
            <FollowerCard title="Avg. Likes" iconName="favorite" value={<>{
              (influencerData?.detail?.[0]?.avg_likes !== 0 && influencerData?.detail?.[0]?.avg_likes !== undefined)
                  ? methods.formatInfluencerProfileNumber(Math.round(influencerData.detail[0].avg_likes))
                  : (influencerData?.detail?.[0]?.public_avg_likes !== 0 && influencerData?.detail?.[0]?.public_avg_likes !== undefined && methods.formatInfluencerProfileNumber(Math.round(influencerData.detail[0].public_avg_likes)))
            }</>} />
          }
      </div>
  )
}

export default FollowerSection