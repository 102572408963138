import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import "./brand-list.scss";
import { connect } from "react-redux";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { SortingColumnsType, TableComponent } from "../../../components/table-component/table";
import { LocalService, LoginService, SignUpService } from "../../../core/services";
import { UserLogin } from "../../../redux/action";
import { DefaultUserProfile } from "../../../config/config";
import { SubscriptionInvoiceType } from "custom-type";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AddInfluencer } from "../../common/switch-user/add-influencer";

const loginService = new LoginService();
const localService = new LocalService();
const signUpService = new SignUpService();


interface Props {
  UserReducer: any;
  switchLayer2: any;
}

const columnDisplayName = {
  talents: 'Talents',
  plan_type: 'Plan Type',
  amount: 'Amount',
  planend_date: 'Plan End Date',
  invoice: 'Invoice',
  edit: '',
  delete: ''
}

const sortingColumnType: SortingColumnsType[] = [
  {
    name: 'talents',
    columnSize: '140px',
  },
  {
    name: 'plan_type',
    columnSize: '100px',
  },
  {
    name: 'amount',
    columnSize: '100px',
  },
  {
    name: 'planend_date',
    columnSize: '100px',
  },
  {
    name: 'invoice',
    columnSize: '100px',
  },
  {
    name: '',
    columnSize: '30px',
  },
  {
    name: '',
    columnSize: '30px',
  }
]

const BrandList: React.FC<Props> = (props: Props) => {
  const [usersList, setUsersList] = useState<any>(null);
  const [renderData, setRenderData] = useState<any>([])
  const [updateData, setUpdateData] = useState<any>({});
  const [showAddInfluencer, setShowAddInfluencer] = useState(false);
  const [menuOpen, setMenuOpen] = useState<number>(-1);

  const navigate = useNavigate();

  async function getTalentInfluencer() {
    try {
      const response = await loginService.getTalentInfluencer({
        user_id: props.switchLayer2.user_id,
        account_id: props.switchLayer2.account_id,
      });
      if (response.data.status.status) {
        setUsersList(response.data.rows);
      } else {
        localService.toastify(response.data.status.message);
      }
    } catch (error) {
      console.error('Error fetching Influencers:', error);
    }
  }

  useEffect(() => {
    getTalentInfluencer();
  }, []);

  async function downloadInvoice(tableCell: any, index: number) {
    let propsData: SubscriptionInvoiceType = {
      photo: props.UserReducer.photo,
      full_name: props.UserReducer.full_name,
      address: props.UserReducer.address,
      email: props.UserReducer.login_email,
      pan: props.UserReducer.pan,
      gstin: props.UserReducer.gstin,
      invoice_no: `${new Date().getFullYear()}${new Date().getMonth() + 1
        }${new Date().getDate()}-00${index + 1}`,
      po_no: '7859',
      particulars: tableCell.plan_name,
      description: tableCell.description,
      sac: 789654,
      price_per_item: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      quantity: 1,
      total_price: tableCell.amount,
      subtotal: 0,
      total: '',
      ...tableCell,
    };

    // propsData.price_per_item = (tableCell.amount / 118) * 100;
    const compareState =
      '07AADCV0015E1ZX'.substring(0, 2) ===
      (props.UserReducer.gstin + '').substring(0, 2);
    if (!compareState) {
      propsData.igst = 18;
    }

    navigate('/subscription/invoice', { state: propsData });
  }

  async function setShowAddInfluencerMethod() {
    setUpdateData({});
    setShowAddInfluencer(!showAddInfluencer);
  }


  async function handleUpdateClick(data: any) {
    try {
      setMenuOpen(-1);
      setUpdateData(data);
      if (props.switchLayer2.account_type === "Talent Partner") {
        setShowAddInfluencer(!showAddInfluencer);
      }
    } catch (error) {
      console.log(`handleUpdateClick error :: ${JSON.stringify(error)}`);
    }
  }

  async function handleDeleteInfluencer(data: any) {
    try {
      const confirmed = window.confirm(`Are you sure you want to delete Influencer '${data?.name}'?`);
      if (confirmed) {
        const response = await signUpService.deleteInfluencerTP(data);
        const checkStatus = response.data.status.status ? 'success' : 'error';
        localService.toastify(response.data.status.message, checkStatus, 1000);
      }
    } catch (error) {
      throw new Error(`delete Influencer Error :: ${JSON.stringify(error)}`);
    }
  };

  return (
    <div className="mostOutterDivBrand">
      <div className="col">
        {props.switchLayer2.account_type === "Talent Partner" &&
          !showAddInfluencer && (
            <div className="contentContaainerDivHeading">
              <strong className='pb-3 d-block'>Talent List</strong>
              <Button
                className="btn btn-primary btn-sm"
                variant="contained"
                onClick={() => setShowAddInfluencerMethod()}
              >
                Add Influencerrr
              </Button>
            </div>
          )}
      </div>
      {showAddInfluencer && (
        <AddInfluencer
          account_id={props.switchLayer2.account_id}
          user_id={props.switchLayer2.user_id}
          updateShowInfluencer={(e) => setShowAddInfluencer(e)}
          data={updateData}
        />
      )}
      <div className="talents-data">
        <TableComponent
          pagination={true}
          columnDisplayName={columnDisplayName}
          inputDataSource={usersList}
          orderByColumnName=''
          sortingColumnType={sortingColumnType}
          renderData={renderData}
          setRenderData={e => setRenderData(e)}>
          {
            renderData.map((data: any, index: number) => {
              return (
                <TableRow key={index} hover role='checkbox'>
                  <TableCell className="d-flex gap-2 align-items-center">
                    <div className="contentDivLeftLeft">
                      <img
                        className="contentDivLeftLeftImage"
                        src={(data?.gcp_profile_image_url || data?.profile_image_url) ? data.gcp_profile_image_url || data?.profile_image_url : DefaultUserProfile?.influencer_unknown_default}
                        alt=""
                      />
                    </div>
                    <div className="d-flex  flex-column">
                      <span>{data.name}</span>
                      <span>{(data.username || data.custom_url) ? data.username || data.custom_url : null}</span>
                    </div>
                  </TableCell>
                  <TableCell>{data.plan_type ? data.plan_type : "No Plan"}</TableCell>
                  <TableCell>{data.amount ? data.amount : "0"}</TableCell>
                  <TableCell>{data.plan_end ? new Date(data.plan_end).toDateString() : "---"}</TableCell>
                  <TableCell >
                    {data.entity !== 'order' && (
                      <>
                        <span
                          className='download-span '
                          onClick={() => downloadInvoice(data, index)}
                          style={{ cursor: "pointer" }}
                        >
                          {data.plan_type ? <FileDownloadOutlinedIcon /> : null}
                        </span>
                      </>
                    )}
                  </TableCell>
                  <TableCell onClick={(e) => handleUpdateClick(data)} className="talent-icons disable-section"><ModeEditIcon className="talent-icons" /></TableCell>
                  <TableCell onClick={() => handleDeleteInfluencer(data)}><DeleteIcon className="talent-icons" /></TableCell>
                </TableRow>
              )
            })
          }
        </TableComponent>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    switchLayer2: state.SwitchLayer2Reducer.switchUser,
  };
};

const mapStateToDispatch = (dispatch: any) => {
  return {
    UserReducer: (data: any) => dispatch(UserLogin(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapStateToDispatch)(BrandList);

export { connectToRedux as BrandList };