import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
//mui metrial
// import AddIcon from '@mui/icons-material/Add';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { ListService } from '../../../../../core/services';
import { CreateCampaign, Loading } from '../../../../../redux/action';
import { createCampaignInitialState } from '../../../../../redux/reducer/reducer';
import PlanHomeNew from './plan-table';
import './plan-home.scss';

const listService: ListService = new ListService()
interface Props {
    UserReducer: any;
    CreateCampaignReducer?: (data: any) => void;
    loading: (status: any) => void;
    filter: string;
}

const PlanHome: React.FC<Props> = (props: Props) => {
    const [planList, setPlanList] = useState([]);
    // const [tableRows, setTableRows] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        props.loading(true);
        try {
            listService.planList({
                user_id: props.UserReducer.user_id,
                brand_id: props.UserReducer.brand_id,
            })
                .then((res: any) => {
                    props.loading(false);
                    try {
                        if (res.data.fetchedRows > 0) {
                            let tableRw: any = Object.keys(res.data.rows[0]);
                            tableRw.splice(0, 1);
                            setPlanList(res.data.rows);
                        } else {
                            //condition
                        }
                    } catch (err) {
                        throw new Error(`Data Align Error :: ${err}`);
                    }
                })
                .catch((e) => {
                    props.loading(false);
                    throw new Error(
                        `PlanService planList function error :: ${e}`
                    );
                });
        } catch (error) {
            throw new Error(`Plan list error :: ${JSON.stringify(error)}`);
        } finally {
        }
    }, [props.UserReducer.brand_id, props.UserReducer.user_id]);

    // const handleEdit = (e: any) => {
    //     console.warn('This is Edit Button');
    // };

    // const handleAddCampaign = (plan_id: any) => {
    //     props.loading(true);
    //     ListService.add_Campaign({
    //         brand_id: props.UserReducer.brand_id,
    //         user_id: props.UserReducer.user_id,
    //         plan_id: plan_id,
    //     })
    //         .then((res: any) => {
    //             if (props.CreateCampaignReducer) {
    //                 if (res.data.status.status) {
    //                     props.CreateCampaignReducer({
    //                         ...createCampaignInitialState,
    //                         createCampaign: {
    //                             campaign_id: res?.data?.status.campaign_id,
    //                         },
    //                         createPlan: res?.data?.rows[0],
    //                         navigation: {
    //                             ...createCampaignInitialState.navigation,
    //                             campaign_create: true,
    //                         },
    //                         completeStep: {
    //                             ...createCampaignInitialState.completeStep,
    //                             createPlan: true,
    //                         },
    //                     });

    //                     navigate('/brand/campaign/createcampaign');
    //                 }
    //                 props.loading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             props.loading(false);
    //             throw new Error(
    //                 `handleAddCampaign error :: ${JSON.stringify(error)}`
    //             );
    //         });
    // };

    const handleNavigation = () => {
        if (props.CreateCampaignReducer) {
            props.CreateCampaignReducer(createCampaignInitialState);
        }
        navigate('/brand/campaign/createplan');
    };

    // const navigateCampaignList = (data: any) => {
    //     navigate(`/brand/plan/campaigns/${data.plan_id}`);
    // };

    return (
        <div className='container-fluid  create_plan_main_container'>
            <div className='row'>
                <div className='col create_plan_top_buttons'>
                    <Button
                        variant='contained'
                        onClick={handleNavigation}
                        className='btn btn-primary'
                    >
                        Create plan
                    </Button>
                    <ul className='campaign-legend'>
                        <li className='campaign-legend_accepted'>Accepted</li>
                        <li className='campaign-legend_pending'>Pending</li>
                        <li className='campaign-legend_rejected'>Rejected</li>
                    </ul>
                </div>
                {planList.length > 0 && <PlanHomeNew filter={props.filter} dataSource={planList} />}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID,
    };
};

const mapDispatchToState = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (status: boolean) => dispatch(Loading(status)),
    };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToState)(PlanHome);

export { connectToRedux as PlanHome };
