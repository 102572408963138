
interface Props {
  className?: string;
}
const IconGender: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 12V17.394H22.1437V13.5097L15.9786 19.5996C16.3756 20.1573 16.6998 20.7525 16.951 21.3852C17.2023 22.0178 17.3279 22.6723 17.3279 23.3486C17.3279 24.9242 16.778 26.2599 15.6781 27.356C14.5783 28.452 13.2379 29 11.6569 29C10.076 29 8.73789 28.4518 7.64273 27.3554C6.54758 26.259 6 24.9228 6 23.3468C6 21.7707 6.54765 20.4309 7.64296 19.3274C8.73826 18.2239 10.0732 17.6721 11.6477 17.6721C12.333 17.6721 12.982 17.7996 13.5946 18.0545C14.2073 18.3093 14.7961 18.6317 15.3612 19.0214L21.5121 12.8563H17.606V12H23ZM11.6543 18.5284C10.2983 18.5284 9.15966 18.9923 8.2383 19.9201C7.31694 20.8478 6.85626 21.9897 6.85626 23.3457C6.85626 24.7017 7.31597 25.8403 8.23538 26.7617C9.1548 27.6831 10.2925 28.1437 11.6485 28.1437C13.0044 28.1437 14.1473 27.684 15.077 26.7646C16.0067 25.8452 16.4716 24.7075 16.4716 23.3515C16.4716 21.9956 16.0077 20.8527 15.0799 19.923C14.1522 18.9933 13.0103 18.5284 11.6543 18.5284Z" fill="#1C1B1F" />
        <path d="M0.218684 17.1025L1.9532 15.4669L0.328192 13.721L0.982798 13.1037L2.6078 14.8496L5.40471 12.2121C4.44457 10.9719 4.02073 9.57429 4.13318 8.01927C4.24563 6.46427 4.86502 5.15569 5.99137 4.09353C7.19093 2.96233 8.6073 2.41951 10.2405 2.46507C11.8736 2.5106 13.2539 3.13895 14.3812 4.35012C15.5085 5.56129 16.0436 6.9918 15.9866 8.64165C15.9296 10.2915 15.3032 11.6802 14.1074 12.8078C12.9849 13.8664 11.6454 14.3991 10.0889 14.4057C8.53248 14.4124 7.17551 13.9008 6.018 12.871L3.2211 15.5085L4.84611 17.2544L4.1915 17.8717L2.5665 16.1258L0.831981 17.7615L0.218684 17.1025ZM6.3724 11.8958C7.34358 12.9392 8.51356 13.4872 9.88233 13.5398C11.2511 13.5924 12.4538 13.13 13.4904 12.1524C14.5271 11.1748 15.0685 9.99396 15.1148 8.60978C15.1611 7.2256 14.6986 6.01179 13.7274 4.96836C12.7562 3.92492 11.586 3.38313 10.2169 3.34299C8.8477 3.30285 7.6448 3.77156 6.60816 4.74912C5.57153 5.72668 5.03028 6.90132 4.98442 8.27305C4.93855 9.64477 5.40121 10.8524 6.3724 11.8958Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconGender;









