import React, { useState } from 'react';
import { TableComponent, SortingColumnsType, } from "../../../components/table-component/table"
import { ApplyCampaignService, LocalService } from '../../../core/services';
import { FormValidation } from "../../../core/utility";
import { TextField } from '@mui/material';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CommonPopUp from '../../../components/popup/common-popup';
import { BASE_URL_WEB } from "../../../config/config";
const localService = new LocalService();
const applyCampaignService = new ApplyCampaignService();
const columnDisplayName = {
  postLink: "Post Link",
};
interface ErrorTypes {
  status: boolean;
  message: string;
}
const sortingColumnType: SortingColumnsType[] = [
  {
    name: "postLink",
    columnSize: "1.875rem",
  },
  {
    name: "",
    columnSize: ".3125rem",
  },
];



interface FormData {
  postLink: string;
}

interface AddAnalyticsProps {
  open: boolean;
  campaign_id: number;
  onClose: () => void;
  post_platform: string,
  sm_id: number;
}



const AddAnalytics: React.FC<AddAnalyticsProps> = ({ open, onClose, campaign_id, post_platform, sm_id }) => {
  const formValidation = new FormValidation();
  const [postLink, setPostLink] = useState('');
  const [formDataList, setFormDataList] = useState<FormData[]>([]);
  const [errorPostLink, setErrorPostLink] = useState<ErrorTypes>();
  const [previousLinks, setPreviousLinks] = useState<string[]>([]);
  const [popupMessage, setPopupMessage] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);


  const handleSubmit = async () => {
    let is_valid_link =false
    if(post_platform == "Instagram"){
      setErrorPostLink(formValidation.CheckInstagramUrl(postLink));
      is_valid_link = formValidation.CheckInstagramUrl(postLink).status
    }
    else
    {
      setErrorPostLink(formValidation.CheckYouTubeUrl(postLink));
      is_valid_link = formValidation.CheckYouTubeUrl(postLink).status
    }
    if(is_valid_link){
      const formData: FormData = { postLink };
      setFormDataList([...formDataList, formData]);
      setPostLink('');
    }
  };

  const removeItem = (index: number) => {
    const updatedFormDataList = formDataList.filter((_, i) => i !== index);
    setFormDataList(updatedFormDataList);
    setPreviousLinks([]);
    localService.toastify("Post link deleted successfully!");
  };


  const handleSubmitUserForm = async () => {
    const res = await applyCampaignService.uploadPostLinkAnalytics({
      data: {
        campaign_id: campaign_id,
        post_platform: post_platform,
        formDataList: formDataList,
        sm_id: sm_id
      },
    });
    if (res) {
      if (res.data.status.status == true) {
        localService.toastify(res?.data?.status?.message);
        onClose()
      }
      else {
        setOpenModal(true)
        setPopupMessage(res?.data?.status?.message)
        // localService.toastify(res?.data?.status?.message);
      }
    }
  };

  const handlePostLinkChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (previousLinks.includes(value)) {
      setErrorPostLink({ status: true, message: "Post Link is a duplicate" });
    } else {
      setPostLink(value);
      setPreviousLinks([...previousLinks, value]);
    }  }
  return (
    <>
      <CommonPopUp
        modalTitle="Add Posts"
        openModal={open}
        onClose={onClose}
        className="modal-medium"
      >
        <div className='row mt-3 pb-3'>
          <div className="col">
            <TextField
              label="Post Link"
              value={postLink}
              className="w-100 mt-0"
              onChange={handlePostLinkChange}

              size="small"
              fullWidth
              margin="normal"
            />
            <span className="error">{errorPostLink?.message}</span>
          </div>

          <div className="col-auto">
            <button
              onClick={handleSubmit}
              className="btn btn-outline-primary">
              Add Posts
            </button>
          </div>
        </div>
        {
          (() => {
            if (formDataList.length > 0) {
              return (
                <>
                  <TableComponent
                    columnDisplayName={columnDisplayName}
                    orderByColumnName=""
                    inputDataSource={formDataList}
                    sortingColumnType={sortingColumnType}
                    renderData={formDataList}
                    setRenderData={(e) => setFormDataList(e)}
                  >
                    {formDataList.map((data: any, index: number) => {
                      return (
                        <TableRow key={index} hover role="checkbox">
                          <TableCell>{data?.postLink}</TableCell>
                          <TableCell>
                            <span style={{ cursor: 'pointer' }}
                              onClick={(e) => removeItem(index)} className='material-symbols-rounded'>
                              delete
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  </TableComponent>
                  <button className='my-3 btn btn-primary' onClick={handleSubmitUserForm}>Submit</button>
                </>
              )
            }
          })()
        }
      </CommonPopUp>

      {openModal && (
        <CommonPopUp
          modalTitle={popupMessage.includes('30') ? "" : "Upgrade to Premium:"}
          modalContent=""
          openModal={openModal}
          onClose={onClose}
          className="modal-medium mx-auto"
        >
          <div className="">
            <div>
              <p>
                {popupMessage}
                {popupMessage.includes('Barter') && (
                  <a className="ms-2" href={`${BASE_URL_WEB}/contact_us`}>
                    contact us
                  </a>
                )}
              </p>
            </div>

            <div className='popup-footer' style={{ marginTop: "32px" }}>
              <div className='row'>
                <div className='col text-end'>
                  <a className="btn btn-primary ms-2 btn-mui" href={`${BASE_URL_WEB}/contact_us`}>
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
    </>
  );
};

export default AddAnalytics;