import { RefObject, useEffect } from 'react';

const useConditionalFocus = (ref: RefObject<HTMLDivElement>, condition: boolean) => {
    useEffect(() => {
      if (condition && ref.current) {
        ref.current.focus();
      }
    }, [condition, ref]);
  };

export default useConditionalFocus;