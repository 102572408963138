
import React from 'react'
import { Methods } from '../../../core/utility';
import { Grid, IconButton, Tooltip } from '@mui/material';
import MaterialSymbol from '../../../components/icons/material-symbol';
import IconComment from '../../../components/icons/icon-comment';

const methods = new Methods()

type Props = {
  data: []
  loading: boolean;
}

const YTMostViewedVideos = (props: Props) => {
  return (
    <Grid container spacing={3}>{props?.data && props?.data?.length > 0 && props?.data?.map((img: any, index: number) => {
      return <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
        <div className="card-posts">
          <div className="card-posts-image embed-responsive embed-responsive-1by1">
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              className="embed-responsive-item"
              frameBorder={0}
              height={315}
              src={`https://www.youtube.com/embed/${img?.video_id}`}
              title="YouTube video player"
              style={{width:'100%'}}
            />
          </div>
          <div className="card-posts-info yt">
            <ul className="yt-ul-list">
              {img?.view_count > 0 &&
                <li>
                <Tooltip title="Views" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='visibility' fontweight='200' className='text-white' fontSize='24px' lineHeight='24px'/>
                      </IconButton>
                    </Tooltip>
                  {/* <strong>Views</strong> */}
                  <span>{img?.view_count !== 0 && methods.convertNumber(img?.view_count)}</span>
                </li>
              }
              {img?.comment_count > 0 &&
                <li>
                  {/* <strong>Comments</strong> */}
                  <Tooltip title="Comments" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <IconComment />
                      </IconButton>
                    </Tooltip>
                  <span>{img?.comment_count !== 0 && methods.convertNumber(img?.comment_count)}</span>
                </li>
              }
              {img?.estimated_minutes_watched > 0 &&
                <li>
                  
                  {/* <strong>Est. Min Watched</strong> */}
                  <Tooltip title="Est. min watched" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='timer_play' fontweight='200' className='text-white' fontSize='24px' lineHeight='24px'/>
                      </IconButton>
                    </Tooltip>
                  <span>{img?.estimated_minutes_watched !==0 && methods.convertNumber(img?.estimated_minutes_watched)}</span>
                </li>
              }
              {img?.average_view_duration > 0 &&
                <li>
                  {/* <strong>Avg. view duration</strong> */}
                  <Tooltip title="Avg. view duration" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='timelapse' fontweight='200' className='text-white' fontSize='24px' lineHeight='24px'/>
                      </IconButton>
                    </Tooltip>
                  <span>{img?.average_view_duration !==0 && `${img?.average_view_duration} Mins`}</span>
                </li>
              }
              {img?.like_count > 0 &&
                <li>
                  {/* <strong>Likes</strong> */}
                  <Tooltip title="Likes" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='thumb_up' fontweight='200' className='text-white' fontSize='24px' lineHeight='24px'/>
                      </IconButton>
                    </Tooltip>
                  <span>{img?.like_count > 0 && methods.convertNumber(img?.like_count)}</span>
                </li>
              }
              {/* <li>
                <strong>Dislikes</strong>
                <span>{img?.dislikes === 0 ? "NA" : methods.convertNumber(img?.dislikes)}</span>
              </li> */}
            </ul>
          </div>
        </div>
      </Grid>
    })}
    </Grid>
  )
}

export default YTMostViewedVideos