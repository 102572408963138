import React from 'react'
import FollowerCardInline from '../follower-card-inline'
import { Methods } from '../../../../core/utility';

type Props = {
    influencerData: any
}
const methods = new Methods();

const PostFrequency = ({ influencerData }: Props) => {
    return (
        <>{(influencerData?.detail?.[0]?.post_frequency > 0 || influencerData?.detail?.[0]?.average_profile_views > 0 || influencerData?.detail?.[0]?.total_impressions > 0) &&
        <div className="follower-sec-inline">
            {influencerData?.detail?.[0]?.post_frequency > 0 &&
            <FollowerCardInline title="Post Frequency" value={influencerData?.detail?.[0]?.post_frequency
                && `${methods.convertNumber(influencerData.detail[0].post_frequency)}/week`
                } />
            }
            {influencerData?.detail?.[0]?.average_profile_views > 0 &&
                <FollowerCardInline title="Avg. Profile Views" value={`${methods.convertNumber(influencerData?.detail?.[0]?.average_profile_views)}/day`} />
            }
            {influencerData?.detail?.[0]?.total_impressions > 0 &&
                <FollowerCardInline tooltip tooltipText="Total number of times the IG User's IG Media have been viewed. Includes ad activity generated through the API, Facebook ads interfaces, and the Promote feature. Does not include profile views." title="Impressions" value={methods.convertNumber(influencerData?.detail?.[0]?.total_impressions)} />
            }
        
        </div>
        }</>
    )
}

export default PostFrequency

