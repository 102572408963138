import React, {useEffect, useState } from 'react';
import * as Utils from '../../../../../../../core/utility';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';


const methods: Utils.Methods = new Utils.Methods();


/**
 * ************************FLOW OF THE NEGOTIATION INPUT COMPONENT *******************************
 * It will take single object as a prop and will return the input fields for the negotiation.
 * All actions will be handle own.
 */

/* Pending is opposite of Negotiating means if the status is pending then the Negotiating button, field and etc.. will be disabled. */



type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';
type CurrentSteps = 1 | 2 | 3 | 4;

/**
 * Represents the data structure for a form used in negotiation input.
 */
type FormDataType = {
    negotiation_id: number;
    proposal_id: number;
    campaign_id: number;
    influencer_id: number;
    sm_id: number;
    deliverable_item: string;
    s_0_quantity: number;
    s_0_price: number;
    s_0_status: StatusType;
    s_0_date: string;
    s_1_quantity: number;
    s_1_price: number;
    s_1_status: StatusType;
    s_1_date: string;
    s_2_quantity: number;
    s_2_price: number;
    s_2_status: StatusType;
    s_2_date: string;
    s_3_quantity: number;
    s_3_price: number;
    s_3_status: StatusType;
    s_3_date: string;
    current_step: CurrentSteps;
    status: StatusType;
    proposal_datetime: string;
    created_at: string;
    updated_at: string;
};

const initalDisableButtons = {
    acceptbtn: false,
    rejectbtn: false,
    editQty: false,
    editAmount: false,
    lockIcon: false,
    allElement: false,
};

const intialVisableFields = {
    closeIcon: false,
    lockIcons: true,

}

interface Props {
    onChange: (data: FormDataType) => void;
    className?: string;
    dataSet: FormDataType;
    userType: 'Influencer' | 'brand';
    dataLength?: number;

};


export const NegotiateInput: React.FC<Props> = ({ onChange, className = '', dataLength, dataSet, userType, ...arg }: Props) => {
    const [random] = useState(methods.getRandNumber());
    const [formData, setFormData] = useState<FormDataType>(dataSet);
    const [disabledFields, setDisabledFields] = useState(initalDisableButtons);
    const [visableFields, setVisableFields] = useState(intialVisableFields);
    const [accepted, setAccepted] = useState(false);
    const [rejected, setRejected] = useState(false);

    const handleFocus = () => {
        const sibling1:any = document.getElementById(`Negotiate-Input-Price-${random}`);
        const sibling2:any = sibling1?.nextElementSibling;
        if (sibling2) {
            const child = sibling2.querySelector('span');
            if (child) {
                child.classList.remove('new-class');
            }
            }
        };
        
          const handleBlur = () => {
            const sibling1:any = document.getElementById(`Negotiate-Input-Price-${random}`);
            const sibling2:any = sibling1?.nextElementSibling;
                if (sibling2) {
                const child = sibling2.querySelector('span');
                if (child) {
                    child.classList.add('new-class');
                }
            }
        }
    
    useEffect(() => {
        let getStat = dataSet[genrateObjectKey(dataSet.current_step, 'status')] === 'NonNegotiable';
        setDisabledFields(prev => (
            {
                ...prev,
                allElement: getStat,
                lockIcon: getStat,
            }
        ))

    }, [])

    
    useEffect(() => {
        const StatusKey = `s_${(dataSet as any).current_step}_price`;

        const previousStatusKey = `s_${(dataSet as any).current_step - 1}_price`;
        setVisableFields(prev => (
            
            {
                ...prev,
                closeIcon: (formData as any)[StatusKey] === (formData as any)[previousStatusKey] ? false : true,

            }
        ));
        setDisabledFields(prev => (
            {
                ...prev,
                lockIcon: (formData as any)[StatusKey] === (formData as any)[previousStatusKey] ? false : true,
            }
        ))
        onChange(formData);
    }, [formData]);
    /**
     * Handles the input change event for the negotiate input.
     * If the current step is 1, the quantity is updated. Accept and reject buttons are disable.
     */
    useEffect(() => {
        const buttonDisablity = {
            ...disabledFields,
            acceptbtn: false,
            rejectbtn: false,
            lockIcon:false


        };
        if (visableFields.closeIcon) {
            buttonDisablity.acceptbtn = true;
            buttonDisablity.rejectbtn = true;
            // buttonDisablity.lockIcon = true;
        }

        if (JSON.stringify(buttonDisablity) === JSON.stringify(disabledFields)) {
            return;
        }

        setDisabledFields(prev => ({ ...prev, ...buttonDisablity }))

    }, [visableFields]);



    /**
     * Handles the input change event for the negotiate input.
     * 
     * @param e - The change event object.
     * @param current_step - The current step value (default: 1).
     * @param need - The need value ('quantity' or 'price').
     */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, current_step: CurrentSteps = 1, need: ("quantity" | 'price')) => {
        if(e.target.value == ''){
            setFormData(prev => ({ ...prev, [genrateObjectKey(current_step, need)]: 0 }))
        }
        else{
            const { value } = e.target;
            setFormData(prev => ({ ...prev, [genrateObjectKey(current_step, need)]: parseInt(value) }));

        }
    };

    /**
     * Retrieves the input value from the form data based on the current step and the specified need.
     * @param currentStep The current step of the negotiation process.
     * @param need The specific need, either "quantity" or "price".
     * @returns The value of the input for the given current step and need, or 0 if not found.
     */
    const getInputValue = (currentStep: CurrentSteps = 1, need: ("quantity" | 'price')) => {
        return formData[genrateObjectKey(currentStep, need)] || 0;
    };


    /**
     * Reverts the key-value pair in the form data based on the current step and need.
     * 
     * @param currentStep - The current step in the negotiation process. Defaults to 1.
     * @param need - The need to revert, either "quantity" or "price".
     */
    const revertKeyValue = (currentStep: CurrentSteps = 1, need: ("quantity" | 'price')) => {
        const previousStatusKey = `s_${(dataSet as any).current_step - 1}_${need}`;
        setFormData(prev => {
            const newKey = genrateObjectKey(currentStep, need);
            return { 
                ...prev, 
                [newKey]: (dataSet as any)[previousStatusKey] 
            };
        });    
        setAccepted(false);
        setRejected(false);
    }


    /**
     * Updates the form data based on the selected value.
     * @param value - The selected value.
     * @param currentStep - The current step number.
     * @param need - The type of data to update ('status', 'quantity', or 'price').
     */
    function handleButtonsValue(value: StatusType, currentStep: number, need: 'status' | 'quantity' | 'price') {
        setFormData(prev => ({ ...prev, [genrateObjectKey(currentStep as CurrentSteps, need)]: value }));
        if (value === 'Rejected') {
            setRejected(true);
            setAccepted(false);
        }

        if (value === 'Accepted') {
            setAccepted(true);
            setRejected(false)
        }
    };

function handleLocks() {
    const statusKey = `s_${(dataSet as any).current_step}_status`;
    const previousStatusKey = `s_${(dataSet as any).current_step - 1}_status`;

    const status = (dataSet as any)[statusKey];
    const previousStatus = (dataSet as any)[previousStatusKey];
    if (status === "Rejected" || status === "Accepted" ||(previousStatus === "NonNegotiable")) {
            
    } 
    else {
        disabledFields?.lockIcon == false ? (dataSet as any)[statusKey] = "NonNegotiable" : (dataSet as any)[statusKey] = "Negotiating" ;
        setDisabledFields(prev => ({ 
            ...prev, 
            lockIcon: !prev.lockIcon,
            editQty: !prev.editQty, 
            editAmount: !prev.editAmount, 
            acceptbtn: !prev.acceptbtn, 
            rejectbtn: !prev.rejectbtn 
        }));
        setAccepted(false);
        setRejected(false);

    } 
}
      

    function handleAccept(){
        // setDisabledFields(prev => ({ ...prev, lockIcon: true }));
        setDisabledFields(prev => ({ ...prev,
            lockIcon: true, 
            editQty: true,
            editAmount: true, 
            acceptbtn: true, 
            rejectbtn: true
            }));
        setRejected(false);
  
    }

    function handleReject(){
        // setDisabledFields(prev => ({ ...prev, lockIcon: true }));
        setDisabledFields(prev => ({ ...prev,
            lockIcon: true, 
            editQty: true,
            editAmount: true, 
            acceptbtn: true, 
            rejectbtn: true
            }));
        setAccepted(false);
    }


    /**
     * Generates an object key based on the current step and the need.
     * @param currentStep The current step (default value: 1).
     * @param need The need ("quantity" or "price").
     * @returns The generated object key.
     */
    const genrateObjectKey = (currentStep: CurrentSteps = 1, need: ("quantity" | 'price' | 'status')) => {
        return `s_${currentStep}_${need}` as keyof FormDataType || '';
    };


    useEffect(() => {
        if (disabledFields.allElement) {
            setDisabledFields(prev => ({ ...prev, editQty: true, editAmount: true, lockIcon: true }))
        }
    }, [disabledFields.allElement])
    const isDisableQuantity = () => {
        const statusKey = `s_${(dataSet as any).current_step}_status`;
        const previousStatusKey = `s_${(dataSet as any).current_step - 1}_status`;
        const previoustatus = (dataSet as any)[previousStatusKey];


        const status = (dataSet as any)[statusKey];
        if(userType === 'Influencer'){
            return disabledFields.editQty;
        }
        
        if (status === "Rejected" || status === "Accepted" || dataSet.deliverable_item === "digital_rights"  || dataSet.deliverable_item === "digital_rights" || dataSet.deliverable_item ==="available_for_exclusivity" || previoustatus === "NonNegotiable") 

        {
            return true;
        } 
        else {
            return disabledFields.editQty;
        }
       
    };
    const isDisablePrice = () => {
        const statusKey = `s_${(dataSet as any).current_step}_status`;
        const previousStatusKey = `s_${(dataSet as any).current_step - 1}_status`;
        const previoustatus = (dataSet as any)[previousStatusKey];
        const status = (dataSet as any)[statusKey];
        if (status === "Rejected" || status === "Accepted" || previoustatus === "NonNegotiable") {
            return true;
        } else {
            return disabledFields.editAmount;
        }
    };
    const isDisabledAcceptedBtn =()=>{
        const statusKey = `s_${(dataSet as any).current_step}_status`;
        const previousPrice = `s_${(dataSet as any).current_step -1 }_price`;
        const currentPrice = `s_${(dataSet as any).current_step}_price`;
        const previousQuantity = `s_${(dataSet as any).current_step-1}_quantity`;
        const currentQuantity= `s_${(dataSet as any).current_step}_quantity`;

        const status = (dataSet as any)[statusKey];
        if (status === "Rejected" || status === "Accepted" ||(formData as any)[previousPrice] !== (formData as any)[currentPrice] || (formData as any)[previousQuantity] !== (formData as any)[currentQuantity]) {
            return true;
        } else {
            return disabledFields.acceptbtn;
        }
    }
    const isDisabledRejectBtn =()=>{
        const statusKey = `s_${(dataSet as any).current_step}_status`;
        const previousPrice = `s_${(dataSet as any).current_step -1 }_price`;
        const currentPrice = `s_${(dataSet as any).current_step}_price`;
        const previousQuantity = `s_${(dataSet as any).current_step-1}_quantity`;
        const currentQuantity= `s_${(dataSet as any).current_step}_quantity`;

        const status = (dataSet as any)[statusKey];
    
        if (status === "Rejected" || status === "Accepted" ||(formData as any)[previousPrice] !== (formData as any)[currentPrice] || (formData as any)[previousQuantity] !== (formData as any)[currentQuantity]) {
            return true;
        } else {
            return disabledFields.rejectbtn;
        }

    }
    const getStatus = (step: number, dataSet: FormDataType): StatusType => {
        return dataSet[genrateObjectKey(dataSet.current_step, 'status')] as StatusType;
    };
    const isRejected = getStatus(dataSet.current_step, dataSet) === 'Rejected';
    const isAccepted = getStatus(dataSet.current_step, dataSet) === 'Accepted';

    return (
        <div className={`nego-input-container`} >
            <section className={`input-section-disable-class table-negotiation ${isRejected ? 'rejected' : ''} ${isAccepted ? 'accepted' : ''}`}>
                <span>
                    <input type='text'
                        value={(formData?.deliverable_item !== 'available_for_exclusivity' && formData?.deliverable_item !== 'digital_rights') ? getInputValue(formData.current_step, 'quantity') : "-"}
                        className='negotiate-input'
                        onChange={(event) => handleInputChange(event, formData.current_step, 'quantity')}
                        disabled={isDisableQuantity() || (userType === 'Influencer')}
                        id={`Negotiate-Input-Quantity-${random} `}
                        name='quantity' style={{ minHeight: '32px', width: '100%' }} />
                </span>
                <span>
                    <fieldset className='input-fields'>
                        <input type='text'
                            disabled={isDisablePrice()}
                            value={getInputValue(formData.current_step, 'price')}
                            className='negotiate-input'
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onChange={(event) => handleInputChange(event, formData.current_step, 'price')}
                            name='price'
                            id={`Negotiate-Input-Price-${random}`} style={{
                                minHeight: '32px',
                                width: '100%'
                            }} />
                        <fieldset className={formData[`s_${formData.current_step - 1}_price` as keyof FormDataType] !== formData[`s_${formData.current_step }_price` as keyof FormDataType] ? "icons-section" : "icons-section disabled"}>
                            {visableFields.closeIcon   && <MaterialSymbol onClick={() => revertKeyValue(formData.current_step, 'price')} className={`closeIcon pointer `} iconName='close' fontweight='200' />}
                            {visableFields?.lockIcons && (
                                <>
                                    {formData[`s_${formData.current_step - 1}_status` as keyof FormDataType] === 'NonNegotiable' || disabledFields.lockIcon === true ? (
                                        <MaterialSymbol
                                            onClick={() => {
                                                if (formData[`s_${formData.current_step - 1}_status` as keyof FormDataType] !== 'NonNegotiable') {
                                                    handleLocks();
                                                }
                                            }}
                                            className={`lockIcon pointer ${disabledFields.lockIcon ? 'disable-icon' : ''}`}
                                            iconName='lock'
                                            fontweight='200'
                                        />
                                    ) : (
                                        <MaterialSymbol
                                            onClick={() => {
                                                if (formData[`s_${formData.current_step - 1}_status` as keyof FormDataType] !== 'NonNegotiable') {
                                                    handleLocks();
                                                }
                                            }}
                                            className={`lockIcon pointer ${disabledFields.lockIcon ? 'disable-icon' : ''}`}
                                            iconName='lock_open'
                                            fontweight='200'
                                        />
                                    )}
                                </>
                            )}
                        </fieldset>
                    </fieldset>
                </span>

            </section>
            <fieldset className={`buttons-field ${isRejected || isAccepted ? "d-none" :"" } `}>
                <button
                    id={`reject-btn-id-${random} `}
                    className={`buttons reject-btn btn-sm btn btn-outline-error reject-btn-border ${rejected ? 'disable-btn rejected' : ''} `}

                    onClick={() => {
                        handleButtonsValue('Rejected', formData.current_step, 'status')
                        handleReject()                    
                    }
                    }
                    disabled={isDisabledRejectBtn()}>{rejected ? <MaterialSymbol iconName='sentiment_sad' iconFilled={true} fontweight='200' className='d-flex' /> : "Reject"}</button>

                <button
                    id={`accept-btn-id-${random} `}
                    onClick={() => {
                        handleButtonsValue('Accepted', formData.current_step, 'status')
                        handleAccept()

                    }
                    }
                    className={`buttons accept-btn btn-sm btn btn-outline-primary ${accepted ? 'disable-btn accepted' : ''}`}
                    disabled={isDisabledAcceptedBtn()}>{accepted ? <MaterialSymbol iconName='sentiment_satisfied' fontweight='200' className='d-flex' iconFilled={true} /> : "Accept"}</button>

            </fieldset>
        </div>
    );
}