import React, { useState, useEffect } from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
import { Editor, ImageCrop } from "../../../../../components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CreateCampaign, Loading } from "../../../../../redux/action";
import { FormValidation } from "../../../../../core/utility";
import {
  CreateCampaign as Create_Campaign,
  LocalService,
} from "../../../../../core/services";
import defaultImage from "../../../../../../src/assets/images/image-fill.svg";
import "./campaign-product.scss";
import FileUploadPopup from '../../../../../components/popup/file-upload-popup';

const formValidation: FormValidation = new FormValidation();
const localService: LocalService = new LocalService();

interface Props {
  UserReducer: any;
  UserID: any;
  campaignReducer: any;
  CreateCampaignReducer?: (data: any) => void;
  loading?: (data: any) => void;
}

const CampaingProduct: React.FC<Props> = (props: Props) => {
  const path = `accounts/${props.UserReducer.account_id}/plans/${props.UserReducer.user_id}/product`;
  const [loading, setLoading] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  const [links, setLinks] = useState("");
  const [formErrors, setFormErrors] = useState({
    description: { status: true, message: "" },
    cost: { status: true, message: "" },
    imageupload: { status: true, message: "" },
    barterProduct: { status: true, message: "" },
    link: { status: true, message: "" },
  });
  const [formData, setFormData] = useState({
    user_id: props.UserID,
    brand_id: props.UserReducer.brand_id,
    product_id: "",
    campaign_id: props?.campaignReducer?.createCampaign?.campaign_id,
    product_name: "",
    product_description: "",
    product_cost: "",
    product_image_url: "",
    product_image_path: "",
    reference_link: [],
    ...props.campaignReducer.product,
  });

  const navigate = useNavigate();

  //handle
  useEffect(() => {
    if (props.campaignReducer.createCampaign === null) {
      navigate("/brand/plans");
    }
  }, [props]);

  const handleSave = async () => {
    setLoading(true);
    const promise = new Promise(async (resolve, reject) => {
      Create_Campaign.createProduct(formData).then((res: any) => {
        if (res?.data?.rows?.status) {
          if (props.CreateCampaignReducer) {
            setFormData({
              ...formData,
              product_id: res?.data?.rows?.product_id,
            });
            props.CreateCampaignReducer({
              product: {
                ...formData,
                product_id: res.data.rows.product_id,
              },
            });
            // props.CreateCampaignReducer({product: formData})
          }
          navigate("/brand/plans");
          // localService.toastify(res?.data?.rows?.message, "success", 1000);
        } else {
          localService.toastify(res?.data?.rows?.message, "error", 1000);
        }

        resolve(res.data.rows);
        setLoading(false);

      });
    }).catch((error) => {
      setLoading(false);
      throw new Error(`handle Save Product function error :: ${JSON.stringify(error)}`)
    })

    return promise;
  };

  const handleNext = async () => {
    //form validation
    const description = formValidation.getdescriptionIsValid(
      formData.product_description
    );
    const cost = formValidation.GetNameValidate(`${formData.product_cost}`);
    const barterProduct = formValidation.GetNameValidate(formData.product_name);
    const _Links =
      formData.reference_link.length > 0
        ? { status: true, message: "" }
        : { status: false, message: "Please add atleast one link" };
    const imageupload = formValidation.GetNameValidate(
      formData.product_image_url
    );

    setFormErrors((e: any) => {
      e.description = description;
      e.cost = cost;
      e.imageupload = imageupload;
      e.barterProduct = barterProduct;
      e.link = _Links;
      return { ...e };
    });

    // setFormErros({
    //     description: { ...description },
    //     cost: { ...cost },
    //     imageupload: { ...imageupload },
    // });

    // console.warn(description, cost, lin, imageupload);

    // console.warn(formData);

    if (
      description.status &&
      cost.status &&
      _Links.status &&
      imageupload.status
    ) {
      //call savefunction
      const response: any = await handleSave();
      if (response.status) {
        if (props.CreateCampaignReducer) {
          props.CreateCampaignReducer({
            navigation: {
              ...props.campaignReducer.navigation,
              influencer: true,
            },
            completeStep: {
              ...props.campaignReducer.completeStep,
              createProduct: true,
            },
          });
        }
        //navigation
        // /brand/campaign/influencer
        // console.warn('/brand/campaign/influencer')
        navigate("/brand/campaign/influencer");
      }
    }
  };

  const handleBack = () => {
    navigate("/brand/campaign/createcampaign");
  };

  const handleAddLink = (url: string) => {
    if (formValidation.isValidURL(url)) {
      if (formData.reference_link.length != 3) {
        let reference_links: any = formData.reference_link;
        reference_links.push(url);
        // setFormData({
        //   ...formData,
        //   reference_link: reference_links,
        // });
        setFormData((e: any) => {
          e.reference_link = reference_links;
          return { ...e }
        })
        setLinks("");
        setFormErrors((e) => ({
          ...e,
          link:{ status: true, message: "" }        
        }));
      } else {
        localService.toastify("Limit Only three Links", 'info');
      }
    }else{
      setFormErrors((e) => ({
        ...e,
        link:{ status: false, message: "Please enter valid link" },
      }));
    }
  };

  const handleRemoveLink = (index: number) => {
    let ref = [...formData.reference_link];
    ref.splice(index, 1);
    setFormData({ ...formData, reference_link: ref });
  };

  return (
    <div className="container-fluid campaign_product_main_container">
      <div className="row mb-3">
        <div className="col-md-9">
          <div className="row mb-3">
            <div className="col-md-6">
              <TextField
                className="w-100"
                error={!formErrors.barterProduct.status}
                helperText={formErrors.barterProduct.message}
                value={formData.product_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    product_name: e.target.value,
                  })
                }
                size="small"
                type="text"
                name="product_name "
                label="Barter product"
                placeholder="Barter product"
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Barter product worth"
                name="cost"
                id="outlined-start-adornment"
                size="small"
                value={formData.product_cost}
                error={!formErrors.barterProduct.status}
                helperText={formErrors.barterProduct.message}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    product_cost: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="create_campaign_editor mb-3">
            <strong>Description</strong>
            <Editor
              editorValues={formData.product_description}
              getEditorValue={(e) =>
                setFormData({
                  ...formData,
                  product_description: e,
                })
              }
            />
            <span className="error">{formErrors.description.message}</span>
          </div>
          <div className="col product_add mt-2">
            <TextField
              className="w-100"
              label="Link"
              name="link"
              id="first_link_id"
              size="small"
              value={links}
              onChange={(e) => {setLinks(e.target.value)}}
              error={!formErrors.link.status}
              helperText={formErrors.link.message}
            //   InputProps={{
            //       startAdornment: <InputAdornment position="start">https://</InputAdornment>,
            //   }}
            />
            <Button
              className="add_button btn btn-outline-primary"
              onClick={() => handleAddLink(links)}
              variant="contained"
            >
              Add
            </Button>
          </div>
          {formData.reference_link.length > 0 ? (
            <div className="row">
              <div className="col list_ul">
                <ul>
                  {formData.reference_link.length > 0
                    ? formData.reference_link.map(
                      (res: string, index: number) => {
                        return (
                          <li key={index}>
                            {res}{" "}
                            <span>
                              <CloseIcon
                                className="close_button"
                                onClick={() => handleRemoveLink(index)}
                              />
                            </span>
                          </li>
                        );
                      }
                    )
                    : ""}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-md-3">
          <div className=" product_image_section">
            <div className="cover_image_section">
              <span className="error">{formErrors.imageupload.message}</span>
              {formData.product_image_url.length > 0 ? (
                <img
                  aria-label="cropped image"
                  className="product_image"
                  src={
                    formData.product_image_url.length > 0
                      ? formData.product_image_url
                      : defaultImage
                  }
                />
              ) : (
                <img
                  aria-label="cropped image"
                  className="product_image"
                  src={defaultImage}
                />
              )}
              {showUploadPopup && (
                <FileUploadPopup openModal={showUploadPopup} onClose={(e) => setShowUploadPopup(false)} onSend={(e: any) =>
                  setFormData({
                      ...formData,
                      product_image_url: e.url,
                      product_image_path: e.key,
                  })
              }
                  path={path}
                  acceptedFileTypes=".jpg, .jpeg, .png" />
              )}
              <Button
                className="btn btn-outline-primary upload_button"

                onClick={() => setShowUploadPopup(true)}
                variant="contained"
              >
                Upload Image
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="button_group">
          <div className="col campaign_product_buttons">
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              className="btn btn-outline-primary me-auto ms-0"
              variant="outlined"
              onClick={handleBack}
            >
              Back
            </LoadingButton>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              className="btn btn-outline-primary"
              variant="outlined"
              onClick={handleSave}
            >
              Save as draft
            </LoadingButton>

            <LoadingButton
              loading={loading}
              loadingPosition="start"
              className="btn btn-primary"
              variant="contained"
              onClick={handleNext}
            >
              Next
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    UserID: state.UserID.userID,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaingProduct);

export { connectToRedux as CampaingProduct };
