import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { AdminDashboardService } from "../../../../core/services/admin-dashboard.service";
import { TableComponent } from "../../../../components/table-component/table";

const adminDashboardService: AdminDashboardService = new AdminDashboardService();
interface Props {
  triggerUpdate?: boolean
}

const columnDisplayName = {
  channel_id: "Channel Id",
  name: "Channel Name",
  phone: "Phone",
  email_id: "Email",
  gender: "Gender",
  location_id: "Location",
  category: "Genre",
  language: "Language",
  subscriber: "Subscriber",
  campaign: "Campaign",
  brand: "Brand",
  campaign_hashtags: "Campaign Hashtags",
  cost_of_dedicated_video: "Cost of Dedicated Videos",
  cost_of_integrated_video: "Cost of Integrated Videos",
  cost_of_yt_shorts: "Cost of YT Short",
  avg_views_of_video: "Average View of a Video",
  brands_worked_past: "Brands Worked with (Past 3 Months)"
};

const sortingColumnType = [
  { name: "channel_id", columnSize: "250px" },
  { name: "name", columnSize: "200px" },
  { name: "phone", columnSize: "100px" },
  { name: "email_id", columnSize: "150" },
  { name: "gender", columnSize: "100px" },
  { name: "location_id", columnSize: "150px" },
  { name: "category", columnSize: "150px" },
  { name: "language", columnSize: "150px" },
  { name: "subscriber", columnSize: "120px" },
  { name: "campaign", columnSize: "150px" },
  { name: "brand", columnSize: "150px" },
  { name: "campaign_hashtags", columnSize: "250px" },
  { name: "cost_of_dedicated_video", columnSize: "250px" },
  { name: "cost_of_integrated_video", columnSize: "250px" },
  { name: "cost_of_yt_shorts", columnSize: "250px" },
  { name: "avg_views_of_video", columnSize: "250px" },
  { name: "brands_worked_past", columnSize: "200px" },
];


export const InfluencerYoutube: React.FC<Props> = (props: Props)=> {
  const [renderData, setRenderData] = useState<any>([]);
  const [externalInfluencer, setExternalInfluencer] = useState<any>([]);

  async function getExternalInfluencerYoutube() {
    try {
      const res = await adminDashboardService.getExternalInfluencerYoutube();
      if (res.status) {
        setExternalInfluencer(res?.data.rows);
      }
    } catch (error) {
      console.error('Error fetching getExternalInfluencerInstagram:', error);
    } 
  }

  useEffect(() => {
    getExternalInfluencerYoutube()
  }, [props.triggerUpdate])

  return (
    <section>
      <div className="talent_verification_tab">

        <TableComponent
          pagination={true}
          columnDisplayName={columnDisplayName}
          sortingColumnType={sortingColumnType}
          inputDataSource={externalInfluencer}
          orderByColumnName=""
          renderData={renderData}
          setRenderData={setRenderData}
        >
          {renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} >
                <TableCell>{data.channel_id}</TableCell>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.phone}</TableCell>
                <TableCell>{data.email_id}</TableCell>
                <TableCell>{data.gender}</TableCell>
                <TableCell>{data.location_id}</TableCell>
                <TableCell>{data.category}</TableCell>
                <TableCell>{data.language}</TableCell>
                <TableCell>{data.subscriber ? data.subscriber : ''}</TableCell>
                <TableCell>{data.campaign}</TableCell>
                <TableCell>{data.brand}</TableCell>
                <TableCell>{data.campaign_hashtags}</TableCell>
                <TableCell>{data.cost_of_dedicated_video}</TableCell>
                <TableCell>{data.cost_of_integrated_video}</TableCell>
                <TableCell>{data.cost_of_yt_shorts}</TableCell>
                <TableCell>{data.avg_views_of_video}</TableCell>
                <TableCell>{data.brands_worked_past}</TableCell>
              </TableRow>
            )
          })}
        </TableComponent>
      </div>
    </section>
  );
};
