import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

export const InfluencerPreferences: React.FC = () => {
    return (
        <>
            <strong className='d-block'>Campaign Preferences</strong>
            <p className='fs-14'>Are you interested in working for?</p>

            <div className='row mb-3'>
                <div className='col'>
                    <FormGroup>
                        <FormControlLabel
                            className='notification-checklist'
                            control={<Checkbox />}
                            label='Cost per view(CPV) based campaigns'
                        />
                        <FormControlLabel
                            className='notification-checklist'
                            control={<Checkbox />}
                            label='Performance(Sales/Conversion) based campaigns'
                        />
                    </FormGroup>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Button variant='contained' className='btn btn-primary'>
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
};
