import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { InvoiceService, LocalService } from "../../../../../../core/services";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  InputAdornment,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@mui/material";
// important common popup component
import CommonPopUp from "../../../../../../components/popup/common-popup";
import { useParams } from 'react-router-dom';

const invoiceService = new InvoiceService();
const localService = new LocalService();

interface Proposal {
  name: string;
  total_price: number;
  payment_status: string;
  account_type?: string;
  account_holder_name?: string;
  account_number?: string;
  ifsc_code?: string;
  address?: string;
  influencer_id: number;
  proposal_id: number;
  file_urls: string;
}

interface RenderAcknoledgeReceiptFormProps {
  UserReducer: any;
  acceptedProposalPaymentDetails: Proposal[];
  proposal: Proposal;
  onUpdatePaymentStatus: (updatedProposal: Proposal) => void;
}

const RenderAcknoledgeReceiptForm: React.FC<RenderAcknoledgeReceiptFormProps> = ({ proposal, onUpdatePaymentStatus, UserReducer, acceptedProposalPaymentDetails }) => {

  interface PaymentReceipt {
    id: number;
    amount: number;
    transaction_id: string;
    comments: string;
    fileUrls: string[];
    payment_acknowledgement: string;
    proposal_id: string;
  }

  // Initialize the state with an array of PaymentReceipt objects
  const [paymentReceipts, setPaymentReceipts] = useState<PaymentReceipt[]>([]);
  const {smId} = useParams();

  // Acknowledgement Modal form data
  const [acknowledgementFormData, setAcknowledgementFormData] = useState<PaymentReceipt>({
    id: 0,
    amount: 0,
    transaction_id: '',
    comments: '',
    fileUrls: [],
    payment_acknowledgement: '',
    proposal_id: ''
  });

  // set Modal variables and usestate
  const [openAcknowledgementModal, setOpenAcknowledgementModal] = useState(false);
  const [acknowledgementModalContent, setAcknowledgementModalContent] = useState('');
  const [acknowledgementModalTitle, setAcknowledgementModalTitle] = useState('Payment Advice');
  const handleOpenAcknowledgementModal = () => setOpenAcknowledgementModal(true);
  function handleCloseAcknowledgementModal() {
    setOpenAcknowledgementModal(false);
    setAcknowledgementFormData({
      id: 0,
      amount: 0,
      transaction_id: '',
      comments: '',
      fileUrls: [],
      payment_acknowledgement: '',
      proposal_id: ''
    });
  }
  async function getPaymentReceipts(){
    const response = await invoiceService.getProposalReceipts({ proposal_id: proposal.proposal_id });
    const checkStatus = response.data.status.status ? 'success' : 'error';
    if (checkStatus === 'success'){
      const rows = response.data.rows;
      // Process the rows to store the array of objects
      const processedReceipts: PaymentReceipt[] = rows.map(row => ({
        id: row.id,
        amount: row.amount,
        transaction_id: row.transaction_id,
        comments: row.comments,
        fileUrls: row.file_urls ? row.file_urls.split(',') : [],
        payment_acknowledgement: row.payment_acknowledgement,
        proposal_id: row.proposal_id
      }));

      setPaymentReceipts(processedReceipts);
    }
  }

  const handleReceiptAckClick = (event: any) => {
    const dataset = event.currentTarget.dataset;
    setAcknowledgementFormData({
      id: dataset.id,
      amount: dataset.amount,
      transaction_id: dataset.transaction_id,
      comments: dataset.comments,
      fileUrls: [dataset.file_url],
      payment_acknowledgement: dataset.payment_acknowledgement,
      proposal_id: dataset.proposal_id
    });
    // Open the modal
    handleOpenAcknowledgementModal();
  }
  
  const handleReceiptAckChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try{
      if (event.target.value === 'Received'){
        const newAcknowledgementFormData = {...acknowledgementFormData, payment_acknowledgement: event.target.value, sm_id:smId}
        setAcknowledgementFormData(newAcknowledgementFormData);
        const response = await invoiceService.submitProposalReceiptAck(newAcknowledgementFormData);
        const checkStatus = response.data.status.status ? 'success' : 'error';
        localService.toastify(response.data.status.message, checkStatus, 1000);
        if (checkStatus === 'success'){
          proposal.payment_status = 'Paid'
          onUpdatePaymentStatus(proposal);
        }
      }
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      handleCloseAcknowledgementModal();
      getPaymentReceipts();
    }
  };

  useEffect(() => {
    getPaymentReceipts();
  }, [proposal, onUpdatePaymentStatus]);
  return (
    <>
      <div className="d-flex">
        {paymentReceipts.map((receipt, index) => (
          receipt.fileUrls.length > 0 && receipt.fileUrls.map((url, idx) => (
            <Tooltip key={`proposal_receipt_${index}_${idx}`} title={url.split('proposal_receipts/')[url.split('proposal_receipts/').length - 1]} arrow>
              <div
                onClick={(e) => handleReceiptAckClick(e)}
                data-tip={url.split('proposal_receipts/')[url.split('proposal_receipts/').length - 1]}
                data-amount={receipt.amount}
                data-transaction_id={receipt.transaction_id}
                data-comments={receipt.comments}
                data-payment_acknowledgement={receipt.payment_acknowledgement}
                data-proposal_id={receipt.proposal_id}
                data-id={receipt.id}
                data-file_url={url}
                // data-file_urls={receipt.fileUrls.strigify}
                style={{ cursor: 'pointer' }}
              >
                <i className="bi bi-file-earmark-text-fill"></i>
              </div>
            </Tooltip>
          ))
        ))}
      </div>
      
      {openAcknowledgementModal && (
        <CommonPopUp
          modalTitle={acknowledgementModalTitle}
          modalContent={acknowledgementModalContent}
          openModal={openAcknowledgementModal}
          onClose={() => handleCloseAcknowledgementModal()}
          className="modal-medium"
        >
          <TableContainer className="pb-2" component={Paper}>
            <Table className='' aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>{acknowledgementFormData.amount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>{acknowledgementFormData.transaction_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Comments</TableCell>
                  <TableCell>{acknowledgementFormData.comments}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Receipt</TableCell>
                  <TableCell>
                    {
                      acknowledgementFormData.fileUrls && acknowledgementFormData.fileUrls.map((url, index) => (
                        <>
                          <span className="me-2">{url.split('proposal_receipts/')[url.split('proposal_receipts/').length-1]}</span>
                          <Tooltip title={url.split('proposal_receipts/')[url.split('proposal_receipts/').length-1]} arrow>
                            <NavLink target="_blank" key={`proposal_receipt_${index}`} to={url} data-tip={url.split('proposal_receipts/')[url.split('proposal_receipts/').length-1]}>
                              <i className="bi bi-file-earmark-text-fill"></i>
                            </NavLink>
                          </Tooltip>
                        </>
                      ))
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{proposal.payment_status}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {proposal.payment_status === 'Pending' && (UserReducer?.influencer_id === acceptedProposalPaymentDetails[0]?.influencer_id) && (
            <div className="pt-4">
              <p> 
                Kindly confirm, if you have received the payment or not
              </p>
              <FormControl className="pb-4" component="fieldset">
                {/* Group radio buttons for user selection */}
                <RadioGroup
                  value={acknowledgementFormData.payment_acknowledgement}
                  onChange={handleReceiptAckChange}
                  row
                >
                  <FormControlLabel value="Received" control={<Radio />} label="Received" />
                  <FormControlLabel value="Not Received" control={<Radio />} label="Not Received" />
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </CommonPopUp>
      )}
    </>
  )
};

export default RenderAcknoledgeReceiptForm;