import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { WarningAmber } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField, } from '@mui/material';
import { FormValidation } from '../../../core/utility';
import { CommonService, LocalService, PublicService } from '../../../core/services';
import { ImageCrop } from '../../../components';
import { UserLogin } from '../../../redux/action';
import './company-trade-details.scss';
import FileUploadPopup from '../../../components/popup/file-upload-popup';
import { Button } from 'react-bootstrap';
import { MenuPropsSelect } from '../../../core/utility/style';

const publicService = new PublicService();
const formValidation = new FormValidation();
const commonService = new CommonService();
const localService = new LocalService();

interface Props {
    UserReducer: any;
    updateAccountImage: (data: any) => void;
}


const CommonCompanyDetails: React.FC<Props> = (props: Props) => {
    const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
    const [disableInput, setDisableInput] = useState(props.UserReducer.gstin ? true : false);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const [path, setPath] = useState({ type: '', path: '' });
    const [accountForm, setAccountForm] = useState({
        account_id: props.UserReducer.account_id,
        company_trade_name: '',
        gstin: '',
        company_type: '',
        address: '',
        pincode: '',
        state: '',
        country: '',
        pan: '',
        aadhaar: '',
        logo: '',
        signature: ''
    });


    // useEffect(() => {
    //     const { logo, signature } = accountForm;

    //     if (logo && logo !== props.UserReducer.logo) {
    //         props.updateAccountImage({ ...props.UserReducer, ...accountForm });
    //     }

    //     if (signature && signature !== props.UserReducer.signature) {
    //         props.updateAccountImage({ ...props.UserReducer, ...accountForm });
    //     }
    // }, [accountForm]);

    useEffect(() => {
        async function getCompanyTradeDetails() {
            setLoading(true)
            try {
                const response = await commonService.getCompanyTradeDetails(props.UserReducer.account_id);
                if (response.data.status) {
                    setAccountForm({ ...accountForm, ...response.data.rows[0] });
                    setDisableInput(response.data.rows[0].gstin != '');
                }
                setLoading(false)
            } catch (error) {
                console.log('Get Notification Error', JSON.stringify(error));
                setLoading(false)
            }
        };
        getCompanyTradeDetails();
    }, [])


    function accountFormValidation(key: string, value: any) {
        let error: any = null;
        setAccountForm((prevData) => ({ ...prevData, [key]: value }));

        switch (key) {
            case 'company_trade_name':
                error = formValidation.GetNameValidate(value);
                break;
            case 'company_type':
                error = formValidation.GetNameValidate(value);
                break;
            case 'address':
                error = formValidation.GetNameValidate(value);
                break;
            case 'pan':
                error = formValidation.getPANisValid(value);
                break;
            case 'aadhaar':
                error = formValidation.getAadhaarisValid(value);
                break;
            default:
                error = null;
        }

        setFormErrors((prevData: any) => ({ ...prevData, [key]: error }))
    }


    async function handleVerify() {
        setLoading(true);
        try {
            let response = await publicService.getGSTINDetail(
                {
                    gstin: accountForm.gstin
                }
            );
            if (response.data.flag) {
                const gstinData = response.data.data;
                setAccountForm((r) => {
                    r.company_trade_name = gstinData.tradeNam;
                    r.company_type = gstinData.ctb;
                    r.address = gstinData.pradr.adr;
                    r.pincode = gstinData.pradr.addr.pncd.substring(0, gstinData.pradr.addr.pncd.length);
                    r.pan = gstinData.gstin.substring(2, gstinData.gstin.length - 3)
                    r.state = gstinData.pradr.addr.stcd
                    return { ...r }
                });

                setDisableInput(true);
            }
        } catch (error) {
            throw new Error(`handleVerifySave error :: ${error}`)
        } finally {
            setLoading(false);
        }
    }


    async function saveAccountDetail() {
        setLoading(true);
        try {

            const { company_trade_name, address, pincode } = accountForm;

            if (company_trade_name && address && pincode) {
                const response = await commonService.updateAccountDetail(accountForm);
                const status: string = response.data.status.status ? 'success' : 'error';
                localService.toastify(response.data.status.message, status, 1000);
            }

        } catch (error) {
            console.log('saveAccountDetail error ==> ', JSON.stringify(error))
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className='company-trade-detail'>
                {/* {showUploadPopup && (
                    <ImageCrop
                        ratio={1 / 1}
                        path={path.path}
                        popup={(e) => setShowUploadPopup(e)}
                        onCroped={(e) => {
                            setAccountForm((prevData: any) => {
                                prevData[`${path.type}`] = e.url;
                                prevData[`${path.type}_path`] = e.key;
                                return { ...prevData };
                            })
                        }}
                    />
                )} */}
                {showUploadPopup && (
                    <FileUploadPopup openModal={showUploadPopup} onClose={(e) => setShowUploadPopup(false)} onSend={(e: any) =>
                        setAccountForm((prevData: any) => {
                            prevData[`${path.type}`] = e.url;
                            prevData[`${path.type}_path`] = e.key;
                            return { ...prevData };
                        })
                    }
                        path={path.path}
                        acceptedFileTypes=".jpg, .jpeg, .png"
                        fileMaximumSize={path.type === 'logo' ? 800 : 100}
                    />
                )}
                <div className='list-section-content rounded'>
                    <div className='row'>
                        <strong className='d-block'>
                            Company Details
                        </strong>
                    </div>
                </div>
                <div className='row'>
                    <strong className='d-block'>
                        Verify Details
                    </strong>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='row mt-3'>
                            <div className='col'>
                                {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                                    className='w-100 col input-common'
                                    size='small'
                                    type='text'
                                    name='gstin'
                                    id='id_gstin'
                                    label='GSTIN'
                                    value={accountForm.gstin}
                                    onChange={(e) => setAccountForm({ ...accountForm, gstin: e.target.value })}
                                />
                                }
                            </div>
                            <div className='col-auto ps-0 col-md'>
                                <LoadingButton
                                    className='btn btn-outline-primary col-auto'
                                    loading={loading}
                                    loadingPosition='start'
                                    variant='contained'
                                    onClick={handleVerify}
                                >
                                    Verify
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </div>

                <Grid container spacing={3}>

                    <Grid item xs={12} >
                        <hr className='w-100 mb-0' />
                    </Grid>
                    <Grid item xs={12} >
                        <strong className='d-block'>
                            Details
                        </strong>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            disabled={disableInput}
                            className='w-100 input-common'
                            required
                            name='company_trade_name'
                            size='small'
                            fullWidth
                            label='Company/Trade Name'
                            id='fullWidth'
                            value={accountForm.company_trade_name}
                            error={!formErrors?.company_trade_name?.status}
                            helperText={formErrors?.company_trade_name?.message}
                            onChange={(e) => accountFormValidation('company_trade_name', e.target.value)}
                        />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <FormControl fullWidth size='small'>
                            <InputLabel id='demo-multiple-name-label'>
                                Company/Trade type
                            </InputLabel>
                            <Select
                                disabled={disableInput}
                                className='w-100 input-common'
                                required
                                labelId='demo-multiple-name-label'
                                id='demo-multiple-name'
                                name='company_type'
                                label='Company/Trade Type'
                                defaultValue={accountForm.company_type}
                                value={accountForm.company_type}
                                onChange={(e) => accountFormValidation('company_type', e.target.value)}
                                MenuProps={MenuPropsSelect}
                            >
                                <MenuItem value={'Individual'}>
                                    Individual
                                </MenuItem>
                                <MenuItem value={'Parternship'}>
                                    Parternship
                                </MenuItem>
                                <MenuItem
                                    value={
                                        'Limited Liability Parternship'
                                    }
                                >
                                    Limited Liability Parternship
                                </MenuItem>
                                <MenuItem
                                    value={'Private Limited Company'}
                                >
                                    Private Limited Company
                                </MenuItem>
                                <MenuItem
                                    value={'Public Limited Company'}
                                >
                                    Public Limited Company
                                </MenuItem>
                                <MenuItem value={'One Person Company'}>
                                    One Person Company
                                </MenuItem>
                                <MenuItem value={'Sole reprietorship'}>
                                    Sole reprietorship
                                </MenuItem>
                                <MenuItem
                                    value={
                                        'Non-Government Organization '
                                    }
                                >
                                    Non-Government Organization (NGO)
                                </MenuItem>
                                <MenuItem
                                    value={'Joint-Venture Company'}
                                >
                                    Joint-Venture Company
                                </MenuItem>
                            </Select>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            required
                            className='w-100 input-common'
                            size='small'
                            name='Address'
                            id='outlined-multiline-flexible'
                            label='Address'
                            multiline
                            maxRows={2}
                            value={accountForm.address}
                            disabled={disableInput}
                            error={formErrors?.address?.status}
                            helperText={formErrors?.address?.message}
                            onChange={(e) => accountFormValidation('address', e.target.value)}
                        />}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            required
                            className='w-100 contact_number input-common'
                            size='small'
                            type='number'
                            name='Pin_Code'
                            label='Pin Code'
                            value={accountForm.pincode}
                            disabled={disableInput}
                            error={formErrors?.pincode?.status}
                            helperText={formErrors?.pincode?.message}
                            onChange={(e) => accountFormValidation('pincode', e.target.value)}
                        />}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            required
                            className='w-100 input-common'
                            size='small'
                            type='text'
                            name='Country'
                            disabled
                            label='Country'
                            value={accountForm.country}
                        />}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            required
                            className='w-100 input-common'
                            size='small'
                            type='text'
                            name='State'
                            disabled
                            label='State'
                            value={accountForm.state}
                        />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            disabled={disableInput}
                            className='w-100 input-common'
                            size='small'
                            type='text'
                            name='PAN'
                            label='PAN'
                            value={accountForm.pan}
                            onChange={(e) => accountFormValidation('pan', e.target.value)}
                        />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? <Skeleton variant="rounded" height={40} /> : <TextField
                            className='w-100 input-common'
                            size='small'
                            type='text'
                            name='Aadhaar'
                            label='Aadhaar'
                            value={accountForm.aadhaar}
                            onChange={(e) => accountFormValidation('aadhaar', e.target.value)}
                        />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h6>Company/trade logo</h6>

                        {accountForm.logo &&
                            (
                                <div>
                                {loading ? <Skeleton variant="rounded" height={100} width={150} /> : <img src={accountForm.logo} alt='logo' style={{ height: '100px' }} />}
                                </div>
                            )
                        }

                        {/* <span className='img-upload-sec mt-3'>
                            <span
                                onClick={() => { setShowUploadPopup(!showUploadPopup); setPath({ ...path, type: 'logo', path: `accounts/${props.UserReducer.account_id}/logo` }) }}
                                className='img-upload'
                            ></span>{' '}
                            <WarningAmber />
                            <span className='fs-12'>
                                (jpeg. upto 100kb)
                            </span>

                        </span> */}
                        <Button
                            className='btn btn-outline-primary mt-3 upload_button'
                            onClick={() => { setShowUploadPopup(!showUploadPopup); setPath({ ...path, type: 'logo', path: `accounts/${props.UserReducer.account_id}/logo` }) }}
                            variant='contained'
                        >
                            Upload Image
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h6 className='pt-3 pt-md-0'>Signature</h6>
                        {accountForm.signature &&
                            (
                                <div>
                                {loading ? <Skeleton variant="rounded" height={100} width={150} /> : <img src={accountForm.signature} alt='logo' style={{ height: '100px' }} />}
                                </div>
                            )
                        }

                        {/* <span className='img-upload-sec mt-3'>
                            <span
                                onClick={() => { setShowUploadPopup(!showUploadPopup); setPath({ ...path, type: 'signature', path: `accounts/${props.UserReducer.account_id}/signature` }) }}
                                className='img-upload'
                            ></span>{' '}
                            <WarningAmber />
                            <span className='fs-12'>
                                (jpeg. upto 100kb)
                            </span>
                        </span> */}
                        <Button
                            className='btn btn-outline-primary upload_button mt-3'
                            onClick={() => { setShowUploadPopup(!showUploadPopup); setPath({ ...path, type: 'signature', path: `accounts/${props.UserReducer.account_id}/signature` }) }}
                            variant='contained'
                        >
                            Upload Image
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <div className=' showupload mt-5'>
                            <LoadingButton
                                className='btn btn-primary'
                                loading={loading}
                                loadingPosition='start'
                                variant='contained'
                                onClick={saveAccountDetail}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </Grid>
                </Grid>
                <div className='row mt-3'>
                    <hr />

                    <div className='col'>
                        {/* <div className='row mt-3'>
                            <div className='col-12 col-md-6'>
                                <TextField
                                    disabled={disableInput}
                                    className='w-100 input-common'
                                    required
                                    name='company_trade_name'
                                    size='small'
                                    fullWidth
                                    label='Company/Trade Name'
                                    id='fullWidth'
                                    value={accountForm.company_trade_name}
                                    error={!formErrors?.company_trade_name?.status}
                                    helperText={formErrors?.company_trade_name?.message}
                                    onChange={(e) => accountFormValidation('company_trade_name', e.target.value)}
                                />
                            </div>
                            <div className='col'>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id='demo-multiple-name-label'>
                                        Company/Trade type
                                    </InputLabel>
                                    <Select
                                        disabled={disableInput}
                                        className='w-100 input-common'
                                        required
                                        labelId='demo-multiple-name-label'
                                        id='demo-multiple-name'
                                        name='company_type'
                                        label='Company/Trade Type'
                                        defaultValue={accountForm.company_type}
                                        value={accountForm.company_type}
                                        onChange={(e) => accountFormValidation('company_type', e.target.value)}
                                    >
                                        <MenuItem value={'Individual'}>
                                            Individual
                                        </MenuItem>
                                        <MenuItem value={'Parternship'}>
                                            Parternship
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                'Limited Liability Parternship'
                                            }
                                        >
                                            Limited Liability Parternship
                                        </MenuItem>
                                        <MenuItem
                                            value={'Private Limited Company'}
                                        >
                                            Private Limited Company
                                        </MenuItem>
                                        <MenuItem
                                            value={'Public Limited Company'}
                                        >
                                            Public Limited Company
                                        </MenuItem>
                                        <MenuItem value={'One Person Company'}>
                                            One Person Company
                                        </MenuItem>
                                        <MenuItem value={'Sole reprietorship'}>
                                            Sole reprietorship
                                        </MenuItem>
                                        <MenuItem
                                            value={
                                                'Non-Government Organization '
                                            }
                                        >
                                            Non-Government Organization (NGO)
                                        </MenuItem>
                                        <MenuItem
                                            value={'Joint-Venture Company'}
                                        >
                                            Joint-Venture Company
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col'>
                                <TextField
                                    required
                                    className='w-100 input-common'
                                    size='small'
                                    name='Address'
                                    id='outlined-multiline-flexible'
                                    label='Address'
                                    multiline
                                    maxRows={2}
                                    value={accountForm.address}
                                    disabled={disableInput}
                                    error={formErrors?.address?.status}
                                    helperText={formErrors?.address?.message}
                                    onChange={(e) => accountFormValidation('address', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col'>
                                <TextField
                                    required
                                    className='w-100 contact_number input-common'
                                    size='small'
                                    type='number'
                                    name='Pin_Code'
                                    label='Pin Code'
                                    value={accountForm.pincode}
                                    disabled={disableInput}
                                    error={formErrors?.pincode?.status}
                                    helperText={formErrors?.pincode?.message}
                                    onChange={(e) => accountFormValidation('pincode', e.target.value)}
                                />
                            </div>
                            <div className='col'>
                                <TextField
                                    required
                                    className='w-100 input-common'
                                    size='small'
                                    type='text'
                                    name='Country'
                                    disabled
                                    label='Country'
                                    value={accountForm.country}
                                />
                            </div>
                            <div className='col'>
                                <TextField
                                    required
                                    className='w-100 input-common'
                                    size='small'
                                    type='text'
                                    name='State'
                                    disabled
                                    label='State'
                                    value={accountForm.state}
                                />
                            </div>
                        </div>
                        <div className='row mt-3'>

                            <div className='col'>
                                <TextField
                                    disabled={disableInput}
                                    className='w-100 input-common'
                                    size='small'
                                    type='text'
                                    name='PAN'
                                    label='PAN'
                                    value={accountForm.pan}
                                    onChange={(e) => accountFormValidation('pan', e.target.value)}
                                />
                            </div>
                            <div className='col'>
                                <TextField
                                    className='w-100 input-common'
                                    size='small'
                                    type='text'
                                    name='Aadhaar'
                                    label='Aadhaar'
                                    value={accountForm.aadhaar}
                                    onChange={(e) => accountFormValidation('aadhaar', e.target.value)}
                                />
                            </div>
                        </div> */}

                        {/* <div className='row mt-3'>
                            <div className='col'>
                                <h6>Company/trade logo</h6>
                                {accountForm.logo &&
                                    (
                                        <img src={accountForm.logo} alt='logo' style={{ height: '100px' }} />
                                    )
                                }

                                <span className='img-upload-sec mt-3'>
                                    <span
                                        onClick={() => { setShowUploadPopup(!showUploadPopup); setPath({ ...path, type: 'logo', path: `accounts/${props.UserReducer.account_id}/logo` }) }}
                                        className='img-upload'
                                    ></span>{' '}
                                    <WarningAmber />
                                    <span className='fs-12'>
                                        (jpeg. upto 100kb)
                                    </span>
                                </span>
                            </div>
                            <div className='col'>
                                <h6>Signature</h6>
                                {accountForm.signature &&
                                    (
                                        <img src={accountForm.signature} alt='logo' style={{ height: '100px' }} />
                                    )
                                }

                                <span className='img-upload-sec mt-3'>
                                    <span
                                        onClick={() => { setShowUploadPopup(!showUploadPopup); setPath({ ...path, type: 'signature', path: `accounts/${props.UserReducer.account_id}/signature` }) }}
                                        className='img-upload'
                                    ></span>{' '}
                                    <WarningAmber />
                                    <span className='fs-12'>
                                        (jpeg. upto 100kb)
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col showupload mt-5'>
                                <LoadingButton
                                    className='btn btn-primary'
                                    loading={loading}
                                    loadingPosition='start'
                                    variant='contained'
                                    onClick={saveAccountDetail}
                                >
                                    Save
                                </LoadingButton>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div >
        </>
    );
};


const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateAccountImage: (data: any) => dispatch(UserLogin(data))
    }
}



const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(CommonCompanyDetails);

export { connectToRedux as CommonCompanyDetails };