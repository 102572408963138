import React, { useState, useEffect, Fragment } from "react";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Checkbox, TableRow, TableCell, Skeleton, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import { DefaultUserProfile } from "../../config/config";
import { Methods } from "../../core/utility";
import AddToList from "../../pages/brand/discover/add-to-list";
import AddToCampaign from "../add-to-campaign/add-to-campaign";
import CommonPopUp from "../../components/popup/common-popup";
import { LocalService, DiscoverService } from "../../core/services";
import { TableComponent } from '../../pages/brand/discover/discover-table';
import { CommonTableComponent } from "../common-table-component/common-table-component";
// import IconAutomobile from "../icons/curated-list/icon-automobile";
import ProfileViewRestriction from "../popup/profile-view-restriction";
import UpgradeBrandPopUp from "../popup/upgrade-brand-popup";

const discoverService: DiscoverService = new DiscoverService();
const methods: Methods = new Methods();

interface Props {
    data: any[];
    platform: string;
    UserReducer: any;
    getSubscriptionDetail: any;
    onAddToList: (data: any) => void;
    addInfluencerDefaultSelectList: any;
}

// const iconMapping = {
//     automobile: IconAutomobile,
//     // Add more mappings as needed
// };

const CuratedList: React.FC<Props> = ({ data, platform, UserReducer, getSubscriptionDetail, onAddToList, addInfluencerDefaultSelectList }) => {
    const [curatedList, setCuratedList] = useState<any>(null);
    const [isLoadingCurated, setIsLoadingCurated] = useState(false);
    const [curatedInfluencerList, setCuratedInfluencerList] = useState([]);
    const [curatedPopUp, setCuratedPopUp] = useState(false);
    const [selectedInfluencerCurated, setSelectedInfluencerCurated] = useState<any>([]);
    const [showPopAddToCampaign, setShowPopAddToCampaign] = useState(false);
    // const [campaignInfluencerIds, setCampaignInfluencerIds] = useState<{ campaign_id: number, influencer_id: number }[]>([]);
    const [showAddToListPopup, setShowAddToListPopup] = useState(false);
    const [error, setError] = useState<Boolean>(false)
    const [errorAddToCampaign, setErrorAddToCampaign] = useState<Boolean>(false)
    const [profileViewLimitPopup, setProfileViewLimitPopup] = useState(false);
    const [profileViewCount, setProfileViewCount] = useState(0);
    const [profileViewLimit, setProfileViewLimit] = useState(0);
    const [selectedSmId, setSelectedSmId] = useState(0);
    const [openUpgradeBrandModal, setopenUpgradeBrandModal] = useState(false);


    useEffect(() => {
        if (curatedList) {
            getCuratedInfluencer(curatedList);
        }
    }, [curatedList])

    const clickCurated = (curated: any) => {
        onAddToList([]);
        setCuratedList(curated);
    }

    const handleOpenUpgradeBrandModal = () => setopenUpgradeBrandModal(true);

    function handleCloseUpradeBrandModal() {
        setopenUpgradeBrandModal(false);
    }

    const getCuratedInfluencer = async (curatedListData: any) => {
        try {
            setIsLoadingCurated(true);
            setCuratedPopUp(true);
            const res = await discoverService.getTopCuratedInfluencer({cl_id: curatedListData.cl_id, infType: curatedListData.list_type.toLowerCase(), user_id: UserReducer.user_id, account_id: UserReducer.account_id, brand_id: UserReducer.brand_id, influencer_id: UserReducer.influencer_id});
            if (res.data.status === 1) {
                setCuratedInfluencerList(res.data.rows);
            }
        } catch (error) {
            console.log(`getCuratedInfluencer error :: ${JSON.stringify(error)}`);
        } finally {
            setIsLoadingCurated(false);
        }
    }

    const closeCuratedPopUp = () => {
        setCuratedPopUp(false);
        setCuratedList(null);
        setSelectedInfluencerCurated([]);
        setShowPopAddToCampaign(false);
        setShowAddToListPopup(false);
        setError(false)
        setErrorAddToCampaign(false)
        if (addInfluencerDefaultSelectList) {
            onAddToList([]);
        }
    }

    const handleCloseAddToCamp = () => {
        setShowPopAddToCampaign(false);
        setSelectedInfluencerCurated([]);
    }

    const handleCheckboxCurated = (checked: boolean, data: any) => {
        let tempStoreData: any[] = [...selectedInfluencerCurated];
        if (checked) {
            setError(false)
            setErrorAddToCampaign(false)
            tempStoreData.push(data);
        } else {
            const index = tempStoreData.findIndex(
                (value) => JSON.stringify(value) === JSON.stringify(data)
            );

            if (index !== -1) {
                tempStoreData.splice(index, 1);
            }
        }
        setSelectedInfluencerCurated(tempStoreData);
        if (addInfluencerDefaultSelectList) {
            onAddToList(tempStoreData);
        }
    };

    const handleAddToListClose = () => {
        setShowAddToListPopup(false);
        setSelectedInfluencerCurated([]);
    }

    const handleAddToCampaignPopup = () => {
        if (getSubscriptionDetail.isBasicUser){
            handleOpenUpgradeBrandModal();
        }else{
            if (selectedInfluencerCurated.length > 0) {
                setErrorAddToCampaign(false)
                setShowAddToListPopup(false);
                setShowPopAddToCampaign(!showPopAddToCampaign)
            }
            else {
                setErrorAddToCampaign(true)
                setError(false)
            }
        }
    }
    const handleAddToListPopup = () => {
        if (getSubscriptionDetail.isBasicUser){
            handleOpenUpgradeBrandModal();
        }else{
            if (selectedInfluencerCurated.length > 0) {
                setError(false);
                setShowPopAddToCampaign(false);
                setShowAddToListPopup(!showAddToListPopup)
            }
            else {
                setError(true)
                setErrorAddToCampaign(false)
            }
        }
    }

    const handleNavigate = (id: number | string, influencerId?: string | number) => {
        if (platform === "instagram") {
            window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
        }
        if (platform === "youtube") {
            window.open(`/brand/profile-insight-yt?sm_id=${id}&influencerId=${influencerId}`, "_blank");
        }
        if (platform === "curatedInstagram") {
            window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
        }
    };

    async function handleProfileView(sm_id: number, influencerId?: number) {
        try {
            //   props.loading(true);
            setSelectedSmId(sm_id);

            const response = await discoverService.canViewProfile({
                user_id: UserReducer.user_id,
                account_id: UserReducer.account_id,
                brand_id: UserReducer.brand_id,
                influencer_id: UserReducer.influencer_id,
                sm_id: sm_id
            })
            if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_profile_limit_popup)) {
                handleNavigate(sm_id, influencerId);
            }
            else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_profile_limit_popup) {
                setProfileViewCount(response?.data?.profile_view_count);
                setProfileViewLimit(response?.data?.profile_view_limit);
                setProfileViewLimitPopup(true);
            }
            // else if (response.data.status && response.data.basic_user ){
            //   setProfileViewLimitPopup(true);
            // }
            else if (!response.data.status && response.data.message.includes('upgrade')) {
                // localService.toastify(response.data.message, 'info', 1000);
                handleOpenUpgradeBrandModal();
            }
            else if (!response.data.status) {
                // localService.toastify(response.data.message, 'error', 1000);
                console.error(`error:: ${response.data.message}`)
            }
        } catch (error) {
            console.error(`handleProfileView error :: ${JSON.stringify(error)}`);
        } finally {
            //   props.loading(false);
        }
    };

    // To handle profile view
    async function handleFocProfileView() {
        try {
            // props.loading(true);
            setProfileViewLimitPopup(false);
            handleNavigate(selectedSmId);
        } catch (error) {
            console.error(`handleFocProfileView error :: ${JSON.stringify(error)}`);
        } finally {
            // props.loading(false);
        }
    };


    const listNames = data ? data.map((item: any) => item.list_name.toLowerCase()) : [];
    // const IconComponent = listNames.length > 0 ? iconMapping[listNames[0]] : null;


    // console.log("IconComponent", IconComponent);
    return (
        <Fragment>
            <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
                className="curated-list-sec mb-3"
            >
                {data && data?.map((item: any, index: number) => (

                    <div key={item?.cl_id} className={`curated-list ${item?.list_name}`}>
                        <FormControlLabel className='label' onClick={() => clickCurated(item)} value={item?.cl_id} control={<Radio style={{ width: '0', height: '0', padding: '0' }} icon={<></>} checkedIcon={<></>} />} label="" />
                        <span className="curated-list-name">{item?.list_name}</span>
                    </div>
                ))
                }
            </RadioGroup>
            <div className="row">
                <CommonPopUp
                    className={`modal-xlarge modal-curated-list ${(showAddToListPopup || showPopAddToCampaign) ? "popper-popup-active" : ""}`}
                    openModal={curatedPopUp}
                    modalTitle={curatedList?.list_name}
                    onClose={() => closeCuratedPopUp()}>

                    <CommonTableComponent
                        platformType={platform === 'instagram' ? 'curatedInstagram' : platform}
                        data={curatedInfluencerList}
                        loading={isLoadingCurated}
                        selectedInfluencer={selectedInfluencerCurated}
                        handleCheckbox={handleCheckboxCurated}
                        handleProfileView={handleProfileView}
                        getSubscriptionDetail={getSubscriptionDetail}
                    />

                    {showAddToListPopup && (
                        <div className='popper-popup center-screen'>
                            <AddToList
                                platform={platform}
                                UserReducer={UserReducer}
                                selectedInfluencer={selectedInfluencerCurated}
                                showAddToListPopup={showAddToListPopup}
                                handleAddToListClose={handleAddToListClose}
                            />
                        </div>
                    )}

                    {showPopAddToCampaign && (
                        <AddToCampaign
                            platform={platform}
                            UserReducer={UserReducer}
                            selectedInfluencer={selectedInfluencerCurated}
                            showPopAddToCampaign={showPopAddToCampaign}
                            handleCloseAddToCamp={handleCloseAddToCamp}
                        />
                    )}

                    {addInfluencerDefaultSelectList === null &&
                        <div className="popup-footer" style={{ position: 'sticky', bottom: 1, zIndex: 1 }}>

                            <div className="d-flex justify-content-end">
                                <div className="d-flex flex-column position-relative">
                                    <LoadingButton
                                        className="btn btn-outline-primary btn-mui me-2"
                                        loadingPosition="start"
                                        variant="contained"
                                        onClick={() => {
                                            handleAddToCampaignPopup()
                                        }}
                                    >
                                        Add to Campaign
                                    </LoadingButton>
                                    {errorAddToCampaign && <span className="error" style={{ position: "absolute", right: '10px', top: '100%', width: '200px' }}>Please select at least one influencer</span>}
                                </div>
                                <div className="d-flex flex-column position-relative">
                                    <LoadingButton
                                        className="btn btn-outline-primary btn-mui"
                                        loadingPosition="start"
                                        variant="contained"
                                        onClick={() => {
                                            handleAddToListPopup()
                                        }}
                                    >
                                        Add to List
                                    </LoadingButton>
                                    {error && <span className="error" style={{ position: "absolute", right: '0', top: '100%', width: '200px' }}>Please select at least one influencer</span>}
                                </div>
                            </div>
                        </div>
                    }

                </CommonPopUp>
            </div>
            {
                profileViewLimitPopup && (
                    <ProfileViewRestriction
                        openModal={profileViewLimitPopup}
                        onClose={() => setProfileViewLimitPopup(false)}
                        profileViewCount={profileViewCount}
                        profileViewLimit={profileViewLimit}
                        handleFocProfileView={() => handleFocProfileView()}
                    />
                )
            }
            {openUpgradeBrandModal && (
                <UpgradeBrandPopUp
                    openUpgradeBrandModal={openUpgradeBrandModal}
                    onClose={() => handleCloseUpradeBrandModal()}
                    popupMinWidth='630px'
                >
                </UpgradeBrandPopUp>
            )}
        </Fragment>
    );
};


export default CuratedList;