import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Numbers } from '../../../core/utility';
import { SubscriptionInvoiceType } from 'custom-type';
const numberFormat: Numbers = new Numbers();

interface TaxInformationProps {
  invoice: SubscriptionInvoiceType;
}

const TaxInformation: React.FC<TaxInformationProps> = ({invoice}) => {
  return (
    <TableRow>
      <TableCell colSpan={6} style={{ paddingLeft: 0 }}>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow className="table-bg-color">
              <TableCell colSpan={3}>
                <strong className="d-block text-center">HSN/SAC</strong>
              </TableCell>
              <TableCell>
                <strong className="d-block text-center">Taxable Value</strong>
              </TableCell>
              {invoice.igst > 0 ? (
                <TableCell>
                  <strong className="d-block text-center">Integrated Tax</strong>
                  <Table style={{ width: '100%', textAlign: 'center' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Rate</strong></TableCell>
                        <TableCell><strong>Amount</strong></TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableCell>
              ) : (
                <>
                  <TableCell>
                    <strong className="d-block text-center">CGST</strong>
                    <Table style={{ width: '100%', textAlign: 'center' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Rate</strong></TableCell>
                          <TableCell><strong>Amount</strong></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <strong className="d-block text-center">SGST</strong>
                    <Table style={{ width: '100%', textAlign: 'center' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Rate</strong></TableCell>
                          <TableCell><strong>Amount</strong></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableCell>
                </>
              )}
              <TableCell style={{ textAlign: 'right' }}>
                <strong>Total Tax Amount</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} className="text-center">{invoice.sac_code}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>&#8377; {invoice.amount}</TableCell>
              {invoice.igst > 0 ? (
                <TableCell style={{ textAlign: 'center' }}>
                  <Table style={{ width: '100%', textAlign: 'center' }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>18%</TableCell>
                        <TableCell>&#8377; {invoice.igst}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              ) : (
                <>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Table style={{ width: '100%', textAlign: 'center' }}>
                      <TableBody>
                        <TableRow>
                          <TableCell>9%</TableCell>
                          <TableCell>&#8377; {invoice.cgst}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Table style={{ width: '100%', textAlign: 'center' }}>
                      <TableBody>
                        <TableRow>
                          <TableCell>9%</TableCell>
                          <TableCell>&#8377; {invoice.sgst}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </>
              )}
              <TableCell style={{ textAlign: 'right' }}>&#8377; {invoice.igst > 0 ? invoice.igst : (invoice.cgst + invoice.sgst)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6} className="inv-table-footer">
                <strong>Tax Amount (in words): </strong>
                <>
                  INR {invoice.igst > 0 ? numberFormat.convertToWords(invoice.igst) : numberFormat.convertToWords(invoice.cgst + invoice.sgst)} only
                </>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
}

export default TaxInformation;