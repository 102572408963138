import React from 'react'

type Props = {
  iconName: string;
  fontweight: string;
  iconFilled?: boolean;
  className?: string;
  onClick?: () => void;
  fontSize?: string;
  lineHeight?:string;
  color?:string
}

const MaterialSymbol = (props: Props) => {
  return (
    <span style={{fontSize:props.fontSize, lineHeight:props.lineHeight, color:props.color}} onClick={props.onClick} className={`material-symbols-rounded confirm-icon ${props?.fontweight} ${props.className ? props.className : ""} ${props?.iconFilled ? "icon-filled" : ""}`}>
      {props?.iconName}
    </span>
  )
}

export default MaterialSymbol