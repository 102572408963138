import React from "react";
import {
    LocalService,
    ListService,
    CreateCampaign as CampaignService,
} from "../../../core/services";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const listService: ListService = new ListService();
const localService: LocalService = new LocalService();

interface Props {
    selectedInfluencer: any[];
    UserReducer: any;
    addInfluencerDefaultSelectList: any;
    handleOpenUpgradeBrandModal?:((value:boolean) => void) | undefined;
}



const SaveFromList: React.FC<Props> = ({ selectedInfluencer, UserReducer, addInfluencerDefaultSelectList, handleOpenUpgradeBrandModal }) => {

    const navigate = useNavigate();

    async function saveDefaultSelectedList() {
       // setLoading(true);
        try {
          if (selectedInfluencer?.length > 0) {
            const response = await listService.addInfluencerList({
              user_id: UserReducer.user_id,
              account_id: UserReducer.account_id,
              brand_id: UserReducer.brand_id,
              influencer_id: UserReducer.influencer_id,
              lists: [addInfluencerDefaultSelectList],
              influencers: selectedInfluencer,
            });
    
            // const checkStatus = response.data.status.status ? "success" : "error";
            // localService.toastify(response.data.status.message, checkStatus, 1000);
            if (response.data.status.status) {
              localService.toastify('Added to list', 'success', 1000);
              navigate(`/brand/list`, { state: addInfluencerDefaultSelectList });
            } else if (!response.data.status.status && response.data.status.message.includes('upgrade')) {
              if(handleOpenUpgradeBrandModal){
                handleOpenUpgradeBrandModal(true);
              }
              // localService.toastify('Please try again', 'error', 3000);
             // handleOpenUpgradeBrandModal();
            } else if (!response.data.status.status) {
              localService.toastify('', 'error', 1000);
            }
          }
        } catch (error) {
          console.log(
            `saveInfluencerInList error in discover :: ${JSON.stringify(error)}`
          );
        } finally {
        //  setLoading(false);
        }
      }
    

    return (
        <>
            {addInfluencerDefaultSelectList && (
                <div className='row default-selected'>
                    <div className='col default-selected-list'>
                    <span className="list-name">{addInfluencerDefaultSelectList?.list_name}&nbsp;({addInfluencerDefaultSelectList?.influencer_counts})</span>
                        <span className='influencer-count'>
                            {selectedInfluencer.length}
                        </span>
                        <span className='btn-span'>
                            <LoadingButton
                                className='btn btn-primary btn-mui'
                                disabled={selectedInfluencer.length === 0}
                                loadingPosition='start'
                                variant='contained'
                                onClick={saveDefaultSelectedList}
                            >
                                Save
                            </LoadingButton>
                        </span>
                    </div>
                </div>
            )}

        </>
    );
};


export default SaveFromList;