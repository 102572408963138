
import React, { useEffect, useState } from 'react';
import { DeliverableItemKeys } from './enums';
import { TableCellData } from './table_cell';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';
import { connect } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ProposalNegotiationStep } from './stepper';
import dayjs from 'dayjs';

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted';

type CurrentSteps = 1 | 2 | 3 | 4;


interface FormDataType {
    negotiation_id: number;
    proposal_id: number;
    campaign_id: number;
    influencer_id: number;
    sm_id: number;
    deliverable_item: string;
    s_0_quantity: number;
    s_0_price: number;
    s_0_status: StatusType;
    s_0_date: string;
    s_1_quantity: number;
    s_1_price: number;
    s_1_status: StatusType;
    s_1_date: string;
    s_2_quantity: number;
    s_2_price: number;
    s_2_status: StatusType;
    s_2_date: string;
    s_3_quantity: number;
    s_3_price: number;
    s_3_status: StatusType;
    s_3_date: string;
    current_step: CurrentSteps;
    status: StatusType;
    proposal_datetime: string;
    created_at: string;
    updated_at: string;
}
interface Props {
    proposalDetail?: any[];
    enumdeliverableItems?: Record<string, string>;
    costing_model?: boolean;
    campaign_type?: boolean;
    status?: string;
    data?: any;
    profileData?: any;
    deliverableHeader?: any;
    tableData?: any;
    campaignPlatform?: any;
    UserReducer: any;
    selectedCampaign: any;
    step?: any;
    negotiateVisible?: boolean;
    throughWhatsapp?:any;

}

const CustomProposalTableMobile: React.FC<Props> = (props: Props) => {
    
    const getInfluencerTypeId = () => {
        if (props?.throughWhatsapp =="false" && (props.UserReducer.account_type == "Brand" || props.UserReducer.account_type == "Agency")) {
            if (props?.profileData) {
                setInfluencerTypeId(props?.profileData?.infTypeId)
            }
        }
        else {
            const filteredInfluencers = props?.UserReducer?.influencer_ids?.filter((ele: any) => {
                return ele?.platform_type === props?.selectedCampaign?.campaign_platform;
            });
            filteredInfluencers?.forEach((item: any) => {
                setInfluencerTypeId(item?.influencer_type_id);
            });

        }
    }



    const [tableCells, setTableCell] = useState<any[]>([]);
    const [influencerTypeId, setInfluencerTypeId] = useState()
    const [enumdeliverableItems] = useState<Record<DeliverableItemKeys, string>>({
        ig_reel: 'Reel',
        ig_story: 'Story',
        ig_carousel_image: 'Carousel Image Post',
        yt_shorts: 'Short',
        available_for_exclusivity: 'Exclusivity',
        ig_static: 'Static',
        digital_rights: 'Digital Rights',
        yt_dedicated_video: 'Dedicated Video',
        yt_integrated_video: 'Integrated Video',
        ["Package Cost 1"]: 'Package Cost'
    });

    const [campaignType] = useState(props.selectedCampaign?.campaign_type || "Paid");
    const [costingModel] = useState(props.selectedCampaign?.campaign_cost_model || "Individual");
    useEffect(() => {
        if (props.data.length > 0) {
            setTableCell(renderTableCells({ data: props.data, step: props?.data[0]?.current_step || 1, campaignType: campaignType }));
        }
    }, [props.data])

    useEffect(() => {
        getInfluencerTypeId()
    }, [props.data])



    const calculateTotalCost = (step: number) => {
        let total = 0;
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i][`s_${step}_status`] !== "Rejected") {
                total += props.data[i][`s_${step}_price`];
            }
        }
        return total;
    };

    const renderTableCells = (props: { data: FormDataType[], step: number, campaignType: string }) => {
        let cells = [];
        for (let i = 1; i <= props.step; i++) {
            cells.push(
                <div key={`step-${i}`} className={`table-proposal-body-other-column-container ${(costingModel?.includes("Package")) ? "extra-space" : ""}`}>
                    {props?.data?.filter((ele: any) => ele.deliverable_item != "Package Cost 1").map((data: any, index: number) => {
                        return (
                            <>

                                <TableCellData key={`${index}-${i}`} dataSet={data} step={i} userType="brand" campaignType={campaignType} costingModel={costingModel} />
                            </>
                        );
                    })}
                </div>
            );
        }
        return cells;
    };
    const stepper = [
        { id: 1, name: "Proposal", status: 'active' },
        { id: 2, name: "First counteroffer", status: 'active' },
        { id: 3, name: "Influencer response", status: 'active' },
        { id: 4, name: "Final counteroffer", status: 'active' },
        { id: 5, name: "Deal closed", status: 'active' },
    ];
    const remainingStepsCount = stepper.length - props?.step[0]?.current_step;
    const renderRemainingSteps = () => {
        const currentStep = props?.step[0]?.current_step;
        const isAcceptedOrRejected = props?.step[0]?.campaign_proposal_status === 'Accepted' || props?.step[0]?.campaign_proposal_status === 'Rejected';
        const remainingStepsCount = stepper.length - currentStep;

        if (remainingStepsCount <= 0) {
            return null; // No remaining steps to render
        }

        return (
            <>
                {campaignType !== "Barter" && stepper.slice(currentStep).map((step, index) => {
                    let className = "remaining-step-container";

                    // Add deal-closed class to the last step if conditions are met
                    if (index === stepper.length - currentStep - 1 && currentStep < 4 && isAcceptedOrRejected) {
                        className += " deal-closed"; // Append custom class
                    }

                    return (
                        <div key={step.id} className={className}>
                            <div className="remaining-step-name">{step.name}</div>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <>
            {tableCells.map((row: any, rowIndex: number) => (
                <Accordion key={rowIndex} className='accordion-container table-negotiation-mobile w-100 '>
                    <AccordionSummary className='accordion-mobile-header'>
                        <div className='proposal-negotiation-resp-header'>
                            <div className='proposal-negotiation-resp-header-top'>
                            <ProposalNegotiationStep step={props.step} data={props.data[rowIndex]} />
                            {campaignType !== "Barter" &&   <span className='resp-amount ms-auto fw-bold'>{props?.data?.length > 0 && <>₹ {calculateTotalCost(rowIndex)}</>}
                                </span>
}
                            </div>
            
                            <span className='resp-date'>
                                {dayjs(props?.profileData?.proposal_datetime).format("DD MMM, YYYY")}
                            </span>

                        </div>
                    </AccordionSummary>
                    <AccordionDetails className='accordion-mobile-content'>
                        <div className='proposal-negotiation-sec proposal-negotiation-sec-mobile flex-grow-1'>
                            <div className="proposal-negotiation-detail main-head">
                                <div className="proposal-negotiation-table">
                                    <div className="table-proposal">
                                        <div className="table-proposal-header-row">
                                            <span className='table-proposal-header-first-col'>Deliverables</span>
                                            <span>
                                                Quantity
                                            </span>
                                            {props?.selectedCampaign?.campaign_type === "Paid" && !costingModel?.includes("Package") && <span>Cost in ₹</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='proposal-negotiation-detail inner-content'>

                                <div className='proposal-negotiation-table'>
                                    <div className="table-proposal-body">
                                        <div className='table-proposal-body-deliverable-name'>
                                            <React.Fragment key={rowIndex}>
                                                {props?.data?.filter((ele: any) => ele.deliverable_item != "Package Cost 1").map((item: any, index: number) => (

                                                    <span className={`table-proposal-body-first-col`}>{[enumdeliverableItems[item?.deliverable_item as DeliverableItemKeys]]}</span>
                                                ))}
                                            </React.Fragment>
                                        </div>
                                        {row}
                                    </div>
                                    {props?.selectedCampaign?.campaign_type !== "Barter" &&
                                        <div className={`table-proposal-total-cost-row ${(costingModel?.includes("Package")) ? "extra-space" : ""}`}>
                                            <strong className='d-flex align-items-center fs-16 fw-bold'>
                                                Total Cost:
                                                <MaterialSymbol className='fs-16'
                                                    iconName='currency_rupee' fontweight='200' />
                                                {props?.data?.length > 0 && calculateTotalCost(rowIndex)}
                                            </strong>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))
            }
            {/* Render remaining steps accordion */}
            {!props.negotiateVisible && renderRemainingSteps()}
          
                {props?.selectedCampaign?.campaign_type == "Paid" && 
                <div>
                    {(() => {
                    const { status, current_step, proposal_negotiation_updated_date } = props?.data[0] || {};
                    const { account_type } = props.throughWhatsapp == "true" ? "Influencer" : props.UserReducer;

                    if (status === "Accepted") {
                        if (account_type === "Brand" || account_type === "Agency") {
                        if (current_step === 1 || current_step === 3) {
                            return <p className='proposal-status-msg'>You have accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        } else if (current_step === 2 || current_step === 4) {
                            return <p className='proposal-status-msg'>Influencer has accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        }
                        } else if (account_type === "Influencer" || account_type === "Talent Partner") {
                        if (current_step === 1 || current_step === 3) {
                            return <p className='proposal-status-msg'>Brand has accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        } else if (current_step === 2 || current_step === 4) {
                            return <p className='proposal-status-msg'>You have accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        }
                        }
                    } else if (status === "Rejected") {
                        if (account_type === "Brand" || account_type === "Agency") {
                        if (current_step === 1 || current_step === 3) {
                            return <p className='proposal-status-msg'>You have rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        } else if (current_step === 2 || current_step === 4) {
                            return <p className='proposal-status-msg'>Influencer has rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        }
                        } else if (account_type === "Influencer" || account_type === "Talent Partner") {
                        if (current_step === 1 || current_step === 3) {
                            return <p className='proposal-status-msg'>Brand has rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        } else if (current_step === 2 || current_step === 4) {
                            return <p className='proposal-status-msg'>You have rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                        }
                        }
                    }
                    })()}
                </div>
                }
            </>
    );
};


const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        UserID: state.UserID.userID,
        selectedCampaign: state.InfSelectCamp.campaign,
    };
};

const connectToRedux = connect(mapStateToProps)(CustomProposalTableMobile);

export { connectToRedux as CustomProposalTableMobile };

