import React, { useEffect, useRef, useState } from 'react';
import Highcharts, { AlignValue, OptionsLayoutValue, VerticalAlignValue } from 'highcharts';

interface Props {
    dataSource: any[];
    title?: string;
    showInLegend: boolean;
    height?: number;
    width?: number;
    verticalAlign?: VerticalAlignValue;
    align?: AlignValue;
    layout?: OptionsLayoutValue
    property?: string
    size?: string
    className?: string;
    innerSize?: string
}

export const PieChart: React.FC<Props> = (props: Props) => {
    try {
        const colors = (Highcharts.getOptions()?.colors ?? []).map((c, i) =>
            Highcharts.color((Highcharts.getOptions()?.colors ?? [])[0])
                .brighten((i - 3) / 7)
                .get()
        );

        const chartOptions: Highcharts.Options = {
            chart: {
                type: "pie",
                backgroundColor: "transparent",
                height: props.height,
                width: props.width,
            },
            legend: {
                layout: props.layout || 'horizontal',
                align: props.align || "center",
                verticalAlign: props.verticalAlign || 'bottom',
                itemMarginTop: 10,
                itemMarginBottom: 10,
                labelFormat: '<b>{name}</b> <br> {percentage:.1f}%'

            },
            title: {
                text: props.title,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    innerSize: props.innerSize,
                    cursor: "pointer",
                    colors,
                    borderRadius: 0,
                    center: ['50%', '50%'],
                    size: props.size || '80%',
                    // showInLegend: props.showInLegend,
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
                        distance: -50,
                        filter: {
                            // property: props.property,
                            operator: ">",
                            value: 4,
                        },
                        
                    },
                },
            },
            series: [
                {
                    name: "",
                    type: "pie",
                    data: props.dataSource,
                },
            ],
            exporting: {
                enabled: false,
                buttons: {
                  contextButton: {
                    menuItems: [
                      'viewFullscreen',
                      'printChart',
                      //'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      //'downloadSVG',
                      'downloadCSV',
                      'downloadXLS',
                    ]
                  }
                }
              }
        };

        const chartRef = useRef(null);

        useEffect(() => {
            if (chartRef.current) {
                Highcharts.chart(chartRef.current, chartOptions);
            }
        }, [props.dataSource, chartOptions]);

        return <div ref={chartRef} className={props.className}></div>;
    } catch (error) {
        throw new Error(`PieChart component failed :: ${JSON.stringify(error)}`);
    }
};
