import React, { useState, useEffect } from 'react';

// Global flag to track whether Ctrl is pressed
let ctrlDown = false;
const ctrlKey = 17; // Control key
const vKey = 86; // V key for paste

export const useCopyPasteListener = () => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === ctrlKey || e.keyCode === 91) ctrlDown = true; // Cmd key on Mac
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.keyCode === ctrlKey || e.keyCode === 91) ctrlDown = false;
    };

    // Add event listeners for keydown and keyup
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    // Clean up event listeners when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
};
