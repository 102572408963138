import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { Button, Checkbox, Skeleton, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { DefaultUserProfile } from "../../config/config";
import { Methods } from "../../core/utility";
import UpgradeBrandPopUp from "../popup/upgrade-brand-popup";

interface TableProps {
  platformType: string;
  selectedInfluencer?: any;
  data?: any;
  loading?: boolean;
  total_records?: number;
  currentPage?: number;
  rowPerPage?: number;
  handleCheckbox?: (checked: boolean, data: any) => void;
  handleProfileView?: (sm_id: number, influencerId: number) => void;
  handleNavigate?: (id: number | string, influencerId: string | number) => void;
  setCurrentPage?: (e: number) => void;
  setRowPerPage?: (e: number) => void;
  getSubscriptionDetail?: any;
}

const youtubeFields = [
  {
    name: "yt_id",
    columnSize: "70px",
  },
  {
    name: "title",
    columnSize: "70px",
  },
  {
    name: "custom_url",
    columnSize: "200px",
    width: "200px",
    maxWidth: "200px",
  },
  {
    name: "subscriber_count",
    columnSize: "90px",
  },
  {
    name: "avg_views",
    columnSize: "70px",
  },
  {
    name: "video_count",
    columnSize: "130px",
  },
];

const youtubeDisplayName: Record<string, string> = {
  yt_id: " ",
  title: "Name",
  custom_url: "Channel Name",
  subscriber_count: "Subscribers",
  avg_views: "Avg. Views",
  video_count: "Total Videos",
};

const instagramFields = [
  {
    name: "ig_id",
    columnSize: "50px",
  },
  {
    name: "insta_name",
    columnSize: "200px",
    width: "200px",
    maxWidth: "200px",
  },
  {
    name: "username",
    columnSize: "200px",
  },
  {
    name: "followers",
    columnSize: "100px",
  },
  {
    name: "er",
    columnSize: "80px",
  },
  {
    name: "category",
    columnSize: "200px",
  },
  {
    name: "location",
    columnSize: "200px",
  },
];

const instagramDisplayName: Record<string, string> = {
  ig_id: " ",
  insta_name: "Name",
  username: "Handle Name",
  followers: "Followers",
  er: "ER",
  category: "Category",
  location: "Location",
};

const curatedInstagramFields = [
  {
    name: "ig_id",
    columnSize: "70px",
  },
  {
    name: "insta_name",
    columnSize: "200px",
    width: "200px",
    maxWidth: "200px",
  },
  {
    name: "username",
    columnSize: "200px",
  },
  {
    name: "followers",
    columnSize: "130px",
  },
  {
    name: "er",
    columnSize: "130px",
  },
  {
    name: "location",
    columnSize: "220px",
  },
];

const curatedInstagramDisplayName: Record<string, string> = {
  ig_id: " ",
  insta_name: "Name",
  username: "Handle Name",
  followers: "Followers",
  er: "ER",
  location: "Location",
};

const fieldDecider: Record<string, any[]> = {
  instagram: instagramFields,
  youtube: youtubeFields,
  curatedInstagram: curatedInstagramFields,
};

const aliesDecider: Record<string, Record<string, string>> = {
  instagram: instagramDisplayName,
  youtube: youtubeDisplayName,
  curatedInstagram: curatedInstagramDisplayName,
};

const methods: Methods = new Methods();

export const CommonTableComponent: React.FC<TableProps> = ({
  platformType,
  selectedInfluencer,
  data,
  loading,
  total_records = 0, // Default to 0 if undefined
  currentPage = 0,    // Default to 0 if undefined
  rowPerPage = 10,    // Default to 10 if undefined
  handleCheckbox = () => { }, // Default no-op function
  handleProfileView = () => { }, // Default no-op function
  handleNavigate = () => { }, // Default no-op function
  setCurrentPage = () => { }, // Default no-op function
  setRowPerPage = () => { },  // Default no-op function
  getSubscriptionDetail = () => { },  // Default no-op function
  ...props
}: TableProps) => {

  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openUpgradeBrandModal: false
  });

  function handleOpenUpgradeBrandModal(){
    setPopUps({
      openUpgradeBrandModal: true
    })
  }

  function handleClosePopUps() {
    setPopUps({
      openUpgradeBrandModal: false
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (getSubscriptionDetail.isBasicUser){
      handleOpenUpgradeBrandModal();
    }else{
      setRowPerPage(parseInt(event.target.value, 10))
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (getSubscriptionDetail.isBasicUser){
      handleOpenUpgradeBrandModal();
    }else{
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="infl-detail-table">
      <TableContainer className="common-table">
        <Table sx={{ minWidth: 650 }} aria-label="custom pagination table">
          <TableHead className="common-table-header">
            <TableRow>
              {fieldDecider[platformType].map((column: any, index: number) => (
                <TableCell
                  key={index}
                  className="tableCell"
                  style={{
                    minWidth: column.columnSize,
                  }}
                  align="left"
                >
                  {aliesDecider[platformType][column.name] || column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="common-table-body">
            {platformType === 'instagram' && data && data.map((data: any, index: number) => (
              <TableRow key={`${data?.ig_id}-${index}`}>
                <TableCell className="tableCell" align="left">
                  <Checkbox 
                    className='p-0'
                    id={`${data?.ig_id}-${index}`}
                    checked={selectedInfluencer?.some(
                      (selected: any) =>
                        JSON.stringify(selected) ===
                        JSON.stringify(data)
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                      {(data?.foc == 'true') ? handleOpenUpgradeBrandModal() : handleCheckbox(e.target.checked, data)}
                    }
                  />
                 
                </TableCell>
                <TableCell className="tableCell" align="left">
                  
               
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <div className="d-flex align-items-center">
                       {data.subscription_status && (
                      !loading && (
                        <span className="position-relative discover-dots">
                          <Tooltip
                            arrow
                            title={
                              data.subscription_status === 'non_subscribed'
                                ? 'Non - subscriber'
                                : data.subscription_status === 'active'
                                ? 'Active subscription'
                                : 'Expired subscription'
                            }
                          >
                            <span
                              className={`discover_subscription_dot discover_subscription_dot_${data.subscription_status}`}
                            ></span>
                          </Tooltip>
                        </span>
                      )
                    )}
                      
                    <div className='curated-list-infl-detail'>
                    <Link className="" to="#"
                          onClick={() => 
                            {
                              (data?.foc == 'true') 
                              ?
                                handleOpenUpgradeBrandModal()
                              : 
                                handleProfileView(data?.ig_id, data?.influencer_id)
                            }
                          }
                        ><img src={(data?.gcp_profile_image_url ? data?.gcp_profile_image_url : data?.profile_image_url) || DefaultUserProfile?.influencer_unknown_default} alt={data?.insta_name} style={{
                        width: '45px', height: '45px', borderRadius: '50%', marginRight:
                          '10px', display: 'inline-flex',
                        border: '1px solid rgba(0, 0, 0, 0.4)'
                      }} /></Link>
                      <Tooltip arrow title={data?.insta_name}>
                        <Link className="btn btn-link text-left p-0 align-self-center infl-name" to="#"
                          onClick={() => 
                            {
                              (data?.foc == 'true') 
                              ?
                                handleOpenUpgradeBrandModal()
                              : 
                                handleProfileView(data?.ig_id, data?.influencer_id)
                            }
                          }
                        >{data?.insta_name}</Link>
                      </Tooltip>
                    </div>
                    </div>
                  )}
                 
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    data?.username
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    methods.convertNumber(parseInt(data?.followers))
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    `${data.er}%`
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    [data?.catg_1, data?.catg_2, data?.catg_3].filter(data => data !== '').join(', ')
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    [data?.city, data.state, data.country].filter(data => data !== '').join(', ')
                    //   [data?.city, "India"].filter(data => data !== '').join(', ')
                  )}
                </TableCell>
              </TableRow>
            ))}

            {
              platformType === 'youtube' && data && data.map((data: any, index: number) => (
                <TableRow key={`${data?.yt_id}-${index}`}>
                  <TableCell className="tableCell" align="left">
                    <Checkbox className='p-0'
                      id={`${data?.yt_id}-${index}`}
                      checked={selectedInfluencer?.some(
                        (selected: any) =>
                          JSON.stringify(selected) ===
                          JSON.stringify(data)
                      )}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        {(data?.foc == 'true') ? handleOpenUpgradeBrandModal() : handleCheckbox(e.target.checked, data)}
                      }
                    />
                     
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      <div className="d-flex align-items-center">
                         {data.subscription_status && (
                      !loading && (
                        <span className="position-relative discover-dots">
                          <Tooltip
                            arrow
                            title={
                              data.subscription_status === 'non_subscribed'
                                ? 'Non - subscriber'
                                : data.subscription_status === 'active'
                                ? 'Active subscription'
                                : 'Expired subscription'
                            }
                          >
                            <span
                              className={`discover_subscription_dot discover_subscription_dot_${data.subscription_status}`}
                            ></span>
                          </Tooltip>
                        </span>
                      )
                    )}
                        <div className='curated-list-infl-detail'>
                        <Link className="" to="#"
                              onClick={() => 
                                {
                                  (data?.foc == 'true') 
                                  ?
                                    handleOpenUpgradeBrandModal()
                                  : 
                                    handleProfileView(data?.yt_id, data?.influencer_id)
                                }
                              }
                            ><img src={(data?.gcp_profile_image_url ? data?.gcp_profile_image_url : data?.profile_image_url) || DefaultUserProfile?.influencer_unknown_default} alt={data?.title} style={{
                            width: '45px', height: '45px', borderRadius: '50%', marginRight:
                              '10px', display: 'inline-flex',
                            border: '1px solid rgba(0, 0, 0, 0.4)'
                          }} /></Link>
                          <Tooltip arrow title={data?.title}>
                            <Link className="btn btn-link text-left p-0 align-self-center" to="#"
                              onClick={() => 
                                {
                                  (data?.foc == 'true') 
                                  ?
                                    handleOpenUpgradeBrandModal()
                                  : 
                                    handleProfileView(data?.yt_id, data?.influencer_id)
                                }
                              }
                            >{data?.title}</Link>
                          </Tooltip>

                        </div>
                      </div>
                    )}
                    
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      data?.custom_url
                    )}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      methods.convertNumber(parseInt(data?.subscriber_count))
                    )}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      methods.convertNumber(parseInt(data?.avg_views))
                    )}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {loading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      data?.video_count
                    )}
                  </TableCell>
                </TableRow>
              ))
            }

            {platformType === 'curatedInstagram' && data && data.map((data: any, index: number) => (
              <TableRow
                className="curalist-list-table-infl-list" key={`${data?.ig_id}-${index}`}>
                <TableCell className="tableCell" align="left">
                  <Checkbox className='p-0'
                    id={`${data?.ig_id}-${index}`}
                    checked={selectedInfluencer?.some(
                      (selected: any) =>
                        JSON.stringify(selected) ===
                        JSON.stringify(data)
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      {(data?.foc == 'true') ? handleOpenUpgradeBrandModal() : handleCheckbox(e.target.checked, data)}
                    }
                  />
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <div className='curated-list-infl-detail'>
                      <img src={(data?.gcp_profile_image_url ? data?.gcp_profile_image_url : data?.profile_image_url) || DefaultUserProfile?.influencer_unknown_default} alt={data?.insta_name} style={{
                        width: '45px', height: '45px', borderRadius: '50%', marginRight:
                          '10px', display: 'inline-flex',
                        border: '1px solid rgba(0, 0, 0, 0.4)'
                      }} />
                      <Tooltip arrow title={data?.insta_name}>
                        <Link className="btn btn-link text-left p-0 infl-name" to="#"
                          onClick={() => 
                            {
                              (data?.foc == 'true') 
                              ?
                                handleOpenUpgradeBrandModal()
                              : 
                                handleProfileView(data?.ig_id, data?.influencer_id)
                            }
                          }
                        >{data?.insta_name}</Link>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    data?.username
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    methods.convertNumber(parseInt(data?.followers))
                  )}
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    `${data.er}%`)
                  }
                </TableCell>
                <TableCell className="tableCell" align="left">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    [data?.city, data.state, data.country].filter(data => data !== '').join(', ')
                  )}
                </TableCell>
              </TableRow>))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10]}
        className={(getSubscriptionDetail?.isBasicUser) ? 'common-pagination opacity-50' : 'common-pagination'}
        count={total_records}
        page={currentPage}
        rowsPerPage={rowPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          color: "#00aae5",
          marginTop: "10px",
        }}
      />
      {popUps.openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={popUps.openUpgradeBrandModal}
          onClose={() => handleClosePopUps()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}
    </div>
  );
};

