import { Link } from 'react-router-dom';
import { Methods } from '../../../core/utility';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { DummyCampaignImage } from '../../../config/config';
import MaterialSymbol from '../../../components/icons/material-symbol';
import IconComment from '../../../components/icons/icon-comment';
import IconImpression from '../../../components/icons/icon-impression';
type Image = {
  shortcode: string;
  gcp_media_url: string;
  like_count: number;
  comments_count: number;
  saved: number;
  impressions: number;
  engagement: number;
  video_views: number;
  reach: number;
};

type Carousel = {
  shortcode: string;
  gcp_media_url: string;
  like_count: number;
  comments_count: number;
  saved: number;
  impressions: number;
  engagement: number;
  video_views: number;
  reach: number;
};

type Props = {
  influencerData: {
    most_liked_images: Image[];
    most_liked_carousel: Carousel[];
  };
};
const methods = new Methods()
const InsightMediaImageCard = (props: Props) => {
  return (
    <Grid container spacing={3}>
      {props?.influencerData && 
        (
          props?.influencerData?.most_liked_carousel?.length > 0 
            ? props?.influencerData?.most_liked_carousel?.map((carousel: any) => {
                // Render logic for most_liked_carousel
            return <Grid key={carousel?.shortcode} item xs={12} sm={6} md={6} lg={3}>
          <div className="card-posts">
            <Link
              to={`https://www.instagram.com/p/${carousel?.shortcode}/`}
              className="card-posts-image embed-responsive embed-responsive-1by1"
              target="_blank"
            >
              <img
                className="embed-responsive-item"
                src={(carousel?.gcp_media_url === 'undefined' || carousel?.gcp_media_url === '') ? DummyCampaignImage.default_campaign_image : carousel?.gcp_media_url}
                width="100%"
                alt="posts"
              />
            </Link>
            {((carousel?.like_count != 0) || (carousel?.comments_count != 0) || (carousel?.reach != 0)) &&
              <div className="card-posts-info">
                <ul>
                  {carousel?.like_count > 0 &&
                    <li>
                      {/* <strong>Likes</strong> */}
                        <Tooltip title="Likes" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                            <MaterialSymbol className='text-white' iconName='favorite' fontweight='200' />
                          </IconButton>
                        </Tooltip>
                      <span>{methods.convertNumber(carousel?.like_count)}</span>
                    </li>
                  }
                  {carousel?.comments_count > 0  &&
                    <li>
                      {/* <strong>Comments</strong> */}

                      <Tooltip title="Comments" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <IconComment />
                        </IconButton>
                      </Tooltip>
                      <span>{methods.convertNumber(carousel?.comments_count)}</span>
                    </li>
                  }
                  {carousel?.reach > 0 &&
                    <li>
                      {/* <strong>Reach</strong> */}
                      <Tooltip title="Reach" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <IconImpression />
                        </IconButton>
                      </Tooltip>
                      <span>{methods.convertNumber(carousel?.reach)}</span>
                    </li>
                  }
                  {/* <li>
                    <Tooltip title="Saved" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='bookmark' fontweight='200' className='text-white' />
                      </IconButton>
                    </Tooltip>
                    <strong>Saved</strong>
                  
                    <span>{img?.saved === 0 ? "NA" : methods.convertNumber(img?.saved)}</span>
                  </li> 
                  <li>
                    <strong>Impressions</strong>
                    <Tooltip title="Impressions" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='fingerprint' fontweight='200' className='text-white' />
                      </IconButton>
                      </Tooltip>
                    <span>{img?.impressions === 0 ? "NA" : methods.convertNumber(img?.impressions)}</span>
                  </li>
                  <li>
                    <strong>Engagement</strong>
                    <Tooltip title="Engagement" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='touch_app' fontweight='200' className='text-white' />
                      </IconButton>
                    </Tooltip>
                    <span>{img?.engagement === 0 ? "NA" : methods.convertNumber(img?.engagement)}</span>
                  </li>
                  {/* <li>
                    <strong>Views</strong>
                    <span>{img?.video_views === 0 ? "NA" : methods.convertNumber(img?.video_views)}</span>
                  </li> */}
                  
                </ul>
              </div>
            }
          </div>
        </Grid>;
              })
            : props?.influencerData?.most_liked_images?.length > 0 &&
              props?.influencerData?.most_liked_images
                ?.sort((a, b) => b.like_count - a.like_count)
                ?.slice(0, 3)
                ?.map((img: any) => {
                  // Render logic for most_liked_images
                  return <Grid key={img?.shortcode} item xs={12} sm={6} md={6} lg={3}>
          <div className="card-posts">
            <Link
              to={`https://www.instagram.com/p/${img?.shortcode}/`}
              className="card-posts-image embed-responsive embed-responsive-1by1"
              target="_blank"
            >
              <img
                className="embed-responsive-item"
                src={(img?.gcp_media_url === 'undefined' || img?.gcp_media_url === '') ? DummyCampaignImage.default_campaign_image : img?.gcp_media_url}
                width="100%"
                alt="posts"
              />
            </Link>
            {((img?.like_count != 0) || (img?.comments_count != 0) || (img?.reach != 0)) &&
              <div className="card-posts-info">
                <ul>
                  {img?.like_count > 0 &&
                    <li>
                      {/* <strong>Likes</strong> */}
                        <Tooltip title="Likes" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                            <MaterialSymbol className='text-white' iconName='favorite' fontweight='200' />
                          </IconButton>
                        </Tooltip>
                      <span>{methods.convertNumber(img?.like_count)}</span>
                    </li>
                  }
                  {img?.comments_count > 0  &&
                    <li>
                      {/* <strong>Comments</strong> */}

                      <Tooltip title="Comments" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <IconComment />
                        </IconButton>
                      </Tooltip>
                      <span>{methods.convertNumber(img?.comments_count)}</span>
                    </li>
                  }
                  {img?.reach > 0 &&
                    <li>
                      {/* <strong>Reach</strong> */}
                      <Tooltip title="Reach" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <IconImpression />
                        </IconButton>
                      </Tooltip>
                      <span>{methods.convertNumber(img?.reach)}</span>
                    </li>
                  }
                  {/* <li>
                    <Tooltip title="Saved" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='bookmark' fontweight='200' className='text-white' />
                      </IconButton>
                    </Tooltip>
                    <strong>Saved</strong>
                  
                    <span>{img?.saved === 0 ? "NA" : methods.convertNumber(img?.saved)}</span>
                  </li> 
                  <li>
                    <strong>Impressions</strong>
                    <Tooltip title="Impressions" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='fingerprint' fontweight='200' className='text-white' />
                      </IconButton>
                      </Tooltip>
                    <span>{img?.impressions === 0 ? "NA" : methods.convertNumber(img?.impressions)}</span>
                  </li>
                  <li>
                    <strong>Engagement</strong>
                    <Tooltip title="Engagement" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='touch_app' fontweight='200' className='text-white' />
                      </IconButton>
                    </Tooltip>
                    <span>{img?.engagement === 0 ? "NA" : methods.convertNumber(img?.engagement)}</span>
                  </li>
                  {/* <li>
                    <strong>Views</strong>
                    <span>{img?.video_views === 0 ? "NA" : methods.convertNumber(img?.video_views)}</span>
                  </li> */}
                  
                </ul>
              </div>
            }
          </div>
        </Grid>
                })
        )}
    </Grid>
  )
};


export default InsightMediaImageCard;