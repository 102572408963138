export class TransformDeliverables {

    /* This function only For Convert Deliverables According to Backend function */
    transformCollectionForDatabase(formDataCollection: any[]) {
        let uniqueSet: { [key: string]: any } = {};
        /*
        Example Purpose:-
        
        {
          "deliverable": "ig_static",
          "no_of_days": 0,
          "no_of_images": 3,
          "quantity": 4,
          "influencer_type": [
            3,
            2,
            1,
            4
          ]
        }
        *******************************************************
                  Upper Data Transform to Down Data
        ********************************************************
        "1": {
          "ig_story": 1,
          "ig_static_image": 3,
          "ig_reel": 2,
          "available_for_exclusivity": 15,
          "ig_static": 4
        }
         */
        try {
            for (let x of formDataCollection) {
                for (let y of x.influencer_type) {
                    /**
                     * Create Unique Keyset According to influencer_type_id
                     */
                    if (!uniqueSet[y]) {
                        uniqueSet[y] = { [x.deliverable]: x.quantity, ig_static_image: x.ig_static_image || 0 }
                    } else {
                        /** 
                         * Update Object Key Data
                         */
                        uniqueSet[y] = {
                            ...uniqueSet[y],
                            [x.deliverable]: x.quantity,
                            ig_static_image: uniqueSet[y].ig_static_image > 0 ? uniqueSet[y].ig_static_image : x.ig_static_image || 0
                        }
                    }
                }
            }
            return uniqueSet;
        } catch (err) {
            throw new Error(`transformCollectiontoDatabase Failed :: ${err}`)
        }
    };


    /**
    * Transforms a database collection into a form object.
    *  
    * @param {any[]} DbCollection - The database collection to transform.
    * @returns {any[]} - The transformed form object.
    */

    transformDbForFormObj(DbCollection: any[]) {
        /*
           Example Purpose:-
           
            {
              "influencer_id": 1,
              "ig_story": 10,
              "ig_static_image": 20,
              "ig_reel": 3,
              "available_for_exclusivity": 0,
              "ig_static": 5
            }
           *******************************************************
                     Upper Data Transform to Down Data
           ********************************************************
            {
                deliverable: 'ig_story',
                ig_static_image: 0,
                quantity: 10,
                influencer_type: [ 1 ]
            },
            */

        let newSource: any[] = [];
        try {
            if (DbCollection.length > 0) {
                for (let x of DbCollection) {
                    /* Get influencer_id */
                    let influencer_type_id = x.influencer_type_id;
                    Object.entries(x).forEach(([key, value]) => {
                        /* Check if key is influencer_id then return */
                        if (['campaign_id', 'influencer_type_id', 'created_at', 'updated_at'].includes(key)) {
                            return;
                        };
                        let createObj: any = {};
                        let index = newSource.findIndex((item) => item.deliverable === key && item.quantity === value);
                        if (index >= 0) {
                            newSource[index].influencer_type.push(influencer_type_id);
                            return;
                        } else if (key !== 'ig_static_image') {
                            if (value === 0) {
                                return;
                            };
                            createObj = {
                                deliverable: key,
                                ig_static_image: key === 'ig_static' ? x.ig_static_image : 0,
                                quantity: value,
                                influencer_type: [influencer_type_id]
                            };

                            if (Object.keys(createObj).length > 0) {
                                newSource.push(createObj);
                            };
                        }
                    })
                };
            }
            return newSource;
        } catch (error) {
            throw new Error(`TransfromDbtoFormObj Failed :: ${error}`)
        }
    };


}