import React, { useState, useEffect, Fragment } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { CampaingInfluencerAll } from "./campaign-influencer-all/campaign-influencer-all";
import { CampaingInfluencerCustom } from "./campaign-influencer-custom/campaign-influencer-custom";
import { CampaingInfluencerList } from "./campaign-influencer-list/campaign-influencer-list";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateCampaign, Loading } from "../../../../../redux/action";
import {
  CreateCampaign as CreateCampaignService,
  LocalService,
} from "../../../../../core/services";

const localService: LocalService = new LocalService();

interface Props {
  UserReducer: any;
  UserID: any;
  campaignReducer: any;
  CreateCampaignReducer?: (data: any) => void;
  loading: (data: any) => void;
}

const initformState = {
  gender: "any",
  costing_module: "individual",
  category: [],
  location_id: [],
  influencer_list_ids: [],
  listInfluencers: {},
  influencer_selector: [], // it will be change for new layout
};

const CampaingInfluencer: React.FC<Props> = (props: Props) => {
  // const [activeTab, setActiveTab] = useState(props.campaignReducer.activeTab);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({
    brand_id: props.UserReducer.brand_id,
    user_id: props.UserID,
    campaign_id: props?.campaignReducer?.createCampaign?.campaign_id,
    ...initformState,
    ...props.campaignReducer.influencer,
    // influencer_selector: props.campaignReducer.influencer
  });

  const [openallValidation, setOpenAllValidation] = useState({
    location: { status: true, message: "" },
    category: { status: true, message: "" },
    deliverables: { status: true, message: "" },
  });

  const [chooseAListValidation, setChooseAListValidation] = useState({
    influencer_list: { status: true, message: "" },
    deliverables: { status: true, message: "" },
  });

  const navigation = useNavigate();

  // useEffect(() => {
  //   setFormData({ ...formData, ...props.campaignReducer.influencer })
  // }, [])

  // useEffect(() => {
  //   console.log('formData ==> ', formData);
  //   console.log('props.campaignReducer.createCampaign ==> ', props.campaignReducer.influencer);
  // }, [])


  useEffect(() => {
    if (!props.campaignReducer.createPlan) {
      navigation("/brand/plans");
    }
  }, []);

  const handleTabClick = (id: number) => {
    const newId = +id;
    if (props.CreateCampaignReducer) {
      props.CreateCampaignReducer({ activeTab: newId });
    }
    if(newId == 1){
      setFormData({ ...formData,...{
        influencer_list_ids: [],
        listInfluencers: {},
        listInfluencer_sm_ids: {}
      }
    })
  }
}

  const handleOpenAlltabData = (e: any) => {
    setFormData({ ...formData, ...e });
  };

  const handleInfluencerListtabData = (e: any) => {
    setFormData({ ...formData, ...e });
  };

  const handleSave = async () => {
    const promise = new Promise((resolve, reject) => {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({ influencer: formData });
      }
      if (props.campaignReducer.activeTab === 1) {
        setLoading(true);
        CreateCampaignService.create_influencer_all(formData)
          .then((res) => {
            resolve(res);
            setLoading(false);
            const checkStatus = res.data.status.status ? "success" : "error";
            navigation("/brand/plans");
            // localService.toastify(res.data.status.message, checkStatus, 1000);
          })
          .catch((error) => {
            reject({ status: false, message: "" });
            throw new Error(`Have Influencer Error :: ${error}`);
          });
      } else if (props.campaignReducer.activeTab === 2) {
        setLoading(true);
        CreateCampaignService.create_influencer_list(formData)
          .then((res: any) => {
            resolve(res);
            setLoading(false);
            const checkStatus = res.data.status.status ? "success" : "error";
            localService.toastify(res.data.status.message, checkStatus, 1000);
            navigation("/brand/plans");
          })
          .catch((error) => {
            reject({ status: false, message: "" });
            throw new Error(`Have Influencer Error :: ${error}`);
          });
      } else if (props.campaignReducer.activeTab === 3) {
      }
    });

    return promise;
  };

  const handleNext = async () => {
    let allowToNextStep = false;
    type ValidationType = {
      status: boolean;
      message: string;
    };
    if (props.campaignReducer.activeTab === 1) {
      const locationValidation: ValidationType =
        formData.location_id.length > 0
          ? { status: true, message: "" }
          : { status: false, message: "location is required" };
      const categoryValidation: ValidationType =
        formData.category.length > 0
          ? { status: true, message: "" }
          : { status: false, message: "category is required" };
      const deliverableValidation: ValidationType =
        (Object.keys(formData.influencer_selector).length != 0 && formData.influencer_selector.constructor === Object)
          ? { status: true, message: "" }
          : { status: false, message: "deliverables is required" };

      allowToNextStep =
        locationValidation.status && categoryValidation.status && deliverableValidation.status ? true : false;

      setOpenAllValidation((e) => {
        e.location = locationValidation;
        e.category = categoryValidation;
        e.deliverables = deliverableValidation;
        return { ...e };
      });
    } else if (props.campaignReducer.activeTab === 2) {
      const validateList: ValidationType =
        formData.influencer_list_ids.length > 0
          ? { status: true, message: "" }
          : { status: false, message: "influencer lists are required" };
      const deliverableValidation: ValidationType =
        (Object.keys(formData.influencer_selector).length != 0 && formData.influencer_selector.constructor === Object)
          ? { status: true, message: "" }
          : { status: false, message: "deliverables is required" };

      allowToNextStep = validateList.status && deliverableValidation.status ? true : false;
      setChooseAListValidation((e) => {
        e.influencer_list = validateList;
        e.deliverables = deliverableValidation;
        return { ...e };
      });
    }

    if (allowToNextStep) {
      const response: any = await handleSave();
      if (response.data.status.status) {
        if (props.CreateCampaignReducer) {
          props.CreateCampaignReducer({
            navigation: {
              ...props.campaignReducer.navigation,
              post: true,
            },
            completeStep: {
              ...props.campaignReducer.completeStep,
              influencer: true,
            },
          });
        }
        navigation("/brand/campaign/post");
      }
    }
  };

  const handleBack = () => {
    let checkCampaignType = (
      props.campaignReducer.createCampaign?.campaign_type + ""
    )
      .toLocaleLowerCase()
      .trim()
      .includes("barter");
    let navigateLink = checkCampaignType
      ? "/brand/campaign/createproduct"
      : "/brand/campaign/createcampaign";
    navigation(navigateLink);
  };

  return (
    <Fragment>
      {props.campaignReducer.createPlan && (
        <div className="campaign_influencer_main_container">
          {/* <button type="button" onClick={() => console.log(formData)}>Print Form Data</button> */}
          <ul className="campaign_influencer_tabs">
            <FormControl className="tabs_formControl">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e: any) => handleTabClick(e.target.value)}
                // defaultValue="1"
                value={
                  props.campaignReducer.activeTab
                    ? props.campaignReducer.activeTab
                    : 1
                }
              >
                <li
                  className={
                    props.campaignReducer.activeTab === 1 ? "active" : ""
                  }
                  onClick={() => handleTabClick(1)}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio className="p-0 me-2" />}
                    label="Open for all"
                  />{" "}
                  <span className="tabContent_formControl">
                    All influencers falling under the specified criteria, will
                    be able to apply.
                  </span>
                </li>
                <li
                  className={
                    props.campaignReducer.activeTab === 2 ? "active" : ""
                  }
                  onClick={() => handleTabClick(2)}
                  style={{ marginLeft: `15px`, marginRight: `15px` }}
                >
                  <FormControlLabel
                    value={2}
                    control={<Radio className="p-0 me-2" />}
                    label="Choose a List"
                  />{" "}
                  <span className="tabContent_formControl">
                    Select a list of influencers (from My Lists) to invite to
                    this campaign. No other influencers will be able to apply
                    if you choose any list.
                  </span>
                </li>
                {/* <li
                  className={
                    props.campaignReducer.activeTab === 3 ? "active" : ""
                  }
                >
                  <FormControlLabel
                    value={3}
                    control={<Radio className="p-0 me-2" />}
                    label="Add Influencer by usernames"
                  />{" "}
                  <br />
                  <span className="tabContent_formControl">
                    Only the added usernames will receive the invite to this
                    line item.
                  </span>
                </li> */}
              </RadioGroup>
            </FormControl>
          </ul>
          <div className="campaign_influencer_tab_container">
            {/* <button type="button" onClick={() => console.log('formData ==> ', formData)}>Get Form Value</button> */}
            {props.campaignReducer.activeTab === 1 && (
              <div className="tab_panel">
                <CampaingInfluencerAll
                  open_all={formData}
                  updateOpen_all={handleOpenAlltabData}
                  getValidationStatus={openallValidation}
                  platformType={
                    props.campaignReducer.createCampaign?.campaign_platform
                  }
                />
              </div>
            )}
            {props.campaignReducer.activeTab === 2 && (
              <div className="tab_panel">
                <CampaingInfluencerList
                  getValidationStatus={chooseAListValidation}
                  influencerList={formData}
                  update_influencerList={handleInfluencerListtabData}
                  platformType={
                    props.campaignReducer.createCampaign?.campaign_platform + ""
                  }
                />
              </div>
            )}
            {props.campaignReducer.activeTab === 3 && (
              <div className="tab_panel">
                {/* <CampaingInfluencerCustom /> */}
              </div>
            )}
          </div>
          <div className="button_group mt-5 mb-5">
            <div className="col campaign_influencer_buttons">
              <LoadingButton
                loading={loading}
                loadingPosition="start"

                className="btn btn-outline-primary me-auto ms-0"
                variant="outlined"
                onClick={handleBack}
              >
                Back
              </LoadingButton>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                className="btn btn-outline-primary"
                variant="contained"
                onClick={handleSave}
              >
                Save as draft
              </LoadingButton>

              <LoadingButton
                loading={loading}
                loadingPosition="start"
                className="btn btn-primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </LoadingButton>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    UserID: state.UserID.userID,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaingInfluencer);

export { connectToRedux as CampaingInfluencer };
