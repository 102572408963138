import React, { useState, useEffect } from "react";
import "./influencer-selector.scss";
import megaplus from "../../assets/images/campaign_icons/mega-plus.png";
import mega from "../../assets/images/campaign_icons/mega.png";
import macroplus from "../../assets/images/campaign_icons/macro-plus.png";
import macro from "../../assets/images/campaign_icons/macro.png";
import microplus from "../../assets/images/campaign_icons/micro-plus.png";
import micro from "../../assets/images/campaign_icons/micro.png";
import nano from "../../assets/images/campaign_icons/nano.png";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { MenuPropsSelect } from "../../core/utility/style";

interface CountProps {
  deliverables: number;
  setDeliverables: (data: any) => void;
  enable: boolean;
}

export const Counter: React.FC<CountProps> = (props: CountProps) => {
  useEffect(() => {
    if (props.enable) {
      let value = 1;
      if (props.deliverables > 0) {
        value = props.deliverables;
      }
      props.setDeliverables(value);
    } else {
      props.setDeliverables(0);
    }
  }, [props.enable]);

  const increment = () => {
    if (props.enable) {
      props.setDeliverables(props.deliverables + 1);
    }
  };

  const decrement = () => {
    if (props.enable) {
      if (props.deliverables > 1) {
        props.setDeliverables(props.deliverables - 1);
      }
    }
  };

  const handleInput = (e: any) => {
    if (props.enable) {
      const {
        target: { value },
      } = e;
      props.setDeliverables(value);
    }
  };

  return (
    <div className="counter_main">
      <span onClick={decrement} className="count_button">
        -
      </span>
      <input
        disabled={!props.enable}
        type="text"
        value={
          props.deliverables === 0 ||
          props.deliverables === undefined ||
          props.deliverables === null
            ? 1
            : props.deliverables
        }
        // value={props.deliverables === 0 ? 1 : props.deliverables}
        onChange={handleInput}
      />
      <span onClick={increment} className="count_button">
        +
      </span>
    </div>
  );
};

interface DeliverablesProps {
  deliverables: any;
  setdeliverables: (data: any) => void;
  // checked: any;
  // setChecked: any;
  platformType: string;
}

const initialCheckBox = {
  reels: false,
  story: false,
  carousal_image_post: false,
  yt_shorts: false,
  yt_dedicated_video: false,
  yt_integrated_video: false,
  digital_rights: false,
  exclusivity: false,
};

export const Deliverables: React.FC<DeliverablesProps> = (
  props: DeliverablesProps
) => {
  const [checkBox, setChecked] = useState(initialCheckBox);
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    let count = 0;
    const updateCheckState = (property: string, value: boolean) => {
      count++;
      setChecked((prevState) => ({ ...prevState, [property]: value }));
    };

    // Update check states based on props.currentValue
    if (props.deliverables) {
      updateCheckState(
        "reels",
        props.deliverables.reels !== undefined && props.deliverables.reels !== 0
      );
      updateCheckState(
        "story",
        props.deliverables.story !== undefined && props.deliverables.story !== 0
      );

      updateCheckState(
        "carousal_image_post",
        props.deliverables.carousal_image_post !== undefined &&
          props.deliverables.carousal_image_post !== 0
      );
      updateCheckState(
        "yt_shorts",
        props.deliverables.yt_shorts !== undefined &&
          props.deliverables.yt_shorts !== 0
      );
      updateCheckState(
        "yt_dedicated_video",
        props.deliverables.yt_dedicated_video !== undefined &&
          props.deliverables.yt_dedicated_video !== 0
      );
      updateCheckState(
        "yt_integrated_video",
        props.deliverables.yt_integrated_video !== undefined &&
          props.deliverables.yt_integrated_video !== 0
      );
      updateCheckState(
        "digital_rights",
        props.deliverables.digital_rights !== undefined &&
          props.deliverables.digital_rights !== 0
      );
      updateCheckState(
        "exclusivity",
        props.deliverables.exclusivity !== undefined &&
          props.deliverables.exclusivity !== 0
      );

      setShowElement(true);
    }
  }, []);

  // useEffect(() => {
  //   /*
  //     Validation Cost for Digital Rights and Available for exclusivity for select duration
  //   */
  //  //digital rights
  //   if(!checkBox.digital_rights){
  //     props.deliverables.digital_rights = 0;
  //   }

  //   // exclusivity
  //   if(!checkBox.exclusivity){
  //     props.deliverables.exclusivity = 0;
  //   }

  // }, [checkBox])

  return (
    <div className="container-fluid select_deliverables_main_container">
      <div className="row">
        <div className="col">
          <FormLabel id="id_rb-costing-only">
            {" "}
            <span className="field_header"> Select Deliverables </span>{" "}
          </FormLabel>
        </div>
      </div>
      <div className="row">
        {showElement && (
          <div className="col">
            <ul>
              {/* Instagram Section  */}
              {(props.platformType + "").toLocaleLowerCase() ===
                "instagram" && (
                <>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, b) => {
                            setChecked({ ...checkBox, reels: b });
                          }}
                          checked={checkBox.reels}
                        />
                      }
                      label="Reel"
                    />
                    <Counter
                      deliverables={props.deliverables.reels}
                      setDeliverables={(e) =>
                        props.setdeliverables({ reels: e })
                      }
                      enable={checkBox.reels}
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, b) => {
                            setChecked({ ...checkBox, story: b });
                          }}
                          checked={checkBox.story}
                        />
                      }
                      label="Story"
                    />
                    <Counter
                      deliverables={props.deliverables.story}
                      setDeliverables={(e) =>
                        props.setdeliverables({ story: e })
                      }
                      enable={checkBox.story}
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, b) => {
                            setChecked({ ...checkBox, carousal_image_post: b });
                          }}
                          checked={checkBox.carousal_image_post}
                        />
                      }
                      label="Carousal image Post"
                    />
                    <Counter
                      deliverables={props.deliverables.carousal_image_post}
                      setDeliverables={(e) =>
                        props.setdeliverables({ carousal_image_post: e })
                      }
                      enable={checkBox.carousal_image_post}
                    />
                  </li>
                </>
              )}
              {/* Youtube Section  */}
              {(props.platformType + "").toLocaleLowerCase() === "youtube" && (
                <>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, b) => {
                            setChecked({ ...checkBox, yt_shorts: b });
                          }}
                          checked={checkBox.yt_shorts}
                        />
                      }
                      label="Shorts"
                    />
                    <Counter
                      deliverables={props.deliverables.yt_shorts}
                      setDeliverables={(e) =>
                        props.setdeliverables({ yt_shorts: e })
                      }
                      enable={checkBox.yt_shorts}
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, b) => {
                            setChecked({ ...checkBox, yt_dedicated_video: b });
                          }}
                          checked={checkBox.yt_dedicated_video}
                        />
                      }
                      label="Dedicated Video"
                    />
                    <Counter
                      deliverables={props.deliverables.yt_dedicated_video}
                      setDeliverables={(e) =>
                        props.setdeliverables({ yt_dedicated_video: e })
                      }
                      enable={checkBox.yt_dedicated_video}
                    />
                  </li>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, b) => {
                            setChecked({ ...checkBox, yt_integrated_video: b });
                          }}
                          checked={checkBox.yt_integrated_video}
                        />
                      }
                      label="Integrated Video"
                    />
                    <Counter
                      deliverables={props.deliverables.yt_integrated_video}
                      setDeliverables={(e) =>
                        props.setdeliverables({ yt_integrated_video: e })
                      }
                      enable={checkBox.yt_integrated_video}
                    />
                  </li>
                </>
              )}

              <li>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(v, b) => {
                        setChecked({ ...checkBox, digital_rights: b });
                        if (!b) {
                          props.deliverables.digital_rights = 0;
                        }
                      }}
                      checked={checkBox.digital_rights}
                    />
                  }
                  label="Cost for Digital Rights"
                />
                <FormControl size="small" disabled={!checkBox.digital_rights}>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="id_digital_rights"
                    value={props.deliverables.digital_rights ?? 0}
                    label=""
                    onChange={(e) =>
                      props.setdeliverables({
                        digital_rights: e.target.value,
                      })
                    }
                    MenuProps={MenuPropsSelect}
                  >
                    <MenuItem value={0}>Select Duration</MenuItem>
                    <MenuItem value={15}>15 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                    <MenuItem value={90}>90 Days</MenuItem>
                    <MenuItem value={180}>180 Days</MenuItem>
                  </Select>
                </FormControl>
              </li>
              <li>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(v, b) => {
                        setChecked({ ...checkBox, exclusivity: b });
                        if (!b) {
                          props.deliverables.exclusivity = 0;
                        }
                      }}
                      checked={checkBox.exclusivity}
                    />
                  }
                  label="Available for exclusivity for select duration"
                />
                <FormControl size="small" disabled={!checkBox.exclusivity}>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="id_exclusivity"
                    value={props.deliverables.exclusivity ?? 0}
                    label=""
                    onChange={(e) => {
                      props.setdeliverables({
                        exclusivity: e.target.value,
                      });
                    }}
                    MenuProps={MenuPropsSelect}
                  >
                    <MenuItem value={0}>Select Duration</MenuItem>
                    <MenuItem value={15}>15 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                    <MenuItem value={90}>90 Days</MenuItem>
                    <MenuItem value={180}>180 Days</MenuItem>
                  </Select>
                </FormControl>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

interface Props {
  influencerSelectorData: any;
  updateInfluencerSelectorData: (data: any) => void;
  platformType: string;
}

export const InfluencerSelector: React.FC<Props> = (props: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (id: number) => {
    setActiveTab(id);
  };

  const handleDeliverables = (v: any, e: any) => {
    props.updateInfluencerSelectorData({
      ...props.influencerSelectorData,
      [v]: { ...props.influencerSelectorData[v], ...e },
    });
  };

  return (
    <div className="influencer_type_component_container">
      {/* <button type="button" onClick={() => console.log(props.influencerSelectorData)}>Check Influencer Data</button> */}
      <ul className="influencer_type_tabs">
        <li
          className={`${activeTab === 1 ? "active" : ""} `}
          onClick={() => handleTabClick(1)}
        >
          <img className="tab_image" src={megaplus} alt="mega plus" />
          <br />
          <span>Mega Plus</span> <br />
          <span>(5M+)</span>
        </li>
        <li
          className={`${activeTab === 2 ? "active" : ""}`}
          onClick={() => handleTabClick(2)}
        >
          <img className="tab_image" src={mega} alt="mega plus" />
          <br />
          <span>Mega</span> <br />
          <span>(1M - 5M)</span>
        </li>
        <li
          className={`${activeTab === 3 ? "active" : ""}`}
          onClick={() => handleTabClick(3)}
        >
          <img className="tab_image" src={macroplus} alt="mega plus" />
          <br />
          <span>Macro Plus</span> <br />
          <span>(500K - 1M)</span>
        </li>
        <li
          className={`${activeTab === 4 ? "active" : ""}`}
          onClick={() => handleTabClick(4)}
        >
          <img className="tab_image" src={macro} alt="mega plus" />
          <br />
          <span>Macro</span> <br />
          <span>(100K - 500K)</span>
        </li>
        <li
          className={`${activeTab === 5 ? "active" : ""}`}
          onClick={() => handleTabClick(5)}
        >
          <img className="tab_image" src={microplus} alt="mega plus" />
          <br />
          <span>Micro Plus</span> <br />
          <span>(50K - 100K)</span>
        </li>
        <li
          className={`${activeTab === 6 ? "active" : ""}`}
          onClick={() => handleTabClick(6)}
        >
          <img className="tab_image" src={micro} alt="mega plus" />
          <br />
          <span>Micro</span> <br />
          <span>(10K - 50K)</span>
        </li>
        <li
          className={`${activeTab === 7 ? "active" : ""}`}
          onClick={() => handleTabClick(7)}
        >
          <img className="tab_image" src={nano} alt="mega plus" />
          <br />
          <span>Nano</span> <br />
          <span>(1K - 10K)</span>
        </li>
      </ul>

      <div className="influencer_type_tab_container">
        {activeTab === 1 && (
          <div className="tab_panel">
            {/* Mega Plus */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["7"]
                  ? props.influencerSelectorData["7"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("7", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
        {activeTab === 2 && (
          <div className="tab_panel">
            {/* Mega */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["6"]
                  ? props.influencerSelectorData["6"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("6", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
        {activeTab === 3 && (
          <div className="tab_panel">
            {/* Macro Plus */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["5"]
                  ? props.influencerSelectorData["5"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("5", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
        {activeTab === 4 && (
          <div className="tab_panel">
            {/* Macro */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["4"]
                  ? props.influencerSelectorData["4"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("4", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
        {activeTab === 5 && (
          <div className="tab_panel">
            {/* Micro Plus */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["3"]
                  ? props.influencerSelectorData["3"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("3", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
        {activeTab === 6 && (
          <div className="tab_panel">
            {/* Micro */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["2"]
                  ? props.influencerSelectorData["2"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("2", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
        {activeTab === 7 && (
          <div className="tab_panel">
            {/* Nano */}
            <Deliverables
              deliverables={
                props.influencerSelectorData["1"]
                  ? props.influencerSelectorData["1"]
                  : {}
              }
              setdeliverables={(e) => handleDeliverables("1", e)}
              platformType={props.platformType + ""}
            />
          </div>
        )}
      </div>
    </div>
  );
};
