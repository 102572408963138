import { Component, ReactNode } from 'react';
import './undefined.scss';
import image404 from '../../../assets/images/oops-404.gif';

export class WrongRoute extends Component {

    constructor(props: any) {
        super(props);
    }



    render(): ReactNode {
        return (
            <div className="container-fluid __pagenotFound_container_fluid">
                <div className="container __pagenotFound_container">
                    <div className="row __pagenotFound_main_row">
                        <div className="col __pagenotFound_main_col">
                            <img className='image-404' src={image404} alt="" />
                            {/* <h1>404 Page Not Found</h1> */}
                            {/* <span className='_pagenotFound_undefined'>404</span>
                            <span className='__pagenotFound'>Not Found</span> */}

                        </div>
                    </div>
                </div>
            </div>
        )
    }


}