import React, { useState, useEffect } from 'react';
// import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { faqData } from '../../../data/data';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import HeadingStyle from '../../../components/heading-style/heading-style';

type Props = {};
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export const FAQ = (props: Props) => {
    const [faqList, setfaqList] = useState(faqData);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        setfaqList(faqList);
    }, [faqList]);

    return (
        <section className='sec-accordion'>
            {/* <div className="banner-innerpage">
                <div className='banner-content'>
                    <h1 className='banner-innerpage-heading title-w-bdr'><span>FAQs</span></h1>
                </div>
            </div> */}

            <div className='container mb-3'>
                <HeadingStyle title='FAQs' />
            </div>

            <div
                className='sec-accordion-content'
                style={{ marginBottom: '80px' }}
            >
                <div className='container'>
                    {faqList.map((faq) => {
                        return (
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label='basic tabs example'
                                        className='common-tab-sec'
                                    >
                                        <Tab
                                            label='Influencers'
                                            className='common-tab'
                                        />
                                        <Tab
                                            label='Brands'
                                            className='common-tab'
                                        />
                                        <Tab
                                            label='Campaigns'
                                            className='common-tab'
                                        />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    {faq?.influencerData?.map(
                                        (inflData: any) => {
                                            return (
                                                <Accordion className='accordion'>
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                        // id='panel1a-header'
                                                        key={inflData.id}
                                                        id={`insta-${inflData.id}`}
                                                        className='accordion-title'
                                                    >
                                                        {inflData.faqTitle}
                                                    </AccordionSummary>
                                                    <AccordionDetails className='accordion-content'>
                                                        {inflData?.content &&
                                                            inflData?.content}
                                                        {inflData.faqSubtitleDescriptionList && (
                                                            <ul>
                                                                {inflData.faqSubtitleDescriptionList &&
                                                                inflData.faqSubtitleDescriptionList
                                                                    ? inflData.faqSubtitleDescriptionList.map(
                                                                          (
                                                                              faqsdl: any
                                                                          ) => {
                                                                              return (
                                                                                  <li>
                                                                                      {
                                                                                          faqsdl.listDesc
                                                                                      }
                                                                                  </li>
                                                                              );
                                                                          }
                                                                      )
                                                                    : ''}
                                                            </ul>
                                                        )}
                                                        {inflData?.faqInnerDescription &&
                                                        inflData?.faqInnerDescription ? (
                                                            <>
                                                                <p>
                                                                    {inflData?.faqDescription &&
                                                                        inflData?.faqDescription}
                                                                </p>
                                                                <ul>
                                                                    {inflData?.faqInnerDescription &&
                                                                        inflData?.faqInnerDescription.faqInnerDescriptionList.map(
                                                                            (
                                                                                faqInnerDesc: any
                                                                            ) => {
                                                                                return (
                                                                                    <li>
                                                                                        {
                                                                                            faqInnerDesc.listDesc
                                                                                        }
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )}
                                                                </ul>
                                                            </>
                                                        ) : null}
                                                        {!inflData?.faqInnerDescription &&
                                                            inflData?.faqDescription && (
                                                                <p>
                                                                    {inflData?.faqDescription &&
                                                                        inflData?.faqDescription}
                                                                </p>
                                                            )}
                                                        <>
                                                            <p>
                                                                {
                                                                    inflData?.faqSubtitleDescription
                                                                }
                                                            </p>
                                                            {inflData?.faqSubDescription &&
                                                            inflData?.faqSubDescription
                                                                ? inflData?.faqSubDescription.map(
                                                                      (
                                                                          faqse: any
                                                                      ) => {
                                                                          return (
                                                                              <>
                                                                                  <strong>
                                                                                      {
                                                                                          faqse.faqSubtitle
                                                                                      }
                                                                                  </strong>
                                                                                  <p>
                                                                                      {
                                                                                          faqse.faqSubtitleDescription
                                                                                      }
                                                                                  </p>
                                                                              </>
                                                                          );
                                                                      }
                                                                  )
                                                                : ''}
                                                            <p>
                                                                {
                                                                    inflData
                                                                        ?.faqInnerDescription
                                                                        ?.desc
                                                                }
                                                            </p>
                                                        </>
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        }
                                    )}
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    {faq?.brandsData?.map((branData: any) => {
                                        return (
                                            <Accordion className='accordion'>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    // id='panel1a-header'
                                                    key={branData.id}
                                                    id={`insta-${branData.id}`}
                                                    className='accordion-title'
                                                >
                                                    {branData.faqTitle}
                                                </AccordionSummary>
                                                <AccordionDetails className='accordion-content'>
                                                    {branData.content}
                                                    {/* {branData.faqDescriptionWithInlineImageDesc ? (
                                                        <p>
                                                            {
                                                                branData.faqDescriptionWithInlineImageDesc
                                                            }
                                                            <img
                                                                src={
                                                                    branData.faqDescriptionWithInlineImageImg
                                                                }
                                                                alt=''
                                                                width='25'
                                                                style={{
                                                                    marginLeft:
                                                                        '5px',
                                                                    marginRight:
                                                                        '5px',
                                                                }}
                                                            />
                                                            {
                                                                branData.faqDescriptionWithInlineImageDesc1
                                                            }
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )} */}
                                                    {branData.faqSubtitleDescriptionList && (
                                                        <ul>
                                                            {branData.faqSubtitleDescriptionList &&
                                                            branData.faqSubtitleDescriptionList
                                                                ? branData.faqSubtitleDescriptionList.map(
                                                                      (
                                                                          faqsdl: any
                                                                      ) => {
                                                                          return (
                                                                              <li>
                                                                                  {
                                                                                      faqsdl.listDesc
                                                                                  }
                                                                              </li>
                                                                          );
                                                                      }
                                                                  )
                                                                : ''}
                                                        </ul>
                                                    )}
                                                    {/* {branData.faqsd && branData.faqsd.faqSubtitleDescriptionList
                                                            <ul>
                                                                {branData.faqsd.faqSubtitleDescriptionList && branData.faqsd.faqSubtitleDescriptionList ? branData.faqsd.faqSubtitleDescriptionList.map(faqsdl => {
                                                                    return <li>{faqsdl.listDesc}</li>
                                                                }) : ""}
                                                            </ul>
                                                        } */}
                                                    {branData?.faqInnerDescription &&
                                                    branData?.faqInnerDescription ? (
                                                        <>
                                                            <p>
                                                                {branData?.faqDescription &&
                                                                    branData?.faqDescription}
                                                            </p>
                                                            <ul>
                                                                {branData?.faqInnerDescription &&
                                                                    branData?.faqInnerDescription.faqInnerDescriptionList.map(
                                                                        (
                                                                            faqInnerDesc: any
                                                                        ) => {
                                                                            return (
                                                                                <li>
                                                                                    {
                                                                                        faqInnerDesc.listDesc
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                            </ul>
                                                        </>
                                                    ) : null}
                                                    {!branData?.faqInnerDescription &&
                                                        branData?.faqDescription && (
                                                            <>
                                                                <p>
                                                                    {branData?.faqDescription &&
                                                                        branData?.faqDescription}
                                                                </p>
                                                            </>
                                                        )}
                                                    <>
                                                        <p>
                                                            {
                                                                branData?.faqSubtitleDescription
                                                            }
                                                        </p>
                                                        {branData?.faqSubDescription &&
                                                        branData?.faqSubDescription
                                                            ? branData?.faqSubDescription.map(
                                                                  (
                                                                      faqse: any
                                                                  ) => {
                                                                      return (
                                                                          <>
                                                                              <strong>
                                                                                  {
                                                                                      faqse.faqSubtitle
                                                                                  }
                                                                              </strong>
                                                                              <p>
                                                                                  {
                                                                                      faqse.faqSubtitleDescription
                                                                                  }
                                                                              </p>
                                                                          </>
                                                                      );
                                                                  }
                                                              )
                                                            : ''}
                                                        <p>
                                                            {
                                                                branData
                                                                    ?.faqInnerDescription
                                                                    ?.desc
                                                            }
                                                        </p>
                                                    </>
                                                    {/* {branData?.img && (
                                                        <img
                                                            src={branData.img}
                                                            style={{
                                                                width: '240px',
                                                                marginBottom:
                                                                    '15px',
                                                            }}
                                                        />
                                                    )} */}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    Item Three
                                </CustomTabPanel>
                            </Box>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
