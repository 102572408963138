import React, { useEffect, useState } from 'react'
import { ClanShopService } from '../../core/services/clan.shop.service';
import { ProductCard } from '../brand/clan-shop/product-card';
import HeadingStyle from '../../components/heading-style/heading-style';
import { useNavigate } from 'react-router';

const AllClanShops = () => {
    const [shopDetails, setShopDetails] = useState<any>([]);
    const shop_id = null;
    const navigate = useNavigate();
    useEffect(() => {

    }, [])

    useEffect(() => {
        ClanShopService.getShopDetails(shop_id)
            .then((res: any) => {
                if (res?.data?.rows) {
                    setShopDetails(res?.data?.rows);
                }
            }).catch((err: any) => { setShopDetails([]) });
    }, []);

    const handleProductClick = (product: any) => {
        // Handle the product click event
        navigate(
            `/clanshop/${product?.shop_id}`
        );
    };

    return (
        <div className='clanshop-user-interface'>
            <div className='container'>
                <HeadingStyle
                    title='All Shops'
                    className='mb-3 d-block'
                />
                <ProductCard
                    data={shopDetails}
                    type='all-shops'
                    onProductClick={handleProductClick}
                />
            </div>
        </div>
    )
}

export default AllClanShops