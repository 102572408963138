import React, { useState } from 'react';
import './main-settings.scss';
import { Outlet, useNavigate, NavLink } from 'react-router-dom';
import HeadingStyle from '../../../components/heading-style/heading-style';
import { Tab, Tabs } from '@mui/material';

export const BrandSetting: React.FC = () => {

    return (
        <>
            <div className='profile_settings_container'>
                <div className='container'>
                    <HeadingStyle
                        title='Profile Settings'
                        className='pb-4 d-block'
                    />
                    <div className='row profile_settings_main_container'>
                        <div className='col-md-3 profile_settings_left_side d-none d-md-flex list-section-sidebar'>
                            <ul className=''>
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'personal-info'}
                                    >
                                        Personal Info
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'companydetails'}
                                    >
                                        Company Details
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'socialconnect'}
                                    >
                                        Social Connect
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'shippingaddress'}
                                    >
                                        Address
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'security'}
                                    >
                                        Security
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'security'}
                                    >
                                        Security
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'subscription'}
                                    >
                                        Subscription
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'subscription-history'}
                                    >
                                        Subscription history
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'paymentmethods'}
                                    >
                                        Banking Details
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'notifications'}
                                    >
                                        Notification
                                    </NavLink>
                                </li>

                                {/* <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'companytradedetails'}
                                    >
                                        Company / Trade Details
                                    </NavLink>
                                </li> */}
                                {/* <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'invoicesetting'}
                                    >
                                        Invoice Settings
                                    </NavLink>
                                </li> */}
                                {/* <li>
                                    <NavLink
                                        className={'profile_settings_navlinks list-section-sidebar-link'}
                                        to={'gstdetails'}
                                    >
                                        GST Details
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                        <Tabs
                            aria-label='nav tabs example'
                            variant='scrollable'
                            scrollButtons='auto'
                            allowScrollButtonsMobile
                            className='d-flex d-md-none nav sub-tabs nav-rounded'
                        >
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'personal-info'}
                            >
                                Personal Info
                            </NavLink>
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'companydetails'}
                            >
                                Company Details
                            </NavLink>
                            {/* <Tab
                                className='tab-underline-menu'
                                label='Social Connect'
                                value='socialconnect'
                            /> */}
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'shippingaddress'}
                            >
                                Addresses
                            </NavLink>
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'security'}
                            >
                                Security
                            </NavLink>
                            {/* <Tab
                                className='tab-underline-menu'
                                label='Security'
                                value='security'
                            /> */}
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'subscription'}
                            >
                                Subscriptions
                            </NavLink>
                            {/* <NavLink
                                className='tab-underline-menu nav-link'
                                to={'paymentmethods'}
                            >
                                Banking Details
                            </NavLink> */}
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'subscription-history'}
                            >
                                Subscription history
                            </NavLink>
                            <NavLink
                                className='tab-underline-menu nav-link'
                                to={'notifications'}
                            >
                                Notification
                            </NavLink>
                            {/* <Tab
                                className='tab-underline-menu'
                                label='Company / Trade Details'
                                value='companytradedetails'
                            /> */}
                            {/* <Tab
                                className='tab-underline-menu'
                                label='Invoice Settings'
                                value='invoicesetting'
                            /> */}

                            {/* <Tab
                                className='tab-underline-menu'
                                label='GST Details'
                                value='gstdetails'
                            /> */}
                        </Tabs>

                        <div className='col-md-9 profile_settings_right_side '>

                            <Outlet />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
