import React, { useState } from 'react';
import CommonPopUp from '../../../components/popup/common-popup';
import TextField from '@mui/material/TextField';
import { ApplyCampaignService } from '../../../core/services/applycampaignflow.service';

const applyCampaignService = new ApplyCampaignService();

interface ROIprops {
  open: boolean;
  er?: any; // Assuming er is a number representing engagement rate
  plays?: any;
  likes?: any;
  views?: any;
  comments?: any;
  user_id?: any;
  account_id?: any;
  brand_id?: any;
  influencer_id?: any;
  campaign_id?: any;
  platform?:string;

  onClose: () => void;
}

const ROI: React.FC<ROIprops> = ({ open, onClose, er, likes, plays,views,comments,user_id,account_id,brand_id,influencer_id,platform,campaign_id }) => {
  const [budget, setBudget] = useState<number>(0); // Set initial state to '' or number
  const [dataNotPresentError, setDataNotPresentError] = useState({
    message: "",
    status: false
  })
  const [showResult, setShowResult] = useState(false);

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // Regex pattern to allow only numbers and optionally one decimal point
    if(input.length >  0){
      const pattern = /^[0-9]*\.?[0-9]*$/;
      // Check if the input matches the pattern
      if (pattern.test(input)) {
        setBudget(parseFloat(input)); // Update state with parsed number
      } else {
        setBudget(0); // Clear state if input is invalid
      }
    }
    else{
      setBudget(0); // Clear state if input is invalid
    }
  };

  const handleSubmit = async () => {
    if(comments || er || likes || plays || views){
      const res = await applyCampaignService.saveROI({
        user_id: user_id,
        account_id: account_id,
        brand_id: brand_id,
        influencer_id: influencer_id,
        campaign_id: campaign_id,
        budget: budget,
        cost_per_er: er != 0 && (budget/ er).toFixed(2) || 0,
        cost_per_like: likes != 0 && (budget/ likes).toFixed(2) || 0,
        cost_per_play: plays != 0 && (budget/ plays).toFixed(2) || 0,
        cost_per_view: views != 0 && (budget/ views).toFixed(2) || 0,
        cost_per_comment: comments != 0 && (budget/ comments).toFixed(2) || 0,
      })
      setDataNotPresentError({
        status:false,
        message:""

      })

      setShowResult(true);
    }
    else{
      setDataNotPresentError({
        status:true,
        message:"The data is unavailable to caluclate ROI"

      })
    }


      
  };

  return (
    <>
      <CommonPopUp
        modalTitle={"ROI Generator"}
        openModal={open}
        onClose={onClose}
        className="modal-medium"
      >
        <div className='row pb-3'>
          <div className="col">
            <TextField
              label="Enter Budget"
              value={budget}
              onChange={handleNumberChange}
              className="w-100 mt-0 input-small input-style"
              fullWidth
              size='small'
              margin="normal"
              variant="outlined"
              inputProps={{
                pattern: /^[0-9]*\.?[0-9]*$/,
              }}
            />
          </div>
          <div className="col-auto">
            <button
              onClick={handleSubmit}
              className="btn btn-outline-primary btn-mui">
              Calculate
            </button>
          </div>
          {dataNotPresentError && <span className='error'>{dataNotPresentError?.message}</span>}

        </div>
        
        <div className={showResult ? "roi-generated-value" : "d-none"}>
          {platform == "Instagram" && <p><span>Cost Per Engagement:</span> <strong>{(budget / (likes+comments+plays)).toFixed(2)}</strong></p>}
          {platform == "YouTube" && <p><span>Cost Per Engagement:</span> <strong>{(budget / (likes+comments+views)).toFixed(2)}</strong></p>}
          {likes !=0 && <p><span>Cost Per Like:</span> <strong>{(budget / likes).toFixed(2)}</strong></p>}
          {plays !=0  && <p><span>Cost Per Views:</span> <strong>{(budget / plays).toFixed(2)}</strong></p>}
          {views !=0  && <p><span>Cost Per View:</span> <strong>{(budget / views).toFixed(2)}</strong></p>}
          {comments !=0 && <p><span>Cost Per Comment:</span> <strong>{(budget / comments).toFixed(2)}</strong></p>}
        </div>
      </CommonPopUp>
    </>
  );
}

export default ROI;
