import * as React from 'react';
import CommonPopUp from '../popup/common-popup';
import { Link } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import HeadingStyle from '../heading-style/heading-style';

interface Props {
  children?: any;
  openUpgradeInfluencerModal: boolean;
  popupMinWidth?: any;
  popupMaxWidth?: any;
  onClose: () => void; // Receive onClose callback as a prop
}

const UpgradeInfluencerPopUp: React.FC<Props> = ({
  children,
  openUpgradeInfluencerModal,
  onClose,
  popupMinWidth,
  popupMaxWidth,
}) => {
  const handleUpgradeClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    onClose(); // Close the modal
    window.location.href = '/influencer/settings/subscription'; // Redirect to the upgrade page
  };
  return (
    // <Modal
    //   open={openUpgradeInfluencerModal}
    //   onClose={onClose}
    //   aria-labelledby='modal-modal-title'
    //   aria-describedby='modal-modal-description'
    //   className='p-2'
    // >
    //   <div className='popup_container'>
    //     <div className='popup_container-inner'>
    //       <div className='popup_container_content'>
    //         <Box
    //           className='popup_content_container'
    //           sx={{
    //             minWidth: popupMinWidth,
    //             maxWidth: popupMaxWidth,
    //           }}
    //         >
    //           <HeadingStyle title='Upgrade to Premium:' />
    //           <IconButton
    //             edge='end'
    //             color='inherit'
    //             onClick={onClose} // Use the onClose callback to handle closing
    //             aria-label='close'
    //             sx={{ position: 'absolute', top: '8px', right: '15px' }}
    //           >
    //             <CloseIcon />
    //           </IconButton>
    //           <div className="">
    //             <ul>
    //               <li>Get discovered by Brands.</li>
    //               <li>Priority over unsubscribed influencers.</li>
    //               <li>Receive direct messages from Brands.</li>
    //               <li>Unlimited brand campaigns to apply on.</li>
    //               <li>Concise Analytics for your influencer profile.</li>
    //               <li>Work on Paid and Barter campaigns.</li>
    //               <li>Send Agreements and Invoices effortlessly.</li>
    //             </ul>
    //             <div className="helper-text d-flex mt-3">
    //               <a className="d-inline ml-1" href="/influencer/settings/subscription" onClick={handleUpgradeClick}>
    //                 Upgrade
    //               </a>
    //             </div>
    //           </div>
    //         </Box>
    //       </div>
    //     </div>
    //   </div>
    // </Modal>
    <CommonPopUp
      modalTitle="Upgrade to Premium:"
      modalContent=""
      openModal={openUpgradeInfluencerModal}
      onClose={onClose}
      className="modal-medium mx-auto"
    >
      <div className="upgrade-premium-list">
        <ul>
          <li>Get discovered by Brands.</li>
          <li>Priority over unsubscribed influencers.</li>
          <li>Receive direct messages from Brands.</li>
          <li>Unlimited brand campaigns to apply on.</li>
          <li>Concise Analytics for your influencer profile.</li>
          <li>Work on Paid and Barter campaigns.</li>
          <li>Send Agreements and Invoices effortlessly.</li>
        </ul>
        <div className="col text-end plan_camp_btn_group popup-footer">
              <Link to="/influencer/settings/subscription" className='btn btn-primary' onClick={handleUpgradeClick}>
                Upgrade
              </Link>
        </div>
      </div>
    </CommonPopUp>
  );
};

export default UpgradeInfluencerPopUp;