import React, { useState, useEffect } from 'react';
import {
  TableComponent,
  SortingColumnsType,
} from "../../../../src/components/table-component/table"
import DeleteIcon from '@mui/icons-material/Delete';
import { SignUpService, LoginService, PublicService, LocalService } from '../../../core/services';
import { RemoveDeleteService } from '../../../core/services/delete-remove.service'
import { FormValidation } from "../../../core/utility";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  //   CloseIcon,
  TextField,
  Button,
  List,
  ListItem,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  OutlinedInput
} from '@mui/material';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import CommonPopUp from '../../../components/popup/common-popup';
import { UserLogin } from "../../../redux/action";
import { MenuPropsSelect } from '../../../core/utility/style';
import MaterialSymbol from '../../../components/icons/material-symbol';
const loginService = new LoginService();
const localService = new LocalService();
const removeDeleteService = new RemoveDeleteService();
const publicService: PublicService = new PublicService();



// import CommonPopUp from './CommonPopUp'; // Assuming this file path
const signupService = new SignUpService();

const columnDisplayName = {
  email: "Email",
  mobile: "Phone no",
  accessRole: "Access",
};

interface ErrorTypes {
  status: boolean;
  message: string;
}

const sortingColumnType: SortingColumnsType[] = [
  {
    name: "email",
    columnSize: "30px",
  },
  {
    name: "mobile",
    columnSize: "20px",
  },
  {
    name: "accessRole",
    columnSize: "8px",
  },
  {
    name: "",
    columnSize: "5px",
  },
];



interface FormData {
  email: string;
  mobile: string;
  accessRole: string;
}

interface ManageUserModalProps {
  open: boolean;
  onClose: () => void;
  brandId: number,
  agencyAccountId: number
}



const ManageUserModal: React.FC<ManageUserModalProps> = ({ open, onClose, brandId, agencyAccountId }) => {
  const formValidation = new FormValidation();

  const [email, setEmail] = useState('');
  const [mobile, setmobile] = useState('');
  const [accessRole, setAccessRole] = useState('');
  const [manageUserData, setManageUserData] = useState([]);
  const [agencyRoles, setAgencyRoles] = useState([]);
  const [formDataList, setFormDataList] = useState<FormData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [removeAccessEmail, setRemoveAccessEmail] = useState('');;
  const [removeAccessAccount, setRemoveAccessAccount] = useState('');;
  const [errorWhatsApp, setErrorWhatsApp] = useState<ErrorTypes>();
  const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
  const [errorAccessRole, setErrorAccessRole] = useState<ErrorTypes>();

  useEffect(() => {
    manageUserDetails();
    getAgencyRoles();
  }, []);

  const manageUserDetails = async () => {
    const response = await loginService.getBrandManagers({
      brand_id: brandId,
      agency_account_id: agencyAccountId
    });
    setManageUserData(response?.data?.rows)
  }
  const getAgencyRoles = async () => {
    const response = await publicService.getAgencyRoles();
    setAgencyRoles(response?.data?.rows)
  }


  const handleEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setEmail(value)
    let isValid: { status: boolean; message: string } = formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    return isValid;
  };

  const handlemobileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.value.length < 10) {
        setmobile(event.target.value);
      }
      else if (event.target.value.length == 10) {
        setmobile(event.target.value);
        let isValid: { status: boolean; message: string } = formValidation.GetMobileControlIsValid(event.target.value)
        setErrorWhatsApp(isValid);
      }
      else {
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(event.target.value));
      }
    }
    catch (error) {
      throw new Error(`handleWhatsApp error :: ${error}`);
    }
  };


  const handleAccessRoleChange = (event: SelectChangeEvent<string>) => { // Adjust the event type to SelectChangeEvent<string>
    const value = event.target.value; // The value here will be a string
    setAccessRole(value);
  };

  const handleSubmit = async () => {
    setErrorEmail(formValidation.GetEmailControlIsValid(`${email}`));
    setErrorWhatsApp(formValidation.GetMobileControlIsValid(`${mobile}`));
    setErrorAccessRole(formValidation.GetRequiredEntry(`${accessRole}`));
    const isCorrectAccessRole = formValidation.GetRequiredEntry(`${accessRole}`).status;
    const isCorrectMobile = formValidation.GetMobileControlIsValid(`${mobile}`).status;
    const isCorrectEmail = formValidation.GetEmailControlIsValid(`${email}`).status;
    if (isCorrectEmail && isCorrectMobile && isCorrectAccessRole) {
      // To check If User already present in auth_users it should belongs to only Agency Account
      const response = await publicService.checkVerifiedUser({
        mobile: Number(mobile),
        email: email,
      });
      if (response.data.status.message.length == 0) {
        const formData: FormData = { email, mobile, accessRole };
        setFormDataList([...formDataList, formData]);
        setEmail('');
        setmobile('');
        setAccessRole('');
      }
      else {
        setErrorEmail(response.data.status)
      }
    }
  };

  const removeItem = (index: number) => {
    const updatedFormDataList = formDataList.filter((_, i) => i !== index);
    setFormDataList(updatedFormDataList);
  };

  const handleCloseModal: any = () => {
    setOpenModal(false);
  }
  const handleSubmitUserForm = async () => {

    const res = await signupService.manageUserAgency({
      data: {
        formDataList: formDataList,
        agency_account_id: agencyAccountId,
        brand_id: brandId
      },
    });
    if (res) {
      if (res.data.status.status == true) {
        localService.toastify(res?.data?.status?.message);
      }
      else {
        localService.toastify(res?.data?.status?.message);
      }
    }
    onClose()
  };

  const removeAccess = async () => {
    const response = await removeDeleteService.removeAccessManageUser({
      brand_id: brandId,
      account_id: removeAccessAccount
    });

    if (response) {
      if (response.data.status.status == true) {
        localService.toastify(response?.data?.status?.message);
      }
      else {
        localService.toastify(response?.data?.status?.message);
      }
    }
    setOpenModal(true);
    onClose()
  }
  return (
    <>
      {openModal && (
        <CommonPopUp openModal={openModal}
          modalTitle="Remove Access"
          onClose={() => handleCloseModal()}
          popupMinWidth={400}
          popupMaxWidth={400}
        >
          <div className="common-popup">
            <p className="para1">
              Are you sure you want to remove access of {removeAccessEmail}
            </p>
          </div>
          <div className='gap-2 d-flex popup-footer justify-content-end'>
            
              <Button
                type="button"
                variant="contained"
                onClick={(e) => { setOpenModal(false) }}
                className="btn btn-outline-primary"
              >
                No
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="btn btn-primary"
                onClick={removeAccess}
              >
                Yes
              </Button>
            
          </div>
        </CommonPopUp>
      )}
      <CommonPopUp
        modalTitle="Manage Users"
        openModal={open}
        onClose={onClose}
        popupMinWidth={800}
        popupMaxWidth={800}
      >
        <div className='row mt-3'>
          <div className="col">
            <TextField
              label="Email"
              value={email}
              className="w-100 mt-0"
              size="small"
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
            />
            <span className="error">{errorEmail?.message}</span>
          </div>
          <div className="col">
            <TextField
              label="Phone no"
              value={mobile}
              className="w-100 mt-0 "
              size="small"
              onChange={handlemobileChange}
              fullWidth
              margin="normal"
            />
            <span className="error">{errorWhatsApp?.message}</span>
          </div>
          <div className="col">
            <FormControl fullWidth size="small">
              <InputLabel id="access-role-label">Access Role</InputLabel>
              <Select
                labelId="access-role-label"
                value={accessRole}
                onChange={handleAccessRoleChange}
                input={<OutlinedInput label="Access Role" />}
                MenuProps={MenuPropsSelect}
              >
                {agencyRoles ? (
                  agencyRoles?.map((res: any) => (
                    <MenuItem
                      key={res.role_id}
                      value={res.role_id}
                    >
                      {`${res.role_name}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    key={1}
                    value={1}
                  >
                    {"No Data"}
                  </MenuItem>
                )}
              </Select>
              <span className="error">{errorAccessRole?.message}</span>
            </FormControl>
          </div>
          {/* Other form fields */}
          <div className="col-auto">
            <Button variant="contained" color="primary" onClick={handleSubmit} className="btn btn-primary">
              Add
            </Button>
          </div>
        </div>
        {
          (() => {
            if (formDataList.length > 0 || manageUserData.length > 0) {
              return (
                <>
                  <TableComponent 
                    columnDisplayName={columnDisplayName}
                    orderByColumnName=""
                    inputDataSource={formDataList}
                    sortingColumnType={sortingColumnType}
                    renderData={formDataList}
                    setRenderData={(e) => setFormDataList(e)}
                  >

                    {formDataList.map((data: any, index: number) => {
                      return (
                        <TableRow key={index} hover role="checkbox">
                          <TableCell>{data?.email}</TableCell>
                          <TableCell>{data?.mobile}</TableCell>
                          <TableCell>                    {
                            agencyRoles.filter((item: any) => item.role_id === data?.accessRole).map((filteredItem: any) => filteredItem.role_name)
                          }
                          </TableCell>
                          <TableCell><IconButton onClick={(e) => removeItem(index)}><MaterialSymbol iconName='delete' fontweight='200' iconFilled /></IconButton></TableCell>
                        </TableRow>
                      );
                    })}
                    {manageUserData.map((data: any, index: number) => {
                      return (
                        <TableRow key={index} hover role="checkbox">
                          <TableCell>{data?.email_primary}</TableCell>
                          <TableCell>{data?.mobile_whatsapp}</TableCell>
                          <TableCell>{data?.role_name}</TableCell>
                          <TableCell>
                            <IconButton className='text-black' onClick={(e) => {
                              setOpenModal(true);
                              setRemoveAccessEmail(data?.email_primary);
                              setRemoveAccessAccount(data?.account_id)
                            }
                            }>
                              
                              <MaterialSymbol iconName='delete' fontweight='200' />
                            </IconButton>
                          
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableComponent>
                  <div className='popup-footer justify-content-end d-flex'>
                    <Button className='btn btn-primary' variant="contained" color="primary" onClick={handleSubmitUserForm} sx={{ marginLeft: '5px' }}>Submit</Button>
                  </div>
                </>
              )
            }

          })()

        }
      </CommonPopUp>
    </>
  );
};

export default ManageUserModal;
