import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { ListService, LocalService } from '../../../../../core/services';
import { createCampaignInitialState } from '../../../../../redux/reducer/reducer';
import { useNavigate } from "react-router-dom";
import { CreateCampaign } from '../../../../../redux/action';
import CommonPopUp from '../../../../../components/popup/common-popup';
import { MenuPropsSelect } from '../../../../../core/utility/style';

const localService: LocalService = new LocalService();
const listService: ListService = new ListService()

interface Props {
  UserReducer: any;
  UserID: any;
  CreateCampaignReducer?: (data: any) => void;
  loading: (status: boolean) => void;
  onChange: (event: boolean) => void;
}
const AnalyticsCampaignModal: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(true);
  const [planList, setplanList] = useState([]);
  const [radiovalue, setRadioValue] = useState(planList.length > 0 ? 2 : 1);
  const [selectPlan, setSelectPlan] = useState("");
  const [showPlanNeed, setShowPlanNeed] = useState(false)


  const handleCloseModal = () => {
    setShowPopup(false)
  };


  useEffect(() => {
    props.onChange(showPopup);
  }, [showPopup])

  const handleAddCampaign = (plan_id: any) => {
    listService.add_Campaign({
      brand_id: props.UserReducer.brand_id,
      user_id: props.UserReducer.user_id,
      plan_id: plan_id,
    }).then((res: any) => {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({
          ...createCampaignInitialState,
          createCampaign: {
            campaign_id: res?.data?.status.campaign_id,
          },
          createPlan: res?.data?.rows[0],
          navigation: {
            ...createCampaignInitialState.navigation,
            campaign_create: true,
          },
          completeStep: {
            ...createCampaignInitialState.completeStep,
            createPlan: true,
          },
        });

        navigate("/brand/internal-campaign");
      }
    });
  };
  const handlePlanNeed = () => {
    setShowPlanNeed(prev => !prev)
  }
  const handleContinue = () => {
    if (radiovalue === 2) {
      if ((selectPlan + "").length > 0) {
        handleAddCampaign(selectPlan);
      } else {
        localService.toastify("Please select plan", "info", 1000);
      }
    } else {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({
          ...createCampaignInitialState,
          createCampaign: {
            campaign_type: "Analytics",
          },
        });
        navigate("/brand/campaign/createplan");
      }
    }
  };

  useEffect(() => {
    props.loading(true);
    try {
      listService.planList({
        user_id: props.UserReducer.user_id,
        brand_id: props.UserReducer.brand_id,
      })
        .then((res: any) => {
          props.loading(false);
          try {
            if (res.data.fetchedRows > 0) {
              setplanList(res.data.rows);
              setRadioValue(2);
              //   setCreateSelectedPlan(res.data.rows[0].plan_id);
            } else {
            }
          } catch (err) {
            throw new Error(`Data Align Error :: ${err}`);
          }
        })
        .catch((e) => {
          props.loading(false);
          throw new Error(`PlanService planList function error :: ${e}`);
        });
    } catch (error) {
      throw new Error(`Plan list error :: ${JSON.stringify(error)}`);
    } finally {
    }
  }, [props.UserReducer.brand_id, props.UserReducer.user_id]);

  return (
    <div>
      <CommonPopUp
        modalTitle="Track campaign"
        modalContent=""
        openModal={showPopup}
        onClose={() => handleCloseModal()}
        className="popup-select-plan modal-medium">
        <div className="create_new_campaign_popup">
          <div className="row">
            <div className="col">
              <span className="select_plan_campaign fw-600">
                Select a plan for the Campaign
              </span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <FormControl size="small">
                <RadioGroup
                  onChange={(e: any) => setRadioValue(+e.target.value)}
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={radiovalue}
                  name="radio-buttons-group"
                >
                  <div className='d-flex flex-wrap'>
                    <FormControlLabel
                      value={1}
                      checked={radiovalue === 1}

                      control={<Radio />}
                      label="New Plan"
                    />
                    <span className='btn btn-text-link align-self-center fs-12' onClick={handlePlanNeed} style={{ cursor: 'pointer' }}>Why is a plan needed?</span>
                    {showPlanNeed && (
                      <p className='fs-14'>Brands can create plans for their diverse products/services, each aiming to achieve different objectives such as driving awareness, performance, or engagement.</p>
                    )}
                  </div>

                  <FormControlLabel
                    value={2}
                    disabled={planList.length < 1}
                    control={<Radio />}
                    defaultChecked={planList.length > 0 && radiovalue === 2}
                    checked={planList.length > 0 && radiovalue === 2}
                    label="Existing Plan"
                  />

                </RadioGroup>
              </FormControl>
            </div>
          </div>
          {(planList.length > 0 && (radiovalue === 2)) &&
            <div className="row mb-3">
              <div className="col">
                <FormControl
                  fullWidth
                  size="small"
                  className="pln_popup_select input-common input-style-select"
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Campaign
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectPlan}
                    label="Select Campaign"
                    onChange={(e) => setSelectPlan(e.target.value)}
                    inputProps={{
                      MenuProps: {
                        disableScrollLock: true,
                      },
                    }}
                    MenuProps={MenuPropsSelect}
                  >
                    {planList.length > 0 ? (
                      planList.map((res: any, index: number) => {
                        return (
                          <MenuItem key={index} value={res.plan_id}>
                            {res.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem key={1} value={1}>
                        {"No Data"}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          }
          <div className="col text-end plan_camp_btn_group popup-footer">
            <Button
              className="btn btn-outline-primary btn-mui"
              onClick={() => setShowPopup(!showPopup)}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary btn-mui ms-2"
              onClick={handleContinue}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </CommonPopUp>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    campaignReducer: state.CreateCampaignReducer,
  };
};

const mapDispatchToState = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
  };
};
const connectToRedux = connect(mapStateToProps, mapDispatchToState)(AnalyticsCampaignModal);
export { connectToRedux as AnalyticsCampaignModal };

