import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import './main-influencer.scss';

export const MainInfluencer: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
