import React from "react";
import "./card.scss";

type Props = {
    children: any;
    cardTitle?: string;
    className?: string;
};

export const Card: React.FC<Props> = ({ children, cardTitle, className }) => {
    return (
        <div className={`card-wrapper ${className ? className : ""}`}>
            <strong className={`${cardTitle ? "card-title" : ""}`}>
                {cardTitle}
            </strong>
            <div className="card-content">{children}</div>
        </div>
    );
};
