import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import {
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  TableContainer,
  TableSortLabel,
  Paper,
  Grid,
  Tooltip
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LoadingButton } from "@mui/lab";
import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { AdminDashboardService } from "../../../../../../core/services/admin-dashboard.service";
import { NavLink } from "react-router-dom";
import "../signup.scss";
import { Loading } from '../../../../../../redux/action';
import { connect } from 'react-redux';
import { RolesService } from "../../../../../../core/services/roles.service";
import { LocalService } from "../../../../../../core/services";
import { useNavigate } from 'react-router-dom';
import { MenuPropsSelect } from "../../../../../../core/utility/style";
import HeadingStyle from "../../../../../../components/heading-style/heading-style";
import CommonPopUp from "../../../../../../components/popup/common-popup"


const adminDashboardService = new AdminDashboardService();
const rolesService = new RolesService();
const localService: LocalService = new LocalService();

interface ColumnDisplayNames {
  [key: string]: string;
}

interface Data {
  id: number;
  total_influencers: number;
  // verified: number;
  // unverified: number;
  total_brief_posted: number;
  total_proposal_received: number;
  total_subscriptions: number;
  total_Quarterly_subscriptions: number;
  total_monthly_subscriptions: number;
  // total_Biannually_subscriptions: number;
  total_annually_subscriptions: number;
  unsubscribed: number;
  free_trial: number;
}


const headCells = [
  {
    id: "total_brands",
    numeric: false,
    disablePadding: false,
    label: "Total brands",
  },
  // {
  //   id: "verified",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Verified",
  // },
  // {
  //   id: "unverified",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Unverified",
  // },
  {
    id: "total_brief_posted",
    numeric: true,
    disablePadding: false,
    label: "Total brief posted",
  },
  {
    id: "total_proposal_received",
    numeric: true,
    disablePadding: false,
    label: "Total proposal received",
  },
  {
    id: "total_subscriptions",
    numeric: true,
    disablePadding: false,
    label: "Active Subscriptions",
  },
  {
    id: "total_monthly_subscriptions",
    numeric: true,
    disablePadding: false,
    label: "Monthly",
  },
  {
    id: "total_Quarterly_subscriptions",
    numeric: true,
    disablePadding: false,
    label: "Quarterly",
  },
  // {
  //   id: "total_Biannually_subscriptions",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Half-Yearly",
  // },
  {
    id: "total_annually_subscriptions",
    numeric: true,
    disablePadding: false,
    label: "Yearly",
  },
  // {
  //   id: "unsubscribed",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "UnSubscribed",
  // },
  {
    id: "free_trial",
    numeric: true,
    disablePadding: false,
    label: "Free Trial",
  },
];


const columnDisplayNames: ColumnDisplayNames = {
  account_name: "Brand Name",
  // handle_name_ig: "IG Handle",
  // handle_name_yt: "YT Channel",
  agency_name: "Agency Name",
  email_primary: "Email",
  mobile_whatsapp: "Phone",
  created_at: "Signed up on",
  plan_start: "Plan Start Date",
  plan_end: "Plan End Date",
  Invoice: "Invoice",
};

const tableHeadColumnPart = [
  {
    name: "account_name",
    columnSize: "140px",
  },
  // {
  //   name: "handle_name_ig",
  //   columnSize: "165px",
  // },
  // {
  //   name: "handle_name_yt",
  //   columnSize: "165px",
  // },
  {
    name: "agency_name",
    columnSize: "140px",
  },
  {
    name: "email_primary",
    columnSize: "80px",
  },
  {
    name: "mobile_whatsapp",
    columnSize: "100px",
  },
  {
    name: "created_at",
    columnSize: "120px",
  },
  {
    name: "plan_start",
    columnSize: "120px",
  },
  {
    name: "plan_end",
    columnSize: "120px",
  },
  {
    name: "Invoice",
    columnSize: "120px",
  },
];

const status = [{
  value: 'All',
  label: 'All'
},
{
  value: 'Paid All',
  label: 'All Plans'
},
{
  value: 'Paid Monthly',
  label: 'Monthly Plan'
},
{
  value: 'Paid Quarterly',
  label: 'Quarterly Plan'
},
// {
//   value: 'Paid Half-Yearly',
//   label: 'Paid Half-Yearly'
// },
{
  value: 'Paid Yearly',
  label: 'Yearly Plan'
},
{
  value: 'Expired',
  label: 'Expired Plan'
},
{
  value: 'Free',
  label: 'Free'
},
];

interface Props {
  loading: (data: any) => any;
  userData: any;
}


const Brands: React.FC<Props> = (props: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = React.useState("");
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [dashboardBasicData, setDashboardBasicData] = useState<any>([]);
  const [dashboardBriefData, setDashboardBriefData] = useState<any>([]);
  const [brandFilterData, setBrandFilterData] = useState<any>([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const [showPop, setShowPop] = useState(false); 
  const [pageType, setPageType] = useState({
    value1: 'Brand',
    value2: 'Agency',
  })
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    name: '',
    status: 'All',
    social_status: [],
    boolean_social_status: {
      ig_connected_active: false,
      ig_connected_non_active: false,
      yt_connected_active: false,
      yt_connected_non_active: false,
      non_connected: false,
    },
    start_date: '',
    end_date: '',
    value1: 'Brand',
    value2: 'Agency',
  });
  const dateFormat = "YYYY-MM-DD";

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const sortedData = React.useMemo(() => {
    if (Array.isArray(brandFilterData)) {
      const sortedArray = [...brandFilterData].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
        return 0;
      });
      return sortedArray;
    }
  }, [brandFilterData, orderBy, order]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openInstagram = (instagram: any) => {
    const instagramUrl = `https://www.instagram.com/${instagram}`;
    window.open(instagramUrl, '_blank');
  };

  ;

  useEffect(() => {
    // CHECK IF USER ALLOWED  ACCESS 
    checkAccessFunction();
  }, []);

  const checkAccessFunction = async () => {
    try {
      const res = await rolesService.checkAccess({ access_roles: ["view_admin_dashboard"], user_role_id: props?.userData?.user_role_id })
      if (res.data.rows.length > 0) {
        getAdminDashboardSign();
      }
      else {
        localService.toastify("You are not allowed to access", "error", 1000);
        navigate(`/brand/dashboard`)
      }
    }
    catch (error) {
      console.error('Error fetching getAdminDashData:', error);

    }
  }

  useEffect(() => {
    getAdminDashboardBrandFilter();
  }, [page]);

  async function getAdminDashboardSign() {
    try {
      setStatsLoading(true);
      const res = await adminDashboardService.getAdminDashData(pageType);
      if (res?.data?.status) {
        setDashboardBasicData(res.data.rows.basic_data[0]);
        setDashboardBriefData(res.data.rows.brief_related_data[0])
      }
    } catch (error) {
      console.error('Error fetching getAdminDashData:', error);
    } finally {
      setStatsLoading(false);
    }
  }

  async function getAdminDashboardBrandFilter() {
    try {
      setFilterLoading(true);
      const res = await adminDashboardService.getAdminDashFilter({
        value1: formData.value1,
        value2: formData.value2,
        start_date: formData.start_date,
        end_date: formData.end_date,
        filter: formData.status,
        name: formData.name,
        social_status: formData.social_status,
        boolean_social_status: formData.boolean_social_status,
        page: page + 1
      });
      if (res?.data?.status) {

        if (page === 0) {
          setBrandFilterData(res.data.rows);
        } else {
          setBrandFilterData((prevData: any) => [...prevData, ...res.data.rows]);
        }
      }
    } catch (error) {
      console.error('Error fetching getAdminDashboardSignFilter:', error);
    } finally {
      setFilterLoading(false);
    }
  }

  async function handleSearch(object: any) {
    try {
      if (formData.start_date === "" && formData.end_date != "") {
        setDateError("Please enter start date");
        return;
      } else {
        if (formData.end_date === "" && formData.start_date != "") {
          setDateError("Please enter end date");
          return;
        } else {
          if (formData.start_date > formData.end_date) {
            setDateError("Start date should be less than end date");
            return;
          } else {
            setDateError("");
            if (page === 0) {
              getAdminDashboardBrandFilter();
            } else {
              setPage(0);
            }
          }
        }
      }
    }
    catch (error) {
      console.error('Error fetching handleSearch:', error);
    } finally {
    }
  }

  const handleProposalClick = () => {
   setShowPop(true);
  };

  const handleCloseModal = () => {
    setShowPop(false);
  };

  return (
    <div className="adminDashboardSignupMostOutterDiv ">
      <div className="adminDashboardSignupContentOutterDiv">
        <div className="adminDashboardSignupContentDiv ">
          <HeadingStyle
            title='Admin Dashboard'
            className=' px-3 d-block'
          />
          <div className='col-auto nav sub-tabs mb-3'>
            <ul>
              <li className=''>
                <NavLink
                  className='nav-link campaign-verification-tab'
                  to={'/brand/admin-dashboard/influencer-signups'}
                >
                  Influencers
                </NavLink>
              </li>
              <li className=''>
                <NavLink
                  className='nav-link talent-verification-tab'
                  to={'/brand/admin-dashboard/brand-signups'}
                >
                  Brands
                </NavLink>
              </li>
            </ul>
          </div>

          <TableContainer className="common-table mb-3">
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <TableHead className="common-table-header">
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      padding={
                        headCell.disablePadding ? "none" : "normal"
                      }
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="common-table-body">
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell className='table_cell' style={{ minWidth: "75px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBasicData?.total_user_count
                    }
                  </TableCell>
                  <TableCell style={{ minWidth: "75px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBriefData?.total_brief_posted
                    }
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "75px", cursor: "pointer" }}
                    onClick={() => handleProposalClick()}
                  >
                    {statsLoading ? <Skeleton animation="wave" /> : dashboardBriefData?.total_proposals}
                  </TableCell>

                  <TableCell style={{ minWidth: "75px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBasicData?.total_sub_count
                    }
                  </TableCell>

                  <TableCell style={{ minWidth: "75px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBasicData?.monthly_sub_count
                    }
                  </TableCell>

                  <TableCell style={{ minWidth: "75px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBasicData?.quarterly_sub_count
                    }
                  </TableCell>

                  <TableCell style={{ minWidth: "50px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBasicData?.annually_sub_count
                    }
                  </TableCell>

                  <TableCell style={{ minWidth: "75px" }}>
                    {statsLoading ? <Skeleton animation="wave" /> :
                      dashboardBasicData?.unsubscribed_count
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {dateError ? <span className="error">{dateError}</span> : ''}
          <div className="formDiv ">
            <form>
              <div className="formInput">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    rowSpacing={1}
                  >
                    <Grid item xs={12} sm={6} lg={2}>
                      <div className="formInputDiv ">
                        <label htmlFor="">
                          Name/Email/Phone...
                        </label>

                        <TextField
                          style={{ fontSize: "14px" }}
                          id="outlined-size-small"
                          size="small"
                          value={formData.name}
                          onChange={(e: any) =>
                            setFormData({
                              ...formData,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                      <div className="formInputDiv">
                        <label htmlFor="">Status</label>
                        <FormControl
                          sx={{ minWidth: 120 }}
                          size="small"
                        >
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={formData.status}
                            onChange={(e: any) =>
                              setFormData({
                                ...formData,
                                status: e.target.value,
                              })
                            }
                            style={{ fontSize: "14px" }}
                            MenuProps={MenuPropsSelect}
                          >
                            {status.map((status: any) => (
                              <MenuItem value={status.value}>
                                {status.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5} lg={2}>
                      {" "}
                      <div className="formInputDiv">
                        <label htmlFor="">From</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["StartDate"]}>
                            <MobileDatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              maxDate={dayjs(new Date())}
                              defaultValue={dayjs(formData.start_date)}
                              onChange={(e: any) =>
                                setFormData({
                                  ...formData,
                                  start_date: e?.format(dateFormat),
                                })
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5} lg={2}>
                      <div className="formInputDiv">
                        <label htmlFor="">To</label>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["EndDate"]}>
                            <MobileDatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              maxDate={dayjs(new Date())}
                              defaultValue={dayjs(formData.end_date)}
                              onChange={(e: any) =>
                                setFormData({
                                  ...formData,
                                  end_date: e?.format(dateFormat),
                                })
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} lg={1}>
                      {" "}
                      <div className="" style={{ marginTop: "20px" }}>
                        <LoadingButton className="btn btn-primary btn-mui" onClick={() => handleSearch(formData)}>Search</LoadingButton>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </form>
          </div>

          <span className="total" style={{ marginBottom: "10px" }}>
            <span className="totalSpan">Total:</span>
            <span
              className="totalSpanValue"
              style={{ marginLeft: "6px" }}
            >
              {sortedData && sortedData[0]?.total_records || 0}
            </span>
          </span>


          <div className="adminDashboardSignupContentDivTable ">

            <TableContainer className="common-table ">
              <Table
                sx={{ minWidth: 650 }}
                aria-label="custom pagination table"
              >
                <TableHead className="common-table-header">
                  <TableRow
                  >
                    {tableHeadColumnPart.map((column) => (
                      <TableCell
                        className="table_cell"
                        style={{
                          minWidth: column.columnSize,
                          padding: "12px",
                        }}
                        align="left"
                        key={column.name}
                      >
                        <TableSortLabel
                          active={orderBy === column.name}
                          direction={order}
                          onClick={() => handleSort(column.name)}
                        >
                          {columnDisplayNames[column.name] ||
                            column.name}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className='common-table-body'>
                  {sortedData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row: any) => (
                      <TableRow>
                        <TableCell className="table_cell" align="left">
                          {filterLoading ? (
                            <Skeleton animation="wave" />
                          ) : row.name ? (
                            <Tooltip title={row.name} arrow>
                              <span>{row.name.length > 15 ? `${row.name.substring(0, 10)}...` : row.name}</span>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        {/* <TableCell className="tableCell" align="left">
                          {filterLoading ? <Skeleton animation="wave" /> :
                            <span className="instagramLink" onClick={() => openInstagram(row.username)}>{row.username}</span>
                          }
                        </TableCell>
                        <TableCell className="tableCell" align="left">
                          {filterLoading ? <Skeleton animation="wave" /> :
                            row.title
                          }
                        </TableCell> */}
                        <TableCell className="tableCell" align="left">
                          {filterLoading ? (
                            <Skeleton animation="wave" />
                          ) : row.account_name ? (
                            <Tooltip title={row.account_name} arrow>
                              <span>{row.account_name.length > 15 ? `${row.account_name.substring(0, 10)}...` : row.account_name}</span>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell className="tableCell" align="left">
                          {filterLoading ? (
                            <Skeleton animation="wave" />
                          ) : row.primary_email ? (
                            <Tooltip title={row.primary_email} arrow>
                              <span>{row.primary_email.length > 10 ? `${row.primary_email.substring(0, 10)}...` : row.primary_email}</span>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell className="tableCell" align="left">
                          {filterLoading ? <Skeleton animation="wave" /> :
                            row.primary_phone
                          }
                        </TableCell>
                        <TableCell className="tableCell" align="left" style={{ padding: '10px' }}>
                          {filterLoading ? <Skeleton animation="wave" /> :
                            row.created_at ? dayjs(row.created_at).format('D MMM YYYY') : ''
                          }
                        </TableCell>
                        <TableCell className="tableCell" align="left" style={{ padding: '10px' }}>
                          {filterLoading ? <Skeleton animation="wave" /> :
                            row.plan_start ? dayjs(row.plan_start).format('D MMM YYYY') : ''
                          }
                        </TableCell>
                        <TableCell className="tableCell" align="left" style={{ padding: '10px' }}>
                          {filterLoading ? <Skeleton animation="wave" /> :
                            row.plan_end ? dayjs(row.plan_end).format('D MMM YYYY') : ''
                          }
                        </TableCell>
                        <TableCell className="tableCell" align="left">
                          {filterLoading ? <Skeleton animation="wave" /> :
                            <Button
                              className="btn btn-primary"
                              variant="outlined"
                            >
                              Download
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              className="common-pagination"
              component="div"
              rowsPerPageOptions={[10]}
              count={sortedData && sortedData[0]?.total_records || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#00aae5",
                marginTop: "10px",
              }}
            />
          </div>
        </div>
        <CommonPopUp
        className={`modal-small`}
        modalTitle="Proposal"
        openModal={showPop}
        onClose={() => handleCloseModal()}
        >
          <ul>
            <li>Accepted Proposal : {dashboardBriefData.total_accepted_proposal}</li>
            <li>Rejected Proposal : {dashboardBriefData.total_rejected_proposal}</li>
            <li>Shortlisted Proposal : {dashboardBriefData.total_shortlist_proposal}</li>
          </ul>
        </CommonPopUp>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Brands);
export { connectToRedux as Brands };
