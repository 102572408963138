import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";

interface Proposal {
  name: string;
  total_price: number;
  payment_status: string;
  account_type?: string;
  account_holder_name?: string;
  account_number?: string;
  ifsc_code?: string;
  address?: string;
  influencer_id: number;
  proposal_id: number;
  file_urls: string;
}

const RenderDownloadInvoiceButtons: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  useEffect(() => {
    if (proposal.file_urls !== null) {
      setFileUrls(proposal.file_urls.split(','));
    }
  }, [proposal.file_urls]);
  return (
    <>
      {
        fileUrls && fileUrls.map((url, index) => (
          <Tooltip title={url.split('campaign_invoices/')[url.split('campaign_invoices/').length-1]} arrow>
            <NavLink target="_blank" key={`campaign_invoice_${index}`} to={url} data-tip={url.split('campaign_invoices/')[url.split('campaign_invoices/').length-1]}>
              <i className="bi bi-file-earmark-text-fill"></i>
            </NavLink>
          </Tooltip>
        ))
      }
    </>
  )
};

export default RenderDownloadInvoiceButtons;