interface Props {
  className?: string;
}
const IconAccepted: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.08075 14.2538L14.8038 7.53075L13.75 6.47693L8.08075 12.1462L5.23075 9.29615L4.17693 10.35L8.08075 14.2538ZM9.50165 19.5C8.18772 19.5 6.95268 19.2506 5.79655 18.752C4.6404 18.2533 3.63472 17.5765 2.7795 16.7217C1.92427 15.8669 1.24721 14.8616 0.748325 13.706C0.249442 12.5504 0 11.3156 0 10.0017C0 8.68772 0.249334 7.45268 0.748 6.29655C1.24667 5.1404 1.92342 4.13472 2.77825 3.2795C3.6331 2.42427 4.63834 1.74721 5.79398 1.24833C6.94959 0.749443 8.18437 0.5 9.4983 0.5C10.8122 0.5 12.0473 0.749334 13.2034 1.248C14.3596 1.74667 15.3652 2.42342 16.2205 3.27825C17.0757 4.1331 17.7527 5.13834 18.2516 6.29398C18.7505 7.44959 19 8.68437 19 9.9983C19 11.3122 18.7506 12.5473 18.252 13.7034C17.7533 14.8596 17.0765 15.8652 16.2217 16.7205C15.3669 17.5757 14.3616 18.2527 13.206 18.7516C12.0504 19.2505 10.8156 19.5 9.50165 19.5ZM9.49998 18C11.7333 18 13.625 17.225 15.175 15.675C16.725 14.125 17.5 12.2333 17.5 9.99998C17.5 7.76664 16.725 5.87498 15.175 4.32498C13.625 2.77498 11.7333 1.99998 9.49998 1.99998C7.26664 1.99998 5.37498 2.77498 3.82498 4.32498C2.27498 5.87498 1.49998 7.76664 1.49998 9.99998C1.49998 12.2333 2.27498 14.125 3.82498 15.675C5.37498 17.225 7.26664 18 9.49998 18Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconAccepted;






