import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';
import { SM_API_BASE_URL } from '../../config/config';
import { LocalService, LoginService } from '../../core/services'

const http = new HttpService();

const localService = new LocalService();
const loginService = new LoginService();

export class UserService {
  // To fetch personal info from auth_influencers table
  async getInfluencerDetails(params: any) {
    try {
      const path = BASE_URL + `/user/influencer-details?influencerId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getInfluencerDetails :: ${error}`);
    }
  }

  // To fetch user-info from auth_users table
  async getUserDetails(params: any) {
    try {
      const path = BASE_URL + `/user/user-details?userId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getUserDetails :: ${error}`);
    }
  }

  // To update user-info details
  async updateUserInfo(data: any): Promise<any> {
    try {
      const path = BASE_URL + '/user/user-details/update';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateUserInfo :: ${error}`);
    }
  }

  // To fetch influencer and brand account details
  async getAccountDetails(params: any) {
    try {
      const path = BASE_URL + `/user/account-details?accountId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getAccountDetails :: ${error}`);
    }
  }

  // To update influencer and brand account name
  async updateAccountDetails(data: any): Promise<any> {
    try {
      const path = BASE_URL + '/user/account-details/update';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateAccountDetails :: ${error}`);
    }
  }

  async addShippingaddresss(data: any) {
    const path = BASE_URL + `/setting/add/address`;
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async updateShippingaddresss(data: any) {
    const path = BASE_URL + `/setting/update/address`;
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async deleteShippingAddress(data: any) {
    const path = BASE_URL + `/setting/delete/address`;
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async updateDefaultAddress(data: any): Promise<ResponseType> {
    const path = BASE_URL + '/update/default/shipping/address';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Update Default Address :: ${error}`)
    }
  };


  static async getInstaProfileDetails(params: any, options: string) {
    const path =
      SM_API_BASE_URL +
      `/instagram/public_profile?instagram_handle_name=${params}`;
    const response = await http.getRequest(path, options);
    return response;
  }

  static async getYtProfileDetails(params: any, options: string) {
    const path =
      SM_API_BASE_URL + `/youtube/public_profile?channel_id=${params}`;
    const response = await http.getRequest(path, options);
    return response;
  }

  async updateInfluencerDetails(data: any): Promise<any> {
    try {
      const path = BASE_URL + '/user/influencer-details/update';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateProfileDetails :: ${error}`);
    }
  }

  async updatePassword(data: any) {
    try {
      const path = BASE_URL + '/user/update-password';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updatePassword :: ${error}`);
    }
  }

  async updatePin(data: any) {
    try {
      const path = BASE_URL + '/user/update-pin';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updatePin :: ${error}`);
    }
  }

  async forgetPasswordPin(data: any) {
    try {
      const path = BASE_URL + '/forget-password-pin';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`forgetPasswordPin :: ${error}`);
    }
  }

  //User Allowed Notifications 
  async getAllowedNotifications(params: any) {
    try {
      const path = BASE_URL + `/setting/notifications?account_id=${params?.account_id}&user_id=${params?.user_id}&influencer_id=${params?.influencer_id}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getNotificationDetails :: ${error}`);
    }
  }

  // Update User Notification
  async updateNotificationSetting(data: any) {
    try {
      const path = BASE_URL + `/setting/notifications/update`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`UpdateNotificationDetails :: ${error}`);
    }
  }

  // To fetch UserReducer current account latest data (To refresh userReducer data)
  async getUserReducerAccountData(data: any) {
    try {
      const path = BASE_URL + `/user/account_data`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`UpdateNotificationDetails :: ${error}`);
    }
  }

  // Reset UserReducer (for social connect)
  async getUpdatedUserReducer(UserReducer: any) {
    if (UserReducer.account_type === 'Influencer'){
      const response = await loginService.getInfluencerAccounts({
        user_id: UserReducer.user_id,
        account_id: UserReducer.account_id,
      });
      let gcp_profile_image_url = '';
      if (response.data.rows.length > 0){
        // To get gcp_profile_image_url for header
        const gcp_profile_image_urls = response.data.rows.map((row: any) => row.gcp_profile_image_url).filter((url: string) => url !== '') ?? ['']

        if (gcp_profile_image_urls.length > 0){
          gcp_profile_image_url = gcp_profile_image_urls[gcp_profile_image_urls.length - 1]
        }
      }
      const currentAccountData = await this.getUserReducerAccountData(UserReducer);
      let userData = null;
      if (response.data.status > 0 && !['', null, undefined].includes(currentAccountData.data.rows[0])) {
        userData = {
          ...currentAccountData.data.rows[0],
          influencer_ids: response.data.rows,
          influencer_id: response.data.rows[0]?.influencer_id,
          gcp_profile_image_url: gcp_profile_image_url
        };
        // props.UserReducer(userData);
        // navigate(`/influencer/dashboard`);
        //without remember me save user Data
        localService.setStorageItem(
          'userreducer',
          JSON.stringify(userData)
        );
      } else {
        // localService.toastify(response.data.status.message);
        userData = null
      }
      return userData;
    } else if (UserReducer.account_type === 'Brand') {
      const response = await loginService.getBrandIds({
        user_id: UserReducer.user_id,
        account_id: UserReducer.account_id,
      });
      const currentAccountData = await this.getUserReducerAccountData(UserReducer);
      let userData = null;
      if (response.data.status.status && !['', null, undefined].includes(currentAccountData.data.rows[0])) {
        userData = {
          ...currentAccountData.data.rows[0],
          ...response.data.rows[0],
          influencer_id: response.data.rows[0].influencer_id
        };
        // props.UserReducer(userData);
        // navigate('/brand/dashboard');
        localService.setStorageItem(
          'userreducer',
          JSON.stringify(userData)
        );
      } else {
        userData = null
      }
      return userData;
    }
  }

  // Get All Brands of Agency and Influencerof Talent and their subscriptions
  async getBrandsOfAgencyAndInflOfTP(account_id: any, account_type:any) {
    try {
      const path = BASE_URL + `/agency-tp/all-users?accountId=${account_id}&accountType=${account_type}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getBrandsOfAgency :: ${error}`);
    }
  }

  // Get All Invoices : Brands of Agency and Influencers of Talent
  async getInvoiceBrandsOfAgencyInflOfTP(params: any) {
    try {
      const path = BASE_URL + `/agency-tp/all-users/invoices?accountId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getInvoiceBrandsOfAgency :: ${error}`);
    }
  }
}
