import { useEffect } from 'react';

const useBodyClass = (className: string): void => {
  useEffect(() => {
    const { body } = document;
    if (body) {
      body.classList.add(className);
    }

    return () => {
      if (body) {
        body.classList.remove(className);
      }
    };
  }, [className]);
};

export default useBodyClass;