import { Edit, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, IconButton, Link, Typography } from '@mui/material'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import MaterialSymbol from '../../../components/icons/material-symbol';
import NewAutomationPost from './new-automation-post';
import NewAutomationStory from './new-automation-story';
import phoneMockup from '../../../assets/images/phone-mockup.png'
import productImage from '../../../assets/images/product-img1.png'

import userAvatar from '../../../assets/images/avtar_male.png'
import Slider from 'react-slick'

type Props = {}

const NewAutomation = (props: Props) => {
    const [automationSelect, setAutomationSelect] = useState({
        post: 'post',
    });
    const  [automationTitle, setAutomationTitle] = useState('')
    const [editTitle, setEditTitle] = useState(false)
    

    const handleEditTitle = (e:any) =>{
        setEditTitle(true)
    }
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutomationTitle(e.target.value)
    }
    
    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const handleInputSubmit = () => {
        if (automationTitle.trim() === '') {
            setAutomationTitle('untitled automation'); 
        }
        setEditTitle(false);
    };

    const inputRef:any = useRef(null);
 
    const handleClickOutside = (e: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
            if (automationTitle.trim() === '') {
                setEditTitle(false); // Close input if empty
            } else {
                handleInputSubmit(); // Submit input if not empty
            }
        }
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
            handleInputSubmit(); // Submit when "Enter" is pressed
        }
    };

    useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [automationTitle]);
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            My Automations
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary', display:'flex', fontWeight:'bold' }}>
            <span>{!editTitle ? automationTitle ? automationTitle: "untitled automation" : 
            <input ref={inputRef} onKeyDown={handleKeyDown} type='text' onChange={handleTitleChange} name="automation_title" value={automationTitle} placeholder={automationTitle} className={`${editTitle ? "automation-edit-title-input" : ""}`} />} </span><IconButton onClick={handleEditTitle} size='small' className='p-0 automation-edit-title bg-none'><MaterialSymbol  iconName='edit' fontweight='200' /></IconButton>
        </Typography>,
    ];

    var productImageSlider = {
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        accessibility: false,
        infinite: false,
        centerMode: true,        
        centerPadding: '20%',
    };
    
  return (
    <section className='new-automation'>
        <div className='container '>
              <Breadcrumbs className='mb-5 fs-18 breadcrumb-automation' separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className='automation-select'>
                  <span className='pb-2 d-block'>Start automation on</span>
                  <div className='input-checkbox-box-container'>
                      <span
                          onClick={() =>
                              setAutomationSelect({
                                  
                                  post: 'post',
                              })
                          }
                          className={`input-checkbox-box d-inline-flex
                                    ${(
                                  automationSelect.post + ''
                          ).toLocaleLowerCase() === 'post'
                                  ? ' active'
                                  : ''}`}

                      >
                          
                          <span className='input-checkbox-box-label d-flex flex-column'><strong>Post or Reel Comments</strong>
                              <span>When a user comments on your Post or Reel</span>
                              </span>
                      </span>
                      <span
                          onClick={() =>
                              setAutomationSelect({
                                  post: 'story',
                              })
                          }
                          className={
                              `input-checkbox-box d-inline-flex nav-link
                                    ${(
                                  automationSelect.post + ''
                              ).toLocaleLowerCase() === 'story'
                                  ? 'active'
                                  : ''}`}
                      >
                          <span className='input-checkbox-box-label d-flex flex-column'>
                              <strong>Story Replies</strong>
                              <span>When a user replies to your Story</span>
                          </span>
                      </span>
                  </div>
            </div>
            <div className='automation-main-content'>
                <div className='automation-phone-mockup'>
                     <img src={phoneMockup} alt="" className='automation-phone-mockup-img' />
                    <div className='automation-phone-mockup-user-sec'>
                        <img src={userAvatar} alt="" className='automation-phone-mockup-user-img' />
                        <div className='automation-phone-mockup-user-info'>
                            <strong>Chandrayee Chattopadhyay</strong>
                            <span>Business chat</span>
                        </div>
                    </div>
                    <Slider className='automation-phone-mockup-product-slider' {...productImageSlider}>
                        
                            <div className='automation-phone-mockup-product-detail'>
                                <img src={productImage} alt="" className='automation-phone-mockup-product-img' />
                                <div className='automation-phone-mockup-product-desc'>
                                    <strong>JBL Tune 760C, Wireless Over Ear Active...</strong>
                                    <button className='btn btn-grey'>Shop on Amazon</button>
                                </div>
                            </div>
                            <div className='automation-phone-mockup-product-detail'>
                                <img src={productImage} alt="" className='automation-phone-mockup-product-img' />
                                <div className='automation-phone-mockup-product-desc'>
                                    <strong>JBL Tune 760C, Wireless Over Ear Active...</strong>
                                    <button className='btn btn-grey'>Shop on Amazon</button>
                                </div>
                            </div>
                            <div className='automation-phone-mockup-product-detail'>
                                <img src={productImage} alt="" className='automation-phone-mockup-product-img' />
                                <div className='automation-phone-mockup-product-desc'>
                                    <strong>JBL Tune 760C, Wireless Over Ear Active...</strong>
                                    <button className='btn btn-grey'>Shop on Amazon</button>
                                </div>
                            </div>
                        
                    </Slider>
                    
                    
                    
                    
                    

                </div>
                  {automationSelect.post.toLowerCase() === 'post' && (
                     <NewAutomationPost />
                  )}
                  {automationSelect.post.toLowerCase() === 'story' && (
                      <NewAutomationStory />
                  )}

            </div>
        </div>
    </section>
  )
}

export default NewAutomation