import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class BrandDashboardService {
  public async getbrandDashboard(param1:number, param2:string, param3:string, param4:string): Promise<any> {
    try {
      const path = BASE_URL + `/brand/dashboard?brand_id=${param1}&from_date=${param2}&to_date=${param3}&platform_type=${param4}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Brand Dashboard Data :: ${error}`);
    }
  }

  public async brandDashboardCamapignNames(param1: any, param2:string): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/dashboard/campaigns_names?brand_id=${param1}&platform_type=${param2}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Brand Dashboard Data :: ${error}`);
    }
  }

  public async brandDashboardCamapignInsight(param1: any, param2:string): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/dashboard/campaigns_insights?campaign_id=${param1}&platform_type=${param2}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Brand Dashboard Data :: ${error}`);
    }
  }

  public async brandDashboardTopInfluencerData(param1: any): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/dashboard/top-influencers?brand_id=${param1}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`brandDashboardTopInfluencerData :: ${error}`);
    }
  }
}
