import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class AffiliateService {
    static async getAffiliateData(params: any): Promise<any> {
        try {
            const path = BASE_URL + `/getAffiliateData?brand_id=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`Affiliate Data :: ${error}`);
        }
    }

    static async getAffiliateDashboardData(params: any): Promise<any> {
        try {
            const path = BASE_URL + `/getAffiliateDashboardData?brand_id=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`Affiliate Data :: ${error}`);
        }
    }

    static async affiliateLinkGenerate(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/affiliateLinkGenerate`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`affiliateLinkGenerate Data :: ${error}`);
        }
    }

    static async checkAffiliateLink(param1: any, param2:any, param3:any, param4:any): Promise<any> {
        try {
            const path = BASE_URL + `/checkAffiliateLink/${param1}/${param2}/${param3}/${param4}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`affiliateLinkGenerate Data :: ${error}`);
        }
    }

     async affiliateAddToCart(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/affiliate/addToCart`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`affiliateAddToCart Data :: ${error}`);
        }
    }

    async affiliateCheckout(data: any): Promise<any> {
        try {
            const path = BASE_URL + '/affiliate/checkout';
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`affiliateAddToCart Data :: ${error}`);
        }
    }
    
}