import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';

interface Props {
    dataSource: any[];
    title?: string;
}

export const StockChart: React.FC<Props> = (props: Props) => {
    const [chartId] = useState(Math.round(Math.random() * 1000));

    useEffect(() => {
        try {
            const options: Highcharts.Options = {
                chart: {
                    renderTo: `container-${chartId}`,
                },
                title: {
                    text: props.title,
                },
                credits: {
                    enabled: false,
                },
                // rangeSelector: {
                //     buttonTheme: {
                //         width: 50,
                //         height: 20,
                //         padding: 5,
                //     },
                //     enabled: true,
                //     inputPosition: {
                //         align: 'right',
                //     },
                //     buttonPosition: {
                //         align: 'center',
                //         x: 110,
                //         y: 0,
                //     },
                //     buttons: [
                //         {
                //             type: 'day',
                //             count: 1,
                //             text: 'daily',
                //         },
                //         {
                //             type: 'week',
                //             count: 1,
                //             text: 'weekly',
                //         },
                //         {
                //             type: 'month',
                //             count: 1,
                //             text: 'monthly',
                //         },
                //     ],
                //     selected: 1,
                // },
                rangeSelector: {
                    selected: 1
                },
                navigator: {
                    enabled: true,
                },
                legend: {
                    enabled: true
                },
                yAxis: {
                    opposite: false,
                    labels: {
                        enabled: false
                    }
                },
                series: props.dataSource.map((series) => ({
                    name: series?.name,
                    data: series?.data,
                    type: series?.type,
                })),
            };

            Highcharts.stockChart(options);
        } catch (error) {
            throw new Error(`StockChart component failed :: ${JSON.stringify(error)}`);
        }
    }, [chartId, props.dataSource, props.title]);

    return <div id={`container-${chartId}`} />;
};
