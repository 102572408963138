import { Edit, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, IconButton, Typography, Link, Switch, styled } from '@mui/material'
import React, { Fragment, useState } from 'react'
import MaterialSymbol from '../../../components/icons/material-symbol';
import NewAutomationPost from './new-automation-post';
import NewAutomationStory from './new-automation-story';
import { NavLink } from 'react-router-dom';
import productImage from '../../../assets/images/product-img.png';

type Props = {}

const ExistingAutomation = (props: Props) => {
    const [automationSelect, setAutomationSelect] = useState({
        post: 'post',
    });

    const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));
    
    
    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            My Automations
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary', display:'flex', fontWeight:'bold' }}>
            Untitled Automation <IconButton size='small' className='p-0 bg-none'><MaterialSymbol className='ms-1' iconName='edit' fontweight='200' /></IconButton>
        </Typography>,
    ];
    
  return (
    <section className='new-automation'>
        <div className='container'>
            <div className='new-automation-top-back'>
                <strong className='me-auto'>
                    <Link href="#">
                        <MaterialSymbol iconName='chevron_left' fontweight='200' />
                    </Link>
                    Instagram DM Automation
                </strong>
                <NavLink to="/influencer/tools/new-automation" className='btn btn-primary' >
                    New Automation
                </NavLink>
            </div>

            <strong className='automation-existing-page-title'>My Automations</strong>
            <div className='automation-existing'>
                <div className='automation-existing-header'>
                    <span className='automation-existing-header-name'>Name</span>
                    <span className='automation-existing-header-switch'></span>
                    <span className='automation-existing-header-sent'>Sent</span>
                    <span className='automation-existing-header-ctr'>CTR</span>
                    <span className='automation-existing-header-modified'>Last Modified</span>
                    <span className='automation-existing-header-action'></span>
                </div>
                <div className='automation-existing-list'>
                    <div className='automation-existing-list-name'>
                        <strong>Untitled Automation</strong>
                        <div className='automation-existing-list-detail'>
                        <span>User comments on <img src={productImage} alt="" /> with keywords</span>
                        <ul><li>Price</li><li>link</li><li>pp</li></ul>
                        </div>
                    </div>
                    <span className='automation-existing-list-switch'>
                     <AntSwitch  defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                     </span>
                     <span className='automation-existing-list-sent'>0</span>
                     <span className='automation-existing-list-ctr'>n/a</span>
                     <span className='automation-existing-list-modified'>2 days ago</span>
                     <span className='automation-existing-list-action'>
                     <IconButton className='text-red'>
                         <MaterialSymbol iconName='delete' fontweight='200' />
                     </IconButton>
                     </span>
                </div>
                <div className='automation-existing-list'>
                    <div className='automation-existing-list-name'>
                        <strong>Untitled Automation</strong>
                        <div className='automation-existing-list-detail'>
                        <span>User comments on <img src={productImage} alt="" /> with keywords</span>
                        <ul><li>Price</li><li>link</li><li>pp</li></ul>
                        </div>
                    </div>
                    <span className='automation-existing-list-switch'>
                     <AntSwitch  defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                     </span>
                     <span className='automation-existing-list-sent'>0</span>
                     <span className='automation-existing-list-ctr'>n/a</span>
                     <span className='automation-existing-list-modified'>2 days ago</span>
                     <span className='automation-existing-list-action'>
                     <IconButton className='text-red'>
                         <MaterialSymbol iconName='delete' fontweight='200' />
                     </IconButton>
                     </span>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ExistingAutomation