import React, { useEffect, Fragment, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { LocalService } from "../core/services";
import { ErrorBoundary } from './error-boundary';
import {
  Loading,
  SwitchLayer1,
  UserLogin,
  SwitchLayer2,
} from "../redux/action";
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  isLogin: any;
  Component: any;
  switchLayer1: (data: any) => void;
  SwitchLayer2: (data: any) => void;
  UserReducer: (data: any) => void;
  // IsUserLogged: (data: any) => void;
}

const localService = new LocalService();

const ProtectedRoute: React.FC<Props> = (props: Props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    const userReducer: any = localService.getStorageItem("userreducer");
    const getswitchAccount: any = localService.getStorageItem("switchaccount");
    const activeAccount: any = localService.getStorageItem("activeaccount");
    const getKey = searchParams[0].get('key');
    if (userReducer && getswitchAccount) {
      props.UserReducer(JSON.parse(userReducer));
      props.switchLayer1(JSON.parse(getswitchAccount));
      props.SwitchLayer2(JSON.parse(activeAccount));
      // props.IsUserLogged(true);
    } else if (!props.isLogin) {
      let navigateUrl = '/login'
      if (getKey) {
        if (window.location.href.includes('influencer/campaign')) {
          navigateUrl = window.location.href.replace(window.location.origin, '');
          setRenderComponent(true);
        }
      }
      navigate(navigateUrl);
    }
  }, []);


  useEffect(() => {
    if (props.isLogin) {
      setRenderComponent(props.isLogin)
    }
  }, [props.isLogin])


  // const handleRouteChange = () => {
  //   setOpen(true)
  // }

  return <Fragment>
    {
      renderComponent &&

      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    }
  </Fragment>
};

const mapStateToProps = (state: any) => {
  return {
    isLogin: state.UserReducer.IsUserLogged,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // IsUserLogged: (data: any) => dispatch(UserLogin(data)),
    loading: (data: any) => dispatch(Loading(data)),
    switchLayer1: (data: any) => dispatch(SwitchLayer1(data)),
    UserReducer: (data: any) => dispatch(UserLogin(data)),
    SwitchLayer2: (data: any) => dispatch(SwitchLayer2(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRoute);

export { connectToRedux as ProtectedRoute };
