import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './order-status.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ClanShopService } from '../../core/services/clan.shop.service';


const OrderStatus = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');
    const payment_id = searchParams.get('payment_id');
    const [orderDetails, setOrderDetails] = useState<any>([])

    useEffect(() => {
        ClanShopService.getOrderDetails(payment_id).then((res: any) => {
            if (res?.data?.rows) {
                setOrderDetails(res?.data?.rows)

                // Get the element by ID
                // var affiliateElement = document.getElementById("affiliate-btn");
                // // Check if the element exists
                // if (affiliateElement) {
                //     // Attach a click event listener
                //     affiliateElement.click();
                // } else {
                //     console.error("Element with ID 'affiliate-btn' not found.");
                // }
            }
        }).catch((err: any) => { setOrderDetails([]) });
    }, [])


    return (
        <div className='order-status-container'>
            {status ? (
                <>
                    {orderDetails?.map((data: any) => {
                        return (
                            <div className='order-details'>
                                <strong className='order-status-heading'><CheckCircleIcon /> Order Booked Successfully</strong>
                                <p><strong>Name : </strong>{data?.name}</p>
                                <p><strong>Email : </strong>{data?.email}</p>
                                <p><strong>Payment Id : </strong>{data?.payment_id}</p>
                                <p><strong>Order_id : </strong>{data?.order_id}</p>
                                <p><strong>Amount : </strong>{(data?.amount / 100)}</p>
                            </div>
                        )
                    })}

                </>
            ) : (
                <>
                    {orderDetails?.map((data: any) => {
                        return (
                            <div className='order-details'>
                                <strong className='order-status-heading'><CancelIcon style={{color:'red'}}/> Order Booking Failed</strong>
                                <p><strong>Name : </strong>{data?.name}</p>
                                <p><strong>Email : </strong>{data?.email}</p>
                                <p><strong>Order_id : </strong>{data?.order_id}</p>
                                <p><strong>Amount : </strong>{(data?.amount / 100)}</p>
                            </div>
                        )
                    })}
                </>
            )}

        </div>
    )
}

export default OrderStatus