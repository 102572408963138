import React, { useState } from "react";
import "./influencer-database-home.scss";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HeadingStyle from "../../../../components/heading-style/heading-style";
import InstagramIcon from "../../../../components/icons/instagram-icon";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { AddInfluencerIG } from "./add-Influencer-ig";
import { AddInfluencerYT } from "./add-Influencer-yt";
import { Tab, tabsClasses } from '@mui/material';
import { ExcelFileInput } from "../../../../components/excel-file-upload";



export const InfluencerDatabaseHome: React.FC = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setShowDetail(false);
    setValue(newValue);
    setExcelData([]);
  };
  const [showDetail, setShowDetail] = React.useState(false);
  const [showExcelUpload, setShowExcelUpload] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [excelData, setExcelData] = useState<any[]>([]);

  const handleExcelData = (data: any[]) => {
    setExcelData(data);
  }

  const clearExcelData = () => {
    setExcelData([]); // Clear the Excel data after successful processing
  };

  const handleUpload = () => {
    setShowExcelUpload(true)
    setShowPopup(true)
    setTimeout(() => setShowPopup(false), 0);
  }

  // const fileToDownload = value === "1" ? instagramSheet : youtubeSheet;


  return (
    <section className="inf-database-section influencer-database-home">
      <div className="influncer_heading">
        <HeadingStyle title="Influencer Database" className="mb-4 d-block" />
        <div className="buttons">
          <div className="sub-buttons">
            <button
              className="btn btn-outline-primary btn-sm btn-aid"
              onClick={() => setShowDetail(prevState => !prevState)}
            >
              Add Influencer Details
            </button>
            <button className="btn btn-primary btn-sm btn-uie" onClick={handleUpload}>
              {`Upload ${value === "1" ? 'Instagram' : 'Youtube'} Excel sheet`}
            </button>
          </div>

          <div className="excel">
            <a
              href={""}
              className="link"
              download
            >
              {`Download ${value === "1" ? 'Instagram' : 'Youtube'} Excel sheet`}
            </a>
          </div>
        </div>
      </div>


      <TabContext value={value}>
        <TabList variant="scrollable"
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          className="nav sub-tabs"
          aria-label="lab API tabs example"
          TabIndicatorProps={{ hidden: true }}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.MuiTabScrollButton-root': {
                borderRadius: '20px',
                height: '24px',
                border: '1px solid #000',
                width: '24px',
                transition: 'box-shadow 0.3s, transform cubic-bezier(0.2,0,0,1)',
                position: 'relative',
                zIndex: 1,
                '&:hover': {
                  boxShadow: '0 0 5px #ddd',
                  transform: 'scale(1.04)'
                },
              },
            },
          }}
        >
          <Tab
            label="Instagram"
            icon={<InstagramIcon width={20} />}
            iconPosition="start"
            value="1"
            className={value === "1" ? 'nav-link active' : "nav-link"}

          />
          <Tab
            label="YouTube"
            className={value === "2" ? 'nav-link active' : "nav-link"}
            icon={
              <YouTubeIcon style={{ color: "red", fontSize: "25px" }} />
            }
            iconPosition="start"
            value="2"
          />
        </TabList>
        <TabPanel value="1">
          {value === "1" && (<AddInfluencerIG showAddInfluencer={showDetail} excelData={excelData} clearExcelData={clearExcelData} />)}
        </TabPanel>
        <TabPanel value="2">
          {value === "2" && (<AddInfluencerYT showAddInfluencer={showDetail} excelData={excelData} clearExcelData={clearExcelData} />)}
        </TabPanel>
      </TabContext>
      {showExcelUpload && <ExcelFileInput onUpload={handleExcelData} openUpload={showPopup} />}
    </section >
  );
};
