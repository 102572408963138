import React, { useEffect } from "react";
import {
  TableComponent,
  SortingColumnsType,
} from "../../../../../../components/table-component/table";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { NavLink } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import "../platform.scss";
import { AdminDashboardService } from "../../../../../../core/services/admin-dashboard.service";
import { Loading } from "../../../../../../redux/action";
import { connect, useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import HeadingStyle from "../../../../../../components/heading-style/heading-style";

const columnDisplayName = {
  full_name: "Campaign Owner",
  login_email: "Owner Email",
  login_mobile: "Owner Phone",
  campaign_title: "Campaign Name",
  campaign_visibility: "Campaign Visibility",
  created_at: "Created at",
  posted_at: "Posted at",
  status: "Status",
  proposal_dt: "Deadline",
  go_live_dt: "Live Date",
  Content: "Content",
  Proposals: "Proposals",
};

const sortingColumnType: SortingColumnsType[] = [
  {
    name: "full_name",
    columnSize: "180px",
  },
  {
    name: "login_email",
    columnSize: "180px",
  },
  {
    name: "login_mobile",
    columnSize: "120px",
  },
  {
    name: "campaign_title",
    columnSize: "200px",
  },
  {
    name: "campaign_visibility",
    columnSize: "200px",
  },
  {
    name: "created_at",
    columnSize: "180px",
  },
  {
    name: "posted_at",
    columnSize: "180px",
  },
  {
    name: "status",
    columnSize: "80px",
  },
  {
    name: "proposal_dt",
    columnSize: "180px",
  },
  {
    name: "go_live_dt",
    columnSize: "180px",
  },
  {
    name: "Content",
    columnSize: "80px",
  },
  {
    name: "Proposals",
    columnSize: "80px",
  },
];

interface Props {
  loading: (data: any) => any;
  userData: any;
}

const adminDashboardService = new AdminDashboardService();

const PlatformCampaignDetails: React.FC<Props> = (props: Props) => {
  const [renderData, setRenderData] = useState<any>([]);
  const [dashboardCampaignData, setDashboardCampaignData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    start_date: "",
    end_date: "",
  });
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    getPlatformFilter(formData);
  }, []);

  async function handleSearch() {
    try {
      if (formData.start_date === "" && formData.end_date != "") {
        setDateError("Please enter start date");
        return;
      } else {
        if (formData.end_date === "" && formData.start_date != "") {
          setDateError("Please enter end date");
          return;
        } else {
          if (formData.start_date > formData.end_date) {
            setDateError("Start date should be less than end date");
            return;
          } else {
            setDateError("");
            setLoading(true);
            getPlatformFilter(formData);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching handleSearch:", error);
    } finally {
      setLoading(false);
    }
  }

  async function getPlatformFilter(formData: any) {
    try {
      props.loading(true);
      setLoading(true);
      const res = await adminDashboardService.getAdminDashCampaignsData(
        formData
      );
      if (res?.data?.status) {
        setDashboardCampaignData(res?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching getPlatformFilter:", error);
    } finally {
      props.loading(false);
      setLoading(false);
    }
  }

  return (
    <div className="adminDashboardPlatformMostOutterDiv campaign-details-main ">
      <HeadingStyle
        title='Admin Dashboard'
        className=' px-3 d-block'
      />
      <div className="col-auto nav sub-tabs mb-3">
        <ul>
          <li className="tab-underline-menu">
            <NavLink
              className="nav-link campaign-verification-tab"
              to={"/brand/admin-dashboard/campaign-details"}
            >
              Campaign Details
            </NavLink>
          </li>
          <li className="tab-underline-menu">
            <NavLink
              className="nav-link talent-verification-tab"
              to={"/brand/admin-dashboard/disconnect-feedbacks"}
            >
              Disconnect feedbacks
            </NavLink>
          </li>
        </ul>
      </div>
      {dateError && <span className="error">{dateError}</span>}
      <div className="date_range_search">
        <Grid container spacing={2}>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["StartDate"]}>
                <MobileDatePicker
                  className="w-100"
                  label="start date"
                  format="DD-MM-YYYY"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.start_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      start_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["EndDate"]}>
                <MobileDatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  label="end date"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.end_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      end_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={2} md={2} xs={12} xl={4} className="btn-div">
            <LoadingButton
              className="btn btn-primary btn-sm"
              loading={loading}
              onClick={() => handleSearch()}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>
      </div>

      <div className="adminDashboardPlatformContentDivTable ">
        <TableComponent
          pagination={true}
          columnDisplayName={columnDisplayName}
          inputDataSource={dashboardCampaignData}
          orderByColumnName=""
          sortingColumnType={sortingColumnType}
          renderData={renderData}
          setRenderData={(e) => setRenderData(e)}
        >
          {renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} hover role="checkbox">
                <TableCell>{data.full_name}</TableCell>
                <TableCell>{data.login_email}</TableCell>
                <TableCell>{data.login_mobile}</TableCell>
                <TableCell>{data.campaign_title}</TableCell>
                <TableCell>{data.campaign_visibility}</TableCell>
                <TableCell>{data.created_at ? dayjs(data.created_at).format('LL') : ''}</TableCell>
                <TableCell>{data.posted_at ? dayjs(data.posted_at).format('LL') : ''}</TableCell>
                <TableCell>{data.status}</TableCell>
                <TableCell>{data.proposal_dt ? dayjs(data.proposal_dt).format('LL') : ''}</TableCell>
                <TableCell>{data.go_live_dt ? dayjs(data.go_live_dt).format('LL') : ''}</TableCell>
                <TableCell>
                  {data.total_creatives}/{data.approved_creatives}
                </TableCell>
                <TableCell>
                  {data.total_proposals}/{data.accepted_proposals}
                </TableCell>
              </TableRow>
            );
          })}
        </TableComponent>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlatformCampaignDetails);
export { connectToRedux as PlatformCampaignDetails };
