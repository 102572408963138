// CustomDrawer.tsx

import { CloseRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
  children: any;
  drawerTitle: string;
  showFooter?: boolean;
  footerContent?: any;
  className?: any;

  // Replace with the actual type of filterCampaignList
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  open,
  children,
  drawerTitle,
  onClose,
  className
}) => {
  const handleDrawerClose = () => {
    document.getElementsByTagName("body")[0].classList.remove("custom-drawer-active");
    onClose();
  }
  return (
    <div className={`custom-drawer ${className} ${open ? 'active' : ''}`}>
      <div className='custom-drawer-header' >
        <strong className='custom-drawer-title'>
          {drawerTitle}
        </strong>
        <span className='custom-drawer-close' onClick={handleDrawerClose}><IconButton><span className=" material-symbols-rounded ">
          close
        </span></IconButton></span>
      </div>
      {children}
    </div >
  );
};

export default CustomDrawer;