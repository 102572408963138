import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class RolesService {
    async checkAccess(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/admin_dashboard/check_access`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Check Access :: ${error}`);
        }
    }
}