import React from "react";

interface YouTubeEmbedProps {
  videoId: string; // YouTube video ID (e.g., "dQw4w9WgXcQ")
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId }) => {
  const onlyID = videoId.split("v=")[1]?.split("&")[0];
  const embedUrl = `https://www.youtube.com/embed/${onlyID}`;

  return (
    <div className="youtube-embed">
      <iframe
        width="100%"
        height="400"
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Video"
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
