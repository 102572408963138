import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import './dashboard.scss';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TimelineChart from './chartComponent';
import {
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
} from '@mui/material';

interface Data1Table {
  Name: string;
  date: string;
  Creatives: number;
  Influencers: number;
  Reach: number;
  ER: number;
  Clicks: number;
  Conversions: number;
  Value: number;
  Amount_Spent: number;
}

interface Data2Table {
  Name: string;
  ig: string;
  Followers: number;
  Reach: number;
  ER: number;
  Category: string;
  Location: string;
  Campaigns: number;
  Total_Amount: number;
}

export const TalentPartnerDashboard: React.FC = () => {
  const [orderBy, setOrderBy] =
    React.useState<keyof Data1Table>('Influencers');
  const [orderBy2, setOrderBy2] =
    React.useState<keyof Data2Table>('Followers');
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [order2, setOrder2] = React.useState<'asc' | 'desc'>('asc');
  // const [value, setValue] = useState('1');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);

  const [table1data, setTable1Data] = React.useState<Data1Table[]>([
    {
      Name: 'Wellness',
      date: '2 Feb,2023-15 Mar,2023',
      Creatives: 3,
      Influencers: 6,
      Reach: 0.5,
      ER: 1.8,
      Clicks: 2034,
      Conversions: 1500,
      Value: 25000,
      Amount_Spent: 400000,
    },
    {
      Name: 'Wellness',
      date: '2 Feb,2023-15 Mar,2023',
      Creatives: 1,
      Influencers: 9,
      Reach: 0.5,
      ER: 1.8,
      Clicks: 2034,
      Conversions: 1500,
      Value: 25000,
      Amount_Spent: 400000,
    },
  ]);

  const [table2data, setTable2Data] = React.useState<Data2Table[]>([
    {
      Name: 'Virat.Kohli',
      ig: '@virat.kohli',
      Followers: 7.6,
      Reach: 6,
      ER: 5.8,
      Category: 'Sports',
      Location: 'Mumbai',
      Campaigns: 6,
      Total_Amount: 3.5,
    },
    {
      Name: 'Virat.Kohli',
      ig: '@virat.kohli',
      Followers: 3.6,
      Reach: 6,
      ER: 5.8,
      Category: 'Sports',
      Location: 'Mumbai',
      Campaigns: 10,
      Total_Amount: 3.5,
    },
  ]);

  const handleSort = (property: keyof Data1Table) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSort2 = (property: keyof Data2Table) => {
    const isAsc = orderBy2 === property && order2 === 'asc';
    setOrderBy2(property);
    setOrder2(isAsc ? 'desc' : 'asc');
  };

  const sortedData = React.useMemo(() => {
    const sortedArray = [...table1data].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedArray;
  }, [table1data, orderBy, order]);

  const sortedData2 = React.useMemo(() => {
    const sortedArray = [...table2data].sort((a, b) => {
      if (a[orderBy2] < b[orderBy2]) return order2 === 'asc' ? -1 : 1;
      if (a[orderBy2] > b[orderBy2]) return order2 === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedArray;
  }, [table2data, orderBy2, order2]);

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage2 = (event: unknown, newPage: number) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const arrayForBox = [
    {
      type: 'Influencer',
      numericValue: '74',
    },
    {
      type: 'Creatives',
      numericValue: '148',
    },
    {
      type: 'Reach',
      numericValue: '1.3M',
    },
    {
      type: 'Engagement Rate',
      numericValue: '5.9%',
    },
    {
      type: 'Amount spend',
      numericValue: '$1,58,456',
    },
    {
      type: 'Views',
      numericValue: '165K',
    },
    {
      type: 'Likes',
      numericValue: '148K',
    },
    {
      type: 'Comments',
      numericValue: '45K',
    }
  ]

  const table_1_header_data = [
    {
      type: 'Name',
      columnSize: '200px',
    },
    {
      type: 'Influencers',
      columnSize: '120px',
    },
    {
      type: 'Creatives',
      columnSize: '120px',
    },
    {
      type: 'Reach',
      columnSize: '120px',
    },
    {
      type: 'ER',
      columnSize: '70px',
    },
    {
      type: 'Clicks',
      columnSize: '70px',
    },
    {
      type: 'Conversions',
      columnSize: '70px',
    },
    {
      type: 'Value',
      columnSize: '120px',
    },
    {
      type: 'Amount_Spents',
      columnSize: '150px',
    }
  ]

  const table_2_header_data = [
    {
      type: 'Name',
      columnSize: '200px',
    },
    {
      type: 'Followers',
      columnSize: '120px',
    },
    {
      type: 'Reach',
      columnSize: '70px',
    },
    {
      type: 'ER',
      columnSize: '70px',
    },
    {
      type: 'Category',
      columnSize: '80px',
    },
    {
      type: 'Location',
      columnSize: '120px',
    },
    {
      type: 'Campaigns',
      columnSize: '70px',
    },
    {
      type: 'Total_Amount',
      columnSize: '150px',
    }
  ]

  return (
    <div className='mostOutterDiv'>
      <div className='contentContaainerDiv'>
        <div className='ToggleButtons'>
          <div className='paidAndViewDetailsDiv'>
            <span className='paidSpan'>
              <InstagramIcon />
              <p className='paidPara'>
                Paid: Premier leather Shoes
              </p>
            </span>
            <span className='viewDetailSpan'>
              <p className='viewDetailsPara'>
                View Details
              </p>
              <KeyboardArrowDownIcon />
            </span>
          </div>

          <div className='overviewAndDownloadDiv'>
            <p className='overviewPara'>Overview</p>
            <span className='downloadSpan'>
              <p className='downloadPara'>Download</p>
              <FileDownloadOutlinedIcon />
            </span>
          </div>

          <div className='overviewAndDownloadBoxesDiv'>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {arrayForBox.map((data) => (
                  <Grid xs={4} md={4} lg={3}>
                    <div className='overviewAndDownloadBoxDiv'>
                      <p className='categories'>
                        {data.type}
                      </p>
                      <p className='numericValue'>
                        {data.numericValue}
                      </p>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>

          <div className='timelineViewMostOutterDiv'>
            <p className='timelineViewHeading'>
              Timeline View
            </p>
            <div className='timelineViewChartOutterDiv'>
              <div className='timelineViewChartDiv'>
                <TimelineChart />
              </div>
            </div>
          </div>

          <div id='container'></div>

          <div className='allCampaignsMostOutterDiv'>
            <p className='allCampaignsHeading'>
              All Campaigns
            </p>
            <div className='allCampaignsTableDiv'>
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor:
                          '#eff0f2',
                      }}
                    >
                      {table_1_header_data.map(
                        (data) => (
                          <TableCell
                            style={{
                              minWidth:
                                data.columnSize,
                            }}
                          >
                            <TableSortLabel
                              active={
                                orderBy ===
                                data.type
                              }
                              direction={
                                order
                              }
                              onClick={() =>
                                handleSort(
                                  data.type as keyof Data1Table
                                )
                              }
                            >
                              {
                                data.type
                              }
                            </TableSortLabel>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((item) => (
                      <TableRow>
                        <TableCell>
                          <div>
                            <p
                              style={{
                                marginBottom:
                                  '0',
                              }}
                            >
                              {
                                item.Name
                              }
                            </p>
                            <p
                              style={{
                                marginBottom:
                                  '0',
                              }}
                            >
                              {
                                item.date
                              }
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          {
                            item.Influencers
                          }
                        </TableCell>
                        <TableCell>
                          {item.Creatives}
                        </TableCell>
                        <TableCell>
                          {item.Reach}M
                        </TableCell>
                        <TableCell>
                          {item.ER}%
                        </TableCell>
                        <TableCell>
                          {item.Clicks}
                        </TableCell>
                        <TableCell>
                          {
                            item.Conversions
                          }
                        </TableCell>
                        <TableCell>
                          ₹{item.Value}
                        </TableCell>
                        <TableCell>
                          ₹
                          {
                            item.Amount_Spent
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[5, 10, 25]}
                count={table1data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={
                  handleChangeRowsPerPage
                }
                sx={{
                  color: '#00aae5',
                  marginTop: '10px',
                }}
              />
            </div>
          </div>

          <div className='topPerforminInfluencerMostOutterDiv'>
            <p className='topPerforminInfluencerHeading'>
              Top Performing Influencers
            </p>
            <div className='topPerforminInfluencerTableDiv'>
              <TableContainer className='common-table'>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead className='common-table-header'>
                    <TableRow
                      style={{
                        backgroundColor:
                          '#eff0f2',
                      }}
                    >
                      {table_2_header_data.map(
                        (data) => (
                          <TableCell
                            style={{
                              minWidth:
                                data.columnSize,
                            }}
                          >
                            <TableSortLabel
                              active={
                                orderBy ===
                                data.type
                              }
                              direction={
                                order
                              }
                              onClick={() =>
                                handleSort2(
                                  data.type as keyof Data2Table
                                )
                              }
                            >
                              {
                                data.type
                              }
                            </TableSortLabel>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className='common-table-body'>
                    {sortedData2.map((item) => (
                      <TableRow>
                        <TableCell>
                          <div className='imageNameDiv'>
                            <div className='imageDiv'></div>
                            <div className='instaNameDiv'>
                              <span>
                                {
                                  item.Name
                                }
                              </span>
                              <span>
                                {
                                  item.ig
                                }
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {item.Followers}
                          M
                        </TableCell>
                        <TableCell>
                          {item.Reach}
                        </TableCell>
                        <TableCell>
                          {item.ER}%
                        </TableCell>
                        <TableCell>
                          {item.Category}
                        </TableCell>
                        <TableCell>
                          {item.Location}
                        </TableCell>
                        <TableCell>
                          {item.Campaigns}
                        </TableCell>
                        <TableCell>
                          ₹
                          {
                            item.Total_Amount
                          }
                          L
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[5, 10, 25]}
                count={table2data.length}
                page={page2}
                rowsPerPage={rowsPerPage2}
                onPageChange={handleChangePage2}
                onRowsPerPageChange={
                  handleChangeRowsPerPage2
                }
                sx={{
                  color: '#00aae5',
                  marginTop: '10px',
                }}
                className='common-pagination'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
