import React, { useState } from 'react'
import { ProposalNegotiationStep } from './stepper'
import { CustomProposalTable } from './custom-proposal-table'
import { NegotiateInput } from './negotiate-input'
import ProposalComment from './proposal-comment'
import { NegotiateInputPackage } from './negotiate-input-package'
import { CustomProposalTableMobile } from './custom-proposal-table-mobile'
import { NegotiateInputMobile } from './negotiate-input-mobile'
import { DeliverableItemKeys } from './enums'
import dayjs from 'dayjs'


type Props = {
  step?: any;
  deliverableHeader?: any;
  data?: any;
  profileData?: any;
  negotiateVisible?: any;
  dataSet?: any;
  onChange?: any;
  userType?: any;
  index?: number;
  setNegotiateVisible?: any;
  submitProposal?: any;
  updateProposal?: any
  campaignType?: any;
  throughWhatsapp?: any;
}

const ProposalContainer = ({

  step, deliverableHeader, data, profileData, negotiateVisible, dataSet, onChange, userType, index, setNegotiateVisible, submitProposal, updateProposal, campaignType, throughWhatsapp
}: Props) => {
  const costing_model = data[0]?.costing_model || "Individual"
  const handleCancel = () => {
    for (let i = 0; i < data.length; i++) {
      let currentStep = data[i].current_step;
      let previousStep = currentStep - 1;

      let currentStepStatusKey = `s_${currentStep}_status`;
      let previousStepStatusKey = `s_${previousStep}_status`;

      let currentStepPriceKey = `s_${currentStep}_price`;
      let previousStepPriceKey = `s_${previousStep}_price`;

      let currentStepQuantityKey = `s_${currentStep}_quantity`;
      let previousStepQuantityKey = `s_${previousStep}_quantity`;
      // Update the current step fields with the previous step fields
      if (data[i][currentStepStatusKey] !== "Pending") {
        data[i][currentStepStatusKey] = data[i][previousStepStatusKey];
        data[i][currentStepPriceKey] = data[i][previousStepPriceKey];
        data[i][currentStepQuantityKey] = data[i][previousStepQuantityKey];

      }
    }
  };

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [enumdeliverableItems] = useState<Record<DeliverableItemKeys, string>>({
    ig_reel: 'Reel',
    ig_story: 'Story',
    ig_carousel_image: 'Carousel Image Post',
    yt_shorts: 'Short',
    available_for_exclusivity: 'Exclusivity',
    ig_static: 'Static',
    digital_rights: 'Digital Rights',
    yt_dedicated_video: 'Dedicated Video',
    yt_integrated_video: 'Integrated Video',
    ["Package Cost 1"]: 'Package Cost'
  });
  const calculateTotalCost = (step: number) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i][`s_${step}_status`] !== "Rejected") {
        total += data[i][`s_${step}_price`];
      }
    }
    return total;
  };
  const checkPriceQuantity = () => {
    let error_status = false;
    let current_step = data[0].current_step;
    if (costing_model == "Individual") {
      data.forEach((obj: any) => {
        if (obj[`s_${current_step}_price`] === 0 || obj[`s_${current_step}_quantity`] === 0) {
          setError(true);
          error_status = true;

          setErrorMessage("Price or Quantity can't be zero")
          return; // exit the loop
        }
      });
      if (error_status == false) {
        submitProposal();
      }
    }
    else {
      const package_cost_item = data.filter((obj: any) => obj?.deliverable_item === "Package Cost 1")
      if (package_cost_item[0][`s_${current_step}_price`] === 0) {
        setError(true);
        error_status = true;
        setErrorMessage("Price or Quantity can't be zero")
        return; // exit the loop
      }
      else {
        submitProposal();

      }
    }
  };


  return (
    <div className='proposal-main-container'>
      {data && data.length > 0 && data[0]?.comments.length > 0 && <ProposalComment comments={data[0]?.comments} />}
      <div className='proposal-detail-sec'>
        {((campaignType === 'Paid') || ((data[0]?.status === 'Accepted' && campaignType !== 'Barter'))) &&
          <ProposalNegotiationStep step={step} data={data} />
        }
        <div className={`proposal-inner-container ${(campaignType === 'Barter') ? "type-barter" : ""}`}>
          <div className='d-none d-md-flex flex-1'>
            <CustomProposalTable deliverableHeader={deliverableHeader}
              data={data}
              profileData={profileData}
              throughWhatsapp={throughWhatsapp} />
          </div>
          {/*................................. Mobile View.................. */}
          <div className='proposal-mobile-container d-flex d-md-none flex-column'>
            <>
              <CustomProposalTableMobile deliverableHeader={deliverableHeader}
                data={data}
                step={step}
                profileData={profileData} negotiateVisible={negotiateVisible}
                throughWhatsapp={throughWhatsapp} />
              {negotiateVisible && (

                <div className='proposal-negotiation-container'>
                  <div className='proposal-negotiation-detail inner-content negotiate-response-table proposal-negotiation-sec-mobile d-flex d-md-none'>
                    <div className='proposal-negotiation-table-container'>
                      <div className='proposal-negotiation-resp-header'>
                        <div className='proposal-negotiation-resp-header-top'>
                          {/* <ProposalNegotiationStep step={step} data={data} /> */}
                          <ul className='stepper-sec mt-0 mb-0'>
                            <li className='mb-0'>My Response</li>
                          </ul>
                        </div>
                        <span className='resp-date'>
                          {dayjs(profileData?.proposal_datetime).format("DD MMM, YYYY")}
                        </span>
                      </div>
                      <div className="proposal-negotiation-table">
                        <div className="table-proposal">

                          <div className="table-proposal-header-row">
                            <span className='table-proposal-header-first-col'>Deliverables</span>
                            <span>
                              Quantity
                            </span>
                            {costing_model === "Individual" && <span>Cost in ₹</span>}
                          </div>

                        </div>
                      </div>
                      <div className='proposal-negotiation-table-row'>
                        <div className={`table-negotiation-body-first-col ${data[0].costing_model === 'Package' ? "package" : ""}`}>
                          {data?.filter((ele: any) => ele.deliverable_item != "Package Cost 1").map((item: any, index: number) => (
                            <>
                              <span className={`table-proposal-body-first-col`}>{[enumdeliverableItems[item?.deliverable_item as DeliverableItemKeys]]}</span>
                              {data[0].costing_model !== 'Package' &&
                                <span className="table-negotiation-body-empty"></span>
                              }
                            </>
                          ))}
                          {costing_model === "Package" && <span className={`table-proposal-body-first-col`}> Package Cost</span>}
                        </div>
                        <div className={`table-negotiation-body-rest-col-container negotiate-sec-input ${data[0].costing_model === 'Package' ? "package" : ""}`}>
                          {costing_model === "Individual" && negotiateVisible && data.length > 0 && [...data]?.map((data, index) => (
                            <>
                              <NegotiateInputMobile dataLength={data.length} key={index} dataSet={data} onChange={onChange} userType={userType} />

                            </>
                          ))}
                          {costing_model === "Package" && negotiateVisible && data.length > 0 && [...data]?.filter((ele: any) => ele.deliverable_item !== "Package Cost 1").map((data, index) => (
                            <>
                              <div className='table-negotiation-body-first-col'>
                                <span>
                                  {data[`s_${data.current_step}_quantity`]}
                                </span></div>
                            </>
                          ))}
                          {costing_model === "Package" && negotiateVisible && data.length > 0 && [...data]?.filter((ele: any) => ele.deliverable_item === "Package Cost 1").map((data, index) => (
                            <>
                              <NegotiateInputPackage dataLength={data.length} key={index} dataSet={data} onChange={onChange} userType={userType} />
                            </>
                          ))}


                        </div>
                      </div>
                      <div className='table-proposal-total-cost-row '>
                        <span className=' d-flex align-items-center fs-16 fw-bold'>{data?.length > 0 && <> Total Cost: ₹ {calculateTotalCost(data[0].current_step)}</>}</span>
                      </div>

                    </div>

                  </div>
                  <span className="error ms-auto">{errorMessage}</span>
                  <div className='proposal-negotiate-action-btn'>
                    <button className='btn btn-outline-primary' onClick={(() => {
                      setErrorMessage("")
                      handleCancel()
                      setNegotiateVisible(false)
                    })}>Cancel</button>
                    <button className='btn btn-primary' onClick={checkPriceQuantity}>Confirm</button>
                  </div>
                </div>
              )}
            </>
          </div>
          {/*................................. Mobile View.................. */}

          {negotiateVisible && (
            <>

              <div className='proposal-negotiation-detail inner-content negotiate-response-table d-none d-md-flex'>
                <div className='proposal-negotiation-table-row'>
                  <div className='table-negotiation-body-first-col'>
                    {costing_model === "Individual" && <span>Quantity</span>}
                    <span>Cost</span>
                    <span>&nbsp;</span>
                  </div>

                  <div className='table-negotiation-body-rest-col-container negotiate-sec-input'>
                    {costing_model === "Individual" && negotiateVisible && data.length > 0 && [...data]?.map((data, index) => (
                      <>
                        <NegotiateInput dataLength={data.length} key={index} dataSet={data} onChange={onChange} userType={userType} />
                      </>
                    ))}
                    {costing_model === "Package" && negotiateVisible && data.length > 0 && [...data]?.filter((ele: any) => ele.deliverable_item === "Package Cost 1").map((data, index) => (
                      <>
                        <NegotiateInputPackage dataLength={data.length} key={index} dataSet={data} onChange={onChange} userType={userType} />
                      </>
                    ))}

                  </div>
                </div>
                <span className='resp-amount ms-auto fw-bold'>{data?.length > 0 && <> Total Cost: ₹ {calculateTotalCost(data[0].current_step)}</>}</span>
                <span className="error ms-auto">{errorMessage}</span>

                <div className='proposal-negotiate-action-btn'>
                  <button className='btn btn-outline-primary' onClick={(() => {
                    setErrorMessage("")
                    handleCancel()
                    setNegotiateVisible(false)
                  })}>Cancel</button>
                  <button className='btn btn-primary' onClick={checkPriceQuantity}>Confirm</button>
                </div>
              </div>
              {(userType == "Influencer" || userType == "Talent Partner") && <div className="remaining-step-container d-md-none mt-4"><div className="remaining-step-name">Final counteroffer</div></div>}
              {(userType == "Influencer" || userType == "Talent Partner") && <div className="remaining-step-container d-md-none"><div className="remaining-step-name">Deal closed</div></div>}
            </>
          )}
        </div>
      </div>
    </div>

  )
}

export default ProposalContainer