import React, { useRef, useState, useEffect } from 'react';
import { Chip } from '@mui/material';
import MaterialSymbol from '../../../components/icons/material-symbol';

const KeywordInput = () => {
  const [showInput, setShowInput] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickOutside = (e: any) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      if (newKeyword.trim() === '') {
        setShowInput(false);
      } else {
        handleInputSubmit();
      }
    }
  };

  const handleInputSubmit = () => {
    if (newKeyword.trim()) {
      setKeywords([...keywords, newKeyword]);
      setShowInput(false);
      setNewKeyword('');
    }
  };

    const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleInputSubmit(); // Submit when pressing Enter
    }
  };
//   useEffect(() => {
//     if (showInput) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showInput, newKeyword]);
 useEffect(() => {
    if (showInput) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showInput, newKeyword]);

  return (
    <li>
      <span className='pb-3 d-inline-flex fs-14'>Set keywords that will start your DM automation.</span>
      <div className='new-automation-chips'>
        {keywords.map((keyword, index) => (
          <Chip key={index} label={keyword} onDelete={() => setKeywords(keywords.filter(k => k !== keyword))} className='chip-item' />
        ))}
        {showInput ? (
          <div className='inline-input d-inline-flex gap-2'>
            <input
              type='text'
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              placeholder='Enter new keyword'
              autoFocus
              className='form-control'
              ref={inputRef}
            />
          </div>
        ) : (
          <span className='new-keyword' onClick={() => setShowInput(true)}>
            <MaterialSymbol iconName='add' fontweight='200' className='fs-14' /> Keyword
          </span>
        )}
      </div>
    </li>
  );
};

export default KeywordInput;
