import React from 'react';
import { Outlet } from 'react-router-dom';
import { AgencyHeader } from '../../components/header/header-agency';

export const MainAgency: React.FC = () => {
  return (
    <div>
      {/* <AgencyHeader /> */}
      <Outlet />
    </div>
  );
};
