import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { AffiliateService } from '../../../core/services/affiliate.service';
import TablePagination from "@mui/material/TablePagination";
import {
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  TableContainer,
  TableSortLabel,
  Paper,
  Grid,
} from "@mui/material";

interface Props {
  UserReducer: any;
  UserID: any;
  loading?: (data: any) => void;
}

const tableHeadColumnPart1 = [
  {
    name: "shop_id",
    columnSize: "165px",
  },
  {
    name: "product_id",
    columnSize: "165px",
  },
  {
    name: "u_id",
    columnSize: "165px",
  },
  {
    name: "req_body_params_data",
    columnSize: "195px",
  },
  {
    name: "req_body_ip",
    columnSize: "165px",
  },
  {
    name: "req_originalUrl",
    columnSize: "165px",
  },
  {
    name: "req_route_method",
    columnSize: "165px",
  },
  {
    name: "req_protocol",
    columnSize: "165px",
  },
  {
    name: "req_hostname",
    columnSize: "165px",
  },
  {
    name: "req_body_config_id",
    columnSize: "165px",
  },
  {
    name: "req_body_cookie",
    columnSize: "165px",
  },
  {
    name: "req_body_meta_data",
    columnSize: "165px",
  },
];
const tableHeadColumnPart2 = [
  {
    name: "logo",
    columnSize: "165px",
  },
  {
    name: "account_name",
    columnSize: "165px",
  },
  {
    name: "media_type",
    columnSize: "110px",
  },

  {
    name: "product_name",
    columnSize: "180px",
  },

  {
    name: "er",
    columnSize: "50px",
  },
  {
    name: "reach",
    columnSize: "70px",
  },
  {
    name: "link_visited",
    columnSize: "120px",
  },
  {
    name: "landed_link",
    columnSize: "70px",
  },
  {
    name: "add_to_cart",
    columnSize: "70px",
  },
  {
    name: "checkout",
    columnSize: "70px",
  },
  {
    name: "success_sales_count",
    columnSize: "70px",
  },
  {
    name: "failed_sales_count",
    columnSize: "70px",
  },
  {
    name: "product_cost",
    columnSize: "165px",
  },
  {
    name: "total_commision",
    columnSize: "100px",
  },
  {
    name: "influencer_share",
    columnSize: "100px",
  },
  {
    name: "link",
    columnSize: "500px",
  },
];
const columnDisplayNames2: any = {
  logo: 'Influencer Image',
  account_name: "Influencer Name",
  media_type: "Media Type",
  product_image_url: 'Product Image',
  product_name: 'Product Name',

  er: "ER",
  reach: "Reach",
  link_visited: "Links Visited",
  landed_link: "Landed on Website",
  add_to_cart: "Added in Cart",
  checkout: "Checkout",
  success_sales_count: "Sales Generate",
  failed_sales_count: "Sale Failed",
  product_cost: "Product Price",
  total_commision: "Commision(%)",
  influencer_share: "Influencer Earned",
  link: "Affiliate Link",
};

const columnDisplayNames: any = {
  shop_id: "Shop Id",
  product_id: "Product Id",
  u_id: "U Id",
  req_body_params_data: "Params",
  req_body_ip: "Ip",
  req_originalUrl: "Original Url",
  req_route_method: "Method",
  req_protocol: "Protocol",
  req_hostname: "Hostname",
  req_body_config_id: "Config Id",
  req_body_cookie: "Cookies",
  req_body_meta_data: "Meta Data",
};
const AffiliateDashboard: React.FC<Props> = (props: Props) => {
  const [affilateData, setAffiliateData] = useState<any>([]);
  const [affiliateDashboardData, setAffiliateDashboardData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const brand_id = props.UserReducer?.brand_id;
  useEffect(() => {
    // AffiliateService.getAffiliateData(brand_id).then((res:any) => {
    //   if(res.data.status.status){
    //       setAffiliateData(res.data.rows);
    //   }

    // }).catch((e: any) => setAffiliateData([]));
    AffiliateService.getAffiliateDashboardData(brand_id).then((res: any) => {
      if (res.data.status.status) {
        setAffiliateDashboardData(res?.data?.rows);
        setAffiliateData(res?.data?.rows?.length)
      }

    }).catch((e: any) => setAffiliateDashboardData([]));

  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const sortedData = React.useMemo(() => {
  //     if (Array.isArray(affilateData)) {
  //     const sortedArray = [...affilateData].sort((a, b) => {
  //       if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
  //       if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
  //       return 0;
  //     });
  //     return sortedArray;
  //   }
  //   }, [dashboardFilterData, orderBy, order]);

  return (
    <div className="container-fluid">
      <TableContainer >
        <Table
          sx={{ minWidth: 650 }}
          aria-label="custom pagination table"
          className="common-table"
        >
          <TableHead className="common-table-header">
            <TableRow
            >
              {tableHeadColumnPart2.map((column) => (
                <TableCell
                  className="tableCell"
                  style={{
                    minWidth: column.columnSize,
                  }}
                  align="left"
                  key={column.name}
                >
                  {/* <TableSortLabel
                                    active={orderBy === column.name}
                                    direction={order}
                                    onClick={() => handleSort(column.name)}
                                  > */}
                  {columnDisplayNames2[column.name] ||
                    column.name}
                  {/* </TableSortLabel> */}
                </TableCell>
              ))}

              {/* <TableCell
                                className="tableCell"
                                style={{ minWidth: "120px" }}
                                align="left"
                              >
                                <FormControl sx={{ m: 1 }} size="small">
                                  <Select
                                    id="demo-select-small"
                                    value={paid}
                                    onChange={(e) => setPaid(e.target.value)}
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}
                                  >
                                  {paids.map((paid) => (
                                    <MenuItem key={paid} value={paid}>
                                    {paid}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell> */}

              {/* {tableHeadColumnPart2.map((column) => (
                              <TableCell
                                className="tableCell"
                                style={{ minWidth: column.columnSize }}
                                align="left"
                                key={column.name}
                              >
                                {/* <TableSortLabel
                                  active={orderBy === column.name}
                                  direction={order}
                                  onClick={() => handleSort(column.name)}
                                >
                                  {columnDisplayNames[column.name] ||
                                      column.name}
                                </TableSortLabel> */}
              {/* </TableCell> */}
              {/* ))}  */}
            </TableRow>
          </TableHead>
          <TableBody className="common-table-body">
            {affiliateDashboardData
              ?.map((row: any) => (
                <TableRow>
                  <TableCell className="tableCell" align="left">
                    <img style={{ maxWidth: "50px" }} src={row?.logo} alt="" />
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.account_name}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.media_type}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    <img style={{ maxWidth: "50px", marginRight: '10px', width: '50px', height: '50px', borderRadius: '50%' }} src={row?.product_image_url} alt="" />
                    {row?.product_name}
                  </TableCell>

                  <TableCell className="tableCell" align="left">
                    {row?.er}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.reach}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.landed_link}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.landed_link}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.add_to_cart}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.checkout}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.success_sales_count}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.failed_sales_count}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {((row?.product_cost * (100 - row?.discount)) / 100).toFixed(2)}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.total_commission}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {((((row?.product_cost * (100 - row?.discount)) / 100) * (row?.total_commission / 100)) * row?.success_sales_count).toFixed(2)}
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    {row?.link + row.cct_id}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
        className='common-pagination'
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        count={affilateData?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          color: "#00aae5",
          marginTop: "10px",
        }}
      /> */}
      {/* **************************** */}

      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer className="table-plan inf-main-data ">
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="custom pagination table"
                        >
                          <TableHead className="">
                            <TableRow
                            >
                              {tableHeadColumnPart1.map((column) => (
                                <TableCell
                                  className="tableCell"
                                  style={{
                                    minWidth: column.columnSize,
                                  }}
                                  align="left"
                                  key={column.name}
                                > */}
      {/* <TableSortLabel
                                    active={orderBy === column.name}
                                    direction={order}
                                    onClick={() => handleSort(column.name)}
                                  > */}
      {/* {columnDisplayNames[column.name] || */}
      {/* column.name} */}
      {/* </TableSortLabel> */}
      {/* </TableCell> */}
      {/* ))} */}

      {/* <TableCell
                                className="tableCell"
                                style={{ minWidth: "120px" }}
                                align="left"
                              >
                                <FormControl sx={{ m: 1 }} size="small">
                                  <Select
                                    id="demo-select-small"
                                    value={paid}
                                    onChange={(e) => setPaid(e.target.value)}
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "600",
                                    }}
                                  >
                                  {paids.map((paid) => (
                                    <MenuItem key={paid} value={paid}>
                                    {paid}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell> */}

      {/* {tableHeadColumnPart2.map((column) => (
                              <TableCell
                                className="tableCell"
                                style={{ minWidth: column.columnSize }}
                                align="left"
                                key={column.name}
                              >
                                {/* <TableSortLabel
                                  active={orderBy === column.name}
                                  direction={order}
                                  onClick={() => handleSort(column.name)}
                                >
                                  {columnDisplayNames[column.name] ||
                                      column.name}
                                </TableSortLabel> */}
      {/* </TableCell> */}
      {/* ))}  */}
      {/* </TableRow>
                        </TableHead>
                        <TableBody>
                          {affilateData
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((row: any) => (
                              <TableRow>
                                <TableCell className="tableCell" align="left">
                                  {row.shop_id}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.product_id}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.u_id}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.req_body_params_data}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.req_body_ip}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.req_originalUrl}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.req_route_method}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                  {row.req_protocol}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                { row.req_hostname}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                {row.req_body_config_id}
                                </TableCell>
                                <TableCell className="tableCell" align="left">
                                {row.req_body_cookie} 
                                </TableCell> */}
      {/* <TableCell className="tableCell" align="left">
                                {row.req_body_meta_data} 
                                </TableCell> */}
      {/* <TableCell className="tableCell" align="left">
                                 
                                  </TableCell> */}
      {/* </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 25]}
                      count={affilateData?.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{
                        color: "#00aae5",
                        marginTop: "10px",
                      }}
                    /> */}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    UserID: state.UserID.userID,
  };
};

const connectToRedux = connect(mapStateToProps)(AffiliateDashboard);

export { connectToRedux as AffiliateDashboard };