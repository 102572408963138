import React, { useEffect, useState, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  IconButton,

} from '@mui/material';
import { ApplyCampaignService } from '../../../../../../../core/services';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';
import Loading from '../../../../../../../components/loader/loading';
import { DeliverableTool } from '../proposal-components/deliverable-tool';
import { Profile } from '../proposal-components/profile';
import ProposalContainer from '../proposal-components/proposal-container';

interface Props {
  UserReducer: any;
  selectedCampaign: any;
  unreadProposal: any;
  updateUnreadProposal: () => void;
  set_sm_id?: (id: number) => void;
}

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';

type CurrentSteps = 1 | 2 | 3 | 4;

interface Influencer {
  influencer_id: number;
  username: string;
  sm_id: number;
}

interface FormDataType {
  negotiation_id: number;
  proposal_id: number;
  campaign_id: number;
  influencer_id: number;
  sm_id: number;
  deliverable_item: string;
  s_0_quantity: number;
  s_0_price: number;
  s_0_status: StatusType;
  s_0_date: string;
  s_1_quantity: number;
  s_1_price: number;
  s_1_status: StatusType;
  s_1_date: string;
  s_2_quantity: number;
  s_2_price: number;
  s_2_status: StatusType;
  s_2_date: string;
  s_3_quantity: number;
  s_3_price: number;
  s_3_status: StatusType;
  s_3_date: string;
  current_step: CurrentSteps;
  status: StatusType;
  proposal_datetime: string;
  created_at: string;
  updated_at: string;
}

const BrandRejected: React.FC<Props> = (props: Props) => {
  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [originalInfluencers, setOriginalInfluencers] = useState<Influencer[]>([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([]);
  const [expanded, setExpanded] = useState<number | false>(false);
  const [details, setDetails] = useState<FormDataType[]>([]);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [deliverableHeader, setDeliverableHeader] = useState<any>([]);
  const [influencersData, setInfluencersData] = useState({
    Nano: [],
    Micro: [],
    'Micro Plus': [],
    Macro: [],
    'Macro Plus': [],
    Mega: [],
    'Mega Plus': [],
    All: [],
  });

  const campaignDetails = useMemo(() => {
    return {
      campaignId: searchParams.get('campaignID'),
      planId: searchParams.get('planID'),
    };
  }, [searchParams]);

  const getDeliverableHeader = async () => {
    try {
      const response: any = await ApplyCampaignService.getDeliverableHeader({ campaign_id: campaignDetails.campaignId });
      if (response.status === 1) {
        setDeliverableHeader(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching deliverable header:', error);
    }
  };

  const getInfluencerList = async () => {
    try {
      setLoading(true);
      const response = await ApplyCampaignService.influencerList({ campaign_id: campaignDetails.campaignId, proposalStatus: 'Rejected' });
      if (response?.data?.rows?.length >= 0) {
        let inf: any = {
          Nano: [],
          Micro: [],
          'Micro Plus': [],
          Macro: [],
          'Macro Plus': [],
          Mega: [],
          'Mega Plus': [],
          All: response.data.rows,
        };

        response.data.rows.forEach((res: any) => {
          if (inf[res.infType]) {
            inf[res.infType].push(res);
          }
        });

        setInfluencersData(inf);
        setInfluencers(response.data.rows);

        setOriginalInfluencers(response.data.rows);
        setFilteredInfluencers(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching influencer list:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfluencerList();
    getDeliverableHeader();
  }, [campaignDetails.campaignId]);

  const handleChange = async (panel: number, sm_id: number) => {
    try {
      setLoading(true);
      if (expanded === panel) {
        setExpanded(false);
      }
      else {
        const response = await ApplyCampaignService.brandProposalDetails({ smId: sm_id, campaign_id: campaignDetails.campaignId });
        if (response?.data?.rows?.length > 0) {
          setDetails(response.data.rows);
          setExpanded(panel);
          getInfluencerList();
        }
      }

    } catch (error) {
      console.error('Error fetching proposal details:', error);
    } finally {
      if (props.unreadProposal.some((e: any) => e.sm_id === sm_id)) {
        props.updateUnreadProposal();
      }
      setLoading(false);
    }
  };

  const handleFilteredInfluencer = (event: any) => {
    const value = event.toLowerCase();
    if (value === 'all') {
      setFilteredInfluencers(originalInfluencers);
    } else {
      const filtered = originalInfluencers.filter((influencer: any) =>
        influencer?.infType?.toLowerCase() === value?.toLowerCase()
      );
      setFilteredInfluencers(filtered);
    }
  };

  return (
    <div className="proposal-container">
      {/* PROPOSAL CONTAINER LEFT */}
      <div className="proposal-container-left">
        <DeliverableTool data={influencersData} filteredInfluencer={(influencer: any) => handleFilteredInfluencer(influencer)} />
      </div>

      {/* PROPOSAL CONTAINER RIGHT */}
      <div className="proposal-container-right">
        {filteredInfluencers.map((inf, index) => (
          <Accordion className='accordion-container' key={inf.influencer_id} expanded={expanded === index} onChange={() => handleChange(index, inf.sm_id)}>
            <AccordionSummary>

              <div className="proposal-header">
                {props.unreadProposal.length > 0 && (props.unreadProposal.map((influencer_data: any, index: any) => (
                  (inf.sm_id === influencer_data.sm_id && influencer_data.status === 'Rejected' && influencer_data.read == 'no') &&

                  <span className='unread-notification-icon'></span>

                )))}
                <div className="proposal-header-left">
                  <Profile profileData={inf} data={details} deliverableHeader={deliverableHeader} userReducer={props.UserReducer} />
                </div>
                <div className="proposal-header-right">
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {loading && expanded === index && details ? (
                <Loading width='80' height='80' />
              ) : (
                <ProposalContainer
                  deliverableHeader={deliverableHeader}
                  data={details}
                  step={details}
                  profileData={inf}
                />
                // <div className='proposal-detail-sec'>

                //   <ProposalComment comments="asdfasdf" />
                //   <ProposalNegotiationStep step={index + 1} />
                //   <CustomProposalTable
                //     deliverableHeader={deliverableHeader}
                //     data={details}
                //     profileData={inf}
                //   />
                // </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
        {/* DISPLAY EMPTY MESSAGE */}
        {influencers.length === 0 && (
          <p className="no-list-comment align-items-baseline mt-5">There are no rejected proposals at the moment.</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const connectToRedux = connect(mapStateToProps)(BrandRejected);

export { connectToRedux as BrandRejected };
