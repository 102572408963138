import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { DiscoverService, LocalService } from '../../../core/services';
import './compare.scss';
import { DefaultUserProfile } from '../../../config/config';
import { Button } from '@mui/material';
// import common popup and upgrade brand popup component
import CommonPopUp from '../../../components/popup/common-popup';
import UpgradeBrandPopUp from '../../../components/popup/upgrade-brand-popup';

import { LoadingButton, TabPanel } from "@mui/lab";
import { Loading } from '../../../redux/action';
import { StockChart, PieChart, ColumnChart } from '../../../components/charts';

const localService: LocalService = new LocalService();

// type Props = {};
interface Props {
    UserReducer: any;
    loading: (data: any) => void;
}
const discoverService: DiscoverService = new DiscoverService();

const CompareYT: React.FC<Props> = (props: Props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [influencer_detail, set_influencer_detail] = useState([]);

    useEffect(() => {
        getCompareData();
      }, []);
    
      async function getCompareData() {
        try {
          props.loading(true);
          const influencerIds = searchParams.getAll('youtubeIds');
          const eachInflId = influencerIds.map((infId: string) => {
          return infId;
          }); 
          const res = await discoverService.compareInfluencers({
                  "platform": "Youtube",
                  "ids": eachInflId,
                  user_id: props.UserReducer.user_id,
                  account_id: props.UserReducer.account_id,
                  brand_id: props.UserReducer.brand_id,
                  influencer_id: props.UserReducer.influencer_id
                });
    
            if (res.status === 1) {
                set_influencer_detail(res?.data);
            }
        } catch (error) {
          console.error('Error fetching getCompareData:', error);
        } finally {
          props.loading(false);
        }
      }

    // set upgrade brand Modal variables and usestate
    const [openUpgradeBrandModal, setopenUpgradeBrandModal] = useState(false);

    const handleOpenUpgradeBrandModal = () => setopenUpgradeBrandModal(true);
    function handleCloseUpradeBrandModal() {
        setopenUpgradeBrandModal(false);
    }

    // set profile view limit Modal variables and usestate
    const [profileViewLimitPopup, setProfileViewLimitPopup] = useState(false);
    const [profileViewCount, setProfileViewCount] = useState(0);
    const [profileViewLimit, setProfileViewLimit] = useState(0);
    const [selectedSmId, setSelectedSmId] = useState(0);
    const [profile_insight_url, setProfile_insight_url] = useState('');
    const [profileViewModalTitle, setProfileViewModalTitle] = useState('View Profile');

    const handleOpenProfileViewLimitModal = () => setProfileViewLimitPopup(true);
    function handleCloseProfileViewLimitModal() {
        setProfileViewLimitPopup(false);
    }

    const handleNavigate = (id: number | string, platform_type: string, influencer_id: number | string) => {
        if (platform_type === 'instagram') {
            window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencer_id}`, "_blank");
        } else {
            window.open(`/brand/profile-insight-yt?sm_id=${id}&influencerId=${influencer_id}`, "_blank");
        }
    };

    async function handleProfileView(event: any, platform_type: string, sm_id: number, influencer_id: number) {
        try {
            props.loading(true);
            // const sm_id = event.target.dataset.sm_id;
            setSelectedSmId(sm_id);
            if (platform_type === 'instagram') {
                setProfile_insight_url(`/brand/profile-insight?sm_id=${sm_id}&influencerId=${influencer_id}`)
            } else {
                setProfile_insight_url(`/brand/profile-insight-yt?sm_id=${sm_id}&influencerId=${influencer_id}`)
            }
            // const link_to_id = event.target.dataset.id;
            //  to={`/brand/profile-insight?sm_id=${row?.ig_id}`} target="_blank"
            const response = await discoverService.canViewProfile({
                user_id: props.UserReducer.user_id,
                account_id: props.UserReducer.account_id,
                brand_id: props.UserReducer.brand_id,
                influencer_id: props.UserReducer.influencer_id,
                sm_id: sm_id
            })
            // debugger
            if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_profile_limit_popup)) {
                // // Open the URL in a new tab
                // // Not using this bcoz got pop blocked error, can be fixable in my browser but might come in user side too.
                // // window.open(`/brand/profile-insight?sm_id=${sm_id}`, '_blank');
                // // Get the element by its ID
                // const buttonElement = document.getElementById(link_to_id);
                // // Trigger the click event programmatically
                // if (buttonElement != null && buttonElement != undefined){
                //   buttonElement.click();
                // }

                // if (platform_type === 'instagram'){
                //   window.open(`/brand/profile-insight?sm_id=${sm_id}`, '_blank');
                // }else{
                //   window.open(`/brand/profile-insight-yt?sm_id=${sm_id}`, '_blank');
                // }
                handleNavigate(sm_id, platform_type, influencer_id);
            }
            else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_profile_limit_popup) {
                setProfileViewCount(response?.data?.profile_view_count);
                setProfileViewLimit(response?.data?.profile_view_limit);
                setProfileViewLimitPopup(true);
            }
            // else if (response.data.status && response.data.basic_user ){
            //   setProfileViewLimitPopup(true);
            // }
            else if (!response.data.status && response.data.message.includes('upgrade')) {
                localService.toastify(response.data.message, 'info', 1000);
                handleOpenUpgradeBrandModal();
            }
            else if (!response.data.status) {
                localService.toastify(response.data.message, 'error', 1000);
            }
        } catch (error) {
            console.log(`getFilteredInfluencerCountYT error :: ${JSON.stringify(error)}`);
        } finally {
            props.loading(false);
        }
    };

    // To handle profile view
    async function handleFocProfileView(event: any, profile_insight_url: string) {
        try {
            props.loading(true);
            const sm_id = event.target.dataset.sm_id;
            // const link_to_id = event.target.dataset.id;
            // const buttonElement = document.getElementById(link_to_id);
            // // Trigger the click event programmatically
            // if (buttonElement != null && buttonElement != undefined){
            //   handleCloseProfileViewLimitModal();
            //   buttonElement.click();
            // }
            handleCloseProfileViewLimitModal();
            window.open(profile_insight_url, '_blank');
        } catch (error) {
            console.log(`getFilteredInfluencerCountYT error :: ${JSON.stringify(error)}`);
        } finally {
            props.loading(false);
        }
    };

    return (
        <div className='comparedashboradbox'>
            <div className='container container-1370'>
                <h3 className='title-w-bdr'>
                    <span>Compare</span>
                </h3>
            </div>
            <section className='subs-sec compare-infl bottom-row flex  d-none d-md-flex'>
                <div className='container custom-container align-items-start'>
                    <ul>
                        <li>
                            <span className='compare-infl-title' />
                            <div className='compare-infl-text-sec'>
                                {influencer_detail.map((data: any, index: number) => (
                                    <Link className='compare-infl-text compare-infl-img' to="#" data-sm_id={data?.rows?.profile_detail[0]?.yt_id} onClick={(event) => handleProfileView(event, 'youtube', data?.rows?.profile_detail[0]?.yt_id, data?.rows.profile_detail[0].influencer_id)}>
                                        <div className='compare-box'>
                                            <img
                                                src={
                                                    (data?.rows.profile_detail[0].gcp_profile_image_url || data?.rows.profile_detail[0].profile_image_url)
                                                      ? (data?.rows.profile_detail[0].gcp_profile_image_url ? data?.rows.profile_detail[0].gcp_profile_image_url : data?.rows.profile_detail[0].profile_image_url)
                                                      : DefaultUserProfile.influencer_unknown_default
                                                }
                                                style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                    borderRadius: '50%',
                                                    marginBottom: '10px',
                                                }}
                                            />
                                            <div className='compare-imgtxt'>
                                                <div className='d-none compare-right-icon'>
                                                    <img
                                                        src='images/plus-icon.png'
                                                        className='compare-right-1'
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <span className='compare-username'>{data?.rows?.profile_detail[0]?.title}</span>
                                                <span className='compare-social-username influ-prof-info-social-username'>
                                                    {data?.rows?.profile_detail[0]?.custom_url}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <span />
                        </li>
                    </ul>
                </div>
            </section>
            <section className='subs-sec compare-infl bottom-row flex d-none d-md-flex'>
                <div className='container custom-container align-items-start'>
                    <ul>
                        {/*-------------------------------Start Of Section-Section-1-------------------------------------*/}
                        <li>
                            <span className='compare-infl-title'>Subscribers</span>
                            <div className='compare-infl-text-sec'>
                                {influencer_detail.map((data: any, index: number) => (
                                    <span className='compare-infl-text'>
                                        <span className='fw-600'>{data?.rows?.profile_detail[0]?.subscriber_count}</span>
                                    </span>
                                ))}
                            </div>
                        </li>
                        {/*-------------------------------Start Of Section-Engagement-------------------------------------*/}
                        <li>
                            <span className='compare-infl-title'>Total Views</span>
                            <div
                                className='compare-infl-text-sec'
                                id='engagement-rate-section'
                            >
                                {influencer_detail.map((data: any, index: number) => (
                                    <span className='compare-infl-text'>
                                        <span className='fw-600'>{data?.rows?.profile_detail[0]?.view_count}</span>
                                    </span>
                                ))}
                            </div>
                        </li>
                        <li>
                            <span className='compare-infl-title'>Total Video Count</span>
                            <div
                                className='compare-infl-text-sec'
                                id='engagement-rate-section'
                            >
                                {influencer_detail.map((data: any, index: number) => (
                                    <span className='compare-infl-text'>
                                        <span className='fw-600'>{data?.rows?.profile_detail[0]?.video_count}</span>
                                    </span>
                                ))}
                            </div>
                        </li>
                        <li>
                            <span className='compare-infl-title'>
                                Gender Split
                                <div className='d-flex mt-3 justify-content-start'>
                                    <span className='mr-3'>
                                        <span
                                            style={{
                                                color: '#5da5e0',
                                                display: 'inline-block',
                                                fontSize: 24,
                                            }}
                                            className='icon-male'
                                        />
                                        Male
                                    </span>
                                    <span>
                                        <span
                                            style={{
                                                color: '#e05d93',
                                                display: 'inline-block',
                                                fontSize: 24,
                                            }}
                                            className='icon-female'
                                        />{' '}
                                        Female
                                    </span>
                                </div>
                            </span>
                            <div className='compare-infl-text-sec'>
                                {influencer_detail && influencer_detail?.map((data: any) => (
                                    <span className='compare-infl-text'>
                                        <div
                                        data-highcharts-chart={1}
                                        style={{ overflow: "hidden" }}
                                        >
                                            <PieChart dataSource={[
                                            { name: "Male", y: data?.rows?.gender_split[0]?.male_percentage, color: "#5da5e0" },
                                            { name: "Female", y: data?.rows?.gender_split[0]?.female_percentage, color: "#e05d93" },
                                            ]} showInLegend={true} title="" size='100%' width={300} height={260}/>

                                        </div>
                                    </span>
                                ))
                                }
                            </div>
                        </li>
                        <li>
                            <span className='compare-infl-title'>Average Views For 1 Month</span>
                            <div
                                className='compare-infl-text-sec'
                                id='engagement-rate-section'
                            >
                                {influencer_detail?.map((data: any, index) => (
                                    <span className="compare-infl-text">
                                        <ul className="cities-list stacked-list">
                                            <li className="stacked-list-item">
                                                <div className="labelContainer">
                                                    <span className="city-name">Mobile : {data?.rows?.mobile_view_month[0]?.average_mobile_views} </span>
                                                </div>
                                            </li>
                                            <li className="stacked-list-item">
                                                <div className="labelContainer">
                                                    <span className="city-name">Tablet : {data?.rows?.tablet_view_month[0]?.average_tablet_views} </span>
                                                </div>
                                            </li>
                                            <li className="stacked-list-item">
                                                <div className="labelContainer">
                                                    <span className="city-name">TV : {data?.rows?.tv_view_month[0]?.average_tv_views} </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </span>
                                ))}
                            </div>
                        </li>
                        <li>
                            <span className='compare-infl-title'>Est. Min Watched For 1 month</span>
                            <div
                                className='compare-infl-text-sec'
                                id='engagement-rate-section'
                            >
                                {influencer_detail?.map((data: any, index) => (
                                    <span className="compare-infl-text">
                                        <ul className="cities-list stacked-list">
                                            <li className="stacked-list-item">
                                                <div className="labelContainer">
                                                    <span className="city-name">Mobile : {data?.rows?.mobile_min_month[0]?.average_mobile_min} </span>
                                                </div>
                                            </li>
                                            <li className="stacked-list-item">
                                                <div className="labelContainer">
                                                    <span className="city-name">Tablet : {data?.rows?.tablet_min_month[0]?.average_tablet_min} </span>
                                                </div>
                                            </li>
                                            <li className="stacked-list-item">
                                                <div className="labelContainer">
                                                    <span className="city-name">TV : {data?.rows?.tv_min_month[0]?.average_tv_min} </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </span>
                                ))}
                            </div>
                        </li>
                        <li>
                            <span className='compare-infl-title'>Top Countries</span>
                            <div
                                className='compare-infl-text-sec'
                                id='engagement-rate-section'
                            >
                                {influencer_detail && influencer_detail.map((data: any, index: any) => (
                                    <span className="compare-infl-text">
                                        <ul className="cities-list stacked-list">
                                            {data?.rows?.top_countries.map((country: any, index: any) => (
                                                <li className="stacked-list-item">
                                                    <span>{index + 1}</span>
                                                    <div className="labelContainer">
                                                        <span className="city-name">{country?.country}</span>
                                                    </div>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </span>
                                ))
                                }
                            </div>
                        </li>
                        <li>
                            <span className='compare-infl-title'>
                                Avg Views Split On Devices
                                <div className='d-flex flex-column mt-3'>
                                    <span>
                                        <span
                                            style={{
                                                backgroundColor: '#7cb5ec',
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                fontSize: 12,
                                                display: 'inline-block',
                                            }}
                                        />
                                        Mobile
                                    </span>
                                    <span>
                                        <span
                                            style={{
                                                backgroundColor: '#006599',
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                fontSize: 12,
                                                display: 'inline-block',
                                            }}
                                        />{' '}
                                        Tablet
                                    </span>
                                    <span>
                                        <span
                                            style={{
                                                backgroundColor: '#FD9900',
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                fontSize: 12,
                                                display: 'inline-block',
                                            }}
                                        />{' '}
                                        TV
                                    </span>
                                </div>
                            </span>
                            <div
                                className='compare-infl-text-sec'
                                id='posts_est_reach_comparison'
                            >
                                {influencer_detail && influencer_detail?.map((data: any) => (
                                    <span className='compare-infl-text'>
                                        <div
                                        data-highcharts-chart={1}
                                        style={{ overflow: "hidden" }}
                                        >
                                            <PieChart dataSource={
                                            [
                                                { name: "Mobile", y: data?.rows?.views_on_devices[0]?.total_mobile_views, color: "#5da5e0" },
                                                { name: "Tablet", y: data?.rows?.views_on_devices[0]?.total_tablet_views, color: "#006599" },
                                                { name: "TV", y: data?.rows?.views_on_devices[0]?.total_tv_views, color: "#FD9900" },
                                            ]
                                            } showInLegend={true} title="" width={300} size="100%" height={260}/>
                                        </div>
                                    </span>
                                ))
                                }
                            </div>
                        </li>
                        <div className='row w-100' />
                    </ul>
                </div>
            </section>

            {openUpgradeBrandModal && (
                <UpgradeBrandPopUp
                    openUpgradeBrandModal={openUpgradeBrandModal}
                    onClose={() => handleCloseUpradeBrandModal()}
                    popupMinWidth='630px'
                >
                </UpgradeBrandPopUp>
            )}

            {profileViewLimitPopup && (
                <CommonPopUp
                    modalTitle={profileViewModalTitle}
                    // modalContent={modalContent}
                    openModal={profileViewLimitPopup}
                    onClose={() => handleCloseProfileViewLimitModal()}
                    popupMinWidth='630px'
                    className="modal-medium mx-auto"
                >
                    {
                        (
                            <div className="justify-content-between align-items-center mt-3">
                                <div className="d-flex">
                                    <p>
                                        {(profileViewCount === 1) ? `As a free user, you will get ${profileViewLimit} profile views. This is your ${profileViewCount} profile view. If you continue, you will be left with ${profileViewLimit - 1} profile views.` : `This is your ${profileViewCount}/${profileViewLimit} profile view. If you continue, you will be left with ${profileViewLimit - profileViewCount} profile views for this month.`}
                                    </p>
                                </div>
                                <div className="helper-text d-flex mt-3">
                                    <p className="inline">Do you wish to continue?</p>
                                    <div className="d-flex justify-content-end ml-auto">
                                        <Button
                                            className="btn btn-primary btn-mui"
                                            onClick={() => handleCloseProfileViewLimitModal()}
                                            variant="outlined"
                                        >
                                            No
                                        </Button>
                                        <Button
                                            className="btn btn-outline-primary btn-mui ms-2"
                                            data-sm_id={selectedSmId}
                                            onClick={(event) => handleFocProfileView(event, profile_insight_url)}
                                            variant="contained"
                                        >
                                            Yes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </CommonPopUp>
            )}
        </div>
    );
};

export default CompareYT;


const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(CompareYT);

export { connectToRedux as CompareYT };