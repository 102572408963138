interface Props {
  className?: string;
}
const IconReceived: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.214355 14.298V4.899H1.61336V11.9134L12.8172 0.701904L13.798 1.68265L2.59411 12.899H9.61336V14.298H0.214355Z" fill="white" />
      </svg>
    </div>
  );
};

export default IconReceived;
