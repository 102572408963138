interface ErrorTypes {
  status: boolean;
  message: string;
}

export class FormValidation {
  GetNameValidate(name: string): ErrorTypes {
    if (name.trim()) {
      if (name.length > 2) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter valid name',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  //To use where name should not contain the number
  GetNameValidate2(name: string): ErrorTypes {
    if (!name.trim()) {
      return { status: false, message: 'This field is required' };
    } else if (name.length <= 2) {
      return { status: false, message: 'Please enter a valid name (minimum 3 characters)' };
    } else if (/\d/.test(name)) {
      return { status: false, message: 'Name cannot contain numbers' };
    } else {
      return { status: true, message: '' };
    }
  }

  // IsvalidYear(year: string) {
  //   const yearPattern = new RegExp(/^(19[5-9]\d|200[0-5])$/);
  //   const status = { status: false, message: 'Please enter valid year' };
  //   if (yearPattern.test(year)) {
  //     status.status = true;
  //     status.message = '';
  //   } else {
  //     status.status = false;
  //     status.message = 'Please enter valid year';
  //   }
  //   return status;
  // }

  IsvalidYear(year: string) {
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 4; // Age must be at least 4 years
    const yearPattern = new RegExp(`^(19[5-9]\\d|20[0-${Math.floor((minYear - 2000) / 10)}]\\d|${minYear})$`);
    const status = { status: false, message: '' };

    if (!yearPattern.test(year)) {
      status.message = 'Please enter a valid year';
      return status;
    }
  
    const age = currentYear - parseInt(year);
    if (age < 4) {
      status.message = 'Age should not be less than 4 years';
      return status;
    }
  
    status.status = true;
    return status;
  }

  IsValidDay(day: string) {
    const dayPattern = new RegExp(/^(0?[1-9]|[1-2][0-9]|3[0-1])$/);
    const status = { status: false, message: '' };
    if (dayPattern.test(day)) {
      status.status = true;
      status.message = '';
    } else {
      status.status = false;
      status.message = 'Please enter valid day';
    }
    return status;
  }

  IsValidMonth(month: string) {
    const monthPattern = new RegExp(/^(0?[1-9]|1[0-2])$/);
    const status = { status: false, message: '' };
    if (monthPattern.test(month)) {
      status.status = true;
      status.message = '';
    } else {
      status.status = false;
      status.message = 'Please enter valid month';
    }
    return status;
  }

  // GetEmailControlIsValid(email: string): ErrorTypes {
  //   const pattern = new RegExp(
  //     // /[a-z0-9._%+!$&*=^|~#%'`?{}\/-]+@([a-z0-9-]+\.)+[a-z]{2,16}/
  //     /^[a-z0-9._%$&*=^|~#%'`?{}]+@[a-z0-9-]+\.[a-z]{2,16}$/i
  //   );
  //   if (email) {
  //     if (pattern.test(email)) {
  //       return { status: true, message: '' };
  //     } else {
  //       return { status: false, message: 'Please enter valid email.' };
  //     }
  //   } else {
  //     return { status: false, message: 'This field is required' };
  //   }
  // }
//   GetEmailControlIsValid(email: string): ErrorTypes {
//   // Remove spaces from the email input
//   const trimmedEmail = email.replace(/\s+/g, '').trim();

//   const pattern = /^[a-z0-9._%$&*=^|~#%'`?{}]+@[a-z0-9-]+\.[a-z]{2,16}$/i;

//   if (trimmedEmail) {
//     if (pattern.test(trimmedEmail)) {
//       return { status: true, message: '' };
//     } else {
//       return { status: false, message: 'Please enter valid email.' };
//     }
//   } else {
//     return { status: false, message: 'This field is required' };
//   }
// }

  GetEmailControlIsValid(email: string): ErrorTypes {
  // Remove spaces from the email input
  const trimmedEmail = email.replace(/\s+/g, '').trim();

  // Define regex for validation
  const pattern = /^[a-z0-9._%$&*=^|~#%'`?{}+]+@[a-z0-9-]+\.[a-z]{2,16}$/i;

  // Perform validation
  if (trimmedEmail) {
    if (pattern.test(trimmedEmail)) {
      return { status: true, message: '' }; // Valid email
    } else if (!trimmedEmail.includes('@')) {
      return { status: false, message: 'Email must include "@" symbol.' };
    } else if (!/@[a-z0-9-]+\.[a-z]{2,16}$/.test(trimmedEmail)) {
      return { status: false, message: 'Invalid domain or TLD.' };
    } else {
      return { status: false, message: 'Please enter a valid email.' }; // Generic error
    }
  } else {
    return { status: false, message: 'This field is required' }; // Empty input error
  }
}

  SecondaryEmailControlIsValid(email: string): ErrorTypes {
    const pattern = new RegExp(
      /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/
    );
    if (email) {
      if (pattern.test(email)) {
        return { status: true, message: '' };
      } else {
        return { status: false, message: 'Please enter valid email.' };
      }
    } else {
      return { status: true, message: '' };
    }
  }

  GetPinIsValid(pin: string): ErrorTypes {
    const pattern = /^\d{4}$/;
    if (pin.length > 0) {
      if (pattern.test(pin)) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter valid 4 digit Numeric Pin.',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  // GetMobileControlIsValid(phone_number: string): ErrorTypes {
  //   const phone_pattern = new RegExp(/^[6-9]\d{9}$/gi);
  //   if (phone_number) {
  //     if (phone_pattern.test(phone_number)) {
  //       return { status: true, message: '' };
  //     } else if (phone_number.length != 10) {
  //       return { status: false, message: 'Please enter valid mobile no.' };
  //     } else {
  //       return { status: false, message: 'Please enter valid mobile no.' };
  //     }
  //   } else {
  //     return { status: false, message: 'Please enter mobile no.' };
  //   }
  // }
 
  GetMobileControlIsValid(phone_number: string | number): ErrorTypes {
    const phone_pattern = /^[6-9]\d{9}$/; // Pattern to validate mobile numbers
  
    // Convert the phone_number to a string if it's a number
    const phoneStr = typeof phone_number === 'number' ? String(phone_number) : phone_number;
  
    // Check if the phone number exists
    if (!phoneStr) {
      return { status: false, message: 'Please enter mobile no.' };
    }
  
    // Trim spaces before validating
    const trimmedPhone = phoneStr.replace(/\s+/g, '').trim();
  
    // Check if the phone number matches the required pattern
    if (!phone_pattern.test(trimmedPhone)) {
      return { status: false, message: 'Please enter valid mobile no.' };
    }
  
    // Check if the phone number has exactly 10 digits
    if (trimmedPhone.length !== 10) {
      return { status: false, message: 'Please enter valid mobile no.' };
    }
  
    // If all checks pass, return valid status
    return { status: true, message: '' };
  }

  // To validate secondary number in My accounts section.
  SecondaryMobileControlIsValid(phone_number: string): ErrorTypes {
    const phone_pattern = new RegExp(
      /^(?:\+\d{1,3}\s?)?(?:\(\d{1,4}\)|\d{1,4})[-.\s]?\d{2,12}$/
    );
    if (phone_number) {
      if (phone_pattern.test(phone_number)) {
        return { status: true, message: '' };
      } else if (phone_number.length != 10) {
        return { status: false, message: 'Please enter valid mobile no.' };
      } else {
        return { status: false, message: 'Please enter valid mobile no.' };
      }
    } else {
      return { status: true, message: '' };
    }
  }

  // To validate genre in Settings edit
  GenreControlIsValid(category_ids: string[]): ErrorTypes {
    if (category_ids.length === 0) {
      return { status: false, message: 'Please select atleast 1 genre' };
    } else if (category_ids.length > 3) {
      return { status: false, message: 'Upto 3 genre are allowed.' };
    } else {
      return { status: true, message: '' };
    }
  }

  // To validate location in settins edit
  LocationControlIsValid(location_id: number): ErrorTypes {
    if (location_id > 0) {
      return { status: true, message: '' };
    } else {
      return { status: false, message: 'Please select atleast 1 Location' };
    }
  }

  //function validation is pending  Now (pather are pending now )
  // GetPasswordValidate(password: string): ErrorTypes {
  //   let response: ErrorTypes = {
  //     status: true,
  //     message: '',
  //   };
  //   try {
  //     if (password.length >= 8) {
  //       const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
  //       if (regex.test(password)) {
  //         response = {
  //           status: true,
  //           message: '',
  //         };
  //       } else {
  //         response = {
  //           status: false,
  //           message:
  //             'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.',
  //         };
  //       }
  //     } else if (password.length == 0) {
  //       response = {
  //         status: false,
  //         message: 'This field is required',
  //       };
  //     } else {
  //       response = {
  //         status: false,
  //         message: 'Minimum 8 char length',
  //       };
  //     }

  //     return response;
  //   } catch (error) {
  //     throw new Error('Get Password Validate Error in utlity :: ' + error);
  //   }
  // }
  
  GetPasswordValidate(password: string): ErrorTypes {
    let response: ErrorTypes = {
      status: true,
      message: '',
    };
  
    try {
      if (password.length === 0) {
        return {
          status: false,
          message: 'This field is required',
        };
      }
  
      const messages: string[] = [];
      
      if (password.length < 8) {
        messages.push('minimum 8 characters in length');
      }
      if (!/[A-Z]/.test(password)) {
        messages.push('1 uppercase letter');
      }
      if (!/[a-z]/.test(password)) {
        messages.push('1 lowercase letter');
      }
      if (!/\d/.test(password)) {
        messages.push('1 number');
      }
      if (!/[@#$%^&+=!]/.test(password)) {
        messages.push('1 special character');
      }
  
      if (messages.length > 0) {
        response = {
          status: false,
          message: 'Password must contain at least : ' + messages.join(', '),
        };
      } else {
        response = {
          status: true,
          message: '',
        };
      }
  
      return response;
    } catch (error) {
      throw new Error('Get Password Validate Error in utility :: ' + error);
    }
  }
  

  getSplitValidation(data: string) {
    try {
      let response = { status: false, message: 'This field is required' };
      const splitvalue = data.split(',');
      if (splitvalue.length >= 1 && splitvalue[0] !== '') {
        response = { ...response, status: true, message: '' };
      }

      return response;
    } catch (error) {
      throw new Error('getSplitformValidation error :: ' + error);
    }
  }

  getBudgetisValid(data: string) {
    let response = { status: false, message: 'This field is required.' };
    try {
      if (data.length > 0) {
        response.status = true;
        response.message = '';
      }

      return response;
    } catch (err) {
      throw new Error(`getBudgetIsValid Error :: ${err}`);
    }
  }

  getdescriptionIsValid(data: string) {
    try {
      let status = { status: false, message: 'This field is required.' };
      let validate = data.replace(/(<([^>]+)>)/gi, '').length;
      if (validate > 5) {
        status.status = true;
        status.message = '';
      }

      return status;
    } catch (error) {
      throw new Error(`get description Is Valid :: ${error}`);
    }
  }

  getgstIsValid(gst_no: string) {
    const gst_regex = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    if (gst_no) {
      if (gst_regex.test(gst_no) === true) {
        return { status: true, message: '' };
      } else {
        return { status: false, message: 'Enter a valid gst number' };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  getPANisValid(pan_no: string): ErrorTypes {
    const pan_regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
    if (pan_no) {
      if (pan_regex.test(pan_no) === true) {
        return { status: true, message: '' };
      } else {
        return { status: false, message: 'Please enter valid PAN' };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  getAadhaarisValid(aadhaar_no: string): ErrorTypes {
    const aadhaar_regex = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/);
    if (aadhaar_no) {
      if (aadhaar_regex.test(aadhaar_no) === true) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter valid Aadhaar no',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  GetShopNameValidate(shopname: any): ErrorTypes {
    if (shopname) {
      if (shopname.length > 2) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter valid shop name',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  GetShopDescriptionValidate(shop_desc: any): ErrorTypes {
    if (shop_desc) {
      if (shop_desc.length > 2) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter valid description name',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  GetClanShopAddressValid(name: string, type: string): ErrorTypes {
    if (name) {
      if (name.length > 1) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: `Please enter valid ${type}`,
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  GetPincodeValid(pincode: string): ErrorTypes {
    const pincode_regex = new RegExp(/^[1-9][0-9]{5}$/);

    if (pincode) {
      if (pincode_regex.test(pincode) === true) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: `Please enter valid pincode`,
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }
  GetQuantity(quantity: number): ErrorTypes {
    if (quantity !== undefined && quantity !== null) {
      if (quantity >= 1) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Quantity must be greater than or equal to 1.',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }
  GetDiscount(discount: number | string): ErrorTypes {
    // Convert the discount to a number if it's a string
    const discountValue =
      typeof discount === 'string' ? parseFloat(discount) : discount;
    if (isNaN(discountValue)) {
      return {
        status: true,
        message: `Percentage must be a valid number`,
      };
    }

    if (discountValue < 0 || discountValue >= 100) {
      return {
        status: true,
        message: `Percentage must be between 0 and 99.99`,
      };
    }

    // Check if there are more than two decimal places
    const decimalPlaces = (discountValue.toString().split('.')[1] || '').length;
    if (decimalPlaces > 2) {
      return {
        status: true,
        message: `Percentage can have up to two decimal places`,
      };
    }

    return { status: false, message: '' };
  }
  // For any Select Field Valdation (Required).................
  GetSelectFieldValidate(value: string): ErrorTypes {
    if (value.length == 0) {
      return { status: false, message: 'This field is required' };
    } else {
      return {
        status: true,
        message: '',
      };
    }
  }

  IsNumber(value: string | number): ErrorTypes {
    let status = { status: false, message: 'This field is required' };
    if (/^[0-9\b]+$/.test(value.toString()) || value.toString().length === 0) {
      // return value;
      status.status = true;
      status.message = '';
    } else if (value.toString()) {
      status.status = false;
      status.message = 'Invalid value provided';
    }

    return status;
  }
  GetLongTextValidate(name: string): ErrorTypes {
    if (name.trim()) {
      if (name.length > 5) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter atleast 5 characters........',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  GetRequiredEntry(value: string): ErrorTypes {
    if (value) {
      return { status: true, message: '' };
    } else {
      return {
        status: false,
        message: 'Please enter atleast 5 characters........',
      };
    }
  }

  isValidURL(url: string): boolean {
    // Regular expression to check URL syntax
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // Protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'); // Query string

    return urlPattern.test(url);
  }
  // FOR INSTAGRAM AND YOUTUBE VALIDATIONS--------------------------------


  CheckInstagramUrl(value: string): ErrorTypes {
    const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/(p\/[^/?#&]+\/?|reel\/[^/?#&]+\/?|p\/[^/?#&]+\/tv\/[^/?#&]+\/?|\w+\/?)([?#].*)?$/;
    if (value.trim().length == 0) {
      return { status: false, message: "Post link can't be blank!" };
    }
    else if (instagramRegex.test(value)) {
      return { status: true, message: '' };
    } else {
      return {
        status: false,
        message: 'Please enter valid instagram url',
      };
    }
  }

  CheckYouTubeUrl(value: string): ErrorTypes {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)\S+$/;
    if (value.trim().length == 0) {
      return { status: false, message: "Post link can't be blank!" };
    }
    else if (youtubeRegex.test(value)) {
      return { status: true, message: '' };
    } else {
      return {
        status: false,
        message: 'Please enter valid youtube url',
      };
    }
  }

  isValidIfsc(value: string): ErrorTypes {
    const IfscRegex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    if (value == null) {
      return { status: false, message: 'IFSC Code can not be blank' }
    }
    if (IfscRegex.test(value) == true) {
      return { status: true, message: '' }
    }
    else {
      return { status: false, message: 'IFSC Code is Invalid !' };
    }
  }


  GetWebsiteValidate(url: string): ErrorTypes {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3})|' + // OR IP (v4) address
      'localhost)' + // OR localhost
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
      '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
    );
  
    if (url.trim()) {
      if (urlPattern.test(url)) {
        return { status: true, message: '' };
      } else {
        return {
          status: false,
          message: 'Please enter a valid URL',
        };
      }
    } else {
      return { status: false, message: 'This field is required' };
    }
  }

  GetInstagramUsernameValidate(username: string): ErrorTypes {
    const pattern = new RegExp(/^(?=[a-zA-Z0-9._]+$)(?!.*\.\.)(?!.*\.$)[^\s]{0,29}$/);
  
    if (username) {
      if (pattern.test(username)) {
        return { status: true, message: '' };
      } else {
        return { status: false, message: 'Please enter a valid username.' };
      }
    } else {
      return { status: false, message: 'Username cannot be empty.' };
    }
  }
  
  
  GetYoutubeChannelIDValidate(channelId: string): ErrorTypes {
    const pattern = new RegExp(/^UC[a-zA-Z0-9_-]{22}$/);
  
    if (channelId) {
      const isValid = pattern.test(channelId.trim());
      if (isValid) {
        return { status: true, message: '' };
      } else {
        return { status: false, message: 'Please enter a valid YouTube channel ID.' };
      }
    } else {
      return { status: false, message: 'Channel ID cannot be empty.' };
    }
  }
  
  
  GethandlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    setter: (value: string) => void,
    restrictionType: 'number' | 'char' | 'nospace' | 'email' | 'phone' | 'emailOrNumber' = 'number', // Default is 'number' restriction
    additionalHandler?: (value: string) => void
  ) => {
    e.preventDefault(); // Prevent the default paste behavior
    
    let pastedData: string = "";

    // Check if clipboardData is available, fallback to execCommand for legacy support
    if (e.clipboardData) {
      pastedData = e.clipboardData.getData("text"); // Standard clipboard API
    } else {
      console.error("Clipboard data not available");
      return; // Return early if clipboard data is not available
    }

    let cleanedData = pastedData;

    // Handle different restriction types
    switch (restrictionType) {
      case 'number':
        // Remove all non-numeric characters
        cleanedData = cleanedData.replace(/\D/g, '');
        break;

      case 'char':
        // Remove anything that isn't a letter
        cleanedData = cleanedData.replace(/[^a-zA-Z]/g, '');
        break;

      case 'nospace':
        // Remove leading and trailing spaces
        cleanedData = cleanedData.trim();
        break;

      case 'email':
        // For email: remove spaces from start and end
        cleanedData = cleanedData.trim();
        // Further complex validation can be done here for valid email format
        break;
      case 'phone':
        cleanedData = cleanedData.replace(/\D/g, ''); // Remove non-numeric characters
        cleanedData = cleanedData.slice(0, 10); // Limit to 10 characters
        break;
      case 'emailOrNumber':
        cleanedData = cleanedData.trim().replace(/\s+/g, ''); // Remove spaces
        break;

      default:
        console.error("Invalid restriction type provided.");
        return;
    }
    // Set the cleaned data to state
    setter(cleanedData);

    // Call additional handler if provided
    if (additionalHandler) {
      additionalHandler(cleanedData);
    }

    // Ensure the cleaned data is processed correctly after a short delay
    setTimeout(() => {
      setter(cleanedData); // Ensure that cleaned data is correctly handled
    }, 0);
  };


  GetHandleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    setter: (value: string) => void,
    currentValue: string,
    restrictionType: 'number' | 'char' | 'nospace' | 'email' | 'emailOrNumber' = 'number', // Default to 'number'
    setError?: (error: ErrorTypes) => void
  ) => {
    const key = e.key;
    const isCtrlOrCmd = e.ctrlKey || e.metaKey;
  let errorMessage = '';
    // Allow standard shortcuts like Ctrl+C, Ctrl+V, Ctrl+A, etc.
    if (isCtrlOrCmd) {
      // Allow copy, paste, cut, and select all
      if (['c', 'v', 'x', 'a'].includes(key.toLowerCase())) {
        return; // Do not block these actions
      }
    }

    switch (restrictionType) {
      case 'number':
        // Prevent non-numeric keys except Backspace, Delete, and navigation keys
        if (/[^0-9]/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
          e.preventDefault();
          errorMessage = 'Only numeric values are allowed.';
        }
        break;

      case 'char':
        // Prevent non-alphabetic keys except Backspace, Delete, and navigation keys
        if (/[^a-zA-Z]/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
          e.preventDefault();
          errorMessage = 'Only alphabetic values are allowed.';
        }
        break;

      case 'nospace':
        // Prevent the space key
        if (key === ' ') {
          e.preventDefault();
          errorMessage = 'Spaces are not allowed.';
        }
        break;
      case 'email':
        // Allow characters valid in email addresses
        const allowedCharactersRegex = /^[a-z0-9._%$&*=^|~#'`?{}+@-]$/i; // Allow common email characters
        if (
          !allowedCharactersRegex.test(key) && // Block invalid keys
          !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key) // Allow navigation and edit keys
        ) {
          e.preventDefault();
          errorMessage = 'Invalid character for an email address.';
        }
        break;
      case 'emailOrNumber':
        const emailOrNumberRegex = /^[a-z0-9@._-]$/i; // Valid for email and numbers
        if (!emailOrNumberRegex.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
          e.preventDefault();
          errorMessage = 'Only valid email or numeric values are allowed.';
        }
      break;

      default:
        console.warn('Invalid restriction type in GetHandleKeyDown');
    }

    if (setError) {
      if (errorMessage) {
        setError({ status: false, message: errorMessage });
      } else {
        setError({ status: true, message: '' }); // Clear error if valid
      }
    }
    // Optionally trim value on Enter key
    if (key === 'Enter') {
      setter(currentValue.trim());
    }
  };


}
