import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';

const http = new HttpService();

export class RemoveDeleteService {
    async removeAccessManageUser(data: any): Promise<any> {
        try 
        { 
            const path = BASE_URL + "/remove-access-manage-user";
            const response = await http.postRequest(path, data, null);
            return response;
        } 
        catch (error) {
            throw new Error(`Remove Access Manage User :: ${error}`);
        }
    }
}