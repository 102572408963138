import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';

interface Props {
  id: string;
  dataSource?: any[];
  title?: string;
  name?: any,
  data?: any,
  label?: any,
  barWidth?: number,
  height?: string,
  legend?: boolean
}


export const ColumnChart: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    const seriesData = props?.dataSource?.map((range: any) => {
      return {
        name: range?.name,
        type: range?.type,
        groupPadding: range?.groupPadding,
        color: range?.color,
        data: range?.data.map((item: any) => ({
          rangeName: item?.name,
          y: item?.value,
        })),
      };
    });

    const chartOptions: Highcharts.Options = {
      chart: {
        type: "column",
        borderRadius: 4,
        height: props.height || '260px',
      },
      title: {
        text: props.title,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        labels: {
          style: {},
        },
        categories: props?.label, // Use the extracted categories here
        crosshair: true,
      },
      yAxis: {
        labels: {
          style: {},
          y: 10,
        },
        min: 0,
        gridLineWidth: 0,
        title: {
          text: "",
          style: {},
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: props.barWidth || 0.2, //Adjust in points , number ex //0.2 is default  
          borderWidth: 0,
        },
      },
      legend: {
        y: 2,
        x: -4,
        enabled: props.legend
      },
      series: seriesData,
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              //'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              //'downloadSVG',
              'downloadCSV',
              'downloadXLS',
            ]
          }
        }
      }

    };

    Highcharts.chart(props.id, chartOptions);

  }, [props.data, props.dataSource, props.title, props.id]);

  return (
    <div id={props.id} style={{ width: '100%', height: props.height || '260px' }}></div>
  );
};
