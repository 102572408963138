import React, { useState, useEffect, SyntheticEvent, useRef } from "react";
import "./brand-data.scss";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import {
  LocalService,
  ListService,
  PublicService,
} from "../../../core/services";
import { Grid, TableCell, TableRow } from "@mui/material";
import { Search } from "@mui/icons-material";
import InstagramIcon from "../../../components/icons/instagram-icon";
import { BrandDataService } from "../../../core/services/brand-data.service";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { PieChart, StockChart } from "../../../components/charts";
import { SortingColumnsType, TableComponent } from "../../../components/table-component/table";
import { Methods } from "../../../core/utility";
import { DefaultUserProfile } from "../../../config/config";
import { Card } from "../../../components";
const localService: LocalService = new LocalService();
const brandDataService: BrandDataService = new BrandDataService();
const methods = new Methods();


interface Props {
  UserReducer: any;
  loading: (data: any) => void;
}

const BrandsData: React.FC<Props> = (props: Props) => {
  const [searchValue, setSearchValue] = useState("")
  const [searchList, setSearchList] = useState<any>([]);
  const [brandDetails, setBrandDetails] = useState<any>([]);
  const [brandName, setBrandName] = useState("")
  const [mediaPostPieChartData, setMediaPostPieChartData] = useState<any[]>([]);
  const [mediaTypePieChartData, setMediaTypePieChartData] = useState<any[]>([]);
  const [subsGrowthStockChartData, setSubsGrowthStockChartData] = useState<any[]>([]);
  const [renderData, setRenderData] = useState<any>([]);
  const [brandInfluencerData, setBrandInfluencerData] = useState<any>([]);
  const [recommendedProfilesData, setRecommendedProfilesData] = useState<any>([])
  
  const pieChartData = [
    { name: "Data-1", y: 72, color: "#5da5e0" },
    { name: "Data-2", y: 36, color: "#e05d93" },
    { name: "Data-3", y: 36, color: "#e58d93" },

  ];

  const columnDisplayName = {
    full_name: "Name",
    username: "Username",
    total_shortcode: "Posts",
    total_likes: "Total Likes",
    total_comments: "Total Comments",
    total_video_views: "Total Video Views",
    total_video_plays: "Total Video Plays",
    total_media_count: "Total Media",
    followers_count: "Followers",
    engagement_rate: "ER",
  };

  const sortingColumnType: SortingColumnsType[] = [
    {
      name: "full_name",
      columnSize: "180px",
    },
    {
      name: "username",
      columnSize: "120px",
    },
    {
      name: "total_shortcode",
      columnSize: "100px",
    },
    {
      name: "total_likes",
      columnSize: "100px",
    },
    {
      name: "total_comments",
      columnSize: "100px",
    },
    {
      name: "total_video_views",
      columnSize: "100px",
    },
    {
      name: "total_video_plays",
      columnSize: "100px",
    },
    {
      name: "total_media_count",
      columnSize: "100px",
    },
    {
      name: "followers_count",
      columnSize: "120px",
    },
    {
      name: "engagement_rate",
      columnSize: "120px",
    }
  ];


  // Function to convert date strings to Date.UTC format
  const convertToUTC = (dateString: any) => {
    try {
      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0], 10);
      const monthIndex = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      return Date.UTC(year, monthIndex, day);
    }
    catch (error) {
      console.error("Error converting date to UTC:", error);
    }
  }

  //Function To Search The Brands
  const handleSearchBar = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = target.value.toLowerCase();
    setSearchValue(inputValue);

    // Early return if inputValue is empty
    if (!inputValue || inputValue.length < 3) {
      setSearchList([]);
      return;
    }

    try {
      const response = await brandDataService.getBrandSearch(inputValue);
      if (response.data.status.status) {
        setSearchList(response.data.rows.brand_search);
      }
    } catch (error) {
      console.log(`getBrandSearch error :: ${JSON.stringify(error)}`);
    }
  };

  //Function To Get The Brand Details  
  const handleBrandData = async (selectedRefId: any) => {
    try {
      const response = await brandDataService.getBrandData(selectedRefId);
      if (response.data.status.status) {
        setBrandDetails(response.data.rows);
        setBrandName(response.data.rows.profile_er[0].insta_name)
        setSearchList([])

      }
      //Video and Image Post Split Data
      const mediaPostData = response?.data?.rows?.media_type_post_split;
      if (Array.isArray(mediaPostData)) {

        let imagePost;
        mediaPostData.forEach(item => { imagePost = item.image_post_count });
        let videoPost;
        mediaPostData.forEach(item => { videoPost = item.video_post_count });


        const mediaPostCData = [
          { name: "Images", y: imagePost, color: "#6B9FE7" },
          { name: "Videos", y: videoPost, color: "#57E4D8" },
        ];

        setMediaPostPieChartData(mediaPostCData)
      } else {
        localService.toastify(response.data.status.message, "info", 1000);
      }

      //Organic , Paid , Paid+Sponsor & Organic+Sponsor Data
      const mediaTypeData = response?.data?.rows?.media_type_data;
      if (Array.isArray(mediaTypeData)) {
        // let paidData = (mediaTypeData[0]?.paid_count > 0) ? mediaTypeData[0].engagement_rate : 0;
        // let paidSponsorData = (mediaTypeData[1]?.paid_sponsor_count > 0) ? mediaTypeData[1].engagement_rate : 0;
        // let organicData = (mediaTypeData[2]?.organic_count > 0) ? mediaTypeData[2].engagement_rate : 0;
        // let organicSponsorData = (mediaTypeData[3]?.organic_sponsor_count > 0) ? mediaTypeData[3].engagement_rate : 0;

        // let paidData = mediaTypeData[0]?.paid_count > 0 ? mediaTypeData[0].engagement_rate : 0;
        // let paidSponsorData = mediaTypeData[1]?.paid_sponsor_count > 0 ? mediaTypeData[1].engagement_rate : 0;
        // let organicData = mediaTypeData[2]?.organic_count > 0 ? mediaTypeData[2].engagement_rate : 0;
        // let organicSponsorData = mediaTypeData[3]?.organic_sponsor_count > 0 ? mediaTypeData[3].engagement_rate : 0;

        let paidData = mediaTypeData[0]?.engagement_rate || 0;
        let paidSponsorData = mediaTypeData[1]?.engagement_rate || 0;
        let organicSponsorData = mediaTypeData[2]?.engagement_rate || 0;
        let organicData = mediaTypeData[3]?.engagement_rate || 0;

        const mediaTypeCData = [
          { name: "Paid", y: paidData, color: "#bfd8cb" },
          { name: "Paid + Sponsor", y: paidSponsorData, color: "#faae88" },
          { name: "Organic", y: organicData, color: "#b1b2b5" },
          { name: "Organic + Sponsor", y: organicSponsorData, color: "#fcd6c5" },
        ];

        setMediaTypePieChartData(mediaTypeCData)
      } else {
        localService.toastify(response.data.status.message, "info", 1000);
      }

      //Subscriber Growth Data  
      const subsGrowthData = response?.data?.rows?.subscriber_growth_chart;
      if (Array.isArray(subsGrowthData)) {

        // let minView: any = minViewData.map(item => item.estimated_min)
        let growthView: any = subsGrowthData.map(item => ([convertToUTC(item.created_at), item.followers_count]));

        const growthStockChartData = [
          {
            name: 'Followers',
            data: growthView
          }

        ]
        setSubsGrowthStockChartData(growthStockChartData)
      } else {
        localService.toastify(response.data.status.message, "info", 1000);
      }

      //Influencer Engaged with Brand 
      const influencersWithBrand = response?.data?.rows?.influencer_engaged

      if (Array.isArray(influencersWithBrand)) {
        setBrandInfluencerData(response.data.rows.influencer_engaged)
      }

      //Recommended Profiles
      const recommendedProfiles = response?.data?.rows?.recommended_profile
      if (Array.isArray(recommendedProfiles)){
        setRecommendedProfilesData(response.data.rows.recommended_profile)
      }


    } catch (error) {
      console.log(`getBrandData error :: ${JSON.stringify(error)}`);
    }

  };


  const renderProfileListItem = (item: any) => {
    const refId = item?.ref_id
    const handleClick = () => {
      handleBrandData(refId);
    };

    return (
      <li className="insta-search-list" key={refId}>
        <div className="col">
          <div className="d-flex flex-wrap align-items-center">
            <Link to="javascript:void(0);" >
              <InstagramIcon width={18} height={24} />
            </Link>
            <strong>
              <Link to="javascript:void(0);" className="insta-username insta-search-username mx-2" onClick={handleClick} >
                {item?.username}
              </Link>
            </strong>
            <Link to="javascript:void(0);" className="ms-auto" style={{ color: 'black' }} onClick={handleClick}>
              <LaunchIcon fontSize="small" sx={{ height: 'auto' }} />
            </Link>
          </div>
        </div>
        <p>
          <Link to="javascript:void(0);" style={{ textDecoration: 'none', color: 'black' }} onClick={handleClick}>
            {item?.insta_name}
          </Link>
        </p>
      </li>
    );
  };

  return (
    <>
      <div className="brands-data-container">
        <div className="brands-data-main m-5">
          <div className="my-auto">
            <div className="search-container-content" >
              <InstagramIcon
                className="search-plt-type-icon icon-instagram"
                width={20}
                height={20}
              />

              <form className="d-flex" >
                <input
                  type="search"
                  placeholder="Search by name or username"
                  className="form-control"
                  id="brand-search-by-name"
                  onChange={handleSearchBar}

                />

                <button
                  type="button"
                  className="btn d-flex btn-search"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <Search />
                </button>
              </form>
              {
                searchList.length > 0 && (
                  <div className="search-suggestion">
                    <ul>
                      {searchList.slice(0, 10).map((item: any) => (renderProfileListItem(item)))}
                    </ul>
                  </div>
                )
              }
            </div>
            <div className="brand-name mt-2 ">
              <span className="brand-name" >{brandName}</span>
            </div>
          </div>
          <Grid container spacing={2} className="mt-2">
            {brandDetails?.post_frequency?.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4} md={6}>
                  <div className='run-camp-card brand-card d-flex'>
                    <p>
                      <strong>Post Frequency</strong>
                    </p>
                    {item.post_frequency ? (
                      <span>{Math.round(item?.post_frequency)} / Week</span>
                    ) : (<span>No Data Available</span>)}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <div className='run-camp-card brand-card d-flex'>
                    <p>
                      <strong>Engagement Rate</strong>
                    </p>
                    {brandDetails?.profile_er[index] ? (
                      <span>{Math.trunc(brandDetails?.profile_er[index]?.engagement_rate)}%</span>
                    ) : (<span>No Data Available</span>)}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <div className='run-camp-card brand-card d-flex'>
                    <p>
                      <strong>Paid Content Frequency</strong>
                    </p>
                    {brandDetails?.paid_frequency[index] ? (
                      <span>{Math.round(brandDetails?.paid_frequency[index]?.paid_content_frequency)} / Week</span>
                    ) : (<span>No Data Available</span>)}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <div className='run-camp-card brand-card d-flex'>
                    <p>
                      <strong>Organic Content Frequency</strong>
                    </p>
                    {brandDetails?.organic_frequency[index] ? (
                      <span>{Math.round(brandDetails?.organic_frequency[index]?.organic_content_frequency)} / Week</span>
                    ) : (<span>No Data Available</span>)}
                  </div>
                </Grid>

              </React.Fragment>
            ))}
          </Grid>

          <Grid container spacing={2} className="mt-2">
            <Grid item xs={12} sm={4} md={6}>
              <div className="audience-type-wrapper run-camp-card">
                <div
                  className="section-header brand-chart sml"
                  style={{ paddingTop: 10, margin: 0 }}
                >
                  <strong>Image & Video Split</strong>

                </div>
                <PieChart
                  dataSource={mediaPostPieChartData}
                  showInLegend={true}
                  title=""
                  layout="vertical"
                  align={'right'}
                  innerSize={'40%'}
                  verticalAlign={'middle'} />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              <div className="audience-type-wrapper run-camp-card">
                <div
                  className="section-header brand-chart sml"
                  style={{ paddingTop: 10, margin: 0 }}
                >
                  <strong>ER of Organic , Paid , Paid+Sponsor & Organic+Sponsor</strong>

                </div>
                <PieChart dataSource={mediaTypePieChartData} showInLegend={true} title="" layout="vertical" align={'right'} verticalAlign={'middle'}/>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className="run-camp-card mt-5 mb-5">
                <div
                  className="section-header brand-chart sml"
                  style={{ paddingTop: 10, margin: 0 }}
                >
                  <strong>Followers Growth</strong>
                </div>
                <div className="col-md-12">
                  <StockChart dataSource={subsGrowthStockChartData} title="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className="mb-2"><strong>Engaged Influencers : </strong><span><strong>{brandInfluencerData[0]?.total_rows ? brandInfluencerData[0]?.total_rows : "NA"}</strong></span></div>
              <TableComponent
                pagination={true}
                columnDisplayName={columnDisplayName}
                inputDataSource={brandInfluencerData}
                orderByColumnName=""
                sortingColumnType={sortingColumnType}
                renderData={renderData}
                setRenderData={(e: any) => setRenderData(e)}
              >
                {renderData.map((data: any, index: number) => {
                  return (
                    <TableRow key={index} >
                      <TableCell className="d-flex align-items-center"><div><img src={DefaultUserProfile?.influencer_unknown_default} style={{
                        width: '45px', height: '45px', borderRadius: '50%', marginRight:
                          '10px', display: 'inline-flex',
                        border: '1px solid rgba(0, 0, 0, 0.4)'
                      }} /></div><div>{data.full_name}</div></TableCell>
                      <TableCell>{data.username}</TableCell>
                      <TableCell>{data.total_shortcode}</TableCell>
                      <TableCell>{methods.formatInfluencerProfileNumber(data.total_likes)}</TableCell>
                      <TableCell>{methods.formatInfluencerProfileNumber(data.total_comments)}</TableCell>
                      <TableCell>{methods.formatInfluencerProfileNumber(data.total_video_views)}</TableCell>
                      <TableCell>{methods.formatInfluencerProfileNumber(data.total_video_plays)}</TableCell>
                      <TableCell>{methods.formatInfluencerProfileNumber(data.total_media_count)}</TableCell>
                      <TableCell>{methods.convertNumber(data.followers_count)}</TableCell>
                      <TableCell>{data.engagement_rate.toFixed(2)} %</TableCell>
                    </TableRow>
                  );
                })}
              </TableComponent>
            </Grid>
          </Grid>
          <div className="mb-3"><strong>Recommended Profiles : </strong><span><strong>{recommendedProfilesData[0]?.total_rows ? recommendedProfilesData[0]?.total_rows : "NA"}</strong></span></div>
          <div className="recommended-card row">
            {recommendedProfilesData.map((data: any, index: number) => {
              return (
                <div className="col-3">
                  <Card className="recommended-profiles">
                    <div className="d-flex">
                    <img src={data?.gcp_profile_pic_url ? data?.gcp_profile_pic_url : DefaultUserProfile?.influencer_unknown_default}
                      alt={data?.full_name} style={{
                        width: '45px', height: '45px', borderRadius:
                          '50%', marginRight: '10px', display: 'inline-flex', border:
                          '1px solid rgba(0, 0, 0, 0.4)'
                      }} />
                    <div className="recommended-username d-flex flex-column">
                      <span>
                        <strong>
                          {data.full_name}
                        </strong>
                      </span>
                      <Link className="influ-prof-info-social-chan text-decoration-none" to={`https://www.instagram.com/${data
                        && data?.username}`} target="_blank">
                        <span>
                          {data.username}
                        </span>
                      </Link>
                    </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(BrandsData);

export { connectToRedux as BrandsData };
