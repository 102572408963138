import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { LocalService, PaymentGatewayService } from '../../core/services';
import { ClanShopCreateOrder } from 'custom-type';
import useRazorpay, { RazorpayOptions } from 'react-razorpay';
import { PAYMENT_GATEWAY_KEY } from '../../config/config';
import { connect, useDispatch } from 'react-redux';
import './user-place-order.scss';
import { toast } from 'react-toastify';
import { AnyCnameRecord } from 'dns';
import { SET_CART } from '../../redux/types';
import { setGuestUser } from '../../redux/action';
import { useCart } from '../../hooks/useCart';
import { AffiliateService } from '../../core/services/affiliate.service';
import Cookies from 'js-cookie';

const paymentGatewayService = new PaymentGatewayService();
const localService = new LocalService();
const affiliateService = new AffiliateService();

interface Props {
    UserReducer: any;
    loading?: (data: any) => void;
    CartReducer: any;
    totalCost: any;
}

const UserPlaceOrder: React.FC<Props> = (props: Props) => {
    
    useCart();
    const location = useLocation();
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [cartData, setCartData] = useState([]);
    const searchParams = new URLSearchParams(location.search);

    const shop_id = Number(searchParams.get('shop_id'));
    const product_id = Number(searchParams.get('product_id'));
    const user_id = Number(searchParams.get('user_id'));
    const address_id = Number(searchParams.get('address_id'));
    const cart = searchParams.get('cart');
    const dispatch = useDispatch();
    const updatedCart = props?.CartReducer?.cartList?.filter((prodInventory:any) => {
        return prodInventory.inventory !== 0 
    })
    useEffect(() => {
   
        // Load cart data from localStorage
        // const savedCart = localService.getStorageItem('cart');
        // if (savedCart) {
        //     // Parse and dispatch the cart data to Redux
        //     const parsedCart = JSON.parse(savedCart);
        //     dispatch({ type: SET_CART, payload: parsedCart });
        // }
        ClanShopService.getOrderPlaceDetails(
            shop_id,
            product_id,
            user_id,
            address_id,
            cart
        )
            .then((res) => {
                if (res?.data?.rows) {
                    setOrderDetails(res?.data?.rows);
                    const cookieValue:any = Cookies.get('cc_affiliate');
                    if(JSON.parse(cookieValue)?.cct_id){
                        affiliateService.affiliateCheckout({cct_id:JSON.parse(cookieValue)?.cct_id, shop_id:shop_id, product_id:product_id}).then((res)=> {
                            // console.log("response",res);
                        }).catch((error) => console.log("errorr", error));
                    }

                }
            })
            .catch((error) => {
                setOrderDetails([]);
            });
        // const savedCart = localStorage.getItem('cart');
        // if (savedCart) {
        //     // Parse and dispatch the cart data to Redux
        //     const parsedCart = JSON.parse(savedCart);
        //     dispatch({ type: SET_CART, payload: parsedCart });
        // }

        setCartData(props?.CartReducer?.cartList);
        const guest_user_login: any =
            localService.getStorageItem('guest_user_login');
        if (guest_user_login) {
            dispatch(setGuestUser(true));
        }
    }, []);

    async function createOrder(orderDetail: ClanShopCreateOrder) {
        try {
            const response: any =
                await paymentGatewayService.clanShopCreateOrder(orderDetail);
            return response;
        } catch (error) {
            throw new Error(`CreaterOrder`);
        }
    }

    async function updateOrderDetail(payment_id: string) {
        try {
            const response =
                await paymentGatewayService.clanShopUpdateOrderDetail({
                    user_id: user_id,
                    razorpay_payment_id: payment_id,
                    order_details: orderDetails,
                });
            if (response?.data?.status?.status) {
                toast.success(
                    `${response?.data?.status?.status}. Order Booked Successfully`
                );
                ClanShopService.getOrderDetails(payment_id).then((res: any) => {
                    if (res?.data?.rows) {
                        // track payment status
                        const options = {
                            "id": res?.data?.rows[0]?.id,
                            "name": 'Product Purchase',
                            "customer_name": res?.data?.rows[0]?.name,
                            "customer_email": res?.data?.rows[0]?.email,
                            "customer_phone": res?.data?.rows[0]?.mobile,
                            "order_id": res?.data?.rows[0]?.order_id,
                            "order_status": res?.data?.rows[0]?.status,
                            "captured": res?.data?.rows[0]?.captured,
                            "payment_id": res?.data?.rows[0]?.payment_id,
                            "price": (res?.data?.rows[0].amount/100 - res?.data?.rows[0]?.tax),
                            "total_price": res?.data?.rows[0].amount/100,
                            "tax": res?.data?.rows[0]?.tax
                        }
                        if(window.cctag){
                            window.cctag('event', 'purchase', {
                                'cct_id': '',
                                'payment_id': res?.data?.rows[0].payment_id,
                                "order_id": res?.data?.rows[0].order_id,
                                "captured": res?.data?.rows[0].captured,
                                "affiliation": "ClanConnect2.0 Purchase Confirmation",
                                "value": res?.data?.rows[0].amount/100,
                                "currency": "INR",
                                "tax": res?.data?.rows[0].tax,
                                "items": JSON.stringify(options)
                            });
                            // window.cctagScript();
                        }
                        navigate(`/clanshop/product-details/place-order/order-status?payment_id=${payment_id}&status=${response?.data?.status?.status}`)
                    }
                }).catch((err: any) => { console.log('error:', err) });

                    // `/clanshop?shop_id=${
                    //     shop_id
                    //         ? shop_id
                    //         : JSON.parse(
                    //               props?.CartReducer?.cartList[0]?.shop_id
                    //           )
                    // }`
                // );
            }else {
                localService.toastify(`${response?.data?.status?.status}. Order Failed`, 'error', 1000);
                ClanShopService.getOrderDetails(payment_id).then((res: any) => {
                    if (res?.data?.rows) {
                        const options = {
                            "id": res?.data?.rows[0]?.id,
                            "name": 'Product Purchase',
                            "customer_name": res?.data?.rows[0]?.name,
                            "customer_email": res?.data?.rows[0]?.email,
                            "customer_phone": res?.data?.rows[0]?.mobile,
                            "order_id": res?.data?.rows[0]?.order_id,
                            "order_status": res?.data?.rows[0]?.status,
                            "captured": res?.data?.rows[0]?.captured,
                            "payment_id": res?.data?.rows[0]?.payment_id,
                            "price": (res?.data?.rows[0].amount/100 - res?.data?.rows[0]?.tax),
                            "total_price": res?.data?.rows[0].amount/100,
                            "tax": res?.data?.rows[0]?.tax
                        }
                        if(window.cctag){
                            window.cctag('event', 'purchase', {
                                'cct_id': '',
                                'payment_id': res?.data?.rows[0].payment_id,
                                "order_id": res?.data?.rows[0].order_id,
                                "captured": res?.data?.rows[0].captured,
                                "affiliation": "ClanConnect2.0 Purchase Confirmation",
                                "value": res?.data?.rows[0].amount/100,
                                "currency": "INR",
                                "tax": res?.data?.rows[0].tax,
                                "items": JSON.stringify(options)
                            });
                            // window.cctagScript();
                        }
                    }
                }).catch((err: any) => { console.log('error:', err) });
            }
        } catch (error) {
            throw new Error(`Update Order Detail Error :: ${error}`);
        }
        // finally {
        //   getSubscriptionDetail();
        // }
    }
    const handlePlaceOrder = async (data: any) => {
        let paymantStatus = {};
        setLoading(true);
        try {
            //First Create order
            let _CreateOrder;
            if (cart) {
                _CreateOrder = await createOrder({
                    cart_details: updatedCart,
                    shop_id: Number(shop_id),
                    product_id: product_id,
                    user_id: user_id,
                    address_id: address_id,
                    orderDetail: {
                        amount: props?.CartReducer?.total + '00',
                        currency: 'INR',
                        notes: {
                            key1: 'LS purchase',
                        },
                    },
                });
            } else {
                _CreateOrder = await createOrder({
                    cart_details: [],
                    shop_id: Number(shop_id),
                    product_id: product_id,
                    user_id: user_id,
                    address_id: address_id,
                    orderDetail: {
                        amount: data?.discounted_price + '00',
                        currency: 'INR',
                        notes: {
                            key1: 'LS purchase',
                        },
                    },
                });
            }
            //if Order is Created the User Make a Payment
            if (_CreateOrder?.data?.status?.status) {
                const RAZORPAY_OPTIONS: RazorpayOptions = {
                    key: PAYMENT_GATEWAY_KEY,
                    amount: _CreateOrder.data.rows.amount_due + '00',
                    currency: 'INR',
                    name: 'ClanConnect',
                    description: 'Test Transaction',
                    // image: Logo,
                    order_id: _CreateOrder.data.rows.id,
                    handler: function (response) {
                        updateOrderDetail(response.razorpay_payment_id);
                    },
                    prefill: {
                        name: data.name,
                        email: data.email,
                        contact: data.mobile,
                    },
                    notes: {
                        address: 'Razorpay Corporate Office',
                    },
                    theme: {
                        color: '#00aae5',
                    },
                };
                const rzpay = new Razorpay(RAZORPAY_OPTIONS);
                rzpay.open();
            } else {
                localService.toastify(_CreateOrder?.data?.status?.message);
            }
        } catch (error) {
            throw new Error(`Make Payment Error :: ${error}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='clanshop-order-now'>
            {orderDetails?.map((data: any) => {
                return (
                    <div className='order-now-content'>
                        <h1 className='order-now-heading'>Order now</h1>
                        <p>
                            Shipping to :{' '}
                            <strong>
                                {data.name}, <br /> {data.house_no}, {data.area}
                                , <br /> {data.post_office}, {data.district},{' '}
                                <br /> {data.city}, {data.state}, <br /> pincode
                                - {data.pincode}
                            </strong>
                        </p>
                        <p>
                            Item:{' '}
                            <strong>
                                {data.product_cost
                                    ? data.discounted_price
                                    : props?.CartReducer?.total}
                            </strong>
                        </p>
                        {/* <p>Delivery: <strong>100</strong></p> */}
                        <p>
                            <strong>Order Total</strong> :{' '}
                            <strong>{props?.CartReducer?.total}</strong>
                        </p>
                        <Button
                            variant='outlined'
                            onClick={() => handlePlaceOrder(data)}
                        >
                            {' '}
                            Place Your Order and Pay
                        </Button>
                    </div>
                );
            })}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        CartReducer: state.CartReducer,
        totalCost: state.CartReducer.totalCost,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setGuestUser: (data: any) => dispatch(setGuestUser(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPlaceOrder);

export { connectToRedux as UserPlaceOrder };
