import React, { useState, useRef } from 'react';
import MaterialSymbol from '../../../components/icons/material-symbol';
import productImage from '../../../assets/images/product-img.png';
import productImage1 from '../../../assets/images/product-img1.png';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

const ProductLinkSection = () => {
  const [showProductLinkInput, setShowProductLinkInput] = useState(false);
  const [customizedImages, setCustomizedImages] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [product, setProduct] = useState({
    title: '',
    link: '',
    image: '',
    buttonTitle: '',
  });
  const [products, setProducts] = useState<any>([]);
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setProduct({ ...product, image: URL.createObjectURL(file) });
    }
  };

  const handleUploadInputChange = (e: any) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };
  
  const handleAddProduct = () => {
    if (product.title && product.link && product.image) {
      setProducts([...products, product]);
      // Clear the input fields after adding the product
      setProduct({ title: '', link: '', image: '', buttonTitle:"" });
    } else {
      alert('Please provide all fields.');
    }
  };
  const handleShowAddProductLink = () => {
    setShowProductLinkInput(true)
    setCustomizedImages(false)
  }
    const handleCustomizedImages = () => {
  setCustomizedImages(true)
  setShowProductLinkInput(false)
  }
  return (
    <div className='product-link-input-container'>
      <div className='product-link-action-btns'>
        <button className='btn btn-outline-primary' onClick={handleShowAddProductLink}>Add product link</button>
        <button className='btn btn-outline-primary' onClick={handleCustomizedImages}>Add customized images with link</button>
      </div>
      {/* Add product link input */}
      {showProductLinkInput && (
        <>
          <span className='d-block pb-2'>Product link</span>
          <div className='product-link-input d-inline-flex gap-2'>
            <input type='text' placeholder='Paste product link' className='form-control' />
            <button className='btn btn-primary btn-sm'>Add link</button>
          </div>
        </>
      )}
       {showProductLinkInput &&
      <div className='linked-product-list-container'>
        {/* PRODUCT 1 */}
        <div className='linked-product'>
          <div className='linked-product-img'>
            <img src={productImage} alt='product' />
          </div>
          <div className='linked-product-detail'>
            <div className='linked-product-detail-left'>
              <strong>Amazon</strong>
              <h2><Link to="">JBL Tune 760NC, Wireless Over Ear Active Noise Cancellation Headphones with Mic...</Link></h2>
            </div>
            <button className='linked-product-delete btn btn-outline-primary'>
              <MaterialSymbol iconName='delete' fontweight='200' />
            </button>
          </div>
        </div>
        {/* PRODUCT 2 */}
        <div className='linked-product'>
          <div className='linked-product-img'>
            <img src={productImage1} alt='product' />
          </div>
          <div className='linked-product-detail'>
            <div className='linked-product-detail-left'>
              <strong>Myntra</strong>
              <h2><Link to="">Men Classic Eau De Toilette 100 ml</Link></h2>
            </div>
            <button className='linked-product-delete btn btn-outline-primary'>
              <MaterialSymbol iconName='delete' fontweight='200' />
            </button>
          </div>
        </div>
      </div>
      }
       {customizedImages &&
      <span className='pb-2 d-block'>Images with buttons</span>
       }
      <div className='d-flex flex-wrap gap-4'>
        {/* Customized Images Section */}
        
        
        {customizedImages &&
            <div className='img-w-btn-container'>
            {/* IMAGES WITH BUTTONS */}
            <div className='img-w-btn-input-sec' style={{position:'relative'}}>
                <div className='img-w-btn-input-img-upload' onClick={handleDivClick}>
                
                {product.image ? (
                <img
                    src={product.image}
                    alt='Uploaded'
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius:'10px 10px 0 0' }}
                />
                ) : (
                <>
                <div className='d-flex flex-column align-items-center' onClick={handleImageUpload} style={{position:'relative', zIndex:'-1'}}>
                    <MaterialSymbol iconName='add_photo_alternate' fontweight='200' />
                    <span>Upload image</span>
                    <input
                        type='file'
                        accept='image/*'
                    
                        style={{ display: 'none' }}
                        id='image-upload'
                    />
                </div>
                    {/* Hidden file input */}
                <input
                type='file'
                accept='image/*'
                ref={fileInputRef}  // Attach the ref to the input
                style={{ display: 'none' }}
                onChange={handleImageUpload}  // Handle image upload when a file is selected
                />
                </>

                )}
            
                {/* <MaterialSymbol iconName='add_photo_alternate' fontweight='200' />
                <span>Upload image</span>
                <IconButton className='upload-image-icon'>
                    <MaterialSymbol className='me-0' iconName='add' fontweight='200' />
                </IconButton> */}
                </div>  
                <input
                type='text'
                placeholder='Enter Title'
                className='form-control'
                name='title'
                value={product.title}
                onChange={handleUploadInputChange}
            />
            <input
                type='text'
                placeholder='Paste Link'
                className='form-control'
                name='link'
                value={product.link}
                onChange={handleUploadInputChange}
            />
            <input
                type='text'
                placeholder='Add Button Title'
                className='form-control'
                name='buttonTitle'
                value={product.buttonTitle}
                onChange={handleUploadInputChange}
            />
            <IconButton className='upload-image-icon' onClick={handleAddProduct}>
                    <label style={{ cursor: 'pointer', lineHeight:1 }}>
                        <MaterialSymbol className='me-0' iconName='add' fontweight='200' />
                    </label>
                    </IconButton>
            </div>
            </div>
            }
      
        {/* Displaying the uploaded products */}
        <div className='product-list'>
            {products.map((prod:any, index:number) => (
            <div key={index} className='product-item'>
                <Link to={prod.link} ><img src={prod.image} alt='Product' style={{ width: '100px', height: '100px' }} /></Link>
                <p className='product-item-title'>{prod.title}</p>
                <button className='btn btn-outline-primary w-100'>
                {prod.link}
                </button>
            </div>
            ))}
        </div>
      </div>

    </div>
  );
};

export default ProductLinkSection;
