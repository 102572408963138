import { Skeleton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DefaultUserProfile } from '../../../config/config';
import InstagramIcon from '../../../components/icons/instagram-icon';
import { Link } from 'react-router-dom';
import { Boy, Girl } from '@mui/icons-material';
import { Methods } from '../../../core/utility';
import MaterialSymbol from '../../../components/icons/material-symbol';
import dayjs from "dayjs";

type Props = {
    ytInfluencerData: any;
    email?:any;
    phone?:any;
    loading: boolean;
    location?:any;
    category?:any;
    language?:any;
    subscription_status?:any;
}

const methods = new Methods();

const YTInfluencerProfileLeft = ({ ytInfluencerData,email,phone,location,category,language,loading,subscription_status}: Props) => {
    const [showMoreText, setShowMoreText] = useState(false)
    const handleMoreText = () => {
        setShowMoreText((prev:boolean) => !prev)
    }
    const dob = ytInfluencerData?.detail?.[0]?.dob;
    const shouldRenderAge = dob && dob !== '1970-01-01';
    return (
        <>
            {/* // FOR DESKTOP VIEW */}
             <div className='influencer-profile__left-inner d-none d-lg-flex'>
                <div className='influencer-profile__image'>
                    {loading ? <Skeleton width={120} height={120} variant='circular' /> : <img
                        src={
                            ytInfluencerData &&
                            ytInfluencerData.profile_detail &&
                            ytInfluencerData.profile_detail.length > 0
                            && ((ytInfluencerData.profile_detail[0].gcp_profile_image_url ? ytInfluencerData.profile_detail[0].gcp_profile_image_url : ytInfluencerData.profile_detail[0].profile_image_url)
                                ||
                                DefaultUserProfile?.influencer_unknown_default)
                        }

                        alt="Influencer Profile"
                        style={{ maxWidth: '130px', height: '130px' }}
                    />
                    }
                    <div className='influencer-profile__image--curve'></div>
                    <div className='influencer-profile__image--curve-left'></div>
                </div>
                <div className='influencer-user__info'>
                    {loading ? <Skeleton height={30} /> : <h3 className="">{ytInfluencerData && ytInfluencerData?.detail?.[0]?.title ? ytInfluencerData && ytInfluencerData?.detail?.[0]?.title : 'NA'}</h3>}
                        <span className='fs-14'>
                        {shouldRenderAge && (
                            <>
                                {`${methods.calculateAge(dob)},`}
                            </>
                        )}
                        {loading ? <Skeleton /> : <>{ytInfluencerData?.detail?.[0]?.gender !== 'Other' && ytInfluencerData?.detail?.[0]?.gender}</>}
                        </span>
                </div>
                <div className='influencer-user__location'>
                    <div className='d-flex'>{subscription_status.status && <p className='d-flex align-items-center'><Tooltip arrow 
                        title={subscription_status.status == 'non_subscribed' ? 'Non - subscriber' : subscription_status.status == 'active' ? 'Active subscription' : 'Expired subscription'}>
                        <span className={`me-3 ms-1 discover_subscription_dot discover_subscription_dot_${subscription_status.status}`}></span></Tooltip></p>
                    }
                    {
                        subscription_status.plan_start_date && 
                        <p className='d-flex align-items-center'>
                            <Tooltip arrow 
                                title='Plan date'><span>
                                {`${dayjs(subscription_status.plan_start_date).format('D MMM YYYY')} - ${dayjs(subscription_status.plan_end_date).format('D MMM YYYY')}`}
                            </span></Tooltip>
                        </p>
                    }
                    </div>
                    {email && email.length > 0 && <p className='d-flex align-items-center'><MaterialSymbol iconName='mail' fontweight='200' color='#929292' iconFilled /> <span className='ms-2'>{email}</span></p>}
                    {(phone != 0 && phone) && <p className='d-flex align-items-center'><MaterialSymbol iconName='call' fontweight='200' color='#929292' iconFilled /><span className='ms-2'>{phone}</span></p>}
                    {location && location.length > 0 && <p className='d-flex align-items-center'><MaterialSymbol iconName='location_on' fontweight='200' color='#929292' iconFilled /><span className='ms-1'>{location}</span></p>}
                </div>
                <div className={`influencer-user__intro ${showMoreText ? "more-text": ""}`}>
                    <p className="mb-0">{loading ? <Skeleton /> : <>{ytInfluencerData?.detail?.[0]?.description !== '' ? ytInfluencerData?.detail?.[0]?.description : 'NA'}</>}</p>
                    {ytInfluencerData?.detail?.[0]?.description && ytInfluencerData?.detail?.[0]?.description.length > 85 && 
                        <button onClick={handleMoreText} className='btn btn-text-link'>{showMoreText ? "less" : "more"}</button>
                    }
                </div>
                <div className='influencer-user__category'>
                    {loading ? <Skeleton /> : 
                    (category[0] || category[1] || category[2] || category[3]) &&
                        <div className='influencer-user__category'>
                            {category[0] && <span>{category[0]}</span>}
                            {category[1] && <span>{category[1]}</span>}
                            {category[2] && <span>{category[2]}</span>}
                            {category[3] && <span>{category[3]}</span>}
                        </div>
                    }
                </div>
                <div className='influencer-user__category'>
                    {loading ? <Skeleton /> : 
                    (language[0] || language[1] || language[2] || language[3]) &&
                        <div className='influencer-user__category'>
                            {language[0] && <span>{language[0]}</span>}
                            {language[1] && <span>{language[1]}</span>}
                            {language[2] && <span>{language[2]}</span>}
                            {language[3] && <span>{language[3]}</span>}
                        </div>
                    }
                </div>
            </div> 

            {/* // FOR MOBILE VIEW */}
            <div className='influencer-profile__left-inner influencer-profile__left-inner-mobile d-lg-none'>
                <div className='influencer-user-profile'>
                    <div className='influencer-profile__image influencer-user-profile-left'>
                        {loading ? <Skeleton width={120} height={120} variant='circular' /> : <img
                            src={
                                ytInfluencerData &&
                                ytInfluencerData.profile_detail &&
                                ytInfluencerData.profile_detail.length > 0
                                && ((ytInfluencerData.profile_detail[0].gcp_profile_image_url ? ytInfluencerData.profile_detail[0].gcp_profile_image_url : ytInfluencerData.profile_detail[0].profile_image_url)
                                    ||
                                    DefaultUserProfile?.influencer_unknown_default)
                            }

                            alt="Influencer Profile"
                        />
                        }
                    </div>
                    <div className='influencer-user-profile-right'>
                        <Link

                            className="influencer-profile__social-chan"
                            target="_blank"
                            to={`https://www.youtube.com/channel/${ytInfluencerData && ytInfluencerData?.detail?.[0]?.channel_id}`}
                        >
                            <span className="influ-prof-info-social-username d-flex justify-content-center align-items-baseline">
                                {loading ? <Skeleton height={30} /> : <h3 className="">{ytInfluencerData && ytInfluencerData?.detail?.[0]?.title ? ytInfluencerData && ytInfluencerData?.detail?.[0]?.title : 'NA'}</h3>}
                            </span>
                        </Link>
                        <div className='influencer-user__info'>
                            {loading ? <Skeleton height={30} /> : <h3>{ytInfluencerData && ytInfluencerData?.profile_detail?.[0]?.insta_name}</h3>}
                            <span className='fs-14'>
                                {ytInfluencerData?.detail?.[0]?.dob ? methods.calculateAge(ytInfluencerData?.detail?.[0]?.dob) : "NA"},{" "}
                                {loading ? <Skeleton /> : <>{ytInfluencerData?.detail?.[0]?.gender}</>}
                            </span>
                            <div className='influencer-user__location'>
                                {subscription_status.status && <p className='d-flex align-items-center'><Tooltip arrow 
                                    title={subscription_status.status == 'non_subscribed' ? 'Non - subscriber' : subscription_status.status == 'active' ? 'Active subscription' : 'Expired subscription'}>
                                        <span className={`discover_subscription_dot discover_subscription_dot_${subscription_status.status}`}></span></Tooltip></p>
                                }
                                {
                                    subscription_status.plan_start_date && 
                                    <p className='d-flex align-items-center'>
                                        <MaterialSymbol iconName='workspace_premium' fontweight='200' color='#929292' iconFilled />
                                        <Tooltip arrow 
                                        title='Plan date'><span>
                                        {`${dayjs(subscription_status.plan_start_date).format('D MMM YYYY')} - ${dayjs(subscription_status.plan_end_date).format('D MMM YYYY')}`}
                                    </span></Tooltip>
                                    </p>
                                }
                                {email && email.length > 0 && <p className='d-flex align-items-center'><MaterialSymbol iconName='mail' fontweight='200' color='#929292' iconFilled /> <span className='ms-2'>{email}</span></p>}
                                {(phone != 0 && phone) && <p className='d-flex align-items-center'><MaterialSymbol iconName='call' fontweight='200' color='#929292' iconFilled /><span className='ms-2'>{phone}</span></p>}
                                {location && location.length > 0 && <p className='d-flex align-items-center'><MaterialSymbol iconName='location_on' fontweight='200' color='#929292' iconFilled /><span className='ms-1'>{location}</span></p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='influencer-user__intro'>
                    <p className="mb-0">{loading ? <Skeleton /> : <>{ytInfluencerData?.detail?.[0]?.description !== '' ? ytInfluencerData?.detail?.[0]?.description : 'NA'}</>}</p>
                </div>
            </div>
        </>
    )
}

export default YTInfluencerProfileLeft