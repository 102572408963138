import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SwipeableDrawer,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ImageCrop, Card } from "../../../components";
import SampleProfile from "../../../assets/images/default-image.jpg";
import HeadingStyle from "../../../components/heading-style/heading-style";
import {
  PublicService,
  LocalService,
  CommonService,
} from "../../../core/services";
import { FormValidation } from "../../../core/utility";
import "./payment-method.scss";
import { Loading } from "../../../redux/action";
import HeadingStyleSecondary from '../../../components/heading-style/heading-style-secondary';
import FileUploadPopup from "../../../components/popup/file-upload-popup";
import { CloseRounded } from "@mui/icons-material";
import ConfirmPopup from "../../../components/confirm-popup/confirm-popup";
import { createPortal } from "react-dom";
import { MenuPropsSelect } from "../../../core/utility/style";

const publicService = new PublicService();
const localService = new LocalService();
const formValidation = new FormValidation();
const commonService = new CommonService();

interface Props {
  UserReducer: any;
  loading: (data: boolean) => void;
}

const CommanPaymentMethod: React.FC<Props> = (props: Props) => {
  const upiPath = `accounts/${props.UserReducer.account_id}/vpa/${props.UserReducer.user_id}/barcode`;
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [savedBankDetails, setSavedBankDetails] = useState<any>(null);
  const [savedVPADetails, setSavedVPADetails] = useState<any>(null);
  const [upiImage, setUpiImage] = useState<any>(null);
  const [active, setActive] = useState(0);
  const [addPaymentPopupOpen, setAddPaymentPopupOpen] = useState(false);
  const handleTabClick = (e: any) => {
    setActive(e);
    setAddPaymentPopupOpen(true)
  };
  const [openApply, setOpenApply] = useState(false);
  const [confirmData, setConfirmData] = useState(null)

  const [bankDetails, setBankDetails] = useState<any>({
    user_id: props.UserReducer.user_id,
    account_id: props.UserReducer.account_id,
    account_holder_name: "",
    account_no: "",
    re_account_no: "",
    account_type: "saving",
    ifsc_code: "",
    bank_name: "",
    bank_address: "",
    micr_code: "",
    default_account: true,
  });

  const [upiDetail, setUpiDetail] = useState<any>({
    user_id: props.UserReducer.user_id,
    account_id: props.UserReducer.account_id,
    account_holder_name: "",
    upi: "",
    upi_qr_code_path: "",
    upi_qr_code_url: "",
  });

  const [upiError, setUpiError] = useState({
    account_holder_name: { status: true, message: "" },
    upi: { status: true, message: "" },
  });

  const [errors, setErrors] = useState<any>({
    account_holder_name: { status: true, message: "" },
    account_no: { status: true, message: "" },
    re_account_no: { status: true, message: "" },
    account_type: { status: true, message: "" },
    ifsc_code: { status: true, message: "" },
    bank_name: { status: true, message: "" },
    bank_address: { status: true, message: "" },
    micr_code: { status: true, message: "" },
  });

  const getSavedBankDetail = useCallback(async () => {
    const { user_id, account_id } = props.UserReducer;
    try {
      const response = await commonService.getSavedBankDetails({
        user_id,
        account_id,
      });
      if (response.data.status) {
        setSavedBankDetails(response.data.rows);
      } else {
        setSavedBankDetails(null);
      }
    } catch (error) {
      console.error("Error fetching saved bank details:", error);
      // Handle the error as needed
    }
  }, [props.UserReducer]);

  const getSavedVPADetail = useCallback(async () => {
    const { user_id, account_id } = props.UserReducer;
    try {
      const response = await commonService.getSavedVPADetails({
        user_id,
        account_id,
      });
      if (response.data.status.status) {
        setSavedVPADetails(response.data.rows);
      } else {
        setSavedVPADetails(null);
      }
    } catch (error) {
      console.error("Error fetching saved VPA details:", error);
      // Handle the error as needed
    }
  }, [props.UserReducer]);

  useEffect(() => {
    getSavedBankDetail();
    getSavedVPADetail();
  }, [getSavedBankDetail, getSavedVPADetail]);

  useEffect(() => {
    if (bankDetails.ifsc_code.length > 10 && bankDetails.ifsc_code.length < 12) {
      async function _IFSC_Verify() {
        try {
          const verification: any = await publicService.getIFSCDetails(
            bankDetails.ifsc_code
          );
          if (verification.status === 200) {
            const { MICR, ADDRESS, BANK } = verification.data;
            setBankDetails((e: any) => {
              e.bank_address = ADDRESS;
              e.bank_name = BANK;
              e.micr_code = MICR;
              return { ...e };
            });
          }
        } catch (error: any) {
          if (error.status === 404) {
            localService.toastify("Incorrect IFSC Code", "error", 1000);
          } else {
            localService.toastify("Some Technical issue", "error", 1000);
          }
        }
      }

      _IFSC_Verify();
    }
  }, [bankDetails.ifsc_code]);

  async function saveBankDetail() {
    setLoading(true);
    let response: any;
    try {
      let validate = true;
      let updatedErrors = { ...errors };
      // Object.keys(bankDetails).forEach((key: any) => {
      //   if ((bankDetails[key] + "").length > 0) {
      //   } else {
      //     setErrors((e: any) => {
      //       e[key] = formValidation.GetNameValidate(bankDetails[key]);
      //       return { ...e };
      //     });
      //     validate = false;
      //   }
      // });
      Object.keys(bankDetails).forEach((key: any) => {
        if ((bankDetails[key] + "").length > 0) {
          if (key === "ifsc_code") {
            const ifscValidation = formValidation.isValidIfsc(bankDetails[key]);
            if (!ifscValidation.status) {
              setErrors((e: any) => {
                e[key] = ifscValidation.message;
                return { ...e };
              });
              validate = false;
            }
          }
        } else {
          setErrors((e: any) => {
            e[key] = formValidation.GetNameValidate(bankDetails[key]);
            return { ...e };
          });
          validate = false;
        }
      });



      if (validate) {
        response = await commonService.addBankAccountDetail(bankDetails);
        const toastType = response.data.status ? "success" : "error";
        localService.toastify("Bank Account Added Successfully", toastType, 1000);
        if (toastType === "success") {
          setActive(0);
          setAddPaymentPopupOpen(false);
        }
        getSavedBankDetail();
      }
    } catch (error) {
      throw new Error(`saveBankDetail error :: ==> ${error}`);
    } finally {
      setLoading(false);
    }
  }

  async function saveUpiDetail() {
    setLoading(true);
    let response: any;
    try {
      let validate = true;
      const keys = Object.keys(upiDetail).forEach((key: any) => {
        if ((upiDetail[key] + "").length > 0) {
        } else {
          setErrors((e: any) => {
            e[key] = formValidation.GetNameValidate(upiDetail[key]);
            return { ...e };
          });
          validate = false;
        }
      });

      if (validate) {
        response = await commonService.addUPIDetail(upiDetail);
        const toastType = response.data.status?.status ? "success" : "error";
        localService.toastify(response.data.status?.message, toastType, 1000);
        if (toastType === "success") {
          setActive(0);
        }
        getSavedVPADetail();
      }
    } catch (error) {
      throw new Error(`saveBankDetail error :: ==> ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const updateDefaultAccount = async (e: any) => {
    try {
      props.loading(true);

      const response = await commonService.updateDefaultAccount({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        bank_account_id: e.target.value
      });

      const checkStatus = response.data.status.status ? 'success' : 'info';
      localService.toastify(response.data.status.message, checkStatus, 1000);
      getSavedBankDetail();

    } catch (error) {
      throw new Error(`updateDefaultAccount error :: ==> ${error}`);
    } finally {
      props.loading(false);
    }
  };

  const deleteOpenPopup = (data: any) => {
    setConfirmData(data);
    setOpenApply(true);
  }

  const confirmDelete = () => {
    deleteBankDetail(confirmData);
    handleApplyClose();
  }

  const deleteBankDetail = async (data: any) => {
    const { id, account_id } = data;
    setLoading(true);
    try {
      const response = await commonService.deleteBankDetails({
        user_id: props.UserReducer.usre_id,
        account_id: account_id,
        id: id,
      });
      const messageType = response.data.status ? "success" : "error";
      localService.toastify("Bank Account Deleted Succesfully", messageType, 1000);
      getSavedBankDetail();
      handleApplyClose()
    } catch (error) {
      console.log(
        `DeleteBankAccountDetail error: ==> ${JSON.stringify(error)}`
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteVPADetail = async (data: any) => {
    const { id, account_id } = data;
    setLoading(true);
    try {
      const response = await commonService.deleteVPADetails({
        user_id: props.UserReducer.usre_id,
        account_id: account_id,
        id: id,
      });
      const messageType = response.data.status.status ? "success" : "error";
      localService.toastify(response.data.status.message, messageType, 1000);
      getSavedVPADetail();
    } catch (error) {
      console.log(
        `DeleteBankAccountDetail error: ==> ${JSON.stringify(error)}`
      );
    } finally {
      setLoading(false);
    }
  };
  const handlePaymentPopupClose = () => {
    setAddPaymentPopupOpen(false);
  };
  const handleOpenPaymentPopup = (data: any) => {
    setAddPaymentPopupOpen(true);

  };
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const drawerAnchor = isSmallScreen ? 'bottom' : 'right';

  const cancelPaymentMethod = () => {
    setAddPaymentPopupOpen(false);
  };
  const handleApplyClose = () => {
    setOpenApply(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase().trim();

    setBankDetails((prevDetails: any) => ({
      ...prevDetails,
      ifsc_code: value,
    }));

    if (value.length < 11) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ifsc_code: {
          message: 'IFSC Code must be 11 characters',
          status: false,
        },
      }));
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ifsc_code: {
          message: '',
          status: true,
        },
      }));

      if (value.length === 11) {
        async function _IFSC_Verify() {
          try {
            const verification: any = await publicService.getIFSCDetails(value);
            if (verification.status === 200) {
              const { MICR, ADDRESS, BANK } = verification.data;
              setBankDetails((prevDetails: any) => ({
                ...prevDetails,
                bank_address: ADDRESS,
                bank_name: BANK,
                micr_code: MICR,
              }));
            }
          } catch (error: any) {
            if (error.status === 404) {
              localService.toastify('Incorrect IFSC Code', 'error', 1000);
            } else {
              localService.toastify('Some Technical issue', 'error', 1000);
            }
          }
        }

        _IFSC_Verify();
      }
    }
  };

  const handleBlur = () => {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      ifsc_code: formValidation.isValidIfsc(bankDetails.ifsc_code),
    }));
  };


  return (
    <>
      {showUploadPopup && (
        // <ImageCrop
        //   ratio={1 / 1}
        //   path={upiPath}
        //   popup={(e) => setShowUploadPopup(e)}
        //   onCroped={(e) => {
        //     setUpiDetail((previous: any) => {
        //       previous.upi_qr_code_path = e.key;
        //       previous.upi_qr_code_url = e.url;
        //       return { ...previous };
        //     });
        //     setUpiImage([e.url]);
        //   }}
        // />
        <FileUploadPopup openModal={showUploadPopup} onClose={(e) => setShowUploadPopup(false)} onSend={(e: any) =>
          setUpiDetail((previous: any) => {
            previous.upi_qr_code_path = e.key;
            previous.upi_qr_code_url = e.url;
            return { ...previous };
          })
        }
          path={upiPath}
          acceptedFileTypes=".jpg, .jpeg, .png" />
      )}

      <div className="d-flex flex-column justify-content-center mx-0 payment-main">
        <div className='d-flex justify-content-between list-section-content rounded'>
          <div className='row align-items-center'>
            <div className="d-flex align-items-center">
            <strong className="">Banking Details</strong>
            <Button
            size='small'
            variant='contained'
            className={
              active === 1
                ? "btn btn-primary btn-mui mt-0 mt-md-0 active d-flex d-md-none ms-auto"
                : "btn btn-primary btn-mui mt-0 mt-md-0 d-flex d-md-none ms-auto"
            }
            onClick={() => handleTabClick(1)}
          >
            Add Account
          </Button>
          </div>
            <p className='mb-0'>Add your banking details to receive campaign payments (wherever applicable).</p>
          </div>
          <Button
            size='small'
            variant='contained'
            className={
              active === 1
                ? "btn btn-primary btn-mui mt-2 mt-md-0 active d-none d-md-flex"
                : "btn btn-primary btn-mui mt-2 mt-md-0 d-none d-md-flex"
            }
            onClick={() => handleTabClick(1)}
          >
            Add Account
          </Button>
        </div>
        <div className="influencer_uppercase">
          <div className="influencer_payment_tabs ps-0 mb-3">
            {/* <div className="d-flex mx-0"> */}
            {/* <div className="_influencer_main_account me-1 mx-1 mt-2">
                <div className="upi mt-1">
                  <h6>UPI</h6>
                  <span
                    className={
                      active === 2
                        ? "btn btn-primary active"
                        : "btn btn-primary"
                    }
                    onClick={() => handleTabClick(2)}
                  >
                    Add UPI
                  </span>
                </div>
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <SwipeableDrawer
          anchor={drawerAnchor}
          open={addPaymentPopupOpen}
          onClose={handlePaymentPopupClose}
          onOpen={handleOpenPaymentPopup}
          transitionDuration={300}
          className='popup-drawer payment-drawer'
          disableSwipeToOpen={false}
          hysteresis={0.8}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className="tab_content">
            {active === 1 && (
              // Bank Account Section
              <>
                <div className='d-flex flex-wrap mb-3'>
                  <div className='col popup-drawer-header'>
                    <span className='align-self-center'>Add Payment Detail</span>
                    {/* <span className='material-symbols-rounded icon-filled close-preview payment-cancel' onClick={cancelPaymentMethod}>cancel</span> */}
                    <IconButton
                      edge='end'
                      color='inherit'
                      onClick={cancelPaymentMethod}
                      aria-label='close'
                    >
                      <CloseRounded />
                    </IconButton>
                  </div>
                </div>
                <div className="tab_panel px-4">
                  <div className=" account_details">
                    <div className="account_details_main_row">
                      {/* <div className="col-12 mb-3">
                        <HeadingStyle title="Add Payment Detail" />
                      </div> */}
                      <Grid container rowSpacing={2} spacing={2}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="w-100 input-common"
                            size="small"
                            type="text"
                            name="acc_Holder_Name"
                            helperText={errors.account_holder_name.message}
                            error={!errors.account_holder_name.status}
                            onChange={(e) =>
                              setBankDetails({
                                ...bankDetails,
                                account_holder_name: e.target.value,
                              })
                            }
                            onBlur={() => {
                              setErrors({
                                ...errors,
                                account_holder_name: formValidation.GetNameValidate(
                                  bankDetails.account_holder_name
                                ),
                              });
                            }}
                            label="Account Holder Name"
                            placeholder="ex: Adarsh Chaudhary"
                          />
                        </Grid>
                        <Grid item xs={12} md={12}><TextField
                          className="w-100 input-common"
                          size="small"
                          type="text"
                          name="acc_No"
                          label="Account Number"
                          helperText={errors.account_holder_name.message}
                          error={!errors.account_no.status}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              account_no: e.target.value,
                            })
                          }
                          onBlur={() => {
                            setErrors({
                              ...errors,
                              account_no: formValidation.GetNameValidate(
                                bankDetails.account_no
                              ),
                            });
                          }}
                          placeholder="ex: 0000-000-000-0000"
                        /></Grid>

                        <Grid item xs={12} md={12}> <TextField
                          className="w-100 input-common"
                          size="small"
                          type="password"
                          name="re_Acc_No"
                          label="Re-Enter Account Number"
                          placeholder="ex: 0000-000-000-0000"
                          error={!errors.re_account_no.status}
                          helperText={errors.account_holder_name.message}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              re_account_no: e.target.value,
                            })
                          }
                          onBlur={() => {
                            setErrors({
                              ...errors,
                              re_account_no: formValidation.GetNameValidate(
                                bankDetails.re_account_no
                              ),
                            });
                          }}
                        /></Grid>
                        <Grid item xs={12} md={12}><FormControl fullWidth size="small"
                          className='input-common'>
                          <InputLabel id="demo-multiple-name-label ">
                            Bank Account type
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            label="Bank Account type"
                            defaultValue={"saving"}
                            error={!errors.account_holder_name.status}
                            onChange={(e) =>
                              setBankDetails({
                                ...bankDetails,
                                account_type: e.target.value as string,
                              })
                            }
                            onBlur={() => {
                              setErrors({
                                ...errors,
                                account_type: formValidation.GetNameValidate(
                                  bankDetails.account_type
                                ),
                              });
                            }}
                            MenuProps={MenuPropsSelect}
                          >
                            <MenuItem value={"saving"}>Saving</MenuItem>
                            <MenuItem value={"current"}>Current</MenuItem>
                          </Select>
                        </FormControl></Grid>
                        {/* <Grid item xs={12} md={12}><TextField
                          className="w-100 input-common"
                          size="small"
                          type="text"
                          name="acc_No"
                          label="IFSC Code"
                          value={bankDetails.ifsc_code}
                          placeholder="ex: SBIN0007549"
                          helperText={errors.account_holder_name.message}
                          error={!errors.ifsc_code.status}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              ifsc_code: e.target.value as string,
                            })
                          }
                          onBlur={() => {
                            setErrors({
                              ...errors,
                              ifsc_code: formValidation.GetNameValidate(
                                bankDetails.ifsc_code
                              ),
                            });
                          }}
                        /></Grid> */}
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="w-100 input-common"
                            size="small"
                            type="text"
                            name="acc_No"
                            label="IFSC Code"
                            value={bankDetails.ifsc_code}
                            placeholder="ex: SBIN0007549"
                            helperText={errors.ifsc_code.message}
                            error={!errors.ifsc_code.status}
                            onChange={handleChange}
                            onBlur={() => {
                              setErrors({
                                ...errors,
                                ifsc_code: formValidation.isValidIfsc(
                                  bankDetails.ifsc_code
                                ),
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}> <TextField
                          value={bankDetails.bank_name}
                          required
                          disabled
                          className="w-100 input-common"
                          size="small"
                          type="text"
                          name="Bank_Name"
                          label="Bank Name"
                          placeholder="ex: State Bank of India"
                        /></Grid>
                        <Grid item xs={12} md={12}><TextField
                          value={bankDetails.bank_address}
                          required
                          disabled
                          className="w-100 input-common"
                          size="small"
                          name="address"
                          type="address"
                          id="outlined-multiline-flexible"
                          label="Bank  Address"
                          multiline
                          maxRows={2}
                          placeholder="ex: ADB bank Greater Noida (201301)"
                        /></Grid>
                        <Grid item xs={12} md={12}><FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={bankDetails.default_account}
                              onChange={(e, v) =>
                                setBankDetails({
                                  ...bankDetails,
                                  default_account: v,
                                })
                              }
                            />
                          }
                          label="Set as Default"
                        /></Grid>
                        <Grid style={{ justifyContent: 'flex-end', display: 'flex' }} item xs={12} md={12}>
                          <Button
                            className="btn btn-outline-primary btn-mui"
                            variant="contained"
                            onClick={cancelPaymentMethod}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            className="btn btn-primary ms-2 btn-mui"
                            loading={loading}
                            loadingPosition="start"
                            onClick={saveBankDetail}
                          >
                            Save
                          </LoadingButton>


                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div>
              {active === 2 && (
                //UPI Section
                <>
                  <div className='row mb-3'>
                    <div className='col popup-drawer-header'>
                      <HeadingStyle title="Add Payment Detail" />
                      {/* <span className='material-symbols-rounded icon-filled close-preview' onClick={cancelPaymentMethod}>cancel</span> */}
                      <IconButton
                        edge='end'
                        color='inherit'
                        onClick={cancelPaymentMethod}
                        aria-label='close'
                      >
                        <CloseRounded />
                      </IconButton>
                    </div>
                  </div>
                  <div className="tab_panel px-4">
                    {/* <div className="mb-3">
                    <HeadingStyle title="Add Payment Detail" />
                  </div> */}
                    <div className="row">
                      <div className="col-12 mt-1 mb-1">
                        <TextField
                          size="small"
                          className="w-100 input-common"
                          name="id_account_holder_name"
                          label="Account Holder"
                          placeholder="example: Adarsh"
                          error={!upiError.account_holder_name.status}
                          helperText={upiError.account_holder_name.message}
                          onChange={(e) =>
                            setUpiDetail({
                              ...upiDetail,
                              account_holder_name: e.target.value,
                            })
                          }
                          onBlur={() => {
                            const account_holder =
                              upiDetail.account_holder_name?.length > 2;
                            setUpiError({
                              ...upiError,
                              account_holder_name: {
                                status: account_holder,
                                message: account_holder
                                  ? ""
                                  : "This field is required",
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 mt-2 mb-1">
                        <TextField
                          size="small"
                          className="w-100 input-common"
                          type="upi id"
                          name="upi_id"
                          label="UPI id"
                          placeholder="example: 8433080460@paytm"
                          error={!upiError.upi.status}
                          helperText={upiError.upi.message}
                          onChange={(e) =>
                            setUpiDetail({ ...upiDetail, upi: e.target.value })
                          }
                          onBlur={() => {
                            const checkUPi = upiDetail.upi.includes("@");
                            setUpiError({
                              ...upiError,
                              upi: {
                                status: checkUPi,
                                message: checkUPi ? "" : "This field is required",
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-start mt-2 mb-1">
                        <h6>UPI QR code</h6>
                        {upiImage ? (
                          upiImage.map((data: any, index: number) => {
                            return (
                              <img
                                key={index}
                                src={data}
                                style={{
                                  width: "200px",
                                  height: " 200px",
                                }}
                              />
                            );
                          })
                        ) : (
                          <img
                            src={SampleProfile}
                            alt=""
                            style={{
                              width: "200px",
                              height: " 200px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div
                    onClick={() => setShowUploadPopup(!showUploadPopup)}
                    className="col-12  _influencer_payment_showupload text-start mt-1 mb-1 img-upload-sec"
                  >
                  </div> */}
                    <div>
                      <Button
                        className='btn btn-outline-primary upload_button mt-3'
                        onClick={() => setShowUploadPopup(!showUploadPopup)}
                        variant='contained'
                      >
                        Upload
                      </Button>
                    </div>
                    <div className="row">
                      <div className="col-12 text-start mt-1 mb-1">
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Set as Default"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-start mt-1 mb-1 d-flex justify-content-end">
                        <LoadingButton
                          className="btn btn-primary mx-2"
                          loading={loading}
                          loadingPosition="start"
                          variant="contained"
                          onClick={saveUpiDetail}
                        >
                          Save
                        </LoadingButton>
                        <Button
                          className="btn btn-outline-primary"
                          variant="contained"
                          onClick={cancelPaymentMethod}
                        >
                          Cancel
                        </Button>
                        {/* <Button
                                            type='submit'
                                            variant='contained'
                                            className='btn btn-primary'
                                        >
                                            Submit
                                        </Button> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* {savedBankDetails && (
            <div className="row mt-3">
              <HeadingStyleSecondary title="Bank Accounts" />

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={() =>
                  savedBankDetails
                    .filter((data: any) => data.default_account === "true")
                    .map((obj: any) => obj.id)
                }
                onChange={updateDefaultAccount}
              >
                {savedBankDetails.map((data: any, index: number) => {
                  return (
                    <div key={index} className="bank-detail-card">
                      <div className="default-selected">
                        <FormControlLabel
                          className="radio-button"
                          value={data.id}
                          id={`id_${data.id}`}
                          control={<Radio />}
                          label=""
                        />
                        {data.default_account === "true" && (
                          <span>Default</span>
                        )}
                      </div>
                      <div className="bankDetails">
                        <div className="row">
                          <div className="col">
                            <strong>Bank Name</strong>
                          </div>
                          <div className="col">
                            <span>{data.bank_name}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <strong>Account Holder Name</strong>
                          </div>
                          <div className="col">
                            <span>{data.account_holder_name}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <strong>Account Number</strong>
                          </div>
                          <div className="col">
                            <span>{data.account_number}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <strong>Account Type</strong>
                          </div>
                          <div className="col">
                            <span>{data.account_type}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <strong>MICR Code</strong>
                          </div>
                          <div className="col">
                            <span>{data.micr_code}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <strong>IFSC Code</strong>
                          </div>
                          <div className="col">
                            <span>{data.ifsc_code}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <strong>Address</strong>
                          </div>
                          <div className="col">
                            <span>{data.address}</span>
                          </div>
                        </div>
                        <div className="delete-bank-detail">
                          <div className="delete-column">
                            <LoadingButton
                              className="btn btn-primary"
                              loading={loading}
                              loadingPosition="start"
                              variant="contained"
                              onClick={() => deleteBankDetail(data)}
                            >
                              Delete
                            </LoadingButton>
                          </div>
                        </div>
                      </div>
                    </div>

                  );
                })}
              </RadioGroup>
            </div>
          )}
          {savedVPADetails && (
            <div className="row">
              <h3>VPA Accounts</h3>
              <hr />
              {savedVPADetails.map((data: any, index: number) => {
                return (
                  <div className="col" key={index}>
                    <Card>
                      <div className="row">
                        <div className="col">
                          <strong>Account Holder Name</strong>
                        </div>
                        <div className="col">
                          <span>{data.account_holder_name}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>UPI</strong>
                        </div>
                        <div className="col">
                          <span>{data.upi_address}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img
                            src={data.upi_image_url}
                            alt="upi"
                            style={{ height: `200px` }}
                          />
                        </div>
                      </div>
                      <div className="row delete-bank-detail">
                        <div className="col delete-column">
                          <LoadingButton
                            className="btn btn-primary"
                            loading={loading}
                            loadingPosition="start"
                            variant="contained"
                            onClick={() => deleteVPADetail(data)}
                          >
                            Delete
                          </LoadingButton>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          )} */}
          </div>
        </SwipeableDrawer>
        {createPortal(<ConfirmPopup confirmPopupInnerText={`Are you sure want to delete this address?`} openPopup={openApply} confirmClosePopup={handleApplyClose}
          confirmClickAction={confirmDelete} drawerTitle="" />, document.body)}
        {savedBankDetails && (
          <div className="row mt-3">
            <strong className='mt-2 mb-1 d-block'>Bank Accounts</strong>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="d-flex flex-column"
              defaultValue={() =>
                savedBankDetails
                  .filter((data: any) => data.default_account === "true")
                  .map((obj: any) => obj.id)
              }
              onChange={updateDefaultAccount}

            >
              {savedBankDetails.map((data: any, index: number) => {
                return (
                  <div key={index} className="bank-detail-card">
                    <div className="default-selected">
                      <FormControlLabel
                        className="radio-button"
                        value={data.id}
                        id={`id_${data.id}`}
                        control={<Radio />}
                        label=""
                      />
                      {data.default_account === "true" && (
                        <span>Default</span>
                      )}
                    </div>
                    <div className="bankDetails">
                      <div className="row">
                        <div className="col">
                          <strong>Bank Name</strong>
                        </div>
                        <div className="col">
                          <span>{data.bank_name}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>Account Holder Name</strong>
                        </div>
                        <div className="col">
                          <span>{data.account_holder_name}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>Account Number</strong>
                        </div>
                        <div className="col">
                          <span>{data.account_number}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>Account Type</strong>
                        </div>
                        <div className="col">
                          <span>{data.account_type}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>MICR Code</strong>
                        </div>
                        <div className="col">
                          <span>{data.micr_code}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>IFSC Code</strong>
                        </div>
                        <div className="col">
                          <span>{data.ifsc_code}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <strong>Address</strong>
                        </div>
                        <div className="col">
                          <span>{data.address}</span>
                        </div>
                      </div>
                      <div className="delete-bank-detail">
                        <div className="delete-column">
                          <Button
                            className="btn btn-primary"
                            variant="contained"
                            onClick={() => deleteOpenPopup(data)}                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                );
              })}
            </RadioGroup>
          </div>
        )}
        {/*Temporarily Hidden VPA Sections*/}
        {/* {savedVPADetails && (
          <div className="row">
            <h3>VPA Accounts</h3>
            <hr />
            {savedVPADetails.map((data: any, index: number) => {
              return (
                <div className="col" key={index}>
                  <Card>
                    <div className="row">
                      <div className="col">
                        <strong>Account Holder Name</strong>
                      </div>
                      <div className="col">
                        <span>{data.account_holder_name}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <strong>UPI</strong>
                      </div>
                      <div className="col">
                        <span>{data.upi_address}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img
                          src={data.upi_image_url}
                          alt="upi"
                          style={{ height: `200px` }}
                        />
                      </div>
                    </div>
                    <div className="row delete-bank-detail">
                      <div className="col delete-column">
                        <LoadingButton
                          className="btn btn-primary"
                          loading={loading}
                          loadingPosition="start"
                          variant="contained"
                          onClick={() => deleteVPADetail(data)}
                        >
                          Delete
                        </LoadingButton>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        )} */}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: boolean) => dispatch(Loading(data))
  }
}

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(CommanPaymentMethod);
export { connectToRedux as CommanPaymentMethod };
