import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class LiveDashboardService {

  static async getLiveDashboardData(
    param1: any,
  ): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/live-subscriber-count?sessionId=${param1}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Live Dashboard Data :: ${error}`);
    }
  }
}
