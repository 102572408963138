import { useState, useEffect } from 'react';
import { ClanShopService } from '../../../core/services/clan.shop.service';
import { ProductCard } from '../../brand/clan-shop/product-card';
import './product-shop.scss';
import HeadingStyle from '../../../components/heading-style/heading-style';
import { Tabs, Tab } from '@mui/material';
export const ProductShop = () => {
    const [brandList, setBrandList] = useState<any>();
    const [productData, setProductData] = useState<any>();
    const [campaignProductData, setCampaignProductData] = useState<any>();
    useEffect(() => {
        ClanShopService.getBrandNames()
            .then((res) => {
                setBrandList(res?.data?.rows);
            })
            .catch((e) => setBrandList([]));
    }, []);

    const handleBrandProducts = (data: any) => {
        ClanShopService.getBrandProductsData(`${data.brand_id}`)
            .then((res: any) => {
                setProductData(res?.data?.rows);
            })
            .catch((e: any) => setProductData([]));
    };
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='product-shop-container'>
                <div className='container'>
                    <HeadingStyle title='Product Shop' />
                    <div className='product-shop-main-container'>
                        <div className='brand-container d-none d-lg-flex flex-column'>
                            <strong>Brands</strong>

                            <ul className=''>
                                {brandList &&
                                    brandList.map((data: any) => (
                                        <li
                                            key={data.brand_id}
                                            onClick={() =>
                                                handleBrandProducts(data)
                                            }
                                        >
                                            {data.brand_name}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                        <div className='products-container'>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant='scrollable'
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label='scrollable force tabs example'
                                className='d-flex d-lg-none product-list-tab'
                            >
                                {brandList &&
                                    brandList.map((data: any) => (
                                        <Tab
                                            label={data.brand_name}
                                            onClick={() =>
                                                handleBrandProducts(data)
                                            }
                                        >
                                            {data.brand_name}
                                        </Tab>
                                    ))}
                            </Tabs>
                            <ProductCard data={productData} type='influencer' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
