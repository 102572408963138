// import './_user_signup.scss'
import React from 'react';
import { NavLink } from 'react-router-dom';
import useBodyClass from '../../hooks/useBodyClass';
import Footer from '../../components/footer/footer';



export const Signup: React.FC = (props) => {
    useBodyClass("common-signup-page")
    return (
        <>
        <div className="sign-up-container common-bg">
            <div className="container ">
                <div className="signup-title">
                    <p>Choose What Describes You Best</p>
                </div>
                <div className="sign-up-buttons">
                    <ul>
                        <li><NavLink to='/signup/influencer' className='btn btn-outline-primary btn-rounded'>Influencer</NavLink> </li>
                        <li><NavLink to='/signup/brand' className='btn btn-outline-primary btn-rounded'>Brand</NavLink> </li>
                        <li><NavLink to='/signup/agency' className='btn btn-outline-primary btn-rounded'>Agency</NavLink> </li>
                        <li><NavLink to='/signup/talentpartner' className='btn btn-outline-primary btn-rounded'>Talent Partner</NavLink> </li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
};