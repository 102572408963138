interface Props {
    className?: string;
}
const IconProfileER: React.FC<Props> = (props: Props) => {
    return (
        <div
            className={props.className}
        >
            <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_9302_1575" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="8" y="5" width="32" height="28">
                    <rect x="8.95996" y="5.12109" width="30.72" height="26.88" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_9302_1575)">
                    <path d="M14.4741 27.5203C13.8275 27.5203 13.2802 27.2963 12.8322 26.8483C12.3842 26.4003 12.1602 25.853 12.1602 25.2064V10.6342C12.1602 9.98762 12.3842 9.44031 12.8322 8.99231C13.2802 8.54431 13.8275 8.32031 14.4741 8.32031H34.1662C34.8129 8.32031 35.3602 8.54431 35.8082 8.99231C36.2562 9.44031 36.4802 9.98762 36.4802 10.6342V29.2531C36.4802 29.7717 36.2435 30.1308 35.7701 30.3302C35.2965 30.5295 34.8776 30.4469 34.5134 30.0826L31.9509 27.5203H14.4741ZM32.7682 25.6003L34.5602 27.3728V10.6342C34.5602 10.5357 34.5191 10.4454 34.437 10.3635C34.355 10.2814 34.2648 10.2403 34.1662 10.2403H14.4741C14.3755 10.2403 14.2853 10.2814 14.2034 10.3635C14.1212 10.4454 14.0802 10.5357 14.0802 10.6342V25.2064C14.0802 25.305 14.1212 25.3952 14.2034 25.4771C14.2853 25.5592 14.3755 25.6003 14.4741 25.6003H32.7682Z" fill="#B5B5B5" />
                </g>
                <rect x="5.12012" y="3.83984" width="20.48" height="17.92" fill="white" />
                <mask id="mask1_9302_1575" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="31" height="31">
                    <rect width="30.72" height="30.72" fill="#D9D9D9" />
                    <rect width="30.72" height="30.72" fill="white" />
                </mask>
                <g mask="url(#mask1_9302_1575)">
                    <path d="M7.72948 22.3992L5.16692 24.9615C4.80276 25.3258 4.38388 25.4084 3.91028 25.2091C3.43689 25.0097 3.2002 24.6506 3.2002 24.132V5.51314C3.2002 4.86653 3.4242 4.31922 3.8722 3.87122C4.3202 3.42322 4.8675 3.19922 5.51412 3.19922H25.2063C25.8529 3.19922 26.4002 3.42322 26.8482 3.87122C27.2962 4.31922 27.5202 4.86653 27.5202 5.51314V20.0853C27.5202 20.7319 27.2962 21.2792 26.8482 21.7272C26.4002 22.1752 25.8529 22.3992 25.2063 22.3992H7.72948ZM6.9122 20.4792H25.2063C25.3048 20.4792 25.3951 20.4382 25.477 20.356C25.5591 20.2741 25.6002 20.1839 25.6002 20.0853V5.51314C25.6002 5.41458 25.5591 5.32434 25.477 5.24242C25.3951 5.16028 25.3048 5.11922 25.2063 5.11922H5.51412C5.41556 5.11922 5.32532 5.16028 5.2434 5.24242C5.16126 5.32434 5.1202 5.41458 5.1202 5.51314V22.2517L6.9122 20.4792ZM8.9602 15.5992H16.6402C16.9126 15.5992 17.1407 15.5073 17.3244 15.3234C17.5082 15.1397 17.6002 14.9116 17.6002 14.6392C17.6002 14.3668 17.5082 14.1387 17.3244 13.9551C17.1407 13.7712 16.9126 13.6792 16.6402 13.6792H8.9602C8.68777 13.6792 8.45971 13.7712 8.27603 13.9551C8.09214 14.1387 8.0002 14.3668 8.0002 14.6392C8.0002 14.9116 8.09214 15.1397 8.27603 15.3234C8.45971 15.5073 8.68777 15.5992 8.9602 15.5992ZM8.9602 11.9192H21.7602C22.0326 11.9192 22.2607 11.8273 22.4444 11.6434C22.6282 11.4597 22.7202 11.2316 22.7202 10.9592C22.7202 10.6868 22.6282 10.4587 22.4444 10.2751C22.2607 10.0912 22.0326 9.99922 21.7602 9.99922H8.9602C8.68777 9.99922 8.45971 10.0912 8.27603 10.2751C8.09214 10.4587 8.0002 10.6868 8.0002 10.9592C8.0002 11.2316 8.09214 11.4597 8.27603 11.6434C8.45971 11.8273 8.68777 11.9192 8.9602 11.9192Z" fill="#B5B5B5" />
                </g>
            </svg>

        </div>
    );
};

export default IconProfileER;






