import React from 'react'

type Props = {
  data: any
}

export const ApprovedCreative = (props: Props) => {
  const approvedCreatives = props?.data?.filter((approvedCreative: any) => {
    return approvedCreative.status === 'Approved'
  })
  return (
    <div className='container container-approved-creative'>
      <h2>All approved creatives ({approvedCreatives?.length})</h2>
      <div className='brand_creative_main_container creatives-container'>

        {approvedCreatives && approvedCreatives.map((creative: any) => {
          return <div className="creatives-container-list" key={creative?.creative_id}>
            <div className="creative-card">{
              creative?.file_type === "image" ? (
                <img
                  src={creative?.url}
                  className="selected-creative"
                  alt="creative"
                />
              ) : (
                <>
                  <span
                    className="icon-video material-symbols-rounded icon-filled">play_circle
                  </span>
                  <video
                    className="selected-creative"
                    height={400}
                    width={300}
                  >
                    <source src={creative?.url} type="video/mp4"></source>
                  </video>
                </>
              )
            }
            </div>
            <span className='fs-14'>{creative.influencer_name}</span>

          </div>
        })}

      </div>
    </div>
  )
}