import React from "react";
import { useState, useEffect } from "react";
import { DefaultUserProfile } from "../../config/config";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip } from "@mui/material";
import useBodyClass from "../../hooks/useBodyClass";

interface Props {
  data?: any;
  unreadMsg?: any;
  activeCampaign?: any;
  clickOnBackBtn?: () => void;
  clickOnInfluencer: (influencer: any) => void;
  direct_tab?: boolean;
  state?: any;
  influencer_type?: any;
  selected_influencer?: any;
}

export const InfluencerList: React.FC<Props> = (props: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const [influencer_list, set_Influencer_list] = useState(props.data);

  useEffect(() => {
    set_Influencer_list(props.data);
  }, [props.data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(event.target.value);
  
    const filteredList = props.data.filter((influencer: any) =>
      (influencer.account_name && influencer.account_name.toLowerCase().includes(value)) ||
      (influencer.name && influencer.name.toLowerCase().includes(value))
    );
    
    set_Influencer_list(filteredList);
  };

  useBodyClass("page-btm-spc-exact")
  return (
    <div className="list-container-box">
      <div className="pro-inf-chat-label">
        <div className="search-inbox-chat">
          <TextField
            className="search-inbox-chat-text"
            fullWidth
            variant="outlined"
            placeholder="Search influencer..."
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className={`pro-inf-list-container-inbox`}>
        <div>
          {props.influencer_type === "brand" && !props.direct_tab && (
            <>
              {props.activeCampaign && (
                <li className="profile-li selected-campaign">
                  <div className="profile">
                    <img
                      className="profile-image-size"
                      src={
                        props.activeCampaign.campaign_cover_url
                          ? props.activeCampaign.campaign_cover_url
                          : DefaultUserProfile.influencer_unknown_default
                      }
                    />
                    <div className="influencer-details">
                      <span className="influencer-name">
                        <Tooltip
                          arrow
                          title={props.activeCampaign.campaign_title}
                        >
                          {props.activeCampaign.campaign_title}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <span
                    className="proposal-status"
                    onClick={props.clickOnBackBtn}
                  >
                    <ArrowBackIcon />
                  </span>
                </li>
              )}
              <ul className="profile-ul inbox-chat-ul">
                {influencer_list && influencer_list?.map((data: any, index: number) => (
                  <li
                    className={`profile-li ${
                      props.selected_influencer &&
                      props.selected_influencer.influencer_id ==
                        data.influencer_id
                        ? "active-profile"
                        : ""
                    }`}
                    key={index}
                    onClick={() => props.clickOnInfluencer(data)}
                  >
                    <div className="profile">
                      <img
                        className="profile-image-size"
                        src={
                          data.gcp_profile_image_url
                              || data.profile_image_url
                              || DefaultUserProfile.influencer_unknown_default
                      }                      
                      />
                      <div className="influencer-details">
                        <span className="influencer-name">
                          <Tooltip arrow title={data.name || data.account_name}>
                            <>{data.name || data.account_name}</>
                          </Tooltip>
                        </span>
                      </div>
                      {props.unreadMsg
                        .filter(
                          (msg: any) => msg.proposal_id == data.proposal_id
                        )
                        .map((msg: any, idx: number) => (
                          <span className="unread-msg">
                            {msg.unread_messages}
                          </span>
                        ))}
                    </div>
                    <div>
                      
                      <span className="proposal-status">{data.status}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}

          {props.influencer_type === "brand" && props.direct_tab && (
            <>
              <ul className="profile-ul inbox-chat-ul">
                {props.state && (
                  <li
                    className={`profile-li ${
                      props.selected_influencer &&
                      props.selected_influencer.influencer_id ==
                        props.state.influencer_id
                        ? "active-profile"
                        : ""
                    }`}
                    onClick={() => props.clickOnInfluencer(props.state)}
                  >
                    <div className="profile">
                      <img
                        className="profile-image-size"
                        src={
                          props.state.profile_image
                            ? props.state.profile_image
                            : DefaultUserProfile.influencer_unknown_default
                        }
                      />
                      <div className="influencer-details ">
                        <span className="influencer-name">
                          <Tooltip arrow title={props.state.name}>
                            {props.state.name}
                          </Tooltip>
                        </span>
                        <br />
                      </div>
                    </div>
                  </li>
                )}

                {influencer_list && influencer_list?.map((data: any, index: number) => (
                  <li
                    className={`profile-li ${
                      props.selected_influencer &&
                      props.selected_influencer.influencer_id ==
                        data.influencer_id
                        ? "active-profile"
                        : ""
                    }`}
                    key={index}
                    onClick={() => props.clickOnInfluencer(data)}
                  >
                    <div className="profile">
                      <img
                        className="profile-image-size"
                        src={
                          data.gcp_profile_image_url
                            ? data.gcp_profile_image_url
                            : data.profile_image_url
                            ? data.profile_image_url
                            : DefaultUserProfile.influencer_unknown_default
                        }
                      />
                      <div className="influencer-details">
                        <span className="influencer-name">
                          <Tooltip arrow title={data.name}>
                            {data.name}
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    {props.unreadMsg
                      .filter(
                        (msg: any) => msg.influencer_id == data.influencer_id
                      )
                      .map((msg: any, idx: number) => (
                        <span className="unread-msg">
                          {msg.unread_messages}
                        </span>
                      ))}
                  </li>
                ))}
              </ul>
            </>
          )}

          {props.influencer_type === "influencer" && props.direct_tab && (
            <>
              <ul className="profile-ul inbox-chat-ul">
                {influencer_list && influencer_list?.map((data: any, index: number) => (
                  <li
                    className={`profile-li ${
                      props.selected_influencer &&
                      props.selected_influencer.influencer_id ==
                        data.influencer_id
                        ? "active-profile"
                        : ""
                    }`}
                    key={index}
                    onClick={() => props.clickOnInfluencer(data)}
                  >
                    <div className="profile">
                      <img
                        className="profile-image-size"
                        src={data.photo || DefaultUserProfile.influencer_unknown_default}
                      />
                      <div className="influencer-details">
                        <span className="influencer-name">
                          <Tooltip arrow title={data.name}>
                            {data.name}
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    {props.unreadMsg
                      .filter(
                        (msg: any) => msg.influencer_id == data.influencer_id
                      )
                      .map((msg: any, idx: number) => (
                        <span className="unread-msg">
                          {msg.unread_messages}
                        </span>
                      ))}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
