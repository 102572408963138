import {HttpService} from './https.service';
import {BASE_URL} from '../../config/config';
import {
  ResponseType,
  UploadCreativeLinks,
  DeleteUploadedCreativeLinks,
  GetUploadedCreativeLinks,
} from 'custom-type';

const http = new HttpService();

export class ApplyCampaignService {
  static async proposalDetail({campaignId, smId}: any) {
    const path =
      BASE_URL + `/influencer/proposal?campaignId=${campaignId}&smId=${smId}`;
    // const path = ''
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async applyCampaign(data: any) {
    const path = BASE_URL + '/influencer/proposal/apply';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async influencerList(data: any) {
    const path = BASE_URL + '/brand/negotiate/influencers';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async brandProposalDetails(data: any) {
    const path = BASE_URL + '/brand/proposal/details';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  //

  async proposalNegotiate(data: any) {
    const path = BASE_URL + '/proposal/negotiate';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

   async acceptProposal(data: any) {
    const path = BASE_URL + '/proposal/accept';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

   async rejectProposal(data: any) {
    const path = BASE_URL + '/proposal/reject';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async uploadCreative(data: any): Promise<ResponseType> {
    const path = BASE_URL + '/influencer/creative/upload';
    try {
      const response = await http.postRequest(path, data, 'file');
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async brandShippingReceiptUpload(data: any) {
    const path = BASE_URL + '/brand/barter/product/upload';
    try {
      const response = await http.postRequest(path, data, 'file');
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateProposal(data: any) {
    const path = BASE_URL + '/proposal/update';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Update Proposal Status :: ${error}`);
    }
  }
  //--------------------------------------------- Creative ------------------------------------------//

  async influencerCreativeDetail(data: any) {
    const path = BASE_URL + '/influencer/creative/detail';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getInfluencerCreativeComments(data: any) {
    const path = BASE_URL + '/influencer/creative/getcomments';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }
  //get Brand Comments After Accepted Proposal
  async getBrandCreativeComments(creativeId: string | number) {
    const path = BASE_URL + `/brand/creative/getcomments/${creativeId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Get Creative Error :: ${error}`);
    }
  }

  //Send After Accepted Proposal
  async sendCreativeComments(data: any) {
    const path = BASE_URL + '/influencer/creative/comments';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  // Influencer Can upload live links (reels, story & shorts etc) creative when brand accept the creative
  async uploadCreativeLink(data: UploadCreativeLinks): Promise<ResponseType> {
    const path = BASE_URL + '/influencer/creative/link/upload';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  // Influencer can Delete Uploaded URL
  async deleteInfluencerCreativeLinks(
    data: DeleteUploadedCreativeLinks
  ): Promise<ResponseType> {
    const path = BASE_URL + '/influencer/creative/link/delete';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getInfluencerCreativeLinks(
    data: GetUploadedCreativeLinks
  ): Promise<ResponseType> {
    const path = BASE_URL + '/influencer/creative/link/get';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateDeliveryStatus(data: any) {
    const path = BASE_URL + '/influencer/product/delivery';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async creativeInfList(data: any) {
    const path = BASE_URL + '/brand/creative/influencerlist';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getbrandCreatives(data: any) {
    const path = BASE_URL + '/brand/creativeslist';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async brandCreativeAcceptReject(data: any) {
    const path = BASE_URL + '/brand/creatives/action';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async brandCampaignStatus(data: any) {
    const path = BASE_URL + '/brand/campaign/status';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateProposalShippingAdd(data: any) {
    const path = BASE_URL + '/influencer/update/proposal/address';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async deleteCampaign(data: {
    user_id: string | number;
    account_id: string | number;
    campaign_id: string | number;
  }) {
    const path = BASE_URL + '/brand/delete/campaign';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateCampaignStatus(data: {
    user_id: string | number;
    account_id: string | number;
    campaign_id: string | number;
    status: string;
    reason: string;
  }) {
    const path = BASE_URL + '/brand/campaign/update_status';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async _IsValidGSTIN(data: {
    user_id: string | number;
    account_id: string | number;
  }) {
    const path = BASE_URL + '/validate/gstin';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async _IsValidApplyProposal(data: {
    smId: string | number;
    campaignID: string | number;
  }) {
    const path =
      BASE_URL +
      `/isvalid/apply/campaign?campaignID=${data.campaignID}&smId=${data.smId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  // monthly foc campaign allowed check with count of foc campaign.
  async allowed_monthly_foc_campaign(data: {
    user_id: string | number;
    account_id: string | number;
    brand_id: string | number;
    influencer_id: string | number;
    campaign_id: any;
  }) {
    const path =
      BASE_URL +
      `/brand/allowed_monthly_foc_campaign?user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}&campaign_id=${data.campaign_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Error: allowed_monthly_foc_campaign :: ${error}`);
    }
  }

  // monthly foc campaign allowed check with count of foc campaign.
  async can_unarchive_campaign(data: {
    user_id: string | number;
    account_id: string | number;
    brand_id: string | number;
    influencer_id: string | number;
    campaign_id: any;
  }) {
    const path =
      BASE_URL +
      `/brand/campaign/canUnarchiveCampaign?user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}&campaign_id=${data.campaign_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Error: can_unarchive_campaign :: ${error}`);
    }
  }

  async uploadPostLinkAnalytics(data: any) {
    const path = BASE_URL + '/analytics/post/upload';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }


  async getChats(data: any) {
    const path = BASE_URL + '/proposal/getchats';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Send Chat In Proposal... :: ${error}`);
    }
  }

  async findCreatives(data: any) {
    const path = BASE_URL + '/proposal/creatives';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getTotalCreatives(data: any) {
    const path = BASE_URL + '/proposal/total_creatives';
    try {
      // const response = await http.getRequest(path, data);
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

// ----------------------------------------------------
  static async updateProposal(data: any) {
    const path = BASE_URL + '/proposal/updateStatus';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async getDeliverableHeader(data: any) {
    const path = BASE_URL + '/proposal/get_deliverable_header';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async shortListProposal(data: any) {
    const path = BASE_URL + '/proposal/shortlist';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async getCampaignDetails(data: any) {
    const path = BASE_URL + '/brand/campaign/details';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`getCampaignDetails :: ${error}`);
    }
  }

  // get the proposal read and unread status
  static async getUnreadProposalCreative(data: any) {
    const path = BASE_URL + '/unread/proposal-creative';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`getProposalStatus :: ${error}`);
    }
  }
  // SAVE ROI
  async saveROI(data: any) {
    const path = BASE_URL + '/analytics/roi';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Analytics ROI :: ${error}`);
    }
  }


  async getInvitedInfluencer(data: {
    campaignID: string | number;
  }) {
    const path =
      BASE_URL +
      `/get-invited-influencer?campaignID=${data.campaignID}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getPlatform({campaignId}: any) {
    const path = BASE_URL + `/campaignPlatform?campaignId=${campaignId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateBulkProposals(data: any) {
    const path = BASE_URL + '/proposal/bulk_update';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Update Proposal Status :: ${error}`);
    }
  }
}
