import React, { useState } from 'react';
import InputEmoji from 'react-input-emoji'; // Import the react-input-emoji component

const MultipleReplies = () => {
  const [isMultipleReplies, setIsMultipleReplies] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [handleReplyClick, setHandleReplyClick] = useState(false)
  const [newReply, setNewReply] = useState(''); // This handles the new reply being added
  const [replies, setReplies] = useState([
    "Sent the link over! 🌟",
    "Check your DM for the link! 📩",
    "Done! Please check your DM 😊",
  ]);

  // Function to handle reply change
  const handleReplyChange = (index: number, updatedReply: string) => {
    const updatedReplies = [...replies];
    updatedReplies[index] = updatedReply;
    setReplies(updatedReplies);
  };

  // Function to handle adding new reply
  const handleSubmitNewReply = (e: any) => {
    e.preventDefault();
    if (newReply.trim()) {
      setReplies([...replies, newReply]); // Add new reply to the array
      setNewReply(''); // Reset new reply input
      setShowReplyInput(false); // Hide input box
    }
  };

  return (
    <li>
      <span className='pb-3 d-inline-flex fs-14'>Would you like to set up a public reply in the feed?</span>
      <div className='custom-replies-container'>
        <div className="reply-options">
          <div className={`${isMultipleReplies ? 'reply-option-active' : ''}`}>
            <button 
              className={`reply-button btn btn-outline-primary ${isMultipleReplies ? 'active' : ''}`} 
              onClick={() => {setIsMultipleReplies(true)
                setHandleReplyClick(false)
              }}
            >
              Yes, multiple custom replies
            </button>

            {isMultipleReplies && (
              <div className='custom-replies-box'>
                <div className='custom-replies-box-yes'>
                  {replies.map((reply, index) => (
                    <div key={index} className='reply-item'>
                      <InputEmoji
                        value={reply} // Bind individual reply to InputEmoji component
                        onChange={(value) => handleReplyChange(index, value)} // Handle individual reply change
                        placeholder="Edit your reply"
                        // className="reply-input form-control mb-2"
                        shouldReturn={true} // Ensure onChange is called
                        shouldConvertEmojiToImage={false} // Keep emoji as text
                      />
                    </div>
                  ))}

                  {!showReplyInput && (
                    <div className='add-reply-btn btn btn-outline-primary' onClick={() => setShowReplyInput(true)}>
                      Add New Reply
                    </div>
                  )}

                  {showReplyInput && (
                    <form className='add-reply-form d-inline-flex gap-2' onSubmit={handleSubmitNewReply}>
                      <InputEmoji
                        value={newReply} // Bind new reply to InputEmoji component
                        onChange={setNewReply} // Set new reply as you type and add emojis
                        placeholder="Enter new reply"
                        // className="reply-input form-control"
                        shouldReturn={true} // Ensure onChange is called
                        shouldConvertEmojiToImage={false} // Keep emoji as text
                      />
                      <button type='submit' className='submit-reply-btn btn btn-primary btn-sm'>Submit</button>
                    </form>
                  )}
                </div>
              </div>
            )}
          </div>

          <button 
            className={`reply-button btn btn-outline-primary ${handleReplyClick ? "reply-active" : ""}`}
            onClick={() => {
              setIsMultipleReplies(false)
              setHandleReplyClick(true)
            }}
          >
            No
          </button>
        </div>
      </div>
    </li>
  );
};

export default MultipleReplies;
