import React from "react";
import { useState, useEffect } from "react";
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { ChatService } from "../../../core/services";
import { CampaignList } from "../../../components/common-inbox-chat/campaign-list";
import { InfluencerList } from "../../../components/common-inbox-chat/influencer-list";
import { ChatBox } from "../../../components/common-inbox-chat/chat-box";
import * as Util from "../../../core/utility";
import { useNavigate } from "react-router-dom";
import "./inbox-chat.scss";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { useMediaQuery } from "@mui/material";
import {
  SubscriptionService,
} from '../../../core/services';
import  UpgradeBrandPopUp from '../../../components/popup/upgrade-brand-popup'
interface Props {
  loading: (data: any) => any;
  userData: any;
}

const chatService: ChatService = new ChatService();
const methods: Util.Methods = new Util.Methods();
const subscriptionService: SubscriptionService = new SubscriptionService();

const CampaignMsg: React.FC<Props> = (props: Props) => {
  const [campaignList, setCamapignList] = useState([]);
  const [influencerList, setInfluencerList] = useState([]);
  const [showInfluencerList, setShowInfluencerList] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState<any>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [activeInfluencerChat, setActiveInfluencerChats] = useState({});
  const [campaignUnreadMsgData, setCampaignUnreadMsgData] = useState<any>([]);
  const [influencerUnreadMsgData, setInfluencerUnreadMsgData] = useState<any>([]);
  const [directUnreadMsgData, setDirectUnreadMsgData] = useState<any>([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [isBasicUser, setBasicUser] = useState(false);
  const [openUpgradeBrandInboxModal, setopenUpgradeBrandInboxModal] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatSendLoading, setChatSendLoading] = useState(false);

  useEffect(() => {
    if (props.userData) {
      getLeftDays();
    }
  }, [props.userData]);

  useEffect(() => {
    getUnreadMessage();
  }, []);

  useEffect(() => {
    if (selectedInfluencer) {
      getActiveChat();
    }
  }, [selectedInfluencer]);

  useEffect(() => {
    chatService
      .getChatCampaignListInbox({
        brand_id: props.userData.brand_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setCamapignList(res.data.rows);
        } else {
          setCamapignList([]);
        }
      });
  }, []);

  async function getLeftDays() {
    const { account_id, user_id, influencer_id } = props.userData;
    try {
      props.loading(true);
      const response =
        await subscriptionService.getSubscription_Remaining_Days({
          account_id: account_id,
          user_id: user_id,
          influencer_id: influencer_id
        });
      if (response.data.status.status) {
          setBasicUser(false);
      } else {
        setBasicUser(true);
        // navigate("/brand/dashboard")
      }
    } catch (error) {
      console.log('getLeftDays Error :: ' + JSON.stringify(error));
    }finally{
      props.loading(false);
    }
  }

  const clickOnCampaign = (campaign: any) => {
    setSelectedCampaign(campaign);
    getInfluencerList(campaign);
  };

  const clickOnBack = () => {
    setShowInfluencerList(false);
    setSelectedInfluencer(null);
    setActiveInfluencerChats({});
  };

  const clickOnInfluencer = (influencer: any) => {
    if(influencer.foc == 'true'){
      setopenUpgradeBrandInboxModal(!openUpgradeBrandInboxModal);
    }else{
    setSelectedInfluencer(influencer);
    }
  };

  const getInfluencerList = (campaign: any) => {
    chatService
      .getChatInfluencerList({
        platfrom_type: campaign.campaign_platform,
        campaignID: campaign.campaign_id,
        brandName: props.userData.brand_name,
        accountId: props.userData.account_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setInfluencerList(res.data.rows);
          setShowInfluencerList(true);
        } else {
          setInfluencerList([]);
          setShowInfluencerList(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching influencer list:", error);
        setInfluencerList([]);
        setShowInfluencerList(false);
      });
  };

  const getActiveChat = () => {
    setChatLoading(true);
    chatService
      .getInfluencerConversion({
        proposal_id: selectedInfluencer.proposal_id,
        account_id: props.userData.account_id,
      })
      .then((res) => {
        if (res.data.status) {
          setActiveInfluencerChats(methods.alignChat(res.data.rows));
          getUnreadMessage();
          setChatLoading(false);
        }
      })
      .catch((err) =>{
        console.log(`active influencer chat error in useEffect :: ${err}`)}
      ).finally(()=>setChatLoading(false))
  };

  function getUnreadMessage() {
    chatService
      .getUnReadMessageChat({ influencer_id: props.userData.influencer_id })
      .then((res) => {
        if (res.status === 1) {
          setCampaignUnreadMsgData(res.data.campaign_unread);
          setInfluencerUnreadMsgData(res.data.influencer_unread);
          setDirectUnreadMsgData(res.data.direct_unread);
        } else {
          setCampaignUnreadMsgData([]);
          setInfluencerUnreadMsgData([]);
          setDirectUnreadMsgData([]);
        }
      })
      .catch((error: any) => console.log(`getUnReadMessageChat :: ${error}`));
  }

  function handleSubmitChat(event: any) {
    try {
      event.preventDefault();
      let target: any = event.target;
      let values = Object.fromEntries(new FormData(target)) as {
        message: string;
      };
      if (values.message.replace(/\s+/g, "").length > 0) {
        setChatSendLoading(true);
        chatService
          .insertProposalMessage({
            proposal_id: selectedInfluencer?.proposal_id,
            comments: values.message,
            from: props.userData.brand_name,
            user_id: props.userData?.user_id ?? 0,
            account_id: props.userData?.account_id ?? 0,
            influencer_id: props.userData?.influencer_id ?? 0,
            sm_id: 0,
            receiver_influencer_id: selectedInfluencer?.influencer_id,
            // receiver_account_id: activeInfluencer?.account_id
          })
          .then((res) => {
            getActiveChat();
            event.target?.reset();
            setChatSendLoading(false);
          })
          .catch((error) => console.log(error))
          .finally(() => {setChatSendLoading(false);});
      }
    } catch (err) {
      throw new Error(`handleSubmitChat`);
    }
  }

  const onClickChatBackBtn = () => {
    setSelectedInfluencer(null);
  };

  const onClickDirectMsg = () => {
    if(isBasicUser){
      setopenUpgradeBrandInboxModal(!openUpgradeBrandInboxModal);
    }else{
      navigate("/brand/direct-msg");
    }
  }

  return (
    <section className="main-inbox-container">
      <h3 className="inbox-heading">Inbox</h3>
      <div className="btn-class-chat">
        <span
          className="btn active-chat-btn"
          onClick={() => navigate("/brand/campaign-msg")}
        >
          {" "}
          Campaign Messages
          <span className={`${campaignUnreadMsgData.length > 0 ? "unread-msg" : "" }`}>{campaignUnreadMsgData.length > 0 ? campaignUnreadMsgData[0].total_unread_messages : ''}</span>
        </span>
        <span className="btn" onClick={() => onClickDirectMsg()}>
          {" "}
          Direct Messages
          <span className={`${directUnreadMsgData.length > 0 ? "unread-msg" : "" }`}>{directUnreadMsgData.length > 0 ? directUnreadMsgData[0].total_unread_messages : ''}</span>
        </span>
      </div>
      <div className="main-chat-inbox-container">
        {isMobile ? (
          <div className="mobile-view">
            {!selectedInfluencer && !showInfluencerList && (
              <CampaignList
                data={campaignList}
                clickOnCampaign={clickOnCampaign}
                unreadMsg={campaignUnreadMsgData}
                influencer_type={'brand'}
              />
            )}

            {!selectedInfluencer && showInfluencerList && (
              <InfluencerList
                data={influencerList}
                selected_influencer={selectedInfluencer}
                activeCampaign={selectedCampaign}
                clickOnBackBtn={clickOnBack}
                clickOnInfluencer={clickOnInfluencer}
                direct_tab={false}
                influencer_type={"brand"}
                unreadMsg={influencerUnreadMsgData}
              />
            )}

            {selectedInfluencer && (
              <div className="chat-container">
                <ChatBox
                  campaign={null}
                  campaignDetail={selectedCampaign}
                  chats={activeInfluencerChat}
                  influencer={selectedInfluencer}
                  userData={props.userData}
                  onSubmit={handleSubmitChat}
                  direct_tab={false}
                  influencer_type={"brand"}
                  isMobile={isMobile}
                  onClickChatBackBtn={onClickChatBackBtn}
                  chatLoading={chatLoading}
                  chatSendLoading={chatSendLoading}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="list-container">
              {!showInfluencerList && (
                <CampaignList
                  data={campaignList}
                  clickOnCampaign={clickOnCampaign}
                  unreadMsg={campaignUnreadMsgData}
                  influencer_type={'brand'}
                />
              )}
              {showInfluencerList && (
                <InfluencerList
                  data={influencerList}
                  selected_influencer={selectedInfluencer}
                  activeCampaign={selectedCampaign}
                  clickOnBackBtn={clickOnBack}
                  clickOnInfluencer={clickOnInfluencer}
                  direct_tab={false}
                  influencer_type={"brand"}
                  unreadMsg={influencerUnreadMsgData}
                />
              )}
            </div>
            <div className="chat-container">
              {selectedInfluencer ? (
                <ChatBox
                  campaign={null}
                  campaignDetail={selectedCampaign}
                  chats={activeInfluencerChat}
                  influencer={selectedInfluencer}
                  userData={props.userData}
                  onSubmit={handleSubmitChat}
                  direct_tab={false}
                  influencer_type={"brand"}
                  chatLoading={chatLoading}
                  chatSendLoading={chatSendLoading}
                />
              ) : (
                <div className="inbox">
                  <div className="in-inbox">
                    <p className="icon">
                      <QuestionAnswerOutlinedIcon />
                    </p>
                    <p className="p-1">Nothing Selected</p>
                    <p className="p-2">
                      Please select a conversation from the list to view its
                      details.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {openUpgradeBrandInboxModal && (
        <>
        <UpgradeBrandPopUp
          openUpgradeBrandModal={openUpgradeBrandInboxModal}
          onClose={() => setopenUpgradeBrandInboxModal(!openUpgradeBrandInboxModal)}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignMsg);
export { connectToRedux as CampaignMsg };
