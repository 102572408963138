import React, { useEffect, useRef, useState } from 'react'
import MaterialSymbol from '../../../components/icons/material-symbol'
import { Link } from 'react-router-dom'
import { DefaultAccountLogo } from '../../../config/config'
import { Chip, IconButton } from '@mui/material'

import productImage from '../../../assets/images/product-img.png'
import productImage1 from '../../../assets/images/product-img1.png'
import SelectPostReel from './select-post-reel'
import KeywordInput from './keyword-input'
import MultipleReplies from './multiple-replies'
import ProductLinkSection from './product-link-section'

type Props = {}

const NewAutomationPost = (props: Props) => {
 const [showInput, setShowInput] = useState(false); // Toggle input visibility
  const [newKeyword, setNewKeyword] = useState<any>(''); // New keyword input state
  const [keywords, setKeywords] = useState<any>([]); // Store the list of keywords
 // Adding event listener to detect outside clicks
   const inputRef:any = useRef(null);
 
  // Detecting click outside the input
  const handleClickOutside = (e:any) => {
    // if (inputRef.current && !inputRef.current.contains(e.target)) {
    //   handleInputSubmit(); // Submit when clicking outside
    // }
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      if (newKeyword.trim() === '') {
        // If input is empty, just close the input without submitting
        setShowInput(false);
      } else {
        // Otherwise, submit the input
        handleInputSubmit();
      }
    }
  };

  // Detecting the 'Enter' key press to submit
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleInputSubmit(); // Submit when pressing Enter
    }
  };
  useEffect(() => {
    if (showInput) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showInput, newKeyword]);
  // Handle input toggle visibility
  const handleAddKeywordClick = () => {
    setShowInput(true);
    setTimeout(() => {
      inputRef.current?.focus(); // Focus the input when it appears
    }, 100);
  };

  // Handle input change
  const handleInputChange = (e:any) => {
    setNewKeyword(e.target.value);
  };

  // Handle input submission (adding the keyword to the array)
  const handleInputSubmit = () => {
    if (newKeyword.trim() !== '') {
      setKeywords([...keywords, newKeyword]); // Add new keyword to array
      setShowInput(false); // Hide input after submission
      setNewKeyword(''); // Clear input field
    }
  };

  // Handle delete for chips
  const handleDelete = (keywordToDelete:any) => {
    setKeywords(keywords.filter((keyword:any) => keyword !== keywordToDelete)); // Remove keyword from array
  };

  const [isMultipleReplies, setIsMultipleReplies] = useState(false); // To show the custom replies section
  const [showReplyInput, setShowReplyInput] = useState(false); // To show the input field
  const [newReply, setNewReply] = useState(''); // To hold the current input value
  const [replies, setReplies] = useState([ // Initial replies
    "Sent the link over! 🌟",
    "Check your DM for the link! 📩",
    "Done! Please check your DM 😊"
  ]);

  const [showProductLinkInput, setShowProductLinkInput] = useState(false); 
  // Toggle the 'multiple custom replies' section
  const handleMultipleRepliesClick = () => {
    setIsMultipleReplies(true);
  };

  // Toggle input visibility for adding a new reply
  const handleAddNewReplyClick = () => {
    setShowReplyInput(true);
  };

  // Handle input change for the new reply
  const handleReplyInputChange = (e:any) => {
    setNewReply(e.target.value);
  };

  // Handle form submission for adding a new reply
  const handleSubmitNewReply = (e:any) => {
    e.preventDefault(); // Prevent form submission default behavior
    if (newReply.trim()) {
      setReplies([...replies, newReply]); // Add the new reply to the list
      setNewReply(''); // Clear the input
      setShowReplyInput(false); // Hide the input field after submission
    }
  };

  const handleShowAddProductLink = () => {
    setShowProductLinkInput(true)
    setcustomizedImages(false)
  }

  const [product, setProduct] = useState({
    title: '',
    link: '',
    image: "",
    buttonTitle: "",
  });
  const [products, setProducts] = useState<any>([]);
  const [customizedImages, setcustomizedImages] = useState<boolean>(false);
   const fileInputRef = useRef<HTMLInputElement>(null);
    const handleDivClick = () => {
    // Add a null check before accessing fileInputRef.current
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadInputChange = (e:any) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleImageUpload = (e:any) => {
    const file = e.target.files[0];
    if (file) {
      setProduct({ ...product, image: URL.createObjectURL(file) });
    }
  };

  const handleAddProduct = () => {
    if (product.title && product.link && product.image) {
      setProducts([...products, product]);
      // Clear the input fields after adding the product
      setProduct({ title: '', link: '', image: '', buttonTitle:"" });
    } else {
      alert('Please provide all fields.');
    }
  };
  const handleCustomizedImages = () => {
  setcustomizedImages(true)
  setShowProductLinkInput(false)
  }

  return (
    <section className='new-automation-steps'>
       <ul className='new-automation-step-counter'>
        <strong className='d-flex align-items-center mb-5'>
        <MaterialSymbol iconFilled iconName='counter_1' fontweight='200' />
        <span className='d-flex flex-column '>Set Triggers
          <span className='fs-14 fw-400'>
            Choose the Post or Reel, set keywords and custom replies.
          </span>
        </span>
      </strong>
        <SelectPostReel />
        <KeywordInput />
        <MultipleReplies />
        <strong className='d-flex align-items-center mb-5 mt-5'>
        <MaterialSymbol iconFilled iconName='counter_2' fontweight='200' />
        <span className='d-flex flex-column '>Set Action
          <span className='fs-14 fw-400'>
            Specify the products/links you'd like to automatically send as a Instagram DM in response to users' comments.
          </span>
        </span>
      </strong>
        
          <ProductLinkSection />
        
      </ul>
        <div className='automation-action-btns'>
          <button className='btn btn-outline-primary me-auto'>
            Save as Draft
          </button>
          <button className='btn btn-outline-primary ms-auto me-2'>
            Preview
          </button>
          <button className='btn btn-primary'>
            Make Live
          </button>
        </div>
    </section>
  )
}

export default NewAutomationPost