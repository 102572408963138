import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation,useParams } from 'react-router-dom';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { ProductCard } from '../brand/clan-shop/product-card';
import './user.scss';
import HeadingStyle from '../../components/heading-style/heading-style';
import { LocalService } from '../../core/services/local.service';
import { useDispatch, connect } from "react-redux";
import { setGuestUser, setGuestUserId, setGuestUserEmail } from '../../redux/action';
interface Props{
    setGuestUser?: (data: any) => void;
    setGuestUserId?: (data: any) => void;
    setGuestUserEmail?: (data: any) => void;
}
const localService = new LocalService();
const ClanShopUser: React.FC<Props> = (props: Props) => {
    const dispatch =useDispatch();
    const [shopData, setShopData] = useState<any>();
    const [productsData, setProductsData] = useState<any>();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

   

    const {shop_id} = useParams();

    useEffect(() => {
        const guest_user_login:any = localService.getStorageItem("guest_user_login")
        const user_id:any = localService.getStorageItem("user_id")
        const user_email_id:any = localService.getStorageItem("user_email_id")
        if(guest_user_login && user_id){
            dispatch(setGuestUserId(user_email_id))
           dispatch(setGuestUserId(JSON.parse(user_id)))
            dispatch(setGuestUser(true));
        }
        ClanShopService.getShopDetails(shop_id)
            .then((res) => {
                if (res?.data?.rows) {
                    setShopData(res?.data?.rows);
                    ClanShopService.getShopProducts(shop_id)
                        .then((res) => {
                            setProductsData(res?.data?.rows);
                        })
                        .catch((e) => {
                            setProductsData([]);
                            setShopData([]);
                        });
                }

                // setShopData(res?.data?.rows);
            })
            .catch((e) => console.warn(e));
    }, []);

    const handleProductClick = (product: any) => {
        // Handle the product click event
        navigate(
            `/clanshop/product-details?shop_id=${shop_id}&product_id=${product.product_id}`
        );
    };

    return (
        <div className='clanshop-user-interface'>
            <div className='container'>
                <HeadingStyle
                    title={shopData && shopData[0]?.shop_name}
                    className='mb-3 d-block'
                />
                <ProductCard
                    data={productsData}
                    type='user'
                    onProductClick={handleProductClick}
                />
            </div>
        </div>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
      setGuestUser: (data: any) => dispatch(setGuestUser(data)),
      setGuestUserId: (data: any) => dispatch(setGuestUserId(data)),
      setGuestUserEmail: (data: any) => dispatch(setGuestUserEmail(data))
    };
};

const connectToRedux = connect(null, mapDispatchToProps)(ClanShopUser);

export { connectToRedux as ClanShopUser };
