import {
    UserReducerState,
    LoadingReducerState,
    LoginUserIdReducerState,
    LoginUserId,
    CreateCampaign,
    AccountLayer,
    UserLayer,
    CampaignEditable,
    SelectedCampaign,
    GuestUser,
    SubscriptionDetail
} from './user-reducer-state';
import {
    LOGINUSERID,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOADING,
    LOGOUT,
    ACCOUNTSWITCH,
    USERSWITCH,
    CREATECAMPAIGN,
    CAMPAIGNEDIT,
    SELECTEDINFLUENCERCAMP,
    GUEST_USER,
    UPDATE_USER_ID,
    USER_EMAIL_ID,
    ADDTOCART,
    REMOVEFROMCART,
    SET_CART,
    UPDATE_QUANTITY,
    UPDATE_TOTAL,
    SUBSCRIPTIONDETAIL
} from '../types';

import { CartReducerState } from './cart-reducer-state';
const initialState: UserReducerState = {
    IsUserLogged: false,
    UserData: null,
    tiggerLogin: false,
    loading: false,
    loginPopup: false,
};

const loadingInitialState: LoadingReducerState = {
    loading: false,
};

const loginUserIdInitialState: LoginUserIdReducerState = {
    loginUserId: null,
};

const SwitchLayer1: AccountLayer = {
    switchAccount: null,
};

const SwitchLayer2: UserLayer = {
    switchUser: null,
};

const login_userID: LoginUserId = {
    userID: null,
};

const cartInitialState: CartReducerState = {
    cartList: [],
    total: 0,
    itemQuantity: 0,
};


export const createCampaignInitialState: CreateCampaign = {
    activeTab: 1,
    navigation: {
        campaign_create: false,
        createProduct: false,
        influencer: false,
        post: false,
    },
    hiddenTab: {
        campaign_create: false,
        createProduct: true,
        influencer: false,
        post: false,
    },
    completeStep: {
        createPlan: false,
        campaign_create: false,
        createProduct: false,
        influencer: false,
        post: false,
    },
    createPlan: null,
    createCampaign: null,
    product: null,
    influencer: null,
    post: null,
};

export const allowEditCampaign: CampaignEditable = {
    plan: true,
    campaign: true,
    product: true,
    influencer: true,
    post: true,
};

export const selectedCampaignInfInitialState: SelectedCampaign = {
    campaign: null,
    proposal: null,
    creatives: null,
    analytics: null,
};

export const guestUserInitialState: GuestUser = {
    guest_user: false, // Assuming initial value is false
    user_id: 0,
    user_email_id: '',
};

const subscription: SubscriptionDetail = {
    subscription_detail: null
}
//subscription Details
export const _SubscriptionDetail:any = (state:SubscriptionDetail = subscription, action:any) => {
    switch(action.type){
        case SUBSCRIPTIONDETAIL:
            return {subscription_detail: action.data};
        default: 
        return state;
    }
};


export const InfSelectCamp: any = (
    state: SelectedCampaign = selectedCampaignInfInitialState,
    action: any
) => {
    switch (action.type) {
        case SELECTEDINFLUENCERCAMP:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const EditCampaign: any = (
    state: CampaignEditable = allowEditCampaign,
    action: any
) => {
    switch (action.type) {
        case CAMPAIGNEDIT:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const CreateCampaignReducer: any = (
    state: CreateCampaign = createCampaignInitialState,
    action: any
) => {
    switch (action.type) {
        case CREATECAMPAIGN:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const SwitchLayer1Reducer: any = (
    state: AccountLayer = SwitchLayer1,
    action: any
) => {
    switch (action.type) {
        case ACCOUNTSWITCH:
            return { switchAccount: action.data };
        default:
            return state;
    }
};

export const SwitchLayer2Reducer: any = (
    state: UserLayer = SwitchLayer2,
    action: any
) => {
    switch (action.type) {
        case USERSWITCH:
            return { switchUser: action.data };
        default:
            return state;
    }
};

export const UserReducer: any = (
    state: UserReducerState = initialState,
    action: any
) => {
    switch (action.type) {
        case LOGIN_ERROR:
            return { ...state, IsUserLogged: false };
        case LOGIN_SUCCESS:
            return { ...state, UserData: action.data, IsUserLogged: true };
        case LOGOUT:
            return { ...state, UserData: null, IsUserLogged: false };
        case LOADING:
            return { ...state };
        default:
            return state;
    }
};

export const LoadingReducer: any = (
    loading: LoadingReducerState = loadingInitialState,
    action: any
) => {
    switch (action.type) {
        case LOADING:
            return { loading: action.data };
        default:
            return loading;
    }
};

export const LoginUserIdReducer: any = (
    loginUserId: LoginUserIdReducerState = loginUserIdInitialState,
    action: any
) => {
    switch (action.type) {
        case LOGINUSERID:
            return { loginUserId: action.data };
        default:
            return loginUserId;
    }
};

export const UserID: any = (
    loginuserid: LoginUserId = login_userID,
    action: any
) => {
    switch (action.type) {
        case LOGINUSERID:
            return { userID: action.data };
        default:
            return loginuserid;
    }
};

export const guestUserReducer: any = (
    state: GuestUser = guestUserInitialState,
    action: any
) => {
    switch (action.type) {
        case GUEST_USER:
            const newState = {
                ...state,
                guest_user: action.payload,
            };

            return newState;
        case UPDATE_USER_ID:
            const user_id = {
                ...state,
                user_id: action.payload,
            };
            return user_id;
        case USER_EMAIL_ID:
            const user_email_id = {
                ...state,
                user_email_id: action.payload,
            };
            return user_email_id;
        default:
            return state;
    }
};
export const CartReducer: any = (
    state: CartReducerState = cartInitialState,
    action: any
) => {
    let updatedCartList;
    let updatedTotalQuantity;
    let totalAmount = 0;
    switch (action.type) {
        case ADDTOCART:
            // const shop_id = action.payload.shop_id;
            const productIdToAdd = action.payload.product_id;
            const isProductInCart = state?.cartList?.some(
                (item: any) => item.product_id === productIdToAdd
            );

            if (isProductInCart) {
                // Product is already in the cart, return the current state
                return state;
            }

            updatedCartList = [...state.cartList, action.payload];

            localStorage.setItem('cart', JSON.stringify(updatedCartList));

            return {
                ...state,
                cartList: updatedCartList,
                itemQuantity: state.itemQuantity + 1, // Increment quantity when adding
            };

        case REMOVEFROMCART:
            const productIdToRemove = action.payload;
            updatedCartList = state.cartList.filter(
                (item: any) => item.product_id !== productIdToRemove
            );

            // // Calculate the updated total quantity by summing the quantities of all items in the updated cart
            updatedTotalQuantity = updatedCartList.reduce(
                (total: any, item: any) => total + item.quantity,
                0
            );

            totalAmount = updatedCartList?.reduce((acc: any, product: any) => {
                const productCost = product.discounted_price || 0;
                const quantity = product.quantity || 0;

                // Check if the product's inventory is greater than 0 before adding it to the total
                if (product.inventory > 0) {
                    return acc + productCost * quantity;
                }

                return acc; // Exclude products with inventory 0 from the total
            }, 0);

            // // Update localStorage with the updated cart
            localStorage.setItem('cart', JSON.stringify(updatedCartList));

            return {
                ...state,
                cartList: updatedCartList,
                itemQuantity: updatedTotalQuantity, // Update the total quantity
                total: totalAmount,
            };
        case SET_CART:
            if (action.payload.isLoggedIn) {
                const updatedCartList = action.payload.cartList || [];

                totalAmount = updatedCartList?.reduce(
                    (acc: any, product: any) => {
                        const productCost = product.discounted_price || 0;
                        const quantity = product.quantity || 0;

                        // Check if the product's inventory is greater than 0 before adding it to the total
                        if (product.inventory > 0) {
                            return acc + productCost * quantity;
                        }

                        return acc; // Exclude products with inventory 0 from the total
                    },
                    0
                );

                return {
                    ...state,
                    cartList: updatedCartList,
                    total: totalAmount,
                };
            } else {
                // Logic for not logged-in user
                const savedCart = localStorage.getItem('cart');
                const parsedCart = savedCart ? JSON.parse(savedCart) : [];
                totalAmount = parsedCart?.reduce((acc: any, product: any) => {
                    const productCost = product.discounted_price || 0;
                    const quantity = product.quantity || 0;

                    // Check if the product's inventory is greater than 0 before adding it to the total
                    if (product.inventory > 0) {
                        return acc + productCost * quantity;
                    }

                    return acc; // Exclude products with inventory 0 from the total
                }, 0);

                return {
                    ...state,
                    cartList: parsedCart,
                    total: totalAmount,
                    // You can update total and other properties as needed for not logged-in users
                };
            }
        // updatedCartList = [...state.cartList, action.payload];

        // localStorage.setItem('cart', JSON.stringify(updatedCartList));
        // updatedCartList = action.payload.cartList;
        // return {
        //     ...state,
        //     // cartList: updatedCartList,
        //     // isLoggedIn: action.payload.isLoggedIn,
        //     // itemQuantity: updatedTotalQuantity, // Update the total quantity
        // };

        // // Handle other action types...
        case UPDATE_TOTAL:
            return {
                ...state,
                total: action.payload,
            };

        case UPDATE_QUANTITY:
            return {
                ...state,
                itemQuantity: action.payload,
            };
        default:
            return state;
    }

};
