import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MaterialSymbol from '../icons/material-symbol';

interface GenericSwipeableDrawerProps {
    drawerAnchor: 'left' | 'right' | 'top' | 'bottom';
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    title: string;
    content: React.ReactNode;
    className?:string,
    id?:string
}

const GenericSwipeableDrawer: React.FC<GenericSwipeableDrawerProps> = ({
    drawerAnchor,
    open,
    onClose,
    onOpen,
    title,
    content,
    className,id
}) => {
    const stopPropagation = (e:any) => {
        e.stopPropagation();
    };
    return (
        <SwipeableDrawer
            id={id}
            anchor={drawerAnchor}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            transitionDuration={300}
            className={`popup-drawer ${className}`}
            hysteresis={0.8}
            disableSwipeToOpen={true}
            disableDiscovery={true}
            onTouchStart={stopPropagation}
            onTouchMove={stopPropagation}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <div className='d-flex flex-wrap mb-3'>
                <div className='col popup-drawer-header'>
                    <span className='align-self-center'>{title}</span>

                    <IconButton
                        edge='end'
                        aria-label='close'
                        onClick={onClose}
                    >
                        <MaterialSymbol iconName='close' fontweight='200' />
                    </IconButton>
                </div>
            </div>
            <div className='d-flex flex-column p-3 px-4'>
                {content}
            </div>
        </SwipeableDrawer>
    );
};

export default GenericSwipeableDrawer;
