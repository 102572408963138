import React, { useState, useEffect } from "react";
import "./main-plan.scss";
import { useTheme } from "@mui/material/styles";
import { Tabs, Tab, Select, Radio, RadioGroup, FormControl, FormControlLabel, MenuItem, Button, InputAdornment, TextField, InputLabel, Grid } from "@mui/material";
import { CampaingList } from "../campaings/campaign-list/campaign-list";
import { connect } from "react-redux";
import { ListService, LocalService } from "../../../../core/services";
import HeadingStyleSecondary from '../../../../components/heading-style/heading-style-secondary';
import { useNavigate } from 'react-router-dom';
import CommonPopUp from '../../../../components/popup/common-popup';
import { CreateCampaign, Loading } from "../../../../redux/action";
import { createCampaignInitialState } from "../../../../redux/reducer/reducer";
import { createPortal } from 'react-dom';
import { MenuPropsSelect } from "../../../../core/utility/style";
import useBodyClass from "../../../../hooks/useBodyClass";
interface Props {
  UserReducer: any;
  loading: (status: any) => void;
  UserID: any;
  CreateCampaignReducer?: (data: any) => void;
}

const listService: ListService = new ListService();
const localService: LocalService = new LocalService();


const MainPlan: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const [value, setValue] = React.useState<number | null>(null);
  const [search, setSearch] = useState("");
  const [planList, setPlanList] = useState<any>([]);
  const [selectPlan, setSelectPlan] = useState("");
  const [createSelectedPlan, setCreateSelectedPlan] = useState("");
  const [classValue, setClassValue] = useState("primary");
  const [showPopup, setShowPopup] = useState(false);
  const [showPlanNeed, setShowPlanNeed] = useState(false)
  const [radiovalue, setRadioValue] = useState(1);
  const navigate = useNavigate()
  const [seletedPlanId, setSeletedPlanId] = useState(0);
  const [updatedPlan, setUpdatedPlan] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // If data-id is set on the target element
    const target = event.currentTarget as HTMLElement; // Cast to HTMLElement
    const dataId = target.getAttribute('data-id'); // Retrieve data-id attribute
    setSeletedPlanId(Number(dataId));
    setValue(newValue);
    setClassValue("outline-primary");
    planList.map((data: any, index: number) => {
      if (index === newValue) {
        setSelectPlan(data.name);
      }
    })
  };

  useEffect(() => {
    props.loading(true);
    try {
      listService.planList({
        user_id: props.UserReducer.user_id,
        brand_id: props.UserReducer.brand_id,
      })
        .then((res: any) => {
          props.loading(false);
          try {
            if (res.data.fetchedRows > 0) {
              setPlanList(res.data.rows);
              setRadioValue(2);
              setCreateSelectedPlan(res.data.rows[0].plan_id);
            } else {
            }
          } catch (err) {
            throw new Error(`Data Align Error :: ${err}`);
          }
        })
        .catch((e) => {
          props.loading(false);
          throw new Error(`PlanService planList function error :: ${e}`);
        });
    } catch (error) {
      throw new Error(`Plan list error :: ${JSON.stringify(error)}`);
    } finally {
    }
  }, [props.UserReducer.brand_id, props.UserReducer.user_id, updatedPlan]);


  const handleAddCampaign = (plan_id: any) => {
    listService.add_Campaign({
      brand_id: props.UserReducer.brand_id,
      user_id: props.UserReducer.user_id,
      plan_id: plan_id,
    }).then((res: any) => {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({
          ...createCampaignInitialState,
          createCampaign: {
            campaign_id: res?.data?.status.campaign_id,
          },
          createPlan: res?.data?.rows[0],
          navigation: {
            ...createCampaignInitialState.navigation,
            campaign_create: true,
          },
          completeStep: {
            ...createCampaignInitialState.completeStep,
            createPlan: true,
          },
        });

        navigate("/brand/campaign/createcampaign");
      }
    });
  };
  const handleAllPlanList = () => {
    setSeletedPlanId(0);
    setSelectPlan("");
    setValue(null);
    setClassValue("primary");
  };

  const handlePlanNeed = () => {
    setShowPlanNeed(prev => !prev)
  }
  const handleContinue = () => {
    if (radiovalue === 2) {
      //get Plan details
      if ((createSelectedPlan + "").length > 0) {
        handleAddCampaign(createSelectedPlan);
      } else {
        // toast.info("Please Select Plan.");
        localService.toastify("Please select plan", "info", 1000);
      }
    } else {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer(createCampaignInitialState);
      }
      navigate("/brand/campaign/createplan");
    }
  };
  useBodyClass("page-btm-spc-exact")

  const onCloseSelectedPlan = () => {
    setShowPopup(!showPopup)
    setCreateSelectedPlan(planList[0]?.plan_id);
  }

  const updatePlan = (plan_name: string) => {
    setUpdatedPlan(plan_name);
    setSelectPlan(plan_name);
  };
  return (
    <section className="main-plan-container">
      <div className="container">

        <div className="plans_tabs">
          <div className='plans_tabs--left'>
            <span className={`btn btn-mui btn-rounded btn-${classValue} btn-sm`} style={{ cursor: 'pointer' }} onClick={handleAllPlanList}><HeadingStyleSecondary title="All Plans" className="d-block" /></span>
          </div>
          <Tabs
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className="nav sub-tabs nav-rounded"
            TabIndicatorProps={{ hidden: true }}
          >
            {planList.map((res: any, index: number) => {
              return (
                <Tab
                  wrapped
                  className={`${index === value ? "nav-link active" : "nav-link"
                    }`}
                  key={index}
                  label={
                    <>{(res?.name.length > 30) ? (
                      <div className='scrolling-text-sec'>
                        <div className='inner-scrolling-text-container'>
                          <span className="inner-scrolling-text camp-title">
                            {res?.name}
                          </span>
                        </div>
                      </div>
                    )
                      : <span className="inner-scrolling-text camp-title">
                        {res?.name}
                      </span>
                    }
                    </>
                  }
                  data-id={res.plan_id}
                />
              );
            })}
          </Tabs>
        </div>

        {/* <HeadingStyle title="All Campaigns" className="mb-3 d-block" /> */}
        <Grid container spacing={2} className='mt-2 mb-4'>
          <Grid item xs={12} sm={8} md={9}>
            <TextField
              className="w-100 campaign-search input-common"
              label="Search"
              placeholder="Type a campaign name, budget etc."
              id="outlined-start-adornment"
              size="small"
              sx={{ m: 0 }}
              onChange={(e) => setSearch(e.target.value)}

            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {(() => {
              if (props.UserReducer.role_name != null && props.UserReducer.create_campaign === "False") {
                return (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setShowPopup(!showPopup)}
                      className="btn btn-primary me-2 disabled"
                    >
                      <span className='material-symbols-rounded me-1'>add</span> Create campaign
                    </Button>

                    {/* <NavLink to="/brand/campaign/createplan" className="btn btn-outline-primary btn-mui disabled">
                    Create Plan
                  </NavLink> */}

                  </>
                )
              }
              else {
                return (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setShowPopup(!showPopup)}
                      className="btn btn-primary me-2 w-100"
                    >
                      <span className='material-symbols-rounded me-1'>add</span>Create campaign
                    </Button>
                    {/* <NavLink to="/brand/campaign/createplan" className="btn btn-outline-primary btn-mui ">
                    Create Plan
                  </NavLink> */}

                  </>
                )
              }

            })()}
          </Grid>
        </Grid>
        <div className="_campaign_main_content">
          <CampaingList search={search} selectPlan={selectPlan} planId={seletedPlanId} updatePlan={updatePlan} />
        </div>
        {showPopup && (
          createPortal(<CommonPopUp
            modalTitle="Create campaign"
            modalContent=""
            openModal={showPopup}
            onClose={() => onCloseSelectedPlan()}
            className="popup-select-plan modal-medium">
            <div className="create_new_campaign_popup">
              <div className="row">
                <div className="col">
                  <span className="select_plan_campaign fw-600">
                    Select a plan for the Campaign
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <FormControl size="small">
                    <RadioGroup
                      onChange={(e: any) => setRadioValue(+e.target.value)}
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={radiovalue}
                      name="radio-buttons-group"
                    >
                      <div className='d-flex flex-wrap'>
                        <FormControlLabel
                          value={1}
                          checked={radiovalue === 1}

                          control={<Radio />}
                          label="New Plan"
                        />
                        <span className='btn btn-text-link align-self-center fs-12' onClick={handlePlanNeed} style={{ cursor: 'pointer' }}>Why is a plan needed?</span>
                        {showPlanNeed && (
                          <p className='fs-14'>Brands can create plans for their diverse products/services, each aiming to achieve different objectives such as driving awareness, performance, or engagement.</p>
                        )}
                      </div>

                      <FormControlLabel
                        value={2}
                        disabled={planList.length < 1}
                        control={<Radio />}
                        defaultChecked={planList.length > 0 && radiovalue === 2}
                        checked={planList.length > 0 && radiovalue === 2}
                        label="Existing Plan"
                      />

                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {(planList.length > 0 && (radiovalue === 2)) &&
                <div className="row mb-3">
                  <div className="col">
                    <FormControl
                      // disabled={(radiovalue !== 2 && (planList.length < 1)) ? true : false}
                      fullWidth
                      size="small"
                      className="pln_popup_select input-common input-style-select"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createSelectedPlan}
                        label="Select Plan"
                        onChange={(e) => setCreateSelectedPlan(e.target.value)}
                        inputProps={{
                          MenuProps: {
                            disableScrollLock: true,
                          },
                        }}
                        MenuProps={MenuPropsSelect}
                      >
                        {planList.length > 0 ? (
                          planList.map((res: any, index: number) => {
                            return (
                              <MenuItem key={index} value={res.plan_id}>
                                {res.name}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem key={1} value={1}>
                            {"No Data"}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              }
              <div className="col text-end plan_camp_btn_group popup-footer">
                <Button
                  className="btn btn-outline-primary btn-mui"
                  onClick={() => onCloseSelectedPlan()}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary btn-mui ms-2"
                  onClick={handleContinue}
                  variant="contained"
                >
                  Continue
                </Button>
              </div>
            </div>
          </CommonPopUp>, document.body)

        )}
      </div>
    </section >
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    campaignReducer: state.CreateCampaignReducer,
  };
};

const mapDispatchToState = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (status: boolean) => dispatch(Loading(status)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToState)(MainPlan);

export { connectToRedux as MainPlan };
