import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Tooltip
} from "@mui/material";
// For bank details section
import AccountDetails from "./account-details";
// For download invoice buttons
import RenderDownloadInvoiceButtons from "./render-download-invoice-buttons";
// For Acknowledge receipt form influencer side
import RenderAcknoledgeReceiptForm from "./render-acknowledge-receipt-form";
// important common popup component
import CommonPopUp from "../../../../../../components/popup/common-popup";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { FormValidation } from "../../../../../../core/utility";
import FileUploadPopup from "../../../../../../components/popup/file-upload-popup";
import { InvoiceService, LocalService } from "../../../../../../core/services";
import { LoadingButton } from "@mui/lab";

const formValidation = new FormValidation();
const invoiceService = new InvoiceService();
const localService = new LocalService();

interface ProposalPaymentTableProps {
  UserReducer: any;
  acceptedProposalPaymentDetails: Proposal[];
  selectedCampaign: any;
}

interface Proposal {
  name: string;
  total_price: number;
  payment_status: string;
  account_type?: string;
  account_holder_name?: string;
  account_number?: string;
  ifsc_code?: string;
  address?: string;
  influencer_id: number;
  proposal_id: number;
  file_urls: string;
}

interface FormData {
  user_id: string;
  account_id: string;
  influencer_id: string;
  campaign_id: string;
  proposal_id: string;
  amount: number;
  transaction_id: string;
  comments: string;
  file_type: string;
  file_name: string;
  storage_size: number;
  file_path: string;
  file_url: string;
  submitted: string;
}

interface FormError {
  amount: { status: boolean; message: string };
  transaction_id: { status: boolean; message: string };
  comments: { status: boolean; message: string };
  receipt: { status: boolean; message: string };
}

const ProposalPaymentTable: React.FC<ProposalPaymentTableProps> = ({ UserReducer, acceptedProposalPaymentDetails: initialAcceptedProposalPaymentDetails, selectedCampaign }) => {

  const path = `accounts/${selectedCampaign.influencerId}/proposal_receipts`;

  const [acceptedProposalPaymentDetails, setAcceptedProposalPaymentDetails] = useState(initialAcceptedProposalPaymentDetails);

  useEffect(() => {
    setAcceptedProposalPaymentDetails(initialAcceptedProposalPaymentDetails);
  }, [initialAcceptedProposalPaymentDetails]);

  const handleUpdatePaymentStatus = (updatedProposal: Proposal) => {
    setAcceptedProposalPaymentDetails((prevDetails) =>
      prevDetails.map((proposal) =>
        proposal.proposal_id === updatedProposal.proposal_id ? updatedProposal : proposal
      )
    );
  };

  // Modal form data
  const [formData, setFormData] = useState<FormData>({
    user_id: UserReducer.user_id,
    account_id: UserReducer.account_id,
    influencer_id: UserReducer.influencer_id,
    campaign_id: selectedCampaign.campaign_id,
    proposal_id: '',
    amount: 0,
    transaction_id: '',
    comments: '',
    file_type: '',
    file_name: '',
    storage_size: 0,
    file_path: '',
    file_url: '',
    submitted: 'false'
  });

  // Set form error fields
  const [formError, setFormErrors] = useState<FormError>({
    amount: { status: false, message: '' },
    transaction_id: { status: false, message: '' },
    comments: { status: false, message: '' },
    receipt: { status: false, message: '' }
  });

  // set Modal variables and usestate
  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openReceiptModal: false,
    openUploadReceiptFile: false
  });
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('Payment Advice');
  const handleOpenReceiptModal = () => setPopUps({ ...popUps, openReceiptModal: true });
  function handleCloseReceiptModal() {
    setPopUps({ ...popUps, openReceiptModal: false });
    setFormData({
      ...formData,
      proposal_id: '',
      amount: 0,
      transaction_id: '',
      comments: '',
      file_type: '',
      file_name: '',
      storage_size: 0,
      file_path: '',
      file_url: '',
      submitted: 'false'
    });
    setFormErrors({
      amount: { status: false, message: '' },
      transaction_id: { status: false, message: '' },
      comments: { status: false, message: '' },
      receipt: { status: false, message: '' }
    });
  }

  function handleCloseReceiptFileModal() {
    setPopUps({ ...popUps, openUploadReceiptFile: false });
  }

  const handleUploadReceipt = (event: any) => {
    setModalTitle('Payment Advice');
    const dataset = event.target.dataset;
    setFormData({
      ...formData,
      proposal_id: dataset.proposal_id,
    })
    // Open the modal
    handleOpenReceiptModal();
  };

  // Submit form logic

  // Form validation function
  const validateForm = (): boolean => {
    const errors: FormError = {
      amount: { status: false, message: '' },
      transaction_id: { status: false, message: '' },
      comments: { status: false, message: '' },
      receipt: { status: false, message: '' }
    };
    let isValid = true;

    if (formData.amount <= 0) {
      errors.amount = { status: true, message: '*Amount must be greater than 0' };
      isValid = false;
    }
    if (!formData.transaction_id) {
      errors.transaction_id = { status: true, message: '*Transaction ID is required' };
      isValid = false;
    }
    if (!formData.comments) {
      errors.comments = { status: true, message: '*Comments are required' };
      isValid = false;
    }
    if (!formData.file_url) {
      errors.receipt = { status: true, message: '*Receipt is required' };
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleConfirmation = async (e: any) => {
    try {
      e.preventDefault();
      // validate form
      if (!validateForm()) {
        return;
      }

      const updatedFormData = {
        ...formData,
        submitted: 'true'
      };
      setFormData(updatedFormData);

      const response = await invoiceService.uploadProposalReceipt(updatedFormData);
      const checkStatus = response.data.status.status ? 'success' : 'error';
      localService.toastify(response.data.status.message, checkStatus, 1000);
      // Close the modal after handling form submission
      handleCloseReceiptModal();
    } catch (error) {
      console.log('Upload Payment Advice Error :: ', JSON.stringify(error));
    } finally {
    }
  };

  const insertCampaignInvoiceDetail = async (e: any) => {
    try {
      const updatedFormData = {
        ...formData,
        file_url: e.url,
        file_path: e.key,
        file_type: e.fileType,
        file_name: e.fileName,
        storage_size: e.storage_size
      };
      setFormData(updatedFormData);

      // const response = await invoiceService.uploadProposalReceipt(updatedFormData);
      // const checkStatus = response.data.status.status ? 'success' : 'error';
      if (![undefined, null, ''].includes(e.url)) {
        setFormErrors({ ...formError, receipt: { status: false, message: '' } })
      }
      // localService.toastify(response.data.status.message, checkStatus, 1000);
      // handleCloseReceiptFileModal();
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      // setLoading(false);
      // getAcceptedProposalPaymentDetails();
      handleCloseReceiptFileModal();
    }
  };

  return (
    <div className="snapshot-wrapper snapshot-info d-flex flex-column infl-payout-detail">
      <strong className="snapshot-card-title">{UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id && 'Influencer'} Payout Details</strong>
      <div id="estimated-payment" className="est-pay-sec">
        <div className="snapshot-card-row">
          <div className="table-responsive">
            <TableContainer component={Paper}>
              <Table className="table table-bottom-influencer">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    {UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id && <TableCell style={{ minWidth: "150px" }}>Influencer Name</TableCell>}
                    {/* <TableCell style={{ minWidth: "90px" }}>Total Amount</TableCell> */}
                    <TableCell style={{ minWidth: "120px" }}>Due Amount (Excl Taxes)</TableCell>
                    <TableCell style={{ minWidth: "60px" }}>Payment Status</TableCell>
                    <TableCell style={{ minWidth: "200px" }}>Account Detail</TableCell>
                    <TableCell style={{ minWidth: "130px" }}>Invoices</TableCell>
                    <TableCell style={{ minWidth: "130px" }}>Payment Advice</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(acceptedProposalPaymentDetails?.length === 0) ? (
                    <TableRow>
                      <TableCell colSpan={8} className="text-center">No transaction details to view.</TableCell>
                    </TableRow>
                  ) : (
                    acceptedProposalPaymentDetails.map((proposal, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        {UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id && <TableCell>{proposal.name}</TableCell>}
                        {/* <TableCell>{proposal.total_price}</TableCell> */}
                        <TableCell>{proposal.total_price}</TableCell>
                        <TableCell>{proposal.payment_status}</TableCell>
                        <TableCell>
                          {proposal.account_type
                            ?
                            <AccountDetails account={proposal} />
                            :
                            'NA'
                          }
                        </TableCell>
                        <TableCell>
                          <RenderDownloadInvoiceButtons proposal={proposal} />
                        </TableCell>
                        <TableCell>
                          {
                            <>
                              {proposal.file_urls !== null &&
                                <RenderAcknoledgeReceiptForm
                                  proposal={proposal}
                                  onUpdatePaymentStatus={handleUpdatePaymentStatus} UserReducer={UserReducer}
                                  acceptedProposalPaymentDetails={acceptedProposalPaymentDetails}
                                />
                              }
                              {
                                (proposal.file_urls !== null && (UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id))
                                && (proposal.payment_status === 'Pending') &&
                                <Button
                                  className='btn btn-primary btn-sm'
                                  data-campaign_id={selectedCampaign.campaign_id}
                                  data-proposal_id={proposal.proposal_id}
                                  data-user_id={UserReducer.user_id}
                                  data-account_id={UserReducer.account_id}
                                  data-influencer_id={UserReducer.influencer_id}
                                  onClick={(e) =>
                                    handleUploadReceipt(e)
                                  }
                                >
                                  Upload
                                </Button>
                              }
                            </>
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {popUps.openReceiptModal && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={popUps.openReceiptModal}
          onClose={() => handleCloseReceiptModal()}
          className="modal-small payment-advice-modal"

        >
          <form onSubmit={handleConfirmation}>
            <TextField
              label="Amount"
              size="small"
              value={formData.amount}
              error={!formError.amount.status}
              helperText={formError.amount.message}
              className='w-100 mb-3'
              id='outlined-basic'
              variant='outlined'
              onChange={(e) => {
                let validate = formValidation.IsNumber(e.target.value)
                if (validate.status) {
                  setFormData({ ...formData, amount: Number(e.target.value) })
                };
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CurrencyRupeeIcon className='currencyIcon' />
                  </InputAdornment>
                ),
              }}
              onBlur={validateForm}
            />
            <TextField
              label="Transaction ID"
              value={formData.transaction_id}
              size="small"
              onChange={(e) => setFormData({ ...formData, transaction_id: e.target.value })}
              error={!formError.transaction_id.status}
              helperText={formError.transaction_id.message}
              className='w-100'
              id='outlined-basic'
              variant='outlined'
              onBlur={validateForm}
            />
            <TextField
              label="Comments"
              rows={3}
              multiline
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              error={!formError.comments.status}
              helperText={formError.comments.message}
              className='w-100 my-3'
              id='outlined-basic'
              variant='outlined'
              onBlur={validateForm}
            />
            {
              formData.file_name !== '' &&
              <p>
                <span className="me-2">{formData.file_name}</span>
                <Tooltip title={formData.file_name} arrow>
                  <NavLink target="_blank" key={`proposal_receipt`} to={formData.file_url} data-tip={formData.file_name}>
                    <i className="bi bi-file-earmark-text-fill"></i>
                  </NavLink>
                </Tooltip>
              </p>
            }

            <div className="row my-1" style={{ padding: '0px 11px' }}>
              <button type='button' className='btn btn-outline-primary px-2' onClick={() => setPopUps({ ...popUps, openUploadReceiptFile: true })}>Upload Payment Advice</button>
              {
                formError.receipt &&
                <p className="small opacity-50">{formError.receipt.message}</p>
              }
              <div className='popup-footer mt-0 ms-0 px-0'>
                <div className='row'>
                  <div className='col text-end'>
                    <button type="submit" className="btn btn-primary btn-mui">Send</button>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </CommonPopUp>
      )}

      {popUps.openUploadReceiptFile && (
        <FileUploadPopup
          openModal={popUps.openUploadReceiptFile}
          onClose={(e) => handleCloseReceiptFileModal()}
          onSend={(e: any) =>
            insertCampaignInvoiceDetail(e)
          }
          path={path}
          acceptedFileTypes=".doc, .docx, .pdf"
        />
      )}
    </div>
  );
};

export default ProposalPaymentTable;