    import React, { useState, useEffect } from 'react';
    import { connect } from 'react-redux';
    import { Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
    import { Instagram, KeyboardArrowDown, KeyboardArrowUp, YouTube } from '@mui/icons-material';
    import { LoadingButton } from '@mui/lab';
    import Table from "@mui/material/Table";
    import TableBody from "@mui/material/TableBody";
    import TableCell from "@mui/material/TableCell";
    import TableContainer from "@mui/material/TableContainer";
    import TableHead from "@mui/material/TableHead";
    import TableRow from "@mui/material/TableRow";
    import Paper from "@mui/material/Paper";
    import { CreateCampaign, Loading } from "../../../../../../redux/action";
    import { Counter } from '../../../../../../components/influencer-selector/influencer-selector';
    import { Card } from '../../../../../../components';
    import { PublicService, LocalService } from '../../../../../../core/services';
    import { FormValidation } from '../../../../../../core/utility';
    import './campaign-influencer-custom.scss';
import { MenuPropsSelect } from '../../../../../../core/utility/style';

    const publicService = new PublicService();
    const localService = new LocalService();
    const formValidation = new FormValidation();

interface Props {
    UserReducer: any;
    UserID: any;
    campaignReducer: any;
    CreateCampaignReducer?: (data: any) => void;
    loading?: (data: any) => void;
}


interface CustomDeliv {
    platformType: string;
    currentValue: any;
    updateValue: (data: any) => void;
}

const initialCheckBox = {
    ig_reels: false,
    ig_story: false,
    ig_carousel: false,
    yt_shorts: false,
    yt_dedicated: false,
    yt_integrated: false,
    digital_rights: false,
    exclusivity: false
}

const CustomDeliverables: React.FC<CustomDeliv> = (props: CustomDeliv) => {
    const [checkBox, setChecked] = useState(initialCheckBox);

    useEffect(() => {
        let count = 0;
        const updateCheckState = (property: string, value: boolean) => {
            count++;
            setChecked(prevState => ({ ...prevState, [property]: value }));
        };

        // Update check states based on props.currentValue
        if (props.currentValue) {
            updateCheckState('ig_reels', props.currentValue.ig_reels !== undefined && props.currentValue.ig_reels !== 0);
            updateCheckState('ig_story', props.currentValue.ig_story !== undefined && props.currentValue.ig_story !== 0);
            updateCheckState('ig_carousel', props.currentValue.ig_carousel !== undefined && props.currentValue.ig_carousel !== 0);
            updateCheckState('yt_shorts', props.currentValue.yt_shorts !== undefined && props.currentValue.yt_shorts !== 0);
            updateCheckState('yt_dedicated', props.currentValue.yt_dedicated !== undefined && props.currentValue.yt_dedicated !== 0);
            updateCheckState('yt_integrated', props.currentValue.yt_integrated !== undefined && props.currentValue.yt_integrated !== 0);
            updateCheckState('digital_rights', props.currentValue.digital_rights !== undefined && props.currentValue.digital_rights !== 0);
            updateCheckState('exclusivity', props.currentValue.exclusivity !== undefined && props.currentValue.exclusivity !== 0);
        }

    }, [props.currentValue]);

    // function updateCheckState(key: string, value: boolean) {
    //     setChecked((e: any) => {
    //         e[key] = value;
    //         return { ...e }
    //     })
    // }

    return (
        <div className="custom-deliverable ">
            <Card>
                <ul>
                    {/* //Instagram Section */}
                    {(props.platformType).toLocaleLowerCase() === 'instagram' &&
                        <>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(v, b) => {
                                                setChecked({ ...checkBox, ig_reels: b });
                                            }}
                                            checked={checkBox.ig_reels}
                                        />
                                    }
                                    label="Reel"
                                />
                                <Counter deliverables={props.currentValue.ig_reels} 
                                setDeliverables={(e) => props.updateValue({ ig_reels: e })} 
                                enable={checkBox.ig_reels} 
                                // enable={true}
                                />
                            </li>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(v, b) => {
                                                setChecked({ ...checkBox, ig_story: b });
                                            }}
                                            checked={checkBox.ig_story}
                                        />
                                    }
                                    label="Story"
                                />
                                <Counter deliverables={props.currentValue.ig_story} setDeliverables={(e) => props.updateValue({ ig_story: e })} enable={checkBox.ig_story} />
                            </li>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(v, b) => {
                                                setChecked({ ...checkBox, ig_carousel: b });
                                            }}
                                            checked={checkBox.ig_carousel}
                                        />
                                    }
                                    label="Carousel Image"
                                />
                                <Counter deliverables={props.currentValue.ig_carousel} setDeliverables={(e) => props.updateValue({ ig_carousel: e })} enable={checkBox.ig_carousel} />
                            </li>
                        </>
                    }
                    {/* //Youtube Section */}
                    {(props.platformType).toLocaleLowerCase() === 'youtube' &&
                        <>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(v, b) => {
                                                setChecked({ ...checkBox, yt_shorts: b });
                                            }}
                                            checked={checkBox.yt_shorts}
                                        />
                                    }
                                    label="Shorts"
                                />
                                <Counter deliverables={props.currentValue.yt_shorts} setDeliverables={(e) => props.updateValue({ yt_shorts: e })} enable={checkBox.yt_shorts} />
                            </li>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(v, b) => {
                                                setChecked({ ...checkBox, yt_dedicated: b });
                                            }}
                                            checked={checkBox.yt_dedicated}
                                        />
                                    }
                                    label="Dedicated Video"
                                />
                                <Counter deliverables={props.currentValue.yt_dedicated} setDeliverables={(e) => props.updateValue({ yt_dedicated: e })} enable={checkBox.yt_dedicated} />
                            </li>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(v, b) => {
                                                setChecked({ ...checkBox, yt_integrated: b });
                                            }}
                                            checked={checkBox.yt_integrated}
                                        />
                                    }
                                    label="Integrated Video"
                                />
                                <Counter deliverables={props.currentValue.yt_integrated} setDeliverables={(e) => props.updateValue({ yt_integrated: e })} enable={checkBox.yt_integrated} />
                            </li>
                        </>
                    }
                    <li>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(v, b) => {
                                        setChecked({ ...checkBox, digital_rights: b });
                                    }}
                                    checked={checkBox.digital_rights}
                                />
                            }
                            label="Digital Rights"
                        />
                        <FormControl
                            size="small"
                            disabled={!checkBox.digital_rights}
                        >
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="id_digital_rights"
                                value={
                                    props.currentValue?.["digital_rights"]
                                        ? props.currentValue?.[
                                        "digital_rights"
                                        ]
                                        : 0
                                }
                                label=""
                                onChange={(e) =>
                                    props.updateValue({
                                        digital_rights: e.target.value,
                                    })
                                }
                                MenuProps={MenuPropsSelect}
                            >
                                <MenuItem value={0}>
                                    Select Duration
                                </MenuItem>
                                <MenuItem value={15}>15 Days</MenuItem>
                                <MenuItem value={30}>30 Days</MenuItem>
                                <MenuItem value={90}>90 Days</MenuItem>
                                <MenuItem value={180}>180 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </li>
                    <li>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(v, b) => {
                                        setChecked({ ...checkBox, exclusivity: b });
                                    }}
                                    checked={checkBox.exclusivity}
                                />
                            }
                            label="Exclusivity"
                        />
                        <FormControl
                            size="small"
                            disabled={!checkBox.exclusivity}
                        >
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="id_exclusivity"
                                value={
                                    props.currentValue?.["exclusivity"]
                                        ? props.currentValue?.[
                                        "exclusivity"
                                        ]
                                        : 0
                                }
                                label=""
                                onChange={(e) =>
                                    props.updateValue({
                                        exclusivity: e.target.value,
                                    })
                                }
                                MenuProps={MenuPropsSelect}
                            >
                                <MenuItem value={0}>
                                    Select Duration
                                </MenuItem>
                                <MenuItem value={15}>15 Days</MenuItem>
                                <MenuItem value={30}>30 Days</MenuItem>
                                <MenuItem value={90}>90 Days</MenuItem>
                                <MenuItem value={180}>180 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </li>
                </ul>
            </Card>
        </div>
    )
}


const initialDeliverables = {
    contactType: 'Influencer',
    talentPartner: '',
    influencerName: '',
    influencerUsername: '',
    phone: '',
    email: '',
    deliverables: {}
};

interface ExpandableProps {
    children: any;
    expandableComponent: any;
    colSpan: number;
}

const ExpandableTableRow: React.FC<ExpandableProps> = (props: ExpandableProps) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {props.children}
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell colSpan={props.colSpan}>{props.expandableComponent}</TableCell>
                </TableRow>
            )}
        </>
    );
};

interface ExtendableElementProps {
    deliverables: any;
    platformType: string;
    updateDeliverables: (data: any) => void;
}

const ExtendableElement: React.FC<ExtendableElementProps> = (props: ExtendableElementProps) => {
    return (
        <div className="extendable-container">
            <div className="row">
                <div className="col">
                    <Card>
                        <div className="row">
                            <div className="col">
                                {JSON.stringify(props.deliverables.deliverables)}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                Name
                            </div>
                            <div className="col">
                                {props.deliverables.influencerName}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                Email
                            </div>
                            <div className="col">
                                {props.deliverables.email}
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col">
                    {/* //Deliverables */}
                    <CustomDeliverables
                        platformType={props.platformType}
                        currentValue={props.deliverables.deliverables}
                        updateValue={props.updateDeliverables} />
                </div>
            </div>
        </div>
    )
}

const CampaingInfluencerCustom: React.FC<Props> = (props: Props) => {
    const [deliverables, setDeliverables] = useState<any>(initialDeliverables);
    const [influencerData, setInfluencerData] = useState<any>([]);
    const [platformType] = useState(props.campaignReducer.createCampaign.campaign_platform);
    const [verifyInfluencer, setVerifyInfluencer] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        influencerName: { status: true, message: '' },
        influencerUsername: { status: true, message: '' },
        phone: { status: true, message: '' },
        email: { status: true, message: '' },
        verify: { status: true, message: '' }
    })

    function handleSetDeliverables(e: any) {
        setDeliverables((v: any) => {
            v.deliverables = { ...v.deliverables, ...e };
            return { ...v }
        });
    }

    function addInfluencerData() {

        // let keys = Object.keys(deliverables).forEach((keys: string) => {
        //     if (keys !== 'deliverables') {
        //         if (keys !== 'talentPartner') {
        //             const validate = formValidation.GetNameValidate(deliverables[keys]);
        //             setFieldErrors((e: any) => {
        //                 e[keys] = validate;
        //                 return { ...e }
        //             })
        //         }
        //     }
        // });


        const validateInfluencerName = formValidation.GetNameValidate(deliverables.influencerName);
        const validateInfluencerUsername = formValidation.GetNameValidate(deliverables.influencerUsername);
        const validatePhone = formValidation.GetMobileControlIsValid(deliverables.phone);
        const validateEmail = formValidation.GetEmailControlIsValid(deliverables.email);
        const validatetalentPartner = formValidation.GetNameValidate(deliverables.talentPartner);
        setFieldErrors(
            {
                ...fieldErrors,
                influencerName: validateInfluencerName,
                influencerUsername: validateInfluencerUsername,
                phone: validatePhone,
                email: validateEmail,


            }
        )

        if (validateInfluencerName.status && validateInfluencerUsername.status && validatePhone.status && validateEmail.status) {
            setInfluencerData((oldData: any) => [...oldData, deliverables]);
            setDeliverables(initialDeliverables);
            setVerifyInfluencer(null);
        }
    };

    function removeInfluencerData(index: number) {
        let temp = [...influencerData];
        temp.splice(index, 1);
        setInfluencerData(temp);
    };

    function updateInfluencerData(updateValue: any, index: number) {
        try {
            let temp = [...influencerData];
            let key = Object.keys(updateValue)[0];
            let value = Object.values(updateValue)[0];
            temp[index].deliverables[key] = value;
            //[key] = value;
            setInfluencerData(temp);

        } catch (error) {
            throw new Error(`updateInfluencer Data error :: ${error}`)
        }
    };

    const handleInstagramProfile = async () => {
        setLoading(true);
        try {

            if (deliverables.influencerUsername) {
                const response = await publicService.instagramVerify(deliverables.influencerUsername);

                if (response?.status ?? false) {
                    localService.toastify(response.info, 'success', 1000);
                    setVerifyInfluencer(response.data);
                    //verify Status Update;
                    setFieldErrors(res => {
                        res.verify.status = true;
                        return { ...res }
                    })
                } else {
                    localService.toastify(response.info, 'error', 1000);
                }
            }

        } catch (error) {
            throw new Error(`handleInstagramProfile Error :: ${error}`)
        } finally {
            setLoading(false);
        }
    }

    const handleYoutubeProfile = async () => {
        setLoading(true);
        try {

            if (deliverables.influencerUsername) {
                const response = await publicService.youtubeVerify(deliverables.influencerUsername);
                if (response?.status === 1) {
                    localService.toastify(response.info, 'success', 1000);
                    setVerifyInfluencer(response.data);
                    //verify Status Update;
                    setFieldErrors(res => {
                        res.verify.status = true;
                        return { ...res }
                    })
                } else {
                    localService.toastify(response.info, 'error', 1000);
                }
            }

        } catch (error) {
            throw new Error(`handleYoutubeProfile Error :: ${error}`)
        } finally {
            setLoading(false);
        }
    }

    const handleInfluencerType = () => {
        try {
            if ((platformType + '').toLocaleLowerCase() === 'instagram') {
                handleInstagramProfile();
            } else if ((platformType + '').toLocaleLowerCase() === 'youtube') {
                handleYoutubeProfile();
            }
        } catch (error) {
            throw new Error(`handleInfluencerType Error ::  ${error}`)
        }
    }

    return (
        <div className="custom-infl-main-container">
            <button type='button' onClick={() => console.log('check formData ==>', deliverables)}>Deliverables</button>
            <button type='button' onClick={() => console.log('Influencer Data ==>', influencerData)}>Get Influencer Data</button>
            <span>{(platformType + '').toLocaleLowerCase() === 'instagram' ? <Instagram style={{ fontSize: '50px' }} className='insta-icon' /> : <YouTube style={{ color: 'red', fontSize: '50px' }} />}</span>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col mt-3">
                            <span><strong>Influencer Details</strong></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Card className='mt-3'>
                                <div className="row mt-3">
                                    <div className="col">
                                        <FormControl fullWidth size='small' >
                                            <InputLabel id="demo-simple-select-label">Contact Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={deliverables.contactType}
                                                label="Contact Type"
                                                onChange={(e) => setDeliverables({ ...deliverables, contactType: e.target.value })}
                                                MenuProps={MenuPropsSelect}
                                            >
                                                <MenuItem value='Influencer'>Influencer</MenuItem>
                                                <MenuItem value='Talent Partner / Manager'>Talent Partner / Manager</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col">
                                        <TextField size="small"
                                            onChange={(e) => setDeliverables({ ...deliverables, influencerName: e.target.value })}
                                            value={deliverables.influencerName}
                                            error={!fieldErrors.influencerName.status}
                                            className='w-100'
                                            id="id_Influencer"
                                            name="influencer"
                                            label="Influencer Name"
                                            variant="outlined" />
                                    </div>
                                    <div className="col">
                                        <TextField size="small"
                                            onChange={(e) => setDeliverables({ ...deliverables, influencerUsername: e.target.value })}
                                            value={deliverables.influencerUsername}
                                            error={!fieldErrors.influencerUsername.status}
                                            className='w-100'
                                            id="id_Influencer"
                                            name="influencer"
                                            label="Influencer username"
                                            disabled={verifyInfluencer ? true : false}
                                            variant="outlined" />
                                    </div>
                                    <div className="col-auto">
                                        <LoadingButton
                                            loading={loading}
                                            className="btn"
                                            loadingPosition="start"
                                            variant="outlined"
                                            onClick={handleInfluencerType}
                                            disabled={verifyInfluencer ? true : false}
                                        >
                                            Verify
                                        </LoadingButton>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto contactType">
                                        <span>{deliverables.contactType}</span>
                                    </div>
                                    {deliverables.contactType === 'Talent Partner / Manager' && <div className="col">
                                        <TextField size="small"
                                            onChange={(e) => setDeliverables({ ...deliverables, talentPartner: e.target.value })}
                                            value={deliverables.talentPartner}
                                            className='w-100'
                                            id="id_Talent"
                                            name="talent"
                                            label="Talent partner / Manager Name"
                                            variant="outlined" />
                                    </div>}
                                    <div className="col">
                                        <TextField size="small"
                                            onChange={(e) => setDeliverables({ ...deliverables, phone: e.target.value })}
                                            value={deliverables.phone}
                                            error={!fieldErrors.phone.status}
                                            className='w-100'
                                            id="id_Phone"
                                            name="phone"
                                            label="Phone *"
                                            variant="outlined" />
                                    </div>
                                    <div className="col">
                                        <TextField size="small"
                                            onChange={(e) => setDeliverables({ ...deliverables, email: e.target.value })}
                                            value={deliverables.email}
                                            error={!fieldErrors.email.status}
                                            className='w-100'
                                            id="id_Email"
                                            name="email"
                                            label="Email"
                                            variant="outlined" />
                                    </div>
                                </div>
                            </Card>
                            {verifyInfluencer && <Card>
                                {(platformType + '').toLocaleLowerCase() === 'instagram' && <div className="row">
                                    <div className="col">
                                        <ul>
                                            <li>Name: <span><strong>{verifyInfluencer.name}</strong></span></li>
                                            <li>Username: <span><strong>{verifyInfluencer.insta_username}</strong></span></li>
                                            <li>Biography: <span><strong>{verifyInfluencer.biography}</strong></span></li>
                                            <li>Category: <span><strong>{verifyInfluencer.category}</strong></span></li>
                                            <li>Followers Count: <span><strong>{verifyInfluencer.followers_count}</strong></span></li>
                                        </ul>
                                    </div>
                                </div>}
                                {(platformType + '').toLocaleLowerCase() === 'youtube' && <div className="row">
                                    <div className="col">
                                        <ul>
                                            <li>Channel Name: <span><strong>{verifyInfluencer.channel_name}</strong></span></li>
                                            <li>Channel Id: <span><strong>{verifyInfluencer.channel_id}</strong></span></li>
                                            <li>Custom Url: <span><strong>{verifyInfluencer.custom_url}</strong></span></li>
                                            <li>Country: <span><strong>{verifyInfluencer.country}</strong></span></li>
                                            <li>Subscribers: <span><strong>{verifyInfluencer.subscriber_count}</strong></span></li>
                                            <li>Videos: <span><strong>{verifyInfluencer.video_count}</strong></span></li>
                                            <li>Description <span><strong>{verifyInfluencer.description}</strong></span></li>
                                        </ul>
                                    </div>
                                </div>}
                            </Card>}
                        </div>
                        <div className="col">

                            <div className="row mt-3">
                                {/* Deliverable */}
                                <div className="col">
                                    <CustomDeliverables
                                        platformType={platformType}
                                        currentValue={deliverables.deliverables} updateValue={handleSetDeliverables} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-end">
                                    <Button
                                        variant="contained"
                                        className="btn btn-primary"
                                        onClick={addInfluencerData}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* //table Section */}
                    {influencerData.length > 0 && <div className="row mt-3">
                        <div className="col">
                            <TableContainer className="table-plan" component={Paper}>
                                <Table
                                    stickyHeader
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" />
                                            <TableCell align="left">Contact Type</TableCell>
                                            <TableCell align="left">Username</TableCell>
                                            <TableCell align="left">Phone</TableCell>
                                            <TableCell align="left">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="pln_table_body">
                                        {influencerData.map((data: any, index: number) => {
                                            return (
                                                <ExpandableTableRow
                                                    key={index}
                                                    expandableComponent={
                                                        <ExtendableElement
                                                            updateDeliverables={(e) => updateInfluencerData(e, index)}
                                                            platformType={platformType}
                                                            deliverables={data} />}
                                                    colSpan={4} >
                                                    <TableCell align="left">{data.contactType}</TableCell>
                                                    <TableCell align="left">{data.influencerUsername}</TableCell>
                                                    <TableCell align="left">{data.phone}</TableCell>
                                                    <TableCell align="left">
                                                        <span className='remove-influencer' onClick={() => removeInfluencerData(index)}>Remove</span>
                                                    </TableCell>
                                                </ExpandableTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>}
                    <div className="row mt-3">
                        <div className="col-auto">
                            <Checkbox inputProps={{ "aria-label": 'Checkbox demo' }} />
                        </div>
                        <div className="col">
                            <p>
                                By checking this box, I confirm that I fully accept the responsibility for the accuracy and integrity of the given details.
                                I understand that any consequences or liabilities resulting from the information provided are solely my own. Furthermore,
                                I affirm that the provided information will not be utilized for spamming purposes.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (data: any) => dispatch(Loading(data))
    }
}

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(CampaingInfluencerCustom);

export { connectToRedux as CampaingInfluencerCustom };