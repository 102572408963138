import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import dummyImg from '../../../assets/images/avtar_male.png';

interface Props {
    platform: string;
    name: string;
    username: string;
    profile_image: string;
    description: string;
    subscriber: number;
    total_media: number;
    follows: number;
}
const InfluencerProfileCard: React.FC<Props> = (props: Props) => {
    const [platform, setPlatform] = useState(
        props.platform === 'instagram' ? true : false
    );
    return (
        <Card className='infl-signup-profile-card' sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardMedia
                    width={'auto !important'}
                    component='img'
                    //   height="140"
                    //   crossOrigin="anonymous"
                    image={
                        props?.profile_image ? props?.profile_image : dummyImg
                    }
                    alt='profile_img'
                />
                <CardContent>
                    <h3 className='mb-0 infl-signup-profile-title'>
                        {props?.name}
                    </h3>
                    <p className='infl-signup-profile-username'>
                        {props?.username}
                    </p>
                    {props.platform && props.subscriber && (
                        <div className='infl-signup-profile-platform'>
                            <span>
                                {props.platform === 'instagram'
                                    ? 'Followers'
                                    : 'Subscribers'}
                            </span>
                            <span>{props.subscriber}</span>
                        </div>
                    )}
                    {platform && props.follows && (
                        <div className='infl-signup-profile-follower'>
                            <span>Follows</span>
                            <span>{props.follows}</span>
                        </div>
                    )}
                    {props.total_media && (
                        <div className='infl-signup-profile-media'>
                            <span>
                                {props.platform === 'instagram'
                                    ? 'Total Media'
                                    : 'Total Videos'}
                            </span>
                            <span>{props.total_media}</span>
                        </div>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default InfluencerProfileCard;
