import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { UserService } from "../../../core/services";
import { connect } from "react-redux";
import { FormValidation } from "../../../core/utility";
import { LocalService } from '../../../core/services';

const userService = new UserService();
const localService = new LocalService();
interface Props {
  UserData: any;
}
interface ErrorTypes {
  status: boolean;
  message: string;
}
const PinSecurity: React.FC<Props> = (props: Props) => {
  const [currentPin, setCurrentPin] = useState("");
  const [newpin, setNewpin] = useState("");
  const [confirmpin, setConfirmpin] = useState("");
  const [errorPin, setErrorPin] = useState<ErrorTypes>();
  const formValidation = new FormValidation();
  const [confirmPINError, setConfirmPINError] = useState<ErrorTypes>({
    status: false,
    message: "",
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorPin(formValidation.GetPinIsValid(newpin));
    const isValidPin = formValidation.GetPinIsValid(`${newpin}`).status
    if (isValidPin) {

      if (newpin === currentPin || confirmpin === currentPin) {
        localService.toastify("Current Pin should not match with new PIN", "error", 1000);
        return

      }
      else if (newpin != confirmpin) {
        setConfirmPINError({
          status: true,
          message: "PINs do not match",
        })
      }
      else if (newpin !== currentPin && newpin.length > 0 && newpin == confirmpin) {
        setConfirmPINError({
          status: false,
          message: "",
        })
        let user_id = props.UserData.user_id;
        const response = await userService.updatePin({
          data: {
            old_pin: currentPin,
            new_pin: newpin,
            user_id: user_id,
          },
        });
        if (response.data.affectedRows > 0) {
          // toast.success("Pin updated successfully");
          localService.toastify("PIN Updated", "success", 1000);
          setCurrentPin("");
          setNewpin("");
          setConfirmpin("");
        }
        // else {
        //   // toast.error("Pin update failed due to wrong pin");
        // }
      }
      else {
        localService.toastify("Pin update failed", "error", 1000);
      }
    }
  };

  return (
    <>
      <span className='fs-16 d-block' style={{ marginBottom: "10px" }}>Pin</span>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              label="Current pin"
              value={currentPin}
              onChange={(event) => setCurrentPin(event.target.value)}
              fullWidth
              size="small"
              type="pin"
              className='input-common'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              type="pin"
              label="New pin"
              value={newpin}
              onChange={(event) => setNewpin(event.target.value)}
              fullWidth
              size="small"
              className='input-common'
            />
            <span className="error">{errorPin?.message}</span>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              type="pin"
              label="Confirm pin"
              value={confirmpin}
              onChange={(event) => setConfirmpin(event.target.value)}
              fullWidth
              size="small"
              className='input-common'
            />
            {confirmPINError.status && <span className="error">{confirmPINError.message}</span>}
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          className="btn btn-outline-primary upload_button  "
          sx={{ mt: 2 }}
        >
          Update Pin
        </Button>
      </form>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserData: state.UserReducer.UserData,
  };
};
const connectToRedux = connect(mapStateToProps, null)(PinSecurity);
export { connectToRedux as PinSecurity };
