import React, { useState } from 'react'
import { TableComponent } from '../../../../../components/table-component/table'
import { IconButton, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Methods } from '../../../../../core/utility';
import { Link } from 'react-router-dom';
import MaterialSymbol from '../../../../../components/icons/material-symbol';


type Props = {
  postFilterData?: any;
  columnDisplayName: any;
  orderByColumnName?: any;
  inputDataSource: any;
  sortingColumnType: any;
  renderData: any;
  setRenderData: any;
  selectCampaign?: any;
  postAnalyticsEr?: any;
  selectDeleteRecord?: any;
  handleOpenDeleteURL?:any;
  platform?:any;
  sampleData?:boolean;
}

const methods: Methods = new Methods()

const SampleDetailedInsightsPost = (props: Props) => {
  return (
    <>
      {
        (() => {
          if (props?.inputDataSource.length > 0) {
            return (
              <>
                <TableComponent
                  columnDisplayName={props?.columnDisplayName}
                  orderByColumnName=""
                  inputDataSource={props?.inputDataSource}
                  sortingColumnType={props?.sortingColumnType}
                  renderData={props?.renderData}
                  setRenderData={props?.renderData}
                >
                  {props?.inputDataSource?.map((data: any, index: number) => {

                    return (

                      <TableRow key={index} hover role="checkbox">
                        <TableCell>
                          <div className="grid-div-post d-flex">
                            {/* <Link to={`brand/profile-insight?sm_id=${}&influencerId=${}`}> */}
                            <img src={props?.platform === 'Instagram' ? data?.gcp_display_url: data?.thumbnails} className="grid-image me-2" />

                            {/* </Link> */}
                            <div className='d-flex flex-column align-items-start justify-content-between'>
                              <div className='d-flex flex-column align-items-start'>
                                <span>{props?.platform === 'Instagram' ? data.full_name : data?.channel_title}</span>
                                <Link target='_blank' className='btn btn-text-link mb-1' to={(props?.platform === 'Instagram') ? `https://www.instagram.com/${data?.username}` : `https://www.youtube.com/channel/${data?.channel_id}`}>{(props?.platform === 'Instagram') ? data?.username : data?.channel_title}</Link>
                              </div>

                              {(props?.platform === 'Instagram') ? (
                                <span style={{ color: '#787878' }}>Duration: { `${methods.formatDuration(data?.video_duration)}`}</span>
                              ) : (
                                <Link target='_blank' className='mb-1 text-black text-decoration-none' to={`https://www.youtube.com/watch?v=${data?.video_id}`}>
                                  <span>{data?.title} </span>
                                </Link>
                              )
                              }
                              
                            </div>
                          </div>
                        </TableCell>
                        
                        {props?.platform === 'Youtube' &&
                          <>
                            {
                              (() => {
                                const matchingItem = props?.postAnalyticsEr && props?.postAnalyticsEr.find((item: any) => item?.url_id === data.url_id);

                                return (
                                  <TableCell>
                                    {matchingItem ? matchingItem?.result.toFixed(2) : "NA"}
                                  </TableCell>
                                );
                              })()
                            }
                          </>
                        }
                        {props?.platform === 'Instagram' && (
                          <TableCell>{data?.er && data?.er ? `${data?.er}%` : "NA"}</TableCell>
                        )}
                        
                        <TableCell>
                          {props?.platform === 'Instagram'
                            ? methods.formatInfluencerProfileNumber(data?.video_plays) 
                            : methods.formatInfluencerProfileNumber(data?.view_count)}
                        </TableCell>
                        <TableCell>
                          {(props?.platform === 'Instagram' && !props.sampleData)
                            ? (data?.__typename === 'GraphVideo'
                              ? methods.formatInfluencerProfileNumber(data?.likes)
                              : "likes are disabled by the user")
                            : methods.formatInfluencerProfileNumber(data?.like_count)}

                            {/* {props?.sampleData && <>{methods.formatInfluencerProfileNumber(data?.like_count)}</>} */}

                        </TableCell>
                        <TableCell>{(props?.platform === 'Instagram' && !props.sampleData) ? methods.formatInfluencerProfileNumber(data?.comments) : methods.formatInfluencerProfileNumber(data?.comment_count)}

                          {(props.platform === 'Instagram' && props.sampleData) ? methods.formatInfluencerProfileNumber(data?.comments) : methods.formatInfluencerProfileNumber(data?.comment_count)}
                        </TableCell>
                        <TableCell>{dayjs(data?.created_at).format("MMM D, YYYY")}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableComponent>
              
              </>
            )
          }

        })()

      }
    </>
  )
}

export default SampleDetailedInsightsPost