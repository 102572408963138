interface Props {
  className?: string;
}
const IconNegotiation: React.FC<Props> = (props: Props) => {
  return (
    <div {...props}
      className={props.className}
    >
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4764_4607" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4764_4607)">
          <path d="M4.82692 19.673V18.1731H7.75L7.02308 17.4769C6.20128 16.7166 5.61697 15.8656 5.27017 14.924C4.92339 13.9823 4.75 13.0307 4.75 12.0692C4.75 10.341 5.25608 8.79133 6.26825 7.42018C7.28042 6.04903 8.60765 5.10768 10.2499 4.59613V6.17685C9.04355 6.64865 8.07528 7.42141 7.34515 8.49513C6.61502 9.56886 6.24995 10.7602 6.24995 12.0692C6.24995 12.8512 6.39803 13.6109 6.6942 14.348C6.99035 15.0852 7.45124 15.7666 8.07687 16.3923L8.71152 17.0269V14.2884H10.2115V19.673H4.82692ZM17.75 19.4038V17.8231C18.9564 17.3513 19.9247 16.5785 20.6548 15.5048C21.3849 14.431 21.75 13.2397 21.75 11.9307C21.75 11.1487 21.6019 10.389 21.3057 9.65188C21.0096 8.9147 20.5487 8.23328 19.9231 7.60763L19.2884 6.97298V9.71148H17.7885V4.3269H23.173V5.82685H20.25L20.9769 6.52305C21.7679 7.31407 22.3445 8.17272 22.7067 9.099C23.0689 10.0253 23.25 10.9692 23.25 11.9307C23.25 13.6589 22.7439 15.2086 21.7317 16.5797C20.7195 17.9509 19.3923 18.8922 17.75 19.4038Z" fill="#1C1B1F" />
          <mask id="mask1_4764_4607" maskUnits="userSpaceOnUse" x="8" y="7" width="11" height="11">
            <rect x="8.75" y="7" width="10.2334" height="10.2334" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask1_4764_4607)">
            <path d="M14.5654 15.7411L11.8414 12.9039V12.1988H13.2272C13.6312 12.1988 13.9898 12.0693 14.303 11.8105C14.6163 11.5516 14.789 11.2055 14.8212 10.772H11.415V10.1324H14.7343C14.6299 9.83994 14.4416 9.60009 14.1694 9.41285C13.8971 9.22562 13.5831 9.13201 13.2272 9.13201H11.415V8.49243H16.3185V9.13201H14.7851C14.9366 9.25282 15.0691 9.40001 15.1828 9.57357C15.2966 9.74714 15.3734 9.93342 15.4133 10.1324H16.3185V10.772H15.4674C15.4379 11.376 15.2055 11.8714 14.7704 12.2582C14.3353 12.6449 13.8209 12.8383 13.2272 12.8383H12.6679L15.4534 15.7411H14.5654Z" fill="#1C1B1F" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default IconNegotiation;


