import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';

const http = new HttpService();

export class ClanShopService {
    static async brandAddProduct(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/brand/add_product`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`brand add_product :: ${error}`);
        }
    }

    static async updatebrandProduct(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/brand/update_brand_product`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`brand add_product :: ${error}`);
        }
    }

    static async getBrandProductsData(params: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/brand/product_data/${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getBrandCampaignProductsData(params: any): Promise<any> {
        try {
            const path =
                BASE_URL + `/clanshop/brand/campaign_product_data/${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getShopDetails(params: any): Promise<any> {
        try {
            const path =
                BASE_URL +
                `/clanshop/influencer/shop-details?account_id=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    // static async getAllShopData(params: any): Promise<any> {
    //     try {
    //         const path =
    //             BASE_URL +
    //             `/clanshop/influencer/shop-details?account_id=${params}`;
    //         const response = await http.getRequest(path, null);
    //         return response;
    //     } catch (error) {
    //         throw new Error(`ClanShop Product Data :: ${error}`);
    //     }
    // }

    static async createShop(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/influencer/create-shop`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getShopName(params: any): Promise<any> {
        try {
            const path =
                BASE_URL + `/clanshop/influencer/check-shop-name/${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getBrandNames(): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/influencer/brand_names`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async addProductToShop(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/influencer/add_product_shop`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async RemoveProductToShop(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/influencer/remove_product_shop`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getShopProducts(params: any): Promise<any> {
        try {
            const path =
                BASE_URL +
                `/clanshop/influencer/shop_products?shop_id=${params}&user_type=influencer`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async brandHook(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/brand/webhook`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getProductDetail(params: any): Promise<any> {
        try {
            const path =
                BASE_URL + `/clanshop/product-detail?product_id=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async createShopBuyer(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/create-buyer`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getOrderPlaceDetails(
        param1: any,
        param2: any,
        param3: any,
        param4: any,
        param5: any
    ): Promise<any> {
        try {
            const path =
                BASE_URL +
                `/clanshop/place-order-details?shop_id=${param1}&product_id=${param2}&user_id=${param3}&address_id=${param4}&cart=${param5}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async checkUserExsist(params: any): Promise<any> {
        try {
            const path =
                BASE_URL + `/clanshop/checkuser-exsist?mobile=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async productIntegerationDetails(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/product-integeration-details`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getOrderDetails(params: any): Promise<any> {
        try {
            const path =
                BASE_URL + `/clanshop/get-order-details?order_id=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async saveCartProduct(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/save-cart-product`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async getUserCartDetails(
        params1?: any,
        params2?: any
    ): Promise<any> {
        try {
            const path =
                BASE_URL +
                `/clanshop/get-user-cart-details?user_id=${params1}&product_ids=${params2}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }

    static async deleteCartItem(data: any): Promise<any> {
        try {
            const path = BASE_URL + `/clanshop/delete-cart-item`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`ClanShop Product Data :: ${error}`);
        }
    }
}
