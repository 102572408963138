import { PaymentGatewayService, LocalService } from "../../core/services";
import { RazorpayOptions } from 'react-razorpay';
import { CreateOrder } from "custom-type";
import { PAYMENT_GATEWAY_KEY } from "../../config/config";
import Logo from "../../assets/images/clan_connect_logo.png";


const paymentGatewayService: PaymentGatewayService = new PaymentGatewayService();

const localService: LocalService = new LocalService();

async function createOrder(orderDetail: CreateOrder) {
  try {
    const response: any = await paymentGatewayService.createOrder(orderDetail);
    return response;
  } catch (error) {
    throw new Error(`CreaterOrder`);
  }
}

async function updateOrderDetail(payment_id: string, props: any) {
  try {
    const response = await paymentGatewayService.updateOrderDetail({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      influencer_id: props.UserReducer.influencer_id,
      account_type: props.UserReducer.account_type,
      account_name: props.UserReducer.account_name,
      login_email: props.UserReducer.login_email,
      login_mobile: props.UserReducer.login_mobile,
      razorpay_payment_id: payment_id,
      campaign_id: props.campaignReducer?.createCampaign?.campaign_id || props?.selectedCampaign?.campaign_id || 0,
      brand_id: props.UserReducer?.brand_id
    });
    if (response.data.status.status) {
      return payment_id
    } else {
      localService.toastify(response.data.status.message, "error", 1000);
      return ''
    }
  } catch (error) {
    console.error(`Update Order Detail Error :: ${error}`);
    return ''
  } finally {
    // getSubscriptionDetail();
  }
}

async function makeRazorpayPayment(data: any, props: any, razorpay: any): Promise<any> {
  const res = {
    status: false,
    paymentId: '',
    message: 'Payment failed.',
    error: null
  }
  try {
    // First Create order
    const razorpayOrder = await createOrder({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      influencer_id: props.UserReducer.influencer_id,
      account_type: props.UserReducer.account_type,
      account_name: props.UserReducer.account_name,
      login_email: props.UserReducer.login_email,
      login_mobile: props.UserReducer.login_mobile,
      plan_id: data.plan_id,
      orderDetail: {
        amount: data.amount + '00',
        currency: 'INR',
        notes: {
          key1: 'LS purchase',
        },
      },
      campaign_id: props.campaignReducer?.createCampaign?.campaign_id || props?.selectedCampaign?.campaign_id || 0,
      brand_id: props.UserReducer?.brand_id
    });

    // If Order is Created, the User Makes a Payment
    if (razorpayOrder.data.status.status) {
      const paymentPromise = new Promise<any>((resolve, reject) => {
        const RAZORPAY_OPTIONS: RazorpayOptions = {
          key: PAYMENT_GATEWAY_KEY,
          amount: razorpayOrder.data.rows.amount_due + '00',
          currency: 'INR',
          name: 'ClanConnect',
          description: `${data?.user_type} ${data.description}`,
          image: Logo,
          order_id: razorpayOrder.data.rows.id,
          handler: function (response) {
            // Payment successful
            updateOrderDetail(response.razorpay_payment_id, props)
              .then((response) => {
                res.status = true;
                res.message = 'Payment success'
                res.paymentId = response
                resolve(res);
              })
              .catch(error => {
                res.status = false;
                res.message = 'Payment failed'
                res.error = error
                console.error("Update Order Detail Error:", error);
                reject(res); // Reject with appropriate error message
              });
          },
          prefill: {
            name: props.UserReducer.account_name,
            email: props.UserReducer.login_email,
            contact: props.UserReducer.login_mobile,
          },
          notes: {
            address: 'Razorpay Corporate Office',
          },
          theme: {
            color: '#00aae5',
          },
          modal: {
            ondismiss: function () {
              res.status = false;
              res.message = 'Payment cancelled'
              reject(res); // Reject with appropriate error message on cancellation
            }
          }
        };
        const rzpay = new razorpay(RAZORPAY_OPTIONS);
        rzpay.open();
      });

      return paymentPromise;
    } else {
      // Order creation failed
      localService.toastify(razorpayOrder.data.status.message);
      return res
    }
  } catch (error: any) {
    // Error occurred during payment process
    res.error = error;
    console.error("Make Payment Error:", error);
    return res;
  }
}

export { makeRazorpayPayment };