import React, { useEffect, useState } from "react";

interface Proposal {
  name: string;
  total_price: number;
  payment_status: string;
  account_type?: string;
  account_holder_name?: string;
  account_number?: string;
  ifsc_code?: string;
  address?: string;
  influencer_id: number;
  proposal_id: number;
  file_urls: string;
}

// For bank details section
const AccountDetails: React.FC<{ account: Proposal }> = ({ account }) => {
  const [showBankDetails, setShowBankDetails] = useState(false);

  const toggleBankDetails = () => {
    setShowBankDetails((prevState) => !prevState);
  };

  return (
    <div>
      <span
        className="btn-account-det-toggle"
        style={{ cursor: 'pointer', marginBottom: '6px', display: 'block' }}
        onClick={toggleBankDetails}
      >
        Account Type: {account.account_type}
        <i className={`bi bi-chevron-${showBankDetails ? 'up' : 'down'}`}></i>
      </span>
      {showBankDetails && (
        <div className="account-det">
          <span>Account Holder Name: {account.account_holder_name}</span>
          <br />
          <span>Account Number: {account.account_number}</span>
          <br />
          <span>IFSC: {account.ifsc_code}</span>
          <br />
          <span>Bank Address: {account.address}</span>
        </div>
      )}
    </div>
  );
};

export default AccountDetails;