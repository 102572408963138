import { PublicService } from "./public.service";

const publicService: PublicService = new PublicService();

interface PageViewEventData {
  url: string;
}

interface ViewContentEventData {
  url: string;
}

export class FbConversionTrack {
  async trackPageView() {
    try {
      const url = window.location.href;
      const response = await publicService.recordFbConversionPageView({ url: url });
      // console.log('Response:', response);
    } catch (error) {
      console.error('Error sending POST request:', error);
    }
  };

  async trackViewContent(data: any) {
    try {
      const url = window.location.href;
      const response = await publicService.recordFbConversionViewContent({ url: url, user_data: data?.userData, campaign_data: data?.campaignData });
      return response;
    } catch (error) {
      console.error('Error sending POST request:', error);
    }
  };
}