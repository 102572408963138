import React from 'react'
import ProductLinkSection from './product-link-section'
import MaterialSymbol from '../../../components/icons/material-symbol'
import MultipleReplies from './multiple-replies'
import KeywordInput from './keyword-input'
import SelectPostStory from './select-post-story'

type Props = {}

const NewAutomationStory = (props: Props) => {
  return (
     <section className='new-automation-steps'>
       <ul className='new-automation-step-counter'>
        <strong className='d-flex align-items-center mb-5'>
        <MaterialSymbol iconFilled iconName='counter_1' fontweight='200' />
        <span className='d-flex flex-column '>Set Triggers
          <span className='fs-14 fw-400'>
            Choose the Story, set keywords.
          </span>
        </span>
      </strong>
        <SelectPostStory />
        <KeywordInput />
        <MultipleReplies />
        <strong className='d-flex align-items-center mb-5 mt-5'>
        <MaterialSymbol iconFilled iconName='counter_2' fontweight='200' />
        <span className='d-flex flex-column '>Set Action
          <span className='fs-14 fw-400'>
            Specify the products/links you'd like to automatically send as a Instagram DM in response to users' comments.
          </span>
        </span>
      </strong>
        
          <ProductLinkSection />
        
      </ul>
        <div className='automation-action-btns'>
          <button className='btn btn-outline-primary me-auto'>
            Save as Draft
          </button>
          <button className='btn btn-outline-primary ms-auto me-2'>
            Preview
          </button>
          <button className='btn btn-primary'>
            Make Live
          </button>
        </div>
    </section>
  )
}

export default NewAutomationStory