import { TextField } from '@mui/material';
import React from 'react';

interface Props {
    code: any;
    method: string;
    headerValue: string;
    urlValue: string;
    paramsValue: string;
    onHeaderChange: (event: any) => void;
    onUrlChange: (event: any) => void;
    onParamsChange: (event: any) => void;
    errorValue: string;

}
const ProductIntegerationInput: React.FC<Props> = (props: Props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <pre><code>{props.code}</code></pre>
            <div>
                <TextField
                    size='small'
                    multiline
                    fullWidth
                    minRows={1}
                    maxRows={6}
                    className='influencer_personal_info mb-3'
                    name='headers'
                    value={props.headerValue}
                    defaultValue=""
                    type='text'
                    id='outlined-basic'
                    label='Headers'
                    variant='outlined'
                    onChange={props.onHeaderChange}

                />
                <TextField
                    size='small'
                    multiline
                    minRows={1}
                    maxRows={3}
                    fullWidth
                    className='influencer_personal_info mb-3'
                    value={props.urlValue}
                    name='url'
                    type='text'
                    id='outlined-basic'
                    label='Url'
                    variant='outlined'
                    onChange={props.onUrlChange}
                />
                {props.method === 'GET' ?
                    <TextField
                        size='small'
                        multiline
                        minRows={1}
                        maxRows={6}
                        fullWidth
                        className='influencer_personal_info mb-3'
                        value={props.paramsValue}
                        name='params'
                        type='text'
                        id='outlined-basic'
                        label='Params'
                        variant='outlined'
                        onChange={props.onParamsChange}
                    /> :
                    <TextField
                        size='small'
                        multiline
                        minRows={1}
                        maxRows={6}
                        fullWidth
                        className='influencer_personal_info'
                        name='data'
                        type='text'
                        id='outlined-basic'
                        label='Data'
                        variant='outlined'
                    />
                }
                <span>{props?.errorValue}</span>
            </div>
        </div >
    )
}

export default ProductIntegerationInput